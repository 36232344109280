<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 1000"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    class="update-document-slideout"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Drawing Document ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly || !canEdit">
          <i class="fad fa-user-tie mr-2"></i> Drawing Document: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <!-- <entity-id class="ml-2" :id="selected.id" :path="clientUrl" title="DrawingDocument"></entity-id> -->
      </h3>
      <h3 v-else-if="cloneMode" class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i>
        Clone Drawing Document: '{{ originalDocument.name }}'
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a Drawing Document
      </h3>
      <div>
        <code
          v-if="readonly || !canEdit"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>
    <div style="position: sticky; top: 0; z-index: 20; background: #fff">
      <v-tabs
        v-model="slideouts.update.tab"
        color="error"
        v-if="slideouts.update.active && !isFetching"
      >
        <v-tab :key="0"> Document Info </v-tab>
        <v-tab :key="1"> Layout </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-container class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Document ...</p>
    </v-container>
    <v-container fluid class="pa-0 update-document-container" v-else>
      <v-form v-model="slideouts.update.valid" ref="updateForm">
        <v-tabs-items v-model="slideouts.update.tab">
          <v-tab-item :key="0">
            <v-container class="pa-6">
              <v-row class="my-0">
                <v-col cols="12" md="2" sm="12" class="py-0">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Document Type"
                    id="DocumentType"
                    placeholder="Type"
                    v-model="selected.type"
                    :items="docTypes"
                    dense
                    filled
                    item-text="text"
                    item-value="value"
                    :readonly="readonly || !canEdit"
                    @change="
                      () => {
                        selected.namingTemplateId = null;
                      }
                    "
                  >
                    <template v-slot:selection="{ item }">
                      <span style="margin-left: -0.5rem">
                        <document-type :type="item.value"></document-type>
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span style="margin-left: -0.5rem">
                        <document-type :type="item.value"></document-type>
                      </span>
                    </template>
                  </v-select-alt>
                </v-col>
                <v-col cols="12" md="7" sm="12" class="py-0">
                  <naming-template-selector
                    v-model="selected.namingTemplateId"
                    :document-type="selected.type"
                    :readonly="readonly || selected.type == null"
                    :label="
                      selected.type == null
                        ? `<i class='fad fa-pen-field mr-2'></i>Naming Template <span class='pl-2 fs-12px text--secondary'>(Select a Document Type to enable!)</span>`
                        : `<i class='fad fa-pen-field mr-2'></i>Naming Template`
                    "
                    nested
                  ></naming-template-selector>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <label
                    v-if="selected.namingTemplateId == null"
                    style="font-weight: 600 !important; font-size: 15px"
                  >
                    Document Sequence
                  </label>
                  <h4 v-if="selected.namingTemplateId == null" class="fs-12px opacity-64 mt-2">
                    Naming Template Only Field
                  </h4>
                  <v-text-field-alt
                    :rules="[allRules.noWhiteSpaces]"
                    v-if="selected.namingTemplateId != null"
                    label="Document Sequence"
                    id="documentSequence"
                    ref="documentSequence"
                    placeholder="Document Sequence"
                    v-model="selected.sequence"
                    :readonly="readonly || !canEdit || selected.namingTemplateId == null"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="grey lighten-5 rounded pt-6 pb-2 mb-4"
                  style="border: 1px dashed #b0bec5 !important"
                >
                  <v-row>
                    <v-col cols="12" md="6" sm="12" class="py-0">
                      <v-text-field-alt
                        :rules="
                          selected.namingTemplateId == null
                            ? [allRules.required, allRules.noWhiteSpaces]
                            : []
                        "
                        label="Document Name"
                        id="documentName"
                        ref="documentName"
                        placeholder="Document Name"
                        class="mono-font-text-field"
                        v-model="selected.name"
                        @input="
                          ($event) => {
                            selected.name = selected.name.toUpperCase();
                          }
                        "
                        :readonly="readonly || !canEdit || selected.namingTemplateId != null"
                      >
                      </v-text-field-alt>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" class="py-0">
                      <v-text-field-alt
                        :rules="
                          selected.namingTemplateId == null
                            ? [allRules.required, allRules.noWhiteSpaces]
                            : []
                        "
                        label="Document Number"
                        id="documentNumber"
                        ref="documentNumber"
                        placeholder="Document Number"
                        class="mono-font-text-field"
                        v-model="selected.number"
                        @input="
                          ($event) => {
                            selected.number = selected.number.toUpperCase();
                          }
                        "
                        :readonly="readonly || !canEdit || selected.namingTemplateId != null"
                      >
                      </v-text-field-alt>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Document State"
                    id="DocumentState"
                    placeholder="State"
                    v-model="selected.state"
                    :items="docStates"
                    dense
                    filled
                    item-text="text"
                    item-value="value"
                    :readonly="readonly || !canEdit"
                  >
                    <template v-slot:selection="{ item }">
                      <status-item
                        style="margin-left: -0.5rem"
                        small
                        :statusList="statusList"
                        :status="item.value"
                      ></status-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <status-item
                        style="margin-left: -0.5rem"
                        small
                        :statusList="statusList"
                        :status="item.value"
                      ></status-item>
                    </template>
                  </v-select-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <layout-template-selector
                    required
                    :readonly="readonly || !canEdit"
                    v-model="selected.drawingViewLayoutId"
                  ></layout-template-selector>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    label="<i class='fab fa-jira mr-1'></i> Jira Issue Key"
                    id="jiraCode"
                    ref="jiraCode"
                    :rules="[allRules.noWhiteSpaces]"
                    placeholder="Jira Issue Key"
                    v-model="selected.jiraCode"
                    :readonly="readonly || !canEdit"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces, allRules.lengthMax(50)]"
                    label="Published Drawing Title"
                    id="publishedDrawingTitle"
                    ref="publishedDrawingTitle"
                    placeholder="Published Drawing Title"
                    v-model="selected.publishedDrawingTitle"
                    :readonly="readonly || !canEdit"
                  >
                    <template v-slot:label-append>
                      <v-tooltip
                        left
                        max-width="320px"
                        z-index="99999"
                        class="ml-2"
                        color="secondary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="text--secondary"
                            style="font-size: 18px; line-height: 0"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fad fa-info-circle"></i>
                          </div>
                        </template>
                        <span style="font-size: 14px">
                          Enter the title of the Drawing Set or Individual Change Sheet Reference.
                        </span>
                      </v-tooltip>
                    </template>
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-menu
                    v-model="publishedDrawingDateMenu"
                    :disabled="readonly || !canEdit"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :nudge-top="20"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field-alt
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        :class="{ 'calendar-input': !readonly && canEdit }"
                        :rules="[allRules.required]"
                        label="Published Drawing Date"
                        id="publishedDrawingDate"
                        placeholder="Published Drawing Date"
                        v-model="selected.publishedDrawingDate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:label-append>
                          <v-tooltip
                            left
                            max-width="320px"
                            z-index="99999"
                            class="ml-2"
                            color="secondary"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="text--secondary"
                                style="font-size: 18px; line-height: 0"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <i class="fad fa-info-circle"></i>
                              </div>
                            </template>
                            <span style="font-size: 14px">
                              Enter the delivery date of the Published Drawing Set OR when this
                              Individual Change Sheet should be published.
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field-alt>
                    </template>
                    <v-date-picker
                      color="orange darken-3"
                      header-color="secondary"
                      :first-day-of-week="1"
                      :rules="[allRules.required]"
                      v-model="selected.publishedDrawingDate"
                      @input="publishedDrawingDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0 mb-6">
                  <label class="v-fake-label mb-0">
                    <i class="fad fa-link mr-2"></i>Associate with
                  </label>
                  <v-radio-group
                    v-model="selected.associateType"
                    row
                    color="info"
                    class="mt-2 associate-type-group"
                    :disabled="readonly || !canEdit"
                    hide-details
                  >
                    <v-radio :value="enums.ASSOCIATE_TYPE.NotAssociated.value">
                      <template v-slot:label>
                        <label
                          class="v-switch-label"
                          :class="{
                            'secondary--text':
                              selected.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value,
                          }"
                          >Nothing</label
                        >
                      </template>
                    </v-radio>
                    <v-radio :value="enums.ASSOCIATE_TYPE.Space.value">
                      <template v-slot:label>
                        <label
                          class="v-switch-label"
                          :class="{
                            'secondary--text':
                              selected.associateType == enums.ASSOCIATE_TYPE.Space.value,
                          }"
                          >Space</label
                        >
                      </template>
                    </v-radio>
                    <v-radio :value="enums.ASSOCIATE_TYPE.Level.value">
                      <template v-slot:label>
                        <label
                          class="v-switch-label"
                          :class="{
                            'secondary--text':
                              selected.associateType == enums.ASSOCIATE_TYPE.Level.value,
                          }"
                          >Level</label
                        >
                      </template>
                    </v-radio>
                    <v-radio :value="enums.ASSOCIATE_TYPE.Building.value">
                      <template v-slot:label>
                        <label
                          class="v-switch-label"
                          :class="{
                            'secondary--text':
                              selected.associateType == enums.ASSOCIATE_TYPE.Building.value,
                          }"
                          >Building
                        </label>
                      </template>
                    </v-radio>
                    <v-radio :value="enums.ASSOCIATE_TYPE.Category.value">
                      <template v-slot:label>
                        <label
                          class="v-switch-label"
                          :class="{
                            'secondary--text':
                              selected.associateType == enums.ASSOCIATE_TYPE.Category.value,
                          }"
                          >Category
                        </label>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                  style="height: 90px"
                  v-if="selected.associateType == enums.ASSOCIATE_TYPE.Space.value"
                >
                  <project-space-selector
                    :label="`<i class='fad fa-table-pivot mr-2'></i>Associated Space`"
                    :project-id="projectId"
                    v-model="selected.spaceId"
                    ref="projectSpaceSelector"
                    hide-details
                    :readonly="readonly || !canEdit"
                  ></project-space-selector>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                  style="height: 90px"
                  v-else-if="selected.associateType == enums.ASSOCIATE_TYPE.Level.value"
                >
                  <project-level-selector
                    :label="`<i class='fad fa-layer-group mr-2'></i>Associated Level`"
                    :project-id="projectId"
                    v-model="selected.levelId"
                    ref="projectLevelSelector"
                    hide-details
                    :readonly="readonly || !canEdit"
                  ></project-level-selector>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                  style="height: 90px"
                  v-else-if="selected.associateType == enums.ASSOCIATE_TYPE.Building.value"
                >
                  <project-building-selector
                    :label="`<i class='fad fa-building mr-2'></i>Associated Building`"
                    :project-id="projectId"
                    v-model="selected.buildingId"
                    ref="projectBuildingSelector"
                    hide-details
                    :readonly="readonly || !canEdit"
                  ></project-building-selector>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                  style="height: 90px"
                  v-else-if="selected.associateType == enums.ASSOCIATE_TYPE.Category.value"
                >
                  <equipment-category-selector
                    :label="`<i class='fad fa-shapes mr-2'></i>Associated Category`"
                    ref="projectCategorySelector"
                    v-model="selected.categoryId"
                    :readonly="readonly || !canEdit"
                    clearable
                    required
                    hide-details
                  ></equipment-category-selector>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  style="height: 90px"
                  class="pt-1 pb-6 d-flex align-start"
                  v-else-if="selected.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value"
                >
                  <span class="d-flex align-center">
                    <i class="fad fa-link-slash"></i>
                    <span class="ml-2" style="font-weight: 600 !important; font-size: 15px">
                      Not Associated
                    </span></span
                  >
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0" v-if="false">
                  <label class="input-label">Assignee</label>
                  <user-avatar
                    class="mt-1"
                    :user="selected.assignee"
                    show-no-user
                    no-user-text="No user assigned!"
                    no-user-icon="fa-user-alt-slash"
                  ></user-avatar>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pb-0">
                  <v-textarea-alt
                    label="Notes"
                    id="notes"
                    ref="notes"
                    rows="4"
                    placeholder="Write your notes"
                    v-model="selected.notes"
                    :readonly="readonly || !canEdit"
                  >
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item :key="1">
            <layout-manager
              v-model="selected.layoutData"
              :disabled="readonly || !canEdit || isLayoutTemplateChanged || isLayoutTemplateAdded"
              parent-selector=".update-document-slideout .vue-slideout-content"
            ></layout-manager>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-container>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && readonly && $has(perms.DrawingDocuments.Update)"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchDrawingDocument()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Drawing Documents</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="del" v-if="$has(perms.DrawingDocuments.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.DrawingDocuments.Update) && !$has(perms.DrawingDocuments.Delete)"
            >
              No Actions Available!
            </v-subheader>
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import Enums from "../../../plugins/enums";
import DocumentType from "./DocumentType.vue";
import LayoutManager from "./LayoutManager.vue";
import ddmAPI from "../services/drawing-documents-service";
import perms from "../../../plugins/permissions";
// import NamingTemplateSelector from "./NamingTemplateSelector.vue";
import LayoutTemplateSelector from "../../Shared/components/LayoutTemplateSelector.vue";
import ProjectSpaceSelector from "../../Shared/components/ProjectSpaceSelector.vue";
import ProjectLevelSelector from "../../Shared/components/ProjectLevelSelector.vue";
import ProjectBuildingSelector from "../../Shared/components/ProjectBuildingSelector.vue";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import EquipmentCategorySelector from "../../Equipments/components/EquipmentCategorySelector.vue";

export default {
  name: "edit-drawing-document",
  components: {
    NamingTemplateSelector: () => import("./NamingTemplateSelector.vue"),
    DocumentType,
    LayoutManager,
    LayoutTemplateSelector,
    ProjectSpaceSelector,
    ProjectLevelSelector,
    ProjectBuildingSelector,
    PanelSizeControl,
    EquipmentCategorySelector,
  },
  data() {
    return {
      publishedDrawingDateMenu: false,
      enums: Enums,
      perms: perms,
      canEdit: false,
      cloneMode: false,
      originalDocument: null,
      drawingDocumentId: null,
      isFetching: false,
      imageApiUrl: `clients/Image`,
      selected: {},
      readonly: false,
      selectedCemented: {},
      docStates: this.$options.filters.EnumToList(Enums.DOCUMENT_STATE, true),
      statusList: Enums.DOCUMENT_STATE,
      docTypes: this.$options.filters.EnumToList(Enums.DOCUMENT_TYPES, true),
      layoutTypes: Enums.DOCUMENT_LAYOUT_TYPES_LIST,
      isLayoutTemplateLoading: false,
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          isLoading: false,
          isClosing: false,
          valid: false,
          tab: null,
          hasChanges: false,
        },
      },
    };
  },
  computed: {
    isLayoutTemplateChanged() {
      return !this.isEqual(
        this.selected.drawingViewLayoutId,
        this.selectedCemented.drawingViewLayoutId
      );
    },
    isLayoutTemplateAdded() {
      return this.selected.drawingViewLayoutId != null;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "1000px";
    },
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    this.canEdit = this.$has(perms.DrawingDocuments.Update);
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.$has(this.perms.DrawingDocuments.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      // this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cloneMode = false;
      this.originalDocument = null;
      this.cementDrawingDocument();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewDrawingDocument) {
      this.$emit("save", this.selected, isNewDrawingDocument);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = this.cloneDeep(this.selected);
      // if (toSend.id == null) toSend.projectId = this.projectId;
      if (toSend.layoutData == null) toSend.layoutData = "";
      else toSend.layoutData = JSON.stringify(toSend.layoutData);
      if (toSend.namingTemplateId != null) {
        toSend.name = "";
        toSend.number = "";
      }
      this.$log("update >> toSend", toSend);
      ddmAPI
        .updateProjectDocument(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Drawing document updated successfully!";
          this.$log(">>> updated", resp.data);
          this.selected = this.cloneDeep(this.prepareDocumentData(resp.data));
          this.cementDrawingDocument();
          var isNewDrawingDocument = false;
          if (!toSend.id) {
            this.drawingDocumentId = this.selected.id;
            isNewDrawingDocument = true;
            message = "Drawing document added successfully!";
          }
          this.announceChange(isNewDrawingDocument);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      if (id == null) {
        this.selected = {
          projectId: this.projectId,
          associateType: 0,
          namingTemplateId: null,
          layoutData: { pages: [] },
        };
        this.readonly = false;
        this.isFetching = false;
        this.drawingDocumentId = null;
        this.cementDrawingDocument();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
        setTimeout(() => {
          this.$refs.documentName.focus();
        }, 250);
      } else {
        this.readonly = !editMode;
        this.drawingDocumentId = id;
        this.fetchDrawingDocument();
      }
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
      }, 250);
    },
    openClone(originalDocument) {
      this.$log("originalDocument", this.cloneDeep(originalDocument));
      if (originalDocument != null) {
        this.selected = this.prepareDocumentData(this.cloneDeep(originalDocument));
        this.originalDocument = this.cloneDeep(originalDocument);
        this.selected.id = null;
        this.cloneMode = true;
        this.readonly = false;
        this.isFetching = false;
        this.drawingDocumentId = null;
        this.slideouts.update.active = true;
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
          this.$refs.documentName.focus();
          this.slideouts.update.tab = 0;
        }, 250);
      }
    },
    fetchDrawingDocument() {
      this.isFetching = true;
      ddmAPI
        .getDocument(this.drawingDocumentId)
        .then((resp) => {
          this.$log("getDrawingDocumentById >> success", this.cloneDeep(resp.data));
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(this.prepareDocumentData(resp.data));
          this.cementDrawingDocument();
        })
        .catch((err) => {
          this.$log("getDrawingDocumentById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    prepareDocumentData(raw) {
      raw.layoutData =
        raw.layoutData == null || raw.layoutData == "" ? { pages: [] } : JSON.parse(raw.layoutData);
      if (raw.publishedDrawingDate != null) {
        var idx = raw.publishedDrawingDate.indexOf("T");
        if (idx >= 0) raw.publishedDrawingDate = raw.publishedDrawingDate.substring(0, idx);
      }
      return raw;
    },
    cementDrawingDocument() {
      this.selectedCemented = this.cloneDeep(this.selected);
      // this.$log("########>>>>>>> cementDrawingDocument()");
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) {
        this.$log("%c==>> DIFF", "color: red", this.deepDiff(this.selectedCemented, this.selected));
      }

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    onViewDeleted(viewId) {
      for (let p = 0; p < this.selected.layoutData.pages.length; p++) {
        const _page = this.selected.layoutData.pages[p];

        for (let r = 0; r < _page.rows.length; r++) {
          const _row = _page.rows[r];

          for (let c = 0; c < _row.cols.length; c++) {
            const _col = _row.cols[c];
            if (viewId == _col.viewId) _col.viewId = null;
          }
        }
      }
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete drawing document: <b>${this.selected.name}</b>?`,
          title: `Delete Drawing Document?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ddmAPI
                  .deleteDocument(this.selected.id)
                  .then((resp) => {
                    this.$emit("delete", this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Drawing Document deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error(
                      "an error occurred during deleting the drawing document",
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
  },
  watch: {
    selected: {
      handler() {
        this.$log("#### watch selected: ", this.cloneDeep(this.selected));
        this.checkForChanges();
      },
      deep: true,
    },
    publishedDrawingDateMenu: {
      handler() {
        if (this.publishedDrawingDateMenu) {
          if (this.selected.publishedDrawingDate == null) {
            this.selected.publishedDrawingDate = this.formatDate(Date.now()).substr(0, 10);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.update-document-container {
  .v-window.v-tabs-items {
    overflow: visible !important;
  }
}

.associate-type-group.v-input--radio-group--row .v-input--radio-group__input {
  gap: 0.5rem 0 !important;
}
</style>
