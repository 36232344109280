<template>
  <v-container fluid class="px-4 py-4" v-if="selected != null">
    <h3 class="text--primary font-weight-black mb-3 d-inline-block">Sheet Count per Space</h3>
    <div
      style="background: #eceff1; border-radius: 0.5rem; width: fit-content"
      class="py-4 px-4 inset-shadow d-inline-block"
    >
      <v-row class="mt-2 mb-0" style="gap: 1rem">
        <v-col cols="12" md="12" class="py-0">
          <label
            class="d-inline-block mr-2"
            style="font-weight: 600 !important; font-size: 15px; width: 160px"
          >
            IAV Sheet Count:
          </label>
          <counter
            v-model="selected.iavSheetCount"
            :min="0"
            :max="100"
            :readonly="readonly"
            :step="1"
          />
        </v-col>
        <v-col cols="12" md="12" class="py-0">
          <label
            class="d-inline-block mr-2"
            style="font-weight: 600 !important; font-size: 15px; width: 160px"
          >
            SCD Sheet Count:
          </label>
          <counter
            v-model="selected.scdSheetCount"
            :min="0"
            :max="100"
            :readonly="readonly"
            :step="1"
          />
        </v-col>
        <v-col cols="12" md="12" class="py-0">
          <label
            class="d-inline-block mr-2"
            style="font-weight: 600 !important; font-size: 15px; width: 160px"
          >
            Plan Sheet Count:
          </label>
          <counter
            v-model="selected.planSheetCount"
            :min="0"
            :max="100"
            :readonly="readonly"
            :step="1"
          />
        </v-col>
        <v-col cols="12" md="12" class="py-0">
          <label
            class="d-inline-block mr-2"
            style="font-weight: 600 !important; font-size: 15px; width: 160px"
          >
            Overview Sheet Count:
          </label>
          <counter
            v-model="selected.overviewSheetCount"
            :min="0"
            :max="100"
            :readonly="readonly"
            :step="1"
          />
        </v-col>
        <v-col cols="12" md="12" class="py-0">
          <label
            class="d-inline-block mr-2"
            style="font-weight: 600 !important; font-size: 15px; width: 160px"
          >
            Detail Sheet Count:
          </label>
          <counter
            v-model="selected.detailSheetCount"
            :min="0"
            :max="100"
            :readonly="readonly"
            :step="1"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import Enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";

export default {
  components: {},
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      perms: perms,
      enums: Enums,
      selected: null,
    };
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  watch: {
    value: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.selected = this.cloneDeep(this.value);
      },
      deep: true,
    },
    selected: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.$emit("input", this.selected);
      },
      deep: true,
    },
  },
};
</script>
