export default [
  { text: "Image", value: "imageUrl", width: "80px", cellClass: "px-0", class: "px-0" },
  {
    text: "Building",
    value: "level.building.name",
    width: "200px",
  },
  {
    text: "Level",
    value: "level.name",
    width: "120px",
  },
  { text: "Number", value: "spaceNumber", width: "120px" },
  { text: "Name", value: "name", width: "160px" },
  {
    text: "Status",
    value: "state",
    width: "120px",
  },
  { text: "Equipment", value: "equipmentCount", width: "120px" },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "120px",
  },
  {
    text: "Creator",
    value: "createdBy",
    width: "96px",
  },
  {
    text: "Last Updater",
    value: "updatedBy",
    width: "130px",
  },
];
