<template>
  <v-container fluid class="px-3 pt-3 pb-0">
    <div class="d-flex justify-space-between align-center mb-2">
      <div class="d-flex align-center">
        <h3 class="text--primary font-weight-black mr-3 d-inline-block">External Access List</h3>
      </div>
    </div>
    <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
      <v-col cols="12" md="12" sm="12" v-if="errors.length > 0">
        <ul>
          <li v-for="(err, i) in errors" :key="i" class="red--text">
            {{ err }}
          </li>
        </ul>
      </v-col>
      <div class="d-flex justify-center align-center" v-if="loading">
        <v-progress-circular indeterminate color="info" :width="2"></v-progress-circular>
      </div>
      <div v-else-if="selected != null">
        <div>
          <div class="d-flex align-center mt-4">
            <h3 class="text--primary font-weight-black mr-3 d-inline-block">Individuals Access</h3>
            <v-btn
              color="orange"
              small
              :disabled="readonly"
              @click="createNewEntry()"
              class="font-weight-bold"
              v-if="selected != null"
            >
              <i class="far fa-plus mr-2"></i>New
            </v-btn>
          </div>
          <v-data-table
            dense
            :headers="headers"
            :items="selected.accessListRecords || []"
            :items-per-page="-1"
            class="elevation-2 mt-2"
            :loading="loading"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
            </template>
            <template v-slot:[`item.userId`]="{ item }">
              <user-all-selector
                :users="users"
                required
                hideLabel
                hideDetails
                hideBorder
                :readonly="item.id != 0"
                :isUsersLoading="isUsersLoading"
                v-model="item.userId"
                style="width: 100%"
                class="ml-0"
              ></user-all-selector>
            </template>
            <template v-slot:[`item.isActive`]="{ item }">
              <v-switch
                class="ma-0 pa-0"
                color="info"
                hide-details
                inset
                dense
                v-model="item.isActive"
                :disabled="readonly"
              >
              </v-switch>
            </template>
            <template v-slot:[`item.actions`]="{ item, index }">
              <v-btn
                icon
                elevation="0"
                color="error"
                @click.stop="del(item, index)"
                :disabled="readonly"
              >
                <i class="fal fa-trash-alt"></i>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <img width="300" src="/img/art/fogg-no-connection-2.png" />
              <p class="font-weight-bold">No Data Available!</p>
            </template>
          </v-data-table>

          <br />
          <div class="d-flex align-center">
            <h3 class="text--primary font-weight-black mr-3 d-inline-block">Groups Access</h3>
            <v-btn
              color="orange"
              small
              @click="createNewGroupEntry()"
              class="font-weight-bold"
              :disabled="readonly"
              v-if="selected != null"
            >
              <i class="far fa-plus mr-2"></i>New
            </v-btn>
          </div>
          <!-- Access Groups  -->
          <v-data-table
            dense
            :headers="groupHeaders"
            :items="selected.accessGroupRecords"
            :items-per-page="-1"
            class="elevation-2 mt-2"
            :loading="loading"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
            </template>
            <template v-slot:[`item.isActive`]="{ item }">
              <v-switch
                class="ma-0 pa-0"
                color="info"
                hide-details
                inset
                dense
                :disabled="readonly"
                v-model="item.isActive"
              >
              </v-switch>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <access-groups-selector
                :accessGroups="accessGroups"
                required
                hideLabel
                hideDetails
                hideBorder
                :readonly="item.id != 0"
                :isAccessGroupsLoading="isAccessGroupsLoading"
                v-model="item.accessGroup"
                style="width: 100%"
                class="ml-0"
              ></access-groups-selector>
            </template>
            <template v-slot:[`item.actions`]="{ item, index }">
              <v-btn
                icon
                elevation="0"
                color="error"
                :disabled="readonly"
                @click.stop="delGroup(item, index)"
              >
                <i class="fal fa-trash-alt"></i>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <img width="300" src="/img/art/fogg-no-connection-2.png" />
              <p class="font-weight-bold">No Data Available!</p>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import enums from "../../../../plugins/enums";
import manufacturerAPI from "../services/manufacturers-service.js";
import perms from "../../../../plugins/permissions";
import headers from "../config/tables/equipmentAccessList.header";
import groupHeaders from "../config/tables/equipmentAccessGroup.header";
import externalUsersAPI from "../../../Admin/services/UsersExternalService";
import externalAccessGroupsAPI from "../../../AccessGroups/services/external-access-group-service";
import UserAllSelector from "../../../Shared/components/UserAllSelector.vue";
import AccessGroupsSelector from "./ExternalAccessGroupSelector.vue";

export default {
  name: "external-access-list",
  props: {
    manufactureId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  data() {
    return {
      enums,
      valid: false,
      hasChanges: false,
      loading: false,
      saveLoading: false,
      users: [],
      accessGroups: [],
      isUsersLoading: false,
      isAccessGroupsLoading: false,
      selected: null,
      selectedCemented: null,
      headers: headers,
      groupHeaders: groupHeaders,
    };
  },
  created() {
    this.getAllUsersData();
    this.getAllGroupsData();
    //this.getProjectAccessList();
  },
  methods: {
    createNewEntry() {
      this.$log(this.selected);
      this.selected.accessListRecords.push({
        id: 0,
        isActive: true,
        userId: null,
      });
    },
    del(item, index) {
      this.selected.accessListRecords.splice(index, 1);
    },
    createNewGroupEntry() {
      this.selected.accessGroupRecords.push({
        id: 0,
        isActive: true,
        accessGroupId: 0,
      });
    },
    delGroup(item, index) {
      this.selected.accessGroupRecords.splice(index, 1);
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      externalUsersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    getAllGroupsData() {
      this.isAccessGroupsLoading = true;
      externalAccessGroupsAPI
        .typeHead(null, true)
        .then((resp) => {
          this.accessGroups = resp.data;
          this.isAccessGroupsLoading = false;
        })
        .catch(() => {
          this.isAccessGroupsLoading = false;
        });
    },

    checkChanges() {
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
      if (this.hasChanges)
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },

    selected: {
      handler() {
        if (this.selected && this.selected.accessGroupRecords)
          this.selected.accessGroupRecords.forEach((e) => (e.accessGroupId = e.accessGroup.id));

        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.ProjectAccessList.Update);
    },
    errors() {
      let errs = [];
      if (!this.selected) return errs;
      if (this.selected.accessListRecords) {
        // Records with no user selected yet
        if (this.selected.accessListRecords.some((e) => !e.userId))
          errs.push("Some Individuals records has no user selected!");

        var accesslistRecords = this.selected.accessListRecords
          .filter((e) => e.userId)
          .map((e) => e.userId);
        if (new Set([...accesslistRecords]).size != accesslistRecords.length)
          errs.push("Individuals Access can't be duplicated!");
      }
      if (this.selected.accessGroupRecords) {
        if (this.selected.accessGroupRecords.some((e) => !e.accessGroupId))
          errs.push("Some Groups Access has no group selected!");
        var groupsIds = this.selected.accessGroupRecords
          .filter((e) => e.accessGroupId)
          .map((e) => e.accessGroup.id);
        if (new Set([...groupsIds]).size != groupsIds.length)
          errs.push("Groups Access can't be duplicated!");
      }
      return errs;
    },
  },
  components: { UserAllSelector, AccessGroupsSelector },
};
</script>
