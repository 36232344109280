<template>
  <div
    class="user-avatar"
    :class="{
      'user-avatar-mini': mini,
      'open-as-link': allowOpenUserLink,
      'user-avatar-elevated': elevated,
    }"
    v-if="user != null && user.id != null"
    v-on="allowOpenUserLink ? { click: openUserInNewTab } : {}"
  >
    <v-tooltip
      :top="!tooltipBottom"
      :bottom="tooltipBottom"
      :nudge-top="tooltipBottom ? 5 : -8"
      :disabled="!icon || noTooltip"
      key="user-circle-icon"
      z-index="100"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge color="orange darken-1" overlap :value="user.isSuspended || false">
          <template v-slot:badge>
            <i class="fas fa-ban fs-10px secondary--text"></i>
          </template>
          <div
            v-bind="attrs"
            v-on="on"
            class="user-circle"
            :style="{
              color: user.isSuspended && maskSuspendedUsers ? avatarColor.fg : avatarColor.fg,
              backgroundColor:
                user.isSuspended && maskSuspendedUsers ? colors.shades.white : avatarColor.bg,
            }"
          >
            <img
              v-if="user.avatarUrl || (user.isSuspended && maskSuspendedUsers)"
              :alt="fullName"
              :src="avatar"
              class="user-image"
            />
            <span
              v-if="!user.isSuspended || !maskSuspendedUsers"
              class="user-initials"
              v-text="initials"
            ></span>
            <span v-if="user.isExternalUser" class="user-external-icon">
              <i class="fas fa-circle white--text"></i>
              <i class="fas fa-circle-star light-blue--text text--darken-4"></i>
            </span>
          </div>
        </v-badge>
      </template>
      <span>{{ fullName }}</span>
    </v-tooltip>
    <span v-if="!icon" class="user-name-role" key="user-text">
      <span
        class="user-fullName"
        :class="{
          'text--secondary font-italic': user.isSuspended && !maskSuspendedUsers,
        }"
        v-text="fullName"
      ></span>
      <span class="d-flex algin-center" style="margin-top: 0.15rem">
        <span
          v-if="!hideLabor && user.defaultLaborType != 0 && user.defaultLaborType != null"
          class="user-labor"
          :class="{
            'text--secondary font-italic': user.isSuspended && !maskSuspendedUsers,
          }"
        >
          <i class="fad fa-wrench-simple fs-10px"></i>
          {{ getEnumMember(enums.LABOR_TYPE_LIST, user.defaultLaborType).text }}
        </span>
        <span v-if="role && !hideRoles" class="user-role-sep">
          <i class="fal fa-slash-back fs-10px fa-flip-vertical mx-1"></i>
        </span>
        <span v-if="role && !hideRoles" class="user-role">
          <i class="fad fa-key fs-10px"></i>
          {{ role }}
        </span>
      </span>
    </span>
  </div>
  <div
    class="user-avatar"
    :class="{ 'user-avatar-mini': mini }"
    v-else-if="user == null && showNoUser"
  >
    <div
      class="user-circle"
      :style="{
        color: noUserAvatarColor.fg,
        backgroundColor: noUserAvatarColor.bg,
      }"
    >
      <span class="user-initials">
        <i
          :class="noUserIcon == null || noUserIcon == '' ? 'fad fa-user-alt-slash' : noUserIcon"
        ></i>
      </span>
    </div>
    <span v-if="!icon" class="user-name-role">
      <span
        class="user-fullName text--secondary"
        v-text="noUserText == null || noUserText == '' ? 'No User' : noUserText"
      ></span>
    </span>
  </div>
</template>

<script>
import colors from "vuetify/lib/util/colors";
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
export default {
  data() {
    return {
      perms,
      enums,
      colors,
      colorCombinations: [
        { fg: colors.shades.white, bg: colors.red.base },
        { fg: colors.shades.white, bg: colors.pink.base },
        { fg: colors.shades.white, bg: colors.purple.base },
        { fg: colors.shades.white, bg: colors.deepPurple.base },
        { fg: colors.shades.white, bg: colors.indigo.base },
        { fg: colors.shades.white, bg: colors.blue.base },
        { fg: colors.shades.black, bg: colors.lightBlue.base },
        { fg: colors.shades.black, bg: colors.cyan.base },
        { fg: colors.shades.black, bg: colors.teal.base },
        { fg: colors.shades.black, bg: colors.green.base },
        { fg: colors.shades.black, bg: colors.lightGreen.base },
        { fg: colors.shades.black, bg: colors.lime.base },
        { fg: colors.shades.black, bg: colors.yellow.base },
        { fg: colors.shades.black, bg: colors.amber.base },
        { fg: colors.shades.black, bg: colors.orange.base },
        { fg: colors.shades.white, bg: colors.deepOrange.base },
        { fg: colors.shades.white, bg: colors.brown.base },
        { fg: colors.shades.white, bg: colors.blueGrey.base },
        { fg: colors.shades.white, bg: colors.grey.darken4 },
      ],
    };
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    elevated: {
      type: Boolean,
      default: false,
    },
    hideRoles: {
      type: Boolean,
      default: false,
    },
    hideLabor: {
      type: Boolean,
      default: false,
    },
    disableOpenLink: {
      type: Boolean,
      default: false,
    },
    showNoUser: {
      type: Boolean,
      default: false,
    },
    noUserText: {
      type: String,
      default: null,
    },
    noUserIcon: {
      type: String,
      default: null,
    },
    tooltipBottom: {
      type: Boolean,
      default: false,
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    maskSuspendedUsers() {
      return this.$store.getters.personalSettings.maskSuspendedUsers;
    },
    avatar() {
      if (this.user.isSuspended && this.maskSuspendedUsers) return "/img/DNA_Symbol.png";
      return this.user.avatarUrl;
    },
    initials() {
      if (this.user.isSuspended && this.maskSuspendedUsers) return "NA";
      return this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
    },
    fullName() {
      if (this.user.isSuspended && this.maskSuspendedUsers) return "Previous User";
      return this.user.firstName + " " + this.user.lastName;
    },
    role() {
      if (this.user.roles && this.user.roles.length > 0) return this.user.roles[0].name;
      else return null;
    },
    avatarColor() {
      if (this.user == null) return this.colorCombinations[this.colorCombinations.length - 1];
      else if (Number.isInteger(this.user.id))
        return this.toColorHex(this.user.id + this.user.firstName);
      return this.toColorHex(this.user.id);
    },
    noUserAvatarColor() {
      return { fg: colors.blueGrey.base, bg: colors.blueGrey.lighten5 };
    },
    allowOpenUserLink() {
      return !this.disableOpenLink && this.$has(this.perms.DNAUsers.View);
    },
  },
  methods: {
    toColorHex(txt) {
      let sum = 0;
      for (let j = 0; j < txt.length; j++) {
        sum += txt.charCodeAt(j);
      }
      let ascii = sum;
      let colorTest = ascii % this.colorCombinations.length; // ths is the make sure that the colors are based on sum
      return this.colorCombinations[colorTest];
    },
    openUserInNewTab(e) {
      e.stopPropagation();
      const path = `/users/${this.user.id}`;
      window.open(path, "_blank");
    },
  },
};
</script>

<style lang="scss">
.user-avatar {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  cursor: default;
  padding: 0.25rem;

  &.open-as-link {
    border-radius: 0.25rem;
    transition: all 0.15s ease-out;
    cursor: pointer;

    &:hover {
      background: rgba($info-base, 0.16);
    }
  }

  .user-circle {
    width: 28px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    // overflow: hidden;
    position: relative;
    user-select: none;
    flex: none;

    .user-external-icon {
      display: flex;
      position: absolute;
      right: 12px;
      top: -4px;
      font-size: 12px;

      i {
        position: absolute;
      }

      i.fa-circle {
        font-size: 14px;
        left: -1px;
        top: -1px;
      }
    }
  }

  &.user-avatar-mini {
    padding: 0.15rem 0 !important;
    gap: 0.5rem;

    .user-circle {
      width: 22px;
      height: 22px;

      .user-initials {
        font-weight: 600 !important;
        font-size: 9px !important;
        line-height: 0;
        font-size: 11px;
      }
    }

    .user-fullName {
      font-size: 13px;
    }
  }

  &.user-avatar-elevated {
    .user-circle {
      box-shadow: 2px 2px 6px rgba($shades-black, 0.4);
    }
  }

  &.user-avatar-elevated {
    .user-circle {
      box-shadow: 2px 2px 6px rgba($shades-black, 0.4);
    }
  }

  img.user-image {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    object-fit: cover;
    position: absolute;
    left: -1px;
    top: -1px;
    border-radius: 28px;
    background: #fff;
  }

  span.user-initials {
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  span.user-name-role {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }

  span.user-fullName {
    font-weight: 600;
    font-size: 14px;
    color: rgba($color: $shades-black, $alpha: 0.87);
    line-height: 1;
  }
  span.user-labor {
    font-weight: 700;
    font-size: 11px;
    color: rgba($color: $shades-black, $alpha: 0.64);
    line-height: 1;

    i {
      margin-right: 2px;
    }
  }

  span.user-role {
    font-weight: 600;
    font-size: 11px;
    color: var(--v-success-base);
    line-height: 1;

    i {
      margin-right: 2px;
    }
  }
  span.user-role-sep {
    font-weight: 600;
    font-size: 11px;
    line-height: 1;
  }
}
.user-group {
  position: relative;
}
</style>
