import Api from "../../Shared/services/api";

const baseUrl = "spaces";
export default {
  get() {
    return Api().get(baseUrl);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  getCreatedByUsers(projectId, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(
      `${baseUrl}/Projects/${projectId}/Spaces/filter/CreatedBy` + "?" + qParams.toString()
    );
  },
  getUpdatedByUsers(projectId, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(
      `${baseUrl}/Projects/${projectId}/Spaces/filter/UpdatedBy` + "?" + qParams.toString()
    );
  },
  getSpaceTemplates(projectId, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(
      `${baseUrl}/Projects/${projectId}/Spaces/filter/SpaceTemplates` + "?" + qParams.toString()
    );
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  bulkDeleteSpaces(listIds) {
    return Api().post(`${baseUrl}/BulkDelete`, listIds);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  getByEquipmentId(equipmentId, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + `/Equipment/${equipmentId}` + "?" + qParams.toString());
  },
  getSpacesByTemplateId(id, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + `/Template/${id}` + "?" + qParams.toString());
  },
  getSpacesByEquipmentId(id, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + `/Equipment/${id}` + "?" + qParams.toString());
  },
  getSpaceById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  getBuildingsAndLevels(projectId) {
    return Api().get(`${baseUrl}/BuildingsAndLevels/Project/${projectId}`);
  },
  getFilterItems(projectId, filter) {
    return Api().get(`${baseUrl}/Projects/${projectId}/Spaces/filter/${filter}`);
  },
  checkIfSpaceNameExist({ name, projectId }) {
    return Api().get(`${baseUrl}/Project/${projectId}/Space/Name/Exist?spaceName=${name}`);
  },
  checkIfSpaceNumberExist({ spaceNumber, projectId, level }) {
    return Api().get(
      `${baseUrl}/Project/${projectId}/Space/Number/Exist?spaceNumber=${spaceNumber}&levelId=${level.id}`
    );
  },
  cloneSpace({ name, imageUrl, spaceId, spaceNumber, level, projectId }) {
    return Api().post(`${baseUrl}/${spaceId}/Clone`, {
      name,
      imageUrl,
      spaceNumber,
      projectId,
      levelId: level ? level.id : null,
    });
  },
  cloneSpaceFromAnotherProject(targetProjectId, { name, imageUrl, spaceId, spaceNumber, level }) {
    return Api().post(`${baseUrl}/${spaceId}/CloneToProject`, {
      name,
      imageUrl,
      spaceNumber,
      projectId: targetProjectId,
      levelId: level ? level.id : null,
    });
  },
  getMasterQuoteBySpaceId(options, spaceId) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/${spaceId}/MasterQuotes?${qParams.toString()}`);
  },
  suggestedComplexity(spaceId) {
    return Api().post(`${baseUrl}/${spaceId}/SuggestedComplexity`);
  },
  getActivityLog(id, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + `/${id}/ActivityLogs?` + qParams.toString());
  },
  addActivityLog(id, note) {
    return Api().post(baseUrl + `/${id}/ActivityLogs`, {
      notes: note,
      type: 2,
    });
  },
  getFirstAndLastActivityLogInRange(id, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + `/${id}/GetFirstAndLastActivityLogInRange?` + qParams.toString());
  },
  getAreaGroupsBySpaceId(spaceId) {
    return Api().get(`${baseUrl}/${spaceId}/AreaGroups`);
  },
  moveAreaGroupEquipmentToAnotherSpace(data) {
    return Api().post(`${baseUrl}/MoveAreaGroupEquipmentToAnotherSpace`, data);
  },
};
