<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="ContractOrigins"
    :total="total"
    :isLoading="isLoading"
    :openOnMount="openOnMount"
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    itemValue="value"
    itemText="text"
  >
    <template v-slot:item="{ item }">
      <contract-origin :origin="item.value"></contract-origin>
    </template>
  </filter-base-menu>
</template>

<script>
import { ContractOriginFilterSettings } from "../FilterSettings";
import enums from "../../../../../plugins/enums";
import FilterBaseMenu from "../FilterBaseMenu.vue";
import ContractOrigin from "../../../../Contracts/components/ContractOrigin.vue";

export default {
  name: "contract-type-filter",
  components: { FilterBaseMenu, ContractOrigin },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new ContractOriginFilterSettings(),
    },
    openOnMount: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ContractOrigins() {
      return this.$options.filters.EnumToList(enums.ContractOrigin, true);
    },
    total() {
      return this.ContractOrigins.length;
    },
  },
  data() {
    return {
      entities: [],
      selectedItems: [],
      isLoading: false,
      initiallyLoaded: false,
    };
  },
  methods: {
    onShow() {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    },
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
  },
};
</script>
