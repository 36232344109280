<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title title="MS Teams" show-subtitle subtitle="Browse DNA MS Teams!" :badge="total" />
    <v-row class="mb-1" justify="space-between">
      <v-col cols="12" md="auto" class="d-flex align-center pt-0"></v-col>
      <v-col cols="12" md="auto" class="pt-0">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="isMSTeamsListLoading"
          >
          </v-text-field>
          <refresh :loading="isMSTeamsListLoading" @refresh="getMSTeamsList()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      class="elevation-2 ms-teams-table table-sticky-header-exclude-footer"
      dense
      :headers="headers"
      :items="msTeamsListFiltered"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="isMSTeamsListLoading"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <h4>{{ item.name }}</h4>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <p v-if="item.description != null" class="fs-12px ma-0">
          {{ item.description }}
        </p>
      </template>

      <template v-slot:[`item.visibility`]="{ item }">
        <ms-team-visibility small :visibility="item.visibility"></ms-team-visibility>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template #loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <view-ms-team ref="viewMSTeam"></view-ms-team>
  </v-container>
</template>

<script>
import msTeamsAPI from "../../../Projects/services/ms-teams-service";
import MsTeamVisibility from "../../../Projects/components/MsTeamVisibility.vue";
import PageTitle from "../../../Shared/components/PageTitle.vue";
import ViewMsTeam from "../components/ViewMsTeam.vue";
import msTeamsHeaders from "../configs/ms-teams.header";
import perms from "../../../../plugins/permissions";
import enums from "../../../../plugins/enums";

export default {
  components: {
    PageTitle,
    ViewMsTeam,
    MsTeamVisibility,
  },
  data() {
    return {
      enums,
      perms,
      total: 0,
      mainSearchInFocus: false,
      headers: msTeamsHeaders,
      msTeamsList: [],
      isMSTeamsListLoading: false,
      options: {
        itemsPerPage: 30,
        search: null,
        sortBy: ["name"],
        sortDesc: [false],
      },
    };
  },
  computed: {
    msTeamsListFiltered() {
      if (this.options.search != null && this.options.search.trim() != "") {
        let searchTerm = this.options.search.toLowerCase();
        return this.msTeamsList.filter((team) => team.name.toLowerCase().indexOf(searchTerm) > -1);
      } else return this.msTeamsList;
    },
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.getMSTeamsList();
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    getMSTeamsList() {
      this.isMSTeamsListLoading = true;
      msTeamsAPI
        .teamsUnfiltered()
        .then((resp) => {
          this.msTeamsList = resp.data.teams;
          this.total = resp.data.length;
          this.isMSTeamsListLoading = false;
        })
        .catch((err) => {
          this.isMSTeamsListLoading = false;
          this.$handleError(err);
        });
    },
    wrapAvatarUrl(url) {
      return msTeamsAPI.getAvatarWrapperUrl(url);
    },
    view(msTeam) {
      this.$refs.viewMSTeam.open(msTeam);
    },
    rowClicked(row) {
      this.view(row);
    },
  },
};
</script>
<style lang="scss">
.ms-teams-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
