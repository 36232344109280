<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 1250"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching User Default Labor Rates...
      </h3>
      <h3 v-else-if="selected != null" class="font-weight-bold pa-1" small>
        <i class="fad fa-user-tie mr-2"></i> User Default Labor Rates for:
        <b>{{ selected.user.firstName }} {{ selected.user.lastName }}</b>
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching User Default Labor Rates ...</p>
    </v-container>

    <v-container fluid class="pa-5" v-else-if="selected != null">
      <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
        <v-form v-model="slideouts.update.valid" ref="settingsForm">
          <v-row>
            <v-col cols="12" md="12" class="py-1 d-flex align-center justify-space-between">
              <h3 class="font-weight-black">
                <u>{{ selected.user.firstName }} {{ selected.user.lastName }}</u> Default Labor
                Rates
              </h3>
              <v-menu dense offset-y right v-if="!readonly" z-index="99999">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :dark="!readonly"
                    color="indigo"
                    style="flex: none !important"
                    :disabled="readonly"
                  >
                    <i class="fad fa-pen mr-2" style="font-size: 16px"></i>
                    Update Options
                  </v-btn>
                </template>

                <v-list class="more-options-menu">
                  <v-list-item @click="syncLaborLaborRates">
                    <v-list-item-icon class="mr-2 justify-center">
                      <i class="fad fa-clone"></i>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        Copy User Labor <b>Cost</b> Rates from <u>DNA Defaults</u>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="syncLaborSellRates">
                    <v-list-item-icon class="mr-2 justify-center">
                      <i class="fad fa-clone"></i>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        Copy User Labor <b>Sell</b> Rates from <u>DNA Defaults</u>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-simple-table dense class="elevation-1">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th class="text-start" style="width: 175px; min-width: 175px">Setting</th>
                      <th class="text-start">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4">
                        <h2 class="font-weight-black py-3">Labor Cost Rates</h2>
                      </td>
                    </tr>
                    <tr>
                      <td><h4 class="font-weight-bold">Associate Consultant</h4></td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.ac"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.ac }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Senior Consultants</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.sc"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.sc }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Consultants</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.c"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.c }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Virtual Integrator</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.vi"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.vi }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">BIM Specialist</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.bim"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.bim }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Management</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.management"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.management }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Technical</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.technical"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.technical }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">SCD</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.scd"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.scd }}</div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="6">
              <v-simple-table dense class="elevation-1">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th class="text-start" style="width: 175px; min-width: 175px">Setting</th>
                      <th class="text-start">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4">
                        <h2 class="font-weight-black py-3">Labor Sell Rates</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Associate Consultant</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.ac"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.ac }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Senior Consultants</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.sc"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.sc }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Consultants</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.c"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.c }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Virtual Integrator</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.vi"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.vi }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">BIM Specialist</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.bim"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.bim }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Management</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.management"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.management }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Technical</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.technical"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.technical }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">SCD</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.scd"
                          prefix="$"
                          suffix="/hr"
                          :readonly="readonly || !canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.scd }}</div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex ml-2" v-if="!isFetching && readonly && canEdit">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :loading="slideouts.update.isLoading"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
              >
                <i class="fas fa-save mr-2"></i>
                Save
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Save <kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                Save & Close
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Save & Close Panel<kbd class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchUserRates()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch User Rates</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import ApiService from "../services/UserSettingsService";
import dnaDefaultsService from "../../DNADefaults/services/dnaDefaults-service";

export default {
  name: "labor-rates",
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enums,
      perms,
      hasChanges: false,
      userId: null,
      readonly: false,
      isFetching: false,
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          valid: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
      selected: null,
      selectedCemented: null,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.$has(this.perms.UserRates.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode();
      }
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = null;
      this.cementUserRates();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    fetchUserRates() {
      this.isFetching = true;
      ApiService.getLaborRates(this.userId)
        .then((resp) => {
          this.$log("getTemplateById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementUserRates();
        })
        .catch((err) => {
          this.$log("getTemplateById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      this.$log("update >> toSend", toSend);
      ApiService.UpdateLaborRates(this.userId, toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "User rates updated successfully!";
          this.$log(">>> updated", resp.data);
          this.selected = resp.data;
          this.cementUserRates();
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    cementUserRates() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
      this.selected = null;
      this.cementUserRates();
    },
    open(id, editMode = false) {
      this.userId = id;
      this.readonly = !editMode;
      this.fetchUserRates();
      this.slideouts.update.active = true;
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.settingsForm, () => {})) return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    syncLaborLaborRates(userRate) {
      this.$dialog.info({
        text: `Are you sure you want to sync company default labor cost rates?`,
        title: `Sync from Company Labor Cost Rates?`,
        color: "info",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Sync",
            color: "info",
            handle: () => {
              return dnaDefaultsService
                .getLaborRates()
                .then((resp) => {
                  this.selected.loadedLaborRates = this.cloneDeep(resp.data.loadedLaborRates);
                  this.$dialog.notify.success("Labor Cost Rates Synced Successfully!", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  var message = "an error occurred during syncing cost rates!";
                  this.$handleError(err, message);
                });
            },
          },
        },
      });
    },
    syncLaborSellRates() {
      this.$dialog.info({
        text: `Are you sure you want to sync company default labor sell rates?`,
        title: `Sync from Company Labor Sell Rates?`,
        color: "info",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Sync",
            color: "info",
            handle: () => {
              return dnaDefaultsService
                .getLaborRates()
                .then((resp) => {
                  this.selected.sellRates = this.cloneDeep(resp.data.sellRates);
                  this.$dialog.notify.success("Labor Sell Rates Synced Successfully!", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  var message = "an error occurred during syncing sell rates!";
                  this.$handleError(err, message);
                });
            },
          },
        },
      });
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
        this.$emit("change", this.selected);
      },
      deep: true,
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.DNAUsers.UpdateDefaultLaborRates);
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "800px";
    },
  },
  components: { PanelSizeControl },
};
</script>
