<template>
  <span v-if="category != null && downloadTaskCategoryObj != null">
    <v-chip
      v-if="chip"
      class="download-task-category px-2"
      x-small
      :color="'secondary'"
      :text-color="'white'"
    >
      <i class="fas mr-2" :class="downloadTaskCategoryObj.icon"></i>
      <span class="font-weight-medium">{{ downloadTaskCategoryObj.text }}</span>
    </v-chip>
    <span v-else style="opacity: 0.87" class="d-flex align-center font-weight-medium fs-12px">
      <i class="fad mr-2" style="width: 12px" :class="downloadTaskCategoryObj.icon"></i>
      <span>{{ downloadTaskCategoryObj.text }}</span>
    </span>
  </span>
</template>

<script>
import enums from "../../../plugins/enums";
export default {
  name: "download-task-category",
  data() {
    return {
      downloadTaskCategories: enums.DownloadTaskCategory,
      downloadTaskCategoryObj: null,
    };
  },
  props: {
    category: {
      type: Number,
      default: null,
    },
    chip: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateDownloadTypeObj();
  },
  computed: {},
  methods: {
    updateDownloadTypeObj() {
      if (this.category != null)
        this.downloadTaskCategoryObj = this.getEnumMember(
          this.downloadTaskCategories,
          this.category
        );
    },
  },
  watch: {
    category: {
      handler() {
        this.updateDownloadTypeObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
