export default [
  {
    text: "Actions",
    value: "actions",
    cellClass: "px-2",
    class: "px-2",
    align: "left",
    sortable: false,
    width: "64px",
    hidable: false,
    hidden: false,
  },
  {
    text: "Id",
    value: "id",
    width: "64px",
    cellClass: "px-1",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Name",
    value: "name",
    width: "250px",
    cellClass: "px-1",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Status",
    value: "state",
    width: "200px",
    cellClass: "px-1",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Source User",
    value: "sourceUser",
    width: "200px",
    cellClass: "px-1",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Target User",
    value: "targetUser",
    width: "200px",
    cellClass: "px-1",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Target Decision Date",
    value: "targetProcessedDate",
    width: "164px",
    cellClass: "px-1",
    class: "px-1",
    hidable: true,
    hidden: false,
  },
  {
    text: "Management User",
    value: "managementUser",
    width: "200px",
    cellClass: "px-1",
    class: "px-1",
    hidable: false,
    hidden: false,
  },
  {
    text: "Management Decision Date",
    value: "managementProcessedDate",
    width: "200px",
    cellClass: "px-1",
    class: "px-1",
    hidable: true,
    hidden: false,
  },
  {
    text: "Created By",
    value: "createdBy",
    width: "100px",
    cellClass: "px-1",
    class: "px-1",
    hidable: true,
    hidden: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "100px",
    cellClass: "px-1",
    class: "px-1",
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "200px",
    cellClass: "px-1",
    class: "px-1",
    hidable: true,
    hidden: false,
  },
];
