<template>
  <v-container fluid class="pa-5" style="overflow: hidden; height: 100%">
    <div
      style="background: #eceff1; border-radius: 0.5rem; overflow: hidden; height: 100%"
      class="pa-5 inset-shadow d-flex flex-column"
    >
      <v-row class="mb-0" justify="space-between" align="center">
        <v-col sm="12" md="6" cols="12" class="d-flex align-center" style="gap: 0.5rem">
          <h3 class="text--primary font-weight-black d-inline-block">
            WireCAD Equipment Integration Requests
          </h3>
          <v-btn
            color="deep-purple"
            dark
            @click="createEquipmentRequest"
            v-if="$has(perms.WireCadIntegrationRequests.Create)"
          >
            <i class="far fa-plus mr-2"></i>New Request
          </v-btn>
        </v-col>
        <v-col
          md="6"
          sm="12"
          cols="12"
          class="d-flex flex-row align-center justify-end flex-wrap"
          style="gap: 0.5rem"
        >
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            hide-details
            clearable
            prepend-inner-icon="far fa-search"
            :loading="loading"
          >
          </v-text-field>
          <refresh :loading="loading" @refresh="getDataDebounced()"></refresh>
        </v-col>
      </v-row>
      <v-data-table
        style="
          overflow: auto;
          overflow: overlay;
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        "
        dense
        :headers="tableHeader"
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        @click:row="rowClicked"
        class="elevation-2 integration-requests-table table-sticky-header-exclude-footer"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'far fa-arrow-to-left',
          lastIcon: 'far fa-arrow-to-right',
          prevIcon: 'far fa-angle-left',
          nextIcon: 'far fa-angle-right',
          itemsPerPageOptions: [15, 30, 50, 100],
        }"
      >
        <template v-slot:progress>
          <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
        </template>

        <template v-slot:[`item.id`]="{ item }">
          <code
            class="mono-font ml-2 secondary--text fs-12px blue-grey lighten-5"
            style="flex: none"
          >
            #{{ item.id }}
          </code>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span class="font-weight-bold">{{ item.name }}</span>
        </template>

        <template v-slot:[`item.state`]="{ item }">
          <integration-request-state small :state="item.state"></integration-request-state>
        </template>

        <template v-slot:[`item.decidedBy`]="{ item }">
          <user-avatar
            :user="item.decidedBy"
            hide-roles
            disable-open-link
            show-no-user
            no-user-text="None"
          ></user-avatar>
        </template>

        <template v-slot:[`item.decidedDate`]="{ item }">
          <dater :date="item.decidedDate" date-only></dater>
        </template>

        <template v-slot:[`item.createdBy`]="{ item }">
          <user-avatar :user="item.createdBy" icon></user-avatar>
        </template>

        <template v-slot:[`item.updatedBy`]="{ item }">
          <user-avatar :user="item.updatedBy" icon></user-avatar>
        </template>

        <template v-slot:[`item.createDate`]="{ item }">
          <dater :date="item.createDate" date-only></dater>
        </template>

        <template v-slot:[`item.updateDate`]="{ item }">
          <dater :date="item.updateDate" date-only></dater>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu dense offset-x right>
            <template v-slot:activator="{ attrs, on }">
              <v-btn icon elevation="0" v-bind="attrs" v-on="on">
                <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <v-list class="more-options-menu">
              <v-list-item @click="view(item)" v-if="$has(perms.WireCadIntegrationRequests.Update)">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small class="secondary--text">far fa-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="secondary--text">View / Update</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="del(item)"
                v-if="
                  $has(perms.WireCadIntegrationRequests.Delete) &&
                  item.state == enums.WireCadIntegrationRequestState.Draft.value
                "
              >
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-trash-alt red--text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="red--text">Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:no-data>
          <img width="500" src="/img/art/fogg-no-connection-2.png" />
          <p class="font-weight-bold">No Data Available!</p>
        </template>
        <template v-slot:loading>
          <video width="250" muted loop autoplay style="padding: 0.5rem">
            <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
          </video>
          <p class="font-weight-bold">Searching the Cosmos...</p>
        </template>
      </v-data-table>
    </div>
    <create-wire-cad-equipment-request
      ref="createRequest"
      @save="onRequestSave"
    ></create-wire-cad-equipment-request>
    <edit-wire-cad-equipment-request
      @save="onRequestSave"
      @sent="onRequestSent"
      @approve="onRequestApprove"
      @reject="onRequestReject"
      ref="editRequest"
    ></edit-wire-cad-equipment-request>
  </v-container>
</template>

<script>
import CreateWireCadEquipmentRequest from "../components/CreateWireCadEquipmentRequest.vue";
import EditWireCadEquipmentRequest from "../components/EditWireCadEquipmentRequest.vue";
import API from "../services/wirecad-equipment-service";
import headers from "../configs/equipment-requests.header";
import perms from "../../../../../plugins/permissions";
import enums from "../../../../../plugins/enums";
import IntegrationRequestState from "../../Shared/components/IntegrationRequestState.vue";

export default {
  components: {
    CreateWireCadEquipmentRequest,
    EditWireCadEquipmentRequest,
    IntegrationRequestState,
  },
  data() {
    return {
      perms,
      enums,
      headers,
      entities: [],
      defaultHeaders: [],
      total: 0,
      valid: false,
      loading: false,
      storageKey: "WireCadEquipmentIntegrationRequests",
      wireCadEquipmentTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        state: [],
        createdByIds: [],
        updatedByIds: [],
      },
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    createEquipmentRequest() {
      this.$refs.createRequest.open();
    },
    rowClicked(request) {
      this.openRequest(request);
    },
    view(request) {
      this.openRequest(request);
    },
    openRequest(request) {
      this.$refs.editRequest.open(request.id);
    },
    onRequestSave() {
      this.getData();
    },
    onRequestSent() {
      this.getData();
    },
    onRequestApprove() {
      this.getData();
    },
    onRequestReject() {
      this.getData();
    },
    getDataDebounced() {
      this.$log("getDataDebounced");
      if (this.wireCadEquipmentTimerId == null) {
        this.$log("START wireCadEquipmentTimerId");
        this.wireCadEquipmentTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.wireCadEquipmentTimerId);

      // delay new call 400ms
      this.wireCadEquipmentTimerId = setTimeout(() => {
        this.$log("wireCadEquipmentTimerId");
        this.getData();
      }, 400);
    },
    getData() {
      this.$log("getData()");
      this.loading = true;
      this.$backToTop(
        0,
        document.querySelector(".integration-requests-table .v-data-table__wrapper")
      );
      API.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$handleError(err);
        });
    },
    onEquipmentIntegrationRequestDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    del(request) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this integration request?`,
          title: `Delete Equipment Integration Request?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return API.delete(request.id)
                  .then(() => {
                    this.onEquipmentIntegrationRequestDeleted(request.id);
                    this.$dialog.notify.success("Request deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch(() => {
                    this.$dialog.notify.error("An error occurred during deleting the request!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
  },
};
</script>

<style lang="scss">
.integration-requests-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
.wire-cad-card {
  overflow: hidden;
  transition: all 0.3s ease-out;
  border: 1.5px solid white;
  display: flex;
  flex-direction: row;
  border-radius: 5rem 2.5rem 2.5rem 5rem !important;
  border-radius: 5rem !important;

  .wire-cad-card-icon {
    width: 70px;
    height: 70px;
    // margin: 0 auto;
    // margin-top: 1rem;
    margin: 0.5rem 1rem 0.5rem 0.5rem;
    border-radius: 70px !important;
    background: rgba($shades-black, 0.06);
    color: rgba($shades-black, 0.87);
    border: 1px dashed rgba($shades-black, 0) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }

  .wire-cad-card-icon-ghost {
    position: absolute;
    // right: 1rem;
    // top: 1rem;
    // font-size: 10rem;
    right: 1rem;
    top: -1rem;
    font-size: 8rem;
    opacity: 0.03;
    user-select: none;
    pointer-events: none;
    z-index: 0;
    transition: all 0.3s ease-out;
  }

  .v-card__title,
  .v-card__subtitle {
    transition: all 0.3s ease-out;
    margin: 0;
    padding: 0;
  }

  .v-card__title {
    font-weight: 800;
  }

  .v-card__subtitle {
    color: rgba($shades-black, 0.54);
  }

  .card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  kbd {
    border: 1px solid $shades-black;
    background: transparent !important;
    color: $shades-black !important;
    font-weight: 600;
    transition: all 0.3s ease-out;
  }

  &:hover,
  &:focus {
    border-color: rgba($info-base, 0.54) !important;
    background: var(--v-info-base) !important;
    // color: #fff;

    &:before {
      opacity: 0 !important;
    }

    kbd {
      background: $shades-black !important;
      border: 1px solid $shades-black;
      color: #fff !important;
      font-weight: 600;
    }

    .v-card__title {
      color: #fff;
    }

    .v-card__subtitle {
      color: rgba(#fff, 1);
    }

    .wire-cad-card-icon {
      background: rgba($info-base, 0.08) !important;
      border-color: rgba($info-base, 0.54) !important;
      color: rgba($info-base, 1);

      background: rgba(#fff, 0.2) !important;
      border-color: rgba(#fff, 0.54) !important;
      color: #fff;
    }

    .wire-cad-card-icon-ghost {
      font-size: 10rem;
      opacity: 0.16;
      color: rgba($info-base, 1);
      color: #fff;
    }
  }

  .v-card__actions {
    display: none !important;
  }
}
</style>
