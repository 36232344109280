<template>
  <v-container
    fluid
    style="height: 100%; display: flex; flex-direction: column"
    v-if="stage != null"
  >
    <page-title :title="stage.text" :badge="this.total" />
    <v-row class="mt-0 mb-1" justify="space-between" align="center">
      <v-col sm="12" md="5" cols="12" class="d-flex align-start" style="gap: 0.5rem">
        <v-btn
          color="info"
          :to="'/projects/create'"
          v-has="[perms.Projects.Create]"
          v-if="stage.value == enums.PROJECT_STAGE.Opportunity.value"
        >
          <i class="fal fa-plus mr-2"></i>Create Opportunity
        </v-btn>
        <v-sheet height="32" color="white" elevation="2" rounded class="d-flex align-center px-2">
          <v-switch
            v-model="options.showStarredProjectsOnly"
            class="ma-0 pa-0"
            color="orange darken-1"
            hide-details
            dense
          >
            <template v-slot:label>
              <label
                class="v-switch-label d-flex align-center ma-0 mr-1 fs-14px"
                :class="{
                  'orange--text text--darken-2': options.showStarredProjectsOnly,
                  'opacity-87': !options.showStarredProjectsOnly,
                }"
              >
                <!-- <i
                  class="fad fa-star mr-2"
                  :class="{
                    'orange--text text--darken-2': options.showStarredProjectsOnly,
                    'secondary--text': !options.showStarredProjectsOnly,
                  }"
                  small
                ></i> -->
                Starred Project
              </label>
            </template>
          </v-switch>
        </v-sheet>
        <v-sheet height="32" color="white" elevation="2" rounded class="d-flex align-center px-2">
          <v-switch
            v-model="options.showMyProjectsOnly"
            class="ma-0 pa-0"
            color="orange darken-1"
            hide-details
            dense
          >
            <template v-slot:label>
              <label
                class="v-switch-label d-flex align-center ma-0 fs-14px"
                :class="{
                  'orange--text text--darken-2': options.showMyProjectsOnly,
                  'opacity-87': !options.showMyProjectsOnly,
                }"
                >My Projects
              </label>
            </template>
          </v-switch>
        </v-sheet>
      </v-col>
      <v-col
        md="7"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <filter-manager
          ref="filterManager"
          v-model="selectedFilters"
          :options.sync="options"
        ></filter-manager>
        <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
        <export-projects :stage="stage" />
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-sheet elevation="2" height="32" rounded class="d-inline-flex" v-if="false">
          <v-btn-toggle v-model="tableMode" mandatory color="info" dense group>
            <v-btn :value="'list'" color="white" style="border-radius: 4px">
              <v-icon>fal fa-list</v-icon>
            </v-btn>
            <v-btn :value="'cards'" color="white" style="border-radius: 4px">
              <v-icon>fal fa-folders</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          hide-details
          clearable
          prepend-inner-icon="fas fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      v-if="tableMode === 'list'"
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 projects-table table-sticky-header-exclude-footer"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`header.jiraCode`]="{ header }">
        <i :class="header.icon"></i>
        {{ header.text.toUpperCase() }}
      </template>

      <template v-slot:[`header.isStarred`]="{ header }">
        <i :class="header.icon"></i>
      </template>

      <template v-slot:[`item.isStarred`]="{ item }">
        <project-star :starred="item.isStarred" :project-id="item.id" small></project-star>
      </template>

      <template v-slot:[`item.jiraCode`]="{ item }">
        <a
          v-if="item.jiraCode != null && item.jiraCode != ''"
          target="_blank"
          class="jira-external-link"
          @click.stop
          :href="'https://dangeloconsultants.atlassian.net/browse/' + item.jiraCode"
        >
          <span class="icon-wrpr mr-1">
            <i class="fab fa-jira org-icon"></i>
            <i class="far fa-external-link alt-icon"></i>
          </span>
          <span>{{ item.jiraCode }}</span>
        </a>
        <span v-else class="text--disabled">
          <i class="fad fa-unlink"></i>
        </span>
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <div class="my-1">
          <client-card :client="item.client" hide-name square></client-card>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <h4>{{ item.fullName }}</h4>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <project-status :status="item.status" small show-parent></project-status>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.spaceCount`]="{ item }">
        <space-count :count="item.spaceCount" />
      </template>

      <template v-slot:[`item.equipmentCount`]="{ item }">
        <equipment-count :count="item.equipmentCount" />
      </template>

      <template v-slot:[`item.projectComplexity`]="{ item }">
        <span
          class="font-weight-medium"
          :class="item.projectComplexity > 0 ? 'secondary--text' : 'text--disabled'"
        >
          {{ item.projectComplexity }}
        </span>
      </template>

      <template v-slot:[`item.totalMsrpPrice`]="{ item }">
        <div class="project-total">
          <v-tooltip left z-index="999" nudge-left="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-inline-block"
                style="font-size: 14px; font-weight: 600 !important"
              >
                {{ totalMSRPWithInstallCost(item) | usdFormat }}
              </div>
            </template>
            <span>{{ item.name }}</span>
            <v-divider class="my-1 d-block" style="width: 100%" dark></v-divider>
            <span class="d-flex flex-column align-end" style="gap: 0.25rem">
              <span style="font-size: 12px; font-weight: 500 !important">
                <b class="blue--text mr-1" style="font-size: 12px; font-weight: 700 !important">
                  {{ installationFactor(item) }}x
                </b>
                {{ installationCost(item) | usdFormat }}
                <i class="fad fa-wrench ml-1"></i>
              </span>
              <span>
                {{ item.totalMsrpPrice | usdFormat }}
                <i class="fad fa-usd-square ml-1"></i>
              </span>
              <v-divider
                class="my-1 d-block"
                style="width: 100%; border-style: dashed !important"
                dark
              ></v-divider>
              <span style="font-size: 16px; font-weight: 600 !important">
                {{ totalMSRPWithInstallCost(item) | usdFormat }}
              </span>
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.leadAC`]="{ item }">
        <user-avatar :user="item.leadAC" icon showNoUser></user-avatar>
      </template>

      <template v-slot:[`item.leadVI`]="{ item }">
        <user-avatar :user="item.leadVI" icon showNoUser></user-avatar>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="update(item.id)" v-if="$has(perms.Projects.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="openCloneModal(item)" v-if="$has(perms.Projects.Create)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-clone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Clone</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="del(item)" v-if="$has(perms.Projects.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <v-data-iterator
      v-if="tableMode === 'cards'"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
    >
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.id" sm="12" md="6" lg="3">
            <project-card :project="item"></project-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <div class="text-center">
          <img width="500" src="/img/art/fogg-no-connection-2.png" style="border-radius: 1rem" />
          <p class="font-weight-bold">No Data Available!</p>
        </div>
      </template>
      <template v-slot:loading>
        <v-row justify="center">
          <v-col sm="12" md="6" lg="4" class="d-flex flex-column text-center">
            <p class="font-weight-bold">Loading... Please wait</p>
            <v-progress-linear color="info" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <clone-project ref="cloneProjectModal"></clone-project>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import projectsAPI from "../services/projects-service.js";
import ProjectStatus from "../components/ProjectStatus.vue";
import ExportProjects from "../components/ExportProjects.vue";
import Enums from "../../../plugins/enums";
import projectHeader from "../config/tables/project.header";
import CloneProject from "./CloneProject.vue";
import ProjectStar from "./ProjectStar.vue";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  ClientFilterSettings,
  ProjectStatusFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";
import ClientCard from "../../Companies/Clients/components/ClientCard.vue";

export default {
  components: {
    ProjectStatus,
    ExportProjects,
    CloneProject,
    ProjectStar,
    FilterManager,
    ClientCard,
  },
  data() {
    return {
      perms: perms,
      enums: Enums,
      storageKey: "Projects",
      tableMode: "list",
      selectedFilters: [],
      entities: [],
      selected: {},
      total: 0,
      projectsTimerId: null,
      valid: false,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        showStarredProjectsOnly: false,
        showMyProjectsOnly: false,
        sortBy: ["createDate"],
        sortDesc: [true],
        clientIds: [],
        status: [],
        createdByIds: [],
        updatedByIds: [],
      },
      modals: {
        update: false,
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: projectHeader,
    };
  },
  props: {
    stage: {
      type: Object,
      default: null,
    },
  },
  computed: {
    stageStatusList() {
      if (this.stage == null) return [];
      return this.stage.PROJECT_STATUS;
    },
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
    validProjectStatusList() {
      var statusFlat = Enums.PROJECT_STATUS_FLAT_LIST.filter(
        (s) => s.SUB_STATUS == null || s.SUB_STATUS.length == 0
      );
      return this.$options.filters.EnumToList(statusFlat, true);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.pq) this.options.search = this.$route.query.pq;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.$log("mounted", this.$route.query.pq, this.cloneDeep(this.stageStatusList));
    window.addEventListener("popstate", this.checkRouteQuery, false);
    // this.$refs.mainFilter.focus();
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
    window.removeEventListener("popstate", this.checkRouteQuery);
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      var clientFilterSettings = new ClientFilterSettings();

      var projectStatusFilterSettings = new ProjectStatusFilterSettings();
      projectStatusFilterSettings.stage = this.stage;

      this.selectedFilters = [
        createdByFilterSettings,
        updatedByFilterSettings,
        clientFilterSettings,
        projectStatusFilterSettings,
      ];
    },
    openCloneModal(project) {
      this.$refs.cloneProjectModal.open(project);
    },
    installationFactor(project) {
      return project.installationFactor || 0.25;
    },
    installationCost(project) {
      return this.installationFactor(project) * project.totalMsrpPrice;
    },
    totalMSRPWithInstallCost(project) {
      return project.totalMsrpPrice + this.installationCost(project);
    },
    rowClicked(row) {
      this.$log("rowClicked", row);
      this.update(row.id);
    },
    getDataDebounced() {
      if (this.projectsTimerId == null) {
        this.projectsTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectsTimerId);

      // delay new call 400ms
      this.projectsTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    getData() {
      this.loadingStates.table = true;
      var optionsToSend = this.cloneDeep(this.options);
      optionsToSend.stage = this.stage.value;
      this.$log("getData()", this.cloneDeep(optionsToSend));
      this.$backToTop(0, document.querySelector(".projects-table .v-data-table__wrapper"));
      projectsAPI
        .query(this.$clean(optionsToSend, true))
        .then((resp) => {
          if (this.options.search != optionsToSend.search) return;
          this.$log("projectsAPI >>> resp", this.cloneDeep(optionsToSend));
          this.entities = resp.data.items;
          // this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    checkRouteQuery(evt) {
      this.$log("checkRouteQuery", this.$route.query.pq, evt);
      if (this.$route.query.pq) this.options.search = this.$route.query.pq;
      else this.options.search = null;
    },
    updateRouterLink() {
      // this.$log("getDataDebounced", this.options.search);
      if (this.$route.query.pq == this.options.search) return;
      // this.$log("Routing");
      if (this.options.search != null && this.options.search != "") {
        // this.$log("PUSH ROUTE", this.options.search);
        this.$router.push({
          path: this.stage.path,
          query: { pq: this.options.search },
          replace: true,
        });
      } else
        this.$router.push({
          path: this.stage.path,
          replace: true,
        });
    },
    del(project) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this project?`,
          title: `Delete Project?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return projectsAPI
                  .delete(project.id)
                  .then(() => {
                    this.onProjectDeleted(project.id);
                    this.$dialog.notify.success("Project deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch(() => {
                    this.$dialog.notify.error("An error occurred during deleting the project!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onProjectDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    add() {
      this.modals.add = true;
      setTimeout(() => {
        this.$refs.updateForm.resetValidation();
      });
      this.selected = {};
    },
    addConfirmed() {
      projectsAPI
        .add(this.selected)
        .then((resp) => {
          this.modals.add = false;
          var message = "Item updated successfully";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "Item added successfully";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.$handleError(err);
        });
    },
    update(id) {
      // this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      // this.modals.update = true;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          name: `view-project`,
          params: { id: id },
          query: { pq: this.options.search },
        });
      } else {
        this.$router.push({
          name: `view-project`,
          params: { id: id },
        });
      }
    },
    updateConfirmed() {
      projectsAPI
        .update(this.selected)
        .then((resp) => {
          this.modals.update = false;
          var message = "Item updated successfully";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "Item added successfully";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.$handleError(err);
        });
    },
    discard() {
      this.modals.add = false;
      this.modals.update = false;
      this.selected = {};
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.projects-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
  // &.custom-sticky-header {
  //   .v-data-table-header {
  //     top: 40px;
  //   }
  // }
}

.v-list.project-status-filter {
  // .v-list-item {
  //   padding-top: 0.5rem;
  //   padding-bottom: 0.5rem;
  // }
  .v-list-item:not(:last-child) {
    border-bottom: thin solid rgba($shades-black, 0.1);
  }
}
</style>
