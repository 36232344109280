import Api from "../../Shared/services/api";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "equipments";
export default {
  get() {
    return Api().get(`${baseUrl}`);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  typeHeadObj(options) {
    const qParams = buildParams(options);
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  restore(id) {
    return Api().put(baseUrl + `/${id}/restore`);
  },
  getRecent(mode, count) {
    return Api().get(`${baseUrl}/Recent?count=${count}&mode=${mode}`);
  },
  clone(id) {
    return Api().post(baseUrl + `/${id}/Clone`);
  },
  replaceEquipment(data) {
    return Api().post(baseUrl + `/ReplaceEquipment`, data);
  },
  getEquipmentStats(id) {
    return Api().get(baseUrl + `/${id}/Stats`);
  },
  getEquipmentItems(id, options, type) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(`${baseUrl}/${id}/Stats/${type}?${qParams.toString()}`);
  },
  getEquipmentById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  getEquipmentSummaryById(id) {
    return Api().get(baseUrl + `/${id}/Summary`);
  },
  getMakes() {
    return Api().get(baseUrl + `/Makes`);
  },
  getActivityLog(id, options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + `/${id}/ActivityLogs?` + qParams.toString());
  },
  addActivityLog(id, note) {
    return Api().post(baseUrl + `/${id}/ActivityLogs`, {
      notes: note,
      type: 2,
    });
  },
  duplicates(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/Duplicates?" + qParams.toString());
  },
  download(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/download?" + qParams.toString());
  },
  exportExcel(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/Export?" + qParams.toString());
  },
  exportGroups(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/ExportEquipmentGroups?" + qParams.toString());
  },
  exportManufacturerFilledTemplate(manufacturerId) {
    return Api().get(`${baseUrl}/${manufacturerId}/ExportFilledManufactureEquipments`, {
      responseType: "blob",
    });
  },
  ImportManufacturerEquipments(fileData, config) {
    return Api().post(`${baseUrl}/ImportManufacturerEquipments`, fileData, config);
  },
  getEquipmentFiles(id) {
    return Api().get(`${baseUrl}/${id}/Files`);
  },
};
