<template>
  <v-container fluid class="px-3 pt-3 pb-0">
    <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
      <v-form v-model="valid" ref="LeadAccessListForm">
        <div v-if="loading" class="d-flex justify-center align-center">
          <v-progress-circular indeterminate color="info" :width="2"></v-progress-circular>
        </div>
        <div v-else>
          <div class="d-flex align-center">
            <h5
              class="d-inline-block mb-0 mr-2"
              style="font-weight: 700 !important; font-size: 14px"
            >
              Who can Access this Lead?
            </h5>
            <v-radio-group
              v-model="selected.allowAll"
              mandatory
              hide-details
              class="mt-0 pt-0 mr-0"
              row
            >
              <v-radio :disabled="readonly" v-if="canEdit" :value="true" color="info">
                <template v-slot:label>
                  <div
                    class="font-weight-bold fs-14px"
                    :class="selected.allowAll ? 'info--text' : ''"
                  >
                    Everyone
                  </div>
                </template>
              </v-radio>
              <v-radio :disabled="readonly" v-if="canEdit" :value="false" color="info" class="mr-0">
                <template v-slot:label>
                  <div
                    class="font-weight-bold fs-14px"
                    :class="!selected.allowAll ? 'info--text' : ''"
                  >
                    Custom List
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <v-scroll-y-transition mode="out-in">
            <h4
              v-if="currentUserAccessWarning"
              key="warning"
              class="red--text darken-2 mb-3 mt-2 d-flex align-center"
            >
              <i class="fad fa-brake-warning fa-swap-opacity fs-18px mr-2"></i>
              You will loose access to this lead because you're not in the access list!
            </h4>
            <h4
              v-else-if="currentUserAccessWarningDisabled"
              key="warningDisabled"
              class="red--text darken-2 mb-3 d-flex align-center"
            >
              <i class="fad fa-brake-warning fa-swap-opacity fs-18px mr-2"></i>
              You will loose access to this lead because your key is not active!
            </h4>
          </v-scroll-y-transition>
          <div v-if="errors.length > 0">
            <ul>
              <li v-for="(err, i) in errors" :key="i" class="red--text">
                {{ err }}
              </li>
            </ul>
          </div>

          <div v-if="!selected.allowAll">
            <div class="d-flex align-center mt-4">
              <h3 class="text--primary font-weight-black mr-3 d-inline-block">
                Individuals Access
              </h3>
              <v-btn
                color="orange"
                small
                @click="createNewEntry()"
                class="font-weight-bold"
                v-if="!readonly && selected != null && !selected.allowAll && canCreate"
              >
                <i class="far fa-plus mr-2"></i>New
              </v-btn>
            </div>
            <v-data-table
              dense
              :headers="headers"
              :items="selected.leadAccessListRecords"
              :items-per-page="-1"
              class="elevation-2 mt-2"
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:progress>
                <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
              </template>
              <template v-slot:[`item.userId`]="{ item }">
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  hideBorder
                  :readonly="item.id != 0"
                  :isUsersLoading="isUsersLoading"
                  v-model="item.userId"
                  style="width: 100%; z-index: 100"
                  class="ml-0"
                ></user-all-selector>
              </template>
              <template v-slot:[`item.isActive`]="{ item }">
                <v-switch
                  :readonly="readonly"
                  v-if="$has(perms.LeadAccessList.Update)"
                  class="ma-0 pa-0"
                  color="info"
                  hide-details
                  inset
                  dense
                  v-model="item.isActive"
                >
                </v-switch>
              </template>
              <template v-if="canDelete" v-slot:[`item.actions`]="{ item, index }">
                <v-btn
                  :disabled="readonly"
                  icon
                  elevation="0"
                  color="error"
                  @click.stop="del(item, index)"
                >
                  <i class="fal fa-trash-alt"></i>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <img width="300" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>
            </v-data-table>

            <br />
            <div class="d-flex align-center">
              <h3 class="text--primary font-weight-black mr-3 d-inline-block">Groups Access</h3>
              <v-btn
                color="orange"
                small
                @click="createNewGroupEntry()"
                class="font-weight-bold"
                v-if="!readonly && selected != null && !selected.allowAll && canCreate"
              >
                <i class="far fa-plus mr-2"></i>New
              </v-btn>
            </div>
            <!-- Access Groups  -->
            <v-data-table
              dense
              :headers="groupHeaders"
              :items="selected.leadAccessGroupRecords"
              :items-per-page="-1"
              class="elevation-2 mt-2"
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:progress>
                <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
              </template>
              <template v-slot:[`item.isActive`]="{ item }">
                <v-switch
                  :readonly="readonly"
                  v-if="canEdit"
                  class="ma-0 pa-0"
                  color="info"
                  hide-details
                  inset
                  dense
                  v-model="item.isActive"
                >
                </v-switch>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <access-groups-selector
                  :accessGroups="accessGroups"
                  required
                  hideLabel
                  hideDetails
                  hideBorder
                  :readonly="item.id != 0"
                  :isAccessGroupsLoading="isAccessGroupsLoading"
                  v-model="item.accessGroup"
                  style="width: 100%; z-index: 1000"
                  class="ml-0"
                ></access-groups-selector>
              </template>
              <template v-if="canDelete" v-slot:[`item.actions`]="{ item, index }">
                <v-btn
                  :disabled="readonly"
                  icon
                  elevation="0"
                  color="error"
                  @click.stop="delGroup(item, index)"
                >
                  <i class="fal fa-trash-alt"></i>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <img width="300" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import perms from "../../../../plugins/permissions";
import enums from "../../../../plugins/enums";
import leadsAPI from "../../services/leads-service";
import headers from "../../config/tables/LeadAccessList.header";
import groupHeaders from "../../config/tables/LeadAccessGroup.header";
import usersAPI from "../../../Admin/services/StaffService";
import accessGroupsAPI from "../../../AccessGroups/services/access-group-service";
import UserAllSelector from "../../../Shared/components/UserAllSelector.vue";
import AccessGroupsSelector from "../../../Projects/components/AccessGroupSelector.vue";
import { AccessListMixin } from "../../../Shared/Mixins/AccessListMixin";
import { readonly } from "vue";

export default {
  name: "Leads-access-list",
  props: {
    leadId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      perms,
      enums,
      valid: false,
      loading: false,
      saveLoading: false,
      users: [],
      accessGroups: [],
      isUsersLoading: false,
      isAccessGroupsLoading: false,
      selectedCemented: null,
      headers: headers,
      groupHeaders: groupHeaders,
    };
  },
  created() {
    this.getAllUsersData();
    this.getAllGroupsData();
  },
  methods: {
    createNewEntry() {
      this.selected.leadAccessListRecords.push({
        id: 0,
        isActive: true,
        userId: null,
      });
    },
    del(item, index) {
      this.selected.leadAccessListRecords.splice(index, 1);
    },
    createNewGroupEntry() {
      this.selected.leadAccessGroupRecords.push({
        id: 0,
        isActive: true,
        accessGroupId: 0,
      });
    },
    delGroup(item, index) {
      this.selected.leadAccessGroupRecords.splice(index, 1);
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    getAllGroupsData() {
      this.isAccessGroupsLoading = true;
      accessGroupsAPI
        .typeHead(null, true)
        .then((resp) => {
          this.accessGroups = resp.data;
          this.isAccessGroupsLoading = false;
        })
        .catch(() => {
          this.isAccessGroupsLoading = false;
        });
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected.leadAccessGroupRecords != null) {
          this.selected.leadAccessGroupRecords.forEach(
            (e) => (e.accessGroupId = e.accessGroupId || e.accessGroup?.id)
          );
        }
        this.$emit("change", this.selected);
      },
      deep: true,
    },
    valid() {
      this.$emit("valid-changes", this.valid);
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.LeadAccessList.Update);
    },
    canDelete() {
      return this.$has(perms.LeadAccessList.Delete);
    },
    canCreate() {
      return this.$has(perms.LeadAccessList.Create);
    },
    currentUserAccessWarning() {
      if (this.selected.allowAll || this.selected.leadAccessGroupRecords == null) return false;
      return (
        !this.selected.leadAccessListRecords.some((r) => r.userId == this.loggedInUser.id) &&
        !this.selected.leadAccessGroupRecords.some(
          (ag) =>
            ag.accessGroup?.users && ag.accessGroup.users?.some((u) => u.id == this.loggedInUser.id)
        )
      );
    },
    currentUserAccessWarningDisabled() {
      if (this.selected.allowAll || this.selected.leadAccessGroupRecords == null) return false;
      return !(
        this.selected.leadAccessListRecords.some(
          (r) => r.userId == this.loggedInUser.id && r.isActive
        ) ||
        this.selected.leadAccessGroupRecords.some(
          (ag) =>
            ag.accessGroup?.users &&
            ag.accessGroup?.users?.some((u) => u.id == this.loggedInUser.id) &&
            ag.isActive
        )
      );
    },
  },
  components: { UserAllSelector, AccessGroupsSelector },
  mixins: [AccessListMixin],
};
</script>
