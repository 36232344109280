<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 700"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="'slideout-readonly-content'"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching MS Team Info ...
      </h3>
      <h3 v-else-if="selected != null" class="font-weight-bold pa-1" small>
        <img :src="defaultTeamsLogo" style="height: 14px" class="mr-2" />
        <span class="opacity-64">MS Team:</span>
        {{ selected.name }}
      </h3>
      <div>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching MS Team ...</p>
    </v-container>
    <v-container fluid v-else-if="selected != null">
      <v-row class="my-0 w-100" dense style="flex: none">
        <v-col cols="12" md="12" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Name</h3>
          <div class="d-flex align-center readonly-border pa-2" style="gap: 1rem; height: 40px">
            <h4>{{ selected.name }}</h4>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Visibility</h3>
          <div class="d-flex align-center readonly-border pa-2" style="gap: 1rem; height: 40px">
            <ms-team-visibility small :visibility="msTeam.visibility"></ms-team-visibility>
          </div>
        </v-col>
        <v-col cols="12" md="8" class="mb-3 pl-4">
          <h3 class="fs-15px opacity-87 mb-2">General Channel Links</h3>
          <div class="d-flex align-center" style="gap: 0.5rem">
            <v-tooltip top nudge-top="-4" :disabled="teamMainLink == null">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="secondary"
                  outlined
                  class="px-2"
                  small
                  target="_blank"
                  :href="teamMainLink"
                  :disabled="teamMainLink == null"
                >
                  <i class="fad fa-arrow-up-right-from-square fs-14px mr-2"></i> Open in App
                </v-btn>
              </template>
              <span>Open in Microsoft Teams App</span>
            </v-tooltip>

            <v-tooltip top nudge-top="-4" :disabled="sharepointUrl == null">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="secondary"
                  outlined
                  class="px-2"
                  small
                  target="_blank"
                  :href="sharepointUrl"
                  :disabled="sharepointUrl == null"
                >
                  <i class="fad fa-folder-tree fs-14px mr-2"></i> Open SharePoint Folder
                </v-btn>
              </template>
              <span>Open SharePoint Folder</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Description</h3>
          <div class="d-flex align-center readonly-border pa-2" style="min-height: 40px">
            <div v-if="selected.description != null && selected.description != ''">
              {{ selected.description }}
            </div>
            <i v-else class="fad fa-empty-set opacity-72"></i>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Channels</h3>
          <div
            v-if="selected.channels != null && selected.channels.length > 0"
            class="d-flex flex-wrap align-center readonly-border pa-4 inset-shadow"
            style="gap: 1rem; background: #eceff1; border-radius: 0.5rem"
          >
            <v-card
              class="d-flex align-center py-1 px-2"
              style="gap: 0.5rem; flex-wrap: nowrap"
              v-for="(channel, i) in selected.channels"
              :key="i"
            >
              <h4 style="white-space: nowrap">
                <i class="fad fa-bullhorn mr-2"></i>{{ channel.name }}
              </h4>
            </v-card>
          </div>
          <div
            v-else
            class="d-flex flex-wrap align-center readonly-border pa-4 inset-shadow"
            style="gap: 1rem; background: #eceff1; border-radius: 0.5rem"
          >
            <i class="fad fa-empty-set opacity-72"></i>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="isFetching"
                @click="fetchJiraProject()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Jira Project Details</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../plugins/permissions";
import msTeamsAPI from "../../../Projects/services/ms-teams-service";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";
import MsTeamVisibility from "../../../Projects/components/MsTeamVisibility.vue";

export default {
  name: "view-jira-project",
  data() {
    return {
      perms,
      msTeam: null,
      msTeamId: null,
      isFetching: false,
      selected: null,
      slideouts: {
        update: {
          valid: false,
          active: false,
          fullWidth: false,
          hasChanges: false,
        },
      },
      tenantId: "d2953fc7-9caa-4587-af20-9b336d870d12",
      defaultTeamsLogo: `https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg`,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "700px";
    },
    generalChannel() {
      if (
        this.selected != null &&
        this.selected.channels != null &&
        this.selected.channels.length > 0
      )
        return this.selected.channels.find((c) => c.name == "General");
      else return null;
    },
    teamMainLink() {
      if (this.selected == null || this.generalChannel == null) return null;

      return `https://teams.microsoft.com/l/team/${this.generalChannel.id}/conversations?groupId=${this.selected.id}&tenantId=${this.tenantId}`;
    },
    teamGeneralChannelLink() {
      if (this.selected == null || this.generalChannel == null) return null;
      return `https://teams.microsoft.com/l/channel/${this.generalChannel.id}/General?groupId=${this.selected.id}&tenantId=${this.tenantId}`;
    },
    sharepointUrl() {
      if (
        this.selected.generalSharePointSiteName == null ||
        this.selected.generalSharePointSiteName == ""
      )
        return null;
      return `https://netorgft2704825.sharepoint.com/sites/${this.selected.generalSharePointSiteName}/Shared Documents/Forms/AllItems.aspx?id=/sites/${this.selected.generalSharePointSiteName}/Shared Documents/General&p=true&ga=1`;
    },
  },
  methods: {
    fetchMSTeam() {
      this.isFetching = true;
      msTeamsAPI
        .team(this.msTeamId)
        .then((resp) => {
          this.isFetching = false;
          this.selected = this.cloneDeep(resp.data.team);
          this.$log("get team:", this.selected);
        })
        .catch((err) => {
          this.isFetching = false;
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);

      this.selected = null;
      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(msTeam) {
      this.$log(">>>>>>> open", msTeam);
      this.msTeam = this.cloneDeep(msTeam);
      this.msTeamId = this.msTeam.id;
      this.fetchMSTeam();
      this.slideouts.update.active = true;
    },
  },
  watch: {},
  components: { PanelSizeControl, MsTeamVisibility },
};
</script>
