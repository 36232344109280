<template>
  <div class="d-flex flex-column pa-4" style="overflow: hidden; height: 100%">
    <div class="d-flex align-center justify-space-between mb-2 mt-2">
      <div class="d-flex align-center" style="gap: 0.75rem">
        <v-sheet
          color="secondary"
          dark
          elevation="2"
          rounded
          class="px-2 d-flex align-center"
          height="44px"
          style="position: relative"
        >
          <span class="filter-label"><i class="fas fa-caret-right mr-1"></i>Actions Filters</span>
          <v-checkbox
            color="info lighten-2"
            v-model="filters.actionType.noAction"
            dense
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <wire-cad-integration-action
              class="ml-2"
              :action="enums.WireCadIntegrationAction.NoAction.value"
              small
            ></wire-cad-integration-action>
            <v-divider style="width: 100%" class="mt-1"></v-divider>
            <span class="fs-12px font-weight-medium mono-font pl-2" style="line-height: 16px">
              {{ actionNoActionCount }}
              <span class="opacity-64">({{ actionNoActionCountPCT }}%)</span>
            </span>
          </div>
        </v-sheet>
        <v-sheet
          color="secondary"
          dark
          elevation="2"
          rounded
          class="px-2 d-flex align-center"
          height="44px"
        >
          <v-checkbox
            color="info lighten-2"
            v-model="filters.actionType.create"
            dense
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <wire-cad-integration-action
              class="ml-2"
              :action="enums.WireCadIntegrationAction.Create.value"
              small
            ></wire-cad-integration-action>
            <v-divider style="width: 100%" class="mt-1"></v-divider>
            <span class="fs-12px font-weight-medium mono-font pl-2" style="line-height: 16px">
              {{ actionCreateCount }}
              <span class="opacity-64">({{ actionCreateCountPCT }}%)</span>
            </span>
          </div>
        </v-sheet>
        <v-sheet
          color="secondary"
          dark
          elevation="2"
          rounded
          class="px-2 d-flex align-center"
          height="44px"
        >
          <v-checkbox
            color="info lighten-2"
            v-model="filters.actionType.link"
            dense
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <wire-cad-integration-action
              class="ml-2"
              :action="enums.WireCadIntegrationAction.Link.value"
              small
            ></wire-cad-integration-action>
            <v-divider style="width: 100%" class="mt-1"></v-divider>
            <span class="fs-12px font-weight-medium mono-font pl-2" style="line-height: 16px">
              {{ actionLinkCount }}
              <span class="opacity-64">({{ actionLinkCountPCT }}%)</span>
            </span>
          </div>
        </v-sheet>
        <v-sheet
          color="secondary"
          dark
          elevation="2"
          rounded
          class="px-2 d-flex align-center"
          height="44px"
        >
          <v-checkbox
            color="info lighten-2"
            v-model="filters.actionType.linkAndUpdate"
            dense
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <wire-cad-integration-action
              class="ml-2"
              :action="enums.WireCadIntegrationAction.LinkAndUpdate.value"
              small
            ></wire-cad-integration-action>
            <v-divider style="width: 100%" class="mt-1"></v-divider>
            <span class="fs-12px font-weight-medium mono-font pl-2" style="line-height: 16px">
              {{ actionLinkAndUpdateCount }}
              <span class="opacity-64">({{ actionLinkAndUpdateCountPCT }}%)</span>
            </span>
          </div>
        </v-sheet>
        <v-divider vertical class="mx-2"></v-divider>
        <v-sheet
          elevation="2"
          rounded
          class="px-2 d-flex align-center"
          height="44px"
          style="position: relative"
        >
          <span class="filter-label">
            <i class="fas fa-caret-right mr-1"></i>Integration Results Filters
          </span>
          <v-checkbox
            v-model="filters.integrationResult.noMatch"
            dense
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <wire-cad-integration-result
              class="ml-2"
              :result="enums.WireCadIntegrationResult.NoMatch.value"
              small
            ></wire-cad-integration-result>
            <v-divider style="width: 100%" class="mt-1"></v-divider>
            <span class="fs-12px font-weight-medium mono-font pl-2" style="line-height: 16px">
              {{ noMatchCount }}
              <span class="opacity-64">({{ noMatchCountPCT }}%)</span>
            </span>
          </div>
        </v-sheet>
        <v-sheet elevation="2" rounded class="px-2 d-flex align-center" height="44px">
          <v-checkbox
            v-model="filters.integrationResult.match"
            dense
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <wire-cad-integration-result
              class="ml-2"
              :result="enums.WireCadIntegrationResult.Match.value"
              small
            ></wire-cad-integration-result>
            <v-divider style="width: 100%" class="mt-1"></v-divider>
            <span class="fs-12px font-weight-medium mono-font pl-2" style="line-height: 16px">
              {{ matchCount }}
              <span class="opacity-64">({{ matchCountPCT }}%)</span>
            </span>
          </div>
        </v-sheet>
        <v-sheet elevation="2" rounded class="px-2 d-flex align-center" height="44px">
          <v-checkbox
            v-model="filters.integrationResult.partiallyLinked"
            dense
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <wire-cad-integration-result
              class="ml-2"
              :result="enums.WireCadIntegrationResult.PartiallyLinked.value"
              small
            ></wire-cad-integration-result>
            <v-divider style="width: 100%" class="mt-1"></v-divider>
            <span class="fs-12px font-weight-medium mono-font pl-2" style="line-height: 16px">
              {{ partiallyLinkedCount }}
              <span class="opacity-64">({{ partiallyLinkedCountPCT }}%)</span>
            </span>
          </div>
        </v-sheet>
        <v-sheet elevation="2" rounded class="px-2 d-flex align-center" height="44px">
          <v-checkbox
            v-model="filters.integrationResult.fullyLinked"
            dense
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <wire-cad-integration-result
              class="ml-2"
              :result="enums.WireCadIntegrationResult.FullyLinked.value"
              small
            ></wire-cad-integration-result>
            <v-divider style="width: 100%" class="mt-1"></v-divider>
            <span class="fs-12px font-weight-medium mono-font pl-2" style="line-height: 16px">
              {{ fullyLinkedCount }}
              <span class="opacity-64">({{ fullyLinkedCountPCT }}%)</span>
            </span>
          </div>
        </v-sheet>
      </div>
    </div>
    <v-data-table
      class="elevation-2 table-sticky-header-exclude-footer"
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="headers"
      :items="itemsFiltered"
      :items-per-page="50"
      :sort-by="sortBy"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:header>
        <thead class="v-data-table-header elevation-2">
          <tr>
            <th
              :colspan="3"
              role="columnheader"
              scope="col"
              class="text-center pl-2"
              style="border-bottom: thin solid rgba(42, 54, 59, 0.12)"
            >
              <span style="font-weight: 700">COCO</span>
            </th>
            <th
              :colspan="3"
              role="columnheader"
              scope="col"
              class="report-wirecad-header text-center pl-2"
              style="border-bottom: thin solid rgba(42, 54, 59, 0.12)"
            >
              <span style="font-weight: 700">WireCAD Manufacturer</span>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.cocoData.name`]="{ item }">
        <v-sheet
          class="d-flex align-center justify-space-between rounded-lg px-2 elevation-2"
          :class="getManufacturerCardClass(item)"
          style="gap: 0.5rem; width: 100%"
          v-if="item.cocoData != null"
        >
          <div
            class="d-flex align-center"
            style="padding-top: 2px; padding-bottom: 2px; gap: 0.5rem"
          >
            <v-tooltip right z-index="999" nudge-right="-4px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <div class="company-logo-tiny">
                    <div
                      style="height: 100%; width: 100%"
                      v-viewer
                      @click.stop
                      v-if="item.cocoData.logoUrl != null && item.cocoData.logoUrl != ''"
                    >
                      <img
                        :key="item.cocoData.id + '_img'"
                        :src="item.cocoData.logoUrl"
                        height="100%"
                        width="100%"
                      />
                    </div>
                    <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                      <img
                        :key="item.cocoData.id + '_img'"
                        src="/img/DNA_Symbol.png"
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <span>
                <v-img
                  v-if="item.cocoData.logoUrl != null && item.cocoData.logoUrl != ''"
                  :src="item.cocoData.logoUrl"
                  height="250px"
                  width="250px"
                  contain
                ></v-img>
                <v-img
                  v-else
                  src="/img/DNA_Symbol.png"
                  height="250px"
                  width="250px"
                  contain
                ></v-img>
              </span>
            </v-tooltip>
            <div class="d-flex flex-column" style="gap: 2px">
              <h4 class="ma-0" style="line-height: normal">{{ item.cocoData.name }}</h4>
              <h5 class="ma-0 opacity-72" style="line-height: normal">
                {{ item.cocoData.abbreviatedName }}
              </h5>
            </div>
          </div>
          <div class="d-flex align-center">
            <v-btn
              min-width="24px !important"
              width="24px !important"
              height="24px !important"
              color="secondary"
              outlined
              elevation="2"
              class="pa-0 ml-2 white"
              small
              target="_blank"
              :href="`/manufacturers/${item.cocoData.id}`"
            >
              <i class="fad fa-arrow-up-right-from-square fs-12px"></i>
            </v-btn>
            <v-btn
              v-if="
                !readonly &&
                item.integrationResult != enums.WireCadIntegrationResult.FullyLinked.value &&
                item.actionType == enums.WireCadIntegrationAction.Link.value
              "
              min-width="24px !important"
              width="24px !important"
              height="24px !important"
              color="secondary"
              outlined
              elevation="2"
              class="pa-0 ml-2 white"
              small
              @click="editLinkedManufacturer(item)"
            >
              <i class="fad fa-pen fs-12px"></i>
            </v-btn>
            <v-btn
              v-if="
                !readonly &&
                item.integrationResult != enums.WireCadIntegrationResult.FullyLinked.value &&
                item.actionType == enums.WireCadIntegrationAction.Link.value
              "
              min-width="24px !important"
              width="24px !important"
              height="24px !important"
              color="red"
              outlined
              elevation="2"
              class="pa-0 ml-2 white"
              small
              @click="clearLinkedManufacturer(item)"
            >
              <i class="fad fa-trash-can-list fs-12px"></i>
            </v-btn>
          </div>
        </v-sheet>
        <v-chip
          class="d-flex align-center rounded px-1 py-1 elevation-2 new-mfr-created"
          color="indigo accent-2"
          dark
          outlined
          small
          style="gap: 0.5rem"
          v-else-if="item.actionType == enums.WireCadIntegrationAction.Create.value"
        >
          <span class="font-weight-bold">
            <i class="fas fa-plus mx-1"></i> New Mfr. will be created</span
          >
          <v-btn
            v-if="
              !readonly &&
              item.integrationResult != enums.WireCadIntegrationResult.FullyLinked.value
            "
            min-width="24px !important"
            width="24px !important"
            height="24px !important"
            color="red"
            text
            class="pa-0 white"
            @click="clearLinkedManufacturer(item)"
          >
            <i class="fad fa-trash-can-list fs-12px"></i>
          </v-btn>
        </v-chip>
        <span v-else><i class="fad fa-empty-set opacity-40"></i></span>
      </template>
      <template v-slot:[`item.integrationResult`]="{ item }">
        <wire-cad-integration-result
          :result="item.integrationResult"
          small
        ></wire-cad-integration-result>
      </template>
      <template v-slot:[`item.wireCadData.guid`]="{ item }">
        <span class="mono-font font-weight-medium fs-10px">{{ item.wireCadData.guid }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-select-alt
          class="request-action-list"
          :class="{
            'request-action-list-disabled':
              readonly ||
              item.integrationResult == enums.WireCadIntegrationResult.FullyLinked.value,
          }"
          :rules="[allRules.required]"
          v-model="item.actionType"
          :items="getAvailableActionsList(item)"
          @change="onActionChanged($event, item)"
          dense
          transparent
          hide-details
          filled
          item-text="text"
          item-value="value"
          :disabled="
            readonly || item.integrationResult == enums.WireCadIntegrationResult.FullyLinked.value
          "
        >
          <template v-slot:selection="{ item: actionItem }">
            <wire-cad-integration-action
              class="ml-2"
              :action="actionItem.value"
            ></wire-cad-integration-action>
          </template>
          <template v-slot:item="{ item: actionItem }">
            <wire-cad-integration-action :action="actionItem.value"></wire-cad-integration-action>
          </template>
        </v-select-alt>
      </template>
      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No items matching your filters!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <manufacturer-selector-modal
      title="Link to an Existing Manufacturer"
      ref="manufacturerSelectorModal"
      @save="onLinkSave"
      @clear="onLinkClear"
      @cancel="onLinkCancel"
    ></manufacturer-selector-modal>
  </div>
</template>

<script>
import perms from "../../../../../plugins/permissions";
import enums from "../../../../../plugins/enums";
import header from "../configs/manufacturer-report.header";
import ManufacturerSelectorModal from "../../../../Equipments/components/ManufacturerSelectorModal.vue";
import WireCadIntegrationResult from "../../Shared/components/WireCadIntegrationResult.vue";
import WireCadIntegrationAction from "../../Shared/components/WireCadIntegrationAction.vue";

export default {
  name: "create-wire-cad-manufacturer-request",
  components: {
    WireCadIntegrationResult,
    WireCadIntegrationAction,
    ManufacturerSelectorModal,
  },
  data() {
    return {
      perms,
      enums,
      selected: {},
      availableActionsForFullyLinked: [enums.WireCadIntegrationAction.NoAction],
      availableActionsForPartiallyLinked: [
        enums.WireCadIntegrationAction.NoAction,
        enums.WireCadIntegrationAction.LinkAndUpdate,
      ],
      availableActionsForNoMatch: [
        enums.WireCadIntegrationAction.NoAction,
        enums.WireCadIntegrationAction.Link,
        enums.WireCadIntegrationAction.Create,
      ],
      availableActionsForMatch: [
        enums.WireCadIntegrationAction.NoAction,
        enums.WireCadIntegrationAction.Link,
        enums.WireCadIntegrationAction.LinkAndUpdate,
      ],
      modals: {
        selectManufacturer: {
          dialog: false,
          loading: false,
        },
      },
      headers: header,
      sortBy: "guid",
      filters: {
        integrationResult: {
          noMatch: true,
          match: true,
          fullyLinked: true,
          partiallyLinked: true,
        },
        actionType: {
          noAction: true,
          create: true,
          link: true,
          linkAndUpdate: true,
        },
      },
      manufacturerToBeLinked: null,
      requestItems: [],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.requestItems = this.cloneDeep(this.items);
  },
  computed: {
    getAvailableActionsList() {
      return (item) => {
        if (item.integrationResult == enums.WireCadIntegrationResult.FullyLinked.value)
          return this.availableActionsForFullyLinked;
        else if (item.integrationResult == enums.WireCadIntegrationResult.PartiallyLinked.value)
          return this.availableActionsForPartiallyLinked;
        else if (item.integrationResult == enums.WireCadIntegrationResult.Match.value)
          return this.availableActionsForMatch;
        else if (item.integrationResult == enums.WireCadIntegrationResult.NoMatch.value)
          return this.availableActionsForNoMatch;
      };
    },
    itemsFiltered() {
      var integrationResults = [];
      if (this.filters.integrationResult.noMatch)
        integrationResults.push(enums.WireCadIntegrationResult.NoMatch.value);
      if (this.filters.integrationResult.match)
        integrationResults.push(enums.WireCadIntegrationResult.Match.value);
      if (this.filters.integrationResult.fullyLinked)
        integrationResults.push(enums.WireCadIntegrationResult.FullyLinked.value);
      if (this.filters.integrationResult.partiallyLinked)
        integrationResults.push(enums.WireCadIntegrationResult.PartiallyLinked.value);

      var actionTypes = [];
      if (this.filters.actionType.noAction)
        actionTypes.push(enums.WireCadIntegrationAction.NoAction.value);
      if (this.filters.actionType.create)
        actionTypes.push(enums.WireCadIntegrationAction.Create.value);
      if (this.filters.actionType.link) actionTypes.push(enums.WireCadIntegrationAction.Link.value);
      if (this.filters.actionType.linkAndUpdate)
        actionTypes.push(enums.WireCadIntegrationAction.LinkAndUpdate.value);

      return this.items
        .filter((item) => integrationResults.includes(item.integrationResult))
        .filter((item) => actionTypes.includes(item.actionType));
    },
    noMatchCount() {
      return this.items.filter(
        (item) => item.integrationResult == enums.WireCadIntegrationResult.NoMatch.value
      ).length;
    },
    noMatchCountPCT() {
      return ((this.noMatchCount / this.items.length) * 100).toFixed(2);
    },
    matchCount() {
      return this.items.filter(
        (item) => item.integrationResult == enums.WireCadIntegrationResult.Match.value
      ).length;
    },
    matchCountPCT() {
      return ((this.matchCount / this.items.length) * 100).toFixed(2);
    },
    fullyLinkedCount() {
      return this.items.filter(
        (item) => item.integrationResult == enums.WireCadIntegrationResult.FullyLinked.value
      ).length;
    },
    fullyLinkedCountPCT() {
      return ((this.fullyLinkedCount / this.items.length) * 100).toFixed(2);
    },
    partiallyLinkedCount() {
      return this.items.filter(
        (item) => item.integrationResult == enums.WireCadIntegrationResult.PartiallyLinked.value
      ).length;
    },
    partiallyLinkedCountPCT() {
      return ((this.partiallyLinkedCount / this.items.length) * 100).toFixed(2);
    },
    actionNoActionCount() {
      return this.items.filter(
        (item) => item.actionType == enums.WireCadIntegrationAction.NoAction.value
      ).length;
    },
    actionNoActionCountPCT() {
      return ((this.actionNoActionCount / this.items.length) * 100).toFixed(2);
    },
    actionCreateCount() {
      return this.items.filter(
        (item) => item.actionType == enums.WireCadIntegrationAction.Create.value
      ).length;
    },
    actionCreateCountPCT() {
      return ((this.actionCreateCount / this.items.length) * 100).toFixed(2);
    },
    actionLinkCount() {
      return this.items.filter(
        (item) => item.actionType == enums.WireCadIntegrationAction.Link.value
      ).length;
    },
    actionLinkCountPCT() {
      return ((this.actionLinkCount / this.items.length) * 100).toFixed(2);
    },
    actionLinkAndUpdateCount() {
      return this.items.filter(
        (item) => item.actionType == enums.WireCadIntegrationAction.LinkAndUpdate.value
      ).length;
    },
    actionLinkAndUpdateCountPCT() {
      return ((this.actionLinkAndUpdateCount / this.items.length) * 100).toFixed(2);
    },
  },
  methods: {
    getManufacturerCardClass(item) {
      if (
        item.integrationResult == enums.WireCadIntegrationResult.FullyLinked.value ||
        item.actionType == enums.WireCadIntegrationAction.Link.value
      ) {
        return "green lighten-4";
      } else if (
        item.integrationResult == enums.WireCadIntegrationResult.PartiallyLinked.value ||
        item.actionType == enums.WireCadIntegrationAction.LinkAndUpdate.value
      ) {
        return "cyan lighten-4";
      } else {
        return "orange lighten-4";
      }
    },
    editLinkedManufacturer(item) {
      if (item.integrationResult != enums.WireCadIntegrationResult.FullyLinked.value) {
        this.manufacturerToBeLinked = item;
        this.$refs.manufacturerSelectorModal.open(item.cocoData?.id);
      }
    },
    clearLinkedManufacturer(item) {
      item.actionType = enums.WireCadIntegrationAction.NoAction.value;
      item.cocoData = null;
    },
    onActionChanged(action, item) {
      console.log("onActionChanged", action, item);
      if (
        action == enums.WireCadIntegrationAction.Link.value &&
        item.integrationResult == enums.WireCadIntegrationResult.NoMatch.value
      ) {
        this.manufacturerToBeLinked = item;
        this.$refs.manufacturerSelectorModal.open(item.cocoData?.id);
      } else if (
        action == enums.WireCadIntegrationAction.Link.value &&
        item.integrationResult == enums.WireCadIntegrationResult.Match.value
      ) {
        if (item.cocoData == null) {
          this.manufacturerToBeLinked = item;
          this.$refs.manufacturerSelectorModal.open(item.cocoData?.id);
        }
      } else if (
        (action == enums.WireCadIntegrationAction.NoAction.value ||
          action == enums.WireCadIntegrationAction.Create.value) &&
        item.integrationResult == enums.WireCadIntegrationResult.NoMatch.value
      ) {
        item.cocoData = null;
      }
    },
    onLinkSave(manufacturer) {
      console.log("onLinkSave", manufacturer);
      this.manufacturerToBeLinked.cocoData = manufacturer;
    },
    onLinkClear() {
      console.log("onLinkClear");
      this.manufacturerToBeLinked.actionType = enums.WireCadIntegrationAction.NoAction.value;
      this.manufacturerToBeLinked.cocoData = null;
      this.manufacturerToBeLinked = null;
    },
    onLinkCancel() {
      console.log("onLinkCancel");
      if (
        this.manufacturerToBeLinked.integrationResult ==
          enums.WireCadIntegrationResult.Match.value &&
        this.manufacturerToBeLinked.actionType == enums.WireCadIntegrationAction.Link.value &&
        this.manufacturerToBeLinked.cocoData != null
      )
        return;

      if (
        this.manufacturerToBeLinked.integrationResult ==
          enums.WireCadIntegrationResult.Match.value &&
        this.manufacturerToBeLinked.actionType == enums.WireCadIntegrationAction.Link.value &&
        this.manufacturerToBeLinked.cocoData == null
      ) {
        this.manufacturerToBeLinked.actionType = enums.WireCadIntegrationAction.NoAction.value;
        return;
      }

      if (
        this.manufacturerToBeLinked.integrationResult ==
          enums.WireCadIntegrationResult.NoMatch.value &&
        this.manufacturerToBeLinked.cocoData != null &&
        this.manufacturerToBeLinked.actionType == enums.WireCadIntegrationAction.Link.value
      )
        return;

      if (
        this.manufacturerToBeLinked.integrationResult ==
          enums.WireCadIntegrationResult.NoMatch.value &&
        this.manufacturerToBeLinked.cocoData == null
      )
        this.manufacturerToBeLinked.actionType = enums.WireCadIntegrationAction.NoAction.value;
    },
  },
  watch: {
    items: {
      handler() {
        if (!this.isEqual(this.items, this.requestItems)) {
          this.requestItems = this.cloneDeep(this.items);
        }
      },
      deep: true,
    },
    requestItems: {
      handler() {
        if (!this.isEqual(this.requestItems, this.items)) {
          this.$emit("update:items", this.cloneDeep(this.requestItems));
        }
      },
      deep: true,
    },
  },
};
</script>
