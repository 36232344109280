<template>
  <div class="alt-comp-wrapper project-space-selector">
    <v-autocomplete-alt
      auto-select-first
      :rules="[allRules.required]"
      :label="
        label == null || label == ''
          ? `<i class='fad fa-table-pivot mr-2'></i>Connected Space`
          : label
      "
      id="projectSpaceSelector"
      placeholder="Select a Project Space"
      :value="value"
      @input="handleSelectedProjectSpace"
      :items="spaces"
      :filter="projectSpacesFilter"
      dense
      filled
      item-value="id"
      item-text="name"
      clearable
      :hide-details="hideDetails"
      :loading="isProjectSpacesLoading"
      :disabled="isProjectSpacesLoading"
      :item-disabled="isSpaceDisabled"
      :readonly="readonly"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <v-row
          :key="item.id + '_spaceTemplateSelector'"
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); width: 100%; flex-wrap: nowrap"
          class="py-2"
          :class="isSpaceDisabled(data.item) ? 'opacity-54' : ''"
        >
          <v-col sm="auto" class="d-flex align-center mr-2">
            <div class="typeahead-space-img">
              <v-img
                v-if="data.item.imageUrl != null && data.item.imageUrl != ''"
                class="img"
                :src="data.item.imageUrl"
                height="100%"
                width="100%"
                contain
              ></v-img>
              <i v-else :class="'fad fa-table-pivot'"></i>
            </div>
          </v-col>
          <v-col class="d-flex justify-center col-md-12 flex-column">
            <strong>
              <span class="level-building-name fs-12px mr-1">
                <i class="fad fa-building mr-1"></i>
                {{ data.item.level.building.name }}</span
              >/<span class="fs-12px ml-1">
                <i class="fad fa-layer-group mr-1"></i>{{ data.item.level.name }}</span
              >
            </strong>
            <div class="d-flex align-center">
              <span
                class="fs-13px mr-2"
                v-if="data.item.spaceNumber != null && data.item.spaceNumber != ''"
              >
                <i class="fas fa-hashtag mr-1"></i>{{ data.item.spaceNumber }}
              </span>
              <strong class="typeahead-space-name ma-0">{{ data.item.name }}</strong>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      target="_blank"
      :href="`/projects/${this.projectId}/spaces/${this.value}`"
      :disabled="value == null"
    >
      <i class="far fa-external-link"></i>
    </v-btn>
  </div>
</template>

<script>
import projectService from "../../Projects/services/projects-service";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    disableTemplatedSpaces: {
      type: Boolean,
      default: false,
    },
    except: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { spaces: [], isProjectSpacesLoading: false };
  },
  methods: {
    isSpaceDisabled(item) {
      return (
        this.except.includes(item.id) || (this.disableTemplatedSpaces && item.templateId != null)
      );
    },
    handleSelectedProjectSpace(val) {
      this.$emit("input", val);
      let spaceObj = this.spaces.find((s) => s.id == val);
      this.$emit("change", spaceObj);
    },
    getProjectSpaces() {
      if (this.projectId == null) return;
      this.isProjectSpacesLoading = true;
      projectService
        .getSpacesLiteOverview(this.projectId)
        .then((resp) => {
          this.spaces = resp.data;
          this.$log("spaces", resp.data);
          this.isProjectSpacesLoading = false;
        })
        .catch((err) => {
          this.isProjectSpacesLoading = false;
          this.$handleError(err);
        });
    },
    projectSpacesFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const text2 = item.spaceNumber == null ? "" : item.spaceNumber.toLowerCase();
      const text3 = item.description == null ? "" : item.description.toLowerCase();
      const text4 = item.level?.name == null ? "" : item.level?.name.toLowerCase();
      const text5 = item.level?.shortName == null ? "" : item.level?.shortName.toLowerCase();
      const text6 =
        item.level?.building?.name == null ? "" : item.level?.building?.name.toLowerCase();
      const text7 =
        item.level?.building?.shortName == null
          ? ""
          : item.level?.building?.shortName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1 ||
        text4.indexOf(searchText) > -1 ||
        text5.indexOf(searchText) > -1 ||
        text6.indexOf(searchText) > -1 ||
        text7.indexOf(searchText) > -1
      );
    },
    refresh() {
      this.getProjectSpaces();
    },
  },
  created() {
    this.getProjectSpaces();
  },
  watch: {
    projectId: {
      handler() {
        this.getProjectSpaces();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.alt-comp-wrapper.project-space-selector {
  .v-autocomplete-alt {
    // width: calc(100% - 40px);
    // flex: 1 1 auto;
    // overflow: hidden;

    .v-input,
    .v-input__slot {
      height: 54px !important;
    }
  }
}

.typeahead-space-name {
  max-width: calc(100% - 40px) !important;
}

.typeahead-space-description {
  max-width: calc(100% - 40px) !important;
}

.space-number {
  white-space: nowrap;
  font-size: 13px;
  color: rgba($shades-black, 0.54);

  i {
    font-size: 11px;
  }
}
</style>
