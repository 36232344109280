<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '1000px'"
    :min-size="nested ? 400 : 1000"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Phase ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly"> Phase: '{{ selected.name }}' </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <entity-id class="ml-2" :label="selected.id" :path="phaseUrl" title="Phase"></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a Phase
      </h3>
      <div>
        <v-btn
          @click="switchToEditMode()"
          v-if="readonly && !isFetching && isMainRoute"
          color="info"
          small
          class="ml-2"
          style="height: 24px; font-size: 12px"
        >
          <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
        </v-btn>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>
    <v-container class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Phase ...</p>
    </v-container>
    <v-form v-else v-model="valid" ref="updateForm">
      <v-container>
        <v-row class="my-0" dense>
          <v-col cols="12" sm="4">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="Name"
              id="phaseName"
              ref="phaseName"
              placeholder="name"
              v-model="selected.name"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Number"
              id="phaseNumber"
              ref="phaseNumber"
              placeholder="number"
              v-model="selected.number"
              type="number"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field-alt
              :rules="[allRules.required, allRules.noWhiteSpaces]"
              label="PO#"
              id="phasePO"
              ref="phasePO"
              placeholder="PO#"
              v-model="selected.po"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="poReceiveDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="readonly"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field-alt
                  v-model="selected.poReceiveDate"
                  label="PO Receive Date"
                  placeholder="PO Receive Date"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field-alt>
              </template>
              <v-date-picker
                color="orange darken-3"
                header-color="secondary"
                :first-day-of-week="1"
                v-model="selected.poReceiveDate"
                @input="poReceiveDateMenu = false"
                show-current
                show-week
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="readonly"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field-alt
                  v-model="selected.startDate"
                  :rules="[allRules.required]"
                  label="Start Date"
                  placeholder="start Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field-alt>
              </template>
              <v-date-picker
                color="orange darken-3"
                header-color="secondary"
                :first-day-of-week="1"
                v-model="selected.startDate"
                @input="startDateMenu = false"
                show-current
                show-week
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="readonly"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field-alt
                  v-model="selected.endDate"
                  :rules="[allRules.required]"
                  label="End Date"
                  placeholder="end Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field-alt>
              </template>
              <v-date-picker
                color="orange darken-3"
                header-color="secondary"
                :first-day-of-week="1"
                v-model="selected.endDate"
                @input="endDateMenu = false"
                show-current
                show-week
                :allowed-dates="(val) => val > selected.startDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select-alt
              :rules="[allRules.required]"
              id="PhaseStatus"
              placeholder="Phase Status"
              label="Phase Status"
              v-model="selected.status"
              :disabled="readonly"
              :items="statusList"
              item-text="textSingle"
              item-value="value"
            >
              <template v-slot:selection="{ item }">
                <project-stage :stage="item.value"></project-stage>
              </template>
              <template v-slot:item="{ item }">
                <project-stage :stage="item.value"></project-stage>
              </template>
            </v-select-alt>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field-alt
              label="Tempo planned time link"
              id="tempoPlannedTimeLink"
              ref="tempoPlannedTimeLink"
              placeholder="Tempo planned time link"
              v-model="selected.tempoPlannedTimeLink"
              :readonly="readonly"
              :rules="[allRules.url, allRules.tempoLink]"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field-alt
              label="Tempo Logged time link"
              id="tempoLoggedTimeLink"
              ref="tempoLoggedTimeLink"
              placeholder="Tempo Logged time link"
              v-model="selected.tempoLoggedTimeLink"
              :readonly="readonly"
              :rules="[allRules.url, allRules.tempoLink]"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" sm="6">
            <jira-issue-selector
              v-model="selected.jiraIssueKey"
              :readonly="readonly"
              :projectId="projectId"
              :phaseId="phaseId"
              :jiraIssueId.sync="selected.jiraIssueId"
            >
            </jira-issue-selector>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Total Authorized Fees"
              id="TotalAuthorizedFees"
              ref="TotalAuthorizedFees"
              placeholder="Total Authorized Fees"
              v-model="selected.totalAuthorizedFees"
              :readonly="readonly"
              prefix="$"
              type="number"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12">
            <v-textarea-alt
              :rules="[allRules.required]"
              label="Description"
              id="Description"
              ref="Description"
              placeholder="Description"
              v-model="selected.description"
              :readonly="readonly"
            >
            </v-textarea-alt>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="readonly" @click="closeUpdateSlideout()">
          <i class="fal fa-chevron-left mr-2"></i>Close
        </v-btn>
        <v-btn
          v-if="!readonly"
          color="info"
          :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="updateConfirmed()"
          :loading="slideouts.update.isLoading"
        >
          <i class="fal mr-2" :class="selected.id ? 'fa-check' : 'fa-plus'"></i>
          {{ selected.id ? "Save Changes" : "Create" }}
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import ApiService from "../services/phases-service";
import perms from "../../../plugins/permissions";
import Enums from "../../../plugins/enums";
import ProjectStage from "./ProjectStage.vue";
import JiraIssueSelector from "../../Shared/components/JiraIssueSelector.vue";
import moment from "moment";

export default {
  name: "edit-phase",
  data() {
    return {
      statusList: Enums.PROJECT_STAGE_LIST,
      poReceiveDateMenu: false,
      startDateMenu: false,
      endDateMenu: false,
      isFetching: false,
      perms,
      valid: false,
      phaseId: null,
      selected: {
        name: "",
        number: "",
        po: "",
        poReceiveDate: null,
        startDate: null,
        endDate: null,
        status: null,
        tempoPlannedTimeLink: "",
        tempoLoggedTimeLink: "",
        totalAuthorizedFees: 0,
        description: "",
        jiraIssueKey: null,
        jiraIssueId: null,
      },
      readonly: false,
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    phaseUrl() {
      if (this.selected == null) return "";
      return `phases/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "view-project-phase") return true;
      else return false;
    },
  },
  methods: {
    fetchPhase() {
      this.isFetching = true;
      ApiService.getById(this.phaseId)
        .then((resp) => {
          this.$log("getPhaseById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          const phase = this.cloneDeep(resp.data);
          phase.startDate = this.formatDate(phase.startDate);
          phase.endDate = this.formatDate(phase.endDate);
          phase.poReceiveDate = this.formatDate(phase.poReceiveDate);
          this.selected = phase;
          this.cementPhase();

          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getPhaseById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementPhase();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewPhase) {
      this.$emit("save", this.selected, isNewPhase);
    },
    updateConfirmed() {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      toSend.projectId = this.projectId;
      ApiService.update(toSend)
        .then((resp) => {
          var message = "Phase updated successfully!";
          this.$log(">>> updated", resp.data);
          this.selected = resp.data;
          this.cementPhase();
          var isNewPhase = false;
          if (!toSend.id) {
            isNewPhase = true;
            message = "Phase added successfully!";
          }
          this.announceChange(isNewPhase);
          this.closeUpdateSlideout();

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      if (!id) {
        this.selected = {};
        this.readonly = false;
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
      } else {
        this.readonly = !editMode;
        this.phaseId = id;
        this.fetchPhase();
      }
      this.cementPhase();
      this.slideouts.update.active = true;
    },
    cementPhase() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.$log("########>>>>>>> cementPhase()");
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
  },
  watch: {
    selected: {
      handler(val) {
        if (val.startDate >= val.endDate) {
          val.endDate = moment(val.startDate).add(1, "days").format("YYYY-MM-DD");
        }
        this.checkForChanges();
      },
      deep: true,
    },
  },
  components: {
    ProjectStage,
    JiraIssueSelector,
  },
};
</script>
