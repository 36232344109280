import perms from "../../plugins/permissions";
export default [
  {
    path: "/integrations/wire-cad",
    name: "integrations-wire-cad",
    meta: {
      layout: "dashboard",
      title: "WireCAD",
      permissions: [perms.WireCadIntegrationRequests.View],
    },
    component: () => import("./WireCad/Shared/views/WireCad.vue"),
  },
  {
    path: "/integrations/jira",
    name: "integrations-jira",
    meta: {
      layout: "dashboard",
      title: "Jira",
      permissions: [perms.JiraIntegration.View],
    },
    component: () => import("./Jira/views/JiraProjects.vue"),
  },
  {
    path: "/integrations/ms-teams",
    name: "integrations-ms-teams",
    meta: {
      layout: "dashboard",
      title: "MS Teams",
      permissions: [perms.MSTeamsIntegration.View],
    },
    component: () => import("./MSTeams/views/MSTeamsList.vue"),
  },
];
