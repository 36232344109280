<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template #header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Role ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> Role: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <entity-id class="ml-2" :label="selected.id" :path="roleUrl" title="Role"></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a New Role
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <div
      color="white"
      style="position: sticky; top: 0; z-index: 2"
      @wheel="wheelIt"
      ref="tabsContainer"
      v-if="slideouts.update.active && !isFetching"
    >
      <v-tabs v-model="slideouts.update.tab" color="error">
        <v-tab :key="0"> <i class="fad fa-memo-circle-info mr-2"></i> Basic Info </v-tab>
        <v-tab :key="1"> <i class="fad fa-key mr-2"></i>Permissions </v-tab>
        <v-tab :key="2">
          <i class="fad fa-users mr-2"></i>Assigned Users
          <v-chip
            :color="slideouts.update.tab == 2 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ selected.users.length }}
          </v-chip>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Role ...</p>
    </v-container>

    <v-tabs-items
      v-if="slideouts.update.active && !isFetching"
      v-model="slideouts.update.tab"
      style="height: 100%"
    >
      <v-tab-item :key="0">
        <v-container fluid class="pa-4">
          <v-form v-model="forms.tab0" ref="updateForm0">
            <v-row class="my-0" dense>
              <v-col cols="12" sm="12">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
                  label="Role Name"
                  id="roleName"
                  ref="roleName"
                  placeholder="admin"
                  v-model="selected.name"
                  :readonly="readonly"
                  hide-details
                  class="mb-0"
                >
                </v-text-field-alt>
              </v-col>
              <v-col cols="12" class="mt-2">
                <label class="v-switch-label fs-15px">Default Role</label>
                <v-checkbox
                  v-model="selected.isDefault"
                  color="info"
                  class="mt-1"
                  hide-details
                  :disabled="readonly"
                >
                  <template v-slot:label>
                    <label
                      class="v-switch-label fs-15px"
                      :class="{ 'info--text': selected.isDefault }"
                    >
                      Mark this role as the default role for new users
                    </label>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="1" style="height: 100%">
        <v-container fluid class="pa-4" style="height: 100%; overflow: auto; overflow: overlay">
          <v-form
            v-model="forms.tab1"
            ref="updateForm1"
            style="height: 100%; display: flex; flex-direction: column"
          >
            <v-row class="my-0" dense>
              <v-col cols="12">
                <v-text-field-alt
                  v-model="search"
                  hide-details
                  clearable
                  placeholder="Search in permissions"
                >
                  <template #prepend-inner>
                    <i class="far fa-search mr-2"></i>
                  </template>
                </v-text-field-alt>
              </v-col>
              <v-col cols="12" style="height: 100%">
                <div style="border-radius: 0.5rem" class="pa-2 tree-wrapper">
                  <div v-if="permissionsLoading" class="d-flex justify-center">
                    <v-progress-circular
                      color="primary"
                      :width="2"
                      :size="24"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <v-treeview
                    class="role-permissions-treeview"
                    v-model="selected.permissionIds"
                    :items="permissions"
                    open-on-click
                    selectable
                    :return-object="false"
                    :open.sync="openedPerms"
                    selected-color="primary"
                    :search="search"
                    hoverable
                    transition
                    selection-type="leaf"
                    v-else
                    :disabled="readonly"
                    @input="permissionIdsChanged"
                  >
                    <template #prepend="{ open, selected, leaf, indeterminate }">
                      <!-- S-{{ selected.toString() }} > I-{{ indeterminate.toString() }} -->
                      <i
                        v-if="leaf"
                        :class="{
                          fal: !selected,
                          fad: selected,
                          'info--text': !readonly && (selected || indeterminate),
                          'secondary--text': !readonly && !selected,
                        }"
                        class="fa-key"
                        style="font-size: 1.3rem"
                      >
                      </i>
                      <i
                        v-else-if="open"
                        :class="{
                          fal: !selected && !indeterminate,
                          fad: selected || indeterminate,
                          'info--text': !readonly && selected && !indeterminate,
                          'teal--text': !readonly && !selected && indeterminate,
                          'secondary--text': !readonly && !selected && !indeterminate,
                        }"
                        class="fa-folder-open"
                        style="font-size: 1.3rem"
                      >
                      </i>
                      <i
                        v-else
                        :class="{
                          'fal fa-folder': !selected && !indeterminate,
                          'fad fa-folder-minus': !selected && indeterminate,
                          'fas fa-folder': selected,
                          'info--text': !readonly && selected && !indeterminate,
                          'teal--text': !readonly && !selected && indeterminate,
                          'secondary--text': !readonly && !selected && !indeterminate,
                        }"
                        class="!selected && indeterminate"
                        style="font-size: 1.2rem"
                      >
                      </i>
                    </template>
                    <template #label="{ item, selected, indeterminate }">
                      <span
                        :class="{
                          'info--text': !readonly && selected && !indeterminate,
                          'teal--text': !readonly && !selected && indeterminate,
                          'secondary--text': !readonly && !selected && !indeterminate,
                        }"
                        style="font-size: 1rem"
                      >
                        <!-- {{ item.name.split(/(?=[A-Z])/).join(" ") }} -->
                        {{ item.name.match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g).join(" ") }}
                      </span>
                    </template>
                  </v-treeview>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="2" style="height: 100%">
        <v-container fluid class="pa-0" style="height: 100%">
          <div
            style="
              background: #eceff1;
              border-radius: 0;
              height: 100%;
              display: flex;
              flex-direction: column;
            "
            class="pa-3 pt-0 inset-shadow"
          >
            <v-form
              v-model="forms.tab2"
              ref="updateForm2"
              style="height: 100%; display: flex; flex-direction: column"
            >
              <div class="d-flex align-center mt-4">
                <h3 class="text--primary font-weight-black mr-3 d-inline-block">Assigned Users</h3>
                <v-btn
                  color="orange"
                  small
                  @click="createNewEntry()"
                  class="font-weight-bold"
                  :disabled="readonly"
                >
                  <i class="far fa-plus mr-2"></i>Add
                </v-btn>
              </div>
              <v-data-table
                style="
                  overflow: auto;
                  overflow: overlay;
                  height: 100%;
                  flex: 1 1 auto;
                  display: flex;
                  flex-direction: column;
                "
                dense
                :headers="usersHeaders"
                :items="selected.users"
                :items-per-page="-1"
                :item-class="rowClass"
                class="elevation-2 mt-2 table-sticky-header-exclude-footer"
                hide-default-footer
              >
                <template v-slot:progress>
                  <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
                </template>
                <template v-slot:[`item.user`]="{ item }">
                  <user-all-selector
                    :users="users"
                    required
                    hideLabel
                    hideDetails
                    hideBorder
                    :readonly="readonly || !item.newAssignment"
                    :isUsersLoading="isUsersLoading"
                    v-model="item.userId"
                    style="width: 100%; z-index: 100"
                    class="ml-0"
                  ></user-all-selector>
                </template>
                <template v-slot:[`item.actions`]="{ item, index }">
                  <div class="d-flex align-center" style="gap: 0.5rem">
                    <div v-if="item.markedForDelete">
                      <roles-selector-static
                        :roles="roles"
                        required
                        label="Assign to"
                        spanHorizontal
                        hideDetails
                        hideBorder
                        small
                        :readonly="readonly"
                        :except="[selected.id]"
                        :isRolesLoading="isRolesLoading"
                        v-model="item.roleId"
                        style="width: 100%; z-index: 100"
                        class="ml-0"
                      ></roles-selector-static>
                    </div>
                    <v-tooltip v-if="item.markedForDelete" top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="green"
                          :disabled="readonly"
                          @click="undoDeleteAssignedUser(item, index)"
                        >
                          <i class="far fa-undo"></i>
                        </v-btn>
                      </template>
                      <span>Reassign user back to this role</span>
                    </v-tooltip>
                    <v-tooltip v-else-if="item.newAssignment" top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          elevation="0"
                          color="error"
                          :disabled="readonly"
                          @click="deleteAssignedUser(item, index)"
                        >
                          <i class="fal fa-trash-alt"></i>
                        </v-btn>
                      </template>
                      <span>Remove this new user from this role</span>
                    </v-tooltip>
                    <v-tooltip v-else top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          elevation="0"
                          color="error"
                          :disabled="readonly"
                          @click="deleteAssignedUser(item, index)"
                        >
                          <i class="fal fa-trash-alt"></i>
                        </v-btn>
                      </template>
                      <span>
                        Move assigned user from this role and<br />assign them to another role
                      </span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:no-data>
                  <img width="300" src="/img/art/fogg-no-connection-2.png" />
                  <p class="font-weight-bold">No Data Available!</p>
                </template>
              </v-data-table>
            </v-form>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <template v-slot:footer>
      <v-card-actions>
        <h4
          v-if="duplicateUserEntries"
          class="d-flex align-center red lighten-5 red--text rounded-lg px-3"
          style="line-height: 28px"
        >
          <i class="fad fa-circle-exclamation mr-2"></i> Error: Duplicate user entries!
        </h4>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && readonly && isMainRoute && $has(perms.Roles.Update)"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  duplicateUserEntries ||
                  !valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  duplicateUserEntries ||
                  !valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchRole()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Role</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="openCloneModal()" v-if="$has(perms.Roles.Create)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-clone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Clone</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              class="my-1"
              v-if="$has(perms.Roles.Create) && $has(perms.Roles.Delete)"
            ></v-divider>
            <v-list-item @click="del" v-if="$has(perms.Roles.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.Roles.Create) && !$has(perms.Roles.Delete)"
            >
              No Actions Available!
            </v-subheader>
          </v-list>
        </v-menu>
      </v-card-actions>

      <clone-role ref="cloneRoleModal" @clone="onRoleCloned"></clone-role>
    </template>
  </slideout>
</template>

<script>
import rolesService from "../services/roles-service";
import perms from "../../../plugins/permissions";
import usersAPI from "../../Admin/services/StaffService";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import permissionsService from "../services/permissions-service";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import CloneRole from "./CloneRole.vue";
import RolesSelectorStatic from "../../Shared/components/RolesSelectorStatic.vue";

export default {
  name: "edit-role",
  components: { PanelSizeControl, CloneRole, UserAllSelector, RolesSelectorStatic },
  data() {
    return {
      perms,
      roleId: null,
      isFetching: false,
      permissionsLoading: false,
      usersHeaders: [
        {
          text: "User",
          value: "user",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          width: "400px",
          sortable: false,
        },
      ],
      openedPerms: ["all"],
      users: [],
      isUsersLoading: false,
      roles: [],
      isRolesLoading: false,
      readonly: false,
      selected: {
        name: null,
        users: [],
        permissionIds: [],
        isDefault: false,
      },
      selectedCemented: {},
      forms: {
        tab0: true,
        tab1: true,
        tab2: true,
      },
      slideouts: {
        update: {
          tab: 0,
          active: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
          fullWidth: false,
        },
      },
      permissions: [],
      search: null,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valid() {
      if (this.forms.tab0 && this.forms.tab1 && this.forms.tab2) return true;
      return false;
    },
    roleUrl() {
      if (this.selected == null) return "";
      return `roles/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-role") return true;
      else return false;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "800px";
    },
    duplicateUserEntries() {
      if (!this.slideouts.update.active || this.selected.users == null) return false;
      var userIds = this.selected.users.filter((e) => e.userId).map((e) => e.userId);
      if (new Set([...userIds]).size != userIds.length) return true;
      return false;
    },
  },
  mounted() {
    this.getAllUsersData();
    this.getAllRolesData();
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    createNewEntry() {
      this.$log("createNewEntry", this.cloneDeep(this.selected.users));
      this.selected.users.push({
        userId: null,
        roleId: this.selected.id,
        markedForDelete: false,
        newAssignment: true,
      });
      this.$log("createNewEntry", this.cloneDeep(this.selected.users));
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    getAllRolesData() {
      this.isRolesLoading = true;
      rolesService
        .get({
          itemsPerPage: -1,
        })
        .then((resp) => {
          this.$log("getAllRolesData", this.roles);
          this.roles = resp.data.items || [];
          this.isRolesLoading = false;
        })
        .catch(() => {
          this.isRolesLoading = false;
        });
    },
    rowClass(item) {
      if (item.markedForDelete) return "red lighten-5";
      return "";
    },
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        !this.duplicateUserEntries &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        !this.duplicateUserEntries &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        this.readonly &&
        !this.isFetching &&
        this.isMainRoute &&
        (!this.selected.isSystem || this.currentUser.isSuperUser) &&
        this.$has(this.perms.Roles.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    onRoleCloned(roleData) {
      this.$log("EditRole => onRoleCloned", roleData);
      this.closeUpdateSlideout();
      setTimeout(() => {
        this.$emit("clone", roleData);
      }, 500);
    },
    openCloneModal() {
      this.$refs.cloneRoleModal.open(this.selected);
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementRole();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewRole) {
      this.$emit("save", this.selected, isNewRole);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      if (!toSend.id) {
        toSend.userIds = toSend.users.map((user) => user.userId);
      }
      rolesService
        .update(toSend)
        .then((resp) => {
          this.$log("rolesService update", resp.data);
          this.slideouts.update.isLoading = false;
          var message = "Role updated successfully!";

          this.selected = this.prepareRole(this.cloneDeep(resp.data));
          this.cementRole();
          if (this.isMainRoute) document.title = this.selected.name + " Role | Concordia";

          var isNewRole = false;
          if (!toSend.id) {
            this.roleId = this.selected.id;
            isNewRole = true;
            message = "Role added successfully!";
          }
          this.announceChange(isNewRole);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
      this.slideouts.update.isLoading = false;
    },
    open(id, editMode = false) {
      this.getPermissions();
      this.openedPerms = ["all"];
      if (id == null) {
        this.selected = {
          name: null,
          users: [],
          permissionIds: [],
          isDefault: false,
        };
        this.readonly = false;
        this.isFetching = false;
        this.roleId = null;
        this.cementRole();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
        setTimeout(() => {
          this.$refs.roleName.focus();
        }, 250);
      } else {
        this.readonly = !editMode;
        this.roleId = id;
        this.fetchRole();
      }
      this.slideouts.update.active = true;
    },
    fetchRole() {
      this.isFetching = true;
      rolesService
        .getRoleById(this.roleId)
        .then((resp) => {
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.prepareRole(this.cloneDeep(resp.data));
          this.cementRole();
          if (this.isMainRoute) document.title = this.selected.name + " Role | Concordia";
        })
        .catch((err) => {
          this.closeUpdateSlideout();
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    prepareRole(roleData) {
      roleData.permissionIds = roleData.permissions.map((elm) => elm.id);
      roleData.permissionIds.sort();
      roleData.users = roleData.userIds.map((userId) => {
        return {
          userId: userId,
          roleId: roleData.id,
          markedForDelete: false,
          newAssignment: false,
        };
      });
      delete roleData.permissions;
      delete roleData.userIds;
      return roleData;
    },
    getPermissions() {
      this.permissionsLoading = true;
      permissionsService
        .get()
        .then((resp) => {
          this.$log("permissions get", resp.data);
          this.permissionsLoading = false;
          this.mapToTree(resp.data);
        })
        .catch((err) => {
          this.permissionsLoading = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    mapToTree(data) {
      this.permissions = [];
      data = data.reduce((a, elm) => {
        let name = elm.name.split(".");
        (a[name[0]] = a[name[0]] || {
          id: Math.random() * 100,
          name: name[0],
          children: [],
        }).children.push({
          name: name[1],
          id: elm.id,
        });
        return a;
      }, []);
      this.permissions.push({
        id: "all",
        name: "All Permissions",
        children: Object.values(data),
      });
    },
    permissionIdsChanged() {
      this.selected.permissionIds.sort();
      this.checkForChanges();
    },
    cementRole() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;
      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.updateForm, () => {})) return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog.warning({
        text: `Are you sure you want to delete this role: <b>${selected.name}</b>?<br><b class=>Note: </b>All users assigned to this role will be auto assigned to the Default Role!`,
        title: `Delete Role?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              return rolesService
                .delete(selected.id)
                .then((resp) => {
                  this.$emit("delete", selected.id);
                  this.closeUpdateSlideout();
                  this.$dialog.notify.success("Role deleted successfully", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  var message = "an error occurred during deleting the item";
                  this.$handleError(err, message);
                });
            },
          },
        },
      });
    },
    deleteAssignedUser(item, index) {
      if (item.newAssignment) {
        this.selected.users.splice(index, 1);
      } else {
        this.selected.users[index].markedForDelete = true;
        var defaultRole = this.roles.find((r) => r.isDefault);
        this.selected.users[index].roleId = defaultRole.id;
      }
      this.checkForChanges();
    },
    undoDeleteAssignedUser(item, index) {
      this.selected.users[index].markedForDelete = false;
      this.selected.users[index].roleId = this.selected.id;
      this.checkForChanges();
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.tree-wrapper {
  border: 1px dashed rgba($shades-black, 0.4);
}

.role-permissions-treeview {
  .v-treeview-node {
    .v-treeview-node__checkbox.mdi-minus-box:not(.v-icon--disabled) {
      color: #009688 !important;
    }
  }
}
</style>
