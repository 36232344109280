<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Jira Projects"
      show-subtitle
      subtitle="Browse DNA Jira Projects!"
      :badge="total"
    />
    <v-row class="mb-1" justify="space-between">
      <v-col cols="12" md="auto" class="d-flex align-center pt-0"></v-col>
      <v-col cols="12" md="auto" class="pt-0">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="isJiraProjectsLoading"
          >
          </v-text-field>
          <refresh :loading="isJiraProjectsLoading" @refresh="getJiraProjects()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      class="elevation-2 jira-projects-table table-sticky-header-exclude-footer"
      dense
      :headers="headers"
      :items="jiraProjectsFiltered"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="isJiraProjectsLoading"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-row align-content="center" justify="start" no-gutters style="flex: none" class="py-2">
          <v-col sm="auto" class="d-flex align-center mr-2">
            <v-img
              v-if="item.avatarUrls['48x48'] != null && item.avatarUrls['48x48'] != ''"
              :src="wrapAvatarUrl(item.avatarUrls['48x48'])"
              height="40"
              width="40"
              position="center center"
              contain
              class="rounded"
            >
            </v-img>
          </v-col>
          <v-col class="d-flex justify-center col-auto flex-column">
            <h4>{{ item.name }}</h4>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.projectCategory`]="{ item }">
        <v-chip
          v-if="item.projectCategory != null"
          small
          color="blue-grey lighten-5"
          class="fs-12px font-weight-medium my-1 py-0 blue-grey--text text--darken-2"
        >
          {{ item.projectCategory.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.isPrivate`]="{ item }">
        <v-chip
          v-if="item.isPrivate"
          small
          color="red"
          class="px-2"
          style="min-height: 20px !important; max-height: 20px !important; height: 20px !important"
          dark
        >
          Private
        </v-chip>
        <v-chip
          v-else
          small
          color="blue accent-3"
          class="px-2"
          style="min-height: 20px !important; max-height: 20px !important; height: 20px !important"
          dark
        >
          Public
        </v-chip>
      </template>

      <template v-slot:[`item.key`]="{ item }">
        <a
          v-if="item.key != null && item.key != ''"
          target="_blank"
          class="jira-external-link"
          @click.stop
          :href="'https://dangeloconsultants.atlassian.net/browse/' + item.key"
        >
          <span class="icon-wrpr mr-1">
            <i class="fab fa-jira org-icon"></i>
            <i class="far fa-external-link alt-icon"></i>
          </span>
          <span>{{ item.key }}</span>
        </a>
        <span v-else class="text--disabled">
          <i class="fad fa-unlink"></i>
        </span>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template #loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <view-jira-project ref="viewJiraProject"></view-jira-project>
  </v-container>
</template>

<script>
import jiraAPI from "../../../Projects/services/jira-service";
import PageTitle from "../../../Shared/components/PageTitle.vue";
import ViewJiraProject from "../components/ViewJiraProject.vue";
import jiraHeaders from "../configs/jira-projects.header";
import perms from "../../../../plugins/permissions";
import enums from "../../../../plugins/enums";

export default {
  components: {
    PageTitle,
    ViewJiraProject,
  },
  data() {
    return {
      enums,
      perms,
      total: 0,
      mainSearchInFocus: false,
      headers: jiraHeaders,
      jiraProjects: [],
      isJiraProjectsLoading: false,
      options: {
        itemsPerPage: 30,
        search: null,
        sortBy: ["name"],
        sortDesc: [false],
      },
    };
  },
  computed: {
    jiraProjectsFiltered() {
      if (this.options.search != null && this.options.search.trim() != "") {
        let searchTerm = this.options.search.toLowerCase();
        return this.jiraProjects.filter(
          (jiraProject) =>
            jiraProject.name.toLowerCase().indexOf(searchTerm) > -1 ||
            jiraProject.key.toLowerCase().indexOf(searchTerm) > -1
        );
      } else return this.jiraProjects;
    },
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.getJiraProjects();
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    getJiraProjects() {
      this.isJiraProjectsLoading = true;
      jiraAPI
        .projects()
        .then((resp) => {
          this.jiraProjects = resp.data;
          this.total = resp.data.length;
          this.isJiraProjectsLoading = false;
        })
        .catch((err) => {
          this.isJiraProjectsLoading = false;
          this.$handleError(err);
        });
    },
    jiraProjectsFilter(item, queryText, itemText) {
      const text1 = item.key.toLowerCase();
      const text2 = item.name.toLowerCase();
      const text3 = item.projectCategory == null ? "" : item.projectCategory.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1
      );
    },
    wrapAvatarUrl(url) {
      return jiraAPI.getAvatarWrapperUrl(url);
    },
    view(jiraProjectId) {
      this.$refs.viewJiraProject.open(jiraProjectId);
    },
    rowClicked(row) {
      this.view(row.id);
    },
  },
};
</script>
<style lang="scss">
.jira-projects-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
