<template>
  <v-container fluid class="pa-3" v-if="projectId != null && projectId != '' && projectId != NaN">
    <div
      style="
        background: #eceff1;
        border-radius: 0.5rem;
        height: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
      "
      class="inset-shadow pa-3"
    >
      <v-row justify="space-between" class="px-1">
        <v-col md="auto" sm="12" cols="12" class="d-flex flex-row align-center flex-wrap">
          <h3 class="text--primary font-weight-black ma-0 mr-4">
            Project Equipment:
            <v-chip class="ml-2 font-weight-bold" small label :loading="loadingStates.table">{{
              total
            }}</v-chip>
          </h3>
          <span style="font-weight: 500" class="mr-2">Sort By</span>
          <v-sheet elevation="2" height="32" rounded="xl" class="d-inline-flex">
            <v-btn-toggle v-model="options.sortBy" mandatory color="info" dense group rounded>
              <v-btn
                :value="'tag'"
                color="white"
                style="margin: 0 !important; text-transform: none"
              >
                Tag
              </v-btn>
              <v-btn
                :value="'model'"
                color="white"
                style="margin: 0 !important; text-transform: none"
              >
                Model
              </v-btn>
              <v-btn
                :value="'spaceCount'"
                color="white"
                style="margin: 0 !important; text-transform: none"
              >
                Space Count
              </v-btn>
            </v-btn-toggle>
          </v-sheet>
          <v-sheet elevation="2" height="32" rounded="xl" class="d-inline-flex ml-2">
            <v-btn-toggle v-model="options.sortDesc" mandatory color="info" dense group rounded>
              <v-tooltip top z-index="999" nudge-top="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :value="false"
                    color="white"
                    style="margin: 0 !important; text-transform: none"
                  >
                    <i v-if="options.sortBy == 'spaceCount'" class="far fa-arrow-down-1-9"></i>
                    <i v-else class="far fa-arrow-down-a-z"></i>
                  </v-btn>
                </template>
                <span>Ascending</span>
              </v-tooltip>
              <v-tooltip top z-index="999" nudge-top="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :value="true"
                    color="white"
                    style="margin: 0 !important; text-transform: none"
                  >
                    <i v-if="options.sortBy == 'spaceCount'" class="far fa-arrow-down-9-1"></i>
                    <i v-else class="far fa-arrow-down-z-a"></i>
                  </v-btn>
                </template>
                <span>Descending</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-sheet>
        </v-col>
        <v-col
          md="auto"
          sm="12"
          cols="12"
          class="d-flex flex-row align-center justify-end flex-wrap"
          style="gap: 0.5rem"
        >
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getProjectEquipment()"></refresh>
        </v-col>
      </v-row>
      <v-data-iterator
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page="options.itemsPerPage"
        :loading="loadingStates.table"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageText: 'Equipment per page:',
          firstIcon: 'far fa-arrow-to-left',
          lastIcon: 'far fa-arrow-to-right',
          prevIcon: 'far fa-angle-left',
          nextIcon: 'far fa-angle-right',
          itemsPerPageOptions: [15, 30],
        }"
        class="project-equipment-data-iterator mt-2 table-sticky-header-exclude-footer"
        style="
          position: relative;
          flex: 1 1 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
        "
        :hide-default-footer="entities.length == 0"
      >
        <template v-slot:default="props">
          <v-progress-linear
            color="info"
            indeterminate
            rounded
            absolute
            height="4"
            :props="props"
            style="z-index: 99999"
            :active="loadingStates.table"
          ></v-progress-linear>
          <v-expansion-panels v-model="expandedEquipmentPanels">
            <v-expansion-panel v-for="(projectEquipment, index) in props.items" :key="index">
              <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                <v-row no-gutters>
                  <v-col cols="auto" md="4" class="d-flex align-center">
                    <equipment-card :equipment="projectEquipment.equipment" noBorder small />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <span class="ml-2">
                      <v-chip
                        style="
                          height: 22px;
                          min-width: 22px;
                          justify-content: center;
                          font-size: 12px;
                        "
                        class="mr-1"
                        color="indigo"
                        text-color="white"
                      >
                        {{ projectEquipment.spaceCount }}
                      </v-chip>
                      <span class="fs-12px indigo--text"> Spaces</span>
                    </span>
                    <span class="ml-6">
                      <v-chip
                        style="
                          height: 22px;
                          min-width: 22px;
                          justify-content: center;
                          font-size: 12px;
                        "
                        class="mr-1"
                        color="indigo"
                        text-color="white"
                      >
                        {{ projectEquipment.equipmentCount }}
                      </v-chip>
                      <span class="fs-12px indigo--text"> Equipment</span>
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex align-center justify-space-between">
                  <h4>
                    <i class="fad fa-table-pivot mr-2"></i>Contained within the following spaces:
                  </h4>
                  <v-menu dense offset-y bottom left v-if="!readonly">
                    <template v-slot:activator="{ attrs: menuAttrs, on: menuOn }">
                      <v-tooltip left z-index="999">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="d-inline-flex" v-bind="menuAttrs" v-on="menuOn">
                            <v-btn color="indigo" v-bind="attrs" v-on="on" dark small>
                              Bulk Actions <i class="fad fa-caret-down ml-2"></i>
                            </v-btn>
                          </div>
                        </template>
                        <span>
                          Bulk change this equipment
                          <u>{{ projectEquipment.equipment.tag }}</u> occurrences in this project!
                        </span>
                      </v-tooltip>
                    </template>
                    <v-list class="more-options-menu">
                      <v-list-item @click="openBulkUpdateModal(projectEquipment.equipment)">
                        <v-list-item-icon class="mr-2 justify-center">
                          <i class="fad fa-pen secondary--text"></i>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="secondary--text font-weight-medium">
                            Bulk Update Equipment
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="openBulkReplaceModal(projectEquipment.equipment)">
                        <v-list-item-icon class="mr-2 justify-center">
                          <i class="fad fa-arrow-up-arrow-down secondary--text"></i>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="secondary--text font-weight-medium">
                            Bulk Replace Equipment
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <v-card elevation="2" class="mt-2">
                  <v-data-table
                    dense
                    :headers="projectEquipmentSpacesHeader"
                    :items="projectEquipment.spaces"
                    :item-class="spaceClass"
                    class="project-equipment-spaces-table elevation-2"
                    :items-per-page="-1"
                    hide-default-footer
                    @click:row="spaceRowClicked($event.space, projectEquipment.equipment)"
                  >
                    <template v-slot:progress>
                      <v-progress-linear
                        color="blue"
                        absolute
                        top
                        indeterminate
                      ></v-progress-linear>
                    </template>

                    <template v-slot:[`header.actions`]>
                      <i class="fad fa-cog pl-2"></i>
                    </template>

                    <template v-slot:[`item.space.imageUrl`]="{ item }">
                      <v-tooltip right z-index="999" nudge-right="-4px">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <div class="space-img">
                              <div
                                style="height: 100%; width: 100%"
                                v-viewer
                                @click.stop
                                v-if="item.space.imageUrl != null && item.space.imageUrl != ''"
                              >
                                <img
                                  :key="item.space.id + '_img'"
                                  :src="item.space.imageUrl"
                                  height="100%"
                                  width="100%"
                                />
                              </div>
                              <i v-else :class="'fad fa-table-pivot'"></i>
                            </div>
                          </div>
                        </template>
                        <span>
                          <v-img
                            v-if="(item.space.imageUrl != null) & (item.space.imageUrl != '')"
                            class="img"
                            :src="item.space.imageUrl"
                            height="250px"
                            width="250px"
                            contain
                          ></v-img>
                          <i
                            v-else
                            :class="'fad fa-table-pivot'"
                            style="font-size: 32px; padding: 2rem"
                          ></i>
                        </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:[`item.space.name`]="{ item }">
                      <strong>
                        <i
                          class="fad fa-grid-2-plus fs-15px deep-purple--text mr-2"
                          v-if="item.space.isBatch"
                        ></i>
                        {{ item.space.name }}</strong
                      >
                    </template>

                    <template v-slot:[`item.space.spaceNumber`]="{ item }">
                      <span class="mono-font font-weight-bold">{{ item.space.spaceNumber }}</span>
                    </template>

                    <template v-slot:[`item.space.state`]="{ item }">
                      <status-item
                        small
                        :statusList="enums.SPACE_STATUS"
                        :status="item.space.state"
                      ></status-item>
                    </template>

                    <template v-slot:[`item.space.template.name`]="{ item }">
                      <v-tooltip
                        top
                        z-index="999"
                        nudge-top="-8px"
                        v-if="item.space.template != null"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-row
                              @click.stop="openTemplateInNewTab(item.space.template.id)"
                              align-content="center"
                              justify="start"
                              no-gutters
                              style="flex: none; max-width: calc(100% - 40px); flex-wrap: nowrap"
                              class="py-0"
                            >
                              <v-col sm="auto" class="d-flex align-center mr-2">
                                <v-badge
                                  color="secondary"
                                  bordered
                                  left
                                  offset-x="15px"
                                  offset-y="28px"
                                >
                                  <template v-slot:badge>
                                    <i class="fad fa-code mb-0" style="font-size: 10px"></i>
                                  </template>
                                  <div class="typeahead-space-img">
                                    <v-img
                                      v-if="
                                        item.space.template.imageUrl != null &&
                                        item.space.template.imageUrl != ''
                                      "
                                      class="img"
                                      :src="item.space.template.imageUrl"
                                      height="100%"
                                      width="100%"
                                      contain
                                    ></v-img>
                                    <i v-else :class="'fad fa-table-pivot'"></i>
                                  </div>
                                </v-badge>
                              </v-col>
                              <v-col class="d-flex justify-center col-auto flex-column pl-1">
                                <strong class="space-name" style="font-size: 12px">{{
                                  item.space.template.name
                                }}</strong>
                              </v-col>
                            </v-row>
                          </div>
                        </template>
                        <span> View Space Template </span>
                      </v-tooltip>
                      <span v-else class="text--disabled">
                        <i class="fad fa-unlink"></i>
                      </span>
                    </template>

                    <template v-slot:[`item.space.createdBy`]="{ item }">
                      <user-avatar :user="item.space.createdBy" icon></user-avatar>
                    </template>

                    <template v-slot:[`item.space.updatedBy`]="{ item }">
                      <user-avatar :user="item.space.updatedBy" icon></user-avatar>
                    </template>

                    <template v-slot:[`item.space.createDate`]="{ item }">
                      <dater :date="item.space.createDate" date-only></dater>
                    </template>

                    <template v-slot:[`item.space.updateDate`]="{ item }">
                      <dater :date="item.space.updateDate" date-only></dater>
                    </template>

                    <template v-slot:[`item.space.equipmentCount`]="{ item }">
                      <equipment-count :count="item.space.equipmentCount" />
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn
                        small
                        icon
                        @click.stop="openUpdateSpace(item.space, projectEquipment.equipment)"
                        elevation="0"
                        class="secondary--text"
                      >
                        <i class="far fa-pen" style="font-size: 15px"></i>
                      </v-btn>
                    </template>

                    <template v-slot:no-data>
                      <img width="300" src="/img/art/fogg-no-connection-2.png" />
                      <p class="font-weight-bold">No Spaces Available!</p>
                    </template>
                  </v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-slot:no-data>
          <div class="text-center">
            <img width="375" class="mt-16" src="/img/art/tumble-weed.svg" />
            <p class="font-weight-bold text--secondary">No Equipment Available!</p>
          </div>
        </template>
        <template v-slot:loading>
          <v-row justify="center">
            <v-col sm="12" md="6" lg="4" class="d-flex flex-column align-center text-center py-16">
              <video
                width="275"
                muted
                loop
                autoplay
                class="inset-shadow-video"
                style="padding: 0.5rem; border-radius: 10rem; background: #fff"
              >
                <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
              </video>
              <p class="font-weight-bold mt-4">Loading Project Equipment...</p>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>

      <edit-project-equipment-space
        ref="editProjectEquipmentSpace"
        :project-id="projectId"
        @save="onSpaceSaved"
        @fetch="onSpaceFetch"
        :installersList="installersList"
        :providersList="providersList"
      ></edit-project-equipment-space>

      <v-dialog v-model="modals.bulkUpdateEquipment.active" max-width="600px" persistent>
        <v-card>
          <v-card-title class="font-weight-bold secondary white--text" small>
            <i class="fad fa-pen mr-4"></i> Bulk Update Equipment
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="pa-0 pt-4 secondary--text" v-if="modals.bulkUpdateEquipment.active">
              <h4 class="secondary--text mb-3" style="font-weight: 600 !important; font-size: 15px">
                Target Equipment
              </h4>
              <equipment-card :equipment="modals.bulkUpdateEquipment.equipment" noBorder small />
              <h3 class="mt-6 mb-2 fs-15px">
                <i class="fad fa-caret-right mr-2"></i>Bulk update all equipment occurrences in this
                project
              </h3>
              <v-container
                style="
                  background: #eceff1;
                  border-radius: 0.5rem;
                  height: 100%;
                  height: 100%;
                  flex-direction: column;
                  display: flex;
                  justify-content: center;
                "
                class="inset-shadow pa-3 mt-3"
              >
                <v-sheet elevation="2" class="pa-2" rounded="lg">
                  <v-row>
                    <v-col cols="12" md="4" class="d-flex align-center">
                      <v-checkbox
                        color="info"
                        v-model="modals.bulkUpdateEquipment.updateCount"
                        class="ma-0 pa-0"
                        hide-details
                        :disabled="modals.bulkUpdateEquipment.isLoading"
                      >
                        <template v-slot:label>
                          <label
                            class="secondary--text"
                            style="font-weight: 600 !important; font-size: 15px; cursor: pointer"
                            :class="{
                              'info--text': modals.bulkUpdateEquipment.updateCount,
                            }"
                          >
                            Update Count
                          </label>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="8">
                      <counter
                        v-model="modals.bulkUpdateEquipment.data.count"
                        :min="1"
                        :max="1000"
                        :readonly="
                          !modals.bulkUpdateEquipment.updateCount ||
                          modals.bulkUpdateEquipment.isLoading
                        "
                      />
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet elevation="2" class="mt-3 pa-2" rounded="lg">
                  <v-row>
                    <v-col cols="12" md="4" class="d-flex align-center">
                      <v-checkbox
                        color="info"
                        v-model="modals.bulkUpdateEquipment.updateProvider"
                        class="ma-0 pa-0"
                        hide-details
                        :disabled="modals.bulkUpdateEquipment.isLoading"
                      >
                        <template v-slot:label>
                          <label
                            class="secondary--text"
                            style="font-weight: 600 !important; font-size: 15px; cursor: pointer"
                            :class="{
                              'info--text': modals.bulkUpdateEquipment.updateProvider,
                            }"
                          >
                            Update Provider
                          </label>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="8" class="d-flex align-center" style="min-height: 52px">
                      <equipment-provider-selector-static
                        v-model="modals.bulkUpdateEquipment.data.providerId"
                        :providers-list="providersList"
                        showMenuIcon
                        :readonly="
                          !modals.bulkUpdateEquipment.updateProvider ||
                          modals.bulkUpdateEquipment.isLoading
                        "
                      ></equipment-provider-selector-static>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet elevation="2" class="mt-3 pa-2" rounded="lg">
                  <v-row>
                    <v-col cols="12" md="4" class="d-flex align-center">
                      <v-checkbox
                        color="info"
                        v-model="modals.bulkUpdateEquipment.updateInstaller"
                        class="ma-0 pa-0"
                        hide-details
                        :disabled="modals.bulkUpdateEquipment.isLoading"
                      >
                        <template v-slot:label>
                          <label
                            class="secondary--text"
                            style="font-weight: 600 !important; font-size: 15px; cursor: pointer"
                            :class="{
                              'info--text': modals.bulkUpdateEquipment.updateInstaller,
                            }"
                          >
                            Update Installer
                          </label>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="8" class="d-flex align-center" style="min-height: 52px">
                      <equipment-installer-selector-static
                        v-model="modals.bulkUpdateEquipment.data.installerId"
                        :installers-list="installersList"
                        showMenuIcon
                        :readonly="
                          !modals.bulkUpdateEquipment.updateInstaller ||
                          modals.bulkUpdateEquipment.isLoading
                        "
                      ></equipment-installer-selector-static>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-container>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeBulkUpdateModal" text>
              <i class="far fa-times mr-2"></i>Cancel
            </v-btn>
            <v-btn
              :disabled="!canUpdate || modals.bulkUpdateEquipment.isLoading"
              color="indigo"
              :dark="canUpdate && !modals.bulkUpdateEquipment.isLoading"
              @click="bulkUpdateConfirmed"
              :loading="modals.bulkUpdateEquipment.isLoading"
            >
              <i class="fas fa-check mr-2"></i>Update All</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="modals.bulkReplaceEquipment.active" max-width="600px" persistent>
        <v-card>
          <v-card-title class="font-weight-bold secondary white--text" small>
            <i class="fad fa-arrow-up-arrow-down mr-4"></i> Bulk Replace Equipment
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container
              class="pa-0 pt-4 secondary--text"
              v-if="modals.bulkReplaceEquipment.active"
            >
              <v-row>
                <v-col cols="12" md="3" class="d-flex align-center">
                  <label
                    class="secondary--text"
                    style="font-weight: 600 !important; font-size: 15px"
                  >
                    Old Equipment
                  </label>
                </v-col>
                <v-col cols="12" md="9" class="d-flex align-center">
                  <equipment-card
                    :equipment="modals.bulkReplaceEquipment.oldEquipment"
                    noBorder
                    small
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="d-flex align-center pr-0">
                  <label
                    class="secondary--text"
                    style="font-weight: 600 !important; font-size: 15px"
                  >
                    New Equipment<i class="fas fa-star-of-life pink--text label-icon"></i>
                  </label>
                </v-col>
                <v-col cols="12" md="9" class="d-flex align-center">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    hide-label
                    id="NewEquipment"
                    ref="NewEquipment"
                    placeholder="No Equipment Selected!"
                    :loading="modals.bulkReplaceEquipment.loadingEquipmentList"
                    v-model="modals.bulkReplaceEquipment.newEquipment"
                    :items="modals.bulkReplaceEquipment.equipmentList"
                    :search-input.sync="modals.bulkReplaceEquipment.searchEquipments"
                    dense
                    filled
                    no-filter
                    clearable
                    return-object
                    style="width: 100%"
                    hide-details
                    item-value="id"
                    item-text="tag"
                  >
                    <template v-slot:item="data">
                      <equipment-card
                        :key="'RE_' + data.item.id"
                        :equipment="data.item"
                        noBorder
                        small
                        class="py-2"
                      />
                    </template>
                    <template v-slot:selection="data">
                      <equipment-card
                        :key="'RES_' + data.item.id"
                        :equipment="data.item"
                        noBorder
                        small
                      />
                    </template>
                  </v-autocomplete-alt>
                </v-col>
              </v-row>

              <h3 class="mt-8 mb-2 fs-15px">
                <i class="fad fa-caret-right mr-2"></i> <b>Optional:</b> Bulk update new equipment
                after replace
              </h3>
              <v-container
                style="
                  background: #eceff1;
                  border-radius: 0.5rem;
                  height: 100%;
                  height: 100%;
                  flex-direction: column;
                  display: flex;
                  justify-content: center;
                "
                class="inset-shadow pa-3 mt-3"
              >
                <v-sheet elevation="2" class="pa-2" rounded="lg">
                  <v-row>
                    <v-col cols="12" md="4" class="d-flex align-center">
                      <v-checkbox
                        color="info"
                        v-model="modals.bulkReplaceEquipment.updateCount"
                        class="ma-0 pa-0"
                        hide-details
                        :disabled="modals.bulkReplaceEquipment.isLoading"
                      >
                        <template v-slot:label>
                          <label
                            class="secondary--text"
                            style="font-weight: 600 !important; font-size: 15px; cursor: pointer"
                            :class="{
                              'info--text': modals.bulkReplaceEquipment.updateCount,
                            }"
                          >
                            Update Count
                          </label>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="8">
                      <counter
                        v-model="modals.bulkReplaceEquipment.data.count"
                        :min="1"
                        :max="1000"
                        :readonly="
                          !modals.bulkReplaceEquipment.updateCount ||
                          modals.bulkReplaceEquipment.isLoading
                        "
                      />
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet elevation="2" class="mt-3 pa-2" rounded="lg">
                  <v-row>
                    <v-col cols="12" md="4" class="d-flex align-center">
                      <v-checkbox
                        color="info"
                        v-model="modals.bulkReplaceEquipment.updateProvider"
                        class="ma-0 pa-0"
                        hide-details
                        :disabled="modals.bulkReplaceEquipment.isLoading"
                      >
                        <template v-slot:label>
                          <label
                            class="secondary--text"
                            style="font-weight: 600 !important; font-size: 15px; cursor: pointer"
                            :class="{
                              'info--text': modals.bulkReplaceEquipment.updateProvider,
                            }"
                          >
                            Update Provider
                          </label>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="8" class="d-flex align-center" style="min-height: 52px">
                      <equipment-provider-selector-static
                        v-model="modals.bulkReplaceEquipment.data.providerId"
                        :providers-list="providersList"
                        showMenuIcon
                        :readonly="
                          !modals.bulkReplaceEquipment.updateProvider ||
                          modals.bulkReplaceEquipment.isLoading
                        "
                      ></equipment-provider-selector-static>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet elevation="2" class="mt-3 pa-2" rounded="lg">
                  <v-row>
                    <v-col cols="12" md="4" class="d-flex align-center">
                      <v-checkbox
                        color="info"
                        v-model="modals.bulkReplaceEquipment.updateInstaller"
                        class="ma-0 pa-0"
                        hide-details
                        :disabled="modals.bulkReplaceEquipment.isLoading"
                      >
                        <template v-slot:label>
                          <label
                            class="secondary--text"
                            style="font-weight: 600 !important; font-size: 15px; cursor: pointer"
                            :class="{
                              'info--text': modals.bulkReplaceEquipment.updateInstaller,
                            }"
                          >
                            Update Installer
                          </label>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="8" class="d-flex align-center" style="min-height: 52px">
                      <equipment-installer-selector-static
                        v-model="modals.bulkReplaceEquipment.data.installerId"
                        :installers-list="installersList"
                        showMenuIcon
                        :readonly="
                          !modals.bulkReplaceEquipment.updateInstaller ||
                          modals.bulkReplaceEquipment.isLoading
                        "
                      ></equipment-installer-selector-static>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-container>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeBulkReplaceModal" text>
              <i class="far fa-times mr-2"></i>Cancel
            </v-btn>
            <v-btn
              :disabled="!canReplace || modals.bulkReplaceEquipment.isLoading"
              color="indigo"
              :dark="canReplace && !modals.bulkReplaceEquipment.isLoading"
              @click="bulkReplaceConfirmed"
              :loading="modals.bulkReplaceEquipment.isLoading"
            >
              <i class="fas fa-check mr-2"></i>Replace</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import projectAPI from "../../Projects/services/projects-service";
import equipmentAPI from "../../Equipments/services/equipments-service.js";
import installersAPI from "../../Equipments/services/installers-service";
import providersAPI from "../../Equipments/services/providers-service";
import EquipmentAvailability from "../../Equipments/components/EquipmentAvailability.vue";
import EquipmentProvider from "../../Equipments/components/EquipmentProvider.vue";
import EquipmentInstaller from "../../Equipments/components/EquipmentInstaller.vue";
import equipmentHeader from "../../Equipments/config/tables/equipment.header";
import EquipmentCard from "../../Equipments/components/EquipmentCard.vue";
import projectEquipmentSpacesHeader from "../../Spaces/config/tables/projectEquipmentSpaces.header";
import EditProjectEquipmentSpace from "../../Spaces/components/EditProjectEquipmentSpace.vue";
import EquipmentProviderSelectorStatic from "../../Equipments/components/EquipmentProviderSelectorStatic.vue";
import EquipmentInstallerSelectorStatic from "../../Equipments/components/EquipmentInstallerSelectorStatic.vue";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  ManufacturerFilterSettings,
  ProductFamilyFilterSettings,
  EquipmentCategoryFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";

export default {
  name: "project-equipment",
  components: {
    EquipmentCard,
    EditProjectEquipmentSpace,
    EquipmentInstallerSelectorStatic,
    EquipmentProviderSelectorStatic,
    FilterManager,
  }, //EquipmentAvailability, EquipmentProvider, EquipmentInstaller,
  data() {
    return {
      perms,
      enums,
      storageKey: "ProjectEquipment",
      entities: [],
      expandedEquipmentPanels: [],
      total: 0,
      equipmentTimerId: null,
      mainSearchInFocus: false,
      storedSearch: null,
      options: {
        search: null,
        sortBy: "tag",
        sortDesc: false,
        createdByIds: [],
        updatedByIds: [],
        providerIds: [],
        installerIds: [],
        categoriesId: [],
        manufacturerIds: [],
        productFamilyIds: [],
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 15,
        multiSort: false,
        mustSort: false,
        page: 1,
      },
      loadingStates: {
        table: false,
      },
      selectedFilters: [],
      slideouts: {},
      modals: {
        bulkUpdateEquipment: {
          active: false,
          isLoading: false,
          equipment: null,
          updateCount: false,
          updateInstaller: false,
          updateProvider: false,
          data: {
            projectId: null,
            equipmentId: null,
            count: null,
            installerId: null,
            providerId: null,
          },
        },
        bulkReplaceEquipment: {
          active: false,
          isLoading: false,
          oldEquipment: null,
          newEquipment: null,
          equipmentList: [],
          replaceEquipmentTimerId: null,
          loadingEquipmentList: false,
          searchEquipments: null,
          updateCount: false,
          updateInstaller: false,
          updateProvider: false,
          data: {
            projectId: null,
            oldEquipmentId: null,
            newEquipmentId: null,
            count: null,
            installerId: null,
            providerId: null,
          },
        },
      },
      headers: equipmentHeader,
      projectEquipmentSpacesHeader,
      installersLoading: false,
      installersList: [],
      providersLoading: false,
      providersList: [],
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
    canUpdate() {
      var atLeastOnSelected =
        this.modals.bulkUpdateEquipment.updateCount ||
        this.modals.bulkUpdateEquipment.updateInstaller ||
        this.modals.bulkUpdateEquipment.updateProvider;

      var installerIsValid =
        !this.modals.bulkUpdateEquipment.updateInstaller ||
        (this.modals.bulkUpdateEquipment.updateInstaller &&
          this.modals.bulkUpdateEquipment.data.installerId != null);

      var providerIsValid =
        !this.modals.bulkUpdateEquipment.updateProvider ||
        (this.modals.bulkUpdateEquipment.updateProvider &&
          this.modals.bulkUpdateEquipment.data.providerId != null);

      return atLeastOnSelected && installerIsValid && providerIsValid;
    },
    canReplace() {
      var newEquipmentIsSelected = this.modals.bulkReplaceEquipment.data.newEquipmentId != null;

      var installerIsValid =
        !this.modals.bulkReplaceEquipment.updateInstaller ||
        (this.modals.bulkReplaceEquipment.updateInstaller &&
          this.modals.bulkReplaceEquipment.data.installerId != null);

      var providerIsValid =
        !this.modals.bulkReplaceEquipment.updateProvider ||
        (this.modals.bulkReplaceEquipment.updateProvider &&
          this.modals.bulkReplaceEquipment.data.providerId != null);

      return newEquipmentIsSelected && installerIsValid && providerIsValid;
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    this.getInstallers();
    this.getProviders();
  },
  methods: {
    initFilterManagerSettings() {
      var manufacturerFilterSettings = new ManufacturerFilterSettings();

      var productFamilyFilterSettings = new ProductFamilyFilterSettings();

      var equipmentCategoryFilterSettings = new EquipmentCategoryFilterSettings();

      this.selectedFilters = [
        manufacturerFilterSettings,
        productFamilyFilterSettings,
        equipmentCategoryFilterSettings,
      ];
    },
    ////////////
    onSpaceFetch() {},
    onSpaceSaved() {},
    ////////////
    openBulkUpdateModal(equipment) {
      this.modals.bulkUpdateEquipment.active = true;
      this.modals.bulkUpdateEquipment.isLoading = false;
      //////////
      this.modals.bulkUpdateEquipment.updateCount = false;
      this.modals.bulkUpdateEquipment.updateInstaller = false;
      this.modals.bulkUpdateEquipment.updateProvider = false;
      //////////
      this.modals.bulkUpdateEquipment.equipment = this.cloneDeep(equipment);
      this.modals.bulkUpdateEquipment.data.projectId = this.projectId;
      this.modals.bulkUpdateEquipment.data.equipmentId = equipment.id;
      this.modals.bulkUpdateEquipment.data.count = null;
      this.modals.bulkUpdateEquipment.data.installerId = null;
      this.modals.bulkUpdateEquipment.data.providerId = null;
    },
    closeBulkUpdateModal() {
      this.modals.bulkUpdateEquipment.active = false;
      this.modals.bulkUpdateEquipment.isLoading = false;
      //////////
      this.modals.bulkUpdateEquipment.updateCount = false;
      this.modals.bulkUpdateEquipment.updateInstaller = false;
      this.modals.bulkUpdateEquipment.updateProvider = false;
      //////////
      this.modals.bulkUpdateEquipment.equipment = null;
      this.modals.bulkUpdateEquipment.data.projectId = null;
      this.modals.bulkUpdateEquipment.data.equipmentId = null;
      this.modals.bulkUpdateEquipment.data.count = null;
      this.modals.bulkUpdateEquipment.data.installerId = null;
      this.modals.bulkUpdateEquipment.data.providerId = null;
    },
    bulkUpdateConfirmed() {
      this.modals.bulkUpdateEquipment.isLoading = true;
      let toSend = this.cloneDeep(this.modals.bulkUpdateEquipment.data);
      this.$log("toSend", this.cloneDeep(toSend));

      projectAPI
        .bulkUpdateEquipmentInProject(toSend)
        .then((resp) => {
          this.modals.bulkUpdateEquipment.isLoading = false;
          this.closeBulkUpdateModal();
          var message = "All equipment occurrences in this project are updated successfully!";
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.modals.bulkUpdateEquipment.isLoading = false;
          this.$handleError(err);
        });
    },
    ////////////
    openBulkReplaceModal(equipment) {
      this.modals.bulkReplaceEquipment.active = true;
      this.modals.bulkReplaceEquipment.isLoading = false;
      this.modals.bulkReplaceEquipment.equipmentList = [];
      this.modals.bulkReplaceEquipment.replaceEquipmentTimerId = null;
      this.modals.bulkReplaceEquipment.loadingEquipmentList = false;
      this.modals.bulkReplaceEquipment.searchEquipments = null;
      //////////
      this.modals.bulkReplaceEquipment.updateCount = false;
      this.modals.bulkReplaceEquipment.updateInstaller = false;
      this.modals.bulkReplaceEquipment.updateProvider = false;
      //////////
      this.modals.bulkReplaceEquipment.oldEquipment = this.cloneDeep(equipment);
      this.modals.bulkReplaceEquipment.newEquipment = null;
      this.modals.bulkReplaceEquipment.data.projectId = this.projectId;
      this.modals.bulkReplaceEquipment.data.oldEquipmentId = equipment.id;
      this.modals.bulkReplaceEquipment.data.newEquipmentId = null;
      this.modals.bulkReplaceEquipment.data.count = null;
      this.modals.bulkReplaceEquipment.data.installerId = null;
      this.modals.bulkReplaceEquipment.data.providerId = null;

      this.getEquipmentSearch();
    },
    closeBulkReplaceModal() {
      this.modals.bulkReplaceEquipment.active = false;
      this.modals.bulkReplaceEquipment.isLoading = false;
      this.modals.bulkReplaceEquipment.equipmentList = [];
      this.modals.bulkReplaceEquipment.replaceEquipmentTimerId = null;
      this.modals.bulkReplaceEquipment.loadingEquipmentList = false;
      this.modals.bulkReplaceEquipment.searchEquipments = null;
      //////////
      this.modals.bulkReplaceEquipment.updateCount = false;
      this.modals.bulkReplaceEquipment.updateInstaller = false;
      this.modals.bulkReplaceEquipment.updateProvider = false;
      //////////
      this.modals.bulkReplaceEquipment.oldEquipment = null;
      this.modals.bulkReplaceEquipment.newEquipment = null;
      this.modals.bulkReplaceEquipment.data.projectId = null;
      this.modals.bulkReplaceEquipment.data.oldEquipmentId = null;
      this.modals.bulkReplaceEquipment.data.newEquipmentId = null;
      this.modals.bulkReplaceEquipment.data.count = null;
      this.modals.bulkReplaceEquipment.data.installerId = null;
      this.modals.bulkReplaceEquipment.data.providerId = null;
    },
    bulkReplaceConfirmed() {
      this.modals.bulkReplaceEquipment.isLoading = true;
      let toSend = this.cloneDeep(this.modals.bulkReplaceEquipment.data);
      this.$log("toSend", this.cloneDeep(toSend));

      projectAPI
        .bulkReplaceEquipmentInProject(toSend)
        .then((resp) => {
          this.modals.bulkReplaceEquipment.isLoading = false;
          this.closeBulkReplaceModal();
          this.getProjectEquipment();
          this.expandedEquipmentPanels = [];
          var message = "Equipment replaced successfully!";
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.modals.bulkReplaceEquipment.isLoading = false;
          this.$handleError(err);
        });
    },
    getEquipmentSearchDebounced() {
      if (this.modals.bulkReplaceEquipment.replaceEquipmentTimerId == null) {
        this.modals.bulkReplaceEquipment.replaceEquipmentTimerId = -1;
        this.getEquipmentSearch();
        return;
      }
      // cancel pending call
      clearTimeout(this.modals.bulkReplaceEquipment.replaceEquipmentTimerId);

      // delay new call 400ms
      this.modals.bulkReplaceEquipment.replaceEquipmentTimerId = setTimeout(() => {
        this.getEquipmentSearch();
      }, 400);
    },
    getEquipmentSearch() {
      this.modals.bulkReplaceEquipment.loadingEquipmentList = true;

      equipmentAPI
        .typeHead(
          this.modals.bulkReplaceEquipment.searchEquipments
            ? this.modals.bulkReplaceEquipment.searchEquipments
            : "",
          false
        )
        .then((resp) => {
          this.modals.bulkReplaceEquipment.equipmentList = resp.data;
          this.modals.bulkReplaceEquipment.loadingEquipmentList = false;
        })
        .catch((err) => {
          this.modals.bulkReplaceEquipment.loadingEquipmentList = false;
          this.$handleError(err);
        });
    },
    ////////////
    openTemplateInNewTab(templateId) {
      window.open(`/space-templates/${templateId}`, "_blank");
    },
    getProjectEquipmentDebounced() {
      if (this.equipmentTimerId == null) {
        this.equipmentTimerId = -1;
        this.getProjectEquipment();
        return;
      }
      // cancel pending call
      clearTimeout(this.equipmentTimerId);

      // delay new call 400ms
      this.equipmentTimerId = setTimeout(() => {
        this.getProjectEquipment();
      }, 400);
    },
    getProjectEquipment() {
      this.$backToTop();
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 1;
      this.$log("######################## GET DATA ########################");
      this.$log("optionsToSend:", optionsToSend);
      this.storedSearch = optionsToSend.search;
      this.loadingStates.table = true;
      projectAPI
        .getEquipmentAndSpacesByProjectId(this.projectId, this.$clean(optionsToSend, true))
        .then((resp) => {
          this.$log("resp:", resp);
          this.total = resp.data.total;
          this.entities = resp.data.items;
          this.loadingStates.table = false;
          this.$log("entities:", this.entities);
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    spaceClass(item) {
      return [item.isBatch ? "batch-space" : "", item.viewed ? "viewed-space" : ""].join(" ");
    },
    spaceRowClicked(space, equipment) {
      this.$log("spaceRowClicked", space, equipment);
      this.$refs.editProjectEquipmentSpace.open(space, equipment, false);
    },
    openUpdateSpace(space, equipment) {
      this.$log("openUpdateSpace", space, equipment);
      this.$refs.editProjectEquipmentSpace.open(space, equipment, true);
    },
    getInstallers() {
      this.installersLoading = true;
      installersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.installersList = resp.data;
          this.installersLoading = false;
        })
        .catch((err) => {
          this.installersLoading = false;
          // this.$handleError(err);
        });
    },
    getProviders() {
      this.providersLoading = true;
      providersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.providersList = resp.data;
          this.providersLoading = false;
        })
        .catch((err) => {
          this.providersLoading = false;
          // this.$handleError(err);
        });
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectEquipmentDebounced();
      },
      deep: true,
    },
    projectId: {
      handler() {
        this.getProjectEquipmentDebounced();
      },
    },
    "modals.bulkUpdateEquipment.updateCount": {
      handler() {
        if (this.modals.bulkUpdateEquipment.updateCount) {
          this.modals.bulkUpdateEquipment.data.count = 1;
        } else {
          this.modals.bulkUpdateEquipment.data.count = null;
        }
      },
    },
    "modals.bulkUpdateEquipment.updateInstaller": {
      handler() {
        this.modals.bulkUpdateEquipment.data.installerId = null;
      },
    },
    "modals.bulkUpdateEquipment.updateProvider": {
      handler() {
        this.modals.bulkUpdateEquipment.data.providerId = null;
      },
    },
    "modals.bulkReplaceEquipment.updateCount": {
      handler() {
        if (this.modals.bulkReplaceEquipment.updateCount) {
          this.modals.bulkReplaceEquipment.data.count = 1;
        } else {
          this.modals.bulkReplaceEquipment.data.count = null;
        }
      },
    },
    "modals.bulkReplaceEquipment.updateInstaller": {
      handler() {
        this.modals.bulkReplaceEquipment.data.installerId = null;
      },
    },
    "modals.bulkReplaceEquipment.updateProvider": {
      handler() {
        this.modals.bulkReplaceEquipment.data.providerId = null;
      },
    },
    "modals.bulkReplaceEquipment.oldEquipment": {
      handler() {
        if (this.modals.bulkReplaceEquipment.oldEquipment == null) {
          this.modals.bulkReplaceEquipment.data.oldEquipmentId = null;
        } else {
          this.modals.bulkReplaceEquipment.data.oldEquipmentId =
            this.modals.bulkReplaceEquipment.oldEquipment.id;
        }
      },
    },
    "modals.bulkReplaceEquipment.newEquipment": {
      handler() {
        if (this.modals.bulkReplaceEquipment.newEquipment == null) {
          this.modals.bulkReplaceEquipment.data.newEquipmentId = null;
          this.modals.bulkReplaceEquipment.searchEquipments = null;
        } else {
          this.modals.bulkReplaceEquipment.data.newEquipmentId =
            this.modals.bulkReplaceEquipment.newEquipment.id;
        }
      },
    },
    "modals.bulkReplaceEquipment.searchEquipments": function (val) {
      this.getEquipmentSearchDebounced();
    },
  },
};
</script>

<style lang="scss">
.project-equipment-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
.project-equipment-data-iterator {
  overflow: hidden;

  // & > div:not(.v-data-footer) {
  //   height: 100%;
  // }

  & > .v-item-group {
    overflow: auto;
    overflow: overlay;
    padding: 0.35rem;
  }

  .v-data-footer {
    background: white;
    // border: 1px solid rgba($shades-black, 0.16);
    border-radius: 0.5rem;
    box-shadow: 0px 3px 1px -2px rgba(42, 54, 59, 0.16), 0px 2px 2px 0px rgba(42, 54, 59, 0.1),
      0px 1px 5px 0px rgba(42, 54, 59, 0.08) !important;
    margin: 0.25rem;
  }

  .v-expansion-panel-content__wrap {
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background: rgb(236, 239, 241);
    box-shadow: inset 0px 3px 1px -2px rgba(42, 54, 59, 0.16),
      inset 0px 2px 2px 0px rgba(42, 54, 59, 0.1), inset 0px 1px 5px 0px rgba(42, 54, 59, 0.08) !important;
  }

  .project-equipment-spaces-table {
    .v-data-table__wrapper {
      padding-bottom: 0 !important;
    }
    tbody tr:not(.v-data-table__empty-wrapper) {
      cursor: pointer;
    }
  }

  .v-expansion-panel-header {
    border: 1px solid #fff;

    &.v-expansion-panel-header--active {
      border-color: rgba($shades-black, 0.1);
    }
  }
}
</style>
