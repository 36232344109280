<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 975"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideout.active"
    v-on:close="onSlideoutClosing"
    style="z-index: 99999 !important"
    :class="readonly ? 'slideout-readonly-content' : ''"
  >
    <!-- :allow-resize="nested ? false : false" -->
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create Equipment
      </h3>
      <div>
        <panel-size-control v-model="slideout.fullWidth"></panel-size-control>
        <v-btn @click="cancel()" icon><i class="far fa-times fs-16px"></i></v-btn>
      </div>
    </template>
    <div
      color="white"
      style="position: sticky; top: 0; z-index: 2"
      @wheel="wheelIt"
      ref="tabsContainer"
    >
      <v-tabs v-model="slideout.tab" color="error" v-if="slideout.active" show-arrows>
        <v-tab :key="0">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!basicInfoTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Basic Information
        </v-tab>
        <v-tab :key="1">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!techDetailsTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Technical Details
        </v-tab>
        <v-tab :key="2">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!pricingTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Pricing
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-container fluid class="pa-0">
      <v-tabs-items v-model="slideout.tab">
        <v-tab-item :key="0" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab0" ref="updateForm0">
              <v-row class="my-0">
                <v-col cols="12" md="4" sm="12" class="py-0" v-if="false">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Category"
                    v-model="selected.categoryId"
                    id="equipmentCategory"
                    ref="equipmentCategory"
                    placeholder="Equipment Category"
                    :items="categories"
                    :filter="categoriesFilter"
                    dense
                    filled
                    item-text="id"
                    item-value="name"
                    :loading="slideout.categoryLoading"
                    :disabled="slideout.categoryLoading"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="data">
                      <v-row align-content="center" justify="start" no-gutters style="flex: none">
                        <v-col sm="auto" class="d-flex align-center mr-2">
                          <i :class="'equipment-icon fad ' + data.item.icon"></i>
                        </v-col>
                        <v-col class="d-flex align-center col-auto">
                          <div class="d-flex align-center">
                            {{ data.item.name }}
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item="data">
                      <v-row align-content="center" justify="start" no-gutters style="flex: none">
                        <v-col sm="auto" class="d-flex align-center mr-2">
                          <i :class="'equipment-icon fad ' + data.item.icon"></i>
                        </v-col>
                        <v-col class="d-flex align-center col-auto">
                          <div class="d-flex align-center">
                            {{ data.item.name }}
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-autocomplete-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <equipment-category-selector
                    required
                    v-model="selected.categoryId"
                    :obj.sync="selected.category"
                    :readonly="readonly"
                  ></equipment-category-selector>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.lengthMax(12), allRules.noWhiteSpaces]"
                    label="Tag"
                    id="equipmentTag"
                    ref="equipmentTag"
                    class="mono-font-text-field"
                    placeholder="XYZ-123"
                    v-model="selected.tag"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Availability"
                    id="equipmentAvailability"
                    placeholder="Availability State"
                    v-model="selected.availability"
                    :items="EquipmentAvailabilityList"
                    :readonly="readonly"
                    itemValue="value"
                    itemText="text"
                  >
                    <template v-slot:selection="{ item }">
                      <equipment-availability :availability="item.value"></equipment-availability>
                    </template>
                    <template v-slot:item="{ item }">
                      <equipment-availability :availability="item.value"></equipment-availability>
                    </template>
                  </v-select-alt>
                </v-col>
              </v-row>
              <manufacturer-product-family-editor
                ref="manufacturerProductFamilyEditor"
                :readonly="readonly"
                :manufacturerId.sync="selected.manufactureId"
                :productFamilyId.sync="selected.productFamilyId"
              ></manufacturer-product-family-editor>
              <v-row class="my-0">
                <!-- <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required]"
                    label="Manufacturer"
                    id="equipmentManufactureId"
                    placeholder="20044"
                    v-model="selected.manufactureId"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col> -->
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Model"
                    id="equipmentModel"
                    placeholder="XYZ-123"
                    class="mono-font-text-field"
                    v-model="selected.model"
                    @input="updatePartNumber"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    label="Part Number"
                    id="equipmentPartNumber"
                    placeholder="Part Number"
                    :rules="[allRules.noWhiteSpaces]"
                    v-model="selected.clientPartNumber"
                    class="mono-font-text-field"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0 alt-comp-wrapper">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Provider"
                    id="equipmentProvider"
                    placeholder="Provider"
                    v-model="selected.provider"
                    :items="providers"
                    :filter="providersFilter"
                    dense
                    filled
                    return-object
                    item-text="name"
                    item-value="id"
                    :loading="slideout.providerLoading"
                    :disabled="slideout.providerLoading"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="data">
                      <equipment-provider :provider-name="data.item.name"></equipment-provider>
                    </template>
                    <template v-slot:item="data">
                      <equipment-provider :provider-name="data.item.name"></equipment-provider>
                      <p
                        class="ma-0 ml-2 text--secondary"
                        v-if="data.item.description != null && data.item.description != ''"
                      >
                        {{ data.item.description }}
                      </p>
                    </template>
                  </v-autocomplete-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0 alt-comp-wrapper">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Installer"
                    id="equipmentInstaller"
                    placeholder="Installer"
                    v-model="selected.installer"
                    :items="installers"
                    :filter="installersFilter"
                    dense
                    filled
                    return-object
                    item-text="name"
                    item-value="id"
                    :loading="slideout.installerLoading"
                    :disabled="slideout.installerLoading"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="data">
                      <div style="font-size: 14px" class="d-block">
                        <equipment-installer :installer-name="data.item.name"></equipment-installer>
                      </div>
                    </template>
                    <template v-slot:item="data">
                      <equipment-installer :installer-name="data.item.name"></equipment-installer>
                      <p
                        class="ma-0 ml-2 text--secondary"
                        v-if="data.item.description != null && data.item.description != ''"
                      >
                        {{ data.item.description }}
                      </p>
                    </template>
                  </v-autocomplete-alt>
                  <!-- <v-btn
                    min-width="36px !important"
                    width="36px !important"
                    height="36px !important"
                    color="secondary"
                    class="pa-0"
                    rounded
                    @click="addNewInstaller()"
                    :disabled="slideout.installerLoading"
                    v-if="!readonly"
                  >
                    <i class="fal fa-plus"></i>
                  </v-btn> -->
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.url]"
                    label="URL"
                    id="equipmentHvacURL"
                    placeholder="https://www.example.com/Equipment"
                    v-model="selected.url"
                    :readonly="readonly"
                    :append-outer-icon="
                      selected.url != null && allRules.isUrl(selected.url) == true
                        ? 'fal fa-external-link fs-18px'
                        : ''
                    "
                    class="shrink"
                    @click:append-outer="openURL()"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0 mb-3">
                  <single-image-uploader
                    id="add-client-uploader"
                    :apiUrl="imageApiUrl"
                    v-model="selected.imageUrl"
                    label="Equipment Image"
                    :disabled="readonly"
                    :required="isImageRequired"
                  >
                    <template v-slot:actions>
                      <v-btn
                        small
                        text
                        elevation="0"
                        @click="copyManufacturerImage"
                        :disabled="readonly"
                      >
                        <span class="fs-12px"
                          ><i class="fad fa-copy mr-2"></i>Copy Manufacturer Image</span
                        >
                      </v-btn>
                    </template>
                  </single-image-uploader>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0 mb-3">
                  <v-textarea-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Description"
                    id="equipmentDescription"
                    placeholder="Equipment Description"
                    v-model="selected.description"
                    no-resize
                    height="165px"
                    hide-details="auto"
                    :readonly="readonly"
                  >
                    <template #label-append>
                      <div style="height: 24px">
                        <v-btn
                          v-if="allRules.noWhiteSpaces(selected.description) != true"
                          :disabled="readonly"
                          color="green darken-1"
                          dark
                          x-small
                          @click="cleanDescription()"
                        >
                          <i class="far fa-broom-wide mr-2"></i
                          ><span class="fs-10px">Clean Text</span>
                        </v-btn>
                      </div>
                    </template>
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="1" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab1" ref="updateForm1">
              <v-row class="my-0">
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    label="Weight (Lbs.)"
                    id="equipmentWeight"
                    ref="equipmentWeight"
                    placeholder="Weight (Lbs.)"
                    v-model="selected.weight"
                    :readonly="readonly"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-divider vertical class="mx-2"></v-divider>
                <v-col cols="auto" class="py-0 pr-0 d-flex align-center">
                  <h4 style="margin-top: 1px">
                    <i class="fad fa-container-storage fa-rotate-90 mr-2"></i>Equipment is a
                  </h4>
                </v-col>
                <v-col cols="auto" class="py-0 d-flex align-center">
                  <v-checkbox
                    color="info"
                    v-model="selected.isRack"
                    :readonly="readonly"
                    :disabled="readonly"
                    class="mt-0"
                    hide-details
                    @change="
                      (v) => {
                        if (v) selected.isRackMountable = false;
                      }
                    "
                  >
                    <template v-slot:label>
                      <label
                        class="v-switch-label"
                        :class="{
                          'info--text': selected.isRack,
                          'secondary--text': !selected.isRack,
                        }"
                        >Rack</label
                      >
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="auto" class="py-0 d-flex align-center">
                  <v-checkbox
                    color="info"
                    v-model="selected.isRackMountable"
                    :readonly="readonly"
                    :disabled="readonly"
                    class="mt-0"
                    hide-details
                    @change="
                      (v) => {
                        if (v) selected.isRack = false;
                      }
                    "
                  >
                    <template v-slot:label>
                      <label
                        class="v-switch-label"
                        :class="{
                          'info--text': selected.isRackMountable,
                          'secondary--text': !selected.isRackMountable,
                        }"
                        >Rack Mountable</label
                      >
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="auto" class="py-0">
                  <v-text-field-alt
                    :label="
                      selected.isRackMountable
                        ? 'Rack Units (RU)'
                        : selected.isRack
                        ? 'Rack Height'
                        : ''
                    "
                    placeholder="Rack Units (RU)"
                    v-model="selected.rackUnits"
                    :readonly="readonly"
                    v-if="selected.isRackMountable || selected.isRack"
                    :rules="[allRules.number, allRules.numberMin(0.01)]"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-divider class="mb-5"></v-divider>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    label="Power Consumption (Watts)"
                    id="equipmentPowerConsumption"
                    placeholder="PowerConsumption (Watts)"
                    v-model="selected.powerConsumption"
                    :readonly="readonly"
                    @input="onPowerConsumptionChange"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <label class="d-block mb-1" style="font-weight: 600 !important; font-size: 15px">
                    HeatLoad & HVAC
                    <span class="text--secondary">Calculation</span>
                  </label>
                  <v-btn-toggle
                    v-model="selected.powerAutoCalculate"
                    mandatory
                    color="info"
                    @change="onPowerAutoCalculateChange"
                  >
                    <v-btn small :value="false" :disabled="readonly">
                      <!-- <v-icon left> fal fa-lock-open-alt </v-icon> -->
                      <i class="fad fa-lock-open-alt mr-2 secondary--text"></i>
                      <span>Manual</span>
                    </v-btn>
                    <v-btn small :value="true" :disabled="readonly">
                      <!-- <v-icon left> fal fa-lock-alt </v-icon> -->
                      <i class="fad fa-lock-alt mr-2 secondary--text"></i>
                      <span>Auto Derived</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    id="equipmentHeatLoad"
                    placeholder="HeatLoad (BTU/Hr)"
                    :label="
                      selected.powerAutoCalculate
                        ? `HeatLoad (BTU/Hr) <i class='fad fa-lock-alt text--disabled mx-1'></i>`
                        : `HeatLoad (BTU/Hr)`
                    "
                    v-model="selected.heatload"
                    :readonly="readonly"
                    :class="selected.powerAutoCalculate ? '-font-italic' : ''"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    id="equipmentHvacTonnage"
                    placeholder="HVAC Tonnage"
                    :label="
                      selected.powerAutoCalculate
                        ? `HVAC Tonnage <i class='fad fa-lock-alt text--disabled mx-1'></i>`
                        : `HVAC Tonnage`
                    "
                    v-model="selected.hvacTonnage"
                    :readonly="readonly"
                    :class="selected.powerAutoCalculate ? 'font-italic' : ''"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="2" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab4" ref="updateForm3">
              <v-row class="my-0">
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.number, allRules.requiredNumber]"
                    label="MSRP"
                    id="equipmentMSRP"
                    ref="equipmentMSRP"
                    placeholder="MSRP"
                    v-model="selected.msrp"
                    @input="updateStreetPrice"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.number]"
                    label="Dealer Price"
                    id="equipmentDealerPrice"
                    placeholder="Dealer Price"
                    v-model="selected.dealerPrice"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.number, allRules.requiredNumber]"
                    label="Street Price"
                    id="equipmentStreetPrice"
                    placeholder="Street Price"
                    v-model="selected.streetPrice"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" text> <i class="far fa-xmark mr-2"></i> Cancel </v-btn>
        <v-btn @click="clear()" color="error" text>
          <i class="far fa-trash-can-list mr-2"></i> Clear Selection
        </v-btn>
        <v-divider vertical class="ml-4 mr-3"></v-divider>
        <v-tooltip top z-index="999" nudge-top="-4px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="jumpToRequiredField()"
              color="pink"
              elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              outlined
            >
              <i class="fas fa-asterisk fs-14px"></i>
            </v-btn>
          </template>
          <span>Jump to Required Field</span>
        </v-tooltip>
        <v-btn
          class="ml-2"
          v-if="!readonly"
          :disabled="!slideout.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>
        <v-btn color="info" :disabled="!valid || !slideout.hasChanges" @click="updateConfirmed">
          <i class="mr-2 far fa-check"></i> Save
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import Enums from "../../../plugins/enums";
import installersAPI from "../services/installers-service";
import providersAPI from "../services/providers-service";
import EquipmentInstaller from "./EquipmentInstaller.vue";
import EquipmentProvider from "./EquipmentProvider.vue";
import ManufacturerProductFamilyEditor from "./ManufacturerProductFamilyEditor.vue";
import EquipmentAvailability from "./EquipmentAvailability.vue";
import PanelSizeControl from "@Shared/components/PanelSizeControl.vue";
import EquipmentCategorySelector from "./EquipmentCategorySelector.vue";

export default {
  name: "create-equipment-slideout",
  data() {
    return {
      perms: perms,
      equipmentId: null,
      isFetching: false,
      readonly: false,
      imageApiUrl: `equipments/Image`,
      EquipmentAvailabilityList: Object.values(Enums.EquipmentAvailability),
      TechnicalDocumentType: Enums.TechnicalDocumentType,
      forms: {
        tab0: true,
        tab1: true,
        tab4: true,
      },
      categories: [],
      providers: [],
      installers: [],
      selected: {},
      selectedCemented: {},
      slideout: {
        active: false,
        fullWidth: false,
        hasChanges: false,
        tab: null,
        categoryLoading: false,
        providerLoading: false,
        installerLoading: false,
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    forceAllowEdit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getProviders();
    this.getInstallers();
  },
  beforeDestroy() {},
  computed: {
    isImageRequired() {
      if (
        this.selected != null &&
        this.selected.category != null &&
        this.selected.category.name == "Software"
      ) {
        return false;
      }
      return true;
    },
    mainPanelSize() {
      return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "68%";
    },
    equipmentUrl() {
      if (this.selected == null) return "";
      return `equipment/${this.selected.id}`;
    },
    equipmentAccessoriesCount() {
      if (this.selected != null && this.selected.accessories != null)
        return this.selected.accessories.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    suggestedEquipmentsCount() {
      if (this.selected?.suggestedEquipments)
        return this.selected.suggestedEquipments.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    valid() {
      if (this.forms.tab0 && this.forms.tab1 && this.forms.tab4) return true;
      return false;
    },
    isMainRoute() {
      if (this.$route.name == "equipment" || this.$route.name == "single-equipment") return true;
      else return false;
    },
    basicInfoTabIsValid() {
      return this.forms.tab0;
    },
    techDetailsTabIsValid() {
      return this.forms.tab1;
    },
    pricingTabIsValid() {
      return this.forms.tab4;
    },
  },
  methods: {
    cleanDescription() {
      this.selected.description = this.selected.description.replace(/\s+/g, " ").trim();
    },
    copyManufacturerImage() {
      var mfr = this.$refs.manufacturerProductFamilyEditor.manufacturer;
      if (mfr != null && mfr.logoUrl != null && mfr.logoUrl != "") {
        this.selected.imageUrl = mfr.logoUrl;
      } else if (mfr != null && (mfr.logoUrl == null || mfr.logoUrl == "")) {
        this.$dialog.notify.warning("Selected manufacturer don't have an image to use!", {
          position: "top-right",
          timeout: 3000,
        });
      } else {
        this.$dialog.notify.warning("No Manufacturer Selected!", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    onSlideoutClosing(e) {
      this.$console().log("onSlideoutClosing, active:", this.slideout.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideout.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementEquipment();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    categoriesFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    onProviderAdded(providerData, isNewProvider) {
      this.$log("onProviderAdded", providerData, isNewProvider);
      if (isNewProvider) {
        this.addToArr(this.providers, providerData);
        this.selected.providerId = providerData.id;
        this.refreshProvidersAutocomplete();
      }
    },
    getProviders() {
      this.slideout.providerLoading = true;
      providersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.providers = resp.data;
          this.refreshProvidersAutocomplete();
          this.checkProvidersHasSelected();
          this.slideout.providerLoading = false;
        })
        .catch((err) => {
          this.slideout.providerLoading = false;
          this.$handleError(err);
        });
    },
    providersFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = (item.description || "").toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    refreshProvidersAutocomplete() {
      if (this.selected.providerId != null && this.providers.length > 0)
        this.selected.provider =
          this.providers.find((c) => c.id == this.selected.providerId) || null;
    },
    checkProvidersHasSelected() {
      if (
        this.selected.provider != null &&
        !this.providers.some((t) => t.id == this.selected.provider.id)
      ) {
        this.providers.unshift(this.cloneDeep(this.selected.provider));
      }
    },
    addNewInstaller() {
      this.$refs.addInstaller.open();
    },
    onInstallerAdded(installerData, isNewInstaller) {
      this.$log("onInstallerAdded", installerData, isNewInstaller);
      if (isNewInstaller) {
        this.addToArr(this.installers, installerData);
        this.selected.installerId = installerData.id;
        this.refreshInstallersAutocomplete();
      }
    },
    getInstallers() {
      this.slideout.installerLoading = true;
      installersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.installers = resp.data;
          this.refreshInstallersAutocomplete();
          this.checkInstallersHasSelected();
          this.slideout.installerLoading = false;
        })
        .catch((err) => {
          this.slideout.installerLoading = false;
          this.$handleError(err);
        });
    },
    installersFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = (item.description || "").toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    refreshInstallersAutocomplete() {
      if (this.selected.installerId != null && this.installers.length > 0)
        this.selected.installer =
          this.installers.find((c) => c.id == this.selected.installerId) || null;
    },
    checkInstallersHasSelected() {
      if (
        this.selected.installer != null &&
        !this.installers.some((t) => t.id == this.selected.installer.id)
      ) {
        this.installers.unshift(this.cloneDeep(this.selected.installer));
      }
    },
    updateConfirmed() {
      let toSend = this.cloneDeep(this.selected);
      toSend.providerId = toSend.provider.id;
      toSend.installerId = toSend.installer.id;
      this.$emit("save", toSend);
      this.closeUpdateSlideout();
    },
    closeUpdateSlideout() {
      this.selected = {};
      this.cementEquipment();
      this.slideout.active = false;
    },
    isNum(value) {
      // return /^[0-9]+.[0-9]+$/.test(value);
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    refreshHeatAndHVAC() {
      if (
        this.selected == null ||
        this.selected.powerAutoCalculate != true ||
        !this.isNum(this.selected.powerConsumption)
      )
        return;

      //Heat Load = POWER CONSUMPTION * 3.412
      //HVAC Tonnage = (POWER CONSUMPTION * 3.412) / 12000
      let pc = parseFloat(this.selected.powerConsumption);
      this.selected.heatload = (pc * 3.412).toFixed(2);
      this.selected.hvacTonnage = ((pc * 3.412) / 12000).toFixed(5);
    },
    open(equipment) {
      this.$log("################### equipment create modal open");
      this.slideout.tab = 0;
      if (equipment == null) {
        this.selected = {
          imageUrl: null,
          isRack: false,
          isRackMountable: false,
          rackUnits: 0,
          powerConsumption: null,
          powerAutoCalculate: true,
        };
      } else {
        this.selected = this.cloneDeep(equipment);
      }
      this.readonly = false;
      this.isFetching = false;
      this.equipmentId = null;
      this.slideout.fullWidth = false;
      this.slideout.active = true;
      this.cementEquipment();
    },
    handleFocus() {
      if (this.slideout.tab == 0) {
        setTimeout(() => {
          // this.$refs.equipmentTag.focus();
        }, 350);
      } else if (this.slideout.tab == 1) {
        setTimeout(() => {
          this.$refs.equipmentWeight.focus();
        }, 350);
      } else if (this.slideout.tab == 2) {
        setTimeout(() => {
          // this.$refs.equipmentWeight.focus();
        }, 350);
      } else if (this.slideout.tab == 3) {
        setTimeout(() => {
          this.$refs.equipmentMSRP.focus();
        }, 350);
      }
    },
    updatePartNumber() {
      this.selected.clientPartNumber = this.selected.model;
    },
    updateStreetPrice() {
      this.selected.streetPrice = this.selected.msrp;
    },
    cementEquipment() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideout.active) return;

      this.slideout.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      // this.$console().log("#### checkForChanges:", this.slideout.hasChanges);
      // if (this.slideout.hasChanges) {
      //   this.$log("%c==>> DIFF", "color: red", this.deepDiff(this.selectedCemented, this.selected));
      // }
      if (this.slideout.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    openURL() {
      window.open(this.selected.url);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm0, () => {
          this.slideout.tab = 0;
        })
      )
        return;
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm1, () => {
          this.slideout.tab = 1;
        })
      )
        return;
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm3, () => {
          this.slideout.tab = 2;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    onPowerAutoCalculateChange() {
      if (this.selected.powerAutoCalculate) {
        this.refreshHeatAndHVAC();
      }
    },
    onPowerConsumptionChange() {
      if (this.isNum(this.selected.powerConsumption)) {
        this.refreshHeatAndHVAC();
      }
    },
    cancel() {
      let toSend = this.cloneDeep(this.selected);
      if (this.valid) {
        toSend.providerId = toSend.provider.id;
        toSend.installerId = toSend.installer.id;
      } else {
        toSend = null;
      }
      this.$emit("cancel", toSend);
      this.closeUpdateSlideout();
    },
    clear() {
      this.$emit("clear", null);
      this.closeUpdateSlideout();
    },
  },
  watch: {
    equipment: {
      handler() {
        this.selected = this.cloneDeep(this.equipment || {});
        this.cementEquipment();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.checkProvidersHasSelected();
        this.checkInstallersHasSelected();

        this.checkForChanges();
      },
      deep: true,
    },
    "selected.tag": {
      handler(newValue) {
        if (this.selected != null && this.selected.tag != null)
          this.selected.tag = this.selected.tag.toUpperCase();
      },
    },
    "slideout.tab": {
      handler() {
        this.handleFocus();
      },
      deep: true,
    },
  },
  components: {
    EquipmentInstaller,
    EquipmentProvider,
    ManufacturerProductFamilyEditor,
    EquipmentAvailability,
    PanelSizeControl,
    EquipmentCategorySelector,
  },
};
</script>

<style lang="scss"></style>
