<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 700"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Layout View ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-street-view mr-2"></i>
          View: '{{ selected.name }}'
        </span>
        <span v-else>
          <i class="fad fa-pen-square mr-2"></i>
          Update: '{{ selected.name }}'
        </span>
        <entity-id
          class="ml-2"
          :label="selected.id"
          :path="layoutViewsUrl"
          title="Layout View"
        ></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a Layout View
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-form v-model="slideouts.update.valid" ref="updateForm">
      <v-container>
        <v-row class="my-0" dense>
          <v-col cols="12" sm="12">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="Name"
              id="layoutViewName"
              ref="layoutViewName"
              placeholder="FRONT ELEVATION"
              v-model="selected.name"
              :readonly="readonly"
            ></v-text-field-alt>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && readonly && isMainRoute && $has(perms.LayoutViews.Update)"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save" : "Create" }}
              <kbd class="light ml-1 fs-12px"> CTRL<span class="opacity-54">+</span>S </kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save & Close" : "Create & Close" }} Panel
              <kbd class="light ml-2 fs-12px">
                CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S
              </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchLayoutView()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Layout View</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="del" v-if="$has(perms.LayoutViews.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.LayoutViews.Update) && !$has(perms.LayoutViews.Delete)"
              >No Actions Available!</v-subheader
            >
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import ApiService from "../services/layout-views-service.js";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";

export default {
  name: "edit-layout-view",
  data() {
    return {
      perms: perms,
      layoutViewId: null,
      isFetching: false,
      imageApiUrl: `layout-views/Image`,
      readonly: false,
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          valid: false,
          active: false,
          fullWidth: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "700px";
    },
    layoutViewsUrl() {
      if (this.selected == null) return "";
      return `layout-views/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-layout-view") return true;
      else return false;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.$has(this.perms.LayoutViews.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementLayoutView();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewLayoutView) {
      this.$emit("save", this.selected, isNewLayoutView);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      this.$log("update >> toSend", toSend);
      ApiService.update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Layout View updated successfully!";
          this.$log(">>> updated", resp.data);
          this.selected = resp.data;
          this.cementLayoutView();
          var isNewLayoutView = false;
          if (!toSend.id) {
            isNewLayoutView = true;
            this.layoutViewId = this.selected.id;
            message = "Layout View added successfully!";
          }
          this.announceChange(isNewLayoutView);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      this.$log(">>>>>>> open", id, editMode);
      if (id == null) {
        this.selected = {
          name: "",
        };
        this.readonly = false;
        this.isFetching = false;
        this.layoutViewId = null;
        this.cementLayoutView();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
        setTimeout(() => {
          this.$refs.layoutViewName.focus();
        }, 250);
      } else {
        this.readonly = !editMode;
        this.layoutViewId = id;
        this.fetchLayoutView();
      }
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
      }, 250);
    },
    fetchLayoutView() {
      this.isFetching = true;
      ApiService.getLayoutViewById(this.layoutViewId)
        .then((resp) => {
          this.$log("getLayoutViewById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementLayoutView();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getLayoutViewById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    cementLayoutView() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.$log("########>>>>>>> cementLayoutView()");
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.updateForm, () => {})) return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: ` Are you sure you want to delete this layout view?<br/><h4>${this.selected.name}</h4>`,
          title: `Delete Layout View?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(this.selected.id)
                  .then((resp) => {
                    this.onDeleteLayoutViewSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Layout View deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Layout View!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDeleteLayoutViewSuccess(id) {
      this.$emit("delete", id);
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "selected.name": {
      handler() {
        this.$log("########>>>>>>> WATCH: selected.name");
        if (this.selected != null && this.selected.name != null)
          this.selected.name = this.selected.name.toUpperCase();
      },
      deep: true,
    },
  },
  components: { PanelSizeControl },
};
</script>
