<template>
  <div>
    <slideout
      dock="right"
      :size="mainPanelSize"
      :min-size="nested ? 400 : 900"
      :allow-resize="nested ? false : false"
      :append-to="nested ? '' : '#app'"
      class="update-construction-phase-slideout"
      :class="readonly ? 'slideout-readonly-content' : ''"
      :visible.sync="slideouts.update.active"
      v-on:close="onSlideoutClosing"
    >
      <template v-slot:header>
        <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
          <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Construction Phase ...
        </h3>
        <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1 d-flex align-center" small>
          <span v-if="readonly" class="mr-1">
            <i class="fad fa-pen-square mr-2"></i> Construction Phase: '{{ selected.name }}
          </span>
          <span v-else class="mr-1">
            <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}'</span
          >
          <v-chip
            color="deep-orange lighten-5"
            text-color="deep-orange darken-1"
            style="border: 1px solid #ff5722 !important; line-height: 0"
            label
            x-small
            v-if="isLinkedParent"
          >
            Linked Parent
          </v-chip>
          <v-chip
            color="teal lighten-5"
            text-color="teal darken-1"
            style="border: 1px solid #009688 !important; line-height: 0"
            label
            x-small
            v-else-if="isLinkedChild"
          >
            Linked Child
          </v-chip>
          <v-fade-transition mode="out-in">
            <div v-if="slideouts.update.tab != 0">
              <construction-phase-type class="ml-2" :type="selected.type"></construction-phase-type>
              <v-chip
                class="ml-2"
                color="indigo lighten-5"
                text-color="indigo darken-1"
                style="border: 1px solid #3f51b5 !important; line-height: 0; height: 28px"
              >
                {{ startMonthValue }}
              </v-chip>
            </div>
          </v-fade-transition>
        </h3>
        <h3 v-else class="font-weight-bold pa-1" small>
          <i class="fad fa-plus-square mr-2"></i> Create a Construction Phase
          <span v-if="selected.id == null && selected.contract != null">
            in: <b>{{ selected.contract.name }}</b></span
          >
        </h3>
        <div>
          <code
            v-if="readonly"
            class="text-caption ml-2 white secondary--text mr-2"
            style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
          >
            <span class="fa-stack mr-0">
              <i class="fad fa-pencil fa-stack-1x"></i>
              <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
            </span>
            Read-Only
          </code>
          <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
          <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>
      <div color="white" style="position: sticky; top: 0; z-index: 20">
        <v-tabs
          v-model="slideouts.update.tab"
          color="error"
          v-if="slideouts.update.active && !isFetching"
        >
          <v-tab :key="0">Basic Information</v-tab>
          <v-tab :key="1">Records</v-tab>
          <v-tab :key="2">Direct Expenses</v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>

      <v-container fluid class="pa-0 text-center" v-if="isFetching">
        <video
          width="320"
          muted
          loop
          autoplay
          style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
        >
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Fetching Construction Phase ...</p>
      </v-container>
      <v-container v-else fluid class="pa-0">
        <v-form v-model="slideouts.update.valid" ref="updateForm">
          <v-tabs-items v-model="slideouts.update.tab">
            <v-tab-item :key="0">
              <v-container fluid class="pa-6">
                <v-row class="my-0">
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-text-field-alt
                      :rules="[allRules.required, allRules.noWhiteSpaces]"
                      label="Name"
                      id="phaseName"
                      ref="phaseName"
                      placeholder="Name"
                      v-model="selected.name"
                      :readonly="readonly"
                    >
                    </v-text-field-alt>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-select-alt
                      :rules="[allRules.required]"
                      label="Type"
                      id="ConstructionPhaseType"
                      placeholder="Construction Phase Type"
                      v-model="selected.type"
                      :items="constructionPhaseTypes"
                      dense
                      filled
                      item-value="value"
                      item-text="text"
                      :readonly="readonly"
                    >
                      <template v-slot:selection="{ item }">
                        <construction-phase-type :type="item.value"></construction-phase-type>
                        <p class="ma-0 ml-2 text--secondary">
                          {{ getEnumMember(enums.CONSTRUCTION_PHASE_TYPE, item.value).description }}
                        </p>
                      </template>
                      <template v-slot:item="{ item }">
                        <construction-phase-type :type="item.value"></construction-phase-type>
                        <p class="ma-0 ml-2 text--secondary">
                          {{ getEnumMember(enums.CONSTRUCTION_PHASE_TYPE, item.value).description }}
                        </p>
                      </template>
                    </v-select-alt>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0" v-if="false">
                    <v-select-alt
                      :rules="[allRules.required]"
                      label="Date Range Type"
                      id="dateRangeType"
                      placeholder="Date Range Type"
                      v-model="selected.dateRangeType"
                      :items="DateRangeTypes"
                      dense
                      filled
                      item-value="value"
                      item-text="text"
                      readonly
                    >
                    </v-select-alt>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-menu
                      v-model="startMonthMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :disabled="readonly"
                      min-width="auto"
                      :nudge-top="20"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field-alt
                          :rules="[allRules.required]"
                          :class="readonly ? '' : 'calendar-input'"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          :disabled="readonly"
                          :label="isMultiple ? 'Start Month' : 'Target Month'"
                          id="startMonthValue"
                          :placeholder="isMultiple ? 'Start Month' : 'Target Month'"
                          v-model="startMonthValue"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field-alt>
                      </template>
                      <v-date-picker
                        color="orange darken-3"
                        header-color="secondary"
                        v-model="startMonthValue"
                        type="month"
                        :disabled="readonly"
                        @change="startMonthChanged"
                        @input="startMonthMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0" v-if="selected.id == null">
                    <label class="v-switch-label">Create Multiple Phases?</label>
                    <v-switch
                      v-model="isMultiple"
                      color="orange"
                      class="mt-2"
                      style="font-size: 14px !important"
                      hide-details
                    >
                    </v-switch>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0" v-if="isMultiple">
                    <v-menu
                      v-model="endMonthMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :disabled="readonly || isLinkedParent"
                      min-width="auto"
                      :nudge-top="20"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field-alt
                          :rules="[allRules.required]"
                          :class="readonly || isLinkedParent ? '' : 'calendar-input'"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          :disabled="readonly || isLinkedParent"
                          label="End Month"
                          id="endMonthValue"
                          placeholder="End Month"
                          v-model="endMonthValue"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field-alt>
                      </template>
                      <v-date-picker
                        color="orange darken-3"
                        header-color="secondary"
                        v-model="endMonthValue"
                        type="month"
                        :disabled="readonly || isLinkedParent"
                        @change="endMonthChanged"
                        @input="endMonthMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field-alt
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      :disabled="
                        readonly ||
                        selected.dateRangeType ==
                          enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE.Month.value
                      "
                      label="Start Date"
                      id="startDate"
                      placeholder="Start Date"
                      v-model="selected.startDate"
                    ></v-text-field-alt>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field-alt
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      :disabled="
                        readonly ||
                        selected.dateRangeType ==
                          enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE.Month.value
                      "
                      label="End Date"
                      id="endDate"
                      placeholder="End Date"
                      v-model="selected.endDate"
                    ></v-text-field-alt>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="1">
              <v-container fluid>
                <div style="background: #eceff1; border-radius: 0.5rem" class="pa-4 inset-shadow">
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0"
                        style="font-weight: 600 !important; font-size: 15px; flex: none"
                      >
                        Associate Consultant
                        <span class="text--secondary ml-1">(AC)</span>
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="ac_records_errors.length > 0">
                      <ul>
                        <li v-for="(err, i) in ac_records_errors" :key="i" class="red--text">
                          {{ err }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <template v-for="(record, i) in laborTypes.ac_records">
                          <construction-phase-record
                            :key="i"
                            style="width: 100%"
                            v-model="laborTypes.ac_records[i]"
                            :users="users"
                            :readonly="readonly"
                            :isUsersLoading="isUsersLoading"
                            :contract="selected"
                            @delete="deleteRecord(laborTypes.ac_records, i)"
                          ></construction-phase-record>
                        </template>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="addNewRecord(laborTypes.ac_records, enums.LABOR_TYPE.AC.value)"
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Record
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0"
                        style="font-weight: 600 !important; font-size: 15px; flex: none"
                      >
                        Senior Consultants
                        <span class="text--secondary ml-1">(SC)</span>
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="sc_records_errors.length > 0">
                      <ul>
                        <li v-for="(err, i) in sc_records_errors" :key="i" class="red--text">
                          {{ err }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <construction-phase-record
                          style="width: 100%"
                          v-for="(record, i) in laborTypes.sc_records"
                          :key="i"
                          v-model="laborTypes.sc_records[i]"
                          :users="users"
                          :readonly="readonly"
                          :isUsersLoading="isUsersLoading"
                          :contract="selected"
                          @delete="deleteRecord(laborTypes.sc_records, i)"
                        ></construction-phase-record>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="addNewRecord(laborTypes.sc_records, enums.LABOR_TYPE.SC.value)"
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Record
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0"
                        style="font-weight: 600 !important; font-size: 15px; flex: none"
                      >
                        Consultants
                        <span class="text--secondary ml-1">(C)</span>
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="c_records_errors.length > 0">
                      <ul>
                        <li v-for="(err, i) in c_records_errors" :key="i" class="red--text">
                          {{ err }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <construction-phase-record
                          style="width: 100%"
                          v-for="(record, i) in laborTypes.c_records"
                          :key="i"
                          v-model="laborTypes.c_records[i]"
                          :users="users"
                          :readonly="readonly"
                          :isUsersLoading="isUsersLoading"
                          :contract="selected"
                          @delete="deleteRecord(laborTypes.c_records, i)"
                        ></construction-phase-record>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="addNewRecord(laborTypes.c_records, enums.LABOR_TYPE.C.value)"
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Record
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0"
                        style="font-weight: 600 !important; font-size: 15px; flex: none"
                      >
                        Virtual Integrator
                        <span class="text--secondary ml-1">(VI)</span>
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="vi_records_errors.length > 0">
                      <ul>
                        <li v-for="(err, i) in vi_records_errors" :key="i" class="red--text">
                          {{ err }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <construction-phase-record
                          style="width: 100%"
                          v-for="(record, i) in laborTypes.vi_records"
                          :key="i"
                          v-model="laborTypes.vi_records[i]"
                          :users="users"
                          :readonly="readonly"
                          :isUsersLoading="isUsersLoading"
                          :contract="selected"
                          @delete="deleteRecord(laborTypes.vi_records, i)"
                        ></construction-phase-record>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="addNewRecord(laborTypes.vi_records, enums.LABOR_TYPE.VI.value)"
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Record
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0"
                        style="font-weight: 600 !important; font-size: 15px; flex: none"
                      >
                        BIM Specialist
                        <span class="text--secondary ml-1">(BIM)</span>
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="bim_records_errors.length > 0">
                      <ul>
                        <li v-for="(err, i) in bim_records_errors" :key="i" class="red--text">
                          {{ err }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <construction-phase-record
                          style="width: 100%"
                          v-for="(record, i) in laborTypes.bim_records"
                          :key="i"
                          v-model="laborTypes.bim_records[i]"
                          :users="users"
                          :readonly="readonly"
                          :isUsersLoading="isUsersLoading"
                          :contract="selected"
                          @delete="deleteRecord(laborTypes.bim_records, i)"
                        ></construction-phase-record>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate) &&
                            laborTypes.bim_records.length == 0
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="addNewBimRecord"
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Record
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0"
                        style="font-weight: 600 !important; font-size: 15px; flex: none"
                      >
                        Management
                        <span class="text--secondary ml-1">(MGT)</span>
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="mgt_records_errors.length > 0">
                      <ul>
                        <li v-for="(err, i) in mgt_records_errors" :key="i" class="red--text">
                          {{ err }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <construction-phase-record
                          style="width: 100%"
                          v-for="(record, i) in laborTypes.mgt_records"
                          :key="i"
                          v-model="laborTypes.mgt_records[i]"
                          :users="users"
                          :labor-types="laborTypes"
                          :contract="selected"
                          :readonly="readonly"
                          :isUsersLoading="isUsersLoading"
                          @delete="deleteRecord(laborTypes.mgt_records, i)"
                        ></construction-phase-record>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="addNewRecord(laborTypes.mgt_records, enums.LABOR_TYPE.MGT.value)"
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Record
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0"
                        style="font-weight: 600 !important; font-size: 15px; flex: none"
                      >
                        Technical
                        <span class="text--secondary ml-1">(TCN)</span>
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="tcn_records_errors.length > 0">
                      <ul>
                        <li v-for="(err, i) in tcn_records_errors" :key="i" class="red--text">
                          {{ err }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <construction-phase-record
                          style="width: 100%"
                          v-for="(record, i) in laborTypes.tcn_records"
                          :key="i"
                          v-model="laborTypes.tcn_records[i]"
                          :users="users"
                          :readonly="readonly"
                          :isUsersLoading="isUsersLoading"
                          :contract="selected"
                          @delete="deleteRecord(laborTypes.tcn_records, i)"
                        ></construction-phase-record>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="addNewRecord(laborTypes.tcn_records, enums.LABOR_TYPE.TCN.value)"
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Record
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0"
                        style="font-weight: 600 !important; font-size: 15px; flex: none"
                      >
                        SCD
                        <span class="text--secondary ml-1">(SCD)</span>
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="scd_records_errors.length > 0">
                      <ul>
                        <li v-for="(err, i) in scd_records_errors" :key="i" class="red--text">
                          {{ err }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <construction-phase-record
                          style="width: 100%"
                          v-for="(record, i) in laborTypes.scd_records"
                          :key="i"
                          v-model="laborTypes.scd_records[i]"
                          :users="users"
                          :readonly="readonly"
                          :isUsersLoading="isUsersLoading"
                          :contract="selected"
                          @delete="deleteRecord(laborTypes.scd_records, i)"
                        ></construction-phase-record>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="addNewSCDRecord"
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Record
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="2">
              <v-container fluid>
                <div style="background: #eceff1; border-radius: 0.5rem" class="pa-4 inset-shadow">
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0 align-center"
                        style="
                          font-weight: 700 !important;
                          font-size: 15px;
                          flex: none;
                          gap: 0.5rem;
                        "
                      >
                        <i class="fad ml-1" :class="enums.DIRECT_EXPENSE_CATEGORY.Travel.icon"></i>
                        Travel
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <template v-for="(directExpense, i) in directExpenses.travel_costs">
                          <construction-phase-direct-expense
                            :key="i"
                            style="width: 100%"
                            v-model="directExpenses.travel_costs[i]"
                            :readonly="readonly"
                            :contract="selected"
                            @delete="deleteDirectExpense(directExpenses.travel_costs, i)"
                          ></construction-phase-direct-expense>
                        </template>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="
                            addNewDirectExpense(
                              directExpenses.travel_costs,
                              enums.DIRECT_EXPENSE_CATEGORY.Travel.value
                            )
                          "
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Travel Expense
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0 align-center"
                        style="
                          font-weight: 700 !important;
                          font-size: 15px;
                          flex: none;
                          gap: 0.5rem;
                        "
                      >
                        <i
                          class="fad ml-1"
                          :class="enums.DIRECT_EXPENSE_CATEGORY.SubContractor.icon"
                        ></i>
                        Subcontractor
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <template v-for="(directExpense, i) in directExpenses.subcontractor_costs">
                          <construction-phase-direct-expense
                            :key="i"
                            style="width: 100%"
                            v-model="directExpenses.subcontractor_costs[i]"
                            :readonly="readonly"
                            :contract="selected"
                            @delete="deleteDirectExpense(directExpenses.subcontractor_costs, i)"
                          ></construction-phase-direct-expense>
                        </template>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="
                            addNewDirectExpense(
                              directExpenses.subcontractor_costs,
                              enums.DIRECT_EXPENSE_CATEGORY.SubContractor.value
                            )
                          "
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Subcontractor Expense
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-3 mt-1"></v-divider>
                  <v-row dense class="pa-2 labor-type-section" align="center">
                    <v-col cols="12" md="3" sm="12" class="py-0">
                      <label
                        class="d-flex mb-0 align-center"
                        style="
                          font-weight: 700 !important;
                          font-size: 15px;
                          flex: none;
                          gap: 0.5rem;
                        "
                      >
                        <i class="fad ml-1" :class="enums.DIRECT_EXPENSE_CATEGORY.Misc.icon"></i>
                        Miscellaneous
                      </label>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="py-2 d-flex align-center flex-column">
                      <v-slide-y-transition
                        group
                        mode="out-in"
                        style="width: 100%"
                        class="d-flex align-center flex-column"
                      >
                        <template v-for="(directExpense, i) in directExpenses.misc_costs">
                          <construction-phase-direct-expense
                            :key="i"
                            style="width: 100%"
                            v-model="directExpenses.misc_costs[i]"
                            :readonly="readonly"
                            :contract="selected"
                            @delete="deleteDirectExpense(directExpenses.misc_costs, i)"
                          ></construction-phase-direct-expense>
                        </template>
                        <v-btn
                          v-if="
                            !readonly &&
                            !selected.isLocked &&
                            $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                          "
                          key="btn"
                          color="info"
                          style="align-self: center"
                          @click="
                            addNewDirectExpense(
                              directExpenses.misc_costs,
                              enums.DIRECT_EXPENSE_CATEGORY.Misc.value
                            )
                          "
                        >
                          <i class="fal fa-plus mr-2"></i>
                          New Miscellaneous Expense
                        </v-btn>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-btn
            class="ml-2"
            v-if="!isFetching && !selected.isLocked && selected.id != null && isLinkedChild"
            @click="switchToParentPhase()"
            color="secondary"
          >
            <i class="fad fa-turn-up mr-2"></i> Switch to Parent Phase
          </v-btn>
          <v-btn
            class="ml-2"
            v-if="!isFetching && !selected.isLocked && selected.id != null && isLinkedParent"
            @click="unlinkPhase()"
            small
            dark
            color="deep-orange"
          >
            <i class="fad fa-link-slash mr-2"></i> Unlink Connected Phases
          </v-btn>
          <v-spacer></v-spacer>
          <div
            class="d-flex ml-2"
            v-if="
              !isFetching &&
              readonly &&
              !selected.isLocked &&
              isMainRoute &&
              $has(perms.ProjectContracts.ConstructionPhasesUpdate)
            "
          >
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="switchToEditMode()"
                  :disabled="isFetching || isLinkedChild"
                  color="orange"
                >
                  <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
              </span>
            </v-tooltip>
          </div>

          <div
            class="d-flex ml-2"
            v-if="!isFetching && !readonly && !selected.isLocked && !isLinkedChild"
          >
            <v-tooltip top z-index="999" nudge-top="-4px" key="refetch-btn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="slideouts.update.isLoading"
                  @click="jumpToRequiredField()"
                  color="error"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  class="ml-2"
                  outlined
                >
                  <i class="fas fa-asterisk fs-14px"></i>
                </v-btn>
              </template>
              <span>Jump to Required Field</span>
            </v-tooltip>
          </div>

          <v-btn
            class="ml-2"
            v-if="
              !isFetching &&
              !readonly &&
              !selected.isLocked &&
              selected.id != null &&
              !isLinkedChild
            "
            :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
            @click="discardChanges()"
            color="secondary"
          >
            <i class="fal fa-clock-rotate-left mr-2"></i> Discard
          </v-btn>

          <div
            class="d-flex ml-2"
            v-if="!isFetching && !readonly && !selected.isLocked && !isLinkedChild"
          >
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="info"
                  :disabled="
                    !slideouts.update.valid ||
                    !validRecords ||
                    slideouts.update.isLoading ||
                    !slideouts.update.hasChanges
                  "
                  @click="updateConfirmed(false)"
                  :loading="slideouts.update.isLoading"
                >
                  <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                  {{ selected.id ? "Save" : "Create" }}
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >{{ selected.id ? "Save" : "Create"
                }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
              </span>
            </v-tooltip>
          </div>
          <div
            class="d-flex ml-2"
            v-if="
              !isFetching &&
              !readonly &&
              !selected.isLocked &&
              $has(perms.ProjectContracts.ConstructionPhasesCreate) &&
              $has(perms.ProjectContracts.ConstructionPhasesUpdate) &&
              !isLinkedChild
            "
          >
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="cyan white--text"
                  :disabled="
                    !slideouts.update.valid ||
                    !validRecords ||
                    slideouts.update.isLoading ||
                    !slideouts.update.hasChanges
                  "
                  @click="updateConfirmed(true)"
                  :loading="slideouts.update.isLoading"
                >
                  <i class="fas fa-save mr-2"></i>
                  {{ selected.id ? "Save & Close" : "Create & Close" }}
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                  class="light ml-2 fs-12px"
                  >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
                >
              </span>
            </v-tooltip>
          </div>
          <div class="d-flex ml-2" v-if="selected.id != null">
            <v-tooltip top z-index="999" nudge-top="-4px" key="refetch-btn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  :loading="isFetching"
                  :disabled="
                    slideouts.update.isLoading || isFetching || slideouts.update.hasChanges
                  "
                  @click="fetchConstructionPhase()"
                >
                  <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
                </v-btn>
              </template>
              <span>Refetch Construction Phase</span>
            </v-tooltip>
          </div>
          <clone-construction-phase
            ref="cloneConstructionPhase"
            @save="onCloneConstructionPhaseSuccess"
          >
          </clone-construction-phase>
          <v-menu
            dense
            offset-y
            top
            left
            z-index="1000"
            v-if="selected.id != null && !isLinkedChild"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
                :elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <v-list class="more-options-menu">
              <v-list-item
                @click="clone"
                v-if="$has(perms.ProjectContracts.ConstructionPhasesUpdate) && !selected.isLocked"
              >
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-clone mr-2</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Clone</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-1"
                v-if="
                  $has(perms.ProjectContracts.ConstructionPhasesDelete) &&
                  $has(perms.ProjectContracts.ConstructionPhasesUpdate) &&
                  !selected.isLocked
                "
              ></v-divider>
              <v-list-item
                @click="del"
                v-if="$has(perms.ProjectContracts.ConstructionPhasesDelete) && !selected.isLocked"
              >
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-trash-alt red--text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="red--text">Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-subheader
                class="font-weight-bold"
                style="height: auto"
                v-if="
                  (!$has(perms.ProjectContracts.ConstructionPhasesDelete) &&
                    !$has(perms.ProjectContracts.ConstructionPhasesUpdate)) ||
                  selected.isLocked
                "
              >
                No Actions Available!
              </v-subheader>
            </v-list>
          </v-menu>
        </v-card-actions>
      </template>
    </slideout>
  </div>
</template>

<script>
import perms from "../../../plugins/permissions";
import constructionPhasesService from "../services/constructionPhases-service";
import ConstructionPhaseType from "./ConstructionPhaseType.vue";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import usersAPI from "../../Admin/services/StaffService";
import enums from "../../../plugins/enums";
import ConstructionPhaseRecord from "./ConstructionPhaseRecord.vue";
import ConstructionPhaseDirectExpense from "./ConstructionPhaseDirectExpense.vue";
import CloneConstructionPhase from "./CloneConstructionPhase.vue";

export default {
  name: "edit-construction-phase",
  data() {
    return {
      perms: perms,
      enums: enums,
      constructionPhaseTypes: this.$options.filters.EnumToList(enums.CONSTRUCTION_PHASE_TYPE, true),
      DateRangeTypes: this.$options.filters.EnumToList(
        enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE,
        true
      ),
      constructionPhaseId: null,
      isUsersLoading: false,
      isFetching: false,
      readonly: false,
      isMultiple: false,
      startMonthMenu: false,
      startMonthValue: null,
      endMonthMenu: false,
      endMonthValue: null,
      users: [],
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          valid: false,
          fullWidth: false,
          tab: null,
          hasChanges: null,
          isLoading: false,
          categoryLoading: false,
        },
      },
      laborTypes: {
        ac_records: [],
        sc_records: [],
        c_records: [],
        vi_records: [],
        bim_records: [],
        mgt_records: [],
        tcn_records: [],
        scd_records: [],
      },
      laborTypesCemented: {
        ac_records: [],
        sc_records: [],
        c_records: [],
        vi_records: [],
        bim_records: [],
        mgt_records: [],
        tcn_records: [],
        scd_records: [],
      },
      directExpenses: {
        travel_costs: [],
        subcontractor_costs: [],
        misc_costs: [],
      },
      directExpensesCemented: {
        travel_costs: [],
        subcontractor_costs: [],
        misc_costs: [],
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLinkedParent() {
      return this.selected != null && this.selected.isLinked && this.selected.parentId == null;
    },
    isLinkedChild() {
      return this.selected != null && this.selected.isLinked && this.selected.parentId != null;
    },
    ac_records_errors() {
      var errors = [];

      var usersIds = this.laborTypes.ac_records
        .filter((r) => r.recordType == 0)
        .map((u) => u.userId);
      if (new Set(usersIds).size != usersIds.length) {
        errors.push("Duplicate users found under the same Labor Type!");
      }
      var placeHolders = this.laborTypes.ac_records
        .filter((r) => r.recordType == 1)
        .map((u) => u.placeholderName);
      if (new Set(placeHolders).size != placeHolders.length) {
        errors.push("Duplicate records with same generic title added!");
      }
      if (this.laborTypes.ac_records.some((r) => r.recordType == 0 && r.userId == null)) {
        errors.push("Some records has no user selected!");
      }
      if (
        this.laborTypes.ac_records.some(
          (r) => r.recordType == 1 && (r.placeholderName == null || r.placeholderName == "")
        )
      ) {
        errors.push("Some records has no generic title added!");
      }
      return errors;
    },
    sc_records_errors() {
      var errors = [];

      var usersIds = this.laborTypes.sc_records
        .filter((r) => r.recordType == 0)
        .map((u) => u.userId);
      if (new Set(usersIds).size != usersIds.length) {
        errors.push("Duplicate users found under the same Labor Type!");
      }
      var placeHolders = this.laborTypes.sc_records
        .filter((r) => r.recordType == 1)
        .map((u) => u.placeholderName);
      if (new Set(placeHolders).size != placeHolders.length) {
        errors.push("Duplicate records with same generic title added!");
      }

      if (this.laborTypes.sc_records.some((r) => r.recordType == 0 && r.userId == null)) {
        errors.push("Some records has no user selected!");
      }
      if (
        this.laborTypes.sc_records.some(
          (r) => r.recordType == 1 && (r.placeholderName == null || r.placeholderName == "")
        )
      ) {
        errors.push("Some records has no generic title added!");
      }
      return errors;
    },
    c_records_errors() {
      var errors = [];
      var usersIds = this.laborTypes.c_records
        .filter((r) => r.recordType == 0)
        .map((u) => u.userId);
      if (new Set(usersIds).size != usersIds.length) {
        errors.push("Duplicate users found under the same Labor Type!");
      }
      var placeHolders = this.laborTypes.c_records
        .filter((r) => r.recordType == 1)
        .map((u) => u.placeholderName);
      if (new Set(placeHolders).size != placeHolders.length) {
        errors.push("Duplicate records with same generic title added!");
      }

      if (this.laborTypes.c_records.some((r) => r.recordType == 0 && r.userId == null)) {
        errors.push("Some records has no user selected!");
      }
      if (
        this.laborTypes.c_records.some(
          (r) => r.recordType == 1 && (r.placeholderName == null || r.placeholderName == "")
        )
      ) {
        errors.push("Some records has no generic title added!");
      }
      return errors;
    },
    vi_records_errors() {
      var errors = [];
      var usersIds = this.laborTypes.vi_records
        .filter((r) => r.recordType == 0)
        .map((u) => u.userId);
      if (new Set(usersIds).size != usersIds.length) {
        errors.push("Duplicate users found under the same Labor Type!");
      }
      var placeHolders = this.laborTypes.vi_records
        .filter((r) => r.recordType == 1)
        .map((u) => u.placeholderName);
      if (new Set(placeHolders).size != placeHolders.length) {
        errors.push("Duplicate records with same generic title added!");
      }

      if (this.laborTypes.vi_records.some((r) => r.recordType == 0 && r.userId == null)) {
        errors.push("Some records has no user selected!");
      }
      if (
        this.laborTypes.vi_records.some(
          (r) => r.recordType == 1 && (r.placeholderName == null || r.placeholderName == "")
        )
      ) {
        errors.push("Some records has no generic title added!");
      }
      return errors;
    },
    bim_records_errors() {
      var errors = [];
      var usersIds = this.laborTypes.bim_records
        .filter((r) => r.recordType == 0)
        .map((u) => u.userId);
      if (new Set(usersIds).size != usersIds.length) {
        errors.push("Duplicate users found under the same Labor Type!");
      }
      var placeHolders = this.laborTypes.bim_records
        .filter((r) => r.recordType == 1)
        .map((u) => u.placeholderName);
      if (new Set(placeHolders).size != placeHolders.length) {
        errors.push("Duplicate records with same generic title added!");
      }

      if (this.laborTypes.bim_records.some((r) => r.recordType == 0 && r.userId == null)) {
        errors.push("Some records has no user selected!");
      }
      if (
        this.laborTypes.bim_records.some(
          (r) => r.recordType == 1 && (r.placeholderName == null || r.placeholderName == "")
        )
      ) {
        errors.push("Some records has no generic title added!");
      }
      return errors;
    },
    mgt_records_errors() {
      var errors = [];
      var usersIds = this.laborTypes.mgt_records
        .filter((r) => r.recordType == 0)
        .map((u) => u.userId);
      if (new Set(usersIds).size != usersIds.length) {
        errors.push("Duplicate users found under the same Labor Type!");
      }
      var placeHolders = this.laborTypes.mgt_records
        .filter((r) => r.recordType == 1)
        .map((u) => u.placeholderName);
      if (new Set(placeHolders).size != placeHolders.length) {
        errors.push("Duplicate records with same generic title added!");
      }

      if (this.laborTypes.mgt_records.some((r) => r.recordType == 0 && r.userId == null)) {
        errors.push("Some records has no user selected!");
      }
      if (
        this.laborTypes.mgt_records.some(
          (r) => r.recordType == 1 && (r.placeholderName == null || r.placeholderName == "")
        )
      ) {
        errors.push("Some records has no generic title added!");
      }
      return errors;
    },
    tcn_records_errors() {
      var errors = [];
      var usersIds = this.laborTypes.tcn_records
        .filter((r) => r.recordType == 0)
        .map((u) => u.userId);
      if (new Set(usersIds).size != usersIds.length) {
        errors.push("Duplicate users found under the same Labor Type!");
      }
      var placeHolders = this.laborTypes.tcn_records
        .filter((r) => r.recordType == 1)
        .map((u) => u.placeholderName);
      if (new Set(placeHolders).size != placeHolders.length) {
        errors.push("Duplicate records with same generic title added!");
      }

      if (this.laborTypes.tcn_records.some((r) => r.recordType == 0 && r.userId == null)) {
        errors.push("Some records has no user selected!");
      }
      if (
        this.laborTypes.tcn_records.some(
          (r) => r.recordType == 1 && (r.placeholderName == null || r.placeholderName == "")
        )
      ) {
        errors.push("Some records has no generic title added!");
      }
      return errors;
    },
    scd_records_errors() {
      var errors = [];
      var usersIds = this.laborTypes.scd_records
        .filter((r) => r.recordType == 0)
        .map((u) => u.userId);
      if (new Set(usersIds).size != usersIds.length) {
        errors.push("Duplicate users found under the same Labor Type!");
      }
      var placeHolders = this.laborTypes.scd_records
        .filter((r) => r.recordType == 1)
        .map((u) => u.placeholderName);
      if (new Set(placeHolders).size != placeHolders.length) {
        errors.push("Duplicate records with same generic title added!");
      }

      if (this.laborTypes.scd_records.some((r) => r.recordType == 0 && r.userId == null)) {
        errors.push("Some records has no user selected!");
      }
      if (
        this.laborTypes.scd_records.some(
          (r) => r.recordType == 1 && (r.placeholderName == null || r.placeholderName == "")
        )
      ) {
        errors.push("Some records has no generic title added!");
      }
      return errors;
    },
    validRecords() {
      if (!this.slideouts.update.active) return true;

      return (
        this.ac_records_errors.length == 0 &&
        this.sc_records_errors.length == 0 &&
        this.c_records_errors.length == 0 &&
        this.vi_records_errors.length == 0 &&
        this.bim_records_errors.length == 0 &&
        this.mgt_records_errors.length == 0 &&
        this.tcn_records_errors.length == 0 &&
        this.scd_records_errors.length == 0
      );
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "900px";
    },
    isMainRoute() {
      return true;
      // if (this.$route.name == "naming-template") return true;
      // else return false;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    unlinkPhase() {
      this.$dialog
        .warning({
          text: `You will lose cascading updates to all connected phases?`,
          title: `Unlink Connected Phases?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Unlink",
              color: "error",
              handle: () => {
                return constructionPhasesService
                  .unlink(this.selected.id)
                  .then((resp) => {
                    this.announceUnlinked();
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Construction Phase unlinked successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error unlinking the Construction Phase!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    switchToParentPhase() {
      this.open(this.selected.parentId, false);
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges &&
        !this.isLinkedChild
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges &&
        !this.isLinkedChild
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.isMainRoute &&
        this.$has(this.perms.Projects.Update) &&
        !this.isLinkedChild
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    onDeleteConstructionPhaseSuccess(id) {
      this.$emit("delete", id);
    },
    convertSelectedRecordsToGroups() {
      this.laborTypes.ac_records = this.cloneDeep(
        this.selected.records.filter((r) => r.laborType == enums.LABOR_TYPE.AC.value)
      );
      this.laborTypes.sc_records = this.cloneDeep(
        this.selected.records.filter((r) => r.laborType == enums.LABOR_TYPE.SC.value)
      );
      this.laborTypes.c_records = this.cloneDeep(
        this.selected.records.filter((r) => r.laborType == enums.LABOR_TYPE.C.value)
      );
      this.laborTypes.vi_records = this.cloneDeep(
        this.selected.records.filter((r) => r.laborType == enums.LABOR_TYPE.VI.value)
      );
      this.laborTypes.bim_records = this.cloneDeep(
        this.selected.records.filter((r) => r.laborType == enums.LABOR_TYPE.BIM.value)
      );
      this.laborTypes.mgt_records = this.cloneDeep(
        this.selected.records.filter((r) => r.laborType == enums.LABOR_TYPE.MGT.value)
      );
      this.laborTypes.tcn_records = this.cloneDeep(
        this.selected.records.filter((r) => r.laborType == enums.LABOR_TYPE.TCN.value)
      );
      this.laborTypes.scd_records = this.cloneDeep(
        this.selected.records.filter((r) => r.laborType == enums.LABOR_TYPE.SCD.value)
      );
    },
    convertRecordsGroupsToSingleList() {
      var list = [];
      list.push(...this.laborTypes.ac_records);
      list.push(...this.laborTypes.sc_records);
      list.push(...this.laborTypes.c_records);
      list.push(...this.laborTypes.vi_records);
      list.push(...this.laborTypes.bim_records);
      list.push(...this.laborTypes.mgt_records);
      list.push(...this.laborTypes.tcn_records);
      list.push(...this.laborTypes.scd_records);
      return list;
    },
    convertDirectExpensesGroupsToSingleList() {
      var list = [];
      list.push(...this.directExpenses.travel_costs);
      list.push(...this.directExpenses.subcontractor_costs);
      list.push(...this.directExpenses.misc_costs);
      return list;
    },
    addNewBimRecord() {
      var email = "mohamed@dangeloconsultants.com";
      var userId = null;
      var user = this.users.find((u) => u.email == email);
      if (user) userId = user.id;
      this.laborTypes.bim_records.push({
        id: 0,
        laborType: enums.LABOR_TYPE.BIM.value,
        recordType: enums.ConstructionPhaseRecordType.AssignedUser.value,
        placeholderName: null,
        userId: userId,
        hours: 0,
      });
    },
    addNewSCDRecord() {
      var email = "scd@dangeloconsultants.com";
      var userId = null;
      var user = this.users.find((u) => u.email == email);
      if (user) userId = user.id;
      if (this.laborTypes.scd_records.some((r) => r.userId == userId)) {
        this.addNewRecord(this.laborTypes.scd_records, enums.LABOR_TYPE.SCD.value);
      } else {
        this.laborTypes.scd_records.push({
          id: 0,
          laborType: enums.LABOR_TYPE.SCD.value,
          recordType: enums.ConstructionPhaseRecordType.AssignedUser.value,
          placeholderName: null,
          userId: userId,
          hours: 0,
        });
      }
    },
    addNewRecord(list, laborType) {
      list.push({
        id: 0,
        laborType: laborType,
        recordType: enums.ConstructionPhaseRecordType.AssignedUser.value,
        placeholderName: null,
        userId: null,
        hours: 0,
      });
    },
    deleteRecord(list, idx) {
      list.splice(idx, 1);
    },
    convertSelectedDirectExpensesToGroups() {
      this.directExpenses.travel_costs = this.cloneDeep(
        this.selected.directExpenses.filter(
          (r) => r.category == enums.DIRECT_EXPENSE_CATEGORY.Travel.value
        )
      );
      this.directExpenses.subcontractor_costs = this.cloneDeep(
        this.selected.directExpenses.filter(
          (r) => r.category == enums.DIRECT_EXPENSE_CATEGORY.SubContractor.value
        )
      );
      this.directExpenses.misc_costs = this.cloneDeep(
        this.selected.directExpenses.filter(
          (r) => r.category == enums.DIRECT_EXPENSE_CATEGORY.Misc.value
        )
      );
    },
    addNewDirectExpense(list, category) {
      list.push({
        id: 0,
        category: category,
        description: null,
        sellCost: 0,
        laborCost: 0,
      });
    },
    deleteDirectExpense(list, idx) {
      list.splice(idx, 1);
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    getNextMonth(date) {
      // Get the month and year of the current date
      const currentMonth = date.getMonth();
      const currentYear = date.getFullYear();

      // Calculate the month and year of the next month
      let nextMonth = currentMonth + 1;
      let nextYear = currentYear;

      if (nextMonth > 11) {
        // If the next month is greater than 11 (December), add 1 to the year and set the month to 0 (January)
        nextMonth = 0;
        nextYear += 1;
      }
      return new Date(nextYear, nextMonth, 1);
    },
    reCalcStartAndEndDates() {
      var startMonthDay = this.formatDate(this.startMonthValue);
      var endMonthDay = this.formatDate(this.endMonthValue);
      if (this.startMonthValue != null && this.endMonthValue == null && this.isMultiple) {
        this.endMonthValue = this.formatDate(
          this.getNextMonth(new Date(this.startMonthValue))
        ).substr(0, 7);
        endMonthDay = this.formatDate(this.endMonthValue);
      }
      if (this.isMultiple) {
        if (this.startMonthValue != null)
          this.selected.startDate = this.formatDate(
            this.getFirstDayOfMonth(
              this.getDateObj(startMonthDay).getFullYear(),
              this.getDateObj(startMonthDay).getMonth()
            )
          );
        if (this.endMonthValue != null)
          this.selected.endDate = this.formatDate(
            this.getFirstDayOfMonth(
              this.getDateObj(endMonthDay).getFullYear(),
              this.getDateObj(endMonthDay).getMonth()
            )
          );
      } else if (this.startMonthValue != null) {
        this.selected.startDate = this.formatDate(
          this.getFirstDayOfMonth(
            this.getDateObj(startMonthDay).getFullYear(),
            this.getDateObj(startMonthDay).getMonth()
          )
        );
        this.selected.endDate = this.formatDate(
          this.getLastDayOfMonth(
            this.getDateObj(startMonthDay).getFullYear(),
            this.getDateObj(startMonthDay).getMonth()
          )
        );
      }
    },
    startMonthChanged() {
      if (this.selected.dateRangeType == enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE.Month.value) {
        this.reCalcStartAndEndDates();
      }
    },
    endMonthChanged() {
      if (
        this.isMultiple &&
        this.selected.dateRangeType == enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE.Month.value
      ) {
        this.reCalcStartAndEndDates();
      }
    },
    clone() {
      this.$log(this.selected);
      this.$refs.cloneConstructionPhase.open(this.selected);
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this naming template?<br/><h4>${this.selected.name}</h4>`,
          title: `Delete Construction Phase?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return constructionPhasesService
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.onDeleteConstructionPhaseSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Construction Phase deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Construction Phase!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementConstructionPhase();
      this.$releaseChanges();
      this.isFetching = false;
      this.expandedAreaGroupPanel = null;

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewConstructionPhase) {
      this.$emit("save", this.cloneDeep(this.selected), isNewConstructionPhase);
    },
    announceUnlinked() {
      this.$emit("unlink", this.cloneDeep(this.selected));
    },
    fetchConstructionPhase() {
      this.isFetching = true;
      constructionPhasesService
        .getById(this.constructionPhaseId)
        .then((resp) => {
          this.$log("getConstructionPhaseById >> success", resp.data);
          this.preparePhase(resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
        })
        .catch((err) => {
          this.$log("getConstructionPhaseById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    preparePhase(data) {
      this.selected = this.cloneDeep(data);
      // if (
      //   this.selected.dateRangeType ==
      //   enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE.Month.value
      // )
      this.startMonthValue = this.selected.startDate.substr(0, 7);

      this.selected.startDate = this.selected.startDate.substr(0, 10);
      this.selected.endDate = this.selected.endDate.substr(0, 10);
      this.convertSelectedRecordsToGroups();
      this.convertSelectedDirectExpensesToGroups();
      this.cementConstructionPhase();
      this.handleFocus();
      this.checkForChanges();
    },
    open(id, editMode = false, contract = null) {
      this.getAllUsersData();

      this.isMultiple = false;
      this.startMonthMenu = false;
      this.startMonthValue = null;
      this.endMonthMenu = false;
      this.endMonthValue = null;

      if (id == null) {
        //new template
        this.selected = {
          id: null,
          contract: contract,
          contractId: contract.id,
          name: null,
          dateRangeType: 2,
          startDate: null,
          endDate: null,
          type: null,
        };
        this.laborTypes = {
          ac_records: [],
          sc_records: [],
          c_records: [],
          vi_records: [],
          bim_records: [],
          mgt_records: [],
          tcn_records: [],
          scd_records: [],
        };
        this.directExpenses = {
          travel_costs: [],
          subcontractor_costs: [],
          misc_costs: [],
        };
        this.readonly = false;
        this.isFetching = false;
        this.constructionPhaseId = null;
        this.slideouts.update.tab = 0;
        this.$refs.updateForm.resetValidation();
        this.handleFocus();
        this.cementConstructionPhase();
      } else {
        this.constructionPhaseId = id;
        this.readonly = !editMode;
        this.slideouts.update.tab = 0;
        this.fetchConstructionPhase();
      }
      this.slideouts.update.active = true;
    },
    cementConstructionPhase() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.cementLaborTypes();
      this.cementDirectExpense();
      this.checkForChanges();
    },
    cementLaborTypes() {
      this.laborTypesCemented = this.cloneDeep(this.laborTypes);
    },
    cementDirectExpense() {
      this.directExpensesCemented = this.cloneDeep(this.directExpenses);
    },
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    },
    getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 0);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = this.cloneDeep(this.selected);
      // var startMonthDay = this.formatDate(toSend.startDate);
      // if (toSend.dateRangeType == enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE.Month.value) {
      //   toSend.startDate = startMonthDay;
      //   toSend.endDate = this.getLastDayOfMonth(
      //     this.getDateObj(startMonthDay).getFullYear(),
      //     this.getDateObj(startMonthDay).getMonth()
      //   );
      //   toSend.endDate = this.formatDate(toSend.endDate);
      // }
      toSend.records = this.convertRecordsGroupsToSingleList();
      toSend.directExpenses = this.convertDirectExpensesGroupsToSingleList();
      if (this.isMultiple && toSend.id == null) {
        toSend.isMultiple = this.isMultiple;
      }
      this.$log(">>> toSend", this.cloneDeep(toSend));
      constructionPhasesService
        .update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          this.$log("update >> Success", resp);
          var message = "Construction Phase is updated successfully!";
          this.announceChange(isNewConstructionPhase);
          this.preparePhase(resp.data);
          var isNewConstructionPhase = false;
          if (!toSend.id) {
            isNewConstructionPhase = true;
            message = "Construction Phase is added successfully!";
          }
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.$log("update >> error", err);
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges =
        !this.isEqual(this.selected, this.selectedCemented) ||
        !this.isEqual(this.laborTypes, this.laborTypesCemented) ||
        !this.isEqual(this.directExpenses, this.directExpensesCemented);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    handleFocus() {
      if (this.isFetching) return;
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
      this.discardRecordsChanges();
      this.discardDirectExpensesChanges();
      if (this.selected.dateRangeType == enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE.Month.value) {
        this.startMonthValue = this.selected.startDate.substr(0, 7);
      }
      this.selected.startDate = this.selected.startDate.substr(0, 10);
      this.selected.endDate = this.selected.endDate.substr(0, 10);
    },
    discardRecordsChanges() {
      this.laborTypes = this.cloneDeep(this.laborTypesCemented);
    },
    discardDirectExpensesChanges() {
      this.directExpenses = this.cloneDeep(this.directExpensesCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    onCloneConstructionPhaseSuccess(id) {
      this.$log("onCloneConstructionPhaseSuccess", id);
      this.closeUpdateSlideout();
      this.announceChange(true);
    },
  },
  watch: {
    isMultiple: {
      handler() {
        this.reCalcStartAndEndDates();
      },
    },
    selected: {
      handler() {
        if (this.slideouts.update.active) {
          this.checkForChanges();
        }
      },
      deep: true,
    },
    laborTypes: {
      handler() {
        if (this.slideouts.update.active) {
          this.checkForChanges();
        }
      },
      deep: true,
    },
    directExpenses: {
      handler() {
        if (this.slideouts.update.active) {
          this.checkForChanges();
        }
      },
      deep: true,
    },
    "slideouts.update.tab": {
      handler() {
        this.handleFocus();
      },
      deep: true,
    },
  },
  created() {},
  components: {
    PanelSizeControl,
    ConstructionPhaseType,
    ConstructionPhaseRecord,
    ConstructionPhaseDirectExpense,
    CloneConstructionPhase,
  },
};
</script>

<style lang="scss">
.update-construction-phase-slideout {
  .v-window.v-tabs-items {
    overflow: visible !important;
  }

  .labor-type-section {
    background: rgba($shades-black, 0.06);
    border: 1px solid rgba($shades-black, 0.06);
    border-radius: 0.5rem;
    transition: all 0.15s ease-out;

    &:hover {
      background: rgba($info-base, 0.06);
      border: 1px solid rgba($info-base, 0.64);
    }
  }
}
</style>
