<template>
  <v-container class="pa-4">
    <v-row>
      <v-col cols="6" v-for="item in dates" :key="item.key">
        <v-menu
          v-model="item.menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="readonly"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field-alt
              v-model="selected[item.key]"
              :label="item.label"
              :placeholder="item.label"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              :id="item.key"
              :ref="item.key"
            >
              <template #prepend-inner><i class="far fa-calendar-edit mr-1"></i></template>
            </v-text-field-alt>
          </template>
          <v-date-picker
            color="orange darken-3"
            header-color="secondary"
            :first-day-of-week="1"
            :allowed-dates="item.allowedDates"
            v-model="selected[item.key]"
            @input="item.menu = false"
            show-current
            show-week
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    selected: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dates: [
        {
          menu: false,
          label: "Potential Start Date",
          key: "potentialStartDate",
          allowedDates: (v) => this.dateGreater(v, new Date(), true),
        },
        {
          menu: false,
          label: "Potential Construction Start Date",
          key: "potentialConstructionStartDate",
          allowedDates: (v) => this.dateGreater(v, this.selected.potentialStartDate || new Date()),
        },
        {
          menu: false,
          label: "Potential Construction Complete Date",
          key: "potentialConstructionCompleteDate",
          allowedDates: (v) =>
            this.dateGreater(v, this.selected.potentialConstructionStartDate || new Date()),
        },
      ],
    };
  },
  methods: {
    dateGreater(great, less, checkEqual = false) {
      const res = new Date(great) > new Date(less);
      if (checkEqual) {
        return res || new Date(great).toDateString() == new Date(less).toDateString();
      }
      return res;
    },
  },
  watch: {
    selected: {
      handler() {
        if (
          this.dateGreater(
            this.selected.potentialStartDate,
            this.selected.potentialConstructionStartDate
          )
        ) {
          this.selected.potentialConstructionStartDate = null;
        }
        if (
          this.dateGreater(
            this.selected.potentialConstructionStartDate,
            this.selected.potentialConstructionCompleteDate
          ) ||
          this.dateGreater(
            this.selected.potentialStartDate,
            this.selected.potentialConstructionCompleteDate
          )
        ) {
          this.selected.potentialConstructionCompleteDate = null;
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
