<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 1400"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideout.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1">
        <i class="fad fa-plus mr-2"></i> Create WireCAD Equipment Integration Request
      </h3>
      <div>
        <panel-size-control v-model="slideout.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <div color="white" style="position: sticky; top: 0; z-index: 2" ref="tabsContainer">
      <v-tabs v-model="slideout.tab" color="error" v-if="slideout.active">
        <v-tab :key="0"> <i class="fad fa-memo-circle-info mr-2"></i> Basic Info </v-tab>
        <v-tab :key="1"> <i class="fad fa-list mr-2"></i>Request Items</v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-tabs-items v-model="slideout.tab" style="height: 100%">
      <v-tab-item :key="0">
        <v-form v-model="slideout.valid" ref="updateForm0" style="height: 100%; overflow: hidden">
          <v-container
            fluid
            style="height: 100%; overflow: hidden; flex-direction: column; display: flex"
          >
            <v-row align="center">
              <v-col cols="12" md="6" class="d-flex align-center">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
                  id="Equipment_Request_Name"
                  ref="Name"
                  label="Request Name"
                  placeholder="Request Name"
                  v-model="selected.name"
                  style="flex: 1 1 auto"
                  hide-details
                >
                </v-text-field-alt>
              </v-col>
              <v-col cols="12" md="12" class="mb-4">
                <rich-text-editor
                  ref="richTextEditor"
                  v-model="selected.justification"
                  title="Request Justification<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                  showLabel
                  allowExpand
                >
                </rich-text-editor>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <v-tab-item :key="1" style="height: 100%; overflow: hidden">
        <v-container
          fluid
          class="inset-shadow py-0 px-0"
          style="
            background: #eceff1;
            height: 100%;
            overflow: hidden;
            flex-direction: column;
            display: flex;
          "
        >
          <v-container fluid class="py-2 px-4">
            <v-row>
              <v-col cols="12" md="6" class="d-flex align-start">
                <label
                  class="mr-2 pt-2 d-flex align-center"
                  style="font-weight: 600 !important; font-size: 15px"
                >
                  Data File<i class="fas fa-star-of-life pink--text label-icon"></i>
                </label>
                <v-file-input
                  class="import-file-input"
                  ref="vFileInput"
                  v-model="selected.file"
                  :rules="rules"
                  prepend-icon=""
                  accept=".xlsx"
                  placeholder="Click to Browse"
                  dense
                  solo
                  show-size
                  truncate-length="46"
                  :loading="isImporting"
                  :disabled="isImporting || isImported"
                  @change="onChange"
                  hide-details
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      :color="validImport ? 'info' : 'red'"
                      dark
                      small
                      style="max-height: 18px"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                  <template v-slot:prepend-inner>
                    <i :class="validImport ? 'fas' : 'fad'" class="fa-paperclip mr-1"></i>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" md="6" class="d-flex align-center">
                <v-sheet
                  color="secondary"
                  elevation="2"
                  rounded
                  class="px-2 d-flex align-center"
                  height="32px"
                >
                  <div class="d-flex align-start py-0 white--text font-weight-medium fs-12px">
                    <span><i class="fad fa-layer-group mr-2"></i>Total Loaded Items</span>
                    <v-divider class="mx-2" vertical dark></v-divider>
                    <v-chip
                      class="mono-font px-2"
                      x-small
                      :color="'white'"
                      :text-color="'secondary'"
                    >
                      {{ selected.requestItems.length }}
                    </v-chip>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
          <div style="height: 100%; overflow: overlay; flex: 1 1 auto">
            <v-scroll-y-transition
              mode="out-in"
              style="
                overflow: auto;
                overflow: overlay;
                height: 100%;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
              "
            >
              <div
                :key="'nothing-imported-yet'"
                class="nothing-imported-yet"
                v-if="!isImported && !isImporting"
              >
                <img src="/img/art/tumble-weed.svg" class="mt-3" />
                <p class="font-weight-medium mb-5 text--disabled">No data imported yet!</p>
              </div>
              <div
                v-else-if="!isImported && isImporting"
                :key="'importing-data'"
                class="d-flex flex-column align-center justify-center"
                style="
                  overflow: auto;
                  overflow: overlay;
                  height: 100%;
                  flex: 1 1 auto;
                  display: flex;
                  flex-direction: column;
                "
              >
                <video
                  width="275"
                  muted
                  loop
                  autoplay
                  class="inset-shadow-video"
                  style="padding: 0.5rem; border-radius: 10rem; background: #fff"
                >
                  <source
                    src="/img/art/astronaut-mines-coins-4979111-4153144.mp4"
                    type="video/mp4"
                  />
                </video>
                <h4 class="mt-3 text--secondary">Generating Data Report ...</h4>
              </div>
              <wire-cad-equipment-request-table
                v-else-if="isImported && !isImporting"
                :items.sync="selected.requestItems"
              ></wire-cad-equipment-request-table>
            </v-scroll-y-transition>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <template v-slot:footer>
      <v-card-actions>
        <v-btn :disabled="isSaving" @click="closeUpdateSlideout()" color="secondary" text>
          <i class="fal fa-xmark mr-2"></i> Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <div class="d-flex mr-2">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="isSaving || isImporting"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          color="info"
          :disabled="isSaving || !isImported || !validJustification || !slideout.valid"
          @click="create()"
          :loading="isSaving"
        >
          <i class="fas fa-check mr-2"></i> Create
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import axios from "@/plugins/axios.js";
import perms from "../../../../../plugins/permissions";
import enums from "../../../../../plugins/enums";
import API from "../services/wirecad-equipment-service";
import PanelSizeControl from "../../../../Shared/components/PanelSizeControl.vue";
import WireCadEquipmentRequestTable from "./WireCadEquipmentRequestTable.vue";
import RichTextEditor from "../../../../Shared/components/RichTextEditor.vue";

export default {
  name: "create-wire-cad-equipment-request",
  components: {
    PanelSizeControl,
    WireCadEquipmentRequestTable,
    RichTextEditor,
  },
  data() {
    return {
      perms,
      enums,
      rules: [
        (file) =>
          !file ||
          file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          "Only .xlsx excel files are acceptable!",
      ],
      selected: {
        name: null,
        justification: null,
        file: null,
        requestItems: [],
      },
      slideout: {
        tab: 0,
        valid: false,
        active: false,
        fullWidth: false,
      },
      isImporting: false,
      isImported: false,
      isSaving: false,
      cancellation: null,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      // return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "1400px";
      return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "75%";
    },
    validImport() {
      if (
        this.selected.file != null &&
        this.selected.file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
        return true;
      return false;
    },
    validJustification() {
      if (!this.slideout.active) return false;
      return (
        this.selected.justification != null &&
        this.selected.justification.trim() != "<p></p>" &&
        this.selected.justification.trim() != ""
      );
    },
  },
  methods: {
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm0, () => {
          this.slideout.tab = 0;
        })
      )
        return;

      if (!this.validJustification) {
        this.slideout.tab = 0;
        setTimeout(() => {
          this.$refs.richTextEditor.focus();
        }, 100);
        return;
      }

      if (!this.validImport) {
        this.slideout.tab = 1;
        setTimeout(() => {
          this.$refs.vFileInput.focus();
        }, 100);
        return;
      }
      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideout.active);
      // prevent close and wait
      e.wait = true;

      if (this.isSaving && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideout.active = false;
    },
    onChange() {
      if (this.validImport) this.importEquipment();
    },
    open() {
      this.$log(">>>>>>> open");
      this.selected.name = null;
      this.selected.justification = null;
      this.selected.file = null;
      this.selected.requestItems = [];

      this.isImporting = false;
      this.isImported = false;
      this.isSaving = false;
      this.cancellation = null;

      this.slideout.fullWidth = false;
      this.slideout.valid = false;
      this.slideout.active = true;
    },
    importEquipment() {
      this.isImporting = true;
      var formData = new FormData();
      formData.append("formFile", this.selected.file, this.selected.file.name);
      const _cancelToken = axios.CancelToken;
      this.cancellation = {
        cancelToken: _cancelToken,
        source: _cancelToken.source(),
      };
      formData.append("cancellation", this.cancellation.source.token);
      API.import(formData, {
        cancelToken: this.cancellation.source.token,
      })
        .then((response) => {
          this.$log("Report:", response.data);
          for (let i = 0; i < response.data.length; i++) {
            const item = response.data[i];
            item.actionType = enums.WireCadIntegrationAction.NoAction.value;
          }
          this.selected.requestItems = response.data;
          this.isImporting = false;
          this.isImported = true;
        })
        .catch((error) => {
          this.$log("Uploading data Error:", error);
          this.isImporting = false;
          this.isImported = false;

          if (axios.isCancel(error)) {
            this.$log("Importing data is cancelled by user!", error.message);
            this.$dialog.notify.error("Importing data is cancelled by user!", {
              position: "top-right",
              timeout: 10 * 1000,
            });
          } else {
            //handle error
            this.$handleError(error);
          }
        });
    },
    create() {
      this.isSaving = true;
      var formData = new FormData();
      formData.append("file", this.selected.file, this.selected.file.name);
      formData.append("name", this.selected.name);
      formData.append("justification", this.selected.justification);
      formData.append("wireCadDataJson", JSON.stringify(this.selected.requestItems));
      const _cancelToken = axios.CancelToken;
      this.cancellation = {
        cancelToken: _cancelToken,
        source: _cancelToken.source(),
      };
      formData.append("cancellation", this.cancellation.source.token);
      API.add(formData, {
        cancelToken: this.cancellation.source.token,
      })
        .then((response) => {
          this.$log("Creating request:", response.data);
          this.isSaving = false;
          this.$dialog.notify.success("Request created successfully!", {
            position: "top-right",
            timeout: 5 * 1000,
          });
          this.$emit("save", response.data, true);
          this.closeUpdateSlideout();
        })
        .catch((error) => {
          this.$log("Creating request Error:", error);
          this.isSaving = false;

          if (axios.isCancel(error)) {
            this.$log("Creating request is cancelled by user!", error.message);
            this.$dialog.notify.error("Creating request is cancelled by user!", {
              position: "top-right",
              timeout: 10 * 1000,
            });
          } else {
            //handle error
            this.$handleError(error);
          }
        });
    },
  },
  watch: {},
};
</script>
<style lang="scss"></style>
