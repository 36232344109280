import Api from "../../Shared/services/api";

const baseUrl = "MicrosoftTeams";
export default {
  teams() {
    return Api().get(`${baseUrl}/Teams`);
  },
  teamsUnfiltered() {
    return Api().get(`${baseUrl}/Teams/Unfiltered`);
  },
  team(id) {
    return Api().get(`${baseUrl}/Teams/${id}`);
  },
  createTeam(projectId) {
    return Api().post(`${baseUrl}/create-team-from-project/${projectId}`);
  },
};
