<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '850px'"
    :min-size="nested ? 500 : 850"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1 d-flex align-center" small>
        <span style="white-space: no-wrap">
          <i class="fad fa-arrow-up-from-arc mr-1"></i> Source
        </span>
        <user-all-selector
          :users="users"
          required
          small
          hideLabel
          hideDetails
          readonly
          hideViewBtn
          :isUsersLoading="isUsersLoading"
          v-model="selected.sourceUserId"
          class="ml-2 white"
        ></user-all-selector>
        <!-- <v-divider vertical style="height: 28px" class="mx-4"></v-divider> -->
        <span style="white-space: no-wrap" class="ml-8">
          <i class="fad fa-arrow-down-to-arc mr-1"></i> Target
        </span>
        <user-all-selector
          :users="users"
          required
          small
          hideLabel
          hideDetails
          readonly
          hideViewBtn
          :isUsersLoading="isUsersLoading"
          v-model="selected.targetUserId"
          class="ml-2 white"
        ></user-all-selector>
      </h3>
      <v-btn @click="closeUpdateSlideout()" icon>
        <i class="far fa-times"></i>
      </v-btn>
    </template>

    <v-form v-model="slideouts.update.valid" ref="updateForm" style="height: 100%">
      <v-container
        v-if="slideouts.update.active"
        fluid
        class="pa-0 inset-shadow"
        style="background: #eceff1; overflow: auto; overflow: overlay; height: 100%"
      >
        <div style="position: sticky; top: 0; z-index: 1">
          <v-tabs grow color="error" v-model="tab">
            <v-tab :key="TABS.SelectionOnly" :disabled="isRecordsLoading">
              <i class="fad fa-check-double mr-2"></i>
              My Selections Only
            </v-tab>
            <v-tab :key="TABS.DateRange" :disabled="isRecordsLoading">
              <i class="fad fa-calendar-range mr-2"></i>
              Date Range
            </v-tab>
            <v-tab :key="TABS.Project" :disabled="isRecordsLoading">
              <i class="fad fa-folders mr-2"></i>
              Project
            </v-tab>
          </v-tabs>
          <v-divider class="opacity-16"></v-divider>

          <v-sheet
            v-if="tab != TABS.SelectionOnly"
            elevation="2"
            height="40"
            class="d-flex flex-row align-center px-1"
            style="width: 100%"
          >
            <v-scroll-y-transition mode="out-in">
              <div
                v-if="tab == TABS.DateRange"
                key="range"
                height="40"
                class="d-flex flex-row align-center px-1"
              >
                <label class="fs-14px ma-0 ml-1 mr-2" style="font-weight: 600">From:</label>
                <v-menu
                  v-model="dateStartMonthMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :nudge-top="20"
                  z-index="999999999"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field-alt
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      short
                      class="blue-grey lighten-5 px-0"
                      style="width: 110px; min-width: 110px; border-radius: 0.25rem"
                      id="StartMonth"
                      placeholder="Start Month"
                      v-model="options.startDate"
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field-alt>
                  </template>
                  <v-date-picker
                    color="orange darken-3"
                    header-color="secondary"
                    v-model="options.startDate"
                    type="month"
                    elevation="4"
                    :allowed-dates="allowedStartMonths"
                    @input="onStartDateInput"
                  ></v-date-picker>
                </v-menu>

                <label class="fs-14px ma-0 ml-4 mr-2" style="font-weight: 600">To:</label>
                <v-menu
                  v-model="dateEndMonthMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :nudge-top="20"
                  z-index="999999999"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field-alt
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      short
                      class="blue-grey lighten-5"
                      style="width: 110px; min-width: 110px; border-radius: 0.25rem"
                      id="EndMonth"
                      placeholder="End Month"
                      v-model="options.endDate"
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    ></v-text-field-alt>
                  </template>
                  <v-date-picker
                    color="orange darken-3"
                    header-color="secondary"
                    v-model="options.endDate"
                    type="month"
                    elevation="4"
                    :allowed-dates="allowedEndMonths"
                    @input="onEndDateInput"
                  ></v-date-picker>
                </v-menu>
                <v-progress-linear
                  color="info"
                  absolute
                  indeterminate
                  bottom
                  height="2"
                  :active="isRecordsLoading"
                ></v-progress-linear>
                <refresh
                  class="ml-2"
                  small
                  dark
                  :loading="isRecordsLoading"
                  @refresh="getByRange()"
                ></refresh>
              </div>
              <div
                v-else-if="tab == TABS.Project"
                key="project"
                elevation="2"
                height="40"
                class="d-flex flex-row align-center px-1"
                style="width: 100%"
              >
                <label class="fs-14px ma-0 ml-1 mr-2" style="font-weight: 600; white-space: nowrap"
                  >Target Project:</label
                >
                <project-selector
                  style="width: 100%"
                  class="mr-2"
                  small
                  hideDetails
                  hideLabel
                  v-model="options.projectId"
                  @input="onProjectInput"
                ></project-selector>

                <v-progress-linear
                  color="info"
                  absolute
                  indeterminate
                  bottom
                  height="2"
                  :active="isRecordsLoading"
                ></v-progress-linear>
                <refresh
                  class="ml-2"
                  small
                  dark
                  :loading="isRecordsLoading"
                  @refresh="getByProject()"
                ></refresh>
              </div>
            </v-scroll-y-transition>
          </v-sheet>
        </div>

        <v-scroll-y-transition mode="out-in" style="width: 100%">
          <h3
            key="no-selections-yet"
            v-if="tab == TABS.SelectionOnly && selected.offerRecords.length == 0"
            class="font-weight-medium orange--text text--darken-2 text-center pt-4"
            style="text-transform: uppercase"
          >
            <i class="fas fa-triangle-exclamation mr-1 fs-16px"></i>
            No Selections Yet!
          </h3>
          <h3
            key="no-project-id"
            v-if="tab == TABS.Project && options.projectId == null"
            class="font-weight-medium orange--text text--darken-2 text-center pt-4"
            style="text-transform: uppercase"
          >
            <i class="fas fa-triangle-exclamation mr-1 fs-16px"></i>
            Please select a project!
          </h3>
        </v-scroll-y-transition>
        <v-scroll-y-transition mode="out-in" style="width: 100%">
          <div
            v-if="isRecordsLoading"
            :key="'projectBigBoardMonths-list-loading'"
            class="projectBigBoardMonths-list-loading d-flex flex-column align-center justify-center"
            style="
              overflow: auto;
              overflow: overlay;
              height: 100%;
              flex: 1 1 auto;
              display: flex;
              flex-direction: column;
            "
          >
            <video
              width="275"
              muted
              loop
              autoplay
              class="inset-shadow-video"
              style="padding: 0.5rem; border-radius: 10rem; background: #fff"
            >
              <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
            </video>
            <h4 class="mt-3 text--disabled">Loading Available Hours ...</h4>
          </div>
          <div
            v-else-if="records.length != 0"
            key="has-records"
            class="pa-3"
            style="overflow: auto; overflow: overlay"
          >
            <v-scroll-y-transition mode="out-in" group>
              <available-record-card
                class="mb-3"
                v-for="(availableRecord, i) in records"
                :key="i"
                :value="records[i]"
                :highlight-default-labor="targetUserDefaultLaborType"
                @input="offeredHoursChanged"
                @source-records="openAvailableSourceRecords(availableRecord)"
                fullWidth
                :disabled="isPastMonth(records[i].startDate)"
              ></available-record-card>
            </v-scroll-y-transition>
          </div>
          <div v-else key="no-hours-available" class="no-hours-available">
            <img src="/img/art/tumble-weed.svg" />
            <p class="font-weight-medium mb-1 opacity-72">No hours available!</p>
          </div>
        </v-scroll-y-transition>
      </v-container>
      <available-hours-source-records
        :users="users"
        :source-user-id="selected.sourceUserId"
        nested
        :isUsersLoading="isUsersLoading"
        ref="availableHoursSourceRecords"
      ></available-hours-source-records>
    </v-form>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-2" @click="closeUpdateSlideout" dark color="deep-purple">
          Back to Offer<i class="far fa-arrow-right ml-2"></i>
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import constructionPhaseRecordsService from "../../Contracts/services/constructionPhaseRecords-service";
import AvailableRecordCard from "./AvailableRecordCard.vue";
import AvailableHoursSourceRecords from "./AvailableHoursSourceRecords.vue";
import ProjectSelector from "../../Shared/components/ProjectSelector.vue";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";

export default {
  name: "add-offer-hours",
  components: {
    AvailableRecordCard,
    AvailableHoursSourceRecords,
    ProjectSelector,
    UserAllSelector,
  },
  data() {
    return {
      perms: perms,
      TABS: {
        SelectionOnly: 0,
        DateRange: 1,
        Project: 2,
      },
      showSelectedOffersOnly: false,
      isRecordsLoading: false,
      records: [],
      selected: {},
      slideouts: {
        update: {
          active: false,
        },
      },
      tab: 1,
      dateStartMonthMenu: false,
      dateEndMonthMenu: false,
      options: {
        startDate: null,
        endDate: null,
        projectId: null,
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    managementMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
    isUsersLoading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    offersUrl() {
      if (this.selected == null) return "";
      return `offers/${this.selected.id}`;
    },
    sourceUserDefaultLaborType() {
      return this.users.find((u) => u.id == this.selected.sourceUserId).defaultLaborType;
    },
    targetUserDefaultLaborType() {
      return this.users.find((u) => u.id == this.selected.targetUserId).defaultLaborType;
    },
  },
  methods: {
    isPastMonth(month) {
      return !this.allowedStartMonths(month);
    },
    openAvailableSourceRecords(availableRecord) {
      this.$refs.availableHoursSourceRecords.open(availableRecord);
    },
    open() {
      this.records = [];
      this.selected = this.cloneDeep(this.value);
      var currentMonth = this.formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).substr(0, 7);
      var nextMonth = this.formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 28)
      ).substr(0, 7);
      this.options.startDate = this.$getFromLocal(
        `create-offer-range-startDate`,
        false,
        currentMonth
      );
      this.options.endDate = this.$getFromLocal(`create-offer-range-endDate`, false, nextMonth);

      if (!this.allowedStartMonths(this.options.startDate)) {
        this.options.startDate = currentMonth;
        this.$setToLocal(`create-offer-range-startDate`, this.options.startDate);
      }
      if (
        !this.allowedStartMonths(this.options.endDate) ||
        this.options.startDate == this.options.endDate
      ) {
        this.options.endDate = nextMonth;
        this.$setToLocal(`create-offer-range-endDate`, this.options.endDate);
      }

      this.tab = this.TABS.DateRange;
      this.getByRange();
      this.slideouts.update.active = true;
    },
    updateRecords() {
      if (this.tab == this.TABS.SelectionOnly) {
        this.records = [];
        this.getBySelection();
      } else if (this.tab == this.TABS.DateRange) {
        this.records = [];
        this.getByRange();
      } else if (this.tab == this.TABS.Project) {
        this.records = [];
        this.options.projectId = null;
        this.getByProject();
      }
    },
    offeredHoursChanged(availableRecord) {
      // this.$log("#### offeredHoursChanged: ", this.cloneDeep(availableRecord));
      var offerRecord = this.selected.offerRecords.find(
        (or) =>
          or.projectId == availableRecord.project.id &&
          or.sourceLaborType == availableRecord.sourceLaborType &&
          or.startDate == availableRecord.startDate
      );
      if (offerRecord == null) {
        //add
        this.selected.offerRecords.push({
          id: 0,
          project: availableRecord.project,
          projectId: availableRecord.project.id,
          sourceLaborType: availableRecord.sourceLaborType,
          targetLaborType: availableRecord.targetLaborType,
          startDate: availableRecord.startDate,
          offeredHours: availableRecord.offeredHours,
        });
      } else {
        if (availableRecord.offeredHours == 0) {
          //remove
          var offerRecordIdx = this.selected.offerRecords.findIndex(
            (or) =>
              or.projectId == availableRecord.project.id &&
              or.sourceLaborType == availableRecord.sourceLaborType &&
              or.startDate == availableRecord.startDate
          );
          // this.$log("#### remove offerRecordIdx: ", offerRecordIdx);
          if (offerRecordIdx >= 0) this.selected.offerRecords.splice(offerRecordIdx, 1);
        } else {
          //update
          offerRecord.offeredHours = availableRecord.offeredHours;
          offerRecord.targetLaborType = availableRecord.targetLaborType;
        }
      }
      // this.$log("#### input: ", this.cloneDeep(this.selected));
      this.$emit("input", this.cloneDeep(this.selected));
    },
    allowedStartMonths(val) {
      const mYear = Number.parseInt(val.substring(0, 4));
      const mMonth = Number.parseInt(val.substring(5, 7));
      const curYear = new Date().getFullYear();
      const curMonth = new Date().getMonth() + 1;
      return mYear > curYear || (mYear == curYear && mMonth >= curMonth);
    },
    onProjectInput() {
      this.getByProject();
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      this.$emit("close");
    },
    allowedEndMonths(val) {
      const mYear = Number.parseInt(val.substring(0, 4));
      const mMonth = Number.parseInt(val.substring(5, 7));
      const sYear = Number.parseInt(this.options.startDate.substring(0, 4));
      const sMonth = Number.parseInt(this.options.startDate.substring(5, 7));
      return (
        this.allowedStartMonths(val) && (mYear > sYear || (mYear == sYear && mMonth >= sMonth))
      );
    },
    onStartDateInput() {
      this.$log(">>>> onStartDateInput");
      this.dateStartMonthMenu = false;
      this.$setToLocal(`create-offer-range-startDate`, this.options.startDate);
      if (!this.allowedEndMonths(this.options.endDate)) {
        this.options.endDate = this.options.startDate;
        this.$setToLocal(`create-offer-range-endDate`, this.options.endDate);
      }
      this.getByRange();
    },
    onEndDateInput() {
      this.$log(">>>> onEndDateInput");
      this.dateEndMonthMenu = false;
      this.$setToLocal(`create-offer-range-endDate`, this.options.endDate);
      this.getByRange();
    },
    confirm() {
      // this.records = list.map(pr => {
      //   var offerRecord = this.selected.offerRecords.find(
      //     or => or.constructionPhaseRecordId == pr.id
      //   );
      //   pr.offeredHours = offerRecord != null ? offerRecord.offeredHours : 0;
      //   return pr;
      // });
    },
    prepareRecords(list) {
      this.records = [];
      this.records = list.map((cpr) => {
        var offerRecord = this.selected.offerRecords.find(
          (or) =>
            or.projectId == cpr.project.id &&
            or.sourceLaborType == cpr.sourceLaborType &&
            or.startDate == cpr.startDate
        );
        cpr.offeredHours = offerRecord != null ? offerRecord.offeredHours : 0;
        cpr.targetLaborType =
          offerRecord != null ? offerRecord.targetLaborType : this.targetUserDefaultLaborType;
        return cpr;
      });
      // this.records = list.map((cpr) => {
      //   cpr.offeredHours = 0;
      //   cpr.targetLaborType = cpr.sourceLaborType;
      //   return cpr;
      // });
    },
    getBySelection() {
      if (this.selected.sourceUserId == null) return;
      this.isRecordsLoading = true;
      var toSend = {
        sourceUserId: this.selected.sourceUserId,
        records: this.selected.offerRecords.map((or) => {
          return {
            projectId: or.project.id,
            startDate: or.startDate,
            sourceLaborType: or.sourceLaborType,
          };
        }),
      };
      if (this.selected.id != null) toSend.offerId = this.selected.id;

      constructionPhaseRecordsService
        .getConstructionPhaseRecordsForSpecifiedOffers(toSend)
        .then((resp) => {
          this.$log("resp.data", resp.data);
          this.prepareRecords(resp.data);
          this.$log("this.records", this.records);
          this.isRecordsLoading = false;
        })
        .catch(() => {
          this.isRecordsLoading = false;
        });
    },
    getByRange() {
      if (this.selected.sourceUserId == null) return;
      this.isRecordsLoading = true;
      var toSend = {
        startDate: this.options.startDate, //this.convertToPhaseDate(this.options.startDate)
        endDate: this.options.endDate, //this.convertToPhaseDate(this.options.endDate)
      };
      if (this.selected.id != null) toSend.offerId = this.selected.id;
      if (this.managementMode) toSend.userId = this.selected.sourceUserId;
      constructionPhaseRecordsService
        .getAvailableConstructionPhaseRecordsByRange(toSend)
        .then((resp) => {
          this.$log("resp.data", resp.data);
          this.prepareRecords(resp.data);
          this.$log("this.records", this.records);
          this.isRecordsLoading = false;
        })
        .catch(() => {
          this.isRecordsLoading = false;
        });
    },
    getByProject() {
      if (this.options.projectId == null) return;
      var toSend = {
        projectId: this.options.projectId,
      };
      if (this.selected.id != null) toSend.offerId = this.selected.id;
      if (this.managementMode) toSend.userId = this.selected.sourceUserId;
      this.isRecordsLoading = true;
      constructionPhaseRecordsService
        .getAvailableConstructionPhaseRecordsByProject(toSend)
        .then((resp) => {
          this.$log("this.records", resp.data);
          this.prepareRecords(resp.data);
          this.isRecordsLoading = false;
        })
        .catch(() => {
          this.isRecordsLoading = false;
        });
    },
    convertToPhaseDate(date) {
      const dateObj = new Date(date);
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // months from 1-12
      const year = dateObj.getUTCFullYear();
      return `${year}-${month}`;
    },
  },
  watch: {
    tab: {
      handler(val) {
        this.updateRecords();
      },
      deep: true,
    },
    value: {
      handler() {
        // this.$log("$ADD-OFFER-HOURS >> WATCH > value", this.cloneDeep(this.value));
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    // selected: {
    //   handler() {
    //     this.$log("$ADD-OFFER-HOURS >> WATCH > selected", this.selected);
    //     if (!this.isEqual(this.selected, this.value)) {
    //       this.$emit("input", this.cloneDeep(this.selected));
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>
