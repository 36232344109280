export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    width: "196px",
    class: "report-action-header",
    cellClass: "report-action-cell",
    sortable: false,
  },
  { text: "Manufacturer", width: "300px", value: "cocoData.name", sortable: true },
  { text: "Integration Result", width: "160px", value: "integrationResult", sortable: true },
  {
    text: "GUID",
    width: "250px",
    value: "wireCadData.guid",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: false,
  },
  {
    text: "Name",
    value: "wireCadData.name",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
  {
    text: "ID",
    value: "wireCadData.id",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
];
