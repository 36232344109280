<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template #header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Contact ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> Contact: '{{ selected.firstName }}
          {{ selected.lastName }}'
        </span>
        <span v-else>
          <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.firstName }}
          {{ selected.lastName }}'
        </span>
        <!-- <entity-id class="ml-2" :id="selected.id" :path="contactUrl" title="Contact"></entity-id> -->
      </h3>
      <h3 v-else-if="selected.id == null && cloneMode" class="font-weight-bold pa-1" small>
        <i class="fad fa-copy mr-2"></i> Clone Contact
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a New Contact
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-container class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Contact ...</p>
    </v-container>
    <v-container fluid class="py-0" v-else>
      <v-form v-model="slideouts.update.valid" ref="updateForm">
        <v-row class="my-0" dense>
          <v-col cols="12">
            <single-image-uploader
              id="add-contact-client-uploader"
              :apiUrl="imageApiUrl"
              v-model="selected.avatarUrl"
              :disabled="readonly"
            ></single-image-uploader>
          </v-col>
          <v-col cols="12">
            <client-selector
              v-model="selected.companyId"
              @input="onClientChanged"
              :required="true"
              nested
            ></client-selector>
          </v-col>
          <v-col cols="6">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="First Name"
              id="contactFirstName"
              ref="contactFirstName"
              placeholder="First Name"
              v-model="selected.firstName"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="6">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="Last Name"
              id="contactLastName"
              ref="contactLastName"
              placeholder="Last Name"
              v-model="selected.lastName"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="6">
            <v-text-field-alt
              :rules="[allRules.required, allRules.validEmail]"
              label="Email"
              id="contactEmail"
              ref="contactEmail"
              placeholder="Email"
              v-model="selected.email"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="6">
            <label class="input-label mb-1">Phone </label>
            <maz-phone-number-input
              clearable
              :class="{ 'hide-clear-btn': readonly }"
              id="contactPhone_edit-contact"
              placeholder="Contact Phone"
              color="primary"
              v-model="selected.phone"
              :disabled="readonly"
              @update="savePhoneWithCountry"
              :default-phoner-number="selected.phone"
              default-country-code="US"
              size="sm"
            >
            </maz-phone-number-input>
          </v-col>
          <v-col cols="6">
            <v-text-field-alt
              :rules="[allRules.noWhiteSpaces]"
              label="Role"
              id="contactRole"
              ref="contactRole"
              placeholder="Contact Role"
              v-model="selected.contactRole"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="6">
            <v-select-alt
              label="State"
              id="contactState"
              ref="contactState"
              placeholder="Contact State"
              v-model="selected.contactState"
              :readonly="readonly"
              :items="states"
              :rules="[allRules.required]"
            >
            </v-select-alt>
          </v-col>
          <v-col cols="12">
            <h3 class="text--primary font-weight-black mt-4 mt-md-0">Contact Location</h3>
            <v-divider class="mb-3 mt-2"></v-divider>
            <address-detail
              :readonly="readonly"
              v-model="selected"
              has-address-line
            ></address-detail>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && readonly && $has(perms.CompanyContacts.Update)"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchContact()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Contact</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>

          <v-list class="more-options-menu">
            <v-list-item
              @click="invite()"
              v-if="
                allowInvite && selected.userInvitation == null && $has(perms.CompanyContacts.Invite)
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-paper-plane</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Invite to CoCo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              disabled
              v-else-if="
                allowInvite &&
                selected.userInvitation != null &&
                selected.userInvitation.isAccepted &&
                $has(perms.CompanyContacts.Invite)
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-check-double</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Invitation Sent & Accepted</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              disabled
              v-else-if="
                allowInvite &&
                selected.userInvitation != null &&
                !selected.userInvitation.isAccepted &&
                $has(perms.CompanyContacts.Invite)
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Invitation Sent and Pending!</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="cloneContact()" v-if="$has(perms.CompanyContacts.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="purple--text">fal fa-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="purple--text">Clone</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="del()" v-if="$has(perms.CompanyContacts.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../plugins/permissions";
import Enums from "../../../../plugins/enums";
import companyContactsService from "../../services/companyContacts-service.js";
import clientSelector from "../../../Shared/components/ClientSelector.vue";
import AddressDetail from "../../../Shared/components/AddressDetail.vue";
import MazPhoneNumberInput from "maz-ui/lib/maz-phone-number-input";
import "maz-ui/lib/css/base.css";
import "maz-ui/lib/css/maz-phone-number-input.css";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";

export default {
  components: {
    clientSelector,
    AddressDetail,
    MazPhoneNumberInput,
    PanelSizeControl,
  },
  name: "edit-contact",
  data() {
    return {
      perms: perms,
      states: this.$options.filters.EnumToList(Enums.clientStates),
      contactId: null,
      isFetching: false,
      readonly: false,
      cloneMode: false,
      phoneObj: null,
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          valid: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
      search: null,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    imageApiUrl() {
      return `CompanyContacts/Avatar`;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "800px";
    },
    allowInvite() {
      if (this.selected != null && this.selected.companyType == "Manufacture") return true;
      return false;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.$has(this.perms.CompanyContacts.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    savePhoneWithCountry(payload) {
      this.phoneObj = payload;
      this.$log("this.phoneObj", this.phoneObj);
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;
      this.$emit("onClose");
      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementContact();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewContact) {
      this.$emit("save", this.selected, isNewContact);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = this.cloneDeep(this.selected);
      companyContactsService
        .update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Contact updated successfully!";
          this.selected = this.cloneDeep(resp.data);
          this.cloneMode = false;
          this.cementContact();
          var isNewContact = false;
          if (!toSend.id) {
            isNewContact = true;
            this.contactId = this.selected.id;
            message = "Contact added successfully!";
          }
          this.announceChange(isNewContact);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });

          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
          this.companyId = null;
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    closeUpdateSlideout() {
      this.cloneMode = false;
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      if (id == null) {
        this.selected = { companyId: this.companyId };
        this.readonly = false;
        this.isFetching = false;
        this.contactId = null;
        this.cementContact();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
        setTimeout(() => {
          this.$refs.contactFirstName.focus();
        }, 250);
      } else {
        this.readonly = !editMode;
        this.contactId = id;
        this.selected = {};
        this.fetchContact();
      }
      this.slideouts.update.active = true;
    },
    clone(id) {
      this.readonly = false;
      this.cloneMode = true;
      this.contactId = id;
      this.selected = {};
      this.fetchContact();
      this.slideouts.update.active = true;
    },
    fetchContact() {
      this.isFetching = true;
      companyContactsService
        .getById(this.contactId)
        .then((resp) => {
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          if (this.cloneMode) this.selected.id = null;
          this.$log("fetchContact()", this.cloneDeep(resp.data), "clone?", this.cloneMode);
          this.cementContact();
        })
        .catch((err) => {
          this.closeUpdateSlideout();
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    cementContact() {
      if (this.cloneMode) this.selectedCemented = this.cloneDeep({});
      else this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;
      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
      if (this.slideouts.update.hasChanges) {
        this.$log("%c==>> DIFF", "color: red", this.deepDiff(this.selectedCemented, this.selected));
      }
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete "<u>${this.selected.firstName} ${this.selected.lastName}</u>" from contacts?`,
          title: `Delete Contact?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return companyContactsService
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.$emit("delete", this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Contact deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onClientChanged(id) {
      this.selected.companyId = id;
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
.tree-wrapper {
  border: 1px dashed rgba($shades-black, 0.4);
}
</style>
