export default [
  {
    text: "Image",
    value: "imageUrl",
    width: "40px",
    order: 1,
    hidable: false,
    hidden: false,
  },
  {
    text: "Client",
    value: "client",
    width: "64px",
    class: "px-0",
    cellClass: "px-0",
    order: 2,
    hidable: false,
    hidden: false,
  },
  { text: "Name", value: "name", order: 3, hidable: false, hidden: false },
  {
    text: "Equipment",
    value: "equipmentCount",
    sortable: false,
    width: "54px",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Complexity",
    value: "complexityFactor",
    sortable: false,
    width: "54px",
    order: 5,
    hidable: true,
    hidden: true,
  },
];
