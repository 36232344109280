<template>
  <v-chip
    v-if="action != null && actionObj != null"
    class="integration-action px-2"
    :small="!small"
    :x-small="small"
    :color="actionObj.color || 'secondary'"
    :text-color="actionObj.textColor || 'white'"
  >
    <i class="fas mr-2" :class="actionObj.icon" style="line-height: 0"></i>
    <span class="font-weight-medium" style="line-height: 0">{{
      actionObj.text.toUpperCase()
    }}</span>
  </v-chip>
</template>

<script>
import enums from "../../../../../plugins/enums";
export default {
  name: "wire-cad-integration-action",
  data() {
    return {
      actionObj: null,
    };
  },
  props: {
    action: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateActionObj();
  },
  methods: {
    updateActionObj() {
      if (this.action != null) {
        this.actionObj = this.getEnumMember(enums.WireCadIntegrationAction, this.action);
      }
    },
  },
  watch: {
    action: {
      handler() {
        this.updateActionObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
