<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 700"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="'slideout-readonly-content'"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Jira Project Info ...
      </h3>
      <h3 v-else-if="selected != null" class="font-weight-bold pa-1" small>
        <i class="fab fa-jira mr-2 blue--text text--darken-2"></i>
        <span class="opacity-64">Jira Project:</span>
        {{ selected.name }}
      </h3>
      <div>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Jira Project ...</p>
    </v-container>
    <v-container fluid v-else-if="selected != null">
      <v-row class="my-0 w-100" dense style="flex: none">
        <v-col cols="12" md="2" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Project Key</h3>
          <div class="d-flex align-center readonly-border pa-2" style="gap: 1rem; height: 40px">
            <a
              v-if="selected.key != null && selected.key != ''"
              target="_blank"
              class="jira-external-link"
              style="flex: none !important; height: 24px"
              @click.stop
              :href="'https://dangeloconsultants.atlassian.net/browse/' + selected.key"
            >
              <span class="icon-wrpr mr-2">
                <i class="fab fa-jira org-icon"></i>
                <i class="far fa-external-link alt-icon"></i>
              </span>
              <span style="line-height: 0 !important">{{ selected.key }}</span>
            </a>
          </div>
        </v-col>
        <v-col cols="12" md="10" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Project Name</h3>
          <div class="d-flex align-center readonly-border pa-2" style="gap: 1rem; height: 40px">
            <v-img
              v-if="selected.avatarUrls['48x48'] != null && selected.avatarUrls['48x48'] != ''"
              :src="wrapAvatarUrl(selected.avatarUrls['48x48'])"
              height="28"
              width="28"
              position="center center"
              contain
              class="rounded"
              style="flex: none"
            >
            </v-img>
            <h4>{{ selected.name }}</h4>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Project Category</h3>
          <div class="d-flex align-center readonly-border pa-2" style="gap: 1rem; height: 40px">
            <v-chip
              v-if="selected.projectCategory != null"
              small
              color="blue-grey lighten-5"
              class="fs-12px font-weight-medium my-1 py-0 blue-grey--text text--darken-2"
            >
              {{ selected.projectCategory.name }}
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Project Access Type</h3>
          <div class="d-flex align-center readonly-border pa-2" style="gap: 1rem; height: 40px">
            <v-chip
              v-if="selected.isPrivate"
              small
              color="red"
              class="px-2"
              style="
                min-height: 20px !important;
                max-height: 20px !important;
                height: 20px !important;
              "
              dark
            >
              Private
            </v-chip>
            <v-chip
              v-else
              small
              color="blue accent-3"
              class="px-2"
              style="
                min-height: 20px !important;
                max-height: 20px !important;
                height: 20px !important;
              "
              dark
            >
              Public
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Project Description</h3>
          <div class="d-flex align-center readonly-border pa-2" style="min-height: 40px">
            <div v-if="selected.description != null && selected.description != ''">
              {{ selected.description }}
            </div>
            <i v-else class="fad fa-empty-set opacity-72">{{ selected.description }}</i>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Project Lead</h3>
          <div
            class="d-flex align-center readonly-border pa-2"
            style="gap: 0.5rem; height: 40px"
            v-if="selected.lead != null"
          >
            <v-tooltip
              right
              z-index="999"
              nudge-right="-4px"
              v-if="
                selected.lead.avatarUrls['48x48'] != null && selected.lead.avatarUrls['48x48'] != ''
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-img
                    :src="wrapAvatarUrl(selected.lead.avatarUrls['48x48'])"
                    height="28"
                    width="28"
                    position="center center"
                    contain
                    class="rounded"
                    style="flex: none"
                  >
                  </v-img>
                </div>
              </template>
              <v-img
                :src="wrapAvatarUrl(selected.lead.avatarUrls['48x48'].replace('/48', '/256'))"
                height="256"
                width="256"
                position="center center"
                contain
                class="rounded"
                style="flex: none"
              >
              </v-img>
            </v-tooltip>
            <h4>{{ selected.lead.displayName }}</h4>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Project Assignee</h3>
          <div class="d-flex align-center readonly-border pa-2" style="gap: 1rem; height: 40px">
            <h4 v-if="selected.assigneeType != null">{{ selected.assigneeType }}</h4>
            <i v-else class="fad fa-empty-set opacity-72"></i>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Issue Types</h3>
          <div
            class="d-flex flex-wrap align-center readonly-border pa-4 inset-shadow"
            style="gap: 1rem; background: #eceff1; border-radius: 0.5rem"
          >
            <v-card
              class="d-flex align-center pa-1"
              style="gap: 0.5rem; flex-wrap: nowrap"
              v-for="(issueType, i) in selected.issueTypes"
              :key="i"
            >
              <v-img
                v-if="issueType.iconUrl != null && issueType.iconUrl != ''"
                :src="wrapAvatarUrl(issueType.iconUrl)"
                height="20"
                width="20"
                position="center center"
                contain
                style="flex: none"
              >
              </v-img>
              <h4 style="white-space: nowrap">{{ issueType.name }}</h4>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Roles</h3>
          <div
            class="d-flex flex-wrap align-center readonly-border pa-4 inset-shadow"
            style="gap: 1rem; background: #eceff1; border-radius: 0.5rem"
          >
            <v-card
              class="d-flex align-center py-1 px-2"
              style="gap: 0.5rem; flex-wrap: nowrap"
              v-for="(role, i) in Object.keys(selected.roles)"
              :key="i"
            >
              <h4 style="white-space: nowrap">{{ role }}</h4>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-3">
          <h3 class="fs-15px opacity-87 mb-1">Versions</h3>
          <div
            v-if="selected.versions != null && selected.versions.length > 0"
            class="d-flex flex-wrap align-center readonly-border pa-4 inset-shadow"
            style="gap: 1rem; background: #eceff1; border-radius: 0.5rem"
          >
            <v-card
              class="d-flex flex-column align-start py-1 px-2"
              style="gap: 0.25rem; flex-wrap: nowrap"
              v-for="(version, i) in selected.versions"
              :key="i"
            >
              <h4 class="mb-0" style="white-space: nowrap">{{ version.name }}</h4>
              <v-chip
                v-if="version.released"
                x-small
                color="green"
                class="px-2 font-weight-bold"
                label
                style="
                  min-height: 20px !important;
                  max-height: 20px !important;
                  height: 20px !important;
                "
                dark
              >
                RELEASED
              </v-chip>
              <v-chip
                v-else
                x-small
                color="orange"
                label
                class="px-2 font-weight-bold"
                style="
                  min-height: 20px !important;
                  max-height: 20px !important;
                  height: 20px !important;
                "
              >
                UNRELEASED
              </v-chip>
              <h5 class="opacity-54" style="white-space: nowrap">{{ version.releaseDate }}</h5>
            </v-card>
          </div>
          <div
            v-else
            class="d-flex flex-wrap align-center readonly-border pa-4 inset-shadow"
            style="gap: 1rem; background: #eceff1; border-radius: 0.5rem"
          >
            <i class="fad fa-empty-set opacity-72"></i>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="isFetching"
                @click="fetchJiraProject()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Jira Project Details</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../plugins/permissions";
import jiraAPI from "../../../Projects/services/jira-service";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";

export default {
  name: "view-jira-project",
  data() {
    return {
      perms,
      jiraProjectId: null,
      isFetching: false,
      selected: null,
      slideouts: {
        update: {
          valid: false,
          active: false,
          fullWidth: false,
          isClosing: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "700px";
    },
  },
  methods: {
    wrapAvatarUrl(url) {
      return jiraAPI.getAvatarWrapperUrl(url);
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);

      this.selected = null;
      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(id) {
      this.$log(">>>>>>> open", id);
      this.jiraProjectId = id;
      this.fetchJiraProject();
      this.slideouts.update.active = true;
    },
    fetchJiraProject() {
      this.isFetching = true;
      jiraAPI
        .getJiraProjectJSON(this.jiraProjectId)
        .then((resp) => {
          this.$log("getJiraProject >> success", resp.data);
          this.isFetching = false;
          this.selected = this.cloneDeep(resp.data);
        })
        .catch((err) => {
          this.$log("getJiraProject >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
  },
  watch: {},
  components: { PanelSizeControl },
};
</script>
