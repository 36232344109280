export default [
  {
    text: "Info",
    value: "actions",
    sortable: false,
    width: "80px",
    class: "px-0",
    cellClass: "px-0",
  },
  {
    text: "Image",
    value: "equipment.name",
    class: "pr-0",
    cellClass: "pr-0",
    width: "90px",
    sortable: false,
  },
  {
    text: "Manufacturer",
    width: "140px",
    value: "equipment.manufacture.name",
  },
  { text: "Tag", value: "equipment.tag", class: "px-0", cellClass: "px-0", width: "120px" },
  { text: "Model", value: "equipment.model", width: "120px" },
  { text: "Count", value: "count", align: "left", sortable: false, width: "100px" },
  { text: "MSRP", value: "equipment.msrp", width: "100px" },
  { text: "Extended MSRP Cost", value: "extendedMsrp", width: "170px" },
  {
    text: "Category",
    value: "equipment.category",
    width: "120px",
    sortable: true,
    sort: (a, b) => a.name.localeCompare(b.name),
  },
  { text: "Provider", value: "providerId", width: "100px" },
  { text: "Installer", value: "installerId", width: "100px" },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "130px",
  },
  {
    text: "Created By",
    value: "createdBy",
    width: "120px",
  },
  {
    text: "Updated By",
    value: "updatedBy",
    width: "120px",
  },
];
