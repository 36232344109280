<template>
  <div>
    <side-menu ref="cocoSideMenu" />
    <v-main>
      <Header />
      <div v-show="$vuetify.breakpoint.mobile" style="margin-bottom: 48px"></div>
      <v-container
        class="app-main-container"
        fluid
        :class="$vuetify.breakpoint.mdAndDown ? 'mdAndDown' : ''"
      >
        <quick-tools-sidebar
          ref="quickTools"
          v-if="loggedInUser != null && !loggedInUser.isExternalUser"
        ></quick-tools-sidebar>
        <slot />
        <coco-viewer ref="cocoViewer"></coco-viewer>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Header from "@/features/Shared/components/Header.vue";
import Footer from "@/features/Shared/components/Footer.vue";
import SideMenu from "@/features/Shared/components/SideMenu.vue";
import CocoViewer from "@/features/Shared/components/CocoViewer/CocoViewer";
import QuickToolsSidebar from "@/features/Shared/components/QuickTools/QuickToolsSidebar.vue";
import Vue from "vue";

export default {
  components: { SideMenu, Header, QuickToolsSidebar, CocoViewer },
  mounted() {
    Vue.prototype.$cocoViewer = this.$refs.cocoViewer;
    Vue.prototype.$cocoSideMenu = this.$refs.cocoSideMenu;
  },
};
</script>

<style lang="scss">
.fs-4px {
  font-size: 4px !important;
}
.fs-6px {
  font-size: 6px !important;
}
.fs-7px {
  font-size: 7px !important;
}
.fs-8px {
  font-size: 8px !important;
}
.fs-9px {
  font-size: 9px !important;
}
.fs-10px {
  font-size: 10px !important;
}
.fs-11px {
  font-size: 11px !important;
}
.fs-12px {
  font-size: 12px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.fs-14px {
  font-size: 14px !important;
}
.fs-15px {
  font-size: 15px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.fs-17px {
  font-size: 17px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-19px {
  font-size: 19px !important;
}
.fs-20px {
  font-size: 20px !important;
}
.fs-21px {
  font-size: 21px !important;
}
.fs-22px {
  font-size: 22px !important;
}
.fs-23px {
  font-size: 23px !important;
}
.fs-24px {
  font-size: 24px !important;
}
.fs-25px {
  font-size: 25px !important;
}
.fs-26px {
  font-size: 26px !important;
}
.fs-27px {
  font-size: 27px !important;
}
.fs-28px {
  font-size: 28px !important;
}
.opacity-4 {
  opacity: 0.04 !important;
}
.opacity-8 {
  opacity: 0.08 !important;
}
.opacity-16 {
  opacity: 0.16 !important;
}
.opacity-20 {
  opacity: 0.2 !important;
}
.opacity-24 {
  opacity: 0.24 !important;
}
.opacity-32 {
  opacity: 0.32 !important;
}
.opacity-40 {
  opacity: 0.4 !important;
}
.opacity-54 {
  opacity: 0.54 !important;
}
.opacity-64 {
  opacity: 0.64 !important;
}
.opacity-72 {
  opacity: 0.72 !important;
}
.opacity-87 {
  opacity: 0.87 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
</style>

<style lang="scss">
.project-link {
  display: inline-block;
  color: #2196f3 !important;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 0.25rem;
  padding: 0.15rem 0.25rem;
  transition: all 0.15s ease-out;

  &:hover {
    color: #fff !important;
    background: #2196f3;
  }
}

.val-positive {
  color: $info-base;
  font-weight: 500;
  background: rgba($info-base, 0.06);

  &:hover {
    background: rgba($info-base, 0.2);
  }
}

.val-negative {
  color: $accent-base;
  font-weight: 500;
  background: rgba($accent-base, 0.06);

  &:hover {
    background: rgba($accent-base, 0.2);
  }
}

.val-zero {
  color: rgba($shades-black, 0.4);

  i {
    color: rgba($shades-black, 0.4);
  }

  &:hover {
    background: rgba($shades-black, 0.08);
  }
}

.no-hours-available {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 350px;
    // opacity: 0.87;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.type-select-with-bg {
  .v-input__slot {
    min-height: 28px !important;
    padding-left: 0.5rem !important;
    padding-right: 0.15rem !important;
    // box-shadow: none !important;
    background-color: rgba($shades-black, 0.08) !important;

    .v-select__slot {
      height: 28px;
    }
  }

  > .v-input__control {
    min-height: 28px !important;
  }

  .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:not(:hover) {
    background-color: rgba($shades-black, 0.08) !important;
  }

  .v-select__selection {
    font-size: 14px;
    font-weight: 500;
  }
}
.v-select-list .v-list-item__title {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

.alt-comp-wrapper {
  &.hide-border {
    .v-autocomplete-alt .v-autocomplete .v-input__control .v-input__slot {
      border: 0 !important;
      padding-left: 0.5rem;
    }
  }

  &.details-hidden {
    // align-items: flex-end;

    & > .v-btn {
      margin-bottom: 2px;
    }
  }

  &.alt-comp-wrapper-small {
    gap: 0.5rem;

    .v-input {
      input {
        max-height: 28px !important;
      }
      .v-input__control {
        min-height: 28px !important;

        & > .v-input__slot {
          min-height: 28px !important;
          padding: 0 0.5rem 0 0.25rem;
          // padding: 0 !important;
        }

        .user-avatar {
          padding: 0;
          .user-circle {
            width: 24px;
            height: 24px;
          }
        }
        .user-fullName {
          font-size: 13px;
        }
        .user-initials {
          font-size: 11px;
        }
      }
    }
  }

  &.alt-comp-wrapper-responsive {
    .v-input--is-readonly {
      input {
        display: none !important;
      }
    }
  }
}
.faded-video {
  border-radius: 14rem;
  padding: 3.5rem !important;
  background: radial-gradient(circle, rgb(255, 255, 255) 50%, transparent 70%, transparent 100%);
}

.inset-shadow-video {
  box-shadow: inset 0px 3px 1px -2px rgba(42, 54, 59, 0.16),
    inset 2px 2px 2px 0px rgba(42, 54, 59, 0.1), inset -2px 2px 2px 0px rgba(42, 54, 59, 0.1),
    inset 0px 1px 5px 0px rgba(42, 54, 59, 0.08);
}

.v-application .has-thin-border {
  border: 1px solid rgba($shades-black, 0.24) !important;
  opacity: 1;
  overflow: hidden;
}

.v-tabs {
  box-shadow: 0px 5px 5px -3px rgba($shades-black, 0.08), 0px 8px 10px 1px rgba($shades-black, 0.05);
}

.v-messages.error--text {
  .v-messages__message {
    &::before {
      display: inline-block;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      content: "\f06a";
      margin-left: -8px;
      margin-right: 4px;
    }
  }
}

.v-text-field__prefix,
.v-text-field__suffix {
  font-size: 14px;
}

.app-main-container {
  &::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    user-select: none;
    pointer-events: none;
    background-color: rgba($shades-black, 0.04);
    -webkit-backdrop-filter: saturate(160%) blur(16px);
    backdrop-filter: saturate(160%) blur(16px);
    transition: all 0.15s ease-out;
    z-index: 18;
  }

  &.has-overlay {
    &::after {
      opacity: 1;
      pointer-events: unset;
      z-index: 1500;
    }
  }
}
.v-tabs {
  .tab-required-icon {
    position: absolute;
    left: 8px;
    top: 8px;
    font-size: 8px;
  }

  &.v-tabs--vertical {
    .tab-required-icon {
      left: 6px;
      top: 6px;
    }
  }
}

.tab-changes-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 8px;
}

.table-search-field {
  transition: all 0.2s ease-out;

  & > .v-input__control {
    min-height: 32px !important;
  }
}

.v-application .vuedl-notification {
  box-shadow: 0 6px 7px -4px rgba($shades-black, 0.2), 0 11px 15px 1px rgba($shades-black, 0.14),
    0 4px 20px 3px rgba($shades-black, 0.12) !important;
  box-shadow: 0 11px 15px -7px rgba($shades-black, 0.2), 0 24px 38px 3px rgba($shades-black, 0.14),
    0 9px 46px 8px rgba($shades-black, 0.12) !important;
}

.viewer-container {
  font-family: $body-font-family;
}

.inset-shadow {
  box-shadow: inset 0px 3px 1px -2px rgb(42 54 59 / 16%), inset 0px 2px 2px 0px rgb(42 54 59 / 10%),
    inset 0px 1px 5px 0px rgb(42 54 59 / 8%) !important;
}

.v-slide-group__next:not(.v-slide-group__next--disabled) i,
.v-slide-group__prev:not(.v-slide-group__prev--disabled) i {
  color: $error-base !important;
}

.v-slide-group__next.v-slide-group__next--disabled,
.v-slide-group__prev.v-slide-group__prev--disabled {
  opacity: 0.4;
}

.theme--light.v-treeview .v-treeview-node__root:hover::before,
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node:hover::before,
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:hover::before,
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
  border-radius: 0.5rem;
}

.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active {
  position: relative;
}

.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:hover::after,
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active::after {
  border-radius: 0 0.5rem 0.5rem 0;
  background: $info-base;
  content: "";
  width: 3px !important;
  height: 24px !important;
  min-height: unset !important;
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
}

.jira-external-link {
  position: relative;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s ease-out;

  span {
    color: rgba($info-base, 1);
  }

  .icon-wrpr {
    width: 12px;
    height: 12px;
  }

  .org-icon {
    position: absolute;
    opacity: 1;
    transform: scale(1) rotate(0deg);
    transition: all 0.2s ease-out;
  }

  .alt-icon {
    position: absolute;
    opacity: 0;
    transform: scale(0) rotate(-180deg);
    transition: all 0.2s ease-out;
  }

  &:focus,
  &:hover {
    background: rgba($info-base, 1);
    span {
      color: #fff;
    }

    .org-icon {
      opacity: 1;
      transform: scale(0) rotate(180deg);
    }

    .alt-icon {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }
}
.v-menu__content {
  .v-list-item {
    padding: 0 24px 0 16px;

    // &:not(:last-child) {
    //   border-bottom: 1px solid rgba($shades-black, 0.1);
    // }
  }
}
.mono-font {
  // font-family: "Nova Mono", monospace;
  font-family: "Cascadia Code", monospace;
}

.label-icon {
  font-size: 8px;
  margin-left: 0.25rem;
}

.typeahead-space-img {
  border: 1.5px solid rgba($shades-black, 0.24);
  // background: rgba($shades-black, 0.04);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  height: 28px;
  width: 28px;
  padding: 0.25rem;
  margin: 0.15rem 0;

  .v-responsive__sizer {
    display: none !important;
  }

  i {
    font-size: 24px;
    opacity: 0.54;
  }
}

.client-img {
  border: 1.5px solid rgba($shades-black, 0.24);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  height: 28px;
  width: 28px;
  padding: 0.15rem;
  overflow: hidden;
}

.typeahead-space-name {
  font-size: 14px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
  margin-bottom: 0.15rem !important;
}

.typeahead-space-description {
  font-size: 12px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0 !important;
  line-height: 1;
}

.more-options-menu {
  .v-list-item {
    height: 32px;
    min-height: 32px;
  }
  .v-list-item__icon {
    align-self: center;
  }

  &.more-options-menu-blue-hover {
    .v-list-item {
      .v-list-item__content {
        z-index: 1;
      }

      &::before {
        background-color: #bbdefb;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &.more-options-menu-deep-purple-hover {
    .v-list-item {
      .v-list-item__content {
        z-index: 1;
      }

      &::before {
        background-color: #d1c4e9 !important;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}
.alt-comp-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;

  .v-select-alt,
  .v-text-field-alt,
  .v-autocomplete-alt {
    width: 100%;
  }

  &.allow-view {
    .v-text-field-alt,
    .v-autocomplete-alt {
      width: calc(100% - 1rem - 28px);
    }
  }
}
.v-main__wrap {
  align-items: flex-start !important;
}

.v-switch-align-top {
  .v-input__slot {
    display: flex;
    align-items: flex-start;
  }
}
.v-switch-swap {
  .v-input__slot {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}

.v-switch-label {
  font-weight: 600 !important;
  font-size: 15px;
  cursor: pointer;
  color: rgba($shades-black, 0.87);
}

.v-fake-label {
  font-weight: 600 !important;
  font-size: 15px;
  cursor: pointer;
}

.input-label {
  display: flex;
  font-weight: 600 !important;
  font-size: 15px;
  align-items: center;
}

.row-not-empty {
  border: 1px solid $primary-base;
  background: rgba($primary-base, 0.08);
  position: relative;

  &:hover {
    background: rgba($primary-base, 0.16) !important;
  }

  &:after {
    content: "";
    position: absolute;
    left: -1px;
    top: 50%;
    width: 3px;
    border-radius: 0 1rem 1rem 0;
    height: calc(100% - 1rem);
    transform: translateY(-50%);
    background: $primary-base;
  }
}
.v-btn {
  .fa,
  .fab,
  .fad,
  .fal,
  .far,
  .fas {
    line-height: 0 !important;
  }

  .v-icon {
    font-size: 14px !important;
  }
}
.v-input__prepend-inner {
  margin-right: 0.5rem;

  .v-icon {
    font-size: 14px !important;
  }
}

.v-input__append-inner {
  margin-left: 0.5rem;

  .v-icon {
    font-size: 16px !important;
  }
}
.v-btn.v-size--default {
  &:not(.v-btn--icon) {
    padding: 8px 16px !important;
    height: unset !important;
  }
}
.v-text-field.v-input input,
.v-text-field.v-input label {
  font-size: 14px !important;
}

.v-data-table__wrapper {
  border-radius: 4px;
}
.v-data-table-header {
  background-color: #f2f3f3;
  border-radius: 4px;
}
.v-data-footer {
  font-size: 14px;
}

.v-tab {
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.v-tab .v-chip.v-size--small {
  text-transform: none !important;
  font-size: 10px;
  height: 18px;
  padding: 0 0.35rem;
  letter-spacing: 0;
}

.download-status {
  &::before {
    opacity: 0.06;
  }
}

.equipment-icon {
  padding: 0.25rem;
  margin-right: 0.4rem;
  border-radius: 50%;
  border: 1.5px solid rgba($shades-black, 0.24);
  background: rgba($shades-black, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  flex: none !important;
}

.category-name {
  // color: var(--v-success-base);
  font-size: 11px;
  font-weight: 500;
}

.equipment-availability {
  &::before {
    opacity: 0.06;
  }
}

.equipment-tag {
  font-size: 12px;
  font-weight: 600;
  font-family: "Nova Mono";
  font-family: "Cascadia Code";
  // text-transform: uppercase;
}

.mono-font-text-field {
  .v-text-field__slot input {
    font-size: 12px !important;
    font-weight: 600 !important;
    font-family: "Nova Mono";
    font-family: "Cascadia Code";
    // text-transform: uppercase;
  }
}

.equipment-prop {
  font-size: 14px;
  font-weight: 500;
}

.equipment-img {
  border: 1.5px solid rgba($shades-black, 0.24);
  background: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  height: 28px;
  width: 54px;
  padding: 0.25rem;
  margin: 0.35rem 0;

  // .v-image {
  //   cursor: pointer;
  // }

  img {
    // cursor: pointer;
    object-fit: contain;
  }

  .v-responsive__sizer {
    display: none !important;
  }

  i {
    font-size: 20px;
    opacity: 0.54;
  }

  // &:hover {
  //   border-color: $info-base;
  // }

  &.equipment-img-box {
    height: 54px;
    width: 54px;
  }
  &.equipment-img-small {
    height: 32px;
    width: 32px;
    border-radius: 0.25rem;
    padding: 0.15rem;
    margin: 0.15rem 0;
    border: 0.5px solid rgba($shades-black, 0.24);
  }
}

.equipment-card {
  border-radius: 0.3rem;
  border: 1.5px solid rgba(42, 54, 59, 0.2);
  gap: 0.65rem;

  &.equipment-card-small {
    padding: 0 0.35rem 0 0.2rem;

    .equipment-card-tag {
      font-size: 10px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .equipment-card-model {
      font-size: 10px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1 auto;
    }
  }

  &.equipment-card-no-border {
    border: none !important;
  }
}

.contract-phase-record-card {
  border-radius: 0.5rem;
  border: 1.5px solid rgba(42, 54, 59, 0.2);
  border: none;
  border-left: 5px solid rgba($shades-black, 0.28) !important;
  gap: 0.65rem;
  // border-radius: 0 1rem 1rem 0 !important;

  &.contract-phase-record-card-no-border {
    border: none !important;
  }

  &.contract-phase-record-card-valid-choice {
    border-left: 5px solid $success-base !important;
  }

  &.contract-phase-record-card-disabled {
    background: #efebe9;
    border: 1.5px dashed rgba(#795548, 0.72) !important;
  }

  // & > div:first-child {
  //   position: relative;
  //   flex: 1 0 auto;

  //   &:after {
  //     content: "";
  //     left: -0.85rem;
  //     top: -1.15rem;
  //     width: 100%;
  //     height: 77px;
  //     position: absolute;
  //     display: block;
  //     background: rgba($shades-black, 0.08);
  //   }
  // }
}

.space-img {
  border: 1.5px solid rgba($shades-black, 0.24);
  background: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  height: 28px;
  width: 54px;
  padding: 0.25rem;
  margin: 0.35rem 0;

  .v-image {
    cursor: pointer;
  }

  img {
    cursor: pointer;
    object-fit: contain;
  }

  .v-responsive__sizer {
    display: none !important;
  }

  i {
    font-size: 24px;
    opacity: 0.54;
  }

  &:hover {
    border-color: $info-base;
  }
}

body.viewer-open {
  padding-right: 0 !important;
}
.vue-slideout-content {
  display: flex;
  flex-direction: column;
  z-index: unset !important;

  & > .container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    & > .v-form {
      height: 100%;
      & > .v-window {
        height: 100%;
        .v-window-item {
          height: 100%;
          & > .container {
            height: 100%;
          }
        }
      }
    }
  }
}

.vue-slideout-enable-animation.vue-slideout-visible > .vue-slideout-layout {
  transition-duration: 312ms !important;
}

// body.vue-slideout-lock-scroll {
//   height: 100vh;
//   overflow: hidden;
// }

.vue-slideout-header {
  align-items: center;
}

.vue-slideout {
  z-index: 100 !important;

  &.slideout-readonly-content > .vue-slideout-layout {
    & > .vue-slideout-header,
    & > .vue-slideout-footer {
      background: rgba($shades-black, 0.08);
      border-top: 1px dashed rgba($shades-black, 0.24);
      border-bottom: 1px dashed rgba($shades-black, 0.24);
    }
  }
}

.slideout-deep-purple-header {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: $deep-purple-base;
    color: #fff;
    border-color: $deep-purple-base;
  }
}

.slideout-dark-header {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: $shades-black;
    color: #fff;
    border-color: $shades-black;
  }
}

.slideout-dark-striped-header-readonly-content {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: repeating-linear-gradient(
      -55deg,
      #2b363b,
      #2b363b 10px,
      #263238 10px,
      #263238 20px
    );
    color: #fff;
    border-color: $shades-black;
  }

  & > .vue-slideout-layout > .vue-slideout-footer {
    background: rgba($shades-black, 0.08);
    border-top: 1px dashed rgba($shades-black, 0.24);
    border-bottom: 1px dashed rgba($shades-black, 0.24);
  }
}

.slideout-black-header {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: #000;
    color: #fff;
    border-color: #000;
  }
}

.slideout-blue-header {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: #01579b;
    color: #fff;
    border-color: #01579b;
  }
}

.slideout-green-header {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: #1b5e20;
    color: #fff;
    border-color: #1b5e20;
  }
}

.slideout-dark-striped-header {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: repeating-linear-gradient(
      -55deg,
      #2b363b,
      #2b363b 10px,
      #263238 10px,
      #263238 20px
    );
    color: #fff;
    border-color: $shades-black;
  }
}

.slideout-full-dark {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: $shades-black;
    color: #fff;
    border-color: $shades-black;
  }
  & > .vue-slideout-layout > .vue-slideout-content {
    background: $shades-black;
    color: #fff;
    border-color: $shades-black;
  }
  & > .vue-slideout-layout > .vue-slideout-footer {
    background: $shades-black;
    color: #fff;
    border-color: rgba(#fff, 0.12);
  }
}

.slideout-readonly-content-dark-header {
  & > .vue-slideout-layout > .vue-slideout-header {
    background: $deep-purple-base;
    color: #fff;
    border-color: $deep-purple-base;
  }
}

.vue-slideout-layout {
  position: fixed !important;
  max-height: 100vh !important;
}

.vuedl-layout__closeBtn {
  top: 13px !important;
}

.v-dialog.vuedl-layout {
  .v-toolbar {
    height: 40px !important;

    .v-toolbar__content {
      height: 40px !important;

      .v-icon {
        font-size: 16px !important;
      }

      .v-toolbar__title {
        font-size: 16px;
      }
    }
  }
  .v-card__text {
    margin-top: 0.5rem;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: rgba($shades-black, 0.87);
  }
}

.v-snack__content {
  font-size: 14px !important;
}

.slideCopyBtn-leave-active,
.slideCopyBtn-enter-active {
  transition: 300ms;
}
.slideCopyBtn-enter {
  transform: translate(-100%, 0);
  opacity: 0;
}
.slideCopyBtn-leave-to {
  transform: translate(-100%, 0);
  opacity: 0;
}
.v-alert__content {
  // line-break: anywhere;
  // word-break: break-all;
  word-break: break-word;
}
.v-application .v-input__control .error--text {
  color: var(--v-accent-base) !important;
  caret-color: var(--v-accent-base) !important;
}

.v-select--chips {
  .v-chip {
    height: 24px;
  }
}
</style>

<style lang="scss">
.readonly-border {
  border: 1px dashed rgba($shades-black, 0.24) !important;
  border-radius: 0.5rem;
}

.readonly-border-darker {
  border: 1px dashed rgba($shades-black, 0.4) !important;
  border-radius: 0.5rem;
}

.check-box-installer {
  margin: 0px;
  padding: 0px;

  .v-input__slot {
    margin: 0px !important;
  }

  .v-messages {
    display: none;
  }
}

.jira-project-key {
  color: $shades-black;
  font-size: 14px;
  font-weight: 600;
}
.jira-project-name {
  color: rgba($shades-black, 0.54);
  font-size: 14px;
  font-weight: 400;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.v-badge__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 16px;
  min-width: 16px;
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);

  i {
    line-height: 0;
  }
}

.v-combobox-wrap {
  .v-select__selections {
    padding: 4px 0;

    .v-chip {
      margin: 4px;
    }
  }
  .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
    max-height: 32px;
    padding: 0;
    min-width: 64px;
  }
  label {
    font-weight: 600 !important;
    font-size: 15px;
  }
  .v-input {
    input {
      font-size: 14px !important;
    }

    .v-input__control > .v-input__slot {
      background-color: rgba($shades-black, 0.08) !important;

      .v-input__icon .v-icon {
        font-size: 16px;
      }
    }
    .v-input__control > .v-input__slot:hover {
      background-color: rgba($shades-black, 0.1) !important;
    }

    &.v-input--is-focused {
      .v-input__control > .v-input__slot {
        background-color: rgba($shades-black, 0.16) !important;
      }
    }

    &.error--text {
      .v-input__control > .v-input__slot {
        background-color: #fce4ec !important;
        .v-input__icon .v-icon {
          color: var(--v-accent-darken2) !important;
        }
      }
      .v-input__control > .v-input__slot:hover {
        background-color: #fce4ec !important;
      }
      &.v-input--is-focused {
        .v-input__control > .v-input__slot {
          background-color: #f8bbd0 !important;
        }
      }
    }
  }
}

.atlwdg-trigger {
  z-index: 100;
  // left: 28% !important;
  font-family: Karla !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 0.25rem 0.5rem 0.15rem 0.5rem !important;
  left: 245px !important;
  width: 32px;
  height: 20px;
  background: #fff !important;
  text-align: center;
  transition: all 0.15s ease-out;
  display: inline-flex !important;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.15s ease-out;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    background: var(--v-secondary-base) !important;
    color: #fff !important;
    border-radius: 0.25rem;
    font-size: 13px;
    padding: 0.25rem 0.5rem;
  }

  &:focus,
  &:hover {
    height: 34px;

    &:after {
      top: 40px;
      opacity: 1;
    }
  }

  &#bug_button {
    color: var(--v-accent-base) !important;
    border-color: var(--v-accent-base) !important;
    top: 0% !important;

    &:after {
      content: "Report a Bug!";
    }

    &:focus,
    &:hover {
      background: var(--v-accent-base) !important;
      color: #fff !important;
      // border-color: #fff !important;
    }
  }
  &#feature_button {
    color: var(--v-success-base) !important;
    border-color: var(--v-success-base) !important;
    top: 0% !important;
    left: calc(245px + 32px + 0.5rem) !important;

    &:after {
      content: "Suggest a Feature!";
    }

    &:focus,
    &:hover {
      background: var(--v-success-base) !important;
      color: #fff !important;
      // border-color: #fff !important;
    }
  }
  &#perms_button {
    color: var(--v-info-base) !important;
    border-color: var(--v-info-base) !important;
    top: 0% !important;
    left: calc(245px + 32px + 32px + 0.5rem + 0.5rem) !important;

    &:after {
      content: "Request a Permission!";
    }

    &:focus,
    &:hover {
      background: var(--v-info-base) !important;
      color: #fff !important;
      // border-color: #fff !important;
    }
  }

  .icon-wrpr {
    position: relative;
    display: inline-flex;
    justify-content: center;
    height: 16px;
    font-size: 14px;
    width: 100%;
  }

  .org-icon {
    position: absolute;
    opacity: 1;
    transform: scale(1) rotate(0deg);
    transition: all 0.2s ease-out;
  }

  .alt-icon {
    position: absolute;
    opacity: 0;
    transform: scale(0) rotate(-180deg);
    transition: all 0.2s ease-out;
  }

  &:focus,
  &:hover {
    .org-icon {
      opacity: 1;
      transform: scale(0) rotate(180deg);
    }

    .alt-icon {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }
}

.tiptap-vuetify-editor {
  .tiptap-vuetify-todo-item-view .todo-content {
    margin-top: 0.15rem;
  }

  .tiptap-vuetify-todo-item-view .v-input--checkbox {
    margin-top: 0 !important;
    margin-right: 0 !important;
    padding-top: 0 !important;
  }

  .tiptap-vuetify-editor__toolbar {
    border: 1px solid rgba($shades-black, 0.2);
    border-radius: 4px 4px 0 0 !important;
    z-index: 1000000;

    .v-btn {
      & > .v-btn__content .v-icon {
        color: rgba($shades-black, 0.7) !important;
      }
    }
  }

  .tiptap-vuetify-editor__content {
    border: 1px solid rgba($shades-black, 0);
    transition-duration: 0.15s;

    .ProseMirror {
      min-height: 125px;
      display: block;
      margin: 0.5rem !important;
    }

    p {
      font-size: 13px;
      margin-top: 0 !important;
      margin-bottom: 4px !important;
      min-height: 1rem;
    }

    h1,
    h2,
    h3,
    h4 {
      margin: 6px 0 12px !important;
    }

    &:focus-within {
      border: 1px solid rgba($shades-black, 0.7);
    }
  }

  .tiptap-vuetify-editor__toolbar .v-toolbar {
    border-radius: 4px 4px 0 0 !important;
  }
}

.tiptap-vuetify-editor__content.tiptap-vuetify-editor__preview__readonly {
  padding: 0.5rem !important;
  max-height: 200px;
  border-radius: 0.5rem;
  background: rgba($shades-black, 0.04);
  border: 1px dashed rgba($shades-black, 0.32);

  p {
    font-size: 13px;
    margin-top: 0 !important;
    margin-bottom: 4px !important;
    min-height: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 6px 0 12px !important;
  }

  a {
    cursor: pointer !important;
    pointer-events: unset !important;
  }
}
</style>

<style lang="scss">
// #cfd8dc;
// #90a4ae;

.companies-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.company-logo-mini {
  height: 36px;
  width: 36px;
  padding: 2px;

  img {
    object-fit: contain;
  }
}

.company-logo-tiny {
  height: 16px;
  width: 16px;

  img {
    object-fit: contain;
  }
}

.company-logo {
  height: 54px;
  width: 54px;
  padding: 8px;

  img {
    object-fit: contain;
  }
}

html {
  font-size: 14px !important;
  overflow-y: overlay;
  overflow-y: auto;
  --scrollbarBG: #eceff1;
  --thumbBG: #d6dee1;
  --thumbBGHover: #a8bbbf;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  background-color: #eceff1;
  background-color: #eeeff0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--thumbBGHover);
}

.v-menu__content {
  overflow: overlay;
  background: #fff;
  border-radius: 0.25rem;
}

.theme--dark.v-divider {
  border-color: rgba(#fff, 0.2) !important;
}
.pointer {
  cursor: pointer;
}

.custom-sticky-header {
  .v-data-table__wrapper {
    max-height: calc(100vh - 225px);
    overflow: overlay;

    .v-data-table-header {
      position: sticky;
      z-index: 8;
      top: 0;
    }
  }
}
.table-sticky-header {
  .v-data-table__wrapper {
    overflow: visible;

    .v-data-table-header {
      position: sticky;
      z-index: 6;
      top: 0;
    }
  }
}
.table-sticky-header-exclude-footer {
  overflow: hidden;

  .v-data-table__wrapper {
    overflow: visible;
    overflow: auto;
    overflow: overlay;
    height: 100%;
    flex: 1 1 auto;
    // padding-bottom: 1rem;

    .v-data-table-header {
      position: sticky;
      z-index: 6;
      top: 0;
    }
  }

  .v-data-footer {
    .v-data-footer__select {
      .v-select {
        margin: 0;
        margin-left: 1rem;
        .v-select__selection {
          font-size: 14px;
        }
      }
    }
  }
}
.table-sticky-footer {
  .v-data-footer {
    position: sticky;
    z-index: 6;
    bottom: 0;
    background: #fff;
  }
}
main.v-main.sticky-main-fix {
  height: 100vh;

  .v-main__wrap {
    & > .container {
      height: 100%;
    }
  }
}
</style>

<style lang="scss">
.Vue-Toastification__container {
  top: 3.5rem !important;
}

.Vue-Toastification__toast {
  font-family: Karla !important;
  padding: 8px 16px 8px 16px !important;
  background-color: rgba(#fff, 0.72) !important;
  color: rgba($shades-black, 0.87) !important;
  -webkit-backdrop-filter: saturate(180%) blur(20px) !important;
  backdrop-filter: saturate(180%) blur(20px) !important;
  box-shadow: 0px 5px 5px -3px rgba(42, 54, 59, 0.16), 0px 8px 10px 1px rgba(42, 54, 59, 0.1),
    0px 3px 14px 2px rgba(42, 54, 59, 0.08) !important;
  border-radius: 1rem !important;
}

.Vue-Toastification__toast.notification-permission {
  // background-color: $shades-black !important;
  // color: rgba(#fff, 0.87) !important;
  border: 1px solid $info-base;
  border-radius: 0.5rem !important;
  top: -2rem;
  box-shadow: 0px 7px 8px -4px rgba(42, 54, 59, 0.16), 0px 12px 17px 2px rgba(42, 54, 59, 0.1),
    0px 5px 22px 4px rgba(42, 54, 59, 0.08) !important;
}
.Vue-Toastification__toast.notification-permission .fad {
  color: $info-base !important;
}

.Vue-Toastification__close-button {
  color: rgba($shades-black, 0.87) !important;
}
</style>

<style lang="scss">
.maz-phone-number-input .country-selector .maz-input {
  border-radius: 4px 0 0 4px !important;
}
.maz-phone-number-input .maz-input.input-phone-number {
  border-radius: 0 4px 4px 0 !important;
}
.maz-input.is-valid,
.maz-input.is-focused.is-valid {
  border-color: $info-base !important;
  border-color: rgba($shades-black, 0.24) !important;
}
.maz-input.input-phone-number.has-value:not(.is-valid):not([name="new_search_in_options"]),
.maz-input.input-phone-number.has-value.is-focused:not(.is-valid):not([name="new_search_in_options"]) {
  border-color: $accent-base !important;
}
.maz-input.is-valid.is-disabled,
.maz-input.is-focused.is-valid.is-disabled {
  // border-color: $shades-black !important;
  border: 1px dashed rgba($shades-black, 0.24) !important;
}

.maz-input.is-valid .maz-input__label {
  color: $info-base !important;
  color: rgba($shades-black, 0.54) !important;
}
.maz-input.input-phone-number.has-value:not(.is-valid) .maz-input__label {
  color: $accent-base !important;
}
.maz-input.is-disabled .maz-input__label,
.maz-input.is-focused.is-disabled .maz-input__label {
  color: rgba($shades-black, 0.54) !important;
}
.maz-input .maz-input__label,
.maz-input.is-focused .maz-input__label {
  font-weight: 600;
}

.maz-input.is-valid .maz-input__icon path.arrow,
.maz-input.is-focused.is-valid .maz-input__icon path.arrow {
  fill: $info-base !important;
  fill: rgba($shades-black, 0.54) !important;
}
.maz-input.input-phone-number.has-value:not(.is-valid) .maz-input__icon path.arrow,
.maz-input.input-phone-number.has-value.is-focused:not(.is-valid) .maz-input__icon path.arrow {
  fill: $accent-base !important;
}
.maz-input.is-valid.is-disabled .maz-input__icon path.arrow,
.maz-input.is-focused.is-valid.is-disabled .maz-input__icon path.arrow {
  fill: rgba($shades-black, 0.54) !important;
}
.maz-input--sm {
  height: 38px !important;
  border-width: 1.5px !important;
}
.hide-clear-btn {
  .maz-input__toggle-btn {
    display: none !important;
  }
}
</style>

<style lang="scss">
.report-file-icon {
  width: 120px;
  height: 120px;
  border-radius: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 54px;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &.xlsx {
    // background: rgba(#2b8c59, 0.06);
    // color: #2b8c59;
    background: rgba(#0d6e37, 0.06);
    color: #0d6e37;
  }
  &.docx {
    background: rgba(#134bb0, 0.06);
    color: #134bb0;
  }
}

.report-title-icon {
  width: 54px;
  height: 54px;
  border-radius: 54px !important;
  background: rgba($shades-black, 0.06);
  color: rgba($shades-black, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  overflow: hidden;
  transition: all 0.3s ease-out;
}
</style>

<style lang="scss">
.tree-building {
  color: $info-base;
}

.tree-level {
  color: $success-base;
}

.tree-space {
  color: $deep-purple-base;
}

.buildings-tree-wrapper {
  border: 1px dashed rgba($shades-black, 0.4);

  .v-treeview-node__children {
    position: relative;
    z-index: 1;
    margin-left: 36px;

    .v-treeview-node__level:nth-child(2) {
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border: 1px dashed rgba($shades-black, 0.4);
      border: 1px dashed rgba($success-base, 1);
      background: rgba($success-base, 0.05);
      border-radius: 0.5rem;
      z-index: -1;
    }
    // margin: 0 3.5rem 0 2.5rem;

    .v-treeview-node:not(:last-child) {
      border-bottom: 1px dashed rgba($shades-black, 0.4);
      border-bottom: 1px dashed rgba($success-base, 1);
    }
  }
}
.fake-label {
  font-weight: 600 !important;
  font-size: 15px;
}
.fake-content {
  word-break: break-word;
}
</style>
