var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"350px","persistent":""},model:{value:(_vm.modals.active),callback:function ($$v) {_vm.$set(_vm.modals, "active", $$v)},expression:"modals.active"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold d-flex",attrs:{"small":""}},[_c('i',{staticClass:"fal fa-clone mr-2"}),_vm._v("Clone Construction Phase ")]),_c('v-divider'),_c('v-form',{ref:"cloneProjectForm",model:{value:(_vm.modals.valid),callback:function ($$v) {_vm.$set(_vm.modals, "valid", $$v)},expression:"modals.valid"}},[_c('v-card-text',[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field-alt',{ref:"phaseName",attrs:{"rules":[_vm.allRules.required, _vm.allRules.noWhiteSpaces],"label":"Phase Name","id":"phaseName","placeholder":"Phase Name"},model:{value:(_vm.modals.data.name),callback:function ($$v) {_vm.$set(_vm.modals.data, "name", $$v)},expression:"modals.data.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-select-alt',{attrs:{"rules":[_vm.allRules.required],"label":"Phase Type","id":"ConstructionPhaseType","placeholder":"Construction Phase Type","items":_vm.constructionPhaseTypes,"dense":"","filled":"","item-value":"value","item-text":"text"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('construction-phase-type',{attrs:{"type":item.value}}),_c('p',{staticClass:"ma-0 ml-2 text--secondary"},[_vm._v(" "+_vm._s(_vm.getEnumMember(_vm.enums.CONSTRUCTION_PHASE_TYPE, item.value).description)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('construction-phase-type',{attrs:{"type":item.value}}),_c('p',{staticClass:"ma-0 ml-2 text--secondary"},[_vm._v(" "+_vm._s(_vm.getEnumMember(_vm.enums.CONSTRUCTION_PHASE_TYPE, item.value).description)+" ")])]}}]),model:{value:(_vm.modals.data.type),callback:function ($$v) {_vm.$set(_vm.modals.data, "type", $$v)},expression:"modals.data.type"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-menu',{attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","min-width":"auto","nudge-top":20},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field-alt',_vm._g(_vm._b({staticClass:"calendar-input",attrs:{"rules":[_vm.allRules.required],"prepend-inner-icon":"mdi-calendar","label":"Target Month","id":"startMonthValue","placeholder":"Target Month"},model:{value:(_vm.modals.data.startDate),callback:function ($$v) {_vm.$set(_vm.modals.data, "startDate", $$v)},expression:"modals.data.startDate"}},'v-text-field-alt',attrs,false),on))]}}]),model:{value:(_vm.startMonthMenu),callback:function ($$v) {_vm.startMonthMenu=$$v},expression:"startMonthMenu"}},[_c('v-date-picker',{attrs:{"color":"orange darken-3","header-color":"secondary","type":"month"},on:{"input":function($event){_vm.startMonthMenu = false}},model:{value:(_vm.modals.data.startDate),callback:function ($$v) {_vm.$set(_vm.modals.data, "startDate", $$v)},expression:"modals.data.startDate"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.cloneConstructionPhaseDiscard()}}},[_c('i',{staticClass:"fal fa-chevron-left mr-2"}),_vm._v("Discard ")]),_c('v-btn',{attrs:{"color":"info","disabled":!_vm.modals.valid,"loading":_vm.modals.loading},on:{"click":function($event){return _vm.cloneProjectConfirmed()}}},[_c('i',{staticClass:"fal fa-check mr-2"}),_vm._v(" Clone ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }