<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="entitiesFiltered"
    :total="total"
    :page.sync="options.page"
    :search.sync="options.search"
    :isLoading="isLoading"
    :openOnMount="openOnMount"
    allow-search
    allow-refresh
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    @refresh="onRefresh"
    itemValue="id"
    itemText="name"
  >
    <template v-slot:item="{ item }">
      <div
        class="d-flex align-center justify-start font-weight-bold flex-wrap py-3"
        style="cursor: pointer; gap: 0.35rem"
        :key="item.id + '_levelTemplateSelector'"
      >
        <span class="level-building-name opacity-64 fs-11px">
          <i class="fad fa-building mr-1"></i>
          {{ item.building.name }}
        </span>
        /
        <span class="fs-14px"><i class="fad fa-layer-group fs-12px mr-2"></i>{{ item.name }} </span>
      </div>
    </template>
  </filter-base-menu>
</template>

<script>
import { LevelFilterSettings } from "../FilterSettings";
import FilterBaseMenu from "../FilterBaseMenu.vue";
import projectAPI from "../../../../Projects/services/projects-service";

export default {
  name: "level-filter",
  components: { FilterBaseMenu },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new LevelFilterSettings(),
    },
    openOnMount: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  data() {
    return {
      entities: [],
      selectedItems: [],
      total: 0,
      timerId: null,
      storedSearch: null,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 10,
        sortBy: ["firstName"],
        sortDesc: [false],
      },
      isLoading: true,
      initiallyLoaded: false,
    };
  },
  computed: {
    entitiesFiltered() {
      if (this.options.search != null && this.options.search.trim() != "") {
        let searchTerm = this.options.search.toLowerCase();
        return this.entities
          .filter(
            (level) =>
              this.settings.buildingIds == null ||
              this.settings.buildingIds.length == 0 ||
              this.settings.buildingIds.includes(level.building.id)
          )
          .filter(
            (level) =>
              level.name.toLowerCase().indexOf(searchTerm) > -1 ||
              level.shortName.toLowerCase().indexOf(searchTerm) > -1 ||
              level.building.name.toLowerCase().indexOf(searchTerm) > -1 ||
              level.building.shortName.toLowerCase().indexOf(searchTerm) > -1
          );
      } else
        return this.entities.filter(
          (level) =>
            this.settings.buildingIds == null ||
            this.settings.buildingIds.length == 0 ||
            this.settings.buildingIds.includes(level.building.id)
        );
    },
  },
  methods: {
    onShow() {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
        this.getData();
      }
    },
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    onRefresh() {
      this.getData();
    },
    getData() {
      this.isLoading = true;

      projectAPI
        .getBuildings(this.settings.projectId)
        .then((resp) => {
          let buildings = resp.data.items;
          this.entities = buildings.flatMap((b) =>
            b.levels.map((l) => {
              return {
                ...l,
                building: {
                  id: b.id,
                  name: b.name,
                  shortName: b.shortName,
                },
              };
            })
          );

          this.total = this.entities.length;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
    settings: {
      handler() {
        this.$log("settings.buildingIds", this.settings.buildingIds);
      },
      deep: true,
    },
  },
};
</script>
