<template>
   <div>
    <div class="d-flex align-center">
      <h3 class="text--primary font-weight-black mr-3 d-inline-block">Access Groups</h3>
      <v-btn
        color="orange"
        small
        @click="createNewGroupEntry()"
        class="font-weight-bold"
        :disabled="readonly"
      >
        <i class="far fa-plus mr-2"></i>Add
      </v-btn>
    </div>
    <v-data-table
    dense
      :headers="groupHeaders"
      :items="selectedAccessGroups"
      :items-per-page="-1"
      class="elevation-2 mt-2"
      :loading="isAccessGroupsLoading"
      hide-default-footer
    >
    <template v-slot:progress>
      <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <access-group-selector
        :accessGroups="accessGroups"
        required
        hideLabel
        hideDetails
        hideBorder
        :disabled="readonly"
        :isAccessGroupsLoading="isAccessGroupsLoading"
        v-model="item.accessGroup"
        style="width: 100%; z-index: 1000"
        class="ml-0"
      ></access-group-selector>
    </template>
    <template v-slot:[`item.actions`]="{ item, index }">
      <v-btn :disabled="readonly" icon elevation="0" color="error" @click.stop="delGroup(item, index)">
        <i class="fal fa-trash-alt"></i>
      </v-btn>
    </template>
    <template v-slot:no-data>
      <img width="300" src="/img/art/fogg-no-connection-2.png" />
      <p class="font-weight-bold">No Data Available!</p>
    </template>
    </v-data-table>
  </div>
</template>
  
<script>
  import Enums from "../../../plugins/enums";
  import { eventBus } from "../../../main";
  import StaffService from "../services/StaffService";
  import groupHeaders from '../config/tables/UserAccessGroup.header'
  import AccessGroupSelector from '../../Projects/components/AccessGroupSelector.vue'
  import accessGroupsAPI from ".././../AccessGroups/services/access-group-service";
  
  export default {
    name: "manage-user-groups",
    data() {
      return {
        groupHeaders: groupHeaders,
        isAccessGroupsLoading: false,
        accessGroups: []
      };
    },
    props: {
      options: {
        type: Object,
        default: () => {},
      },
      selectedAccessGroups: {
        type: Array,
        default: () => [],
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      getAllGroupsData() {
        this.isAccessGroupsLoading = true;
        accessGroupsAPI
          .typeHead(null, true)
          .then((resp) => {
            this.accessGroups = resp.data;
            this.isAccessGroupsLoading = false;
          })
          .catch(() => {
            this.isAccessGroupsLoading = false;
          });
      },
      createNewGroupEntry() {
        this.selectedAccessGroups.push({
          id: 0,
          name: ''
        });
      },
      delGroup(item, index) {
        this.selectedAccessGroups.splice(index, 1);
      }
    },
    computed: {},
    watch: {},
    components: {
      AccessGroupSelector
    },
    mounted() {
      this.getAllGroupsData();
    },
  };
</script>
  
<style lang="scss"></style>
