export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    width: "196px",
    class: "report-action-header",
    cellClass: "report-action-cell",
    sortable: false,
  },
  { text: "Equipment", value: "cocoData.name", sortable: true },
  { text: "Integration Result", width: "160px", value: "integrationResult", sortable: true },
  {
    text: "GUID",
    width: "250px",
    value: "wireCadData.equipmentGUID",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: false,
  },
  {
    text: "Name",
    width: "200px",
    value: "wireCadData.equipmentName",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
  {
    text: "Description",
    width: "350px",
    value: "wireCadData.equipmentDescription",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
  {
    text: "Weight",
    width: "100px",
    value: "wireCadData.equipmentWeight",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
  {
    text: "Power",
    width: "100px",
    value: "wireCadData.equipmentPower",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
  {
    text: "Equipment Cost 1",
    width: "160px",
    value: "wireCadData.equipmentCost1",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
  {
    text: "Equipment Cost 2",
    width: "160px",
    value: "wireCadData.equipmentCost2",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
  {
    text: "WireCAD Mfr. GUID",
    width: "250px",
    value: "wireCadData.fkManufacturer",
    class: "report-wirecad-header",
    cellClass: "report-wirecad-cell",
    sortable: true,
  },
];
