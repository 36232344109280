<template>
  <v-container class="pa-5 pt-2" fluid>
    <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 mt-3 inset-shadow">
      <v-btn :disabled="readonly" color="info" ref="addContact" @click="open()">
        <i class="fal fa-plus mr-2"></i>Add New Contact
      </v-btn>
      <v-data-table
        dense
        :headers="headers"
        :items="selected.leadContacts"
        :items-per-page="-1"
        class="elevation-2 mt-2"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
        </template>
        <template v-slot:[`item.contactState`]="{ item }">
          {{ $getTextByValue(item.contactState, states) }}
        </template>
        <template v-slot:[`item.firstName`]="{ item }">
          <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
        </template>
        <template v-slot:[`item.company`]="{ item }">
          <v-tooltip top nudge-top="-16px">
            <template v-slot:activator="{ on, attrs }">
              <div class="pa-2" v-bind="attrs" v-on="on" @click.stop="viewClient(item.company.id)">
                <v-img
                  v-if="item.company.logoUrl != null && item.company.logoUrl != ''"
                  :src="item.company.logoUrl"
                  max-height="28"
                  max-width="54"
                  position="left center"
                  contain
                >
                </v-img>
                <v-img
                  v-else
                  src="/img/DNA_Symbol.png"
                  max-height="28"
                  max-width="54"
                  position="left center"
                  contain
                >
                </v-img>
              </div>
            </template>
            <span>{{ item.company.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ index }">
          <v-btn :disabled="readonly" icon elevation="0" color="error" @click.stop="del(index)">
            <i class="fal fa-trash-alt"></i>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <img width="300" src="/img/art/fogg-no-connection-2.png" />
          <p class="font-weight-bold">No Data Available!</p>
        </template>
      </v-data-table>
      <add-contacts-to
        ref="addContactToLead"
        @confirm="slideoutsConfirmed"
        :readonly="readonly"
        :selected="selected"
      ></add-contacts-to>
    </div>
  </v-container>
</template>
<script>
import AddContactsTo from "./AddContactsTo.vue";
import Enums from "../../../../plugins/enums";
import clientContactHeader from "../../../Companies/Clients/config/tables/clientContact.header";

export default {
  components: { AddContactsTo },
  props: {
    selected: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: clientContactHeader,
      states: this.$options.filters.EnumToList(Enums.clientStates),
    };
  },
  methods: {
    open() {
      this.$refs.addContactToLead.open();
    },
    slideoutsConfirmed(newContacts) {
      newContacts.forEach((elm) => {
        const index = this.selected.leadContacts.findIndex((contact) => contact.id == elm.id);
        if (index == -1) {
          this.selected.leadContacts.push(elm);
        } else {
          this.selected.leadContacts.splice(index, 1, elm);
        }
      });
    },
    viewClient(id) {},
    del(index) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this contact?`,
          title: `Delete Contact?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                this.selected.leadContacts.splice(index, 1);
                this.$dialog.notify.info(
                  "The deletion will take effect after the lead has been saved",
                  {
                    position: "top-right",
                    timeout: 3000,
                  }
                );
              },
            },
          },
        })
        .then((res) => {});
    },
  },
};
</script>
