var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alt-comp-wrapper equipment-selector",class:{
    'details-hidden': _vm.hideDetails,
    'alt-comp-wrapper-small': _vm.small,
  }},[_c('v-autocomplete-alt',{attrs:{"auto-select-first":"","rules":_vm.required ? [_vm.allRules.required] : [],"label":_vm.hideLabel
        ? null
        : _vm.label == null || _vm.label == ''
        ? "<i class='fad fa-plug mr-2'></i>Equipment"
        : _vm.label,"id":"equipmentSelector","placeholder":"Select a Equipment","items":_vm.equipmentList,"value":_vm.selected.equipmentId,"search-input":_vm.search,"item-value":"id","item-text":"tag","dense":"","filled":"","no-filter":"","clearable":"","hide-details":_vm.hideDetails,"loading":_vm.isEquipmentLoading,"readonly":_vm.readonly,"menu-props":{
      zIndex: 90000000000,
      contentClass: 'user-selector-menu',
    }},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.handleSelectedEquipment},scopedSlots:_vm._u([_vm._l((['selection', 'item']),function(item){return {key:("" + item),fn:function(data){return [_c('equipment-card',{key:item.id + '_equipmentListSelector',attrs:{"equipment":data.item,"small":"","noBorder":""}})]}}})],null,true)}),_c('v-btn',{staticClass:"pa-0",attrs:{"min-width":"24px !important","width":"24px !important","height":"24px !important","color":"secondary","outlined":"","small":"","target":"_blank","href":("/equipment/" + (this.selected.equipmentId)),"disabled":_vm.selected.equipmentId == null,"loading":_vm.isEquipmentLoading}},[_c('i',{staticClass:"far fa-external-link"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }