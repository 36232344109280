<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="entitiesFiltered"
    :total="total"
    :search.sync="options.search"
    :isLoading="isLoading"
    :openOnMount="openOnMount"
    allow-search
    allow-refresh
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    @refresh="onRefresh"
    itemValue="id"
    itemText="name"
  >
    <template v-slot:item="{ item }">
      <v-row align-content="center" justify="start" no-gutters style="flex: none">
        <v-col sm="auto" class="d-flex align-center mr-2">
          <i class="equipment-icon fad" :class="item.icon"></i>
        </v-col>
        <v-col class="d-flex align-center col-auto">
          <div class="d-flex align-center">
            {{ item.name }}
            <span class="ml-2 text--disabled">
              <i class="fas fa-hashtag fs-12px mr-1"></i>{{ item.number }}
            </span>
          </div>
        </v-col>
      </v-row>
    </template>
  </filter-base-menu>
</template>

<script>
import { EquipmentCategoryFilterSettings } from "../FilterSettings";
import FilterBaseMenu from "../FilterBaseMenu.vue";
import categoriesAPI from "../../../../Equipments/services/categories-service";

export default {
  name: "equipment-category-filter",
  components: { FilterBaseMenu },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new EquipmentCategoryFilterSettings(),
    },
    openOnMount: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  data() {
    return {
      entities: [],
      selectedItems: [],
      total: 0,
      timerId: null,
      storedSearch: null,
      options: {
        search: null,
      },
      isLoading: true,
      initiallyLoaded: false,
    };
  },
  computed: {
    entitiesFiltered() {
      if (this.options.search != null && this.options.search.trim() != "") {
        let searchTerm = this.options.search.toLowerCase();
        return this.entities.filter(
          (building) =>
            building.name.toLowerCase().indexOf(searchTerm) > -1 ||
            building.number.toLowerCase().indexOf(searchTerm) > -1
        );
      } else return this.entities;
    },
  },
  methods: {
    onShow() {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
        this.getData();
      }
    },
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    onRefresh() {
      this.getData();
    },
    getData() {
      this.isLoading = true;

      categoriesAPI
        .get()
        .then((resp) => {
          this.entities = resp.data.items.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          );
          this.total = this.entities.length;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
  },
};
</script>
