<template>
  <v-container class="pa-3" fluid v-if="projectId != null && projectId != '' && projectId != NaN">
    <h3 class="text--primary font-weight-black">Project Reports</h3>
    <div style="background: #eceff1; border-radius: 0.5rem" class="px-5 py-2 mt-3 inset-shadow">
      <v-row class="mt-1 mb-3">
        <v-col
          v-if="$has(perms.ProjectReports.BOM)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <bom-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.BOM.value)"
          ></bom-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.ExtendedBOM)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <extended-bom-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.ExtendedBOM.value)"
          ></extended-bom-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.AreaGroupBOM)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <area-groups-bom-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.AreaGroupBOM.value)"
          ></area-groups-bom-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.PIF)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <pif-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.PIF.value)"
          ></pif-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.OPC)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <opc-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.OPC.value)"
          ></opc-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.BIN)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <bin-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.BIN.value)"
          ></bin-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.RevitFamilies)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <revit-families-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.RevitFamilies.value)"
          ></revit-families-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.ClientProposal)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <client-proposal-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.ClientProposal.value)"
          ></client-proposal-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.DrawingSheetList)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <drawing-sheet-list-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.DrawingSheetList.value)"
          ></drawing-sheet-list-report>
        </v-col>
        <v-col
          v-if="$has(perms.ProjectReports.SpaceList)"
          cols="10"
          lg="4"
          md="4"
          sm="12"
          class="pa-2"
          style="overflow: visible !important"
        >
          <space-list-report
            :project-id="projectId"
            @generate="onGenerate"
            @history="viewReportHistory(enums.DownloadTaskCategory.SpacesList.value)"
          ></space-list-report>
        </v-col>
      </v-row>
    </div>
    <view-report-history ref="viewReportHistory"></view-report-history>
  </v-container>
</template>

<script>
import enums from "../../../../plugins/enums";
import perms from "../../../../plugins/permissions";
import BomReport from "./BomReport.vue";
import ExtendedBomReport from "./ExtendedBomReport.vue";
import AreaGroupsBomReport from "./AreaGroupsBomReport.vue";
import PifReport from "./PifReport.vue";
import OpcReport from "./OpcReport.vue";
import BinReport from "./BinReport.vue";
import RevitFamiliesReport from "./RevitFamiliesReport.vue";
import ClientProposalReport from "./ClientProposalReport.vue";
import DrawingSheetListReport from "./DrawingSheetListReport.vue";
import SpaceListReport from "./SpaceListReport.vue";
import ViewReportHistory from "./shared/ViewReportHistory.vue";

export default {
  components: {
    BomReport,
    ExtendedBomReport,
    AreaGroupsBomReport,
    PifReport,
    OpcReport,
    BinReport,
    RevitFamiliesReport,
    ClientProposalReport,
    DrawingSheetListReport,
    SpaceListReport,
    ViewReportHistory,
  },
  name: "project-reports-list",
  data() {
    return {
      enums,
      perms,
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    //get project spaces
  },
  methods: {
    onGenerate() {},
    viewReportHistory(downloadTaskCategory) {
      this.$refs.viewReportHistory.open(this.projectId, downloadTaskCategory);
    },
  },
  watch: {},
  computed: {},
};
</script>

<style lang="scss">
.report-card {
  overflow: hidden;
  transition: all 0.15s ease-out;
  border: 1.5px solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5rem 2.5rem 2.5rem 5rem !important;
  border-radius: 5rem !important;

  .report-card-content {
    display: flex;
    flex-direction: row;
  }

  .report-icon {
    width: 70px;
    height: 70px;
    // margin: 0 auto;
    // margin-top: 1rem;
    margin: 0.5rem 1rem 0.5rem 0.5rem;
    border-radius: 70px !important;
    background: rgba($shades-black, 0.06);
    color: rgba($shades-black, 0.87);
    border: 1px dashed rgba($shades-black, 0) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }

  .report-icon-ghost {
    position: absolute;
    // right: 1rem;
    // top: 1rem;
    // font-size: 10rem;
    right: 2rem;
    top: -1rem;
    font-size: 8rem;
    opacity: 0.05;
    user-select: none;
    pointer-events: none;
    z-index: 0;
    transition: all 0.3s ease-out;
  }

  .v-card__title,
  .v-card__subtitle {
    transition: all 0.3s ease-out;
    margin: 0;
    padding: 0;
  }

  .v-card__title {
    font-weight: 800;
  }

  .v-card__subtitle {
    opacity: 0.54;
  }

  .card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .v-card__actions {
    padding-right: 1rem;

    .v-btn {
      color: rgba($shades-black, 1);
      color: rgba($deep-purple-base, 1);
      background: rgba($deep-purple-base, 0.06);
    }
  }

  &:hover,
  &:focus {
    border-color: rgba($deep-purple-base, 0.54) !important;
    background: $deep-purple-base !important;
    // color: #fff;

    &:before {
      opacity: 0 !important;
    }

    .v-card__title {
      color: #fff;
    }

    .v-card__subtitle {
      color: rgba(#fff, 1);
    }

    .report-icon {
      background: rgba($deep-purple-base, 0.08) !important;
      border-color: rgba($deep-purple-base, 0.54) !important;
      color: rgba($deep-purple-base, 1);

      background: rgba(#fff, 0.2) !important;
      border-color: rgba(#fff, 0.54) !important;
      color: #fff;
    }

    .report-icon-ghost {
      font-size: 10rem;
      opacity: 0.16;
      color: rgba($deep-purple-base, 1);
      color: #fff;
    }

    .v-card__actions {
      padding-right: 1rem;

      .v-btn {
        color: #fff !important;
        background: rgba(#fff, 0.24);
      }
    }
  }
}
</style>
