<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '1100px'"
    :min-size="nested ? 400 : 1100"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.diff.active"
    v-on:close="onSlideoutClosing"
    class="slideout-dark-header"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1 d-flex align-center" small>
        <span style="white-space: no-wrap">
          <i class="fad fa-calendar-clock mr-2"></i> Space Aggregated Changes
        </span>
      </h3>
      <v-btn @click="closeDiffSlideout()" icon dark>
        <i class="far fa-times"></i>
      </v-btn>
    </template>

    <v-form v-model="slideouts.diff.valid" ref="updateForm" style="height: 100%">
      <v-container
        v-if="slideouts.diff.active"
        fluid
        class="pa-0 inset-shadow d-flex flex-column"
        style="background: #eceff1; overflow: auto; overflow: overlay; height: 100%"
      >
        <div style="position: sticky; top: 0; z-index: 1">
          <v-sheet
            elevation="2"
            height="48"
            class="d-flex flex-row align-center px-1"
            style="width: 100%"
          >
            <label class="fs-15px ma-0 mx-2 font-weight-bold">Show Aggregated Changes Since:</label>
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :nudge-top="20"
              z-index="999999999"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field-alt
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  short
                  class="blue-grey lighten-5 px-0"
                  style="width: 120px; min-width: 120px; border-radius: 0.25rem"
                  id="StartMonth"
                  placeholder="Start Month"
                  v-model="options.startDate"
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field-alt>
              </template>
              <v-date-picker
                color="orange darken-3"
                header-color="secondary"
                :first-day-of-week="1"
                v-model="options.startDate"
                elevation="4"
                :allowed-dates="allowedStartDate"
                @input="onStartDateInput"
              ></v-date-picker>
            </v-menu>
            <i class="fad fa-bolt ml-4"></i>
            <v-btn class="ml-4" @click="sinceLastWeek()" outlined small dark color="deep-purple">
              <span class="fs-12px font-weight-bold"> Since Last Week </span>
            </v-btn>
            <v-btn class="ml-2" @click="sinceThisMonth()" outlined small dark color="deep-purple">
              <span class="fs-12px font-weight-bold"> Since 1<sup>st</sup> of This Month </span>
            </v-btn>
            <v-btn class="ml-2" @click="sinceLastMonth()" outlined small dark color="deep-purple">
              <span class="fs-12px font-weight-bold"> Since 1<sup>st</sup> of Last Month </span>
            </v-btn>
            <v-progress-linear
              color="info"
              absolute
              indeterminate
              bottom
              height="2"
              :active="isLoading"
            ></v-progress-linear>
          </v-sheet>
        </div>
        <v-scroll-y-transition mode="out-in" style="width: 100%">
          <div
            v-if="isLoading"
            :key="'projectBigBoardMonths-list-loading'"
            class="projectBigBoardMonths-list-loading d-flex flex-column align-center justify-center"
            style="
              overflow: auto;
              overflow: overlay;
              height: 100%;
              flex: 1 1 auto;
              display: flex;
              flex-direction: column;
            "
          >
            <video
              width="275"
              muted
              loop
              autoplay
              class="inset-shadow-video"
              style="padding: 0.5rem; border-radius: 10rem; background: #fff"
            >
              <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
            </video>
            <h4 class="mt-3">Fetching Space Aggregated Change ...</h4>
          </div>
          <div
            v-else-if="
              variance != null &&
              (variance.props.length > 0 ||
                variance.oldAreaGroups.length > 0 ||
                variance.areaGroups.length > 0)
            "
            key="has-logs"
            class="pa-5"
            style="overflow: auto; overflow: overlay"
          >
            <v-expansion-panels multiple v-model="expandedPanels">
              <v-expansion-panel key="props">
                <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                  <v-row no-gutters justify="space-between">
                    <v-col cols="auto" class="d-flex align-center">
                      <span class="font-weight-bold d-flex align-center">
                        <v-chip
                          style="
                            height: 22px;
                            min-width: 22px;
                            justify-content: center;
                            font-size: 12px;
                          "
                          color="blue-grey"
                          text-color="white"
                          class="mr-2"
                          >{{ variance.props.length }}</v-chip
                        >Prop{{ variance.props.length > 1 ? "s" : "" }} were changed
                      </span>
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center"> </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="logged-changes-panel-content">
                  <div v-for="(changedProp, j) in variance.props" :key="j">
                    <v-divider class="mb-1"></v-divider>
                    <div class="py-2 px-4 mb-1">
                      <span class="d-flex align-center">
                        <v-chip small color="blue-grey" text-color="white" style="height: 22px">{{
                          changedProp.prop
                            .split(/(?=[A-Z])/)
                            .join(" ")
                            .replace(". ", " > ")
                        }}</v-chip>
                        <span class="font-weight-medium ml-2">was updated</span>
                      </span>
                      <v-row class="mt-1">
                        <v-col cols="12" md="6">
                          <div class="d-flex">
                            <span class="font-weight-bold mr-2">From:</span>
                            <!-- <manufacturer-logged-prop
                                  v-if="changedProp.prop == 'Manufacture'"
                                  v-model="changedProp.oldValue"
                                ></manufacturer-logged-prop> -->
                            <image-url-logged-prop
                              v-if="changedProp.prop == 'ImageUrl'"
                              v-model="changedProp.oldValue"
                            ></image-url-logged-prop>
                            <default-logged-prop
                              v-else
                              v-model="changedProp.oldValue"
                            ></default-logged-prop>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div class="d-flex">
                            <span class="font-weight-bold mr-2">To:</span>
                            <!-- <manufacturer-logged-prop
                                  v-if="changedProp.prop == 'Manufacture'"
                                  v-model="changedProp.newValue"
                                ></manufacturer-logged-prop> -->
                            <image-url-logged-prop
                              v-if="changedProp.prop == 'ImageUrl'"
                              v-model="changedProp.newValue"
                            ></image-url-logged-prop>
                            <default-logged-prop
                              v-else
                              v-model="changedProp.newValue"
                            ></default-logged-prop>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                key="equip"
                v-if="
                  variance.areaGroups.length > 0 ||
                  variance.oldAreaGroups.length > 0 ||
                  variance.areaGroupsDiffs.length > 0
                "
              >
                <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                  <v-row no-gutters justify="space-between">
                    <v-col cols="auto" class="d-flex align-center">
                      <span class="font-weight-bold"> Equipment change log </span>
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center"> </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="logged-changes-panel-content pa-2 pt-0">
                  <v-container
                    fluid
                    class="blue-grey lighten-5"
                    style="
                      border-radius: 4px;
                      border: 1.5px solid;
                      border-color: #b39ddb !important;
                    "
                  >
                    <!-- <v-divider class="mt-4"></v-divider> -->
                    <h3 class="secondary--text">
                      <v-sheet elevation="1" rounded="xl" class="d-inline-flex align-center">
                        <label class="fake-label ma-0 mx-2">Show as: </label>
                        <v-btn-toggle
                          v-model="variance.diffsView"
                          mandatory
                          color="deep-purple"
                          dense
                          group
                          style="height: 28px"
                        >
                          <v-btn
                            :value="false"
                            color="white"
                            class="mx-1"
                            style="border-radius: 12px; padding: 8px 10px !important"
                          >
                            <i class="fad fa-camera mr-2"></i>
                            <span style="text-transform: none" class="font-weight-medium">
                              Snapshots
                            </span>
                          </v-btn>
                          <v-btn
                            :value="true"
                            color="white"
                            class="mx-0 mr-1"
                            style="border-radius: 12px; padding: 8px 10px !important"
                          >
                            <i class="far fa-plus-minus mr-2"></i>
                            <span style="text-transform: none" class="font-weight-medium">
                              Differences
                            </span>
                          </v-btn>
                        </v-btn-toggle>
                      </v-sheet>
                    </h3>
                    <v-container fluid class="pa-0 mt-2" v-if="variance.diffsView">
                      <area-groups-entry-log
                        :areaGroups="variance.areaGroupsDiffs"
                      ></area-groups-entry-log>
                    </v-container>
                    <v-container fluid class="pa-0" v-else>
                      <v-row>
                        <v-col cols="12" md="6">
                          <h3
                            class="mb-1 mt-3 blue-grey--text fake-label"
                            style="padding-left: 2px"
                          >
                            From
                          </h3>
                          <area-groups-entry-log
                            :areaGroups="variance.oldAreaGroups"
                          ></area-groups-entry-log>
                        </v-col>
                        <v-col cols="12" md="6">
                          <h3
                            class="mb-1 mt-3 blue-grey--text fake-label"
                            style="padding-left: 2px"
                          >
                            To
                          </h3>
                          <area-groups-entry-log
                            :areaGroups="variance.areaGroups"
                          ></area-groups-entry-log>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-else key="no-hours-available" class="no-hours-available">
            <img src="/img/art/tumble-weed.svg" />
            <v-chip
              color="brown lighten-4 brown--text"
              style="height: 24px"
              class="font-weight-medium mb-1 px-3"
            >
              No aggregated changes made since <u class="ml-1">{{ options.startDate }}</u>
            </v-chip>
          </div>
        </v-scroll-y-transition>
      </v-container>
    </v-form>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDiffSlideout" text><i class="far fa-xmark mr-2"></i>Close</v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../plugins/permissions";
import spaceService from "../../services/spaces-service";
import DefaultLoggedProp from "./DefaultLoggedProp.vue";
import AreaGroupsEntryLog from "./AreaGroupsEntryLog.vue";
import ImageUrlLoggedProp from "./ImageUrlLoggedProp.vue";

export default {
  name: "space-aggregated-change",
  components: { DefaultLoggedProp, AreaGroupsEntryLog, ImageUrlLoggedProp },
  data() {
    return {
      perms: perms,
      isLoading: false,
      variance: null,
      expandedPanels: [],
      space: {},
      slideouts: {
        diff: {
          active: false,
        },
      },
      startDateMenu: false,
      endDateMenu: false,
      options: {
        startDate: null,
        endDate: null,
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    sinceLastWeek() {
      this.options.startDate = this.formatDate(
        new Date(new Date() - 7 * 24 * 60 * 60 * 1000)
      ).substr(0, 10);
      this.onStartDateInput();
    },
    sinceThisMonth() {
      this.options.startDate = this.formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).substr(0, 10);
      this.onStartDateInput();
    },
    sinceLastMonth() {
      this.options.startDate = this.formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
      ).substr(0, 10);
      this.onStartDateInput();
    },
    open(space) {
      this.logs = [];
      this.space = this.cloneDeep(space);
      this.options.startDate = this.$getFromLocal(
        `SpaceAggregatedChange-startDate`,
        false,
        this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).substr(0, 10)
      );
      // this.options.endDate = this.$getFromLocal(
      //   `SpaceAggregatedChange-endDate`,
      //   false,
      //   this.formatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 28)).substr(
      //     0,
      //     10
      //   )
      // );
      this.options.endDate = this.formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      ).substr(0, 10);
      this.loadVariance();
      this.slideouts.diff.active = true;
    },
    allowedStartDate(val) {
      const mYear = Number.parseInt(val.substring(0, 4));
      const mMonth = Number.parseInt(val.substring(5, 7));
      const mDay = Number.parseInt(val.substring(8, 10));
      const curYear = new Date().getFullYear();
      const curMonth = new Date().getMonth() + 1;
      const curDay = new Date().getDate();
      return (
        mYear < curYear ||
        (mYear == curYear && mMonth < curMonth) ||
        (mYear == curYear && mMonth == curMonth && mDay < curDay)
      );
    },
    closeDiffSlideout() {
      this.slideouts.diff.active = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.diff.active);
      this.$emit("close");
    },
    onStartDateInput() {
      this.$log(">>>> onStartDateInput");
      this.startDateMenu = false;
      this.$setToLocal(`SpaceAggregatedChange-startDate`, this.options.startDate);
      this.loadVariance();
    },
    prepareVariance(varianceData) {
      varianceData.diffsView = true;
      if (varianceData.oldAreaGroups == null) varianceData.oldAreaGroups = [];
      if (varianceData.areaGroups == null) varianceData.areaGroups = [];
      varianceData.areaGroupsDiffs = this.compare(
        this.cloneDeep(varianceData.oldAreaGroups || []),
        this.cloneDeep(varianceData.areaGroups || [])
      );
      this.variance = this.cloneDeep(varianceData);
      this.expandedPanels = [0, 1];
    },
    compare(oldGroups, newGroups) {
      //check old groups
      for (let i = 0; i < oldGroups.length; i++) {
        const oldGroup = oldGroups[i];
        const matchingNewGroup = newGroups.find((ng) => ng.id == oldGroup.id);
        oldGroup.diffs = {};
        if (matchingNewGroup == null) oldGroup.diffs["deleted"] = true;
        else {
          //check for deleted equipment
          for (let j = 0; j < oldGroup.equipments.length; j++) {
            const oldEquip = oldGroup.equipments[j];
            const matchingNewEquip = matchingNewGroup.equipments.find((ne) => ne.id == oldEquip.id);
            oldEquip.diffs = {};
            if (matchingNewEquip == null) {
              // this is a new equipment
              oldEquip.diffs["deleted"] = true;
            }
          }
        }
      }

      //check new groups
      for (let i = 0; i < newGroups.length; i++) {
        const newGroup = newGroups[i];
        const matchingOldGroup = oldGroups.find((og) => og.id == newGroup.id);
        newGroup.diffs = {};
        if (matchingOldGroup == null) {
          // this is a new group
          newGroup.diffs["new"] = true;
        } else {
          //check if group name changed
          if (newGroup.name != matchingOldGroup.name) newGroup.diffs["nameChanged"] = true;

          //check for equipment changes
          var updateCount = 0;
          for (let j = 0; j < newGroup.equipments.length; j++) {
            const newEquip = newGroup.equipments[j];
            const matchingOldEquip = matchingOldGroup.equipments.find((oe) => oe.id == newEquip.id);
            newEquip.diffs = {};
            if (matchingOldEquip != null) {
              //check if the count changed
              if (newEquip.count != matchingOldEquip.count) {
                newEquip.diffs["countChanged"] = true;
                updateCount++;
              }
            } else {
              // this is a new equipment
              newEquip.diffs["new"] = true;
              updateCount++;
            }
          }
          if (updateCount > 0) newGroup.diffs["equipmentUpdated"] = true;

          // add deleted equipments
          var deletedOldEquipment = matchingOldGroup.equipments.filter((e) => e.diffs.deleted);
          if (deletedOldEquipment.length > 0) newGroup.equipments.push(...deletedOldEquipment);
        }
      }

      // add deleted equipments
      var deletedOldGroups = oldGroups.filter((e) => e.diffs.deleted);
      if (deletedOldGroups.length > 0) newGroups.push(...deletedOldGroups);

      return newGroups;
    },
    loadVariance() {
      this.isLoading = true;
      var toSend = {
        startDate: this.options.startDate, //this.convertToPhaseDate(this.options.startDate)
        endDate: this.options.endDate, //this.convertToPhaseDate(this.options.endDate)
      };
      spaceService
        .getFirstAndLastActivityLogInRange(this.space.id, toSend)
        .then((resp) => {
          this.$log("variance > resp.data", resp.data);
          this.prepareVariance(resp.data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    convertToPhaseDate(date) {
      const dateObj = new Date(date);
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // months from 1-12
      const year = dateObj.getUTCFullYear();
      return `${year}-${month}`;
    },
  },
  watch: {},
};
</script>
