<template>
  <v-card
    v-if="selected != null"
    elevation="2"
    :class="{
      'contract-phase-record-card-no-border': noBorder,
      'contract-phase-record-card-valid-choice': validChoice,
      'contract-phase-record-card-disabled': disabled,
    }"
    class="contract-phase-record-card d-flex align-center justify-space-between flex-row py-2 px-3"
    style="gap: 1rem"
    :style="{
      width: fullWidth ? '100%' : 'auto',
    }"
    rounded="lg"
  >
    <div class="d-flex flex-column align-start" style="gap: 0.5rem">
      <h4>
        <i class="fad fa-folder-open mr-1 secondary--text"></i>
        <a
          small
          text
          target="_blank"
          class="py-0 px-1 fs-13px secondary--text"
          max-height="20px"
          :href="`/projects/${selected.project.id}`"
          >{{ selected.project.fullName }}
        </a>
      </h4>
      <h4 class="d-flex align-center">
        <i class="fad fa-calendar-lines mr-2 secondary--text" style="width: 16px"></i>
        <v-chip color="secondary" dark class="px-1" label x-small>
          <span class="fs-13px font-weight-medium">{{ selected.startDate.substr(0, 7) }}</span>
        </v-chip>
      </h4>
    </div>
    <div class="d-flex flex-row align-center" style="gap: 1rem">
      <div class="d-flex flex-column align-end text-right" style="gap: 6px; height: 61px">
        <b class="opacity-87 fs-12px" style="white-space: nowrap">{{
          getLaborTypeObj(selected.sourceLaborType).desc
        }}</b>
        <span
          class="d-flex align-center available-zone"
          style="gap: 0.35rem"
          @click="openSourceRecords"
        >
          <span class="fs-12px font-weight-bold opacity-64">Available:</span>
          <span class="green--text fs-18px font-weight-medium mono-font">
            <!-- {{ selected.availableHours.toString().padStart(2, "0") }} -->
            {{ (selected.availableHours - selected.shiftedHours) | fixedFloatFormat }}
          </span>
        </span>
      </div>
      <v-divider vertical></v-divider>
      <div class="d-flex flex-column align-start" style="gap: 2px; height: 61px">
        <b class="opacity-72">Shifted Hours</b>
        <counter
          v-model="selected.shiftedHours"
          :min="0"
          :max="selected.availableHours"
          @input="shiftedHoursChanged"
          :readonly="readonly || disabled"
          isFloat
        />
      </div>
      <v-divider vertical></v-divider>
      <div
        class="d-flex flex-column align-start"
        style="gap: 2px; position: relative; height: 61px"
      >
        <span class="d-flex align-center">
          <span class="mr-1 opacity-72 font-weight-bold">
            Shift To <span class="text--secondary">Date</span>
          </span>
        </span>
        <v-menu
          v-model="shiftedToDateMenu"
          :close-on-content-click="false"
          :disabled="readonly || disabled"
          transition="scale-transition"
          offset-y
          left
          origin="right top"
          min-width="auto"
          z-index="999999999"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field-alt
              prepend-inner-icon="mdi-calendar"
              readonly
              short
              class="blue-grey lighten-5"
              style="width: 120px; min-width: 120px; border-radius: 0.25rem"
              placeholder="Target Date"
              v-model="selected.shiftedToDate"
              @input="shiftedToDateChanged"
              :clearable="!readonly && !disabled"
              v-bind="attrs"
              hide-details
              v-on="on"
            ></v-text-field-alt>
          </template>
          <v-date-picker
            color="orange darken-3"
            header-color="secondary"
            v-model="selected.shiftedToDate"
            :disabled="readonly || disabled"
            type="month"
            elevation="4"
            :allowed-dates="allowedShiftToMonths"
            @input="shiftedToDateChanged"
          ></v-date-picker>
        </v-menu>
        <span
          v-if="shiftDiff != 0"
          style="white-space: nowrap; position: absolute; left: 0; bottom: -6px"
          class="px-1 rounded fs-11px d-flex align-center font-weight-medium"
          :class="{
            'info--text': shiftDiff > 0,
            'pink--text': shiftDiff < 0,
            'val-zero': shiftDiff == 0,
          }"
          >{{ shiftDiff > 0 ? "+" : "" }}{{ shiftDiff }} {{ shiftDiff == 1 ? "month" : "months" }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import enums from "../../../plugins/enums";

export default {
  name: "available-record-card",
  components: {},
  data() {
    return {
      enums,
      selected: null,
      shiftedToDateMenu: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.$log("available", this.cloneDeep(this.value));
    this.selected = this.cloneDeep(this.value);
  },
  methods: {
    openSourceRecords() {
      this.$emit("source-records", this.cloneDeep(this.selected));
    },
    shiftedHoursChanged() {
      this.$emit("input", this.cloneDeep(this.selected));
    },
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
    shiftedToDateChanged($value) {
      this.shiftedToDateMenu = false;
      this.$emit("input", this.cloneDeep(this.selected));
    },
    monthDiff(dt1, dt2) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60 * 24 * 30;
      return Math.round(diff);
    },
    allowedShiftToMonths(val) {
      const val_Year = Number.parseInt(val.substring(0, 4));
      const val_Month = Number.parseInt(val.substring(5, 7));
      const cur_Year = new Date().getFullYear();
      const cur_Month = new Date().getMonth() + 1;
      const projectFirstMonth_Year = Number.parseInt(
        this.selected.project.firstMonth.substring(0, 4)
      );
      const projectFirstMonth_Month = Number.parseInt(
        this.selected.project.firstMonth.substring(5, 7)
      );
      const projectLastMonth_Year = Number.parseInt(
        this.selected.project.lastMonth.substring(0, 4)
      );
      const projectLastMonth_Month = Number.parseInt(
        this.selected.project.lastMonth.substring(5, 7)
      );
      ///////////////////////////////
      const isNotSameAsPhase = val.substr(0, 7) != this.selected.startDate.substr(0, 7);
      const isAfterCurrentMonth =
        val_Year > cur_Year || (val_Year == cur_Year && val_Month >= cur_Month);
      const isAfterContractStarts =
        val_Year > projectFirstMonth_Year ||
        (val_Year == projectFirstMonth_Year && val_Month >= projectFirstMonth_Month);
      const isBeforeContractEnds =
        val_Year < projectLastMonth_Year ||
        (val_Year == projectLastMonth_Year && val_Month <= projectLastMonth_Month);

      return (
        isAfterCurrentMonth && isAfterContractStarts && isBeforeContractEnds && isNotSameAsPhase
      );
    },
  },
  computed: {
    validChoice() {
      if (this.selected.shiftedHours != 0 && this.selected.shiftedToDate != null) return true;
      return false;
    },
    shiftDiff() {
      if (this.selected.shiftedToDate == null) return 0;
      return this.monthDiff(
        new Date(this.selected.startDate),
        new Date(this.selected.shiftedToDate)
      );
    },
  },
  watch: {
    value: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.selected = this.cloneDeep(this.value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.available-zone {
  display: inline-flex;
  cursor: pointer;
  background: rgba($shades-black, 0.06);
  border: 1px solid rgba($shades-black, 0.16);
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  transition: all 0.15s ease-out;

  &:hover {
    background: rgba($success-base, 0.2);
  }
}
</style>
