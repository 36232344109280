<template>
  <v-menu :close-on-content-click="false" bottom nudge-bottom="32">
    <template v-slot:activator="{ on: { click } }">
      <v-tooltip bottom nudge-bottom="-5">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn height="32" color="white" x-small @click="click" :disabled="disabled">
              <i class="fal fa-tasks" style="font-size: 16px"></i>
            </v-btn>
          </div>
        </template>
        <span>Change Columns Visibility</span>
      </v-tooltip>
    </template>
    <v-list class="py-0">
      <div class="d-flex my-1 justify-space-between align-center">
        <h5 class="pl-4">Columns Visibility</h5>
        <v-btn color="primary" text class="text-capitalize mr-1" @click="reset()">reset</v-btn>
      </div>
      <v-list-item-group
        v-model="groups"
        @change="emitHeaders"
        multiple
        style="overflow: auto; overflow: overlay; max-height: 260px"
      >
        <v-list-item
          :input-value="column"
          v-for="(column, k) in value"
          :key="k + '_' + column.value"
          :active-class="column.hidable && !column.disabled ? 'blue--text' : ''"
          :class="column.disabled ? 'opacity-32 d-none' : ''"
          dense
          :disabled="!column.hidable || column.disabled"
        >
          <template v-slot:default="{ active }">
            <!-- <span class="fs-12px font-weight-medium mr-1" style="width: 20px">{{ k + 1 }}</span> -->
            <v-list-item-action class="mr-1 my-0">
              <v-checkbox
                :disabled="!column.hidable || column.disabled"
                :input-value="active"
                color="info"
                dense
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="px-1">
              <v-list-item-title
                style="font-size: 12px !important"
                :class="column.hidable ? '' : 'opacity-87'"
              >
                <i class="fad fa-lock-keyhole mr-2" v-if="!column.hidable"></i>{{ column.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "columns-visibility-control",
  props: {
    value: {
      type: Array,
      require: true,
    },
    defaults: {
      type: Array,
      require: true,
    },
    withColumnsOrder: {
      type: Boolean,
      default: false,
    },
    storageKey: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groups: [],
    };
  },
  mounted() {
    let columns = this.cloneDeep(this.value);
    if (this.storageKey) {
      columns = this.compareWithOriginalHeaders(
        this.$getFromLocal(`${this.storageKey}-filteredColumns`, true, this.value),
        columns
      );
    }
    this.groups = this.returnSelectedGroup(columns);
    this.$emit("input", columns);
  },
  methods: {
    compareWithOriginalHeaders(headers, originalHeaders) {
      return originalHeaders.map((elm) => {
        const header = headers.find((header) => header.value == elm.value);
        if (header) elm.hidden = header.hidden;
        return elm;
      });
    },
    emitHeaders(groups) {
      let headers = this.returnSelectedHeader(groups);
      if (this.withColumnsOrder) {
        headers = this.sortByOrder(headers);
      }
      this.$emit("input", headers);
    },
    sortByOrder(headers) {
      return headers.sort((a, b) => a.order - b.order);
    },
    returnSelectedHeader(groups) {
      return this.value.map((elm, key) => {
        elm.hidden = !groups.includes(key);
        return elm;
      });
    },
    returnSelectedGroup(columns) {
      const selectedGroups = [];
      const headers = this.sortByOrder(this.cloneDeep(this.value));
      columns.forEach((element) => {
        if (!element.hidden)
          selectedGroups.push(headers.findIndex((column) => column.text == element.text));
      });
      return selectedGroups;
    },
    reset() {
      let columns = this.cloneDeep(this.value);
      if (this.storageKey) {
        columns = this.compareWithOriginalHeaders(this.defaults, columns);
      }
      this.groups = this.returnSelectedGroup(columns);
      this.$emit("input", columns);
    },
  },
  watch: {
    value: {
      handler(val) {
        if (this.storageKey) {
          this.$setToLocal(`${this.storageKey}-filteredColumns`, val, true);
        }
      },
      deep: true,
    },
  },
};
</script>
