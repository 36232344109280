<template>
  <div class="container py-0" v-if="value != null">
    <div>
      <h5
        style="
          margin-bottom: 0.35rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.25rem;
        "
      >
        <!-- <span style="opacity: 0.7"
          ><i class="fad" style="margin-right: 0.25rem" :class="templateType.icon"></i>
          {{ templateType.text }}
        </span> -->
        <span class="secondary--text opacity-72">
          <i class="fad" style="margin-right: 0.25rem" :class="downloadTaskCategory.icon"></i>
          {{ downloadTaskCategory.text }}
        </span>
        <span style="opacity: 0.7">
          <dater :date="value.createDate" no-tooltip></dater>
        </span>
      </h5>
      <h4 style="margin-bottom: 0.35rem">{{ value.fileName }}</h4>
      <h4>
        <span style="opacity: 0.7; margin-right: 0.25rem"> Status: </span>
        <span class="d-flex align-center" style="gap: 0.25rem">
          <i
            class="fad"
            style="margin-right: 0.25rem"
            :style="{ color: status.colorHex }"
            :class="status.icon"
          ></i>
          <u :style="{ color: status.colorHex }">{{ status.text }}</u>
        </span>
      </h4>
    </div>
    <v-btn
      small
      color="#4caf50"
      style="margin-top: 0.5rem; color: #fff"
      :href="value.url"
      :download="value.fileName"
      v-if="value.url != null && value.url != ''"
    >
      <i class="fas fa-arrow-down-to-line" style="margin-right: 0.5rem"></i
      ><span style="font-weight: 600">Download</span>
    </v-btn>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "toast-notification",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  methods: {},
  computed: {
    templateType() {
      if (this.value != null) {
        return this.getEnumMember(Enums.TemplateType, this.value.templateType);
      } else return null;
    },
    downloadTaskCategory() {
      if (this.value != null) {
        return this.getEnumMember(Enums.DownloadTaskCategory, this.value.downloadTaskCategory);
      } else return null;
    },
    status() {
      if (this.value != null) {
        return this.getEnumMember(Enums.DownloadStatus, this.value.status);
      } else return null;
    },
  },
};
</script>
