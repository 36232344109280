<template>
  <div>
    <v-card :disabled="projectId == null" class="report-card" :elevation="3" @click="generate()">
      <div class="report-card-content">
        <div class="report-icon">
          <i :class="enums.REPORT_TYPE.Proposal.icon"></i>
        </div>
        <div class="report-icon-ghost">
          <i :class="enums.REPORT_TYPE.Proposal.icon"></i>
        </div>
        <div class="card-text">
          <v-card-title class="text-left d-block font-weight-bold">{{
            enums.REPORT_TYPE.Proposal.text
          }}</v-card-title>
          <v-card-subtitle class="text-left font-weight-bold">
            {{ enums.REPORT_TYPE.Proposal.fullText }}
          </v-card-subtitle>
        </div>
      </div>
      <v-card-actions v-if="$has(perms.ProjectReports.ViewHistory)">
        <v-tooltip top nudge-top="-4px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.stop="openHistory()">
              <i class="fas fa-history fs-16px"></i>
            </v-btn>
          </template>
          <span>
            Show all my generated {{ enums.REPORT_TYPE.Proposal.text }} reports for this project!
          </span>
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <slideout
      dock="right"
      :size="mainPanelSize"
      :visible.sync="slideout.active"
      @close="onSlideoutClosing"
      class="slideout-deep-purple-header"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold px-1 d-flex align-center" small>
          <i :class="enums.REPORT_TYPE.Proposal.icon" class="fs-24px mb-1 mr-3" style=""></i>
          New <span class="px-1">{{ enums.REPORT_TYPE.Proposal.text }}</span> Report
          <span class="opacity-54 font-weight-medium text-center pl-2">
            "{{ enums.REPORT_TYPE.Proposal.fullText }}"
          </span>
        </h3>
        <div>
          <panel-size-control dark v-model="slideout.fullWidth"></panel-size-control>
          <v-btn @click="close()" dark icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>

      <div color="white" style="position: sticky; top: 0; z-index: 2">
        <v-tabs
          v-model="slideout.tab"
          color="error"
          v-show="slideout.active && !slideout.isGenerating"
          :key="reportSettings.reportSelectionScope"
        >
          <v-tab :key="0" class="d-flex align-center">
            <i class="fad fa-sliders mr-2"></i> Settings
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>

      <v-container fluid class="pa-0 text-center" v-show="slideout.isGenerating">
        <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
          <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">
          Generating <b>{{ enums.REPORT_TYPE.Proposal.text }}</b> Report ...
        </p>
      </v-container>
      <v-container
        v-show="!slideout.isGenerating"
        fluid
        class="px-3 pt-3"
        style="height: 100%; overflow: hidden"
      >
        <v-form v-model="slideout.valid" ref="updateForm">
          <v-tabs-items v-model="slideout.tab" style="overflow: visible !important; height: 100%">
            <v-tab-item :key="0">
              <div class="readonly-border-darker pa-4">
                <h4 class="secondary--text fs-15px">Type</h4>
                <v-container
                  fluid
                  class="d-flex flex-row flex-wrap align-center pa-4 mt-2 inset-shadow"
                  style="
                    gap: 1.25rem;
                    overflow: auto;
                    background: #eceff1;
                    border-radius: 0.5rem;
                    overflow: overlay;
                    height: 100%;
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <v-radio-group
                    v-model="reportSettings.proposalType"
                    mandatory
                    class="ma-0 pa-0 proposal-type-radio-group"
                    row
                    hide-details
                  >
                    <v-sheet class="pl-1 py-1" style="border-radius: 2rem" elevation="2">
                      <v-radio :value="enums.PROPOSAL_TYPE.AV.value" color="info">
                        <template v-slot:label>
                          <div
                            class="font-weight-medium fs-15px"
                            :class="
                              reportSettings.proposalType == enums.PROPOSAL_TYPE.AV.value
                                ? 'info--text'
                                : 'secondary--text opacity-72'
                            "
                          >
                            AV Proposal
                          </div>
                        </template>
                      </v-radio>
                    </v-sheet>

                    <v-sheet class="pl-1 py-1" style="border-radius: 2rem" elevation="2">
                      <v-radio :value="enums.PROPOSAL_TYPE.Telecom.value" color="info">
                        <template v-slot:label>
                          <div
                            class="font-weight-medium fs-15px"
                            :class="
                              reportSettings.proposalType == enums.PROPOSAL_TYPE.Telecom.value
                                ? 'info--text'
                                : 'secondary--text opacity-72'
                            "
                          >
                            Telecom Proposal
                          </div>
                        </template>
                      </v-radio>
                    </v-sheet>

                    <v-sheet class="pl-1 py-1" style="border-radius: 2rem" elevation="2">
                      <v-radio :value="enums.PROPOSAL_TYPE.Meta.value" color="info">
                        <template v-slot:label>
                          <div
                            class="font-weight-medium fs-15px"
                            :class="
                              reportSettings.proposalType == enums.PROPOSAL_TYPE.Meta.value
                                ? 'info--text'
                                : 'secondary--text opacity-72'
                            "
                          >
                            MT.EN Proposal
                          </div>
                        </template>
                      </v-radio>
                    </v-sheet>

                    <v-sheet class="pl-1 py-1" style="border-radius: 2rem" elevation="2">
                      <v-radio :value="enums.PROPOSAL_TYPE.FeeTables.value" color="info">
                        <template v-slot:label>
                          <div
                            class="font-weight-medium fs-15px"
                            :class="
                              reportSettings.proposalType == enums.PROPOSAL_TYPE.FeeTables.value
                                ? 'info--text'
                                : 'secondary--text opacity-72'
                            "
                          >
                            Fee Tables Proposal
                          </div>
                        </template>
                      </v-radio>
                    </v-sheet>

                    <v-sheet class="pl-1 py-1" style="border-radius: 2rem" elevation="2">
                      <v-radio :value="enums.PROPOSAL_TYPE.Combined.value" color="info">
                        <template v-slot:label>
                          <div
                            class="font-weight-medium fs-15px"
                            :class="
                              reportSettings.proposalType == enums.PROPOSAL_TYPE.Combined.value
                                ? 'info--text'
                                : 'secondary--text opacity-72'
                            "
                          >
                            Combined Proposal
                          </div>
                        </template>
                      </v-radio>
                    </v-sheet>
                  </v-radio-group>
                </v-container>
              </div>
              <div class="readonly-border-darker mt-6 pa-4">
                <v-container fluid class="pa-0">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      v-if="
                        reportSettings.projectId != null &&
                        reportSettings.proposalType != enums.PROPOSAL_TYPE.Combined.value
                      "
                    >
                      <project-contract-selector
                        label="<i class='fad fa-file-signature mr-2'></i> Target Contract"
                        :project-id="reportSettings.projectId"
                        required
                        v-model="reportSettings.contractId"
                        ref="projectSpaceSelector"
                        proposals-only
                        hide-details
                      ></project-contract-selector>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      v-if="
                        reportSettings.projectId != null &&
                        reportSettings.proposalType != enums.PROPOSAL_TYPE.Meta.value
                      "
                    >
                      <company-contact-selector
                        label="<i class='fad fa-user mr-2'></i> Addressed Contact"
                        :project-id="reportSettings.projectId"
                        v-model="reportSettings.companyContactId"
                        ref="projectSpaceSelector"
                        hide-details
                      ></company-contact-selector>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      v-if="reportSettings.proposalType == enums.PROPOSAL_TYPE.Meta.value"
                    >
                      <v-text-field-alt
                        :rules="[
                          allRules.noWhiteSpaces,
                          allRules.required,
                          allRules.numberMin(0.01),
                          allRules.number,
                        ]"
                        label="AV Design Fees"
                        placeholder="AV Design Fees"
                        id="proposalFees"
                        v-model="reportSettings.proposalContractFee"
                        dense
                        hide-details
                      >
                      </v-text-field-alt>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      :md="
                        reportSettings.proposalType == enums.PROPOSAL_TYPE.FeeTables.value ? 12 : 4
                      "
                    >
                      <v-menu
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :nudge-top="20"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field-alt
                            :rules="[allRules.required]"
                            prepend-inner-icon="mdi-calendar"
                            id="Proposal-Date"
                            label="Proposal Date"
                            placeholder="Proposal Date"
                            style="cursor: pointer !important"
                            v-model="reportSettings.proposalDate"
                            class="calendar-input"
                            readonly
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field-alt>
                        </template>
                        <v-date-picker
                          color="orange darken-3"
                          header-color="secondary"
                          :first-day-of-week="1"
                          v-model="reportSettings.proposalDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                      v-if="reportSettings.proposalType != enums.PROPOSAL_TYPE.FeeTables.value"
                    >
                      <v-menu
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :nudge-top="20"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field-alt
                            :rules="[allRules.required]"
                            prepend-inner-icon="mdi-calendar"
                            id="Project-kickoff"
                            label="Project Kickoff Date"
                            placeholder="Project Kickoff Date"
                            v-model="reportSettings.projectKickoffDate"
                            style="cursor: pointer !important"
                            class="calendar-input"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            hide-details
                          ></v-text-field-alt>
                        </template>
                        <v-date-picker
                          color="orange darken-3"
                          header-color="secondary"
                          :first-day-of-week="1"
                          v-model="reportSettings.projectKickoffDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                      v-if="reportSettings.proposalType != enums.PROPOSAL_TYPE.FeeTables.value"
                    >
                      <v-menu
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :nudge-top="20"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field-alt
                            :rules="[allRules.required]"
                            prepend-inner-icon="mdi-calendar"
                            id="Project-completion"
                            label="Project Completion Date"
                            placeholder="Project Completion Date"
                            v-model="reportSettings.projectCompletionDate"
                            style="cursor: pointer !important"
                            class="calendar-input"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            hide-details
                          ></v-text-field-alt>
                        </template>
                        <v-date-picker
                          color="orange darken-3"
                          header-color="secondary"
                          :first-day-of-week="1"
                          v-model="reportSettings.projectCompletionDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div
                class="readonly-border-darker mt-6 pa-4"
                v-if="reportSettings.proposalType == enums.PROPOSAL_TYPE.Combined.value"
              >
                <h4 class="secondary--text fs-15px mb-4">Included Combined Proposals</h4>
                <v-container fluid class="pa-0">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-checkbox v-model="reportSettings.combinedProposal.av.checked" class="ma-0">
                        <template v-slot:label>
                          <div
                            class="font-weight-medium fs-15px"
                            :class="
                              reportSettings.combinedProposal.av.checked
                                ? 'info--text'
                                : 'secondary--text opacity-72'
                            "
                          >
                            AV
                          </div>
                        </template>
                      </v-checkbox>
                      <project-contract-selector
                        label="<i class='fad fa-file-signature mr-2'></i> Target AV Proposal"
                        v-if="reportSettings.projectId != null"
                        :project-id="reportSettings.projectId"
                        :required="reportSettings.combinedProposal.av.checked"
                        :readonly="!reportSettings.combinedProposal.av.checked"
                        v-model="reportSettings.combinedProposal.av.contractId"
                        proposals-only
                        hide-details
                      >
                      </project-contract-selector>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-checkbox
                        v-model="reportSettings.combinedProposal.telecom.checked"
                        class="ma-0"
                      >
                        <template v-slot:label>
                          <div
                            class="font-weight-medium fs-15px"
                            :class="
                              reportSettings.combinedProposal.telecom.checked
                                ? 'info--text'
                                : 'secondary--text opacity-72'
                            "
                          >
                            Telecom
                          </div>
                        </template>
                      </v-checkbox>
                      <project-contract-selector
                        label="<i class='fad fa-file-signature mr-2'></i> Target Telecom Proposal"
                        v-if="reportSettings.projectId != null"
                        :project-id="reportSettings.projectId"
                        :required="reportSettings.combinedProposal.telecom.checked"
                        :readonly="!reportSettings.combinedProposal.telecom.checked"
                        v-model="reportSettings.combinedProposal.telecom.contractId"
                        proposals-only
                        hide-details
                      >
                      </project-contract-selector>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-checkbox
                        v-model="reportSettings.combinedProposal.security.checked"
                        class="ma-0"
                      >
                        <template v-slot:label>
                          <div
                            class="font-weight-medium fs-15px"
                            :class="
                              reportSettings.combinedProposal.security.checked
                                ? 'info--text'
                                : 'secondary--text opacity-72'
                            "
                          >
                            Security
                          </div>
                        </template>
                      </v-checkbox>
                      <project-contract-selector
                        label="<i class='fad fa-file-signature mr-2'></i> Target Security Proposal"
                        v-if="reportSettings.projectId != null"
                        :project-id="reportSettings.projectId"
                        :required="reportSettings.combinedProposal.security.checked"
                        :readonly="!reportSettings.combinedProposal.security.checked"
                        v-model="reportSettings.combinedProposal.security.contractId"
                        proposals-only
                        hide-details
                      >
                      </project-contract-selector>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" text> <i class="far fa-xmark mr-2"></i>Cancel</v-btn>
          <v-btn
            color="deep-purple"
            :dark="!slideout.isGenerating && slideout.valid"
            :disabled="slideout.isGenerating || !slideout.valid"
            @click="generateConfirmed()"
            :loading="slideout.isGenerating"
          >
            <i class="fal fa-sync mr-2"></i> Generate
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
  </div>
</template>

<script>
import enums from "../../../../plugins/enums";
import perms from "../../../../plugins/permissions";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";
import reportsService from "../../services/reports-service";
import { eventBus } from "../../../../main";
import ProjectContractSelector from "../../../Contracts/components/ProjectContractSelector.vue";
import CompanyContactSelector from "../CompanyContactSelector.vue";

export default {
  components: { PanelSizeControl, ProjectContractSelector, CompanyContactSelector },
  data() {
    return {
      enums,
      perms,
      installersList: enums.INSTALLERS_LIST,
      selectedSpaces: [],
      selectedLevel: [],
      reportSettings: {
        projectId: null,
        contractId: null,
        companyContactId: null,
        proposalDate: null,
        projectKickOffDate: null,
        projectCompletionDate: null,
        proposalType: enums.PROPOSAL_TYPE.AV.value,
        includeOmittedPhasesNote: false,
        proposalContractFee: null,
        combinedProposal: {
          av: {
            checked: false,
            contractId: null,
            combinedProposalType: enums.COMBINED_PROPOSAL_TYPE.AV.value,
          },
          telecom: {
            checked: false,
            contractId: null,
            combinedProposalType: enums.COMBINED_PROPOSAL_TYPE.Telecom.value,
          },
          security: {
            checked: false,
            contractId: null,
            combinedProposalType: enums.COMBINED_PROPOSAL_TYPE.Security.value,
          },
        },
      },
      slideout: {
        active: false,
        valid: false,
        isGenerating: false,
        fullWidth: false,
        tab: 0,
      },
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "1000px";
    },
  },
  mounted() {
    this.reportSettings.projectId = this.projectId;
  },
  methods: {
    openHistory() {
      this.$emit("history");
    },
    generate() {
      this.reset();
      this.slideout.active = true;
    },
    reset() {
      this.slideout.isGenerating = false;
      this.slideout.tab = 0;
      this.slideout.valid = false;
      this.selectedSpaces = [];
      this.reportSettings = {
        projectId: this.projectId,
        contractId: null,
        companyContactId: null,
        proposalDate: null,
        projectKickOffDate: null,
        projectCompletionDate: null,
        proposalType: enums.PROPOSAL_TYPE.AV.value,
        includeOmittedPhasesNote: false,
        proposalContractFee: null,
        combinedProposal: {
          av: {
            checked: false,
            contractId: null,
            combinedProposalType: enums.COMBINED_PROPOSAL_TYPE.AV.value,
          },
          telecom: {
            checked: false,
            contractId: null,
            combinedProposalType: enums.COMBINED_PROPOSAL_TYPE.Telecom.value,
          },
          security: {
            checked: false,
            contractId: null,
            combinedProposalType: enums.COMBINED_PROPOSAL_TYPE.Security.value,
          },
        },
      };
    },
    cancel() {
      this.reset();
      this.slideout.active = false;
    },
    close() {
      this.reset();
      this.slideout.active = false;
    },
    generateConfirmed() {
      this.slideout.isGenerating = true;
      var toSend = this.cloneDeep(this.reportSettings);
      if (!toSend.combinedProposal.av.checked) toSend.combinedProposal.av = null;
      if (!toSend.combinedProposal.telecom.checked) toSend.combinedProposal.telecom = null;
      if (!toSend.combinedProposal.security.checked) toSend.combinedProposal.security = null;
      this.$log("report generateConfirmed", toSend);
      reportsService.ContractProposal(toSend).then(this.onResponse).catch(this.onCatch);
    },
    onResponse(resp) {
      this.$log("report generate resp.data", resp.data);
      this.$notify(resp.data, "info", resp.data.id, true);
      eventBus.$emit("add-to-download-center", resp.data);
      this.close();
    },
    onCatch(err) {
      this.$log(">>> err - reportsService Proposal", err);
      this.slideout.isGenerating = false;
      this.$handleError(err);
    },
    onSlideoutClosing() {},
  },
  watch: {
    reportSettings: {
      handler(newSettings, oldSettings) {
        this.$log(
          "reportSettings",
          this.isEqual(this.cloneDeep(newSettings), this.cloneDeep(oldSettings))
        );
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.cloneDeep(newSettings), this.cloneDeep(oldSettings))
        );
      },
      deep: true,
    },
    "reportSettings.combinedProposal.av.checked": {
      handler(newSettings, oldSettings) {
        if (!this.reportSettings.combinedProposal.av.checked)
          this.reportSettings.combinedProposal.av.contractId = null;
      },
    },
    "reportSettings.combinedProposal.telecom.checked": {
      handler(newSettings, oldSettings) {
        if (!this.reportSettings.combinedProposal.telecom.checked)
          this.reportSettings.combinedProposal.telecom.contractId = null;
      },
    },
    "reportSettings.combinedProposal.security.checked": {
      handler(newSettings, oldSettings) {
        if (!this.reportSettings.combinedProposal.security.checked)
          this.reportSettings.combinedProposal.security.contractId = null;
      },
    },
  },
};
</script>
<style lang="scss">
.proposal-type-radio-group {
  .v-input--radio-group__input {
    display: flex;
    gap: 1rem;
  }
}
</style>
