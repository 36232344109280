<template>
  <div>
    <v-card :disabled="projectId == null" class="report-card" :elevation="3" @click="generate()">
      <div class="report-card-content">
        <div class="report-icon">
          <i :class="enums.REPORT_TYPE.DrawingSheetList.icon"></i>
        </div>
        <div class="report-icon-ghost">
          <i :class="enums.REPORT_TYPE.DrawingSheetList.icon"></i>
        </div>
        <div class="card-text">
          <v-card-title class="text-left d-block font-weight-bold">{{
            enums.REPORT_TYPE.DrawingSheetList.text
          }}</v-card-title>
          <v-card-subtitle class="text-left font-weight-bold">
            {{ enums.REPORT_TYPE.DrawingSheetList.fullText }}
          </v-card-subtitle>
        </div>
      </div>
      <v-card-actions v-if="$has(perms.ProjectReports.ViewHistory)">
        <v-tooltip top nudge-top="-4px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.stop="openHistory()">
              <i class="fas fa-history fs-16px"></i>
            </v-btn>
          </template>
          <span>
            Show all my generated {{ enums.REPORT_TYPE.DrawingSheetList.text }} reports for this
            project!
          </span>
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <slideout
      dock="right"
      :size="mainPanelSize"
      :visible.sync="slideout.active"
      @close="onSlideoutClosing"
      class="slideout-deep-purple-header"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold px-1 d-flex align-center" small>
          <i
            :class="enums.REPORT_TYPE.DrawingSheetList.icon"
            class="fs-24px mb-1 mr-3"
            style=""
          ></i>
          New <span class="px-1">{{ enums.REPORT_TYPE.DrawingSheetList.text }}</span> Report
          <span class="opacity-54 font-weight-medium text-center pl-2">
            "{{ enums.REPORT_TYPE.DrawingSheetList.fullText }}"
          </span>
        </h3>
        <div>
          <panel-size-control dark v-model="slideout.fullWidth"></panel-size-control>
          <v-btn @click="close()" dark icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>

      <div color="white" style="position: sticky; top: 0; z-index: 2">
        <v-tabs
          v-model="slideout.tab"
          color="error"
          v-show="slideout.active && !slideout.isGenerating"
          :key="reportSettings.associateType"
        >
          <v-tab :key="0" class="d-flex align-center">
            <i class="fad fa-sliders mr-2"></i> Settings
          </v-tab>
          <v-tab :key="1" class="d-flex align-center">
            <i class="fad fa-bullseye-arrow mr-2"></i> Scope
            <span class="opacity-64 fs-12px ml-1" v-if="reportSettings.associateType == null">
              (All Sheets)
            </span>
            <span
              class="opacity-64 fs-12px ml-1"
              v-else-if="reportSettings.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value"
            >
              (Not Associated)
            </span>
            <span
              class="opacity-64 fs-12px ml-1"
              v-else-if="reportSettings.associateType == enums.ASSOCIATE_TYPE.Space.value"
            >
              (Space)
            </span>
            <span
              class="opacity-64 fs-12px ml-1"
              v-else-if="reportSettings.associateType == enums.ASSOCIATE_TYPE.Level.value"
            >
              (Level)
            </span>
            <span
              class="opacity-64 fs-12px ml-1"
              v-else-if="reportSettings.associateType == enums.ASSOCIATE_TYPE.Building.value"
            >
              (Building)
            </span>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>

      <v-container fluid class="pa-0 text-center" v-show="slideout.isGenerating">
        <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
          <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">
          Generating <b>{{ enums.REPORT_TYPE.DrawingSheetList.text }}</b> Report ...
        </p>
      </v-container>
      <v-container
        v-show="!slideout.isGenerating"
        fluid
        class="px-3 pt-3"
        style="height: 100%; overflow: hidden"
      >
        <v-form v-model="slideout.valid" ref="updateForm">
          <v-tabs-items v-model="slideout.tab" style="overflow: visible !important; height: 100%">
            <v-tab-item :key="0">
              <div class="readonly-border-darker pa-4">
                <h4 class="secondary--text fs-15px">Target</h4>
                <v-radio-group
                  v-model="reportSettings.isExternal"
                  :disabled="slideout.isGenerating"
                  mandatory
                  class="mt-1"
                  row
                  hide-details
                >
                  <v-radio :value="false" color="deep-purple">
                    <template v-slot:label>
                      <div
                        class="v-switch-label d-block"
                        :class="
                          !reportSettings.isExternal
                            ? 'deep-purple--text text--darken-2'
                            : 'secondary--text opacity-72'
                        "
                      >
                        Internal
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="true" color="deep-purple">
                    <template v-slot:label>
                      <div
                        class="v-switch-label d-block"
                        :class="
                          reportSettings.isExternal
                            ? 'deep-purple--text text--darken-2'
                            : 'secondary--text opacity-72'
                        "
                      >
                        External <span class="opacity-54">(For a Client)</span>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div class="readonly-border-darker mt-6 pa-4">
                <h4 class="secondary--text fs-15px">Included List Sheet</h4>
                <v-radio-group
                  v-model="reportSettings.includedListSheet"
                  :disabled="slideout.isGenerating"
                  mandatory
                  class="mt-1"
                  row
                  hide-details
                >
                  <v-radio
                    :value="enums.ReportIncludedListSheet.SpaceListSheet"
                    color="deep-purple"
                  >
                    <template v-slot:label>
                      <div
                        class="v-switch-label d-block"
                        :class="
                          reportSettings.includedListSheet ==
                          enums.ReportIncludedListSheet.SpaceListSheet
                            ? 'deep-purple--text text--darken-2'
                            : 'secondary--text opacity-72'
                        "
                      >
                        Space List Sheet
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    :value="enums.ReportIncludedListSheet.AreaGroupsListSheet"
                    color="deep-purple"
                  >
                    <template v-slot:label>
                      <div
                        class="v-switch-label d-block"
                        :class="
                          reportSettings.includedListSheet ==
                          enums.ReportIncludedListSheet.AreaGroupsListSheet
                            ? 'deep-purple--text text--darken-2'
                            : 'secondary--text opacity-72'
                        "
                      >
                        Area Groups List Sheet
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-tab-item>
            <v-tab-item
              :key="1"
              style="height: 100%; overflow: hidden; display: flex; flex-direction: column"
            >
              <h4 class="secondary--text">Export a Drawing Set Associated with:</h4>
              <v-radio-group
                v-model="reportSettings.associateType"
                :disabled="slideout.isGenerating"
                mandatory
                class="mt-1"
                row
                hide-details
              >
                <v-radio :value="null" color="deep-purple">
                  <template v-slot:label>
                    <div
                      class="font-weight-medium fs-15px"
                      :class="
                        reportSettings.associateType == null
                          ? 'deep-purple--text'
                          : 'secondary--text opacity-72'
                      "
                    >
                      All Drawing Documents
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="enums.ASSOCIATE_TYPE.NotAssociated.value" color="deep-purple">
                  <template v-slot:label>
                    <div
                      class="font-weight-medium fs-15px"
                      :class="
                        reportSettings.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value
                          ? 'deep-purple--text'
                          : 'secondary--text opacity-72'
                      "
                    >
                      Not Associated
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="enums.ASSOCIATE_TYPE.Space.value" color="deep-purple">
                  <template v-slot:label>
                    <div
                      class="font-weight-medium fs-15px"
                      :class="
                        reportSettings.associateType == enums.ASSOCIATE_TYPE.Space.value
                          ? 'deep-purple--text'
                          : 'secondary--text opacity-72'
                      "
                    >
                      Space
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="enums.ASSOCIATE_TYPE.Level.value" color="deep-purple">
                  <template v-slot:label>
                    <div
                      class="font-weight-medium fs-15px"
                      :class="
                        reportSettings.associateType == enums.ASSOCIATE_TYPE.Level.value
                          ? 'deep-purple--text'
                          : 'secondary--text opacity-72'
                      "
                    >
                      Level
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="enums.ASSOCIATE_TYPE.Building.value" color="deep-purple">
                  <template v-slot:label>
                    <div
                      class="font-weight-medium fs-15px"
                      :class="
                        reportSettings.associateType == enums.ASSOCIATE_TYPE.Building.value
                          ? 'deep-purple--text'
                          : 'secondary--text opacity-72'
                      "
                    >
                      Building
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <div
                class="pa-5 mt-3 inset-shadow"
                style="
                  overflow: auto;
                  overflow: overlay;
                  overflow: hidden;
                  background: #eceff1;
                  border-radius: 0.5rem;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                "
              >
                <v-row dense justify="center">
                  <v-col cols="12" md="7">
                    <v-scroll-y-transition mode="out-in">
                      <p
                        v-if="reportSettings.associateType == null"
                        key="ASSOCIATE_TYPE.All"
                        class="text-center d-flex flex-column justify-content-center align-center"
                      >
                        <img width="350" src="/img/art/_clip-science.png" />
                        <span class="font-weight-bold fs-16px">
                          All project drawing documents will be included in this report!
                        </span>
                      </p>
                      <p
                        v-else-if="
                          reportSettings.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value
                        "
                        key="ASSOCIATE_TYPE.NotAssociated"
                        class="text-center pa-4 d-flex flex-column justify-content-center align-center"
                      >
                        <i class="fad fa-link-slash mb-3" style="font-size: 4rem"></i>
                        <span class="font-weight-bold fs-16px">
                          Only non associated drawing<br />documents will be included in this
                          report!
                        </span>
                      </p>
                      <div
                        key="ASSOCIATE_TYPE.Space"
                        v-else-if="reportSettings.associateType == enums.ASSOCIATE_TYPE.Space.value"
                      >
                        <div class="text-center mt-6">
                          <i class="fad fa-table-pivot mb-3" style="font-size: 4rem"></i>
                        </div>
                        <p class="font-weight-bold fs-16px text-center mb-8">
                          Only drawing set documents for the<br />selected space will be included in
                          this report!
                        </p>
                        <project-space-selector
                          :label="`<i class='fad fa-table-pivot mr-1'></i> Associated Space`"
                          :project-id="projectId"
                          v-model="reportSettings.associateTypeId"
                          ref="projectSpaceSelector"
                        ></project-space-selector>
                      </div>
                      <div
                        key="ASSOCIATE_TYPE.Level"
                        v-else-if="reportSettings.associateType == enums.ASSOCIATE_TYPE.Level.value"
                      >
                        <div class="text-center mt-6">
                          <i class="fad fa-layer-group mb-3" style="font-size: 4rem"></i>
                        </div>
                        <p class="font-weight-bold fs-16px text-center mb-12">
                          Only drawing set documents for the<br />selected level will be included in
                          this report!
                        </p>
                        <project-level-selector
                          :label="`<i class='fad fa-layer-group mr-1'></i> Associated Level`"
                          :project-id="projectId"
                          v-model="reportSettings.associateTypeId"
                          ref="projectLevelSelector"
                        ></project-level-selector>
                      </div>
                      <div
                        key="ASSOCIATE_TYPE.Building"
                        v-else-if="
                          reportSettings.associateType == enums.ASSOCIATE_TYPE.Building.value
                        "
                      >
                        <div class="text-center mt-6">
                          <i class="fad fa-building mb-3" style="font-size: 4rem"></i>
                        </div>
                        <p class="font-weight-bold fs-16px text-center mb-12">
                          Only drawing set documents for the<br />selected building will be included
                          in this report!
                        </p>
                        <project-building-selector
                          :label="`<i class='fad fa-building mr-1'></i> Associated Building`"
                          :project-id="projectId"
                          v-model="reportSettings.associateTypeId"
                          ref="projectBuildingSelector"
                        ></project-building-selector>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" text> <i class="far fa-xmark mr-2"></i>Cancel</v-btn>
          <v-btn
            color="deep-purple"
            :dark="!slideout.isGenerating && slideout.valid"
            :disabled="slideout.isGenerating || !slideout.valid"
            @click="generateConfirmed()"
            :loading="slideout.isGenerating"
          >
            <i class="fal fa-sync mr-2"></i> Generate
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
  </div>
</template>

<script>
import enums from "../../../../plugins/enums";
import perms from "../../../../plugins/permissions";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";
import reportsService from "../../services/reports-service";
import { eventBus } from "../../../../main";
import ProjectSpaceSelector from "../../../Shared/components/ProjectSpaceSelector.vue";
import ProjectBuildingSelector from "../../../Shared/components/ProjectBuildingSelector.vue";
import ProjectLevelSelector from "../../../Shared/components/ProjectLevelSelector.vue";

export default {
  components: {
    PanelSizeControl,
    ProjectSpaceSelector,
    ProjectBuildingSelector,
    ProjectLevelSelector,
  },
  data() {
    return {
      enums,
      perms,
      selectedSpaces: [],
      selectedLevel: [],
      reportSettings: {
        projectId: null,
        isExternal: false,
        associateType: null,
        associateTypeId: null,
        includedListSheet: enums.ReportIncludedListSheet.SpaceListSheet,
      },
      slideout: {
        active: false,
        valid: true,
        isGenerating: false,
        fullWidth: false,
        tab: 0,
      },
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valid() {
      return true;
    },
    mainPanelSize() {
      return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "1000px";
    },
  },
  methods: {
    openHistory() {
      this.$emit("history");
    },
    generate() {
      this.reset();
      this.slideout.active = true;
    },
    reset() {
      this.slideout.isGenerating = false;
      this.slideout.tab = 0;
      this.reportSettings = {
        projectId: this.projectId,
        isExternal: false,
        associateType: null,
        associateTypeId: null,
        includedListSheet: enums.ReportIncludedListSheet.SpaceListSheet,
      };
    },
    cancel() {
      this.reset();
      this.slideout.active = false;
    },
    close() {
      this.reset();
      this.slideout.active = false;
    },
    generateConfirmed() {
      this.slideout.isGenerating = true;
      var toSend = this.cloneDeep(this.reportSettings);
      this.$log("report generateConfirmed", toSend);
      reportsService.DrawingSheetList(toSend).then(this.onResponse).catch(this.onCatch);
    },
    onResponse(resp) {
      this.$log("report generate resp.data", resp.data);
      this.$notify(resp.data, "info", resp.data.id, true);
      eventBus.$emit("add-to-download-center", resp.data);
      this.close();
    },
    onCatch(err) {
      this.$log(">>> err - reportsService DrawingSheetList", err);
      this.slideout.isGenerating = false;
      this.$handleError(err);
    },
    onSlideoutClosing() {},
  },
  watch: {
    reportSettings: {
      handler(newSettings, oldSettings) {
        this.$log(
          "reportSettings",
          this.isEqual(this.cloneDeep(newSettings), this.cloneDeep(oldSettings))
        );
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.cloneDeep(newSettings), this.cloneDeep(oldSettings))
        );
      },
      deep: true,
    },
    "reportSettings.associateType": {
      handler(newVal) {
        this.reportSettings.associateTypeId = null;
      },
    },
  },
};
</script>

<style lang="scss"></style>
