<template>
  <v-container fluid class="pa-3">
    <h3 class="text--primary font-weight-black mb-3">Project Timeline</h3>
    <div class="d-flex justify-center align-center mt-3" v-if="loading">
      <v-progress-circular indeterminate color="info" :width="2"></v-progress-circular>
    </div>
    <v-form v-model="valid" ref="projectTimelineForm" v-else>
      <template v-for="stageItem in dates">
        <div class="stage-wrapper" :class="stageItem.key" :key="stageItem.key">
          <v-row align="end">
            <v-col cols="12" class="pb-0" :key="`timeline-divider${stageItem.key}`">
              <div class="d-flex justify-space-between align-center mb-2">
                <div class="d-flex flex-column">
                  <h3 class="stage-title font-weight-black mr-3 d-inline-block">
                    {{ stageItem.title }}
                  </h3>
                  <h5 class="deep-orange--text" v-if="!stageItem.isCompleteDateAfterStartDate()">
                    <u>Warning</u>: Construction complete date should be after both start dates!
                  </h5>
                </div>
                <transition
                  name="fade"
                  mode="out-in"
                  appear
                  v-if="stageItem.projectStage == projectStage && canEdit"
                >
                  <div key="project-info-vars-btns" class="d-flex" style="gap: 0.5rem">
                    <v-btn @click="discard()" :disabled="!hasChanges || loading || saveLoading">
                      <i class="fal fa-redo mr-2"></i>Discard
                    </v-btn>
                    <v-btn
                      color="info"
                      @click="saveTimeline()"
                      :loading="saveLoading"
                      :disabled="!valid || loading || saveLoading || !hasChanges"
                    >
                      <i class="fal fa-check mr-2"></i>Save Timeline
                    </v-btn>
                  </div>
                </transition>
              </div>
              <v-divider></v-divider>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="input in stageItem.timeline"
              :key="input.key + stageItem.key"
            >
              <v-menu
                :key="input.key + stageItem.key"
                v-model="input.menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="
                  input.projectStage != projectStage || projectStage != initialStage || !canEdit
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field-alt
                    v-model="selected[stageItem.key][input.key]"
                    :label="input.label"
                    :placeholder="input.label"
                    :class="{
                      'calendar-input':
                        input.projectStage == projectStage &&
                        projectStage == initialStage &&
                        canEdit,
                    }"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    :id="input.key"
                    :ref="input.key"
                  >
                    <!-- <template #prepend-inner><i class="far fa-calendar-edit mr-1"></i></template> -->
                  </v-text-field-alt>
                </template>
                <v-date-picker
                  color="orange darken-3"
                  header-color="secondary"
                  :first-day-of-week="1"
                  :allowed-dates="input.allowedDates"
                  v-model="selected[stageItem.key][input.key]"
                  @input="input.menu = false"
                  show-current
                  show-week
                  show-adjacent-months
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </template>

      <project-timeline-chart :selected="selected"></project-timeline-chart>
      <v-divider class="my-10"></v-divider>
      <project-timeline-chart-range :selected="selected"></project-timeline-chart-range>
    </v-form>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import projectsAPI from "../services/projects-service";
import ProjectTimelineChart from "./ProjectTimelineChart.vue";
import ProjectTimelineChartRange from "./ProjectTimelineChartRange.vue";

export default {
  components: { ProjectTimelineChart, ProjectTimelineChartRange },
  name: "project-timeline",
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    projectStage: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      hasChanges: false,
      loading: false,
      saveLoading: false,
      initialStage: 1,
      selected: {
        opportunityTimeline: {
          potentialStartDate: null,
          potentialConstructionStartDate: null,
          potentialConstructionCompleteDate: null,
        },
        leadTimeline: {
          potentialStartDate: null,
          potentialConstructionStartDate: null,
          potentialConstructionCompleteDate: null,
        },
        activeTimeline: {
          potentialStartDate: null,
          potentialConstructionStartDate: null,
          potentialConstructionCompleteDate: null,
        },
      },
      selectedCemented: {},
      dates: {
        lead: {
          key: "leadTimeline",
          title: "Lead Timeline",
          projectStage: 0,
          timeline: [
            {
              projectStage: 0,
              menu: false,
              label: "Potential Start Date",
              key: "potentialStartDate",
              allowedDates: (v) => this.dateGreater(v, new Date(), true),
            },
            {
              projectStage: 0,
              menu: false,
              label: "Potential Construction Start Date",
              key: "potentialConstructionStartDate",
              allowedDates: (v) =>
                this.dateGreater(v, this.selected.leadTimeline.potentialStartDate || new Date()),
            },
            {
              projectStage: 0,
              menu: false,
              label: "Potential Construction Complete Date",
              key: "potentialConstructionCompleteDate",
              allowedDates: (v) =>
                this.dateGreater(
                  v,
                  this.selected.leadTimeline.potentialConstructionStartDate || new Date()
                ),
            },
          ],
          isCompleteDateAfterStartDate: () => {
            if (
              this.selected.leadTimeline.potentialConstructionCompleteDate != null &&
              this.selected.leadTimeline.potentialConstructionCompleteDate != ""
            ) {
              return (
                this.dateGreater(
                  this.selected.leadTimeline.potentialConstructionCompleteDate,
                  this.selected.leadTimeline.potentialStartDate
                ) &&
                this.dateGreater(
                  this.selected.leadTimeline.potentialConstructionCompleteDate,
                  this.selected.leadTimeline.potentialConstructionStartDate
                )
              );
            }
            return true;
          },
        },
        opportunity: {
          key: "opportunityTimeline",
          title: "Opportunity Timeline",
          projectStage: 1,
          timeline: [
            {
              menu: false,
              projectStage: 1,
              label: "Start Date",
              key: "potentialStartDate",
              allowedDates: (v) => this.dateGreater(v, new Date(), true),
            },
            {
              menu: false,
              projectStage: 1,
              label: "Construction Start Date",
              key: "potentialConstructionStartDate",
              allowedDates: (v) =>
                this.dateGreater(
                  v,
                  this.selected.opportunityTimeline.potentialStartDate || new Date()
                ),
            },
            {
              menu: false,
              projectStage: 1,
              label: "Construction Complete Date",
              key: "potentialConstructionCompleteDate",
              allowedDates: (v) =>
                this.dateGreater(
                  v,
                  this.selected.opportunityTimeline.potentialConstructionStartDate || new Date()
                ),
            },
          ],
          isCompleteDateAfterStartDate: () => {
            if (
              this.selected.opportunityTimeline.potentialConstructionCompleteDate != null &&
              this.selected.opportunityTimeline.potentialConstructionCompleteDate != ""
            ) {
              return (
                this.dateGreater(
                  this.selected.opportunityTimeline.potentialConstructionCompleteDate,
                  this.selected.opportunityTimeline.potentialStartDate
                ) &&
                this.dateGreater(
                  this.selected.opportunityTimeline.potentialConstructionCompleteDate,
                  this.selected.opportunityTimeline.potentialConstructionStartDate
                )
              );
            }
            return true;
          },
        },
        active: {
          key: "activeTimeline",
          title: "Active Timeline",
          projectStage: 2,
          timeline: [
            {
              menu: false,
              projectStage: 2,
              label: "Start Date",
              key: "potentialStartDate",
              allowedDates: (v) => this.dateGreater(v, new Date(), true),
            },
            {
              menu: false,
              projectStage: 2,
              label: "Construction Start Date",
              key: "potentialConstructionStartDate",
              allowedDates: (v) =>
                this.dateGreater(v, this.selected.activeTimeline.potentialStartDate || new Date()),
            },
            {
              menu: false,
              projectStage: 2,
              label: "Construction Complete Date",
              key: "potentialConstructionCompleteDate",
              allowedDates: (v) =>
                this.dateGreater(
                  v,
                  this.selected.activeTimeline.potentialConstructionStartDate || new Date()
                ),
            },
          ],
          isCompleteDateAfterStartDate: () => {
            if (
              this.selected.activeTimeline.potentialConstructionCompleteDate != null &&
              this.selected.activeTimeline.potentialConstructionCompleteDate != ""
            ) {
              return (
                this.dateGreater(
                  this.selected.activeTimeline.potentialConstructionCompleteDate,
                  this.selected.activeTimeline.potentialStartDate
                ) &&
                this.dateGreater(
                  this.selected.activeTimeline.potentialConstructionCompleteDate,
                  this.selected.activeTimeline.potentialConstructionStartDate
                )
              );
            }
            return true;
          },
        },
      },
    };
  },
  created() {
    this.initialStage = this.projectStage;
    this.getProjectTimeline();
  },
  methods: {
    getProjectTimeline() {
      this.loading = true;
      projectsAPI
        .getProjectItem(this.projectId, "Timeline")
        .then((resp) => {
          this.$log("getProjectItem Timeline:", resp.data);
          this.setSelected(resp.data);
          this.cementTimeline();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSelected() {
      let selected = null;
      switch (this.projectStage) {
        case 1:
          selected = this.selected.opportunityTimeline;
          break;
        case 2:
          selected = this.selected.activeTimeline;
          break;
        default:
          selected = null;
          break;
      }
      return selected;
    },
    saveTimeline() {
      const dataToSend = this.getSelected();
      if (dataToSend) {
        this.saveLoading = true;
        projectsAPI
          .saveProjectItem(this.projectId, "Timeline", dataToSend)
          .then((resp) => {
            this.setSelected(resp.data);
            this.cementTimeline();
            this.$dialog.notify.success("Timeline updated!", {
              position: "top-right",
              timeout: 3000,
            });
          })
          .catch((err) => {
            this.$handleError(err);
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    },
    checkChanges() {
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    cementTimeline() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkChanges();
    },
    discard() {
      this.selected = this.cloneDeep(this.selectedCemented);
      this.checkChanges();
    },
    dateGreater(great, less, checkEqual = false) {
      const res =
        new Date(great) >= (less == null || less == "" ? new Date(great) : new Date(less));
      if (checkEqual) {
        return res || new Date(great).toDateString() == new Date(less).toDateString();
      }
      return res;
    },
    setSelected(data) {
      Object.values(data).forEach((elm) =>
        Object.keys(elm).forEach((item) => {
          if (elm[item]) elm[item] = this.formatDate(new Date(elm[item]));
        })
      );
      this.selected = data;
    },
  },
  watch: {
    selected: {
      handler() {
        Object.keys(this.dates).forEach((item) => {
          this.$log("item", item);
          return;
          if (
            this.dateGreater(
              this.selected[item].potentialStartDate,
              this.selected[item].potentialConstructionStartDate
            )
          ) {
            this.selected[item].potentialConstructionStartDate = null;
          }
          if (
            this.dateGreater(
              this.selected[item].potentialConstructionStartDate,
              this.selected[item].potentialConstructionCompleteDate
            ) ||
            this.dateGreater(
              this.selected[item].potentialStartDate,
              this.selected[item].potentialConstructionCompleteDate
            )
          ) {
            this.selected[item].potentialConstructionCompleteDate = null;
          }
        });

        this.checkChanges();
      },
      deep: true,
    },
    hasChanges() {
      this.$emit("has-changes", this.hasChanges);
    },
    valid() {
      this.$emit("valid-changes", this.valid);
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.ProjectTimeline.Update);
    },
  },
};
</script>
<style lang="scss">
.stage-wrapper {
  border: 1px dashed rgba($shades-black, 0.4);
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  &.leadTimeline {
    background: rgba($info-base, 0.02);
    border-color: $info-base;

    .stage-title {
      color: $info-base;
      background: rgba($info-base, 0.1);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem;
    }
  }
  &.opportunityTimeline {
    background: rgba($orange-base, 0.02);
    border-color: $orange-base;

    .stage-title {
      color: $orange-base;
      background: rgba($orange-base, 0.1);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem;
    }
  }
  &.activeTimeline {
    background: rgba($success-base, 0.02);
    border-color: $success-base;

    .stage-title {
      color: $success-base;
      background: rgba($success-base, 0.1);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem;
    }
  }
}
</style>
