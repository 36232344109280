<template>
  <div class="alt-comp-wrapper project-space-area-group-selector">
    <v-autocomplete-alt
      auto-select-first
      :rules="[allRules.required]"
      :label="
        label == null || label == '' ? `<i class='fad fa-shapes mr-2'></i> Area Group` : label
      "
      id="spaceAreaGroupSelector"
      placeholder="Select a Space Area Group"
      :value="value"
      @input="handleSelectedSpaceAreaGroup"
      :items="areaGroups"
      :filter="spaceAreaGroupsFilter"
      dense
      filled
      item-value="id"
      item-text="name"
      clearable
      :hide-details="hideDetails"
      :loading="isSpaceAreaGroupsLoading"
      :disabled="isSpaceAreaGroupsLoading"
      :readonly="readonly"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <div
          :key="item.id + '_spaceAreaGroupSelector'"
          style="width: 100%"
          class="d-flex align-center justify-space-between py-2"
        >
          <div class="d-flex flex-column" style="gap: 0.25rem">
            <h4 class="ma-0 fs-14px">
              <i class="fad fa-shapes fs-13px mr-2"></i>{{ data.item.name }}
            </h4>
            <div class="d-flex align-center" style="gap: 1rem">
              <div class="d-flex align-center fs-13px" style="gap: 0.35rem">
                <i class="fas fa-plug"></i>{{ data.item.equipmentCount }}
              </div>
              <div class="d-flex align-center fs-13px" style="gap: 0.35rem">
                <i class="fad fa-calendar-day"></i>
                <dater :date="data.item.createDate" date-only></dater>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column" style="gap: 0.15rem; min-width: 154px">
            <span class="opacity-54 font-weight-bold fs-12px">Created By</span>
            <user-avatar
              mini
              hide-roles
              hide-labor
              disable-open-link
              :user="data.item.createdBy"
            ></user-avatar>
          </div>
        </div>
      </template>
    </v-autocomplete-alt>
  </div>
</template>

<script>
import spaceService from "../../Spaces/services/spaces-service";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    spaceId: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { areaGroups: [], isSpaceAreaGroupsLoading: false };
  },
  methods: {
    handleSelectedSpaceAreaGroup(val) {
      this.$emit("input", val);
      let areaGroupObj = this.areaGroups.find((s) => s.id == val);
      this.$emit("change", areaGroupObj);
    },
    getSpacesAreaGroups() {
      if (this.spaceId == null) return;
      this.isSpaceAreaGroupsLoading = true;
      spaceService
        .getAreaGroupsBySpaceId(this.spaceId)
        .then((resp) => {
          this.areaGroups = resp.data;
          this.$log("areaGroups", resp.data.areaGroups);
          this.isSpaceAreaGroupsLoading = false;
        })
        .catch((err) => {
          this.isSpaceAreaGroupsLoading = false;
          this.$handleError(err);
        });
    },
    spaceAreaGroupsFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const text2 = item.number == null ? "" : item.number.toLowerCase();
      const text3 = item.description == null ? "" : item.description.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1
      );
    },
    refresh() {
      this.getSpacesAreaGroups();
    },
  },
  created() {
    this.getSpacesAreaGroups();
  },
  watch: {
    spaceId: {
      handler() {
        this.getSpacesAreaGroups();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.alt-comp-wrapper.project-space-area-group-selector {
  .v-autocomplete-alt {
    // width: calc(100% - 40px);
    // flex: 1 1 auto;
    // overflow: hidden;

    .v-input,
    .v-input__slot {
      height: 54px !important;
    }
  }
}

.typeahead-space-name {
  max-width: calc(100% - 40px) !important;
}

.typeahead-space-description {
  max-width: calc(100% - 40px) !important;
}

.space-number {
  white-space: nowrap;
  font-size: 13px;
  color: rgba($shades-black, 0.54);

  i {
    font-size: 11px;
  }
}
</style>
