<template>
  <v-container fluid class="px-3 py-4 d-flex flex-column" style="height: 100%; overflow: hidden">
    <h2 class="text--primary font-weight-black mb-2">WireCAD Integration</h2>
    <v-card style="flex: 1 1 auto; display: flex; flex-direction: column">
      <div ref="tabsContainer" style="position: sticky; top: 0; z-index: 1" @wheel="wheelIt">
        <v-tabs color="error" v-model="tab" show-arrows>
          <v-tab v-for="(item, i) in authorizedTabs" :key="i">
            <v-fab-transition mode="out-in">
              <span class="tab-required-icon" v-if="!item.valid">
                <i class="fas fa-star-of-life pink--text"></i>
              </span>
            </v-fab-transition>
            <i class="fad mr-2" :class="item.icon"></i>
            {{ item.title }}
            <v-fab-transition mode="out-in">
              <span class="tab-changes-icon" v-if="item.hasChange">
                <i class="fas fa-save info--text fa-beat"></i>
              </span>
            </v-fab-transition>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>
      <v-tabs-items v-model="tab" style="height: 100%; overflow: hidden" class="d-flex flex-column">
        <v-tab-item
          v-for="(item, i) in authorizedTabs"
          :key="i"
          :class="`${item.class}-tab`"
          style="height: 100%; overflow: hidden"
          class="flex-column"
        >
          <keep-alive>
            <wire-cad-manufacturer-requests
              v-if="tab == i && item.key == getTab(tabs.manufacturers.id).key"
              @has-changes="tabHasChanged($event, 'manufacturers')"
              @valid-changes="tabValidityChanged($event, 'manufacturers')"
            ></wire-cad-manufacturer-requests>
            <wire-cad-equipment-requests
              v-else-if="tab == i && item.key == getTab(tabs.equipment.id).key"
              @has-changes="tabHasChanged($event, 'equipment')"
              @valid-changes="tabValidityChanged($event, 'equipment')"
            ></wire-cad-equipment-requests>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import perms from "../../../../../plugins/permissions";
import enums from "../../../../../plugins/enums";
import WireCadManufacturerRequests from "../../Manufacturers/views/WireCadManufacturerRequests.vue";
import WireCadEquipmentRequests from "../../Equipment/views/WireCadEquipmentRequests.vue";

export default {
  name: "wire-cad",
  components: { WireCadManufacturerRequests, WireCadEquipmentRequests },
  data() {
    return {
      perms: perms,
      enums: enums,
      tab: 0,
      tabs: {
        manufacturers: {
          title: "Manufacturers",
          id: "manufacturers",
          class: "wire-cad-manufacturers",
          icon: "fa-industry-windows",
          valid: true,
          hasChange: false,
          key: 0,
        },
        equipment: {
          title: "Equipment",
          id: "equipment",
          class: "wire-cad-equipment",
          icon: "fa-plug",
          valid: true,
          hasChange: false,
          key: 1,
        },
      },
    };
  },
  props: {},
  computed: {
    authorizedTabs() {
      // var authTabs = Object.values(this.tabs).filter((tab) => tab.authorized);
      var authTabs = Object.values(this.tabs);
      for (let i = 0; i < authTabs.length; i++) {
        authTabs[i].key = i;
      }
      return authTabs;
    },
    activeTabClass() {
      // var active = Object.values(this.tabs).find((t) => t.key == this.tab);
      var active = Object.values(this.authorizedTabs).find((t) => t.key == this.tab);
      if (active != null) {
        return active.class;
      }
      return "none";
    },
  },
  created() {},
  mounted() {},
  methods: {
    getTab(id) {
      // var tab = Object.values(this.tabs).find((t) => t.id == id);
      var tab = this.authorizedTabs.find((t) => t.id == id) || {};
      return tab;
    },
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    tabValidityChanged(val, item) {
      this.tabs[item].valid = val;
    },
    tabHasChanged(val, item) {
      this.tabs[item].hasChange = val;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.filter-label {
  position: absolute;
  top: -22px;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  color: rgba($shades-black, 0.87);
  background: rgba($shades-black, 0.12);
  border-radius: 0.25rem;
  border: 1px solid rgba($shades-black, 0.2);
  padding: 0 0.25rem;
}

.wire-cad-equipment-requests-table table thead.elevation-2 tr > th:first-child {
  background: #f2f3f3;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 689px;
  min-width: 689px;
  border-right: thin solid rgba(42, 54, 59, 0.12);

  &:after {
    content: "";
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    width: 2px;
    z-index: -2;
    box-shadow: 3px 0 4px rgba($shades-black, 0.16);
  }
}

.wire-cad-equipment-requests-table table thead:not(.elevation-2) {
  z-index: 10000 !important;
}

.wire-cad-equipment-requests-table table thead:not(.elevation-2) tr > th:first-child {
  background: #f2f3f3;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10000;
  // border-right: thin solid rgba(42, 54, 59, 0.12);
}

.wire-cad-equipment-requests-table table thead:not(.elevation-2) tr > th:nth-child(2) {
  background: #f2f3f3;
  position: sticky;
  left: 196px;
  top: 0;
  z-index: 100;
  border-left: thin solid rgba(42, 54, 59, 0.12);
}

.wire-cad-equipment-requests-table table thead:not(.elevation-2) tr > th:nth-child(3) {
  background: #f2f3f3;
  position: sticky;
  left: 529px;
  top: 0;
  z-index: 100;
  border-left: thin solid rgba(42, 54, 59, 0.12);
  border-right: thin solid rgba(42, 54, 59, 0.12);

  &:after {
    content: "";
    position: absolute;
    height: calc(100%);
    top: 0;
    right: 0;
    width: 2px;
    z-index: -2;
    box-shadow: 3px 0 4px rgba($shades-black, 0.16);
  }
}
/////////////////////////
///
.wire-cad-equipment-requests-table table tbody tr > td:first-child {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
  // border-right: thin solid rgba(42, 54, 59, 0.12);
}

.wire-cad-equipment-requests-table table tbody tr > td:nth-child(2) {
  background: #fff;
  position: sticky;
  left: 196px;
  top: 0;
  z-index: 100;
  border-left: thin solid rgba(42, 54, 59, 0.12);
}

.wire-cad-equipment-requests-table table tbody tr > td:nth-child(3) {
  background: #fff;
  position: sticky;
  left: 529px;
  top: 0;
  z-index: 100;
  border-left: thin solid rgba(42, 54, 59, 0.12);
  border-right: thin solid rgba(42, 54, 59, 0.12);

  // &:after {
  //   content: "";
  //   position: absolute;
  //   height: calc(100%);
  //   top: 0;
  //   right: 0;
  //   width: 2px;
  //   z-index: -2;
  //   box-shadow: 3px 0 4px rgba($shades-black, 0.16);
  // }
}

.app-main-container {
  height: 100%;
}

.nothing-imported-yet {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 350px;
    // opacity: 0.87;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.import-file-input {
  .v-input__slot {
    cursor: pointer !important;
  }

  .v-file-input__text--placeholder {
    font-size: 14px;
    font-weight: 500;
  }

  .v-text-field__details {
    margin-bottom: 0 !important;
  }

  .v-input__control > .v-input__slot {
    background-color: rgba($shades-black, 0.1) !important;
  }

  .v-label {
    background-color: #e9ebeb !important;
    padding: 0 0.35rem;
    border-radius: 0.5rem;
    color: rgba($shades-black, 0.72) !important;
    border: 1px solid rgba($shades-black, 0.16) !important;
  }

  .v-input__control > .v-input__slot:hover {
    background-color: rgba($shades-black, 0.16) !important;
    .v-label {
      background-color: #dddfdf !important;
    }
  }
}

.request-action-list {
  cursor: pointer !important;
  width: 196px;

  input {
    display: none;
  }

  .v-select {
    .v-input__slot {
      padding: 0 !important;
    }
  }

  &.v-select-alt .v-input .v-input__control > .v-input__slot {
    background-color: transparent !important;
  }

  &:not(.request-action-list-disabled).v-select-alt:hover
    .v-input
    .v-input__control
    > .v-input__slot {
    background-color: rgba($shades-black, 0.1) !important;
  }

  &.request-action-list-disabled {
    opacity: 0.54;

    .v-input__append-inner {
      display: none;
    }
  }
}

.report-action-cell {
  background: #f2f3f3;
  // border-left: 1px solid rgba($shades-black, 0.05);
  border-bottom: 1px solid rgba($shades-black, 0.1);
  padding: 0 !important;
}
.report-action-header {
  background: #e3e3e3;
  border-left: 1px solid rgba($shades-black, 0.05);
}
.report-wirecad-cell {
  background: rgba(#f3e5f5, 0.54);
}
.report-wirecad-header {
  background: rgba(#f3e5f5, 0.54);
}
.report-wirecad-header-top {
  background: rgba(#f3e5f5, 0.54);
}
.new-mfr-created {
  height: 32px !important;

  .v-chip__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-btn {
      padding: 0 !important;
    }
  }
}
.new-equip-created {
  height: 32px !important;

  .v-chip__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-btn {
      padding: 0 !important;
    }
  }
}
</style>
