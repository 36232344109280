<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Manufacturer ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> Manufacturer: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <entity-id
          class="ml-2"
          :label="selected.id"
          :path="manufacturerUrl"
          title="Manufacturer"
        ></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a Manufacturer
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <div color="white" style="position: sticky; top: 0; z-index: 2">
      <v-tabs
        v-model="slideouts.update.tab"
        color="error"
        v-if="slideouts.update.active && !isFetching"
      >
        <v-tab :key="0">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!forms.tab0">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          <i class="fad fa-circle-info me-2"></i> Basic Information
        </v-tab>
        <v-tab :key="1"><i class="fad fa-square-list me-2"></i> Details </v-tab>
        <v-tab :key="2"><i class="fad fa-key me-2"></i> External Access List </v-tab>
        <v-tab :key="3"><i class="fad fa-diagram-project me-2"></i> WireCAD Integration </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Manufacturer ...</p>
    </v-container>
    <v-container key="tabs-items-container" fluid class="pa-0" v-else>
      <v-tabs-items v-model="slideouts.update.tab" style="overflow: visible !important">
        <v-tab-item :key="0">
          <v-form v-model="forms.tab0" ref="updateForm0">
            <company-basic-information
              v-if="slideouts.update.active"
              v-model="selected"
              :imageApiUrl="imageApiUrl"
              :readonly="readonly"
              :isActive="slideouts.update.active"
              class="pb-0"
            ></company-basic-information>
          </v-form>
        </v-tab-item>
        <v-tab-item :key="1">
          <v-form v-model="forms.tab1" ref="updateForm1">
            <company-details v-model="selected" :readonly="readonly"></company-details>
          </v-form>
        </v-tab-item>
        <v-tab-item :key="2">
          <v-form v-model="forms.tab2" ref="updateForm2">
            <manufacture-access-list
              v-model="selected"
              ref="accessListComponent"
              :manufactureId="manufacturerId"
              :readonly="readonly"
            ></manufacture-access-list>
          </v-form>
        </v-tab-item>
        <v-tab-item :key="3">
          <v-form v-model="forms.tab3" ref="updateForm3">
            <v-container class="pa-6" v-if="selected != null">
              <v-row class="mt-0 mb-2">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.guid, allRules.noWhiteSpaces]"
                    label="WireCAD Manufacturer GUID"
                    class="mono-font-text-field"
                    placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                    v-model="selected.wireCadManufacturerGUID"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </v-container>

    <manage-contacts nested ref="manageContacts"></manage-contacts>
    <manage-product-families
      ref="manageProductFamilies"
      nested
      @close="onProductFamiliesPanelClosed"
    ></manage-product-families>
    <manufacturer-stats ref="manufacturerStats" nested></manufacturer-stats>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="
            !isFetching &&
            readonly &&
            isMainRoute &&
            selected.name != specialManufacturerName &&
            $has(perms.Manufacturers.Update)
          "
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges ||
                  ($refs.accessListComponent &&
                    $refs.accessListComponent.errors &&
                    $refs.accessListComponent.errors.length > 0)
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges ||
                  ($refs.accessListComponent &&
                    $refs.accessListComponent.errors &&
                    $refs.accessListComponent.errors.length > 0)
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchManufacturer()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Manufacturer</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>

          <v-list class="more-options-menu"
            ><v-list-item
              @click="showContacts"
              v-if="$has(perms.CompanyContacts.View) && selected.name != specialManufacturerName"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-address-book</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Contacts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="viewProductFamilies" v-if="$has(perms.Manufacturers.View)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-shapes</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Product Families</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="displayStats">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-analytics</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Stats</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              class="my-1"
              v-if="$has(perms.Manufacturers.Update) && selected.name != specialManufacturerName"
            ></v-divider>

            <v-list-item
              @click="convertToClient"
              v-if="$has(perms.Manufacturers.Update) && selected.name != specialManufacturerName"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fas fa-user-tie orange--text text--darken-1</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="orange--text text--darken-2"
                  >Convert To Client</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider
              class="my-1"
              v-if="$has(perms.Manufacturers.Delete) && selected.name != specialManufacturerName"
            ></v-divider>

            <v-list-item
              @click="del"
              v-if="$has(perms.Manufacturers.Delete) && selected.name != specialManufacturerName"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../plugins/permissions";
import ApiService from "../services/manufacturers-service.js";
import companiesApi from "../../Shared/services/companies-service";
import CompanyBasicInformation from "../../Shared/components/BasicInformation.vue";
import CompanyDetails from "../../Shared/components/Details.vue";
import manufactureAccessList from "./ExternalAccessList.vue";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";
import ManageContacts from "../../Shared/components/ManageContacts.vue";
import ManageProductFamilies from "./ManageProductFamilies.vue";
import ManufacturerStats from "./Stats/ManufacturerStats.vue";

export default {
  name: "edit-manufacturer",
  data() {
    return {
      perms,
      manufacturerId: null,
      isFetching: false,
      specialManufacturerName: "**NO ASSOCIATED MAKE**",
      imageApiUrl: `Manufactures/Image`,
      readonly: false,
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          tab: 0,
          active: false,
          isLoading: false,
          isClosing: false,
          fullWidth: false,
          hasChanges: false,
        },
      },
      forms: {
        tab0: true,
        tab1: true,
        tab2: true,
        tab3: true,
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    manufacturerUrl() {
      if (this.selected == null) return "";
      return `manufacturers/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-manufacturer") return true;
      else return false;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "800px";
    },
    valid() {
      if (this.forms.tab0 && this.forms.tab1 && this.forms.tab2 && this.forms.tab3) return true;
      return false;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.isMainRoute &&
        this.selected.name != this.specialManufacturerName &&
        this.$has(this.perms.Manufacturers.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    showContacts() {
      this.$refs.manageContacts.open(this.selected.id, false);
    },
    viewProductFamilies() {
      this.$refs.manageProductFamilies.open(this.selected.id, false);
    },
    onProductFamiliesPanelClosed() {},
    displayStats() {
      this.$refs.manufacturerStats.open(this.selected);
    },
    convertToClient() {
      this.$dialog
        .warning({
          title: `Convert to Client?`,
          text: `Are you sure you want to convert '<b>${this.selected.name}</b>' to be a client?`,
          color: "warning",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "warning",
              handle: () => {
                return companiesApi
                  .convertToClient(this.selected.id)
                  .then((resp) => {
                    this.$dialog.notify.success(
                      `'${this.selected.name}' is converted to a client successfully!`,
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementManufacturer();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewManufacturer) {
      this.$emit("save", this.selected, isNewManufacturer);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      this.$log("update >> toSend", toSend);
      ApiService.update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          this.$log(">>> updated", resp.data);
          var message = "Manufacturer updated successfully!";
          this.selected = resp.data;
          if (this.selected.accessListRecords == null) {
            this.selected.accessListRecords = [];
          }
          this.cementManufacturer();
          var isNewManufacturer = false;
          if (!toSend.id) {
            this.manufacturerId = this.selected.id;
            isNewManufacturer = true;
            message = "Manufacturer added successfully!";
          }
          this.announceChange(isNewManufacturer);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });

          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      if (id == null) {
        this.selected = {
          name: "",
          abbreviatedName: "",
          installationFactor: 0,
          accessGroupRecords: [],
          accessListRecords: [],
        };
        this.readonly = false;
        this.isFetching = false;
        this.manufacturerId = null;
        this.cementManufacturer();
      } else {
        this.readonly = !editMode;
        this.manufacturerId = id;
        this.fetchManufacturer();
      }
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
        this.$refs.updateForm0?.resetValidation();
      }, 250);
    },
    fetchManufacturer() {
      this.isFetching = true;
      ApiService.getById(this.manufacturerId)
        .then((resp) => {
          this.$log("getManufacturerById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          if (this.selected.accessListRecords == null) {
            this.selected.accessListRecords = [];
          }
          this.cementManufacturer();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getManufacturerById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    cementManufacturer() {
      if (this.selected.installationFactor == null) this.selected.installationFactor = 0;
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) {
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
      }

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm0, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm1, () => {
          this.slideouts.update.tab = 1;
        })
      )
        return;

      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm2, () => {
          this.slideouts.update.tab = 2;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this manufacturer: <b>${this.selected.name}</b>?`,
          title: `Delete Manufacturer?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(this.selected.id)
                  .then((resp) => {
                    this.$emit("delete", this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Item deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
  },
  watch: {
    manufacturer: {
      handler() {
        this.selected = this.cloneDeep(this.manufacturer || {});
        this.cementManufacturer();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "selected.abbreviatedName": {
      handler() {
        if (this.selected != null && this.selected.abbreviatedName != null)
          this.selected.abbreviatedName = this.selected.abbreviatedName.toUpperCase();
      },
      deep: true,
    },
  },
  components: {
    CompanyBasicInformation,
    CompanyDetails,
    PanelSizeControl,
    ManageContacts,
    ManageProductFamilies,
    ManufacturerStats,
    manufactureAccessList,
  },
};
</script>
