<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="stageStatusList"
    :total="total"
    :isLoading="isLoading"
    :openOnMount="openOnMount"
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    itemValue="value"
    itemText="text"
  >
    <template v-slot:item="{ item }">
      <lead-status v-if="settings.stage.value == -1" :status="item.value" small></lead-status>
      <project-status v-else :status="item.value" small></project-status>
    </template>
  </filter-base-menu>
</template>

<script>
import { ProjectStatusFilterSettings } from "../FilterSettings";
import FilterBaseMenu from "../FilterBaseMenu.vue";
import ProjectStatus from "../../../../Projects/components/ProjectStatus.vue";
import LeadStatus from "../../../../Leads/components/LeadStatus.vue";

export default {
  name: "project-status-filter",
  components: { FilterBaseMenu, ProjectStatus, LeadStatus },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new ProjectStatusFilterSettings(),
    },
    openOnMount: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    stageStatusList() {
      if (this.settings == null || this.settings.stage == null) return [];
      return this.settings.stage.PROJECT_STATUS;
    },
    total() {
      return this.stageStatusList.length;
    },
  },
  data() {
    return {
      entities: [],
      selectedItems: [],
      isLoading: false,
      initiallyLoaded: false,
    };
  },
  methods: {
    onShow() {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    },
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
  },
};
</script>
