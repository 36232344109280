<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="entities"
    :total="total"
    :isLoading="isLoading"
    :openOnMount="openOnMount"
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    @refresh="onRefresh"
    itemValue="id"
    itemText="name"
  >
    <template v-slot:item="{ item }">
      <equipment-installer :installer-name="item.name"></equipment-installer>
    </template>
  </filter-base-menu>
</template>

<script>
import { InstallerFilterSettings } from "../FilterSettings";
import FilterBaseMenu from "../FilterBaseMenu.vue";
import installersAPI from "../../../../Equipments/services/installers-service";
import EquipmentInstaller from "../../../../Equipments/components/EquipmentInstaller.vue";

export default {
  name: "installer-filter",
  components: { FilterBaseMenu, EquipmentInstaller },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new InstallerFilterSettings(),
    },
    openOnMount: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  data() {
    return {
      entities: [],
      selectedItems: [],
      total: 0,
      isLoading: true,
      initiallyLoaded: false,
    };
  },
  methods: {
    onShow() {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
        this.getData();
      }
    },
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    onRefresh() {
      this.getData();
    },
    getData() {
      this.isLoading = true;

      installersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.entities = resp.data;
          this.total = this.entities.length;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
  },
};
</script>
