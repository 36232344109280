<template>
  <div>
    <v-card :disabled="projectId == null" class="report-card" :elevation="3" @click="generate()">
      <div class="report-card-content">
        <div class="report-icon">
          <i :class="enums.REPORT_TYPE.AreaGroupsBOM.icon"></i>
        </div>
        <div class="report-icon-ghost">
          <i :class="enums.REPORT_TYPE.AreaGroupsBOM.icon"></i>
        </div>
        <div class="card-text">
          <v-card-title class="text-left d-block font-weight-bold">{{
            enums.REPORT_TYPE.AreaGroupsBOM.text
          }}</v-card-title>
          <v-card-subtitle class="text-left font-weight-bold">
            {{ enums.REPORT_TYPE.AreaGroupsBOM.fullText }}
          </v-card-subtitle>
        </div>
      </div>
      <v-card-actions v-if="$has(perms.ProjectReports.ViewHistory)">
        <v-tooltip top nudge-top="-4px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.stop="openHistory()">
              <i class="fas fa-history fs-16px"></i>
            </v-btn>
          </template>
          <span>
            Show all my generated {{ enums.REPORT_TYPE.AreaGroupsBOM.text }} reports for this
            project!
          </span>
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <slideout
      dock="right"
      :size="mainPanelSize"
      :visible.sync="slideout.active"
      @close="onSlideoutClosing"
      class="slideout-deep-purple-header"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold px-1 d-flex align-center" small>
          <i :class="enums.REPORT_TYPE.AreaGroupsBOM.icon" class="fs-24px mb-1 mr-3" style=""></i>
          New <span class="px-1">{{ enums.REPORT_TYPE.AreaGroupsBOM.text }}</span> Report
          <span class="opacity-54 font-weight-medium text-center pl-2">
            "{{ enums.REPORT_TYPE.AreaGroupsBOM.fullText }}"
          </span>
        </h3>
        <div>
          <panel-size-control dark v-model="slideout.fullWidth"></panel-size-control>
          <v-btn @click="close()" dark icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>

      <div color="white" style="position: sticky; top: 0; z-index: 2">
        <v-tabs
          v-model="slideout.tab"
          color="error"
          v-show="slideout.active && !slideout.isGenerating"
          :key="reportSettings.reportSelectionScope"
        >
          <v-tab :key="0" class="d-flex align-center">
            <i class="fad fa-sliders mr-2"></i> Settings
          </v-tab>
          <v-tab :key="1" class="d-flex align-center">
            <i class="fad fa-bullseye-arrow mr-2"></i> Scope
            <span
              class="opacity-64 fs-12px ml-1"
              v-if="reportSettings.reportSelectionScope == enums.ReportScopeOptions.AllSpaces"
            >
              (All Spaces)
            </span>
            <span
              class="opacity-64 fs-12px ml-1"
              v-else-if="
                reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedSpaces
              "
            >
              (Custom Spaces)
            </span>
            <span
              class="opacity-64 fs-12px ml-1"
              v-else-if="
                reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedLevels
              "
            >
              (Custom Levels)
            </span>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>

      <v-container fluid class="pa-0 text-center" v-show="slideout.isGenerating">
        <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
          <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">
          Generating <b>{{ enums.REPORT_TYPE.AreaGroupsBOM.text }}</b> Report ...
        </p>
      </v-container>
      <v-container
        v-show="!slideout.isGenerating"
        fluid
        class="px-3 pt-3"
        style="height: 100%; overflow: hidden"
      >
        <v-tabs-items v-model="slideout.tab" style="overflow: visible !important; height: 100%">
          <v-tab-item :key="0">
            <div class="readonly-border-darker pa-4">
              <h4 class="secondary--text fs-15px">Target</h4>
              <v-radio-group
                v-model="reportSettings.isExternal"
                :disabled="slideout.isGenerating"
                mandatory
                class="mt-1"
                row
                hide-details
              >
                <v-radio :value="false" color="deep-purple">
                  <template v-slot:label>
                    <div
                      class="v-switch-label d-block"
                      :class="
                        !reportSettings.isExternal
                          ? 'deep-purple--text text--darken-2'
                          : 'secondary--text opacity-72'
                      "
                    >
                      Internal
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="true" color="deep-purple">
                  <template v-slot:label>
                    <div
                      class="v-switch-label d-block"
                      :class="
                        reportSettings.isExternal
                          ? 'deep-purple--text text--darken-2'
                          : 'secondary--text opacity-72'
                      "
                    >
                      External <span class="opacity-54">(For a Client)</span>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div class="readonly-border-darker mt-6 pa-4">
              <h4 class="secondary--text fs-15px">Included List Sheet</h4>
              <v-radio-group
                v-model="reportSettings.includedListSheet"
                :disabled="slideout.isGenerating"
                mandatory
                class="mt-1"
                row
                hide-details
              >
                <v-radio :value="enums.ReportIncludedListSheet.SpaceListSheet" color="deep-purple">
                  <template v-slot:label>
                    <div
                      class="v-switch-label d-block"
                      :class="
                        reportSettings.includedListSheet ==
                        enums.ReportIncludedListSheet.SpaceListSheet
                          ? 'deep-purple--text text--darken-2'
                          : 'secondary--text opacity-72'
                      "
                    >
                      Space List Sheet
                    </div>
                  </template>
                </v-radio>
                <v-radio
                  :value="enums.ReportIncludedListSheet.AreaGroupsListSheet"
                  color="deep-purple"
                >
                  <template v-slot:label>
                    <div
                      class="v-switch-label d-block"
                      :class="
                        reportSettings.includedListSheet ==
                        enums.ReportIncludedListSheet.AreaGroupsListSheet
                          ? 'deep-purple--text text--darken-2'
                          : 'secondary--text opacity-72'
                      "
                    >
                      Area Groups List Sheet
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div class="readonly-border-darker mt-6 pa-4">
              <h4 class="secondary--text fs-15px">Included Installers</h4>
              <v-container
                fluid
                class="d-flex flex-row flex-wrap align-center pa-4 mt-2 inset-shadow"
                style="
                  gap: 1.25rem;
                  overflow: auto;
                  background: #eceff1;
                  border-radius: 0.5rem;
                  overflow: overlay;
                  height: 100%;
                  flex: 1 1 auto;
                  display: flex;
                  flex-direction: column;
                "
              >
                <v-sheet
                  class="px-1 py-1"
                  style="border-radius: 2rem 2rem 2rem 2rem"
                  outlined
                  elevation="2"
                  v-for="item in installersList"
                  :key="item.value"
                  :style="
                    reportSettings.installers.includes(item.value)
                      ? 'border-color: #9575CD'
                      : 'border-color: rgba(0, 0, 0, .2);'
                  "
                >
                  <v-checkbox
                    class="check-box-installer mt-0"
                    v-model="reportSettings.installers"
                    :label="item.text"
                    :value="item.text"
                    color="deep-purple"
                    dense
                  >
                    <template v-slot:label>
                      <equipment-installer
                        elevated
                        :installer-name="item.value"
                      ></equipment-installer>
                    </template>
                  </v-checkbox>
                </v-sheet>
              </v-container>
            </div>
          </v-tab-item>
          <v-tab-item
            :key="1"
            style="height: 100%; overflow: hidden; display: flex; flex-direction: column"
          >
            <report-spaces-selection-scope
              :project-id="projectId"
              :selected-spaces.sync="selectedSpaces"
              v-model="reportSettings"
            ></report-spaces-selection-scope>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" text> <i class="far fa-xmark mr-2"></i>Cancel</v-btn>
          <v-btn
            color="deep-purple"
            :dark="!slideout.isGenerating"
            :disabled="slideout.isGenerating"
            @click="generateConfirmed()"
            :loading="slideout.isGenerating"
          >
            <i class="fal fa-sync mr-2"></i> Generate
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
  </div>
</template>

<script>
import enums from "../../../../plugins/enums";
import perms from "../../../../plugins/permissions";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";
import EquipmentInstaller from "../../../Equipments/components/EquipmentInstaller.vue";
import ReportSpacesSelectionScope from "./shared/ReportSpacesSelectionScope.vue";
import reportsService from "../../services/reports-service";
import { eventBus } from "../../../../main";

export default {
  components: { PanelSizeControl, EquipmentInstaller, ReportSpacesSelectionScope },
  data() {
    return {
      enums,
      perms,
      installersList: enums.INSTALLERS_LIST,
      selectedSpaces: [],
      selectedLevel: [],
      reportSettings: {
        projectId: null,
        reportSelectionScope: enums.ReportScopeOptions.AllSpaces,
        spaceIds: [],
        levelIds: [],
        spacesSortBy: "name",
        spacesSortDesc: false,
        isExternal: true,
        includedListSheet: enums.ReportIncludedListSheet.SpaceListSheet,
        installers: [],
      },
      slideout: {
        active: false,
        isGenerating: false,
        fullWidth: false,
        tab: 0,
      },
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valid() {
      return true;
    },
    mainPanelSize() {
      return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "1000px";
    },
  },
  methods: {
    openHistory() {
      this.$emit("history");
    },
    generate() {
      this.reset();
      this.slideout.active = true;
    },
    reset() {
      this.slideout.isGenerating = false;
      this.slideout.tab = 0;
      this.selectedSpaces = [];
      this.reportSettings = {
        projectId: this.projectId,
        reportSelectionScope: enums.ReportScopeOptions.AllSpaces,
        spaceIds: [],
        levelIds: [],
        spacesSortBy: "name",
        spacesSortDesc: false,
        isExternal: true,
        includedListSheet: enums.ReportIncludedListSheet.SpaceListSheet,
        installers: this.installersList.map((i) => i.text),
      };
    },
    cancel() {
      this.reset();
      this.slideout.active = false;
    },
    close() {
      this.reset();
      this.slideout.active = false;
    },
    generateConfirmed() {
      this.slideout.isGenerating = true;
      var toSend = this.cloneDeep(this.reportSettings);
      this.$log("report generateConfirmed", toSend);
      reportsService.AreaGroupsBOM(toSend).then(this.onResponse).catch(this.onCatch);
    },
    onResponse(resp) {
      this.$log("report generate resp.data", resp.data);
      this.$notify(resp.data, "info", resp.data.id, true);
      eventBus.$emit("add-to-download-center", resp.data);
      this.close();
    },
    onCatch(err) {
      this.$log(">>> err - reportsService AreaGroupsBOM", err);
      this.slideout.isGenerating = false;
      this.$handleError(err);
    },
    onSlideoutClosing() {},
  },
  watch: {
    reportSettings: {
      handler(newSettings, oldSettings) {
        this.$log(
          "reportSettings",
          this.isEqual(this.cloneDeep(newSettings), this.cloneDeep(oldSettings))
        );
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.cloneDeep(newSettings), this.cloneDeep(oldSettings))
        );
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
