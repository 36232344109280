<template>
  <v-dialog v-model="dialog" max-width="500px" style="z-index: 99999999" persistent>
    <v-fade-transition mode="out-in">
      <v-card v-if="dialog">
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-link-simple mr-2"></i>
          {{ title != null && title != "" ? title : "Select Equipment" }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="pt-5 pb-0">
            <v-form v-model="valid">
              <equipment-selector
                required
                ref="equipmentSelector"
                :equipment.sync="selected.equipment"
                :equipmentId.sync="selected.equipmentId"
                :loading.sync="isEquipmentLoading"
              ></equipment-selector
            ></v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" text :disabled="isEquipmentLoading">
            <i class="far fa-xmark mr-2"></i> Cancel
          </v-btn>
          <v-btn @click="clear()" color="error" text :disabled="isEquipmentLoading">
            <i class="far fa-trash-can-list mr-2"></i> Clear Selection
          </v-btn>
          <v-btn
            color="info"
            @click="save()"
            :loading="isEquipmentLoading"
            :disabled="!valid || isEquipmentLoading"
          >
            <i class="fad fa-check mr-2"></i> Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-fade-transition>
  </v-dialog>
</template>

<script>
import EquipmentSelector from "./EquipmentSelector.vue";

export default {
  components: { EquipmentSelector },
  data() {
    return {
      valid: false,
      dialog: false,
      isEquipmentLoading: false,
      selected: {
        equipment: null,
        equipmentId: null,
      },
    };
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    open(equipmentId) {
      this.selected.equipmentId = equipmentId;
      this.selected.equipment = null;
      this.dialog = true;
    },
    save() {
      console.log("this.selected.equipment", this.selected.equipment);
      this.$emit("save", this.selected.equipment);
      this.close();
    },
    clear() {
      this.$emit("clear");
      this.close();
    },
    cancel() {
      this.$emit("cancel");
      this.close();
    },
    close() {
      this.dialog = false;
      this.selected.equipment = null;
      this.selected.equipmentId = null;
    },
  },
};
</script>

<style lang="scss">
.alt-comp-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
