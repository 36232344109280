<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 600 : 1350"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.phases.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i>
        Fetching Contract's Construction Phases ...
      </h3>
      <h3 v-else-if="contract != null" class="font-weight-bold pa-1" small>
        <i class="fad fa-timeline-arrow mr-2"></i> <b>{{ contract.name }}</b> Construction Phases
      </h3>
      <div>
        <panel-size-control v-model="slideouts.phases.fullWidth"></panel-size-control>
        <v-btn @click="discard()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-scroll-y-transition mode="out-in">
      <v-container v-if="isFetching" class="pa-0 text-center" key="fetching">
        <video
          width="320"
          muted
          loop
          autoplay
          style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
        >
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Fetching Contract's Construction Phases ...</p>
      </v-container>
      <v-container v-else-if="contract != null" fluid key="results" class="pa-4">
        <div
          style="
            background: #eceff1;
            border-radius: 0.5rem;
            height: 100%;
            display: flex;
            flex-direction: column;
          "
          class="pa-3 pt-0 inset-shadow"
        >
          <v-sheet
            rounded
            elevation="2"
            class="d-inline-flex align-center pa-2 mt-2"
            style="gap: 0.5rem; width: fit-content"
          >
            <h4><i class="fad fa-file-signature mr-2 ml-1"></i>Contract:</h4>
            <h4 class="font-weight-black">{{ contract.name }}</h4>
            <v-divider vertical class="mx-2"></v-divider>
            <code
              class="mono-font secondary--text fs-12px"
              :class="{
                'brown lighten-4 brown--text': contract.isArchived,
                'blue-grey lighten-5':
                  !contract.isArchived && contract.status == enums.CONTRACT_STATUS.Draft.value,
                'orange lighten-4':
                  !contract.isArchived && contract.status == enums.CONTRACT_STATUS.Pending.value,
                'green lighten-4':
                  !contract.isArchived && contract.status == enums.CONTRACT_STATUS.Approved.value,
              }"
              style="flex: none"
            >
              #{{ contract.id }}
            </code>
            <div class="d-inline-flex align-center">
              <v-chip
                v-if="contract.isArchived"
                class="px-2"
                small
                label
                :color="'brown'"
                :text-color="'white'"
              >
                <i class="fad fa-archive mr-2"></i>
                <span class="font-weight-medium" style="line-height: 0"> Archived </span>
              </v-chip>
              <contract-status v-else small :status="contract.status"></contract-status>
              <v-tooltip
                top
                z-index="9999999"
                max-width="400px"
                v-if="contract.status == enums.CONTRACT_STATUS.Approved.value"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    class="ml-1 px-1"
                    min-width="20px"
                    height="24px"
                    small
                    color="green darken-4"
                    @click.stop="displayApprovedMessage"
                  >
                    <i class="fad fa-circle-info fs-16px"></i>
                  </v-btn>
                </template>
                <div style="width: 100%" class="py-1" :id="'contract-approved-msg-' + contract.id">
                  <div class="font-weight-bold green--text accent-3">
                    <i class="fas fa-circle-check mr-1" style="width: 20px"></i>Contract Approved
                  </div>
                  <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
                  <div class="d-flex align-center">
                    <i class="fad fa-user mr-1" style="width: 20px"></i>By:
                    <span class="font-weight-bold ml-1">
                      {{ contract.approvedBy.firstName }}
                      {{ contract.approvedBy.lastName }}
                    </span>
                  </div>
                  <div class="d-flex align-center">
                    <i class="fad fa-calendar-day mr-1" style="width: 20px"></i>On:
                    <span class="font-weight-bold ml-1">
                      <dater :date="contract.approvedOn"></dater
                    ></span>
                  </div>
                  <div
                    class="d-flex align-start flex-column"
                    v-if="
                      contract.approveNote != null &&
                      contract.approveNote.trim() != '<p></p>' &&
                      contract.approveNote.trim() != ''
                    "
                  >
                    <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
                    <b class="d-inline-flex align-center">
                      <i class="fad fa-note mr-1" style="width: 20px"></i>Notes:
                    </b>
                    <div
                      class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 mt-2"
                      style="line-break: anywhere"
                      v-html="contract.approveNote"
                    ></div>
                  </div>
                </div>
              </v-tooltip>
              <i
                v-if="contract.isLocked"
                class="fas fa-lock-keyhole secondary--text text--darken-1 fs-16px mx-2"
              ></i>
              <i
                v-else-if="
                  !contract.isLocked && contract.status == enums.CONTRACT_STATUS.Approved.value
                "
                class="fas fa-lock-keyhole-open deep-orange--text text--darken-1 fs-16px mx-2"
              ></i>
              <i
                v-else-if="
                  !contract.isLocked && contract.status == enums.CONTRACT_STATUS.Draft.value
                "
                class="fas fa-lock-keyhole-open secondary--text opacity-24 fs-16px mx-2"
              ></i>
            </div>
          </v-sheet>
          <v-divider class="my-2"></v-divider>
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center" style="gap: 1rem">
              <h4 class="font-weight-black">Construction Phases</h4>
              <v-btn
                v-if="$has(perms.ProjectContracts.ConstructionPhasesCreate)"
                color="info"
                :dark="!contract.isLocked"
                small
                @click="addConstructionPhase"
                :disabled="contract.isLocked"
              >
                <i class="fal fa-plus mr-2"></i>Add Construction Phase
              </v-btn>
            </div>

            <v-tooltip left z-index="999">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  elevation="2"
                  width="32px"
                  height="28px"
                  min-width="32px"
                  min-height="28px"
                  :loading="isFetching"
                  :disabled="slideouts.phases.isLoading || isFetching"
                  @click="getContractPhases()"
                >
                  <i class="far fa-redo" style="font-size: 16px"></i>
                </v-btn>
              </template>
              <span>Refresh Construction Phases</span>
            </v-tooltip>
          </div>
          <v-data-table
            dense
            :headers="constructionPhasesHeaders"
            :items="contract.constructionPhases"
            class="elevation-2 mt-2"
            hide-default-footer
            :items-per-page="-1"
            sort-by="startDate"
            :sort-desc="false"
            :custom-sort="customSort"
            :item-class="rowClass"
            @click:row="constructionPhaseRowClicked"
          >
            <template v-slot:progress>
              <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
            </template>
            <template v-slot:[`header.actions`]>
              <i class="fad fa-cog pl-2"></i>
            </template>
            <template v-slot:[`header.totalAC`]="{ header }">
              <header-with-tooltip :header="header"></header-with-tooltip>
            </template>
            <template v-slot:[`header.totalSC`]="{ header }">
              <header-with-tooltip :header="header"></header-with-tooltip>
            </template>
            <template v-slot:[`header.totalC`]="{ header }">
              <header-with-tooltip :header="header"></header-with-tooltip>
            </template>
            <template v-slot:[`header.totalVI`]="{ header }">
              <header-with-tooltip :header="header"></header-with-tooltip>
            </template>
            <template v-slot:[`header.totalBIM`]="{ header }">
              <header-with-tooltip :header="header"></header-with-tooltip>
            </template>
            <template v-slot:[`header.totalMGT`]="{ header }">
              <header-with-tooltip :header="header"></header-with-tooltip>
            </template>
            <template v-slot:[`header.totalTCN`]="{ header }">
              <header-with-tooltip :header="header"></header-with-tooltip>
            </template>
            <template v-slot:[`header.totalSCD`]="{ header }">
              <header-with-tooltip :header="header"></header-with-tooltip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <h4 class="d-flex align-center" style="gap: 0.5rem">
                <construction-phase-type :type="item.type"></construction-phase-type>
                <span
                  class="d-flex align-start flex-column py-1"
                  style="gap: 0.1rem; white-space: nowrap"
                >
                  <v-chip
                    color="deep-orange lighten-5"
                    text-color="deep-orange darken-1"
                    class="px-1"
                    style="border: 1px solid #ff5722 !important; line-height: 0; height: 14px"
                    label
                    x-small
                    v-if="isLinkedParentPhase(item)"
                  >
                    Linked Parent
                  </v-chip>
                  <v-chip
                    color="teal lighten-5"
                    text-color="teal darken-1"
                    class="px-1"
                    style="border: 1px solid #009688 !important; line-height: 0; height: 14px"
                    label
                    x-small
                    v-else-if="isLinkedChildPhase(item)"
                  >
                    Linked Child
                  </v-chip>
                  {{ item.name }}
                </span>
              </h4>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <construction-phase-type :type="item.type"></construction-phase-type>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              <!-- <span v-if="item.dateRangeType == DateRangeTypes.Month.value">Month</span>
                      <span v-else-if="item.dateRangeType == DateRangeTypes.Custom.value">
                        Custom Range
                      </span> -->
              <h4>{{ item.startDate.substr(0, 7) }}</h4>
            </template>
            <!-- <template v-slot:[`item.startDate`]="{ item }">
                      <dater :date="item.startDate" date-only></dater>
                    </template> -->
            <template v-slot:[`item.endDate`]="{ item }">
              <dater :date="item.endDate" date-only></dater>
            </template>
            <template v-slot:[`item.totalAC`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalAC > 0,
                  'pink--text': item.totalAC < 0,
                }"
              >
                {{ item.totalAC | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalSC`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalSC > 0,
                  'pink--text': item.totalSC < 0,
                }"
              >
                {{ item.totalSC | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalC`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalC > 0,
                  'pink--text': item.totalC < 0,
                }"
              >
                {{ item.totalC | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalVI`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalVI > 0,
                  'pink--text': item.totalVI < 0,
                }"
              >
                {{ item.totalVI | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalBIM`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalBIM > 0,
                  'pink--text': item.totalBIM < 0,
                }"
              >
                {{ item.totalBIM | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalMGT`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalMGT > 0,
                  'pink--text': item.totalMGT < 0,
                }"
              >
                {{ item.totalMGT | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalTCN`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalTCN > 0,
                  'pink--text': item.totalTCN < 0,
                }"
              >
                {{ item.totalTCN | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalSCD`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalSCD > 0,
                  'pink--text': item.totalSCD < 0,
                }"
              >
                {{ item.totalSCD | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalHours`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalHours > 0,
                  'pink--text': item.totalHours < 0,
                }"
              >
                {{ item.totalHours | fixedFloatFormatNoDecimalPoints }}
              </span>
            </template>
            <template v-slot:[`item.totalLaborRevenue`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalLaborRevenue > 0,
                  'pink--text': item.totalLaborRevenue < 0,
                }"
              >
                {{ item.totalLaborRevenue | usdFormat }}
              </span>
            </template>
            <template v-slot:[`item.totalLaborCost`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalLaborCost > 0,
                  'pink--text': item.totalLaborCost < 0,
                }"
              >
                {{ item.totalLaborCost | usdFormat }}
              </span>
            </template>
            <template v-slot:[`item.totalDirectExpensesRevenue`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalDirectExpensesRevenue > 0,
                  'pink--text': item.totalDirectExpensesRevenue < 0,
                }"
              >
                {{ item.totalDirectExpensesRevenue | usdFormat }}
              </span>
            </template>
            <template v-slot:[`item.totalDirectExpensesCost`]="{ item }">
              <span
                class="fs-13px"
                :class="{
                  'info--text': item.totalDirectExpensesCost > 0,
                  'pink--text': item.totalDirectExpensesCost < 0,
                }"
              >
                {{ item.totalDirectExpensesCost | usdFormat }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <i class="fas fa-link linked-icon" v-if="isLinkedChildPhase(item)"></i>
              <v-menu dense offset-x right v-if="!contract.isLocked">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon small elevation="0" v-bind="attrs" v-on="on">
                    <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                  </v-btn>
                </template>
                <v-list class="more-options-menu">
                  <v-list-item
                    @click="updateConstructionPhase(item)"
                    v-if="
                      !contract.isLocked && $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                    "
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-pen</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Update</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="cloneConstructionPhase(item)"
                    v-if="
                      !contract.isLocked && $has(perms.ProjectContracts.ConstructionPhasesUpdate)
                    "
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Clone</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider
                    class="my-1"
                    v-if="
                      $has(perms.ProjectContracts.ConstructionPhasesUpdate) &&
                      $has(perms.ProjectContracts.ConstructionPhasesDelete)
                    "
                  ></v-divider>

                  <v-list-item
                    @click="deleteConstructionPhase(item)"
                    v-if="
                      !contract.isLocked && $has(perms.ProjectContracts.ConstructionPhasesDelete)
                    "
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-trash-alt red--text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="red--text">Delete</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <img width="275" src="/img/art/fogg-206.png" class="mt-3" />
              <p class="font-weight-bold fs-14px mb-5 text--secondary">
                No Construction Phases Available!
              </p>
            </template>
            <template v-slot:[`body.append`]="{ items }">
              <tr class="contract-totals">
                <td></td>
                <td colspan="2" align="right">
                  <h4 class="align-right blue-grey--text">Contract Totals:</h4>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalAC') > 0,
                      'pink--text': sum(items, 'totalAC') < 0,
                    }"
                  >
                    {{ sum(items, "totalAC") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalSC') > 0,
                      'pink--text': sum(items, 'totalSC') < 0,
                    }"
                  >
                    {{ sum(items, "totalSC") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalC') > 0,
                      'pink--text': sum(items, 'totalC') < 0,
                    }"
                  >
                    {{ sum(items, "totalC") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalVI') > 0,
                      'pink--text': sum(items, 'totalVI') < 0,
                    }"
                  >
                    {{ sum(items, "totalVI") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalBIM') > 0,
                      'pink--text': sum(items, 'totalBIM') < 0,
                    }"
                  >
                    {{ sum(items, "totalBIM") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalMGT') > 0,
                      'pink--text': sum(items, 'totalMGT') < 0,
                    }"
                  >
                    {{ sum(items, "totalMGT") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalTCN') > 0,
                      'pink--text': sum(items, 'totalTCN') < 0,
                    }"
                  >
                    {{ sum(items, "totalTCN") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalSCD') > 0,
                      'pink--text': sum(items, 'totalSCD') < 0,
                    }"
                  >
                    {{ sum(items, "totalSCD") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalHours') > 0,
                      'pink--text': sum(items, 'totalHours') < 0,
                    }"
                  >
                    {{ sum(items, "totalHours") | fixedFloatFormatNoDecimalPoints }}
                  </span>
                </td>
                <td class="px-2" v-if="$has(perms.ProjectContracts.ViewCostSums)">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalLaborCost') > 0,
                      'pink--text': sum(items, 'totalLaborCost') < 0,
                    }"
                  >
                    {{ sum(items, "totalLaborCost") | usdFormat }}
                  </span>
                </td>
                <td class="px-2" v-if="$has(perms.ProjectContracts.ViewRevenueSums)">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalLaborRevenue') > 0,
                      'pink--text': sum(items, 'totalLaborRevenue') < 0,
                    }"
                  >
                    {{ sum(items, "totalLaborRevenue") | usdFormat }}
                  </span>
                </td>
                <td class="px-2" v-if="$has(perms.ProjectContracts.ViewCostSums)">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalDirectExpensesCost') > 0,
                      'pink--text': sum(items, 'totalDirectExpensesCost') < 0,
                    }"
                  >
                    {{ sum(items, "totalDirectExpensesCost") | usdFormat }}
                  </span>
                </td>
                <td class="px-2" v-if="$has(perms.ProjectContracts.ViewRevenueSums)">
                  <span
                    class="fs-13px"
                    :class="{
                      'info--text': sum(items, 'totalDirectExpensesRevenue') > 0,
                      'pink--text': sum(items, 'totalDirectExpensesRevenue') < 0,
                    }"
                  >
                    {{ sum(items, "totalDirectExpensesRevenue") | usdFormat }}
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-container>
    </v-scroll-y-transition>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined class="mr-2" @click="openUpdateContract">
          <i class="fad fa-memo-circle-info mr-2" style="font-size: 15px"></i> Contract Info
        </v-btn>
        <v-btn v-if="canViewDocuments" outlined color="secondary" @click="openFileManager">
          <i class="fad fa-folder-open mr-2" style="font-size: 15px"></i>Contract Documents
        </v-btn>
      </v-card-actions>
    </template>

    <edit-construction-phase
      ref="editConstructionPhase"
      nested
      @close="slideouts.phases.isEditPanelActive = false"
      @save="onConstructionPhaseSaved"
      @delete="onDeleteConstructionPhaseSuccess"
      @unlink="onConstructionPhaseUnlinked"
    ></edit-construction-phase>

    <clone-construction-phase ref="cloneConstructionPhase" @save="onCloneConstructionPhaseSuccess">
    </clone-construction-phase>

    <contract-activity-log nested ref="contractActivityLog"></contract-activity-log>

    <contract-file-manager nested ref="fileManager"></contract-file-manager>

    <edit-contract-details
      @save="onContractDetailsSaved"
      ref="editContractDetails"
      nested
    ></edit-contract-details>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import contractsService from "../services/contracts-service";
import constructionPhasesService from "../services/constructionPhases-service";
import constructionPhasesHeaders from "../config/tables/constructionPhases.header";
import HeaderWithTooltip from "../../Shared/components/HeaderWithTooltip.vue";
import ConstructionPhaseType from "./ConstructionPhaseType.vue";
import ContractStatus from "./ContractStatus.vue";
import CloneConstructionPhase from "./CloneConstructionPhase.vue";
import EditConstructionPhase from "./EditConstructionPhase.vue";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import ContractActivityLog from "./activityLogs/ContractActivityLog.vue";
import ContractFileManager from "./ContractFileManager.vue";
import EditContractDetails from "./EditContractDetails.vue";

export default {
  name: "contract-construction-phases",
  components: {
    CloneConstructionPhase,
    EditConstructionPhase,
    HeaderWithTooltip,
    PanelSizeControl,
    ConstructionPhaseType,
    ContractStatus,
    ContractActivityLog,
    ContractFileManager,
    EditContractDetails,
  },
  data() {
    return {
      perms,
      enums,
      headers: constructionPhasesHeaders,
      baseUrl: null,
      contractId: null,
      contract: null,
      isFetching: false,
      slideouts: {
        phases: {
          isLoading: false,
          active: false,
          fullWidth: false,
          isEditPanelActive: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openFileManager() {
      this.$refs.fileManager.openWithContract(this.contract);
    },
    openUpdateContract() {
      this.$refs.editContractDetails.open(this.contract.id);
    },
    onContractDetailsSaved() {
      this.$log("onContractDetailsSaved");
      // this.getProjectContracts();
    },
    rowClass(item) {
      if (this.isLinkedParentPhase(item)) return "phase-parent";
      else if (this.isLinkedChildPhase(item)) return "phase-child";
      return "";
    },
    displayApprovedMessage() {
      var approvedMsg = document
        .querySelector("#contract-approved-msg-" + this.contract.id)
        .cloneNode(true);
      var devElms = approvedMsg.querySelectorAll(".v-divider");
      devElms.forEach((dev) => {
        dev.className = "v-divider theme--light my-2 mx-0";
      });
      this.$dialog
        .info({
          title: `'${this.contract.name}' is approved!`,
          text: approvedMsg.innerHTML,
          color: "success",
        })
        .then((res) => {});
    },
    addConstructionPhase() {
      this.slideouts.phases.isEditPanelActive = true;
      this.$refs.editConstructionPhase.open(null, true, this.contract);
    },
    updateConstructionPhase(ConstructionPhase) {
      this.slideouts.phases.isEditPanelActive = true;
      this.$refs.editConstructionPhase.open(ConstructionPhase.id, true);
    },
    constructionPhaseRowClicked(row) {
      this.slideouts.phases.isEditPanelActive = true;
      this.$refs.editConstructionPhase.open(row.id, false);
    },
    onConstructionPhaseUnlinked(phaseData) {
      this.$log("onConstructionPhaseUnlinked", phaseData);
      this.getContractPhases();
      this.$emit("change");
    },
    onConstructionPhaseSaved(phaseData, isNewPhase) {
      this.$log("onConstructionPhaseSaved", phaseData, isNewPhase);
      this.getContractPhases();
      this.$emit("change");
    },
    onCloneConstructionPhaseSuccess(id) {
      this.$log("onCloneConstructionPhaseSuccess", id);
      this.getContractPhases();
      this.$emit("change");
    },
    discard() {
      this.slideouts.phases.active = false;
    },
    sum(items, prop) {
      var sum = items.reduce((total, item) => {
        return this.fixedFloat(total + item[prop]);
      }, 0);
      return sum;
    },
    getContractPhases() {
      this.isFetching = true;
      this.$log("getContractPhases", this.contractId);
      if (this.contractId) {
        contractsService
          .getContractPhases(this.contractId)
          .then((resp) => {
            this.contract = resp.data;
            this.isFetching = false;
          })
          .catch((err) => {
            this.isFetching = false;
            this.$handleError(err);
          });
      }
    },
    open(contractId) {
      this.contractId = contractId;
      this.slideouts.phases.active = true;
      this.getContractPhases();
    },
    onSlideoutClosing(e) {
      if (this.slideouts.phases.isEditPanelActive) {
        // Avoid to close the inner Slide while outer mask clicked
        e.wait = true;
        return;
      }
      this.slideouts.phases.isEditPanelActive = false;

      // prevent close and wait
      e.wait = true;

      if (this.slideouts.phases.isLoading && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");

      this.contract = null;
      this.contractId = null;
      this.isFetching = false;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "startDate") {
          //dateRangeType
          if (isDesc[0]) {
            return new Date(b["startDate"]) - new Date(a["startDate"]);
          } else {
            return new Date(a["startDate"]) - new Date(b["startDate"]);
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
            } else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    deleteConstructionPhase(phase) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this naming template?<br/><h4>${phase.name}</h4>`,
          title: `Delete Construction Phase?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return constructionPhasesService
                  .delete(phase.id)
                  .then((resp) => {
                    this.onDeleteConstructionPhaseSuccess(phase.id);
                    this.$dialog.notify.success("Construction Phase deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Construction Phase!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    cloneConstructionPhase(phase) {
      this.$refs.cloneConstructionPhase.open(phase);
      return;
    },
    onDeleteConstructionPhaseSuccess(id) {
      this.$log("onDeleteConstructionPhaseSuccess", id);
      this.getContractPhases();
      this.$emit("change");
    },
    isLinkedParentPhase(phase) {
      return phase != null && phase.isLinked && phase.parentId == null;
    },
    isLinkedChildPhase(phase) {
      return phase != null && phase.isLinked && phase.parentId != null;
    },
  },
  watch: {},
  computed: {
    mainPanelSize() {
      // return this.slideouts.phases.fullWidth ? "100%" : this.nested ? "87%" : "1350px";
      return this.slideouts.phases.fullWidth ? "100%" : this.nested ? "87%" : "72%";
    },
    constructionPhasesHeaders() {
      var headers = this.headers.filter((h) => !h.hidden);
      if (!this.$has(perms.ProjectContracts.ViewRevenueSums)) {
        headers = headers.filter(
          (h) => h.value != "totalLaborRevenue" && h.value != "totalDirectExpensesRevenue"
        );
      }
      if (!this.$has(perms.ProjectContracts.ViewCostSums)) {
        headers = headers.filter(
          (h) => h.value != "totalLaborCost" && h.value != "totalDirectExpensesCost"
        );
      }
      return headers;
    },
    canViewDocuments() {
      return this.$has(perms.ProjectContracts.DocumentsView);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}

tr.phase-parent {
  background: rgba(#f4511e, 0.06);
  position: relative;

  & > td:first-child {
    border-left: 3px solid #f4511e;
  }

  // & > td:first-child .linked-icon {
  //   position: absolute;
  //   left: 1rem;
  //   bottom: -10px;
  //   color: #f4511e;
  // }
}

tr.phase-child {
  background: rgba(#00897b, 0.06);
  position: relative;

  & > td:first-child {
    border-left: 3px solid #00897b;
  }

  & > td:first-child .linked-icon {
    position: absolute;
    left: 6px;
    top: -6px;
    color: #f4511e;
  }
}
// .old-contract-container {
//   padding: 0.5rem;
//   border: 1px dashed rgba($shades-black, 0.4);
//   border-radius: 0.5rem;
// }
</style>
