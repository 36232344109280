<template>
  <slideout
    dock="right"
    class="big-board-entry-details"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 950"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.update.active"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Entry Details...
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-user-tie mr-2"></i> Entry Details
        <v-btn-toggle
          v-model="treeView"
          mandatory
          color="deep-purple accent-3"
          dense
          group
          style="height: 40px"
        >
          <v-btn dense :value="false" style="border-radius: 4px">
            <i class="fad fa-list mr-2 fs-16px"></i>List View
          </v-btn>
          <v-btn dense :value="true" style="border-radius: 4px">
            <i class="fad fa-sitemap mr-2 fs-16px"></i>Tree View
          </v-btn>
        </v-btn-toggle>
      </h3>
      <div>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-container
      fluid
      class="d-flex align-center justify-center text-center inset-shadow"
      style="height: 100%; background: #eceff1; border-radius: 0"
      v-if="isFetching"
    >
      <video
        width="250"
        muted
        loop
        autoplay
        class="inset-shadow-video"
        style="padding: 0.5rem; border-radius: 10rem; background: #fff; margin: 0 auto"
      >
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <h3 class="mt-3 text--secondary font-weight-bold">Fetching Entry Details ...</h3>
    </v-container>

    <v-container fluid class="pa-3" v-else-if="entryData != null" style="height: 100%">
      <v-row
        v-if="entryData.rowType == 'UserEntry'"
        align="center"
        justify="start"
        dense
        class="mb-2"
        style="flex: none"
      >
        <v-col cols="12" md="auto" style="width: 250px" class="px-0">
          <user-all-selector
            v-if="entryData.recordType == 0"
            :users="users"
            required
            hideLabel
            hideDetails
            readonly
            :isUsersLoading="false"
            v-model="entryData.userId"
            class="ml-1"
          ></user-all-selector>
          <user-avatar
            class="ml-2"
            v-else
            showNoUser
            :noUserText="entryData.placeholderName"
            noUserIcon="fas fa-question"
          ></user-avatar>
        </v-col>
        <v-col cols="12" md="auto">
          <label
            class="d-inline-flex ml-4"
            style="font-weight: 600 !important; font-size: 14px; flex: none; align-items: center"
            >{{ getLaborTypeObj(entryData.laborType).desc }}
          </label>
        </v-col>
        <v-col cols="12" md="auto">
          <label
            v-if="rowTotalMode"
            class="d-flex ml-6"
            style="font-weight: 600 !important; font-size: 14px; flex: none; align-items: center"
          >
            <i class="fad fa-calendar-lines mr-2"></i>
            {{ dateRangeStart.month }} {{ dateRangeStart.year }}
            <i class="fad fa-arrow-right-long mx-3"></i>
            {{ dateRangeEnd.month }} {{ dateRangeEnd.year }}
          </label>
          <label
            v-else
            class="d-flex ml-6"
            style="font-weight: 600 !important; font-size: 14px; flex: none; align-items: center"
          >
            <i class="fad fa-calendar-lines mr-2"></i>
            {{ entryData.parentDate.month }} {{ entryData.parentDate.year }}
          </label>
        </v-col>
        <v-col cols="12" md class="d-flex justify-end" style="gap: 1rem">
          <export-entry-details-to-excel
            :recordsList="filteredRecordsList"
            :entryData="entryData"
            :primaryTitle="primaryTitle"
            :secondaryTitle="secondaryTitle"
            :options="entryDetailsOptions"
            :type="type"
          ></export-entry-details-to-excel>
          <big-board-contracts-filter
            v-model="filterContractStatus"
            inset
          ></big-board-contracts-filter>
        </v-col>
      </v-row>
      <!-- -if="entryData.laborType == enums.LABOR_TYPE.Hours.value" -->
      <v-row v-else align="center" justify="start" dense class="mb-2" style="flex: none">
        <v-col cols="12" md="auto" class="pa-0 ml-1 rounded" style="background: #eeeeee">
          <div
            class="big-board-group rounded"
            :class="getLaborTypeObj(entryData.laborType).groupClass"
          >
            <div class="big-board-group-content px-2" style="height: 28px">
              <i
                v-if="getLaborTypeObj(entryData.laborType).groupIcon"
                class="fad fs-18px mr-1"
                style="line-height: 0"
                :class="getLaborTypeObj(entryData.laborType).groupIcon"
              ></i>
              <label
                class="d-inline-flex ma-0"
                style="font-weight: 600 !important; font-size: 14px; flex: none; align-self: center"
              >
                {{ getLaborTypeObj(entryData.laborType).desc }}
              </label>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="auto">
          <label
            class="d-inline-flex ml-1"
            style="font-weight: 600 !important; font-size: 14px; flex: none; align-items: center"
            >{{ entryData.title }}
          </label>
        </v-col>
        <v-col cols="12" md="auto">
          <label
            v-if="rowTotalMode"
            class="d-flex ml-6"
            style="font-weight: 600 !important; font-size: 14px; flex: none; align-items: center"
          >
            <i class="fad fa-calendar-lines mr-2"></i>
            {{ dateRangeStart.month }} {{ dateRangeStart.year }}
            <i class="fad fa-arrow-right-long mx-3"></i>
            {{ dateRangeEnd.month }} {{ dateRangeEnd.year }}
          </label>
          <label
            v-else
            class="d-flex ml-6"
            style="font-weight: 600 !important; font-size: 14px; flex: none; align-items: center"
          >
            <i class="fad fa-calendar-lines mr-2"></i>
            {{ entryData.parentDate.month }} {{ entryData.parentDate.year }}
          </label>
        </v-col>
        <v-col cols="12" md class="d-flex justify-end" style="gap: 1rem">
          <export-entry-details-to-excel
            :recordsList="filteredRecordsList"
            :entryData="entryData"
            :primaryTitle="primaryTitle"
            :secondaryTitle="secondaryTitle"
            :options="entryDetailsOptions"
            :type="type"
          ></export-entry-details-to-excel>
          <big-board-contracts-filter
            v-model="filterContractStatus"
            inset
          ></big-board-contracts-filter>
        </v-col>
      </v-row>
      <div
        class="pa-5 inset-shadow"
        style="
          overflow: auto;
          background: #eceff1;
          border-radius: 0.5rem;
          overflow: overlay;
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        "
      >
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <div v-if="rowTotalMode">
              <div
                class="entry-totals"
                v-if="
                  entryData.rowType == 'UserEntry' ||
                  entryData.laborType == enums.LABOR_TYPE.Hours.value
                "
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    class="mr-1"
                    :class="{
                      'val-positive': entryData.rowSumTotal.total > 0,
                      'val-negative': entryData.rowSumTotal.total < 0,
                      'val-zero': entryData.rowSumTotal.total == 0,
                    }"
                    >{{ entryData.rowSumTotal.total | fixedFloatFormat }}</span
                  >
                  Hours
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    class="mr-1"
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalApproved > 0,
                      'val-negative': entryData.rowSumTotal.totalApproved < 0,
                      'val-zero': entryData.rowSumTotal.totalApproved == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalApproved | fixedFloatFormat }}</span
                  >
                  Hours
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    class="mr-1"
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalPending > 0,
                      'val-negative': entryData.rowSumTotal.totalPending < 0,
                      'val-zero': entryData.rowSumTotal.totalPending == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalPending | fixedFloatFormat }}</span
                  >
                  Hours
                </span>
              </div>
              <div
                class="entry-totals"
                v-else-if="entryData.laborType == enums.LABOR_TYPE.Revenue.value"
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.total > 0,
                      'val-negative': entryData.rowSumTotal.total < 0,
                      'val-zero': entryData.rowSumTotal.total == 0,
                    }"
                    >{{ entryData.rowSumTotal.total | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalApproved > 0,
                      'val-negative': entryData.rowSumTotal.totalApproved < 0,
                      'val-zero': entryData.rowSumTotal.totalApproved == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalApproved | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalPending > 0,
                      'val-negative': entryData.rowSumTotal.totalPending < 0,
                      'val-zero': entryData.rowSumTotal.totalPending == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalPending | usdFormat }}</span
                  >
                </span>
              </div>
              <div
                class="entry-totals"
                v-else-if="entryData.laborType == enums.LABOR_TYPE.Labor.value"
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.total > 0,
                      'val-negative': entryData.rowSumTotal.total < 0,
                      'val-zero': entryData.rowSumTotal.total == 0,
                    }"
                    >{{ entryData.rowSumTotal.total | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalApproved > 0,
                      'val-negative': entryData.rowSumTotal.totalApproved < 0,
                      'val-zero': entryData.rowSumTotal.totalApproved == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalApproved | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalPending > 0,
                      'val-negative': entryData.rowSumTotal.totalPending < 0,
                      'val-zero': entryData.rowSumTotal.totalPending == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalPending | usdFormat }}</span
                  >
                </span>
              </div>
              <div
                class="entry-totals"
                v-else-if="
                  entryData.laborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
                  entryData.laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value
                "
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.total > 0,
                      'val-negative': entryData.rowSumTotal.total < 0,
                      'val-zero': entryData.rowSumTotal.total == 0,
                    }"
                    >{{ entryData.rowSumTotal.total | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalApproved > 0,
                      'val-negative': entryData.rowSumTotal.totalApproved < 0,
                      'val-zero': entryData.rowSumTotal.totalApproved == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalApproved | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalPending > 0,
                      'val-negative': entryData.rowSumTotal.totalPending < 0,
                      'val-zero': entryData.rowSumTotal.totalPending == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalPending | usdFormat }}</span
                  >
                </span>
              </div>
              <div
                class="entry-totals"
                v-else-if="entryData.laborType == enums.LABOR_TYPE.NetSummary.value"
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.total > 0,
                      'val-negative': entryData.rowSumTotal.total < 0,
                      'val-zero': entryData.rowSumTotal.total == 0,
                    }"
                    >{{ entryData.rowSumTotal.total | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalApproved > 0,
                      'val-negative': entryData.rowSumTotal.totalApproved < 0,
                      'val-zero': entryData.rowSumTotal.totalApproved == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalApproved | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    :class="{
                      'val-positive': entryData.rowSumTotal.totalPending > 0,
                      'val-negative': entryData.rowSumTotal.totalPending < 0,
                      'val-zero': entryData.rowSumTotal.totalPending == 0,
                    }"
                    >{{ entryData.rowSumTotal.totalPending | usdFormat }}</span
                  >
                </span>
              </div>
            </div>
            <div v-else>
              <div
                class="entry-totals"
                v-if="
                  entryData.rowType == 'UserEntry' ||
                  entryData.laborType == enums.LABOR_TYPE.Hours.value
                "
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    class="mr-1"
                    :class="{
                      'val-positive': entryData.totalHours > 0,
                      'val-negative': entryData.totalHours < 0,
                      'val-zero': entryData.totalHours == 0,
                    }"
                    >{{ entryData.totalHours | fixedFloatFormat }}</span
                  >
                  Hours
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    class="mr-1"
                    :class="{
                      'val-positive': entryData.totalApprovedHours > 0,
                      'val-negative': entryData.totalApprovedHours < 0,
                      'val-zero': entryData.totalApprovedHours == 0,
                    }"
                    >{{ entryData.totalApprovedHours | fixedFloatFormat }}</span
                  >
                  Hours
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    class="mr-1"
                    :class="{
                      'val-positive': entryData.totalPendingHours > 0,
                      'val-negative': entryData.totalPendingHours < 0,
                      'val-zero': entryData.totalPendingHours == 0,
                    }"
                    >{{ entryData.totalPendingHours | fixedFloatFormat }}</span
                  >
                  Hours
                </span>
              </div>
              <div
                class="entry-totals"
                v-else-if="entryData.laborType == enums.LABOR_TYPE.Revenue.value"
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    :class="{
                      'val-positive': entryData.totalRevenue > 0,
                      'val-negative': entryData.totalRevenue < 0,
                      'val-zero': entryData.totalRevenue == 0,
                    }"
                    >{{ entryData.totalRevenue | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    :class="{
                      'val-positive': entryData.totalApprovedRevenue > 0,
                      'val-negative': entryData.totalApprovedRevenue < 0,
                      'val-zero': entryData.totalApprovedRevenue == 0,
                    }"
                    >{{ entryData.totalApprovedRevenue | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    :class="{
                      'val-positive': entryData.totalPendingRevenue > 0,
                      'val-negative': entryData.totalPendingRevenue < 0,
                      'val-zero': entryData.totalPendingRevenue == 0,
                    }"
                    >{{ entryData.totalPendingRevenue | usdFormat }}</span
                  >
                </span>
              </div>
              <div
                class="entry-totals"
                v-else-if="entryData.laborType == enums.LABOR_TYPE.Labor.value"
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    :class="{
                      'val-positive': entryData.totalLabor > 0,
                      'val-negative': entryData.totalLabor < 0,
                      'val-zero': entryData.totalLabor == 0,
                    }"
                    >{{ entryData.totalLabor | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    :class="{
                      'val-positive': entryData.totalApprovedLabor > 0,
                      'val-negative': entryData.totalApprovedLabor < 0,
                      'val-zero': entryData.totalApprovedLabor == 0,
                    }"
                    >{{ entryData.totalApprovedLabor | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    :class="{
                      'val-positive': entryData.totalPendingLabor > 0,
                      'val-negative': entryData.totalPendingLabor < 0,
                      'val-zero': entryData.totalPendingLabor == 0,
                    }"
                    >{{ entryData.totalPendingLabor | usdFormat }}</span
                  >
                </span>
              </div>
              <div
                class="entry-totals"
                v-else-if="
                  entryData.laborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
                  entryData.laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value
                "
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    :class="{
                      'val-positive': entryData.totalExpenses > 0,
                      'val-negative': entryData.totalExpenses < 0,
                      'val-zero': entryData.totalExpenses == 0,
                    }"
                    >{{ entryData.totalExpenses | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    :class="{
                      'val-positive': entryData.totalApprovedExpenses > 0,
                      'val-negative': entryData.totalApprovedExpenses < 0,
                      'val-zero': entryData.totalApprovedExpenses == 0,
                    }"
                    >{{ entryData.totalApprovedExpenses | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    :class="{
                      'val-positive': entryData.totalPendingExpenses > 0,
                      'val-negative': entryData.totalPendingExpenses < 0,
                      'val-zero': entryData.totalPendingExpenses == 0,
                    }"
                    >{{ entryData.totalPendingExpenses | usdFormat }}</span
                  >
                </span>
              </div>
              <div
                class="entry-totals"
                v-else-if="entryData.laborType == enums.LABOR_TYPE.NetSummary.value"
              >
                <span class="total-hours fs-14px font-weight-bold">
                  Total:
                  <span
                    :class="{
                      'val-positive': entryData.total > 0,
                      'val-negative': entryData.total < 0,
                      'val-zero': entryData.total == 0,
                    }"
                    >{{ entryData.total | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-approved">
                  Total Approved:
                  <span
                    :class="{
                      'val-positive': entryData.totalApproved > 0,
                      'val-negative': entryData.totalApproved < 0,
                      'val-zero': entryData.totalApproved == 0,
                    }"
                    >{{ entryData.totalApproved | usdFormat }}</span
                  >
                </span>
                <v-divider vertical></v-divider>
                <span class="total-pending">
                  Total Pending:
                  <span
                    :class="{
                      'val-positive': entryData.totalPending > 0,
                      'val-negative': entryData.totalPending < 0,
                      'val-zero': entryData.totalPending == 0,
                    }"
                    >{{ entryData.totalPending | usdFormat }}</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex align-center" style="gap: 0.5rem">
            <columns-visibility-control
              :defaults="defaultHeaders"
              :withColumnsOrder="true"
              v-model="bigBoardEntryRecordsHeaders"
              :storageKey="storageKey"
              :disabled="treeView"
            />
            <v-text-field
              v-model="search"
              label="Search"
              ref="mainSearch"
              class="table-search-field"
              @focus="mainSearchInFocus = true"
              @blur="mainSearchInFocus = false"
              :style="{
                'max-width':
                  mainSearchInFocus || (search != '' && search != null) ? '200px' : '110px',
              }"
              dense
              solo
              clearable
              hide-details
              prepend-inner-icon="far fa-search"
            >
            </v-text-field>
          </div>
        </div>
        <v-sheet
          color="white"
          class="pa-2 mt-4"
          rounded
          elevation="2"
          v-if="treeView && !isNetSummarySpecial"
        >
          <v-treeview class="records-tree" dense open-on-click transition :items="recordsTree">
            <template v-slot:prepend="{ item }">
              <!-- <i class="fad fa-user-tie tree-client" v-if="item.type == 'Client'"></i> -->
              <div class="main-img mr-2" v-if="item.type == 'Client'">
                <v-img
                  v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
                  :src="item.client.logoUrl"
                  max-height="28"
                  max-width="28"
                  position="left center"
                  contain
                >
                </v-img>
                <v-img
                  v-else
                  src="/img/DNA_Symbol.png"
                  max-height="28"
                  max-width="28"
                  position="left center"
                  contain
                >
                </v-img>
              </div>
              <i class="fad fa-folder tree-project" v-else-if="item.type == 'Project'"></i>
              <i
                class="fad fa-file-signature tree-contract"
                v-else-if="item.type == 'Contract'"
              ></i>
              <i class="fas fa-circle-small tree-record" v-else></i>
            </template>
            <template v-slot:label="{ item }">
              <span class="tree-client" v-if="item.type == 'Client'">
                <span class="main-title font-weight-medium">
                  {{ item.client.name }}
                </span>
                <entity-id
                  class="ml-2"
                  :path="`clients/${item.client.id}`"
                  title="Client"
                  hideLabel
                  tooltipOnTop
                ></entity-id>
              </span>
              <span class="tree-project" v-else-if="item.type == 'Project'">
                <span class="main-title font-weight-medium">
                  {{ item.project.fullName }}
                </span>
                <entity-id
                  class="ml-2"
                  :path="`projects/${item.project.id}`"
                  title="Project"
                  hideLabel
                  tooltipOnTop
                ></entity-id>
              </span>
              <span class="tree-contract" v-else-if="item.type == 'Contract'">
                <div class="d-inline-flex align-center" style="gap: 0.5rem; width: fit-content">
                  <span class="font-weight-medium main-title">{{ item.contract.name }}</span>
                  <v-divider vertical class="mx-1"></v-divider>
                  <code
                    class="mono-font d-inline-flex align-center secondary--text fs-11px"
                    :class="{
                      'brown lighten-4 brown--text': item.contract.isArchived,
                      'blue-grey lighten-5':
                        !item.contract.isArchived &&
                        item.contract.status == enums.CONTRACT_STATUS.Draft.value,
                      'orange lighten-4':
                        !item.contract.isArchived &&
                        item.contract.status == enums.CONTRACT_STATUS.Pending.value,
                      'green lighten-4':
                        !item.contract.isArchived &&
                        item.contract.status == enums.CONTRACT_STATUS.Approved.value,
                    }"
                    style="flex: none; height: 16px; line-height: 0"
                  >
                    #{{ item.contract.id }}
                  </code>
                  <div class="d-inline-flex align-center">
                    <v-chip
                      v-if="item.contract.isArchived"
                      class="px-2"
                      small
                      label
                      :color="'brown'"
                      :text-color="'white'"
                    >
                      <i class="fad fa-archive mr-2"></i>
                      <span class="font-weight-medium" style="line-height: 0"> Archived </span>
                    </v-chip>
                    <contract-status v-else small :status="item.contract.status"></contract-status>
                    <v-tooltip
                      top
                      z-index="9999999"
                      max-width="400px"
                      v-if="item.contract.status == enums.CONTRACT_STATUS.Approved.value"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          text
                          class="ml-1 px-1"
                          min-width="20px"
                          height="24px"
                          small
                          color="green darken-4"
                          @click.stop="displayApprovedMessage(item.contract)"
                        >
                          <i class="fad fa-circle-info fs-16px"></i>
                        </v-btn>
                      </template>
                      <div
                        style="width: 100%"
                        class="py-1"
                        :id="'contract-approved-msg-' + item.contract.id"
                      >
                        <div class="font-weight-bold green--text accent-3">
                          <i class="fas fa-circle-check mr-1" style="width: 20px"></i>Contract
                          Approved
                        </div>
                        <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
                        <div class="d-flex align-center">
                          <i class="fad fa-user mr-1" style="width: 20px"></i>By:
                          <span class="font-weight-bold ml-1">
                            {{ item.contract.approvedBy.firstName }}
                            {{ item.contract.approvedBy.lastName }}
                          </span>
                        </div>
                        <div class="d-flex align-center">
                          <i class="fad fa-calendar-day mr-1" style="width: 20px"></i>On:
                          <span class="font-weight-bold ml-1">
                            <dater :date="item.contract.approvedOn"></dater
                          ></span>
                        </div>
                        <div
                          class="d-flex align-start flex-column"
                          v-if="
                            item.contract.approveNote != null &&
                            item.contract.approveNote.trim() != '<p></p>' &&
                            item.contract.approveNote.trim() != ''
                          "
                        >
                          <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
                          <b class="d-inline-flex align-center">
                            <i class="fad fa-note mr-1" style="width: 20px"></i>Notes:
                          </b>
                          <div
                            class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 mt-2"
                            style="line-break: anywhere"
                            v-html="item.contract.approveNote"
                          ></div>
                        </div>
                      </div>
                    </v-tooltip>
                    <i
                      v-if="item.contract.isLocked"
                      class="fas fa-lock-keyhole secondary--text text--darken-1 fs-16px mx-2"
                    ></i>
                    <i
                      v-else-if="
                        !item.contract.isLocked &&
                        item.contract.status == enums.CONTRACT_STATUS.Approved.value
                      "
                      class="fas fa-lock-keyhole-open deep-orange--text text--darken-1 fs-16px mx-2"
                    ></i>
                    <i
                      v-else-if="
                        !item.contract.isLocked &&
                        item.contract.status == enums.CONTRACT_STATUS.Draft.value
                      "
                      class="fas fa-lock-keyhole-open secondary--text opacity-24 fs-16px mx-2"
                    ></i>
                  </div>
                </div>
              </span>
              <span class="tree-record" v-else>
                <h4 class="d-flex align-center">
                  <v-chip
                    v-if="false"
                    color="blue-grey darken-1"
                    class="pl-1 d-flex"
                    style="font-size: 12px; margin-left: -4px"
                    label
                    dark
                    outlined
                    x-small
                  >
                    <i class="fad fa-calendar-range mr-1" style="line-height: 0; width: 16px"></i>
                    <span class="fs-11px">{{ item.constructionPhase.startDate.substr(0, 7) }}</span>
                  </v-chip>
                  <i v-if="false" class="fad fa-circle fs-8px mx-2" style="line-height: 1"></i>
                  <construction-phase-type
                    class="mr-1"
                    style="line-height: 1"
                    small
                    :type="item.constructionPhase.type"
                  ></construction-phase-type>
                  <span class="font-weight-medium main-title" style="line-height: 0">{{
                    item.constructionPhase.name
                  }}</span>
                  <i class="fad fa-circle fs-8px mx-2" style="line-height: 1"></i>
                  <span
                    class="d-flex flex-column"
                    v-if="item.record.laborType != null && item.record.laborType != 0"
                  >
                    <label
                      class="d-inline-flex blue-grey--text align-center"
                      style="
                        font-weight: 700 !important;
                        font-size: 12px;
                        flex: none;
                        line-height: 1;
                      "
                    >
                      <i class="fas fa-caret-right fs-8px mr-1" style="line-height: 1"></i>
                      {{ getLaborTypeObj(item.record.laborType).desc }}
                    </label>
                    <user-all-selector
                      v-if="item.record.recordType == 0"
                      :users="users"
                      required
                      hideLabel
                      hideDetails
                      hideBorder
                      readonly
                      small
                      responsive
                      :isUsersLoading="isUsersLoading"
                      v-model="item.record.userId"
                    ></user-all-selector>
                    <user-avatar
                      v-else
                      mini
                      showNoUser
                      :noUserText="item.record.placeholderName"
                      noUserIcon="fas fa-question"
                    ></user-avatar>
                  </span>
                  <div class="d-flex align-start flex-column" v-else>
                    <label
                      class="d-inline-flex blue-grey--text align-center"
                      style="
                        font-weight: 700 !important;
                        font-size: 12px;
                        flex: none;
                        line-height: 1;
                      "
                    >
                      <i
                        class="fad mr-1"
                        :class="getExpenseCategoryObj(item.record.category).icon"
                      ></i>
                      {{ getExpenseCategoryObj(item.record.category).text }}
                    </label>
                    <span class="fs-12px">{{ item.record.description }}</span>
                  </div>
                </h4>
              </span>
            </template>
            <template v-slot:append="{ item }">
              <div v-if="item.type == 'Client'" class="d-flex align-center justify-end">
                <span v-if="isDirectExpense">
                  <span v-if="isDirectExpenseCost">
                    <span class="font-weight-bold">Cost: </span
                    >{{ calcClientExpensesCost(item) | usdFormat }}
                  </span>
                  <span v-if="!isDirectExpenseCost">
                    <span class="font-weight-bold">Revenue: </span
                    >{{ calcClientExpensesRevenue(item) | usdFormat }}
                  </span>
                </span>
                <v-row dense v-else style="width: 100%" justify="end">
                  <v-col cols="12" md="7" class="text-right">
                    <span
                      style="white-space: nowrap"
                      class="fs-12px"
                      :class="
                        calcClientHours(item) > 0
                          ? 'info--text'
                          : calcClientHours(item) < 0
                          ? 'pink--text'
                          : 'grey--text'
                      "
                    >
                      <span class="mono-font fs-13px">{{
                        calcClientHours(item) | fixedFloatFormatNoDecimalPoints
                      }}</span>
                      {{ calcClientHours(item) == 1 ? "hr" : "hrs" }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="5" class="text-right">
                    <span style="white-space: nowrap">
                      <span class="font-weight-bold">{{ filterContractStatusLabel }} Total: </span>
                      <span
                        v-if="showLaborRates"
                        :class="
                          calcClientTotalCost(item) > 0
                            ? 'info--text'
                            : calcClientTotalCost(item) < 0
                            ? 'pink--text'
                            : 'grey--text'
                        "
                      >
                        {{ calcClientTotalCost(item) | usdFormat }}
                      </span>
                      <span
                        v-else
                        :class="
                          calcClientTotalRevenue(item) > 0
                            ? 'info--text'
                            : calcClientTotalRevenue(item) < 0
                            ? 'pink--text'
                            : 'grey--text'
                        "
                      >
                        {{ calcClientTotalRevenue(item) | usdFormat }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="item.type == 'Project'" class="d-flex align-center justify-end">
                <span v-if="isDirectExpense">
                  <span v-if="isDirectExpenseCost">
                    <span class="font-weight-bold">Cost: </span
                    >{{ calcProjectExpensesCost(item) | usdFormat }}
                  </span>
                  <span v-if="!isDirectExpenseCost">
                    <span class="font-weight-bold">Revenue: </span
                    >{{ calcProjectExpensesRevenue(item) | usdFormat }}
                  </span>
                </span>
                <v-row dense v-else style="width: 100%" justify="end">
                  <v-col cols="12" md="7" class="text-right">
                    <span
                      style="white-space: nowrap"
                      class="fs-12px"
                      :class="
                        calcProjectHours(item) > 0
                          ? 'info--text'
                          : calcProjectHours(item) < 0
                          ? 'pink--text'
                          : 'grey--text'
                      "
                    >
                      <span class="mono-font fs-13px">{{
                        calcProjectHours(item) | fixedFloatFormatNoDecimalPoints
                      }}</span>
                      {{ calcProjectHours(item) == 1 ? "hr" : "hrs" }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="5" class="text-right">
                    <span style="white-space: nowrap">
                      <span class="font-weight-bold">{{ filterContractStatusLabel }} Total: </span>
                      <span
                        v-if="showLaborRates"
                        :class="
                          calcProjectTotalCost(item) > 0
                            ? 'info--text'
                            : calcProjectTotalCost(item) < 0
                            ? 'pink--text'
                            : 'grey--text'
                        "
                      >
                        {{ calcProjectTotalCost(item) | usdFormat }}
                      </span>
                      <span
                        v-else
                        :class="
                          calcProjectTotalRevenue(item) > 0
                            ? 'info--text'
                            : calcProjectTotalRevenue(item) < 0
                            ? 'pink--text'
                            : 'grey--text'
                        "
                      >
                        {{ calcProjectTotalRevenue(item) | usdFormat }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="item.type == 'Contract'" class="d-flex align-center justify-end">
                <span v-if="isDirectExpense">
                  <span v-if="isDirectExpenseCost">
                    <span class="font-weight-bold">Cost: </span
                    >{{ calcContractExpensesCost(item) | usdFormat }}
                  </span>
                  <span v-if="!isDirectExpenseCost">
                    <span class="font-weight-bold">Revenue: </span
                    >{{ calcContractExpensesRevenue(item) | usdFormat }}
                  </span>
                </span>
                <v-row dense v-else style="width: 100%" justify="end">
                  <v-col cols="12" md="7" class="text-right">
                    <span
                      style="white-space: nowrap"
                      class="fs-12px"
                      :class="
                        calcContractHours(item) > 0
                          ? 'info--text'
                          : calcContractHours(item) < 0
                          ? 'pink--text'
                          : 'grey--text'
                      "
                    >
                      <span class="mono-font fs-13px">{{
                        calcContractHours(item) | fixedFloatFormatNoDecimalPoints
                      }}</span>
                      {{ calcContractHours(item) == 1 ? "hr" : "hrs" }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="5" class="text-right">
                    <span style="white-space: nowrap">
                      <span class="font-weight-bold">{{ filterContractStatusLabel }} Total: </span>
                      <span
                        v-if="showLaborRates"
                        :class="
                          calcContractTotalCost(item) > 0
                            ? 'info--text'
                            : calcContractTotalCost(item) < 0
                            ? 'pink--text'
                            : 'grey--text'
                        "
                      >
                        {{ calcContractTotalCost(item) | usdFormat }}
                      </span>
                      <span
                        v-else
                        :class="
                          calcContractTotalRevenue(item) > 0
                            ? 'info--text'
                            : calcContractTotalRevenue(item) < 0
                            ? 'pink--text'
                            : 'grey--text'
                        "
                      >
                        {{ calcContractTotalRevenue(item) | usdFormat }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </div>
              <div v-else class="d-flex align-center justify-end">
                <span v-if="isDirectExpense">
                  <span v-if="isDirectExpenseCost">
                    <span class="font-weight-bold">Cost: </span>{{ item.laborCost | usdFormat }}
                  </span>
                  <span v-if="!isDirectExpenseCost">
                    <span class="font-weight-bold">Revenue: </span>{{ item.sellCost | usdFormat }}
                  </span>
                </span>
                <v-row dense v-else style="width: 100%" justify="end">
                  <v-col cols="12" md="4" class="text-right">
                    <span v-if="showLaborRates" style="white-space: nowrap">
                      <span class="font-weight-medium opacity-54">Cost Rate: </span>
                      {{ item.laborCost | usdFormat }} <span class="opacity-54">/hr</span>
                    </span>
                    <span v-else style="white-space: nowrap">
                      <span class="font-weight-medium opacity-54">Sell Rate: </span>
                      {{ item.sellCost | usdFormat }} <span class="opacity-54">/hr</span>
                    </span>
                  </v-col>
                  <v-col cols="12" md="3" class="text-right">
                    <span
                      style="white-space: nowrap"
                      class="fs-12px"
                      :class="
                        item.hours > 0 ? 'info--text' : item.hours < 0 ? 'pink--text' : 'grey--text'
                      "
                    >
                      <span class="mono-font fs-13px">{{
                        item.hours | fixedFloatFormatNoDecimalPoints
                      }}</span>
                      {{ item.hours == 1 ? "hr" : "hrs" }}
                    </span>
                  </v-col>
                  <v-col cols="12" md="5" class="text-right">
                    <span style="white-space: nowrap">
                      <span class="font-weight-bold">{{ filterContractStatusLabel }} Total: </span>
                      <span
                        v-if="showLaborRates"
                        :class="
                          item.hours * item.laborCost > 0
                            ? 'info--text'
                            : item.hours * item.laborCost < 0
                            ? 'pink--text'
                            : 'grey--text'
                        "
                      >
                        {{ (item.hours * item.laborCost) | usdFormat }}
                      </span>
                      <span
                        v-else
                        :class="
                          item.hours * item.sellCost > 0
                            ? 'info--text'
                            : item.hours * item.sellCost < 0
                            ? 'pink--text'
                            : 'grey--text'
                        "
                      >
                        {{ (item.hours * item.sellCost) | usdFormat }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-treeview>
        </v-sheet>
        <big-board-entry-details-table
          v-else-if="!treeView && !isNetSummarySpecial"
          :headers="tableHeader"
          :records-list="filteredRecordsList"
          :entry-data="entryData"
          :type="type"
          :users="users"
          :isUsersLoading="isUsersLoading"
          :rowTotalMode="rowTotalMode"
        ></big-board-entry-details-table>
        <div v-else class="d-flex flex-column align-center">
          <h3 class="mt-8 text-center brown white--text d-inline-flex px-2 rounded">
            Details list for this entry type will be coming soon!
          </h3>
        </div>
      </div>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-2" v-if="!isFetching" @click="closeUpdateSlideout()" color="secondary">
          <i class="fal fa-xmark mr-2"></i> Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import ContractStatus from "../../Contracts/components/ContractStatus.vue";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import contractsService from "../../Contracts/services/contracts-service";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import ExportEntryDetailsToExcel from "./ExportEntryDetailsToExcel.vue";
import BigBoardEntryDetailsTable from "./BigBoardEntryDetailsTable.vue";
import BigBoardContractsFilter from "./BigBoardContractsFilter.vue";
import ConstructionPhaseType from "../../Contracts/components/ConstructionPhaseType.vue";

export default {
  name: "big-board-entry-details",
  components: {
    PanelSizeControl,
    UserAllSelector,
    ExportEntryDetailsToExcel,
    BigBoardEntryDetailsTable,
    ConstructionPhaseType,
    ContractStatus,
    BigBoardContractsFilter,
  },
  data() {
    return {
      enums,
      perms,
      storageKey: "BigBoardEntryDetails",
      isFetching: false,
      filterContractStatus: null,
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          valid: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
      treeView: false,
      search: null,
      mainSearchInFocus: false,
      bigBoardEntryRecordsHeaders: [
        {
          text: "Project",
          value: "project.fullName",
          width: "300px",
          sortable: true,
          hidable: true,
          hidden: false,
          disabled: true,
        },
        {
          text: "Client",
          value: "client.name",
          width: "100px",
          sortable: true,
          hidable: true,
          hidden: false,
          disabled: true,
        },
        {
          text: "Contract Id",
          value: "contract.id",
          cellClass: "pl-0 pr-1",
          class: "px-1",
          width: "90px",
          sortable: true,
          hidable: false,
          hidden: false,
          disabled: true,
        },
        {
          text: "Contract Name",
          value: "contract.name",
          width: "200px",
          sortable: true,
          hidable: false,
          hidden: false,
          disabled: true,
        },
        {
          text: "Contract Status",
          value: "contract.status",
          width: "145px",
          sortable: true,
          hidable: true,
          hidden: false,
          disabled: true,
        },
        {
          text: "Target Month",
          value: "constructionPhase.startDate",
          width: "125px",
          sortable: true,
          hidable: true,
          hidden: false,
          disabled: true,
        },
        {
          text: "Construction Phase",
          value: "constructionPhase.name",
          width: "180px",
          sortable: true,
          hidable: true,
          hidden: false,
          disabled: true,
        },
        {
          text: "Record Details",
          value: "constructionPhaseRecord",
          width: "240px",
          sortable: false,
          hidable: true,
          hidden: false,
          disabled: true,
        },
        {
          text: "Expense Category",
          value: "category",
          width: "164px",
          sortable: true,
          hidable: true,
          hidden: false,
          disabled: true,
        },
        {
          text: "Expense Description",
          value: "description",
          width: "172px",
          sortable: true,
          hidable: true,
          hidden: false,
          disabled: true,
        },
        {
          text: "Hours",
          value: "hours",
          width: "87px",
          sortable: true,
          hidable: false,
          hidden: false,
          disabled: true,
        },
        {
          text: "Sell Rate",
          value: "sellCost",
          width: "110px",
          sortable: true,
          hidable: false,
          hidden: false,
          disabled: true,
        },
        {
          text: "Cost Rate",
          value: "laborCost",
          width: "110px",
          sortable: true,
          hidable: false,
          hidden: false,
          disabled: true,
        },
        {
          text: "Contract Total",
          value: "total",
          width: "140px",
          sortable: true,
          hidable: false,
          hidden: false,
          disabled: true,
        },
      ],
      recordsList: [],
      rowTotalMode: false,
      dateRangeStart: null,
      dateRangeEnd: null,
      entryData: null,
      entryDetailsOptions: {
        projectId: null,
        userId: null,
        startingDate: null,
        placeHolderName: null,
        laborType: null,
        recordType: null,
        useCostRate: false,
      },
      contracts: [],
      defaultHeaders: [],
    };
  },
  mounted() {
    this.defaultHeaders = this.cloneDeep(this.bigBoardEntryRecordsHeaders);
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
    isUsersLoading: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Number,
      default: null,
    },
  },
  computed: {
    primaryTitle() {
      if (this.entryData.rowType == "UserEntry") {
        if (this.entryData.recordType == 0) {
          let user = this.users.find((e) => e.id == this.entryData.userId);
          return user.firstName + " " + user.lastName;
        } else return this.entryData.placeholderName;
      } else return this.getLaborTypeObj(this.entryData.laborType).desc;
    },
    secondaryTitle() {
      if (this.entryData.rowType == "UserEntry")
        return this.getLaborTypeObj(this.entryData.laborType).desc;
      else return this.entryData.title;
    },
    recordsTree() {
      var tree = [];

      if (this.filteredRecordsList.length >= 0) {
        this.filteredRecordsList.forEach((record) => {
          //Find the Client
          var client_index = tree.findIndex((c) => c.id == record.client.id);
          if (client_index == -1) {
            //Client Not Found
            tree.push({
              id: record.client.id,
              client: record.client,
              type: "Client",
              totals: 0,
              children: [
                {
                  id: record.project.id,
                  project: record.project,
                  type: "Project",
                  totals: 0,
                  children: [
                    {
                      id: record.contract.id,
                      contract: record.contract,
                      type: "Contract",
                      totals: 0,
                      children: [record],
                    },
                  ],
                },
              ],
            });
          } else {
            //Client Found
            //find the Project
            var project_index = tree[client_index].children.findIndex(
              (p) => p.id == record.project.id
            );
            if (project_index == -1) {
              //Project Not Found
              tree[client_index].children.push({
                id: record.project.id,
                project: record.project,
                type: "Project",
                totals: 0,
                children: [
                  {
                    id: record.contract.id,
                    contract: record.contract,
                    type: "Contract",
                    totals: 0,
                    children: [record],
                  },
                ],
              });
            } else {
              //Project Found
              //find the Contract
              var contract_index = tree[client_index].children[project_index].children.findIndex(
                (c) => c.id == record.contract.id
              );
              if (contract_index == -1) {
                //Contract Not Found
                tree[client_index].children[project_index].children.push({
                  id: record.contract.id,
                  contract: record.contract,
                  type: "Contract",
                  totals: 0,
                  children: [record],
                });
              } else
                tree[client_index].children[project_index].children[contract_index].children.push(
                  record
                );
            }
          }
        });
      }

      tree.sort((clientA, clientB) => clientA.client.name.localeCompare(clientB.client.name));
      for (let i = 0; i < tree.length; i++) {
        const client = tree[i];
        client.children.sort((projectA, projectB) =>
          projectA.project.fullName.localeCompare(projectB.project.fullName)
        );
        for (let j = 0; j < client.children.length; j++) {
          const project = client.children[j];
          project.children.sort((contractA, contractB) =>
            contractA.contract.name.localeCompare(contractB.contract.name)
          );

          for (let k = 0; k < project.children.length; k++) {
            const contract = project.children[k];
            contract.children.sort((recordA, recordB) =>
              recordA.constructionPhase.name.localeCompare(recordB.constructionPhase.name)
            );
          }
        }
      }

      return tree;
    },
    isNetSummarySpecial() {
      return (
        this.entryData.laborType == enums.LABOR_TYPE.NetSummary.value &&
        (this.entryData.rowType == "TotalNetCost" ||
          this.entryData.rowType == "TotalNetRevenue" ||
          this.entryData.rowType == "TotalNet")
      );
    },
    isTotalNetCost() {
      return (
        this.entryData.laborType == enums.LABOR_TYPE.NetSummary.value &&
        this.entryData.rowType == "TotalNetCost"
      );
    },
    isTotalNetRevenue() {
      return (
        this.entryData.laborType == enums.LABOR_TYPE.NetSummary.value &&
        this.entryData.rowType == "TotalNetRevenue"
      );
    },
    isTotalNet() {
      return (
        this.entryData.laborType == enums.LABOR_TYPE.NetSummary.value &&
        this.entryData.rowType == "TotalNet"
      );
    },
    isDirectExpense() {
      return (
        this.entryData.laborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
        this.entryData.laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value ||
        this.entryData.originalLaborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
        this.entryData.originalLaborType == enums.LABOR_TYPE.DirectExpensesRevenue.value
      );
    },
    isDirectExpenseCost() {
      return this.entryData.isCost;
    },
    showLaborRates() {
      return (
        (this.isMyBoard && this.entryData.rowType == "UserEntry") ||
        this.entryData.laborType == enums.LABOR_TYPE.Labor.value ||
        this.entryData.originalLaborType == enums.LABOR_TYPE.Labor.value
      );
    },
    activeBoard() {
      return this.getEnumMember(enums.BIG_BOARD_TYPE, this.type);
    },
    isBigBoard() {
      return this.type == enums.BIG_BOARD_TYPE.BigBoard.value;
    },
    isSmallBoard() {
      return this.type == enums.BIG_BOARD_TYPE.SmallBoard.value;
    },
    isMyBoard() {
      return this.type == enums.BIG_BOARD_TYPE.MyBoard.value;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "75%";
    },
    filterContractStatusLabel() {
      if (this.filterContractStatus == enums.CONTRACT_STATUS.Pending.value) return "Pending";
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Approved.value) return "Approved";
      return "";
    },
    filteredRecordsList() {
      if (this.filterContractStatus == enums.CONTRACT_STATUS.Pending.value) {
        return this.recordsList.filter(
          (r) =>
            r.contract.status == enums.CONTRACT_STATUS.Draft.value ||
            r.contract.status == enums.CONTRACT_STATUS.Pending.value
        );
      } else if (this.filterContractStatus == enums.CONTRACT_STATUS.Approved.value) {
        return this.recordsList.filter(
          (r) => r.contract.status == enums.CONTRACT_STATUS.Approved.value
        );
      }
      return this.recordsList.filter(this.searchFilterRecord);
    },
    filteredHeaders() {
      return this.generateHeaders();
    },
    tableHeader() {
      return this.filteredHeaders.filter((elm) => !elm.hidden);
    },
  },
  methods: {
    searchFilterRecord(item) {
      if (this.search == null || this.search == "") return true;
      var term = this.search.toLowerCase();
      return (
        item.project.fullName.toLowerCase().indexOf(term) > -1 ||
        item.client.name.toLowerCase().indexOf(term) > -1 ||
        item.contract.id.toString().toLowerCase() == term ||
        item.contract.name.toLowerCase().indexOf(term) > -1 ||
        item.constructionPhase.name.toLowerCase().indexOf(term) > -1 ||
        item.constructionPhase.startDate.substr(0, 7).toLowerCase().indexOf(term) > -1 ||
        item.record.description?.toLowerCase().indexOf(term) > -1 ||
        item.record.placeholderName?.toLowerCase().indexOf(term) > -1 ||
        item.description?.toLowerCase().indexOf(term) > -1 ||
        (item.category != null &&
          this.getExpenseCategoryObj(item.category).text.toLowerCase().indexOf(term) > -1) ||
        //hours
        (!this.isDirectExpense && item.hours.toString().toLowerCase().indexOf(term) > -1) ||
        //cost rate
        (!this.isDirectExpense &&
          this.showLaborRates &&
          (item.laborCost.toString().toLowerCase().indexOf(term) > -1 ||
            this.$options.filters.usdFormat(item.laborCost).toString().toLowerCase().indexOf(term) >
              -1)) ||
        //sell rate
        (!this.isDirectExpense &&
          !this.showLaborRates &&
          (item.sellCost.toString().toLowerCase().indexOf(term) > -1 ||
            this.$options.filters.usdFormat(item.sellCost).toString().toLowerCase().indexOf(term) >
              -1)) ||
        //total from cost
        (!this.isDirectExpense &&
          this.showLaborRates &&
          ((item.hours * item.laborCost).toString().toLowerCase().indexOf(term) > -1 ||
            this.$options.filters
              .usdFormat(item.hours * item.laborCost)
              .toString()
              .toLowerCase()
              .indexOf(term) > -1)) ||
        //total from sell
        (!this.isDirectExpense &&
          !this.showLaborRates &&
          ((item.hours * item.sellCost).toString().toLowerCase().indexOf(term) > -1 ||
            this.$options.filters
              .usdFormat(item.hours * item.sellCost)
              .toString()
              .toLowerCase()
              .indexOf(term) > -1)) ||
        //expenses cost
        (this.isDirectExpense &&
          this.isDirectExpenseCost &&
          (item.laborCost.toString().toLowerCase().indexOf(term) > -1 ||
            this.$options.filters.usdFormat(item.laborCost).toString().toLowerCase().indexOf(term) >
              -1)) ||
        //expenses revenue
        (this.isDirectExpense &&
          !this.isDirectExpenseCost &&
          (item.sellCost.toString().toLowerCase().indexOf(term) > -1 ||
            this.$options.filters.usdFormat(item.sellCost).toString().toLowerCase().indexOf(term) >
              -1))
      );
    },
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
    getExpenseCategoryObj(val) {
      return this.getEnumMember(enums.DIRECT_EXPENSE_CATEGORY, val);
    },
    //////////////////// calc Contract ////////////////////
    calcContractExpensesCost(item) {
      return item.children.reduce((total, cur) => {
        return total + cur.laborCost;
      }, 0);
    },
    calcContractExpensesRevenue(item) {
      return item.children.reduce((total, cur) => {
        return total + cur.sellCost;
      }, 0);
    },
    calcContractHours(item) {
      return item.children.reduce((total, cur) => {
        return total + cur.hours;
      }, 0);
    },
    calcContractTotalCost(item) {
      return item.children.reduce((total, cur) => {
        return total + cur.hours * cur.laborCost;
      }, 0);
    },
    calcContractTotalRevenue(item) {
      return item.children.reduce((total, cur) => {
        return total + cur.hours * cur.sellCost;
      }, 0);
    },
    //////////////////// calc Project ////////////////////
    calcProjectExpensesCost(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcContractExpensesCost(cur);
      }, 0);
    },
    calcProjectExpensesRevenue(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcContractExpensesRevenue(cur);
      }, 0);
    },
    calcProjectHours(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcContractHours(cur);
      }, 0);
    },
    calcProjectTotalCost(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcContractTotalCost(cur);
      }, 0);
    },
    calcProjectTotalRevenue(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcContractTotalRevenue(cur);
      }, 0);
    },
    //////////////////// calc Client ////////////////////
    calcClientExpensesCost(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcProjectExpensesCost(cur);
      }, 0);
    },
    calcClientExpensesRevenue(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcProjectExpensesRevenue(cur);
      }, 0);
    },
    calcClientHours(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcProjectHours(cur);
      }, 0);
    },
    calcClientTotalCost(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcProjectTotalCost(cur);
      }, 0);
    },
    calcClientTotalRevenue(item) {
      return item.children.reduce((total, cur) => {
        return total + this.calcProjectTotalRevenue(cur);
      }, 0);
    },
    fillContractData() {
      for (let i = 0; i < this.recordsList.length; i++) {
        let record = this.recordsList[i];
        const contract = this.getContract(record.contractId);
        record.contract = contract;
        record.project = contract.project;
        record.client = contract.project.client;
        record.constructionPhase = this.getConstructionPhase(
          record.contractId,
          record.constructionPhaseId
        );
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "projectId") {
          this.$log(
            "contractId",
            isDesc[0],
            this.getContract(a.contractId).project.fullName,
            " == ",
            this.getContract(b.contractId).project.fullName,
            this.getContract(a.contractId)
              .project.fullName.toLowerCase()
              .localeCompare(this.getContract(b.contractId).project.fullName.toLowerCase())
          );
          if (!isDesc[0]) {
            return this.getContract(a.contractId)
              .project.fullName.toLowerCase()
              .localeCompare(this.getContract(b.contractId).project.fullName.toLowerCase());
          } else {
            return this.getContract(b.contractId)
              .project.fullName.toLowerCase()
              .localeCompare(this.getContract(b.contractId).project.fullName.toLowerCase());
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
            } else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    viewClient(id) {
      const path = `/clients/${id}`;
      window.open(path, "_blank");
    },
    displayApprovedMessage(contract) {
      var approvedMsg = document
        .querySelector("#contract-approved-msg-" + contract.id)
        .cloneNode(true);
      var devElms = approvedMsg.querySelectorAll(".v-divider");
      devElms.forEach((dev) => {
        dev.className = "v-divider theme--light my-2 mx-0";
      });
      this.$dialog
        .info({
          title: `'${contract.name}' is approved!`,
          text: approvedMsg.innerHTML,
          color: "success",
        })
        .then((res) => {});
    },
    getContract(contractId) {
      return this.contracts.find((c) => c.id == contractId);
    },
    getConstructionPhase(contractId, constructionPhaseId) {
      var contract = this.getContract(contractId);
      if (contract) {
        var constructionPhase = contract.constructionPhases.find(
          (c) => c.id == constructionPhaseId
        );
        return constructionPhase;
      } else return null;
    },
    fetchBigBoardEntryDetails(contractIds) {
      this.isFetching = true;
      contractsService
        .getByListIds({ ids: contractIds })
        .then((resp) => {
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.contracts = this.cloneDeep(resp.data);
          this.fillContractData();
        })
        .catch((err) => {
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
      this.contracts = [];
      this.entryData = null;
    },
    open(entryData, projectId, recordsList = []) {
      this.rowTotalMode = false;
      // this.$log("entry Details open", entryData, recordsList);
      this.treeView = false;
      this.entryData = this.cloneDeep(entryData);
      this.recordsList = this.cloneDeep(recordsList);

      this.entryDetailsOptions = {
        projectId: projectId,
        userId: this.entryData.userId,
        date: `${this.entryData.parentDate.month} ${this.entryData.parentDate.year}`,
        placeholderName: this.entryData.placeholderName,
        laborType: this.entryData.laborType,
        recordType: this.entryData.recordType,
        useCostRate: this.isMyBoard,
      };

      var idsList = [];
      if (this.entryData && this.recordsList && this.recordsList.length > 0) {
        idsList = this.recordsList.map((r) => r.contractId);
        idsList = [...new Set(idsList)];
      }
      this.prepareHeaders();
      this.fetchBigBoardEntryDetails(idsList);
      this.slideouts.update.active = true;
    },
    openRowTotal(item, dateRangeStart, dateRangeEnd, projectId, recordsList = []) {
      this.rowTotalMode = true;
      this.dateRangeStart = item[`hours-${dateRangeStart}`].parentDate;
      this.dateRangeEnd = item[`hours-${dateRangeEnd}`].parentDate;
      var entryData = item;
      this.treeView = false;
      this.entryData = this.cloneDeep(entryData);
      this.recordsList = this.cloneDeep(recordsList);
      //${dateRangeStart.month}, ${dateRangeStart.year}
      this.entryDetailsOptions = {
        projectId: projectId,
        userId: item.userId,
        date: `${this.dateRangeStart.month} ${this.dateRangeStart.year} : ${this.dateRangeEnd.month} ${this.dateRangeEnd.year}`,
        placeholderName: item.placeholderName,
        laborType: item.laborType,
        recordType: item.recordType,
        useCostRate: this.isMyBoard,
      };

      var idsList = [];
      if (this.entryData && this.recordsList && this.recordsList.length > 0) {
        idsList = this.recordsList.map((r) => r.contractId);
        idsList = [...new Set(idsList)];
      }
      this.prepareHeaders();
      this.fetchBigBoardEntryDetails(idsList);
      this.slideouts.update.active = true;
    },
    prepareHeaders() {
      this.bigBoardEntryRecordsHeaders.forEach((h) => {
        if (this.filteredHeaders.some((fh) => fh.value == h.value)) h.disabled = false;
        else h.disabled = true;
      });
    },
    generateHeaders() {
      var headersList = [];
      if (this.isDirectExpense && this.isDirectExpenseCost)
        headersList = this.bigBoardEntryRecordsHeaders.filter(
          (h) =>
            h.value != "sellCost" &&
            h.value != "hours" &&
            h.value != "total" &&
            h.value != "constructionPhaseRecord"
        );
      else if (this.isDirectExpense && !this.isDirectExpenseCost)
        headersList = this.bigBoardEntryRecordsHeaders.filter(
          (h) =>
            h.value != "laborCost" &&
            h.value != "hours" &&
            h.value != "total" &&
            h.value != "constructionPhaseRecord"
        );
      else if (this.showLaborRates)
        headersList = this.bigBoardEntryRecordsHeaders.filter(
          (h) => h.value != "sellCost" && h.value != "category" && h.value != "description"
        );
      else
        headersList = this.bigBoardEntryRecordsHeaders.filter(
          (h) => h.value != "laborCost" && h.value != "category" && h.value != "description"
        );

      if (!this.rowTotalMode) {
        headersList = headersList.filter((h) => h.value != "constructionPhase.startDate");
      }

      var sellCostHeader = headersList.find((h) => h.value == "sellCost");
      if (sellCostHeader != null && this.isDirectExpense) sellCostHeader.text = "Revenue";
      else if (sellCostHeader != null && !this.isDirectExpense) sellCostHeader.text = "Sell Rate";

      var sellCostHeader = headersList.find((h) => h.value == "laborCost");
      if (sellCostHeader != null && this.isDirectExpense) sellCostHeader.text = "Cost";
      else if (sellCostHeader != null && !this.isDirectExpense) sellCostHeader.text = "Cost Rate";

      return headersList;
    },
  },
};
</script>
<style lang="scss">
.records-tree {
  .v-treeview-node__prepend {
    margin: 0 !important;
  }

  & > .v-treeview-node {
    //Client
    border-radius: 0.5rem;
    border: 1px solid rgba($shades-black, 0.08);
    //   .v-treeview-node__root {}

    &:not(:last-child):after {
      content: "";
      width: 100%;
      height: 1.25px;
      background: rgba($shades-black, 0.1);
      display: block;
    }
  }
  .v-treeview-node__children {
    //Project
    .v-treeview-node__children {
      //Contract
      .v-treeview-node__children {
        //Record
        .v-treeview-node:not(:last-child) .v-treeview-node__content {
          border-bottom: 1px solid rgba($shades-black, 0.1);
          min-height: 40px;
        }
        .v-treeview-node__append {
          width: 44%;
        }
      }
    }
  }
  .v-treeview-node__children {
    padding: 0.35rem;
  }

  .v-treeview-node__append {
    width: 44%;
  }

  .v-treeview-node {
    border: 1px solid rgba($deep-purple-base, 0);
    border-radius: 0.5rem;

    & > .v-treeview-node__root > .v-treeview-node__content {
      & > .v-treeview-node__prepend > .main-img {
        border: 1.5px solid rgba($deep-purple-base, 0);
        border-radius: 0.5rem;
        overflow: hidden;
      }
    }

    &:hover {
      background: rgba($deep-purple-base, 0.06);
      border: 1px solid rgba($deep-purple-base, 0.24);

      & > .v-treeview-node__root > .v-treeview-node__content {
        & > .v-treeview-node__prepend > i {
          color: $deep-purple-base;
        }

        & > .v-treeview-node__prepend > .main-img {
          border: 1.5px solid rgba($deep-purple-base, 1);
        }

        & > .v-treeview-node__label .main-title {
          color: $deep-purple-base;
        }
      }
    }
  }
}
.entry-totals {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  & > span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > span {
      font-weight: 500;
      padding: 0rem 0.35rem;
      border-radius: 0.25rem;
      gap: 0.5rem;
      margin-left: 0.5rem;
    }

    i {
      font-size: 10px;
      margin-right: 8px;
    }
  }

  .val-positive > span {
    color: $info-base;
    background: rgba($info-base, 0.08);
  }

  .val-negative > span {
    color: $accent-base;
    background: rgba($accent-base, 0.08);
  }

  .val-zero > span {
    color: rgba($shades-black, 0.4);

    i {
      color: rgba($shades-black, 0.4);
    }
  }
}

.big-board-entry-details
  .big-board-contracts-filter
  .v-input--radio-group--row
  .v-input--radio-group__input {
  flex-wrap: nowrap !important;
}
</style>
