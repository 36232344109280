<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <div class="d-flex align-center">
      <page-title title="Manage Direct Expenses" :badge="this.total" />
    </div>
    <v-row class="mt-0 mb-1" justify="space-between" align="center">
      <v-col sm="12" md="3" cols="12" class="d-flex align-start" style="gap: 0.5rem">
        <date-range-month
          class="mr-1"
          actions-menu
          :start-date.sync="options.startDate"
          :end-date.sync="options.endDate"
        ></date-range-month>
      </v-col>
      <v-col
        md="9"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <filter-manager
          ref="filterManager"
          v-model="selectedFilters"
          :options.sync="options"
        ></filter-manager>
        <direct-expenses-export-excel :options="options"></direct-expenses-export-excel>
        <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          hide-details
          clearable
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 direct-expenses-table table-sticky-header-exclude-footer"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.contract.project.client.name`]="{ item }">
        <div class="my-1">
          <client-card :client="item.contract.project.client" hide-name square></client-card>
        </div>
      </template>

      <template v-slot:[`item.contract.project.fullName`]="{ item }">
        <a
          class="project-link"
          :href="`/projects/${item.contract.project.id}`"
          target="_blank"
          @click.stop
        >
          {{ item.contract.project.fullName }}
        </a>
      </template>

      <template v-slot:[`item.contract.project.leadAC`]="{ item }">
        <user-avatar :user="item.contract.project.leadAC" icon showNoUser></user-avatar>
      </template>

      <template v-slot:[`item.contract.name`]="{ item }">
        <h4 class="d-flex align-center" style="gap: 0.5rem">
          <code
            class="mono-font secondary--text fs-12px"
            :class="{
              'brown lighten-4 brown--text': item.contract.isArchived,
              'blue-grey lighten-5':
                !item.contract.isArchived &&
                item.contract.status == enums.CONTRACT_STATUS.Draft.value,
              'orange lighten-4':
                !item.contract.isArchived &&
                item.contract.status == enums.CONTRACT_STATUS.Pending.value,
              'green lighten-4':
                !item.contract.isArchived &&
                item.contract.status == enums.CONTRACT_STATUS.Approved.value,
            }"
            style="flex: none"
          >
            #{{ item.contract.id }}
          </code>
          {{ item.contract.name }}
        </h4>
      </template>

      <template v-slot:[`item.contract.status`]="{ item }">
        <div class="d-inline-flex align-center pl-1">
          <i
            v-if="item.contract.isLocked"
            class="fas fa-lock-keyhole secondary--text text--darken-1 fs-16px ml-1 mr-2"
          ></i>
          <i
            v-else-if="
              !item.contract.isLocked &&
              item.contract.status == enums.CONTRACT_STATUS.Approved.value
            "
            class="fas fa-lock-keyhole-open deep-orange--text text--darken-1 fs-16px ml-1 mr-2"
          ></i>
          <i
            v-else-if="
              !item.contract.isLocked && item.contract.status == enums.CONTRACT_STATUS.Draft.value
            "
            class="fas fa-lock-keyhole-open secondary--text opacity-24 fs-16px ml-1 mr-2"
          ></i>
          <v-chip
            v-if="item.contract.isArchived"
            class="px-2 ml-1"
            small
            label
            :color="'brown'"
            :text-color="'white'"
          >
            <i class="fad fa-archive mr-2"></i>
            <span class="font-weight-medium" style="line-height: 0"> Archived </span>
          </v-chip>
          <contract-status
            v-else
            class="ml-2"
            small
            :status="item.contract.status"
          ></contract-status>
          <v-tooltip
            top
            z-index="9999999"
            max-width="400px"
            v-if="item.contract.status == enums.CONTRACT_STATUS.Approved.value"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                class="ml-1 px-1"
                min-width="20px"
                height="24px"
                small
                color="green darken-4"
                @click.stop="displayApprovedMessage(item.contract)"
              >
                <i class="fad fa-circle-info fs-16px"></i>
              </v-btn>
            </template>
            <div style="width: 100%" class="py-1" :id="'contract-approved-msg-' + item.contract.id">
              <div class="font-weight-bold green--text accent-3">
                <i class="fas fa-circle-check mr-1" style="width: 20px"></i>Contract Approved
              </div>
              <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
              <div class="d-flex align-center">
                <i class="fad fa-user mr-1" style="width: 20px"></i>By:
                <span class="font-weight-bold ml-1">
                  {{ item.contract.approvedBy.firstName }}
                  {{ item.contract.approvedBy.lastName }}
                </span>
              </div>
              <div class="d-flex align-center">
                <i class="fad fa-calendar-day mr-1" style="width: 20px"></i>On:
                <span class="font-weight-bold ml-1">
                  <dater :date="item.contract.approvedOn"></dater
                ></span>
              </div>
              <div
                class="d-flex align-start flex-column"
                v-if="
                  item.contract.approveNote != null &&
                  item.contract.approveNote.trim() != '<p></p>' &&
                  item.contract.approveNote.trim() != ''
                "
              >
                <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
                <b class="d-inline-flex align-center">
                  <i class="fad fa-note mr-1" style="width: 20px"></i>Notes:
                </b>
                <div
                  class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 mt-2"
                  style="line-break: anywhere"
                  v-html="item.contract.approveNote"
                ></div>
              </div>
            </div>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <h4 class="d-flex align-center" style="gap: 0.5rem">
          <construction-phase-type :type="item.type"></construction-phase-type>
          {{ item.name }}
        </h4>
      </template>

      <template v-slot:[`item.startDate`]="{ item }">
        <h4>{{ item.startDate.substr(0, 7) }}</h4>
      </template>

      <template v-slot:[`item.totalDirectExpensesCost`]="{ item }">
        <span
          class="fs-12px"
          :class="
            item.totalDirectExpensesCost > 0
              ? 'info--text'
              : item.totalDirectExpensesCost < 0
              ? 'pink--text'
              : 'grey--text'
          "
        >
          <span class="mono-font fs-13px">{{ item.totalDirectExpensesCost | usdFormat }}</span>
        </span>
      </template>

      <template v-slot:[`item.totalDirectExpensesRevenue`]="{ item }">
        <span
          class="fs-12px"
          :class="
            item.totalDirectExpensesRevenue > 0
              ? 'info--text'
              : item.totalDirectExpensesRevenue < 0
              ? 'pink--text'
              : 'grey--text'
          "
        >
          <span class="mono-font fs-13px">{{ item.totalDirectExpensesRevenue | usdFormat }}</span>
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <!-- <v-list-item
              @click="update(item.id)"
              v-if="$has(perms.ResourceShiftRequests.Update)"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item @click="review(item.id)" v-if="$has(perms.ProjectContracts.View)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">fas fa-eye</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text"> View Contract </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import directExpensesAPI from "../services/direct-expenses-service.js";
import directExpensesHeader from "../config/tables/manage-direct-expenses-header.js";
import DateRangeMonth from "../../Shared/components/DateRangeMonth.vue";
import ClientCard from "../../Companies/Clients/components/ClientCard.vue";
import ContractStatus from "../components/ContractStatus.vue";
import ConstructionPhaseType from "../components/ConstructionPhaseType.vue";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  ClientFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";
import DirectExpensesExportExcel from "../components/DirectExpensesExportExcel.vue";

export default {
  components: {
    DateRangeMonth,
    ClientCard,
    ContractStatus,
    ConstructionPhaseType,
    FilterManager,
    DirectExpensesExportExcel,
  },
  data() {
    return {
      perms: perms,
      enums: enums,
      storageKey: "ManageDirectExpenses",
      entities: [],
      selected: {},
      total: 0,
      directExpensesTimerId: null,
      valid: false,
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["startDate"],
        sortDesc: [true],
        startDate: null,
        endDate: null,
        leadACIds: [],
        clientIds: [],
      },
      selectedFilters: [],
      modals: {
        update: false,
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: directExpensesHeader,
    };
  },
  props: {},
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
    this.options.startDate = this.$getFromLocal(
      `manage-direct-expenses-range-startDate`,
      false,
      this.formatDate(new Date(new Date().getFullYear(), 0, 1)).substr(0, 7)
    );
    this.options.endDate = this.$getFromLocal(
      `manage-direct-expenses-range-endDate`,
      false,
      this.formatDate(new Date(new Date().getFullYear(), 11, 31)).substr(0, 7)
    );
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");

    var leadACFilterSettings = new UserFilterSettings();
    leadACFilterSettings.title = "Lead AC";
    leadACFilterSettings.model = "LeadACIds";
    leadACFilterSettings.ref = "LeadACFilter";

    var clientFilterSettings = new ClientFilterSettings();
    this.selectedFilters = [leadACFilterSettings, clientFilterSettings];
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    rowClicked(shiftRequest) {},
    getDataDebounced() {
      this.$log("getDataDebounced");
      if (this.directExpensesTimerId == null) {
        this.$log("START directExpensesTimerId");
        this.directExpensesTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.directExpensesTimerId);

      // delay new call 400ms
      this.directExpensesTimerId = setTimeout(() => {
        this.$log("directExpensesTimerId");
        this.getData();
      }, 400);
    },
    getData() {
      this.$log("getData()", this.$clean(this.options, true));
      this.loadingStates.table = true;
      this.$backToTop(0, document.querySelector(".direct-expenses-table .v-data-table__wrapper"));
      directExpensesAPI
        .getDirectExpensesByRange(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    checkRouteQuery(evt) {
      this.$log("checkRouteQuery", this.$route.query.pq, evt);
      if (this.$route.query.pq) this.options.search = this.$route.query.pq;
      else this.options.search = null;
    },
    del(shiftRequest) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this Resource Shift Request?`,
          title: `Delete Resource Shift Request?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return directExpensesAPI
                  .delete(shiftRequest.id)
                  .then(() => {
                    this.onResourceShiftRequestDeleted(shiftRequest.id);
                    this.$dialog.notify.success("Resource Shift Request deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch(() => {
                    this.$dialog.notify.error(
                      "An error occurred during deleting the resource shift request!",
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onResourceShiftRequestDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    add() {
      this.modals.add = true;
      setTimeout(() => {
        this.$refs.updateForm.resetValidation();
      });
      this.selected = {};
    },
    isLinkedParentPhase(phase) {
      return phase != null && phase.isLinked && phase.parentId == null;
    },
    isLinkedChildPhase(phase) {
      return phase != null && phase.isLinked && phase.parentId != null;
    },
    displayApprovedMessage(contract) {
      var approvedMsg = document
        .querySelector("#contract-approved-msg-" + contract.id)
        .cloneNode(true);
      var devElms = approvedMsg.querySelectorAll(".v-divider");
      devElms.forEach((dev) => {
        dev.className = "v-divider theme--light my-2 mx-0";
      });
      this.$dialog
        .info({
          title: `'${contract.name}' is approved!`,
          text: approvedMsg.innerHTML,
          color: "success",
        })
        .then((res) => {});
    },
    stateChanged() {
      var op = this.cloneDeep(this.options);
      op.page = 1;
      op.state = this.shiftRequestState;
      this.options = this.cloneDeep(op);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$log(">> options > watch handler");
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.$setToLocal(`manage-direct-expenses-range-startDate`, this.options.startDate);
        this.$setToLocal(`manage-direct-expenses-range-endDate`, this.options.endDate);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
// .direct-expenses-table {
//   tbody tr:not(.v-data-table__empty-wrapper) {
//     cursor: pointer;
//   }
// }
</style>
