var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-3 pt-3 pb-0",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('h3',{staticClass:"text--primary font-weight-black mr-3 d-inline-block"},[_vm._v("External Access List")])])]),_c('div',{staticClass:"pa-5 inset-shadow",staticStyle:{"background":"#eceff1","border-radius":"0.5rem"}},[(_vm.errors.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('ul',_vm._l((_vm.errors),function(err,i){return _c('li',{key:i,staticClass:"red--text"},[_vm._v(" "+_vm._s(err)+" ")])}),0)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"info","width":2}})],1):(_vm.selected != null)?_c('div',[_c('div',[_c('div',{staticClass:"d-flex align-center mt-4"},[_c('h3',{staticClass:"text--primary font-weight-black mr-3 d-inline-block"},[_vm._v("Individuals Access")]),(_vm.selected != null)?_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"orange","small":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.createNewEntry()}}},[_c('i',{staticClass:"far fa-plus mr-2"}),_vm._v("New ")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-2 mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.selected.accessListRecords || [],"items-per-page":-1,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('user-all-selector',{staticClass:"ml-0",staticStyle:{"width":"100%"},attrs:{"users":_vm.users,"required":"","hideLabel":"","hideDetails":"","hideBorder":"","readonly":item.id != 0,"isUsersLoading":_vm.isUsersLoading},model:{value:(item.userId),callback:function ($$v) {_vm.$set(item, "userId", $$v)},expression:"item.userId"}})]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"color":"info","hide-details":"","inset":"","dense":"","disabled":_vm.readonly},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","elevation":"0","color":"error","disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();return _vm.del(item, index)}}},[_c('i',{staticClass:"fal fa-trash-alt"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}),_c('br'),_c('div',{staticClass:"d-flex align-center"},[_c('h3',{staticClass:"text--primary font-weight-black mr-3 d-inline-block"},[_vm._v("Groups Access")]),(_vm.selected != null)?_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"orange","small":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.createNewGroupEntry()}}},[_c('i',{staticClass:"far fa-plus mr-2"}),_vm._v("New ")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-2 mt-2",attrs:{"dense":"","headers":_vm.groupHeaders,"items":_vm.selected.accessGroupRecords,"items-per-page":-1,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"color":"info","hide-details":"","inset":"","dense":"","disabled":_vm.readonly},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('access-groups-selector',{staticClass:"ml-0",staticStyle:{"width":"100%"},attrs:{"accessGroups":_vm.accessGroups,"required":"","hideLabel":"","hideDetails":"","hideBorder":"","readonly":item.id != 0,"isAccessGroupsLoading":_vm.isAccessGroupsLoading},model:{value:(item.accessGroup),callback:function ($$v) {_vm.$set(item, "accessGroup", $$v)},expression:"item.accessGroup"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","elevation":"0","color":"error","disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();return _vm.delGroup(item, index)}}},[_c('i',{staticClass:"fal fa-trash-alt"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)})],1)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }