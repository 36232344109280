<template>
  <span class="secondary--text fs-14px" v-if="parsedValue.Action == 'Cloned To'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-clone fa-swap-opacity mx-1"></i> This space was <u><b>cloned to</b></u> a
        new space =>
        <entity-id :label="parsedValue.Data.SpaceId" :path="spaceUrl" title="Space"></entity-id>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'Cloned From'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-clone mx-1"></i> This space was <u><b>cloned from</b></u> another space =>
        <entity-id :label="parsedValue.Data.SpaceId" :path="spaceUrl" title="Space"></entity-id>
      </span>
    </v-alert>
  </span>

  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'Equipment Moved To'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-clone fa-swap-opacity mx-1"></i> This space has some equipment <u><b>moved to</b></u> a
        another space =>
        <entity-id :label="parsedValue.Data.SpaceId" :path="spaceUrl" title="Space"></entity-id>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'Equipment Moved From'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-clone mx-1"></i> This space has some equipment <u><b>moved from</b></u> another space =>
        <entity-id :label="parsedValue.Data.SpaceId" :path="spaceUrl" title="Space"></entity-id>
      </span>
    </v-alert>
  </span>

  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'Delete'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-clone mx-1"></i> Deleted!
      </span>
    </v-alert>
  </span>
</template>

<script>
export default {
  components: {},
  name: "related-actions-logs",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
    spaceUrl() {
      if (this.parsedValue.Data == null) return "";
      return `projects/${this.parsedValue.Data.ProjectId}/spaces/${this.parsedValue.Data.SpaceId}`;
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
