<template>
  <v-chip
    v-if="result != null && resultObj != null"
    class="integration-result px-2"
    :small="!small"
    :x-small="small"
    :color="resultObj.color || 'secondary'"
    :text-color="resultObj.textColor || 'white'"
  >
    <i class="fas mr-2" :class="resultObj.icon" style="line-height: 0"></i>
    <span class="font-weight-medium" style="line-height: 0">{{
      resultObj.text.toUpperCase()
    }}</span>
  </v-chip>
</template>

<script>
import enums from "../../../../../plugins/enums";
export default {
  name: "wire-cad-integration-result",
  data() {
    return {
      resultObj: null,
    };
  },
  props: {
    result: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateResultObj();
  },
  methods: {
    updateResultObj() {
      if (this.result != null) {
        this.resultObj = this.getEnumMember(enums.WireCadIntegrationResult, this.result);
      }
    },
  },
  watch: {
    result: {
      handler() {
        this.updateResultObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
