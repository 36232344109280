<template>
  <div
    class="report-spaces-selection-scope"
    v-if="reportSettings != null"
    style="height: 100%; overflow: hidden; display: flex; flex-direction: column"
  >
    <div>
      <h4 class="secondary--text">Report Selection Scope</h4>
      <v-radio-group
        v-model="reportSettings.reportSelectionScope"
        :disabled="isGenerating"
        mandatory
        class="mt-1"
        row
        hide-details
      >
        <v-radio :value="enums.ReportScopeOptions.AllSpaces" color="deep-purple">
          <template v-slot:label>
            <div
              class="font-weight-medium fs-15px"
              :class="
                reportSettings.reportSelectionScope == enums.ReportScopeOptions.AllSpaces
                  ? 'deep-purple--text'
                  : 'secondary--text opacity-72'
              "
            >
              All Spaces
            </div>
          </template>
        </v-radio>
        <v-radio :value="enums.ReportScopeOptions.SelectedSpaces" color="deep-purple">
          <template v-slot:label>
            <div
              class="font-weight-medium fs-15px"
              :class="
                reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedSpaces
                  ? 'deep-purple--text'
                  : 'secondary--text opacity-72'
              "
            >
              Selected Spaces
            </div>
          </template>
        </v-radio>
        <v-radio :value="enums.ReportScopeOptions.SelectedLevels" color="deep-purple">
          <template v-slot:label>
            <div
              class="font-weight-medium fs-15px"
              :class="
                reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedLevels
                  ? 'deep-purple--text'
                  : 'secondary--text opacity-72'
              "
            >
              Selected Buildings/Levels
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
    <div
      class="pa-5 mt-3 inset-shadow"
      :class="
        reportSettings.reportSelectionScope == enums.ReportScopeOptions.AllSpaces
          ? 'justify-center'
          : ''
      "
      style="
        overflow: auto;
        overflow: overlay;
        overflow: hidden;
        background: #eceff1;
        border-radius: 0.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
      "
    >
      <v-scroll-y-transition mode="out-in">
        <p
          v-if="reportSettings.reportSelectionScope == enums.ReportScopeOptions.AllSpaces"
          key="reportSelectionScope-AllSpaces"
          class="text-center pa-4 d-flex flex-column justify-content-center align-center mb-16"
        >
          <img width="350" src="/img/art/_clip-science.png" />
          <span class="font-weight-bold fs-16px"> All spaces will be included! </span>
        </p>
        <v-container
          fluid
          v-else-if="reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedSpaces"
          key="reportSelectionScope-SelectedSpaces"
          class="pa-0"
          style="height: 100%; display: flex; flex-direction: column"
        >
          <v-row justify="space-between">
            <v-col cols="12" md="auto">
              <h4 class="ma-0 fs-16px font-size-bold">
                <b class="deep-purple--text mx-1">
                  {{ reportSettings.spaceIds.length }}
                </b>
                Selected Spaces
              </h4>
            </v-col>
            <v-col
              cols="12"
              md
              class="align-center flex-wrap justify-end py-0"
              :class="{
                'd-flex':
                  reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedSpaces,
              }"
              style="gap: 0.5rem"
            >
              <space-status-filter
                v-model="options.statuses"
                :settings="spaceStatusFilterSettings"
                :openOnMount="false"
              ></space-status-filter>
              <level-filter
                v-model="options.levelIds"
                :settings="levelFilterSettings"
                :openOnMount="false"
              ></level-filter>
              <building-filter
                v-model="options.buildingIds"
                :settings="buildingFilterSettings"
                @input="onBuildingFilterInput"
                :openOnMount="false"
              ></building-filter>
              <space-template-filter
                v-model="options.spaceTemplateIds"
                :settings="spaceTemplateFilterSettings"
                :openOnMount="false"
              ></space-template-filter>

              <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
              <v-text-field
                v-model="options.search"
                ref="mainSearch"
                class="table-search-field reports-spaces-search"
                @focus="mainSearchInFocus = true"
                @blur="mainSearchInFocus = false"
                :style="{
                  'max-width':
                    mainSearchInFocus || (options.search != '' && options.search != null)
                      ? '200px'
                      : '110px',
                }"
                label="Search"
                dense
                solo
                clearable
                hide-details
                prepend-inner-icon="far fa-search"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0 pt-2" justify="space-between" style="height: 100%; display: flex">
            <v-col cols="12" md="12" style="height: 100%; display: flex">
              <v-data-table
                style="
                  overflow: auto;
                  overflow: overlay;
                  height: 100%;
                  flex: 1 1 auto;
                  display: flex;
                  flex-direction: column;
                "
                dense
                v-show="
                  reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedSpaces
                "
                :headers="headers"
                v-model="selectedSpacesList"
                :items="spaces"
                show-select
                item-key="id"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="15"
                :loading="loadingStates.table"
                class="elevation-2 table-sticky-header-exclude-footer"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'far fa-arrow-to-left',
                  lastIcon: 'far fa-arrow-to-right',
                  prevIcon: 'far fa-angle-left',
                  nextIcon: 'far fa-angle-right',
                  itemsPerPageOptions: [15, 30, 50, 100],
                }"
              >
                <template v-slot:[`header.data-table-select`]="{ props, on }">
                  <v-tooltip top z-index="999" nudge-top="-4px">
                    <template v-slot:activator="{ on: ont, attrs }">
                      <div v-bind="attrs" v-on="ont">
                        <v-simple-checkbox
                          color="info"
                          :ripple="false"
                          v-bind="props"
                          v-on="on"
                          key="user-header-select"
                        ></v-simple-checkbox>
                      </div>
                    </template>
                    <span>Select all spaces on THIS PAGE ONLY!</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.imageUrl`]="{ item }">
                  <v-tooltip right z-index="999" nudge-right="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div class="space-img">
                          <v-img
                            v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                            class="img"
                            :src="item.imageUrl"
                            height="100%"
                            width="100%"
                            contain
                          ></v-img>
                          <i v-else :class="'fad fa-table-pivot'"></i>
                        </div>
                      </div>
                    </template>
                    <span>
                      <v-img
                        v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                        class="img"
                        :src="item.imageUrl"
                        height="250px"
                        width="250px"
                        contain
                      ></v-img>
                      <i
                        v-else
                        :class="'fad fa-table-pivot'"
                        style="font-size: 32px; padding: 2rem"
                      ></i>
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <p class="ma-0 mt-2">
                    <code class="text-caption">#{{ item.id }}</code>
                  </p>
                  <strong>{{ item.name }}</strong>
                  <p>{{ item.description }}</p>
                </template>

                <template v-slot:[`item.spaceNumber`]="{ item }">
                  <span class="mono-font font-weight-bold">{{ item.spaceNumber }}</span>
                </template>

                <template v-slot:[`item.state`]="{ item }">
                  <space-status small :status="item.state"></space-status>
                </template>

                <template v-slot:[`item.createdBy`]="{ item }">
                  <user-avatar :user="item.createdBy" icon></user-avatar>
                </template>

                <template v-slot:[`item.createDate`]="{ item }">
                  <dater :date="item.createDate" date-only></dater>
                </template>

                <template v-slot:[`item.updateDate`]="{ item }">
                  <dater :date="item.updateDate" date-only></dater>
                </template>

                <template v-slot:[`item.equipmentsCount`]="{ item }">
                  <equipment-count :count="getEquipmentsCount(item)" />
                </template>

                <template v-slot:[`item.equipmentCount`]="{ item }">
                  <equipment-count :count="item.equipmentCount" />
                </template>

                <template v-slot:[`item.updatedBy`]="{ item }">
                  <span>{{ item.createdBy.firstName }} {{ item.createdBy.lastName }}</span>
                </template>

                <template v-slot:no-data>
                  <img width="300" src="/img/art/fogg-no-connection-2.png" />
                  <p class="font-weight-bold">No Data Available!</p>
                </template>

                <template v-slot:loading>
                  <video width="250" muted loop autoplay style="padding: 0.5rem; opacity: 0.64">
                    <source
                      src="/img/art/astronaut-loves-music-4980476-4153140.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <p class="font-weight-bold">Searching the Cosmos...</p>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          fluid
          v-else-if="reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedLevels"
          key="reportSelectionScope-SelectedLevels"
          class="pa-0"
          style="height: 100%; display: flex; flex-direction: column"
        >
          <v-scroll-y-transition mode="out-in">
            <p
              class="mb-0 d-flex align-center"
              key="scope-view-sheets-loading"
              v-if="levelsLoading"
            >
              <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
              <span class="font-weight-bold fs-14px"> Processing... </span>
            </p>
            <div key="scope-view-sheets-tree" v-else>
              <p class="mb-0 font-weight-bold fs-16px mb-2">
                <b class="deep-purple--text mx-1">
                  {{ reportSettings.levelIds.length }}
                </b>
                Selected Levels
              </p>
              <div
                style="border-radius: 0.5rem"
                class="pa-2 buildings-tree-wrapper white elevation-2"
              >
                <v-treeview
                  v-model="reportSettings.levelIds"
                  dense
                  open-on-click
                  transition
                  selectable
                  selected-color="deep-purple"
                  :items="levelsTree"
                >
                  <template v-slot:prepend="{ item }">
                    <i class="fad fa-building tree-building" v-if="item.type == 'Building'"></i>
                    <i class="fad fa-layer-group tree-level" v-else></i>
                  </template>
                  <template v-slot:label="{ item }">
                    <span class="tree-building" v-if="item.type == 'Building'">
                      {{ item.name }}
                      <v-chip
                        label
                        v-if="item.area != null && item.area != 0"
                        x-small
                        color="secondary"
                        class="ml-2 font-weight-bold px-1 justify-center d-inline-flex fs-11px"
                        style="line-height: 0"
                      >
                        {{ item.area | numberWithCommas }}
                        <span class="ml-1">SQFT</span>
                      </v-chip>
                    </span>
                    <span class="tree-level" v-else>{{ item.name }}</span>
                  </template>
                </v-treeview>
              </div>
            </div>
          </v-scroll-y-transition>
        </v-container>
      </v-scroll-y-transition>
    </div>
  </div>
</template>

<script>
import enums from "../../../../../plugins/enums";
import projectService from "../../../services/projects-service";
import buildingsAPI from "../../../services/buildings-service";
import reportSpaceHeader from "../../../../Spaces/config/tables/reportSpace.header";
import {
  LevelFilterSettings,
  BuildingFilterSettings,
  SpaceTemplateFilterSettings,
  SpaceStatusFilterSettings,
} from "../../../../Shared/components/FilterManager/FilterSettings";
import SpaceStatusFilter from "../../../../Shared/components/FilterManager/Filters/SpaceStatusFilter.vue";
import LevelFilter from "../../../../Shared/components/FilterManager/Filters/LevelFilter.vue";
import BuildingFilter from "../../../../Shared/components/FilterManager/Filters/BuildingFilter.vue";
import SpaceTemplateFilter from "../../../../Shared/components/FilterManager/Filters/SpaceTemplateFilter.vue";
import SpaceStatus from "../../../../Spaces/components/SpaceStatus.vue";

export default {
  components: { LevelFilter, BuildingFilter, SpaceTemplateFilter, SpaceStatusFilter, SpaceStatus },
  data() {
    return {
      enums,
      reportSettings: null,
      headers: reportSpaceHeader,
      projectSpacesTimerId: null,
      spaces: [],
      selectedSpacesList: [],
      total: 0,
      buildings: [],
      levels: [],
      levelsTree: [],
      levelsLoading: false,
      mainSearchInFocus: false,
      storageKey: "ReportSelectionScopeSpaces",
      options: {
        itemsPerPage: 15,
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        statuses: [],
        buildingIds: [],
        levelIds: [],
        spaceTemplateIds: [],
      },
      loadingStates: {
        table: false,
      },
      spaceStatusFilterSettings: new SpaceStatusFilterSettings(),
      levelFilterSettings: new LevelFilterSettings(),
      buildingFilterSettings: new BuildingFilterSettings(),
      spaceTemplateFilterSettings: new SpaceTemplateFilterSettings(),
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
    isGenerating: {
      type: Boolean,
      default: false,
    },
    selectedSpaces: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.getBuildingsAndLevels();

    this.selectedSpacesList = this.cloneDeep(this.selectedSpaces);
    this.reportSettings = this.cloneDeep(this.value);

    this.spaceStatusFilterSettings = new SpaceStatusFilterSettings();

    this.levelFilterSettings = new LevelFilterSettings();
    this.levelFilterSettings.projectId = this.projectId;

    this.buildingFilterSettings = new BuildingFilterSettings();
    this.buildingFilterSettings.projectId = this.projectId;

    this.spaceTemplateFilterSettings = new SpaceTemplateFilterSettings();
    this.spaceTemplateFilterSettings.projectId = this.projectId;
  },
  methods: {
    onBuildingFilterInput(val) {
      this.$log("onBuildingFilterInput", this.cloneDeep(val));
      this.levelFilterSettings.buildingIds = this.cloneDeep(val);
    },
    getProjectSpacesDebounced() {
      this.$log("getProjectSpacesDebounced()", this.projectId);
      if (this.projectSpacesTimerId == null) {
        this.projectSpacesTimerId = -1;
        this.$log("getProjectSpacesDebounced() CALL 1");
        this.getProjectSpaces();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectSpacesTimerId);

      // delay new call 400ms
      this.projectSpacesTimerId = setTimeout(() => {
        this.$log("getProjectSpacesDebounced() CALL 2");
        this.getProjectSpaces();
      }, 400);
    },
    getProjectSpaces() {
      this.$log("getProjectSpaces()", this.projectId);
      this.loadingStates.table = true;
      projectService
        .getSpaces(this.projectId, this.$clean(this.cloneDeep(this.options), true))
        .then((resp) => {
          this.spaces = resp.data.items;
          this.$log("getProjectSpaces", this.spaces);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getBuildingsAndLevels() {
      this.levelsLoading = true;
      buildingsAPI
        .getProjectBuildings(this.projectId)
        .then((resp) => {
          this.$log("getBuildingsAndLevels", resp.data.items);
          this.buildings = resp.data.items;
          this.treePrepare(resp.data.items);
          this.levelsLoading = false;
        })
        .catch((err) => {
          this.levelsLoading = false;
          this.$handleError(err);
        });
    },
    treePrepare(data) {
      this.levelsTree = data.map((elm) => this.getTreeObject(elm));
    },
    getTreeObject(elm) {
      return {
        id: elm.id,
        name: elm.name,
        shortName: elm.shortName,
        area: elm.area,
        description: elm.description,
        type: "Building",
        children: [...elm.levels],
      };
    },
  },
  watch: {
    selectedSpaces: {
      handler() {
        if (!this.isEqual(this.selectedSpacesList, this.selectedSpaces)) {
          this.selectedSpacesList = this.cloneDeep(this.selectedSpaces);
        }
      },
      deep: true,
    },
    selectedSpacesList: {
      handler() {
        if (!this.isEqual(this.selectedSpacesList, this.selectedSpaces)) {
          this.reportSettings.spaceIds = this.selectedSpacesList.map((s) => s.id);
          this.$emit("update:selected-spaces", this.cloneDeep(this.selectedSpacesList));
        }
      },
      deep: true,
    },
    value: {
      handler() {
        if (!this.isEqual(this.reportSettings, this.value)) {
          this.reportSettings = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    reportSettings: {
      handler() {
        this.$log("reportSettings.spaceIds", this.cloneDeep(this.reportSettings.spaceIds));
        if (!this.isEqual(this.reportSettings, this.value)) {
          this.$emit("input", this.cloneDeep(this.reportSettings));
        }
      },
      deep: true,
    },
    options: {
      handler(newOptions, oldOptions) {
        // && !this.isEqual(this.cloneDeep(newOptions), this.cloneDeep(oldOptions))
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, newOptions.itemsPerPage);
        if (this.reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedSpaces) {
          this.getProjectSpacesDebounced();
        }
      },
      deep: true,
    },
    "options.buildingIds"(ids) {
      if (this.reportSettings.reportSelectionScope != enums.ReportScopeOptions.SelectedLevels)
        return;

      this.options.levelIds = [];
      this.levels = this.buildings
        .filter((elm) => ids.includes(elm.id))
        .map((elm) => elm.levels)
        .flat();
    },
    "reportSettings.reportSelectionScope": {
      handler(newVal) {
        this.options.search = null;
        if (this.reportSettings.reportSelectionScope != enums.ReportScopeOptions.SelectedLevels)
          this.reportSettings.levelIds = [];
        else if (
          this.reportSettings.reportSelectionScope != enums.ReportScopeOptions.SelectedSpaces
        ) {
          this.reportSettings.spaceIds = [];
          this.selectedSpacesList = [];
        }
      },
    },
  },
};
</script>

<style lang="scss">
.report-spaces-selection-scope {
  .v-data-table-header {
    th:first-child .v-icon.mdi-minus-box,
    th:first-child .v-icon.mdi-checkbox-marked {
      color: $deep-purple-base !important;
    }
  }

  .v-data-table__selected {
    background: rgba($deep-purple-base, 0.1) !important;

    .theme--light.v-icon {
      color: $deep-purple-base !important;
    }

    .v-input--selection-controls__ripple {
      color: $deep-purple-base !important;
    }
  }
}
</style>
