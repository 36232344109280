var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('page-title',{attrs:{"title":"MS Teams","show-subtitle":"","subtitle":"Browse DNA MS Teams!","badge":_vm.total}}),_c('v-row',{staticClass:"mb-1",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex align-center pt-0",attrs:{"cols":"12","md":"auto"}}),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"}},[_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
            'max-width':
              _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
                ? '200px'
                : '110px',
          }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.isMSTeamsListLoading},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('refresh',{attrs:{"loading":_vm.isMSTeamsListLoading},on:{"refresh":function($event){return _vm.getMSTeamsList()}}})],1)])],1),_c('v-data-table',{staticClass:"elevation-2 ms-teams-table table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.msTeamsListFiltered,"options":_vm.options,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.isMSTeamsListLoading,"footer-props":{
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [(item.description != null)?_c('p',{staticClass:"fs-12px ma-0"},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e()]}},{key:"item.visibility",fn:function(ref){
    var item = ref.item;
return [_c('ms-team-visibility',{attrs:{"small":"","visibility":item.visibility}})]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)}),_c('view-ms-team',{ref:"viewMSTeam"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }