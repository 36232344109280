<template>
  <div
    class="alt-comp-wrapper equipment-selector"
    :class="{
      'details-hidden': hideDetails,
      'alt-comp-wrapper-small': small,
    }"
  >
    <v-autocomplete-alt
      auto-select-first
      :rules="required ? [allRules.required] : []"
      :label="
        hideLabel
          ? null
          : label == null || label == ''
          ? `<i class='fad fa-plug mr-2'></i>Equipment`
          : label
      "
      id="equipmentSelector"
      placeholder="Select a Equipment"
      :items="equipmentList"
      :value="selected.equipmentId"
      :search-input.sync="search"
      @input="handleSelectedEquipment"
      item-value="id"
      item-text="tag"
      dense
      filled
      no-filter
      clearable
      :hide-details="hideDetails"
      :loading="isEquipmentLoading"
      :readonly="readonly"
      :menu-props="{
        zIndex: 90000000000,
        contentClass: 'user-selector-menu',
      }"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <equipment-card
          :equipment="data.item"
          small
          noBorder
          :key="item.id + '_equipmentListSelector'"
        />
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="24px !important"
      width="24px !important"
      height="24px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      target="_blank"
      :href="`/equipment/${this.selected.equipmentId}`"
      :disabled="selected.equipmentId == null"
      :loading="isEquipmentLoading"
    >
      <i class="far fa-external-link"></i>
    </v-btn>
  </div>
</template>

<script>
import equipmentService from "../../Equipments/services/equipments-service";
import EquipmentCard from "../../Equipments/components/EquipmentCard.vue";

export default {
  components: { EquipmentCard },
  props: {
    equipmentId: {
      type: Number,
      default: null,
    },
    equipment: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log("this.selected.equipment", this.equipmentId, this.selected.equipmentId);
    this.selected.equipmentId = this.equipmentId;
    this.getEquipment();
  },
  data() {
    return {
      search: null,
      equipmentTimerId: null,
      equipmentList: [],
      isEquipmentLoading: false,
      selected: {
        equipment: null,
        equipmentId: null,
      },
    };
  },
  methods: {
    updateEquipmentObj() {
      if (this.selected.equipmentId != null && this.equipmentList.length > 0) {
        this.selected.equipment = this.equipmentList.find((b) => b.id == this.selected.equipmentId);
      } else {
        this.selected.equipment = null;
      }
      this.$emit("update:equipment", this.selected.equipment);
    },
    handleSelectedEquipment(val) {
      this.selected.equipmentId = val;
      this.updateEquipmentObj();
    },
    getEquipment() {
      this.isEquipmentLoading = true;
      var optionsToSend = {
        includedEquipmentId: this.selected.equipmentId,
        search: this.search,
        all: false,
      };
      equipmentService
        .typeHeadObj(this.$clean(optionsToSend, true))
        .then((resp) => {
          this.equipmentList = resp.data;
          this.$log("equipmentList", resp.data);
          this.isEquipmentLoading = false;
          this.updateEquipmentObj();
        })
        .catch((err) => {
          this.isEquipmentLoading = false;
          this.$handleError(err);
        });
    },
    getEquipmentDebounced() {
      if (this.equipmentTimerId == null) {
        this.equipmentTimerId = -1;
        this.getEquipment();
        return;
      }
      // cancel pending call
      clearTimeout(this.equipmentTimerId);
      // delay new call 400ms
      this.equipmentTimerId = setTimeout(() => {
        this.getEquipment();
      }, 500);
    },
  },
  created() {},
  watch: {
    search: {
      handler() {
        this.getEquipmentDebounced();
      },
      deep: true,
    },
    isEquipmentLoading: {
      handler() {
        this.$emit("update:loading", this.isEquipmentLoading);
      },
    },
    equipmentId: {
      handler() {
        if (this.selected.equipmentId != this.equipmentId) {
          this.selected.equipmentId = this.equipmentId;
          this.$nextTick(() => {
            this.updateEquipmentObj();
          });
        }
      },
    },
    "selected.equipmentId": {
      handler() {
        if (this.selected.equipmentId != this.equipmentId) {
          this.$emit("update:equipmentId", this.selected.equipmentId);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.alt-comp-wrapper.equipment-selector {
  label {
    display: flex;
    align-items: center;

    i {
      line-height: 0;
    }
  }
  .v-autocomplete-alt {
    // min-width: 250px;
    width: calc(100% - 40px);
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.equipment-image-wrapper {
  border: thin solid rgba($shades-black, 0.24);
  border-radius: 0.25rem;
}
</style>
