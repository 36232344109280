<template>
  <v-tooltip
    v-if="manufacturer != null"
    top
    nudge-top="-8px"
    :disabled="!hideName"
    z-index="999999999999999999999"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        v-bind="attrs"
        v-on="on"
        class="d-flex align-center"
        @click.stop
        style="gap: 0.5rem; padding: 1px"
        :style="hideName ? 'width: fit-content' : ''"
        hover
        :to="manufacturerLink"
        target="_blank"
      >
        <div class="company-logo-mini" :style="hideName && !square ? 'width: auto' : ''">
          <div
            style="height: 100%; width: 100%"
            v-if="manufacturer.logoUrl != null && manufacturer.logoUrl != ''"
          >
            <img
              :key="manufacturer.id + '_img'"
              :src="manufacturer.logoUrl"
              height="100%"
              width="100%"
              style="object-fit: contain"
              class="rounded"
            />
          </div>
          <div style="height: 100%; width: 100%" v-else>
            <img
              :key="manufacturer.id + '_img'"
              src="/img/DNA_Symbol.png"
              height="100%"
              width="100%"
              style="object-fit: contain"
            />
          </div>
        </div>
        <h5 v-if="!hideName" class="ma-0">{{ manufacturer.name }}</h5>
      </v-card>
    </template>
    <span>{{ manufacturer.name }}</span>
  </v-tooltip>
  <div v-else class="text--disabled">N/A</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    manufacturer: {
      type: Object,
      default: null,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    manufacturerLink() {
      if (this.manufacturer == null) return null;
      return `/manufacturers/${this.manufacturer.id}`;
    },
  },
};
</script>

<style lang="scss"></style>
