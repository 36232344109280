<template>
  <div class="v-textarea-alt">
    <!-- <label
      :for="this.id"
      v-html="label + requiredIcon"
      @mouseenter="showCopyBtn()"
      @mouseleave="hideCopyBtn()"
    ></label> -->
    <div style="display: flex; align-items: center; justify-content: space-between">
      <label :for="this.id" v-html="label + requiredIcon"></label>
      <slot name="label-append"></slot>
    </div>
    <!-- <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-inline-block" v-bind="attrs" v-on="on">
          <transition name="slideCopyBtn">
            <v-btn
              v-if="showCopy"
              @focus="showCopyBtn()"
              @mouseenter="showCopyBtn()"
              @mouseleave="hideCopyBtn()"
              icon
              x-small
              color="secondary"
              class="ml-1"
              @click="copyToClipboard"
            >
              <i class="fad fa-copy"></i>
            </v-btn>
          </transition>
        </div>
      </template>
      <span>Copy to Clipboard</span>
    </v-tooltip>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar> -->

    <v-textarea
      :id="this.id"
      ref="vTextArea"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
      @keydown="onKeyDown"
    >
      <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-textarea>
  </div>
</template>

<script>
export default {
  name: "v-textarea-alt",
  inheritAttrs: true,
  data() {
    return {
      showCopy: false,
      showCopyTimer: null,
      snackbar: false,
      snackbarText: "Copied to Clipboard!",
      snackbarTimeout: 2000,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.code == "Escape") {
        this.$refs.vTextArea.blur();
        setTimeout(() => {
          let parent = this.$refs.vTextArea.$el.closest("div.vue-slideout");
          if (parent) parent.focus();
        });
      }
    },
    hideCopyBtn() {
      clearTimeout(this.showCopyTimer);
      this.showCopyTimer = setTimeout(() => {
        this.showCopy = false;
      }, 250);
    },
    showCopyBtn() {
      clearTimeout(this.showCopyTimer);
      this.showCopy = true;
    },
    copyToClipboard() {
      let val = this.$refs.vTextArea.value;
      let valTitleCase = "";
      if (val != null)
        valTitleCase = val.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

      navigator.clipboard.writeText(valTitleCase);
      this.snackbar = true;
    },
    focus() {
      this.$refs.vTextArea.focus();
    },
    blur() {
      this.$refs.vTextArea.blur();
    },
    select() {
      this.focus();
      this.$refs.vTextArea.$el.querySelector("textarea").select();
    },
  },
  computed: {
    hasError() {
      return this.$refs.vTextArea.hasError;
    },
    hasRequiredRule() {
      if (this.$attrs == null || this.$attrs.rules == null) return false;
      return this.$attrs.rules.some((r) => r.name == "required" || r.name == "requiredNumber");
    },
    requiredIcon() {
      return this.hasRequiredRule
        ? " <i class='fas fa-star-of-life pink--text label-icon'></i>"
        : "";
    },
    commonAttrs() {
      return {
        label: "",
        solo: true,
        dense: true,
        flat: true,
        filled: true,
        class: {
          "mt-1": this.$props.label,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.v-textarea-alt {
  label {
    font-weight: 600 !important;
    font-size: 15px;
  }
  .v-input {
    textarea {
      font-size: 14px !important;
    }

    .v-input__control > .v-input__slot {
      background-color: rgba($shades-black, 0.08) !important;

      .v-input__icon .v-icon {
        font-size: 16px;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1.5px solid transparent !important;
        border-radius: 4px;
        z-index: 1;
        pointer-events: none;
        user-select: none;
        transition: all 0.15s ease-out;
      }
    }
    .v-input__control > .v-input__slot:hover {
      background-color: rgba($shades-black, 0.12) !important;

      &:before {
        border: 1.5px solid transparent !important;
      }
    }

    &.v-input--is-focused {
      .v-input__control > .v-input__slot {
        background-color: rgba($shades-black, 0.14) !important;

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #dddfde inset !important;
        }

        &:before {
          border: 1.5px solid rgba($shades-black, 0.87) !important;
        }
      }
    }

    &.error--text {
      .v-input__control > .v-input__slot {
        background-color: #fce4ec !important;
        .v-input__icon .v-icon {
          color: var(--v-accent-darken2) !important;
        }
      }
      .v-input__control > .v-input__slot:hover {
        background-color: #fce4ec !important;
      }
      &.v-input--is-focused {
        .v-input__control > .v-input__slot {
          background-color: #f8bbd0 !important;
        }
      }
    }

    &.v-input--is-readonly {
      .v-input__control > .v-input__slot {
        background-color: transparent !important;
        border: 1px dashed rgba($shades-black, 0.24) !important;
      }

      .v-input__append-inner {
        display: none !important;
      }
    }
  }
}
</style>
