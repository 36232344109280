<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="entitiesFiltered"
    :total="total"
    :page.sync="options.page"
    :search.sync="options.search"
    :openOnMount="openOnMount"
    allow-search
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    itemValue="id"
    itemText="name"
    noItemsText="<span class='d-flex align-center flex-column'>
                  <b>No items available!</b>
                  <span class='opacity-64'>Please Select a Manufacturer First</span>
                </span>"
  >
    <template v-slot:item="{ item }">
      <div class="d-flex justify-start flex-column py-2" style="cursor: pointer">
        <span class="d-flex align-center flex-row" v-if="item.id != -1">
          <div class="company-logo-tiny">
            <div
              style="height: 100%; width: 100%"
              v-if="item.manufacturer.logoUrl != null && item.manufacturer.logoUrl != ''"
            >
              <img
                :key="item.manufacturer.id + '_img'"
                :src="item.manufacturer.logoUrl"
                height="100%"
                width="100%"
                style="object-fit: contain"
              />
            </div>
            <div style="height: 100%; width: 100%" v-else>
              <img
                :key="item.manufacturer.id + '_img'"
                src="/img/DNA_Symbol.png"
                height="100%"
                width="100%"
                style="object-fit: contain"
              />
            </div>
          </div>
          <div class="fs-12px ml-1 font-weight-bold opacity-87">
            {{ item.manufacturer.name }}
          </div>
        </span>
        <strong class="fs-13px">{{ item.name }}</strong>
      </div>
    </template>
  </filter-base-menu>
</template>

<script>
import { ProductFamilyFilterSettings } from "../FilterSettings";
import FilterBaseMenu from "../FilterBaseMenu.vue";

export default {
  name: "product-family-filter",
  components: { FilterBaseMenu },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new ProductFamilyFilterSettings(),
    },
    productFamilies: {
      type: Array,
      default: () => [],
    },
    openOnMount: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  data() {
    return {
      selectedItems: [],
      timerId: null,
      storedSearch: null,
      options: {
        search: null,
      },
      isLoading: true,
      initiallyLoaded: false,
    };
  },
  computed: {
    entitiesFiltered() {
      if (this.options.search != null && this.options.search.trim() != "") {
        let searchTerm = this.options.search.toLowerCase();
        return this.productFamilies.filter(
          (family) =>
            family.name.toLowerCase().indexOf(searchTerm) > -1 ||
            family.description.toLowerCase().indexOf(searchTerm) > -1 ||
            family.manufacturer.name.toLowerCase().indexOf(searchTerm) > -1 ||
            family.manufacturer.abbreviatedName.toLowerCase().indexOf(searchTerm) > -1
        );
      } else return this.productFamilies;
    },
    total() {
      return this.entitiesFiltered.length;
    },
  },
  methods: {
    onShow() {},
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
  },
};
</script>
