export default [
  // {
  //   text: "Actions",
  //   value: "actions",
  //   order: 0,
  //   sortable: false,
  //   width: "72px",
  //   class: "px-2",
  //   cellClass: "px-1",
  //   hidable: true,
  // },
  {
    text: "Client",
    value: "contract.project.client.name",
    width: "54px",
    sortable: false,
    hidable: true,
    hidden: false,
  },
  {
    text: "Project",
    value: "contract.project.fullName",
    width: "250px",
    cellClass: "px-0",
    class: "px-0",
    sortable: true,
    hidable: true,
    hidden: false,
  },
  {
    text: "Lead AC",
    value: "contract.project.leadAC",
    width: "72px",
    cellClass: "px-0",
    class: "px-0",
    sortable: false,
    hidable: true,
    hidden: false,
  },
  {
    text: "Contract",
    value: "contract.name",
    width: "250px",
    cellClass: "px-1",
    class: "px-1",
    sortable: true,
    hidable: true,
    hidden: false,
  },
  {
    text: "Contract Status",
    value: "contract.status",
    width: "110px",
    sortable: false,
    hidable: true,
  },
  {
    text: "Target Month",
    value: "startDate",
    width: "130px",
    sortable: true,
    hidable: true,
  },
  {
    text: "Phase",
    value: "name",
    width: "250px",
    sortable: true,
    hidable: true,
  },
  {
    text: "Σ Direct Exp Cost",
    value: "totalDirectExpensesCost",
    width: "160px",
    class: "px-2",
    cellClass: "px-2",
    sortable: true,
    hidable: true,
  },
  {
    text: "Σ Direct Exp Revenue",
    value: "totalDirectExpensesRevenue",
    width: "160px",
    class: "px-2",
    cellClass: "px-2",
    sortable: true,
    hidable: true,
  },
];
