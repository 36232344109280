var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reportSettings != null)?_c('div',{staticClass:"report-spaces-selection-scope",staticStyle:{"height":"100%","overflow":"hidden","display":"flex","flex-direction":"column"}},[_c('div',[_c('h4',{staticClass:"secondary--text"},[_vm._v("Report Selection Scope")]),_c('v-radio-group',{staticClass:"mt-1",attrs:{"disabled":_vm.isGenerating,"mandatory":"","row":"","hide-details":""},model:{value:(_vm.reportSettings.reportSelectionScope),callback:function ($$v) {_vm.$set(_vm.reportSettings, "reportSelectionScope", $$v)},expression:"reportSettings.reportSelectionScope"}},[_c('v-radio',{attrs:{"value":_vm.enums.ReportScopeOptions.AllSpaces,"color":"deep-purple"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"font-weight-medium fs-15px",class:_vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.AllSpaces
                ? 'deep-purple--text'
                : 'secondary--text opacity-72'},[_vm._v(" All Spaces ")])]},proxy:true}],null,false,2938575743)}),_c('v-radio',{attrs:{"value":_vm.enums.ReportScopeOptions.SelectedSpaces,"color":"deep-purple"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"font-weight-medium fs-15px",class:_vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.SelectedSpaces
                ? 'deep-purple--text'
                : 'secondary--text opacity-72'},[_vm._v(" Selected Spaces ")])]},proxy:true}],null,false,3237792575)}),_c('v-radio',{attrs:{"value":_vm.enums.ReportScopeOptions.SelectedLevels,"color":"deep-purple"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"font-weight-medium fs-15px",class:_vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.SelectedLevels
                ? 'deep-purple--text'
                : 'secondary--text opacity-72'},[_vm._v(" Selected Buildings/Levels ")])]},proxy:true}],null,false,3951459093)})],1)],1),_c('div',{staticClass:"pa-5 mt-3 inset-shadow",class:_vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.AllSpaces
        ? 'justify-center'
        : '',staticStyle:{"overflow":"hidden","background":"#eceff1","border-radius":"0.5rem","height":"100%","display":"flex","flex-direction":"column"}},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(_vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.AllSpaces)?_c('p',{key:"reportSelectionScope-AllSpaces",staticClass:"text-center pa-4 d-flex flex-column justify-content-center align-center mb-16"},[_c('img',{attrs:{"width":"350","src":"/img/art/_clip-science.png"}}),_c('span',{staticClass:"font-weight-bold fs-16px"},[_vm._v(" All spaces will be included! ")])]):(_vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.SelectedSpaces)?_c('v-container',{key:"reportSelectionScope-SelectedSpaces",staticClass:"pa-0",staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h4',{staticClass:"ma-0 fs-16px font-size-bold"},[_c('b',{staticClass:"deep-purple--text mx-1"},[_vm._v(" "+_vm._s(_vm.reportSettings.spaceIds.length)+" ")]),_vm._v(" Selected Spaces ")])]),_c('v-col',{staticClass:"align-center flex-wrap justify-end py-0",class:{
              'd-flex':
                _vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.SelectedSpaces,
            },staticStyle:{"gap":"0.5rem"},attrs:{"cols":"12","md":""}},[_c('space-status-filter',{attrs:{"settings":_vm.spaceStatusFilterSettings,"openOnMount":false},model:{value:(_vm.options.statuses),callback:function ($$v) {_vm.$set(_vm.options, "statuses", $$v)},expression:"options.statuses"}}),_c('level-filter',{attrs:{"settings":_vm.levelFilterSettings,"openOnMount":false},model:{value:(_vm.options.levelIds),callback:function ($$v) {_vm.$set(_vm.options, "levelIds", $$v)},expression:"options.levelIds"}}),_c('building-filter',{attrs:{"settings":_vm.buildingFilterSettings,"openOnMount":false},on:{"input":_vm.onBuildingFilterInput},model:{value:(_vm.options.buildingIds),callback:function ($$v) {_vm.$set(_vm.options, "buildingIds", $$v)},expression:"options.buildingIds"}}),_c('space-template-filter',{attrs:{"settings":_vm.spaceTemplateFilterSettings,"openOnMount":false},model:{value:(_vm.options.spaceTemplateIds),callback:function ($$v) {_vm.$set(_vm.options, "spaceTemplateIds", $$v)},expression:"options.spaceTemplateIds"}}),_c('v-sheet',{staticClass:"mx-1",attrs:{"height":"28","width":"1","color":"blue-grey lighten-4"}}),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field reports-spaces-search",style:({
                'max-width':
                  _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
                    ? '200px'
                    : '110px',
              }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search"},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-row',{staticClass:"mt-0 pt-2",staticStyle:{"height":"100%","display":"flex"},attrs:{"justify":"space-between"}},[_c('v-col',{staticStyle:{"height":"100%","display":"flex"},attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.SelectedSpaces
              ),expression:"\n                reportSettings.reportSelectionScope == enums.ReportScopeOptions.SelectedSpaces\n              "}],staticClass:"elevation-2 table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.spaces,"show-select":"","item-key":"id","options":_vm.options,"server-items-length":_vm.total,"items-per-page":15,"loading":_vm.loadingStates.table,"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'far fa-arrow-to-left',
                lastIcon: 'far fa-arrow-to-right',
                prevIcon: 'far fa-angle-left',
                nextIcon: 'far fa-angle-right',
                itemsPerPageOptions: [15, 30, 50, 100],
              }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
              var props = ref.props;
              var on = ref.on;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"999","nudge-top":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var ont = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),ont),[_c('v-simple-checkbox',_vm._g(_vm._b({key:"user-header-select",attrs:{"color":"info","ripple":false}},'v-simple-checkbox',props,false),on))],1)]}}],null,true)},[_c('span',[_vm._v("Select all spaces on THIS PAGE ONLY!")])])]}},{key:"item.imageUrl",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"space-img"},[((item.imageUrl != null) & (item.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.imageUrl,"height":"100%","width":"100%","contain":""}}):_c('i',{class:'fad fa-table-pivot'})],1)])]}}],null,true)},[_c('span',[((item.imageUrl != null) & (item.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.imageUrl,"height":"250px","width":"250px","contain":""}}):_c('i',{class:'fad fa-table-pivot',staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])]}},{key:"item.description",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"ma-0 mt-2"},[_c('code',{staticClass:"text-caption"},[_vm._v("#"+_vm._s(item.id))])]),_c('strong',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.description))])]}},{key:"item.spaceNumber",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"mono-font font-weight-bold"},[_vm._v(_vm._s(item.spaceNumber))])]}},{key:"item.state",fn:function(ref){
              var item = ref.item;
return [_c('space-status',{attrs:{"small":"","status":item.state}})]}},{key:"item.createdBy",fn:function(ref){
              var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.createdBy,"icon":""}})]}},{key:"item.createDate",fn:function(ref){
              var item = ref.item;
return [_c('dater',{attrs:{"date":item.createDate,"date-only":""}})]}},{key:"item.updateDate",fn:function(ref){
              var item = ref.item;
return [_c('dater',{attrs:{"date":item.updateDate,"date-only":""}})]}},{key:"item.equipmentsCount",fn:function(ref){
              var item = ref.item;
return [_c('equipment-count',{attrs:{"count":_vm.getEquipmentsCount(item)}})]}},{key:"item.equipmentCount",fn:function(ref){
              var item = ref.item;
return [_c('equipment-count',{attrs:{"count":item.equipmentCount}})]}},{key:"item.updatedBy",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdBy.firstName)+" "+_vm._s(item.createdBy.lastName))])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem","opacity":"0.64"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true),model:{value:(_vm.selectedSpacesList),callback:function ($$v) {_vm.selectedSpacesList=$$v},expression:"selectedSpacesList"}})],1)],1)],1):(_vm.reportSettings.reportSelectionScope == _vm.enums.ReportScopeOptions.SelectedLevels)?_c('v-container',{key:"reportSelectionScope-SelectedLevels",staticClass:"pa-0",staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(_vm.levelsLoading)?_c('p',{key:"scope-view-sheets-loading",staticClass:"mb-0 d-flex align-center"},[_c('i',{staticClass:"fad fa-spinner-third fa-spin fs-20px mr-2"}),_c('span',{staticClass:"font-weight-bold fs-14px"},[_vm._v(" Processing... ")])]):_c('div',{key:"scope-view-sheets-tree"},[_c('p',{staticClass:"mb-0 font-weight-bold fs-16px mb-2"},[_c('b',{staticClass:"deep-purple--text mx-1"},[_vm._v(" "+_vm._s(_vm.reportSettings.levelIds.length)+" ")]),_vm._v(" Selected Levels ")]),_c('div',{staticClass:"pa-2 buildings-tree-wrapper white elevation-2",staticStyle:{"border-radius":"0.5rem"}},[_c('v-treeview',{attrs:{"dense":"","open-on-click":"","transition":"","selectable":"","selected-color":"deep-purple","items":_vm.levelsTree},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
              var item = ref.item;
return [(item.type == 'Building')?_c('i',{staticClass:"fad fa-building tree-building"}):_c('i',{staticClass:"fad fa-layer-group tree-level"})]}},{key:"label",fn:function(ref){
              var item = ref.item;
return [(item.type == 'Building')?_c('span',{staticClass:"tree-building"},[_vm._v(" "+_vm._s(item.name)+" "),(item.area != null && item.area != 0)?_c('v-chip',{staticClass:"ml-2 font-weight-bold px-1 justify-center d-inline-flex fs-11px",staticStyle:{"line-height":"0"},attrs:{"label":"","x-small":"","color":"secondary"}},[_vm._v(" "+_vm._s(_vm._f("numberWithCommas")(item.area))+" "),_c('span',{staticClass:"ml-1"},[_vm._v("SQFT")])]):_vm._e()],1):_c('span',{staticClass:"tree-level"},[_vm._v(_vm._s(item.name))])]}}],null,false,3029178208),model:{value:(_vm.reportSettings.levelIds),callback:function ($$v) {_vm.$set(_vm.reportSettings, "levelIds", $$v)},expression:"reportSettings.levelIds"}})],1)])])],1):_vm._e()],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }