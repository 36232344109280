export default [
  {
    text: "Actions",
    value: "actions",
    order: 0,
    sortable: false,
    width: "168px",
    class: "px-2",
    cellClass: "px-1",
    hidable: true,
  },
  {
    text: "Client",
    value: "project.client.name",
    width: "54px",
    cellClass: "px-0",
    class: "px-0",
    sortable: false,
    hidable: true,
    hidden: false,
  },
  {
    text: "Project",
    value: "project.fullName",
    width: "250px",
    cellClass: "px-0",
    class: "px-0",
    sortable: true,
    hidable: true,
    hidden: false,
  },
  {
    text: "Lead AC",
    value: "project.leadAC",
    width: "72px",
    cellClass: "px-0",
    class: "px-0",
    sortable: false,
    hidable: true,
    hidden: false,
  },
  {
    text: "Origin",
    value: "origin",
    cellClass: "px-0",
    class: "px-0",
    sortable: true,
    hidable: true,
    width: "100px",
  },
  {
    text: "Type",
    value: "type",
    cellClass: "px-0",
    class: "px-0",
    sortable: true,
    hidable: true,
    width: "100px",
  },
  {
    text: "Id",
    value: "id",
    cellClass: "pl-0 pr-1",
    class: "px-1",
    width: "54px",
    sortable: true,
    hidable: true,
  },
  {
    text: "Type",
    value: "type",
    sortable: true,
    hidable: true,
    width: "100px",
  },
  {
    text: "Origin",
    value: "origin",
    sortable: true,
    hidable: true,
    width: "100px",
  },
  {
    text: "Name",
    value: "name",
    sortable: true,
    hidable: true,
    width: "250px",
  },
  {
    text: "PR",
    value: "prNumber",
    cellClass: "pl-0 pr-1",
    class: "px-1",
    width: "40px",
    sortable: false,
    hidable: true,
  },
  {
    text: "NTP",
    value: "noticeToProceed",
    cellClass: "pl-0 pr-1",
    class: "px-1",
    width: "54px",
    sortable: true,
    hidable: true,
  },
  {
    text: "PO",
    value: "poNumber",
    cellClass: "pl-0 pr-1",
    class: "px-1",
    width: "54px",
    sortable: false,
    hidable: true,
  },
  {
    text: "Status",
    value: "status",
    width: "96px",
    sortable: true,
    hidable: true,
  },
  {
    text: "Labor Hours",
    value: "totalHours",
    width: "130px",
    sortable: true,
    hidable: true,
  },
  {
    text: "Labor Cost",
    value: "totalLaborCost",
    width: "130px",
    sortable: true,
    hidable: true,
  },
  {
    text: "Labor Revenue",
    value: "totalLaborRevenue",
    width: "140px",
    sortable: true,
    hidable: true,
  },
  {
    text: "Σ Direct Exp Cost",
    value: "totalDirectExpensesCost",
    width: "120px",
    class: "px-2",
    cellClass: "px-2",
    sortable: false,
    hidable: true,
  },
  {
    text: "Σ Direct Exp Revenue",
    value: "totalDirectExpensesRevenue",
    width: "140px",
    class: "px-2",
    cellClass: "px-2",
    sortable: false,
    hidable: true,
  },
  {
    text: "Approved On",
    value: "approvedOn",
    width: "125px",
    hidable: true,
    hidden: false,
  },
  {
    text: "Approved by",
    value: "approvedBy",
    width: "125px",
    hidable: true,
    hidden: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "87px",
    cellClass: "pl-0",
    class: "pl-0",
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "96px",
    cellClass: "px-0",
    class: "px-0",
    hidable: true,
    hidden: false,
  },
  {
    text: "Creator",
    value: "createdBy",
    width: "72px",
    cellClass: "px-0",
    class: "px-0",
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updater",
    value: "updatedBy",
    width: "96px",
    cellClass: "px-0",
    class: "px-0",
    hidable: true,
    hidden: false,
  },
];
