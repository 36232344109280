<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '800px'"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.replace.active"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small>
        <i class="fad fa-trash-undo mr-2"></i> Delete & Replace Equipment
      </h3>
      <v-btn @click="deleteReplaceDialogDiscard()" icon><i class="far fa-times"></i></v-btn>
    </template>
    <div color="white" style="position: sticky; top: 0; z-index: 1">
      <v-tabs v-model="slideouts.replace.tab" color="error" v-if="slideouts.replace.active">
        <v-tab :key="0"> Actions </v-tab>
        <v-tab :key="1"> Connected Spaces </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0" v-if="slideouts.replace.active">
      <v-tabs-items v-model="slideouts.replace.tab">
        <v-tab-item :key="0">
          <v-form v-model="modals.replaceEquipment.valid" ref="deleteReplaceForm">
            <v-container fluid class="">
              <v-row class="my-0" dense>
                <v-col sm="7" class="pl-2 py-0">
                  <h4 mb-2 style="font-weight: 600; font-size: 15px">
                    <b class="mr-1">Step 1.</b> Delete this Equipment
                  </h4>
                  <div class="old-equipment-container mt-2 mb-5">
                    <equipment-card :equipment="sourceEquipment" />
                  </div>
                </v-col>
                <v-col sm="7" class="py-0">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="<b class='mr-1'>Step 2.</b> Then, Replace it with this Equipment"
                    id="NewEquipment"
                    ref="NewEquipment"
                    placeholder="No Equipment Selected!"
                    :loading="modals.replaceEquipment.isLoading"
                    v-model="modals.replaceEquipment.equipment"
                    :items="modals.replaceEquipment.replaceEquipments"
                    :search-input.sync="modals.replaceEquipment.searchEquipments"
                    dense
                    filled
                    no-filter
                    clearable
                    return-object
                    item-value="id"
                    item-text="tag"
                  >
                    <template v-slot:item="data">
                      <equipment-card noBorder :key="'RE_' + data.item.id" :equipment="data.item" />
                    </template>
                    <template v-slot:selection="data">
                      <equipment-card
                        noBorder
                        :key="'RES_' + data.item.id"
                        :equipment="data.item"
                      />
                    </template>
                  </v-autocomplete-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item :key="1">
          <v-container fluid class="pa-6 pt-2" style="background: #eceff1">
            <h4 class="mb-2 mt-2">Spaces that contains this Equipment</h4>
            <v-row class="mb-1" style="flex: none" justify="space-between" align="center">
              <v-col>
                <div class="old-equipment-container">
                  <equipment-card :equipment="sourceEquipment" />
                </div>
              </v-col>
              <v-col md="4" sm="12" cols="12">
                <v-text-field
                  v-model="options.search"
                  label="Search"
                  ref="mainSearch"
                  class="table-search-field"
                  dense
                  solo
                  clearable
                  hide-details
                  prepend-inner-icon="far fa-search"
                  :loading="loadingStates.table"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              dense
              :headers="headers"
              :items="entities"
              :server-items-length="totalSpaces"
              :items-per-page="15"
              :loading="loadingStates.table"
              :options.sync="options"
              class="elevation-2"
            >
              <template v-slot:[`item.imageUrl`]="{ item }">
                <v-badge
                  color="info"
                  :value="selected.id == item.id"
                  offset-x="15px"
                  offset-y="38px"
                >
                  <template v-slot:badge>
                    <i class="fas fa-wrench mb-0"></i>
                  </template>
                  <v-tooltip right z-index="999" nudge-right="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div class="space-img">
                          <v-img
                            v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                            class="img"
                            :src="item.imageUrl"
                            height="100%"
                            width="100%"
                            contain
                          ></v-img>
                          <i v-else :class="'fad fa-table-pivot'"></i>
                        </div>
                      </div>
                    </template>
                    <span>
                      <v-img
                        v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                        class="img"
                        :src="item.imageUrl"
                        height="250px"
                        width="250px"
                        contain
                      ></v-img>
                      <i
                        v-else
                        :class="'fad fa-table-pivot'"
                        style="font-size: 32px; padding: 2rem"
                      ></i>
                    </span>
                  </v-tooltip>
                </v-badge>
              </template>

              <template v-slot:[`item.name`]="{ item }">
                <strong>{{ item.name }}</strong>
                <p class="ma-0" v-if="item.description != null && item.description.trim() != ''">
                  {{ item.description }}
                </p>
              </template>

              <template v-slot:[`item.template.name`]="{ item }">
                <v-row
                  v-if="item.template != null"
                  align-content="center"
                  justify="start"
                  no-gutters
                  style="flex: none; max-width: calc(100% - 40px); flex-wrap: nowrap"
                  class="py-0"
                >
                  <v-col sm="auto" class="d-flex align-center mr-2">
                    <v-badge color="secondary" bordered left offset-x="15px" offset-y="28px">
                      <template v-slot:badge>
                        <i class="fad fa-code mb-0" style="font-size: 10px"></i>
                      </template>
                      <div class="typeahead-space-img">
                        <v-img
                          v-if="item.template.imageUrl != null && item.template.imageUrl != ''"
                          class="img"
                          :src="item.template.imageUrl"
                          height="100%"
                          width="100%"
                          contain
                        ></v-img>
                        <i v-else :class="'fad fa-table-pivot'"></i>
                      </div>
                    </v-badge>
                  </v-col>
                  <v-col class="d-flex justify-center col-auto flex-column">
                    <strong class="space-name">{{ item.template.name }}</strong>
                  </v-col>
                </v-row>
                <span v-else class="text--disabled">
                  <i class="fad fa-unlink"></i>
                </span>
              </template>

              <template v-slot:[`item.createDate`]="{ item }">
                <dater :date="item.createDate" date-only></dater>
              </template>

              <template v-slot:[`item.updateDate`]="{ item }">
                <dater :date="item.updateDate" date-only></dater>
              </template>

              <template v-slot:[`item.equipmentsCount`]="{ item }">
                <equipment-count :count="getEquipmentsCount(item)" />
              </template>

              <template v-slot:no-data>
                <img width="300" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>

              <template v-slot:loading>
                <video width="250" muted loop autoplay style="padding: 0.5rem">
                  <source
                    src="/img/art/astronaut-loves-music-4980476-4153140.mp4"
                    type="video/mp4"
                  />
                </video>
                <p class="font-weight-bold">Searching the Cosmos...</p>
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="deleteReplaceDialogDiscard" :disabled="modals.replaceEquipment.isLoading">
          <i class="fal fa-chevron-left mr-2"></i>Cancel
        </v-btn>
        <v-btn
          color="info"
          :disabled="!modals.replaceEquipment.valid || modals.replaceEquipment.isLoading"
          :loading="modals.replaceEquipment.isLoading"
          @click="deleteReplaceConfirmed()"
        >
          <i class="fal fa-check mr-2"></i> Replace
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import ApiService from "../services/equipments-service.js";
import SpaceService from "../../Spaces/services/spaces-service";
import EquipmentCard from "./EquipmentCard.vue";
import equipmentSpacesHeader from "../../Spaces/config/tables/equipmentSpaces.header.js";
export default {
  name: "replace-equipment",
  data() {
    return {
      entities: [],
      totalSpaces: 0,
      replaceEquipmentTimerId: null,
      connectedSpacesTimerId: null,
      selected: {},
      sourceEquipment: {},
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
      },
      loadingStates: {
        table: false,
      },
      headers: equipmentSpacesHeader,
      modals: {
        replaceEquipment: {
          active: false,
          valid: false,
          isLoading: false,
          equipment: null,
          searchEquipments: "",
        },
      },
      slideouts: {
        replace: {
          active: false,
          isLoading: false,
          tab: 0,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deleteReplaceDialogDiscard() {
      this.slideouts.replace.active = false;
      this.modals.replaceEquipment.equipment = null;
      this.modals.replaceEquipment.searchEquipments = "";
      this.$emit("Discard");
    },
    deleteReplaceConfirmed() {
      this.modals.replaceEquipment.isLoading = true;
      var data = {
        newEquipmentid: this.modals.replaceEquipment.equipment.id,
        oldEquipmentid: this.sourceEquipment.id,
      };

      ApiService.replaceEquipment(data)
        .then((resp) => {
          this.modals.replaceEquipment.isLoading = false;
          this.slideouts.replace.active = false;
          this.$emit("Success");
          this.$dialog.notify.success("Equipment replaced successfully!", {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((error) => {
          this.$handleError(error.request.response);
          this.modals.replaceEquipment.isLoading = false;
        });
    },
    getProjectSpaces() {
      this.loadingStates.table = true;
      if (this.sourceEquipment.id) {
        SpaceService.getByEquipmentId(this.sourceEquipment.id, this.$clean(this.options))
          .then((resp) => {
            this.entities = resp.data.items;
            this.totalSpaces = resp.data.total;

            this.loadingStates.table = false;
          })
          .catch((err) => {
            this.loadingStates.table = false;
            this.$handleError(err);
          });
      }
    },
    getEquipmentsCount(item) {
      if (item != null && item.areaGroups != null)
        return item.areaGroups.reduce((total, cur) => {
          return total + this.getAreaGroupEquipmentsCount(cur);
        }, 0);
      else return 0;
    },
    getAreaGroupEquipmentsCount(areaGroup) {
      if (areaGroup != null)
        return areaGroup.areaEquipments.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    getEquipmentSearch() {
      this.modals.replaceEquipment.isLoading = true;

      ApiService.typeHead(
        this.modals.replaceEquipment.searchEquipments
          ? this.modals.replaceEquipment.searchEquipments
          : "",
        false
      )
        .then((resp) => {
          this.modals.replaceEquipment.replaceEquipments = resp.data;
          this.modals.replaceEquipment.isLoading = false;
        })
        .catch((err) => {
          this.modals.replaceEquipment.isLoading = false;
          this.$handleError(err);
        });
    },
    open(equipment) {
      this.sourceEquipment = equipment;
      this.entities = [];
      this.totalSpaces = 0;
      this.getProjectSpaces();
      this.options.page = 1;
      this.slideouts.replace.active = true;
    },
    getProjectSpaceDebounced() {
      if (this.connectedSpacesTimerId == null) {
        this.connectedSpacesTimerId = -1;
        this.getProjectSpaces();
        return;
      }
      // cancel pending call
      clearTimeout(this.connectedSpacesTimerId);

      // delay new call 400ms
      this.connectedSpacesTimerId = setTimeout(() => {
        this.getProjectSpaces();
      }, 400);
    },
    getEquipmentSearchDebounced() {
      if (this.replaceEquipmentTimerId == null) {
        this.replaceEquipmentTimerId = -1;
        this.getEquipmentSearch();
        return;
      }
      // cancel pending call
      clearTimeout(this.replaceEquipmentTimerId);

      // delay new call 400ms
      this.replaceEquipmentTimerId = setTimeout(() => {
        this.getEquipmentSearch();
      }, 400);
    },
  },
  watch: {
    "modals.replaceEquipment.searchEquipments": function (val) {
      this.getEquipmentSearchDebounced();
    },
    options: {
      handler() {
        this.getProjectSpaceDebounced();
      },
      deep: true,
    },
  },
  mounted() {
    this.getProjectSpaces();
  },
  components: {
    EquipmentCard,
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}

// .old-equipment-container {
//   padding: 0.5rem;
//   border: 1px dashed rgba($shades-black, 0.4);
//   border-radius: 0.5rem;
// }
</style>
