import Api from "../../Shared/services/api";
import ApiUploader from "../../Shared/services/apiUploader";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "leads";
export default {
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  get(id) {
    return Api().get(`${baseUrl}/${id}`);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  clone(id) {
    return Api().post(baseUrl + `/${id}/Clone`);
  },
  archive(id) {
    return Api().patch(baseUrl + `/${id}/Archive`);
  },
  unarchive(id) {
    return Api().patch(baseUrl + `/${id}/Unarchive`);
  },
  uploadImage(imageData, config) {
    return ApiUploader().post(`${baseUrl}/Image`, imageData, config);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  checkIfLeadNameExist(name) {
    return Api().get(`${baseUrl}/${name}/Exist`);
  },
  convertToProject(leadId) {
    return Api().post(`${baseUrl}/${leadId}/Convert/Project`);
  },
  getAccessList(leadId) {
    return Api().get(`${baseUrl}/${leadId}/AccessList`);
  },
  saveAccessList(leadId, data) {
    return Api().put(`${baseUrl}/${leadId}/AccessList`, data);
  },
};
