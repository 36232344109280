export default {
  validEmail: (v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid",
  noWhiteSpaces: (v) =>
    v == null ||
    v.length == 0 ||
    (v.toString().split("  ").length <= 1 &&
      v.toString().trim().length > 0 &&
      v.toString()[0] != " " &&
      v.toString()[v.length - 1] != " ") ||
    "White spaces are not allowed!",
  length: (len) => (v) => (v || "").length >= len || `More than ${len} characters are required!`,
  lengthMax: (len) => (v) =>
    (v || "").length <= len || `More than ${len} characters are NOT allowed!`,
  lengthExact: (len) => (v) =>
    (v || "").length == len || (v || "").length == 0 || `Only ${len} characters are valid!`,
  intNumber: (v) => /^[-+]?[0-9]\d*$/.test(v) || (v || "").length == 0 || "Only Integers are valid",
  number: (v) =>
    /^(-?[0-9]*.[0-9]+|[0-9]+)$/.test(v) || (v || "").length == 0 || "Only numbers are valid",
  numberFF: (v) => /^(-?[0-9]*.[0-9]+|[0-9]+)$/.test(v) || "" || "Only numbers are valid", //for firefox
  // number: (v) =>
  //   /^[0-9]+.[0-9]+|[0-9]+$/.test(v) || (v || "").length == 0 || "Only numbers are valid",
  numberMin: (min) => (v) => (v || "") >= min || `Minimum number is ${min}!`,
  numberMax: (max) => (v) => (v || "") <= max || `Maximum number is ${max}!`,
  isClientAbbr: (v) =>
    /^[0-9A-Z\.\_\-]{2,6}$/.test(v) ||
    (v || "").length == 0 ||
    "Min of 2 to Max of 6 Capital Letters, Numbers, or special characters '_', '-' or '.'",
  usPostalCode: (v) => /^[0-9]{5}$/i.test(v) || (v || "").length == 0 || "Invalid US Postal Code",
  mexicoPostalCode: (v) =>
    /^[0-9]{5}$/i.test(v) || (v || "").length == 0 || "Invalid Mexican Postal Code",
  canadaPostalCode: (v) =>
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(v) ||
    (v || "").length == 0 ||
    "Invalid Canadian Postal Code",
  polandPostalCode: (v) =>
    /^[0-9]{2}-[0-9]{3}$|^[0-9]{5}$/i.test(v) ||
    (v || "").length == 0 ||
    "Invalid Polish Postal Code",
  password: (v) =>
    !!(v || "").match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
    "Password must contains at least a capital letter, a small letter, a number & a special character!",
  required: (v) => !!v || "This field is required!",
  requiredNumber: (v) => !!v || v === 0 || "This field is required!",
  confirmPassword: (value, password) => {
    return value === password || "Password doesn't match";
  },
  emailRules: [
    (v) => !!v || "E-mail is required",
    (v) => /.+@.+/.test(v) || "E-mail must be valid",
  ],
  arrayNotEmpty: (v) => (v || []).length > 0 || `Select at least one!`,
  //   /^(https?:\/\/(?:www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b)*(\/[\/\d\w\.-]*)*(?:[\?])*(.+)*)$/
  url: (v) =>
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
      v
    ) ||
    (v || "").length == 0 ||
    "Invalid URL!",
  urlForced: (v) =>
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
      v
    ) || "Invalid URL!",
  isUrl: (v) =>
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
      v
    ) || "Invalid URL!",
  tempoLink: (v) => /io.tempo/.test(v) || (v || "").length == 0 || "Invalid Tempo URL!",
  guid: (v) =>
    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/.test(
      v
    ) ||
    (v || "").length == 0 ||
    "Invalid GUID!",
};
