<template>
  <v-sheet
    elevation="2"
    height="32"
    class="d-flex flex-row align-center"
    style="padding-right: 2px"
    rounded
  >
    <v-menu dense offset-y right v-if="actionsMenu" z-index="1000" v-model="mainMenuOpen">
      <template v-slot:activator="{ attrs: menuAttrs, on: menuOn, value }">
        <v-tooltip bottom z-index="999" nudge-bottom="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="menuAttrs" v-on="menuOn">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-1"
                :text="!value"
                height="28px"
                width="32px"
                min-height="28px"
                min-width="32px"
                color="deep-purple"
                :dark="value"
                style="text-transform: none !important"
              >
                <i class="far fa-calendar-lines-pen fs-16px"></i>
              </v-btn>
            </div>
          </template>
          <span class="d-flex align-center">Update Range</span>
        </v-tooltip>
      </template>
      <v-list class="more-options-menu">
        <v-menu dense offset-x right z-index="1000" max-width="300px" open-on-hover>
          <template v-slot:activator="{ attrs, on, value }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              :class="{
                'deep-purple lighten-4': value,
              }"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-arrows-left-right-to-line"></i>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">Shift Whole Range</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="justify-end">
                <i class="fas fa-caret-right"></i>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list class="more-options-menu more-options-menu-deep-purple-hover">
            <v-subheader class="secondary--text" style="height: 32px">Shift Forward</v-subheader>
            <v-list-item @click="shiftForward1Month" class="py-0" style="height: auto !important">
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-forward-step"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold"> By 1 Month </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ getNextMonth(startDateValue) }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ getNextMonth(endDateValue) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="shiftForwardByRange" class="py-0" style="height: auto !important">
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-forward-fast"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  By Range Length
                  <span class="info--text ml-1">
                    +{{ calcDateOffset(startDateValue, endDateValue) + 1 }} months
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ getNextRange(startDateValue, endDateValue).startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ getNextRange(startDateValue, endDateValue).endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-subheader class="secondary--text" style="height: 32px">Shift Backward</v-subheader>
            <v-list-item @click="shiftBackward1Month" class="py-0" style="height: auto !important">
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-backward-step"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold"> By 1 Month </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ getPrevMonth(startDateValue) }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ getPrevMonth(endDateValue) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="shiftBackwardByRange" class="py-0" style="height: auto !important">
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-backward-fast"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  By Range Length
                  <span class="pink--text ml-1">
                    -{{ calcDateOffset(startDateValue, endDateValue) + 1 }} months
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ getPrevRange(startDateValue, endDateValue).startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ getPrevRange(startDateValue, endDateValue).endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu dense offset-x right z-index="1000" max-width="300px" open-on-hover>
          <template v-slot:activator="{ attrs, on, value }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              :class="{
                'deep-purple lighten-4': value,
              }"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-circle-quarter-stroke"></i>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">Select a Quarter</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="justify-end">
                <i class="fas fa-caret-right"></i>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list class="more-options-menu more-options-menu-deep-purple-hover">
            <v-list-item
              @click="selectRange(prevYearQ4)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fas fa-circle" style="z-index: 1"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  {{ prevYear }} - Q4
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ prevYearQ4.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ prevYearQ4.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item
              @click="selectRange(crntYearQ1)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-circle-quarter-stroke"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  {{ crntYear }} - Q1
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ crntYearQ1.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ crntYearQ1.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="selectRange(crntYearQ2)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-circle-half-stroke"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  {{ crntYear }} - Q2
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ crntYearQ2.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ crntYearQ2.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="selectRange(crntYearQ3)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-circle-three-quarters-stroke"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  {{ crntYear }} - Q3
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ crntYearQ3.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ crntYearQ3.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="selectRange(crntYearQ4)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fas fa-circle" style="z-index: 1"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  {{ crntYear }} - Q4
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ crntYearQ4.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ crntYearQ4.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item
              @click="selectRange(nextYearQ1)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-circle-quarter-stroke"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  {{ nextYear }} - Q1
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ nextYearQ1.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ nextYearQ1.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu dense offset-x right z-index="1000" max-width="300px" open-on-hover>
          <template v-slot:activator="{ attrs, on, value }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              :class="{
                'deep-purple lighten-4': value,
              }"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-calendar-days"></i>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">Select a Year</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="justify-end">
                <i class="fas fa-caret-right"></i>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list class="more-options-menu more-options-menu-deep-purple-hover">
            <v-list-item
              @click="selectRange(prevYearFull)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-backward"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  <span class="text--secondary mr-2">Past Year:</span>
                  <u>{{ prevYear }}</u>
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ prevYearFull.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ prevYearFull.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="selectRange(crntYearFull)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-flag"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  <span class="text--secondary mr-2">Current Year:</span>
                  <u>{{ crntYear }}</u>
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ crntYearFull.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ crntYearFull.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="selectRange(nextYearFull)"
              class="py-0"
              style="height: auto !important"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <i class="fad fa-forward"></i>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">
                  <span class="text--secondary mr-2">Next Year:</span>
                  <u>{{ nextYear }}</u>
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">
                  {{ nextYearFull.startDate }}
                  <i class="far fa-arrow-right-long text--secondary mx-1"></i>
                  {{ nextYearFull.endDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>
    <v-btn
      v-if="!actionsMenu"
      class="mr-1"
      @click="monthBackward"
      text
      height="28px"
      width="32px"
      min-height="28px"
      min-width="32px"
      color="secondary"
      style="text-transform: none !important"
    >
      <i class="fad fa-backward-fast"></i>
    </v-btn>
    <v-btn
      v-if="!actionsMenu"
      class="mr-1"
      @click="monthBackward"
      text
      height="28px"
      width="32px"
      min-height="28px"
      min-width="32px"
      color="secondary"
      style="text-transform: none !important"
    >
      <i class="fad fa-backward-step"></i>
    </v-btn>
    <v-menu
      v-model="dateStartMonthMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      z-index="9999"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field-alt
          prepend-inner-icon="mdi-calendar"
          readonly
          short
          :class="{
            'deep-purple lighten-5': dateStartMonthMenu,
            'blue-grey lighten-5': !dateStartMonthMenu,
          }"
          class="px-0"
          style="width: 100px; min-width: 100px"
          placeholder="Start Month"
          v-model="startDateValue"
          hide-details
          v-bind="attrs"
          v-on="on"
        ></v-text-field-alt>
      </template>
      <v-date-picker
        color="orange darken-3"
        header-color="secondary"
        v-model="startDateValue"
        type="month"
        elevation="4"
        @input="dateStartMonthMenu = false"
      ></v-date-picker>
    </v-menu>
    <label class="fs-13px ma-0 mx-2" style="font-weight: 600">
      <i class="far fa-arrow-right-long text--secondary"></i>
    </label>
    <v-menu
      v-model="dateEndMonthMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      z-index="9999"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field-alt
          prepend-inner-icon="mdi-calendar"
          readonly
          short
          :class="{
            'deep-purple lighten-5': dateEndMonthMenu,
            'blue-grey lighten-5': !dateEndMonthMenu,
          }"
          class="px-0"
          style="width: 100px; min-width: 100px"
          placeholder="End Month"
          v-model="endDateValue"
          v-bind="attrs"
          hide-details
          v-on="on"
        ></v-text-field-alt>
      </template>
      <v-date-picker
        color="orange darken-3"
        header-color="secondary"
        v-model="endDateValue"
        type="month"
        elevation="4"
        @input="dateEndMonthMenu = false"
      ></v-date-picker>
    </v-menu>
    <v-btn
      v-if="!actionsMenu"
      class="ml-1"
      @click="monthForward"
      text
      height="28px"
      width="32px"
      min-height="28px"
      min-width="32px"
      color="secondary"
      style="text-transform: none !important"
    >
      <i class="fad fa-forward-step"></i>
    </v-btn>
    <v-btn
      v-if="!actionsMenu"
      class="ml-1"
      @click="monthForward"
      text
      height="28px"
      width="32px"
      min-height="28px"
      min-width="32px"
      color="secondary"
      style="text-transform: none !important"
    >
      <i class="fad fa-forward-fast"></i>
    </v-btn>
  </v-sheet>
</template>

<script>
export default {
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    actionsMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainMenuOpen: false,
      startDateValue: null,
      endDateValue: null,
      dateStartMonthMenu: false,
      dateEndMonthMenu: false,
    };
  },
  methods: {
    selectRange(range) {
      this.startDateValue = range.startDate;
      this.endDateValue = range.endDate;
      this.mainMenuOpen = false;
    },
    getOffsetMonth(month, offset) {
      var dateSegments = month.split("-");
      var totalMonthsCount = Number.parseInt(dateSegments[1]) + offset;
      var offsetMonth = totalMonthsCount % 12;
      var offsetYears = Math.floor(totalMonthsCount / 12);

      if (offsetMonth == 0) {
        offsetMonth = 12;
        offsetYears--;
      } else if (offsetMonth < 0) offsetMonth = 12 - Math.abs(offsetMonth);

      var newMonth = `${Number.parseInt(dateSegments[0]) + offsetYears}-${Math.abs(offsetMonth)
        .toString()
        .padStart(2, "0")}`;

      return newMonth;
    },
    getNextMonth(month) {
      var dateSegments = month.split("-");
      if (Number.parseInt(dateSegments[1]) == 12)
        return `${Number.parseInt(dateSegments[0]) + 1}-${(1).toString().padStart(2, "0")}`;
      else
        return `${Number.parseInt(dateSegments[0])}-${(Number.parseInt(dateSegments[1]) + 1)
          .toString()
          .padStart(2, "0")}`;
    },
    getPrevMonth(month) {
      var dateSegments = month.split("-");
      if (Number.parseInt(dateSegments[1]) == 1)
        return `${Number.parseInt(dateSegments[0]) - 1}-${(12).toString().padStart(2, "0")}`;
      else
        return `${Number.parseInt(dateSegments[0])}-${(Number.parseInt(dateSegments[1]) - 1)
          .toString()
          .padStart(2, "0")}`;
    },
    monthDiff(dt1, dt2) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60 * 24 * 30;
      return Math.round(diff);
    },
    calcDateOffset(dateA, dateB) {
      return this.monthDiff(new Date(dateA), new Date(dateB));
    },
    getNextRange(dateA, dateB) {
      var offset = this.calcDateOffset(dateA, dateB);
      if (offset > 0) offset++;
      else if (offset < 0) offset--;
      var startDate = this.getOffsetMonth(dateA, offset); //starts right after the end date
      var endDate = this.getOffsetMonth(dateB, offset);
      return {
        startDate: startDate,
        endDate: endDate,
      };
    },
    getPrevRange(dateA, dateB) {
      var offset = this.calcDateOffset(dateA, dateB);
      if (offset > 0) offset++;
      else if (offset < 0) offset--;
      var startDate = this.getOffsetMonth(dateA, -offset); //starts right after the end date
      var endDate = this.getOffsetMonth(dateB, -offset);
      return {
        startDate: startDate,
        endDate: endDate,
      };
    },
    shiftForward1Month() {
      this.startDateValue = this.getOffsetMonth(this.startDateValue, 1);
      this.endDateValue = this.getOffsetMonth(this.endDateValue, 1);
    },
    shiftBackward1Month() {
      this.startDateValue = this.getOffsetMonth(this.startDateValue, -1);
      this.endDateValue = this.getOffsetMonth(this.endDateValue, -1);
    },
    shiftForwardByRange() {
      var nextRange = this.getNextRange(this.startDateValue, this.endDateValue);
      this.startDateValue = nextRange.startDate;
      this.endDateValue = nextRange.endDate;
    },
    shiftBackwardByRange() {
      var nextRange = this.getPrevRange(this.startDateValue, this.endDateValue);
      this.startDateValue = nextRange.startDate;
      this.endDateValue = nextRange.endDate;
    },
  },
  created() {
    this.startDateValue = this.startDate;
    this.endDateValue = this.endDate;
  },
  computed: {
    crntYear() {
      return new Date().getFullYear();
    },
    prevYear() {
      return this.crntYear - 1;
    },
    nextYear() {
      return this.crntYear + 1;
    },
    prevYearFull() {
      return {
        startDate: `${this.prevYear}-01`,
        endDate: `${this.prevYear}-12`,
      };
    },
    crntYearFull() {
      return {
        startDate: `${this.crntYear}-01`,
        endDate: `${this.crntYear}-12`,
      };
    },
    nextYearFull() {
      return {
        startDate: `${this.nextYear}-01`,
        endDate: `${this.nextYear}-12`,
      };
    },
    prevYearQ4() {
      return {
        startDate: `${this.prevYear}-10`,
        endDate: `${this.prevYear}-12`,
      };
    },
    crntYearQ1() {
      return {
        startDate: `${this.crntYear}-01`,
        endDate: `${this.crntYear}-03`,
      };
    },
    crntYearQ2() {
      return {
        startDate: `${this.crntYear}-04`,
        endDate: `${this.crntYear}-07`,
      };
    },
    crntYearQ3() {
      return {
        startDate: `${this.crntYear}-08`,
        endDate: `${this.crntYear}-10`,
      };
    },
    crntYearQ4() {
      return {
        startDate: `${this.crntYear}-10`,
        endDate: `${this.crntYear}-12`,
      };
    },
    nextYearQ1() {
      return {
        startDate: `${this.nextYear}-01`,
        endDate: `${this.nextYear}-03`,
      };
    },
  },
  watch: {
    startDate: {
      handler(val) {
        this.startDateValue = this.startDate;
      },
    },
    endDate: {
      handler(val) {
        this.endDateValue = this.endDate;
      },
    },
    startDateValue: {
      handler(val) {
        this.$emit("update:start-date", this.startDateValue);
      },
    },
    endDateValue: {
      handler(val) {
        this.$emit("update:end-date", this.endDateValue);
      },
    },
  },
  components: {},
};
</script>
