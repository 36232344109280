var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entryData != null)?_c('v-data-table',{staticClass:"elevation-2 mt-4 table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.recordsList,"items-per-page":30,"sort-by":_vm.sortBy,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'far fa-arrow-to-left',
    lastIcon: 'far fa-arrow-to-right',
    prevIcon: 'far fa-angle-left',
    nextIcon: 'far fa-angle-right',
    itemsPerPageOptions: [15, 30, 50, 100],
  }},scopedSlots:_vm._u([{key:"header.sellCost",fn:function(){return [(_vm.isDirectExpense)?_c('span',[_vm._v("Revenue")]):_c('span',[_vm._v("Sell Rate")])]},proxy:true},{key:"header.laborCost",fn:function(){return [(_vm.isDirectExpense)?_c('span',[_vm._v("Cost")]):_c('span',[_vm._v("Cost Rate")])]},proxy:true},{key:"item.project.fullName",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"main-title font-weight-medium"},[_vm._v(" "+_vm._s(item.project.fullName)+" ")]),_c('entity-id',{staticClass:"ml-2",attrs:{"path":("projects/" + (item.project.id)),"title":"Project","hideLabel":"","tooltipOnTop":""}})]}},{key:"item.client.name",fn:function(ref){
  var item = ref.item;
return [(item.client)?_c('v-tooltip',{attrs:{"top":"","nudge-top":"-16px","z-index":"999999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.viewClient(item.client.id)}}},'div',attrs,false),on),[(item.client.logoUrl != null && item.client.logoUrl != '')?_c('v-img',{attrs:{"src":item.client.logoUrl,"max-height":"28","max-width":"54","position":"left center","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","max-height":"28","max-width":"54","position":"left center","contain":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.client.name))])]):_vm._e()]}},{key:"item.contract.id",fn:function(ref){
  var item = ref.item;
return [_c('code',{staticClass:"mono-font ml-2 secondary--text fs-12px",class:{
        'brown lighten-4 brown--text': item.contract.isArchived,
        'blue-grey lighten-5':
          !item.contract.isArchived && item.contract.status == _vm.enums.CONTRACT_STATUS.Draft.value,
        'orange lighten-4':
          !item.contract.isArchived &&
          item.contract.status == _vm.enums.CONTRACT_STATUS.Pending.value,
        'green lighten-4':
          !item.contract.isArchived &&
          item.contract.status == _vm.enums.CONTRACT_STATUS.Approved.value,
      },staticStyle:{"flex":"none"}},[_vm._v(" #"+_vm._s(item.contract.id)+" ")])]}},{key:"item.contract.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.contract.name)+" ")]}},{key:"item.contract.status",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex align-center"},[(item.contract.isArchived)?_c('v-chip',{staticClass:"px-2",attrs:{"small":"","label":"","color":'brown',"text-color":'white'}},[_c('i',{staticClass:"fad fa-archive mr-2"}),_c('span',{staticClass:"font-weight-medium",staticStyle:{"line-height":"0"}},[_vm._v(" Archived ")])]):_c('contract-status',{attrs:{"small":"","status":item.contract.status}})],1)]}},{key:"item.constructionPhase.startDate",fn:function(ref){
      var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.constructionPhase.startDate.substr(0, 7)))])]}},{key:"item.constructionPhase.name",fn:function(ref){
      var item = ref.item;
return [_c('construction-phase-type',{staticClass:"mr-1",attrs:{"small":"","type":item.constructionPhase.type}}),_vm._v(" "+_vm._s(item.constructionPhase.name)+" ")]}},{key:"item.constructionPhaseRecord",fn:function(ref){
      var item = ref.item;
return [_c('h4',{staticClass:"d-flex align-start flex-column",staticStyle:{"width":"100%"}},[(item.record.laborType != null && item.record.laborType != 0)?_c('span',{staticClass:"d-flex flex-column py-1",staticStyle:{"width":"100%"}},[_c('label',{staticClass:"d-inline-flex blue-grey--text align-center mb-1",staticStyle:{"font-weight":"700 !important","font-size":"12px","flex":"none","line-height":"1"}},[_c('i',{staticClass:"fas fa-caret-right fs-8px mr-1",staticStyle:{"line-height":"1"}}),_vm._v(" "+_vm._s(_vm.getLaborTypeObj(item.record.laborType).desc)+" ")]),(item.record.recordType == 0)?_c('user-all-selector',{attrs:{"users":_vm.users,"required":"","hideLabel":"","hideDetails":"","hideBorder":"","readonly":"","small":"","responsive":"","isUsersLoading":_vm.isUsersLoading},model:{value:(item.record.userId),callback:function ($$v) {_vm.$set(item.record, "userId", $$v)},expression:"item.record.userId"}}):_c('user-avatar',{attrs:{"mini":"","showNoUser":"","noUserText":item.record.placeholderName,"noUserIcon":"fas fa-question"}})],1):_c('div',{staticClass:"d-flex align-start flex-column"},[_c('label',{staticClass:"d-inline-flex blue-grey--text align-center",staticStyle:{"font-weight":"700 !important","font-size":"12px","flex":"none","line-height":"1"}},[_c('i',{staticClass:"fad mr-1",class:_vm.getExpenseCategoryObj(item.record.category).icon}),_vm._v(" "+_vm._s(_vm.getExpenseCategoryObj(item.record.category).text)+" ")]),_c('span',{staticClass:"fs-12px"},[_vm._v(_vm._s(item.record.description))])])])]}},{key:"item.category",fn:function(ref){
      var item = ref.item;
return [_c('label',{staticClass:"d-flex mb-0 align-center",staticStyle:{"font-weight":"600 !important","font-size":"14px","flex":"none","gap":"0.5rem"}},[_c('i',{staticClass:"fad ml-1",class:_vm.getExpenseCategoryObj(item.category).icon}),_vm._v(" "+_vm._s(_vm.getExpenseCategoryObj(item.category).text)+" ")])]}},{key:"item.description",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.description))]}},{key:"item.hours",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.hours))]}},{key:"item.sellCost",fn:function(ref){
      var item = ref.item;
return [(_vm.isDirectExpense)?_c('span',[_vm._v(_vm._s(_vm._f("usdFormat")(item.sellCost)))]):_c('span',[_vm._v(_vm._s(_vm._f("usdFormat")(item.sellCost))+" /hr")])]}},{key:"item.laborCost",fn:function(ref){
      var item = ref.item;
return [(_vm.isDirectExpense)?_c('span',[_vm._v(_vm._s(_vm._f("usdFormat")(item.laborCost)))]):_c('span',[_vm._v(_vm._s(_vm._f("usdFormat")(item.laborCost))+" /hr")])]}},{key:"item.total",fn:function(ref){
      var item = ref.item;
return [(_vm.showLaborRates)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("usdFormat")((item.hours * item.laborCost))))]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("usdFormat")((item.hours * item.sellCost))))])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }