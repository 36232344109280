<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 800 : 1100"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Access Group ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> Access Group: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <entity-id
          class="ml-2"
          :label="selected.id"
          :path="accessGroupUrl"
          title="Access Group"
        ></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create an Access Group
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-form v-model="valid" ref="updateForm">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.required, allRules.noWhiteSpaces]"
              label="Name"
              id="name"
              placeholder="Name"
              v-model="selected.name"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-textarea-alt
              :rules="[allRules.required, allRules.noWhiteSpaces]"
              label="Notes"
              id="Note"
              :rows="1"
              placeholder="Notes"
              v-model="selected.notes"
              :readonly="readonly"
            >
            </v-textarea-alt>
          </v-col>
          <v-col cols="12">
            <v-btn @click="openUserSlide()" :disabled="readonly" color="info" class="mb-2">
              <i class="fas fa-plus mr-2" style="font-size: 10px"></i>Add User
            </v-btn>
            <v-data-table
              dense
              :headers="headers"
              :items="selected.users"
              class="elevation-2"
              hide-default-footer
              disable-pagination
              item-key="id"
              :loading="isFetching"
            >
              <template v-slot:no-data>
                <img width="300" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>
              <template v-slot:[`item.firstName`]="{ item }">
                <v-row style="margin: 5px 0px">
                  <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
                </v-row>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <a :href="'mailto:' + item.email">{{ item.email }}</a>
              </template>

              <template v-slot:[`header.defaultLaborType`]>
                <i class="fad fa-wrench-simple mr-2"></i>Default Labor Type
              </template>

              <template v-slot:[`item.defaultLaborType`]="{ item }">
                <div class="d-flex align-center font-weight-bold" style="font-size: 12px">
                  {{ getEnumMember(enums.LABOR_TYPE_LIST, item.defaultLaborType).desc }}
                </div>
              </template>

              <template v-slot:[`item.isSuspended`]="{ item }">
                <v-chip v-if="item.isSuspended" x-small color="error"> Suspended </v-chip>
                <v-chip v-else x-small color="success"> Active </v-chip>
              </template>

              <template v-slot:[`item.roles`]="{ item }">
                <v-row style="margin: 5px 0px">
                  <v-chip
                    style="font-size: 11px; font-weight: 600"
                    v-for="role in item.roles"
                    :key="role.name"
                    label
                    x-small
                    color="deep-purple"
                    text-color="white"
                    :ripple="false"
                    link
                  >
                    {{ role.name }}
                  </v-chip>
                </v-row>
              </template>

              <template v-slot:[`item.joinDate`]="{ item }">
                <dater :date="item.joinDate" date-only></dater>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn :disabled="readonly" color="error" icon @click="del(item.id)"
                  ><v-icon>fal fa-trash-alt</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <add-users-to
        @confirm="slideoutsConfirmed"
        :disabled-users="selected.users"
        :nested="true"
        ref="addUserTo"
      ></add-users-to>
    </v-form>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="
            !isFetching &&
            readonly &&
            !selected.isLocked &&
            isMainRoute &&
            $has(perms.AccessGroups.Update)
          "
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly && !selected.isLocked">
          <v-tooltip top z-index="999" nudge-top="-4px" key="refetch-btn">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && !selected.isLocked && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly && !selected.isLocked">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly && !selected.isLocked">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px" key="refetch-btn">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchAccessGroup()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Access Group</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item
              @click="delAccessGroup"
              v-if="$has(perms.AccessGroups.Delete) && !selected.isLocked"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.AccessGroups.Delete)"
            >
              No Actions Available!
            </v-subheader>
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import AddUsersTo from "../../Workload/components/AddUsersTo.vue";
import userHeader from "../../Admin/config/tables/user.header";
import ApiService from "../services/access-group-service.js";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
export default {
  name: "edit-access-group",
  data() {
    return {
      perms,
      enums,
      accessGroupId: null,
      isFetching: false,
      valid: false,
      selected: {
        name: "",
        users: [],
        notes: "",
      },
      headers: userHeader,
      readonly: false,
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
          fullWidth: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  computed: {
    accessGroupUrl() {
      if (this.selected == null) return "";
      return `access-groups/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-access-group") return true;
      else return false;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "1100px";
    },
  },
  methods: {
    switchToEditMode() {
      this.readonly = false;
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    openUserSlide() {
      this.$refs.addUserTo.open();
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    slideoutsConfirmed(newUsers) {
      newUsers.forEach((elm) => {
        const index = this.selected.users.findIndex((user) => user.id == elm.id);
        if (index == -1) {
          this.selected.users.push(elm);
        } else {
          this.selected.users.splice(index, 1, elm);
        }
      });
    },
    delAccessGroup() {
      this.$emit("onDeleteAccessGroup");
    },
    del(id) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this user?`,
          title: `Delete User?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                const index = this.selected.users.findIndex((elm) => elm.id == id);
                if (index !== -1) {
                  this.selected.users.splice(index, 1);
                  this.$dialog.notify.info(
                    "The deletion will take effect after the changes has been saved",
                    {
                      position: "top-right",
                      timeout: 3000,
                    }
                  );
                }
              },
            },
          },
        })
        .then((res) => {});
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = { name: "", users: [], notes: "" };
      this.cementAccessGroup();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewAccessGroup) {
      this.$emit("save", this.selected, isNewAccessGroup);
    },
    updateConfirmed(canHide) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      toSend.UserIds = toSend.users.map((elm) => elm.id);
      ApiService.update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          this.$log(">>> updated", resp.data);
          var message = "Access Group updated successfully!";
          this.selected = resp.data;
          this.cementAccessGroup();
          var isNewAccessGroup = false;
          this.readonly = true;
          if (!toSend.id) {
            this.accessGroupId = this.selected.id;
            isNewAccessGroup = true;
            message = "Access Group added successfully!";
          }
          this.announceChange(isNewAccessGroup);
          if (canHide) this.closeUpdateSlideout();

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
      this.slideouts.update.isLoading = false;
    },
    open(id, editMode = false) {
      if (id == null) {
        this.readonly = false;
        this.isFetching = false;
        this.accessGroupId = null;
        this.cementAccessGroup();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
      } else {
        this.readonly = !editMode;
        this.accessGroupId = id;
        this.fetchAccessGroup();
      }
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
      }, 250);
    },

    fetchAccessGroup() {
      this.isFetching = true;
      ApiService.getById(this.accessGroupId)
        .then((resp) => {
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementAccessGroup();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.closeUpdateSlideout();
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    cementAccessGroup() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
  },
  watch: {
    accessGroup: {
      handler() {
        this.selected = this.cloneDeep(this.accessGroup || {});
        this.cementAccessGroup();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
  components: {
    AddUsersTo,
    PanelSizeControl,
  },
};
</script>
