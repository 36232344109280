<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 700"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Lead ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-pen-square mr-2"></i> Lead: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <entity-id class="ml-2" :label="selected.id" :path="leadsUrl" title="Lead"></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a Lead
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>
    <div color="white" style="position: sticky; top: 0; z-index: 1">
      <v-tabs
        v-model="slideouts.update.tab"
        color="error"
        v-if="slideouts.update.active && !isFetching"
      >
        <v-tab :key="0">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!projectInfoTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Project Info
        </v-tab>
        <v-tab :key="1">Timeline</v-tab>
        <v-tab :key="2">Budget</v-tab>
        <!-- <v-tab :key="3">Assignees</v-tab> -->
        <v-tab :key="3">Contacts</v-tab>
        <v-tab :key="4" v-if="$has(perms.LeadAccessList.View)">Lead Access List</v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-container class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Lead ...</p>
    </v-container>
    <v-container fluid class="pa-0" v-else>
      <v-form v-model="slideouts.update.valid" ref="updateForm">
        <v-tabs-items v-model="slideouts.update.tab">
          <v-tab-item :key="0">
            <v-container class="pa-4">
              <h3 class="text--primary font-weight-black">Basic Details</h3>
              <v-divider class="mb-3 mt-2"></v-divider>
              <v-row class="mt-3 mb-0">
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Name"
                    id="projectName"
                    ref="projectNameBox"
                    placeholder="Project Name"
                    v-model="selected.name"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Lead Status"
                    id="LeadStatus"
                    placeholder="Lead Status"
                    v-model="selected.status"
                    :items="leadStatusList"
                    dense
                    filled
                    item-text="text"
                    item-value="value"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="{ item }">
                      <lead-status :status="item.value"></lead-status>
                    </template>
                    <template v-slot:item="{ item }">
                      <lead-status :status="item.value"></lead-status>
                    </template>
                  </v-select-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-select-alt
                    label="Project Type"
                    id="ProjectType"
                    placeholder="Project Type"
                    v-model="selected.type"
                    :items="enums.PROJECT_TYPE_LIST"
                    dense
                    filled
                    :readonly="readonly"
                  >
                    <template v-slot:selection="{ item }">
                      <span>
                        <b class="blue-grey white--text rounded px-2 mr-1">{{ item.letter }}</b>
                        {{ item.text }}
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>
                        <b class="blue-grey white--text rounded px-2 mr-1">{{ item.letter }}</b>
                        {{ item.text }}
                      </span>
                    </template>
                  </v-select-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.noWhiteSpaces]"
                    label="Project Number"
                    id="projectNumber"
                    ref="projectNumberBox"
                    placeholder="Project Number"
                    v-model="selected.number"
                    readonly
                  >
                  </v-text-field-alt>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="py-0">
                  <client-selector
                    v-model="selected.clientId"
                    :obj.sync="selected.client"
                    :readonly="readonly"
                    required
                  >
                  </client-selector>
                </v-col>

                <v-col
                  cols="12"
                  :md="selected.id == null ? 6 : 6"
                  sm="12"
                  class="py-0 d-flex align-center"
                  key="ms-teams-col"
                  style="gap: 0.75rem"
                >
                  <microsoft-teams-selector
                    style="width: 100%"
                    ref="microsoftTeamsSelector"
                    :label="
                      selected.createMSTeam
                        ? `<img src='${defaultTeamsLogo}' style='height: 14px;' class='mr-2'> MS Team ${toBeAutoGenerated}`
                        : `<img src='${defaultTeamsLogo}' style='height: 14px;' class='mr-2'> MS Team`
                    "
                    :team-id.sync="selected.microsoftTeamId"
                    :site-name.sync="selected.microsoftGeneralSiteName"
                    :general-channel-id.sync="selected.microsoftGeneralChannelId"
                    :auto-generate="selected.createMSTeam"
                    :readonly="
                      readonly ||
                      selected.createMSTeam ||
                      selected.msTeamCreationStatus == enums.LinkedProjectStatus.Pending.value ||
                      selected.msTeamCreationStatus == enums.LinkedProjectStatus.Inprogress.value ||
                      selected.msTeamCreationStatus == enums.LinkedProjectStatus.Succeeded.value
                    "
                  >
                    <template v-slot:label-append v-if="selected.id != null">
                      <v-tooltip
                        left
                        max-width="320px"
                        z-index="99999"
                        class="ml-2"
                        color="secondary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="text--secondary"
                            style="font-size: 18px; line-height: 0"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fad fa-info-circle"></i>
                          </div>
                        </template>
                        <span style="font-size: 14px"> Auto Creation: {{ msTeamStatus }} </span>
                      </v-tooltip>
                    </template>
                  </microsoft-teams-selector>
                  <div
                    v-if="selected.id == null && $has(perms.Leads.CreateMsTeam)"
                    class="d-flex flex-row-reverse align-center justify-end"
                    style="gap: 0.25rem; height: 100%"
                  >
                    <label class="v-switch-label" for="createMSTeam" style="white-space: nowrap">
                      Auto Create?
                    </label>
                    <v-switch
                      id="createMSTeam"
                      color="orange"
                      class="ma-0 pa-0"
                      v-model="selecetd.createMSTeam"
                      hide-details
                    ></v-switch>
                  </div>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-6">
                  <h4 class="font-weight-bold">Lead AC</h4>
                  <user-all-selector
                    :users="users"
                    label="Lead AC"
                    hideLabel
                    hideDetails
                    :readonly="isFetching || readonly"
                    :isUsersLoading="isUsersLoading"
                    v-model="selected.leadACId"
                    style="width: 100%"
                  ></user-all-selector>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-6">
                  <h4 class="font-weight-bold">Lead VI</h4>
                  <user-all-selector
                    :users="users"
                    label="Lead VI"
                    hideLabel
                    hideDetails
                    :readonly="isFetching || readonly"
                    :isUsersLoading="isUsersLoading"
                    v-model="selected.leadVIId"
                    style="width: 100%"
                  ></user-all-selector>
                </v-col>

                <v-col cols="12" md="4" sm="12" class="py-0">
                  <single-image-uploader
                    id="add-lead-uploader"
                    :apiUrl="imageApiUrl"
                    v-model="selected.imageUrl"
                    label="Project Image"
                    full-height
                    :disabled="isFetching || readonly"
                  ></single-image-uploader>
                </v-col>

                <v-col cols="12" md="8" sm="12" class="py-0">
                  <rich-text-editor
                    v-model="selected.description"
                    title="Description"
                    showLabel
                    :readonly="readonly"
                    allowExpand
                  />
                </v-col>
              </v-row>
              <h3 class="text--primary font-weight-black mt-4">Project Location</h3>
              <v-divider class="mb-3 mt-2"></v-divider>
              <address-detail :readonly="readonly" v-model="selected"></address-detail>
            </v-container>
          </v-tab-item>
          <v-tab-item :key="1">
            <project-timeline :selected="selected" :readonly="readonly"></project-timeline>
          </v-tab-item>
          <v-tab-item :key="2" eager>
            <project-budget v-model="selected" :readonly="readonly"></project-budget>
          </v-tab-item>
          <!-- <v-tab-item :key="3">
              <assignees :selected="selected" :readonly="readonly"></assignees>
            </v-tab-item> -->
          <v-tab-item :key="3">
            <contacts :selected="selected" :readonly="readonly"></contacts>
          </v-tab-item>
          <v-tab-item :key="4">
            <lead-access-list
              :selected="selected"
              :readonly="readonly"
              :leadId="Number(leadId)"
            ></lead-access-list>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-btn
          v-if="
            $has(perms.Leads.Update) &&
            selected.id != null &&
            !selected.isArchived &&
            !selected.isConvertedToProject
          "
          :disabled="slideouts.update.hasChanges"
          color="indigo"
          dark
          @click="convertToOpportunity()"
        >
          <i class="fas fa-lightbulb-on mr-2"></i>Convert To Opportunity
        </v-btn>
        <v-spacer></v-spacer>
        <v-sheet
          v-if="selected.isConvertedToProject"
          rounded="lg"
          color="yellow darken-2"
          class="d-inline-flex align-center font-weight-medium px-3 mr-4"
          style="
            gap: 0.5rem;
            border: 1px dashed rgba(0, 0, 0, 0.4) !important;
            border-left: 5px solid rgba(0, 0, 0, 0.4) !important;
            border-right: 5px solid rgba(0, 0, 0, 0.4) !important;
          "
        >
          <i class="fad fa-lightbulb-on fs-20px mr-2" style="line-height: 0"></i>
          Converted to Opportunity by
          <user-avatar :user="selected.convertedBy"></user-avatar> on
          <dater :date="selected.convertedDate" date-only></dater>
        </v-sheet>
        <v-sheet
          v-if="selected.isArchived"
          rounded="lg"
          color="brown"
          dark
          class="d-inline-flex align-center font-weight-medium px-3 mr-4"
          style="
            gap: 0.5rem;
            border: 1px dashed rgba(0, 0, 0, 0.4) !important;
            border-left: 5px solid rgba(0, 0, 0, 0.4) !important;
            border-right: 5px solid rgba(0, 0, 0, 0.4) !important;
          "
        >
          <i class="fad fa-archive fs-20px mr-2" style="line-height: 0"></i>
          Lead is Archived
        </v-sheet>
        <div
          class="d-flex ml-2"
          v-if="
            !isFetching &&
            readonly &&
            isMainRoute &&
            $has(perms.Leads.Update) &&
            !selected.isArchived &&
            !selected.isConvertedToProject
          "
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save" : "Create" }}
              <kbd class="light ml-1 fs-12px"> CTRL<span class="opacity-54">+</span>S </kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save & Close" : "Create & Close" }} Panel
              <kbd class="light ml-2 fs-12px">
                CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S
              </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchLead()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Lead</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="clone" v-if="$has(perms.Leads.Create)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-clone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Clone</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item @click="del" v-if="$has(perms.Leads.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.Leads.Update) && !$has(perms.Leads.Delete)"
              >No Actions Available!</v-subheader
            >
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import leadService from "../../services/leads-service";
import enums from "../../../../plugins/enums";
import perms from "../../../../plugins/permissions";
import LeadStatus from "../LeadStatus.vue";
import ClientSelector from "../../../Shared/components/ClientSelector.vue";
// import JiraProjectSelector from "../../../Shared/components/JiraProjectSelector.vue";
import ProjectTimeline from "./ProjectTimeline.vue";
import ProjectBudget from "./ProjectBudget.vue";
// import Assignees from "./Assignees.vue";
import Contacts from "./Contacts.vue";
import RichTextEditor from "../../../Shared/components/RichTextEditor.vue";
import AddressDetail from "../../../Shared/components/AddressDetail.vue";
import MicrosoftTeamsSelector from "../../../Projects/components/MicrosoftTeamsSelector.vue";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";
import UserAllSelector from "../../../Shared/components/UserAllSelector.vue";
import usersAPI from "../../../Admin/services/StaffService";
import LeadAccessList from "./LeadAccessList.vue";

export default {
  name: "edit-lead",
  data() {
    return {
      perms,
      enums,
      leadId: null,
      isFetching: false,
      readonly: false,
      users: [],
      isUsersLoading: false,
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          valid: false,
          tab: null,
          hasChanges: null,
          fullWidth: false,
          isLoading: false,
          categoryLoading: false,
        },
      },
      leadStatusList: this.$options.filters.EnumToList(enums.LEAD_STATUS, true),
      leadMainStatus: 1,
      imageApiUrl: `Leads/Image`,
      DNA_COUNTRIES: this.$options.filters.EnumToList(enums.DNA_COUNTRIES, true),
      isPostalCodeLoading: false,
      toBeAutoGenerated: ` <span class='orange--text fs-12px ml-2'><i class='fas fa-lock-a mr-1'></i>To Be Auto Generated</span>`,
      defaultTeamsLogo: `https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg`,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
    this.getAllUsersData();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.$has(this.perms.LayoutViews.Update) &&
        !this.selected.isArchived &&
        !this.selected.isConvertedToProject
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.updateForm, () => {})) return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    onCloneLeadSuccess(lead) {
      this.$emit("clone", lead);
    },
    onDeleteLeadSuccess(id) {
      this.$emit("delete", id);
    },
    clone() {
      this.$dialog
        .info({
          text: `Are you sure you want to clone this lead?<br/><h4>${this.selected.name}</h4>`,
          title: `Clone Lead?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return leadService
                  .clone(this.selected.id)
                  .then((resp) => {
                    this.onCloneLeadSuccess(resp.data);
                    this.$dialog.notify.success("Lead cloned successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error cloning lead!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this lead?<br/><h4>${this.selected.name}</h4>`,
          title: `Delete Lead?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return leadService
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.onDeleteLeadSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Item deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the item", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    convertToOpportunity() {
      this.$dialog.warning({
        text: `Are you sure you want to convert this lead into an opportunity?`,
        title: `Convert Lead to opportunity?`,
        color: "warning",
        icon: "fal fa-lightbulb-on mr-3",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "warning",
            handle: () => {
              return leadService
                .convertToProject(this.leadId)
                .then((resp) => {
                  this.$router.push({
                    name: "view-project",
                    params: {
                      id: resp.data.id,
                    },
                  });
                  this.$dialog.notify.success("Lead convert to project successfully", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  this.$handleError(err);
                });
            },
          },
        },
      });
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = { leadContacts: [] };
      this.cementLead();
      this.$releaseChanges();
      this.isFetching = false;

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewLead) {
      this.$emit("save", this.selected, isNewLead);
    },
    fetchLead() {
      this.isFetching = true;
      leadService
        .get(this.leadId)
        .then((resp) => {
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.setSelected(this.cloneDeep(resp.data));
          this.cementLead();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getLead >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    setSelected(data) {
      if (data.potentialStartDate) {
        data.potentialStartDate = this.formatDate(new Date(data.potentialStartDate));
      }
      if (data.potentialConstructionCompleteDate) {
        data.potentialConstructionCompleteDate = this.formatDate(
          new Date(data.potentialConstructionCompleteDate)
        );
      }
      if (data.potentialConstructionStartDate) {
        data.potentialConstructionStartDate = this.formatDate(
          new Date(data.potentialConstructionStartDate)
        );
      }
      data.leadContacts = data.leadContacts.map((elm) => elm.companyContact);
      this.selected = data;
    },
    prepare() {
      const dataToSend = this.cloneDeep(this.selected);
      delete dataToSend.client;
      delete dataToSend.createdBy;
      delete dataToSend.updatedBy;
      // dataToSend.leadAssignees = dataToSend.leadAssignees.map(elm => {
      //   if (elm.user) elm.userId = elm.user.id;
      //   delete elm.user;
      //   delete elm.leadId;
      //   return elm;
      // });
      if (dataToSend.leadContacts) {
        dataToSend.leadContacts = dataToSend.leadContacts.map((elm) => {
          let newObj = {};
          newObj.companyContactId = elm.id;
          return newObj;
        });
      }
      return dataToSend;
    },
    open(id, editMode = false) {
      if (id == null) {
        this.selected = {
          leadContacts: [],
        };
        this.leadId = null;
        this.readonly = false;
        this.isFetching = false;
        this.slideouts.update.tab = 0;
        this.cementLead();
        this.$refs.updateForm.resetValidation();
      } else {
        this.leadId = id;
        this.readonly = !editMode;
        this.slideouts.update.tab = 0;
        this.fetchLead();
      }
      this.slideouts.update.active = true;
    },
    cementLead() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      const dataToSend = this.prepare();
      leadService
        .update(dataToSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          let message = "Lead updated!";
          this.setSelected(this.cloneDeep(resp.data));
          this.cementLead();
          var isNewLead = false;
          if (!dataToSend.id) {
            isNewLead = true;
            message = "Lead created successfully!";
          }
          this.announceChange(isNewLead);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.$log("err", err, err.request);
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      // this.$log("####> checkForChanges:", this.slideouts.update.hasChanges);
      // if (this.slideouts.update.hasChanges) {
      //   this.$log(
      //     "%c=>> deepDiff",
      //     "color: red",
      //     this.deepDiff(this.selectedCemented, this.selected)
      //   );
      // }

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "1200px";
    },
    leadsUrl() {
      if (this.selected == null) return "";
      return `leads/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "lead") return true;
      else return false;
    },
    projectInfoTabIsValid() {
      return this.slideouts.update.valid;
    },
    msTeamStatus() {
      if (this.selected == null || this.selected.id == null || this.isFetching) return "";
      return this.getEnumMember(enums.LinkedProjectStatus, this.selected.msTeamCreationStatus || 0)
        .text;
    },
  },
  watch: {
    selected: {
      handler() {
        this.$log(">>>>>> watch: selected");
        this.checkForChanges();
      },
      deep: true,
    },
  },
  components: {
    LeadStatus,
    ClientSelector,
    ProjectTimeline,
    ProjectBudget,
    // Assignees,
    Contacts,
    // JiraProjectSelector,
    RichTextEditor,
    AddressDetail,
    MicrosoftTeamsSelector,
    PanelSizeControl,
    UserAllSelector,
    LeadAccessList,
  },
};
</script>
