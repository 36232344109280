<template>
  <div class="file-manager-container" :class="fullHeight ? 'full-height-uploader' : ''">
    <div
      class="mb-2"
      style="display: flex; align-items: center; justify-content: space-between; height: 28px"
    >
      <label
        :id="labelId"
        :for="inputId"
        class="fm-label"
        v-html="label || 'File Manager'"
        tabindex="1"
      ></label>
      <div class="d-flex align-center" style="gap: 0.5rem">
        <v-scroll-y-transition mode="out-in">
          <v-btn small v-if="allowRetry" key="retry" dark color="orange" @click="retry">
            <span class="fs-12px"><i class="fad fa-redo mr-2"></i>Retry All</span>
          </v-btn>
        </v-scroll-y-transition>
        <v-scroll-y-transition mode="out-in">
          <v-btn
            small
            v-if="!emptyMode && canUpload && !disabled"
            key="browse"
            color="info"
            @click="clickOnLabel"
          >
            <span class="fs-12px"><i class="fad fa-arrow-to-top mr-2"></i>Upload Files</span>
          </v-btn>
        </v-scroll-y-transition>
      </div>
    </div>
    <v-scroll-y-transition mode="out-in">
      <div
        key="loading"
        class="d-flex flex-column align-center justify-center py-4"
        v-if="loadingFiles"
      >
        <v-progress-circular indeterminate color="info" :width="2" :size="28"></v-progress-circular>
        <h4 class="mt-3 text--disabled">Loading Files ...</h4>
      </div>
      <div key="list" class="file-manager" v-else-if="id != null && baseUrl != null">
        <!-- accept=".png, .jpg, .jpeg, .svg" -->
        <input
          type="file"
          :id="inputId"
          hidden
          :multiple="multiple"
          :ref="inputId"
          @change="onFileInputChange"
          :disabled="disabled"
          v-if="canUpload"
        />
        <div class="fm-dropzone" @dragenter="onDragEnter">
          <div
            class="fm-drop-catcher"
            @dragleave="onDragLeave"
            @dragover.prevent
            @drop.stop.prevent="onDrop"
            v-if="canUpload && !disabled && dropZoneIsOnDrag"
          >
            <i class="fad fa-inbox-in fa-3x mb-3"></i>
            <span>Drop Image Here</span>
          </div>
          <div class="fm-files" v-if="files.length > 0">
            <v-scroll-y-transition mode="out-in" group>
              <file
                v-for="file in files"
                :key="file.id"
                :value="file"
                :can-update="canUpdate"
                :can-download="canDownload"
                :can-delete="canDelete"
                @delete="deleteUploadedFile(file)"
                @update="updateUploadedFile(file)"
              ></file>
            </v-scroll-y-transition>
          </div>
          <div class="fm-upload-wrapper">
            <v-scroll-y-transition mode="out-in">
              <div
                class="fm-queue mt-2"
                key="fm-queue"
                v-if="canUpload && !disabled && uploader.queue.length > 0"
              >
                <v-scroll-y-transition mode="out-in" group>
                  <file
                    v-for="(queueItem, i) in uploader.queue"
                    :key="queueItem.queueId"
                    :value="queueItem.file"
                    uploadQueue
                    :baseUrl="baseUrl"
                    :uploadActive="queueItem.uploading"
                    :uploadProgress="queueItem.uploadProgress"
                    :uploadError="queueItem.failed"
                    @cancel="cancelUploadingFile(queueItem, i)"
                    @retry="retryFailedFile(queueItem, i)"
                  ></file>
                </v-scroll-y-transition>
              </div>
              <div class="fm-empty-placeholder py-4" key="fm-empty" v-else-if="emptyMode">
                <div
                  class="fm-main-info ma-0 fs-18px font-weight-bold mt-2"
                  v-if="canUpload && !disabled"
                >
                  <p class="fm-icon opacity-72"><i class="fad fa-file-arrow-up fa-4x"></i></p>
                  Drag & drop {{ multiple ? "files" : "a file" }} here!
                  <p class="text--disabled mb-1">or</p>
                  <label :for="inputId" v-show="false" :id="'BrowseLabel_' + inputId"
                    >Upload Files</label
                  >
                  <v-btn small color="info" @click="clickOnLabel('BrowseLabel_' + inputId)">
                    <span class="fs-12px"><i class="fas fa-arrow-to-top mr-2"></i>Browse</span>
                  </v-btn>
                  <p class="text--disabled fs-14px mb-0 mt-2">
                    Max file size:
                    <span style="font-weight: 800" class="text--secondary">100MB</span>
                  </p>
                  <p class="text--disabled fs-14px mb-0">
                    Supported file types:
                    <span style="font-weight: 800" class="text--secondary">
                      .pdf, .doc, .docx, .ppt, .pptx, .txt, .jpg, .png, .svg
                    </span>
                  </p>
                </div>
                <div class="fm-main-info ma-0 fs-18px font-weight-bold mt-2" v-else>
                  <p class="fm-icon opacity-72"><i class="fad fa-face-sleeping fa-4x"></i></p>
                  <p class="text--disabled fs-14px mt-3 mb-0">No Files Uploaded Here!</p>
                </div>
              </div>
            </v-scroll-y-transition>
          </div>
        </div>
      </div>
    </v-scroll-y-transition>
    <!-- Rename Folder Modal -->
    <v-dialog
      v-model="modals.updateDescription.active"
      max-width="500px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-pen-square mr-2"></i>Update Description of File:
          <b>{{ modals.updateDescription.selected.fileName }}</b>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.updateDescription.valid" ref="updateDescriptionForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <v-textarea-alt
                    :rules="[allRules.noWhiteSpaces]"
                    label="File Description"
                    id="fileDescription"
                    ref="fileDescription"
                    placeholder="File Description"
                    v-model="modals.updateDescription.selected.description"
                  >
                  </v-textarea-alt>
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateDescriptionDiscard()">
              <i class="fal fa-chevron-left mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="info"
              :disabled="!modals.updateDescription.valid || modals.updateDescription.loading"
              :loading="modals.updateDescription.loading"
              @click="updateDescriptionConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "@/plugins/axios.js";
import Api from "../../services/api";
import File from "./File.vue";

export default {
  components: { File },
  name: "file-manager",
  data() {
    return {
      dropZoneIsOnDrag: false,
      loadingFiles: false,
      acceptedTypes: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "text/plain",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
      ],
      files: [],
      uploader: {
        running: false,
        queue: [],
        queueCount: 0,
      },
      modals: {
        updateDescription: {
          active: false,
          valid: false,
          loading: false,
          selected: {},
          data: {
            description: null,
          },
        },
      },
    };
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    baseUrl: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    canUpload: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canDownload: {
      type: Boolean,
      default: true,
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.resetFileManager();
  },
  computed: {
    inputId() {
      return "fm-input-" + this.id;
    },
    labelId() {
      return "BrowseLabel_" + this.inputId;
    },
    emptyMode() {
      if (this.files.length > 0 || this.uploader.queue.length > 0) return false;
      return true;
    },
    allowRetry() {
      if (
        !this.uploader.running &&
        this.uploader.queue.length > 0 &&
        this.uploader.queue.some((qi) => qi.failed && !qi.uploading)
      )
        return true;
      return false;
    },
  },
  methods: {
    openUpdateDescriptionDialog(file) {
      this.modals.updateDescription.selected = this.cloneDeep(file);
      this.modals.updateDescription.active = true;
      setTimeout(() => {
        this.$refs.updateDescriptionForm.resetValidation();
      });
      setTimeout(() => {
        this.$refs.fileDescription.select();
      }, 100);
    },
    updateDescriptionDiscard() {
      this.modals.updateDescription.active = false;
      this.modals.updateDescription.loading = false;
      this.modals.updateDescription.selected = {};
    },
    updateDescriptionConfirmed() {
      this.modals.updateDescription.loading = true;
      let toSend = {
        id: this.modals.updateDescription.selected.id,
        description: this.modals.updateDescription.selected.description,
      };
      this.updateFile(toSend.id, toSend.description)
        .then((resp) => {
          this.modals.updateDescription.loading = false;
          this.updateArr(this.files, resp.data);
          this.updateDescriptionDiscard();
        })
        .catch((err) => {
          this.modals.updateDescription.loading = false;
          this.$handleError(err);
        });
    },
    resetFileManager() {
      this.files = [];
      this.getFiles();
    },
    getFiles() {
      if (this.baseUrl == null) return;
      this.loadingFiles = true;
      this.getFilesList()
        .then((response) => {
          this.files = response.data;
          this.loadingFiles = false;
        })
        .catch((error) => {
          this.$handleError(error);
          this.loadingFiles = false;
        });
    },
    clickOnLabel() {
      document.getElementById(this.labelId).click();
    },
    onDragEnter(event) {
      this.dropZoneIsOnDrag = true;
    },
    onDragLeave(event) {
      this.dropZoneIsOnDrag = false;
    },
    onDrop(event) {
      this.dropZoneIsOnDrag = false;
      event.preventDefault();
      event.stopPropagation();
      var files = event.target.files || event.dataTransfer.files;
      if (files.length) {
        this.processDroppedFiles(files);
      }
      this.$refs[this.inputId].value = null;
    },
    onFileInputChange(event) {
      var files = Array.from(event.target.files);
      this.$refs[this.inputId].value = null;
      this.processDroppedFiles(files);
    },
    processDroppedFiles(files) {
      if (!this.multiple) {
        files = [files[0]];
      }
      //1. Add New Files to Queue.
      for (var i = 0; i < files.length; i++) {
        //check if file is acceptable
        if (this.acceptableFile(files[i])) {
          this.appendFileToUploadQueue(files[i]);
        }
      }
      //2. If queue is running => just add the file
      //3. If queue is NOT running => run it
      if (!this.uploader.running) {
        this.uploader.running = true;
        this.uploadNextFile();
      }
    },
    appendFileToUploadQueue(file) {
      const _cancelToken = axios.CancelToken;
      var queueItem = {
        queueId: ++this.uploader.queueCount,
        file: file,
        uploading: false,
        uploadProgress: 0,
        finished: false,
        failed: false,
        cancellation: {
          cancelToken: _cancelToken,
          source: _cancelToken.source(),
        },
      };
      this.uploader.queue.push(queueItem);
    },
    updateUploadedFile(file) {
      this.openUpdateDescriptionDialog(file);
    },
    deleteUploadedFile(file) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this file?<br /><b>${file.fileName}</b>`,
          title: `Delete File?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return this.deleteFile(file.id)
                  .then((response) => {
                    var idx = this.files.findIndex((f) => f.id == file.id);
                    this.files.splice(idx, 1);
                    this.$dialog.notify.success("File deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((error) => {
                    this.$dialog.notify.error("Error deleting file!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    cancelUploadingFile(queueItem, i) {
      //5. If file is canceled,
      var queueItemIdx = this.uploader.queue.findIndex((qi) => qi.queueId == queueItem.queueId);
      //5. A. file is uploading => cancel it and UploadNextFile();
      if (queueItem.uploading) {
        queueItem.cancellation.source.cancel(`Uploading ${queueItem.file.name} was cancelled!`);
      }
      //5. B. file is not uploading => just remove it from Queue;
      else {
        this.uploader.queue.splice(queueItemIdx, 1);
      }
    },
    retryFailedFile(queueItem, i) {
      //7. Retry
      var queueItemIdx = this.uploader.queue.findIndex((qi) => qi.queueId == queueItem.queueId);
      //7. A. make sure file was failed before => remove the failed flag, mark it as not started,
      //      and start the queue if it was not running
      if (queueItem.failed) {
        queueItem.failed = false;
        queueItem.uploading = false;
        queueItem.uploadProgress = 0;

        //move it to the bottom (UI & Queue Array)
        this.uploader.queue.push(this.uploader.queue.splice(queueItemIdx, 1)[0]);

        // if queue was not running start it
        if (!this.uploader.running) {
          this.uploader.running = true;
          this.uploadNextFile();
        }
      }
    },
    getNextFile() {
      for (var i = 0; i < this.uploader.queue.length; i++) {
        if (!this.uploader.queue[i].uploading && !this.uploader.queue[i].failed) {
          this.uploader.queue[i].uploading = true;
          return this.uploader.queue[i];
        }
      }
      return null;
    },
    uploadNextFile() {
      var queueItem = this.getNextFile();
      if (queueItem == null) {
        //no more files to process, stop the queue and exit
        console.info("no more files to process, stop the queue and exit");
        this.uploader.running = false;
        return;
      }
      //update queue file
      queueItem.failed = false;
      queueItem.uploading = true;
      queueItem.uploadProgress = 0;

      var formData = new FormData();
      formData.append("file", queueItem.file, queueItem.file.name);
      formData.append("cancellation", queueItem.cancellation.source.token);
      this.uploadFile(formData, {
        cancelToken: queueItem.cancellation.source.token,
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          queueItem.uploadProgress = percentCompleted;
        },
      })
        .then((response) => {
          var message = `<u>Uploaded!</u>: <b>${queueItem.file.name}</b> has been uploaded successfully!`;
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 5000,
          });
          //4. If file is uploaded replace/fill its UI with the new result
          //   (and check if current files already has the file to replace it too).
          var newFile = response.data;
          //update file UI
          var oldFileIdx = this.files.findIndex((file) => file.id == newFile.id);
          if (oldFileIdx == -1) {
            //This is a new file not a new version of an existing file
            this.files.push(newFile);
          }
          //Remove the current queue file
          var queueItemIdx = this.uploader.queue.findIndex(
            (file) => file.queueId == queueItem.queueId
          );
          this.uploader.queue.splice(queueItemIdx, 1);

          //upload next file in the queue
          this.uploadNextFile();
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            var queueItemIdx = this.uploader.queue.findIndex(
              (file) => file.queueId == queueItem.queueId
            );
            var message = `<u>Cancelled!</u>: Uploading <b>${queueItem.file.name}</b> was cancelled!`;
            this.$dialog.notify.error(message, {
              position: "top-right",
              timeout: 5000,
            });
            this.uploader.queue.splice(queueItemIdx, 1);
          } else {
            //handle error
            var message = `<u>Upload Error!</u>: <b>${queueItem.file.name}</b> failed uploading!`;
            this.$dialog.notify.error(message, {
              position: "top-right",
              timeout: 5000,
            });
            //6. If file failed => mark it as failed and add retry btn and skip it in queue
            var queueItemIdx = this.uploader.queue.findIndex(
              (file) => file.queueId == queueItem.queueId
            );
            this.uploader.queue[queueItemIdx].failed = true;
            this.uploader.queue[queueItemIdx].uploading = false;
          }

          //upload next file in the queue
          this.uploadNextFile();
        });
    },
    retry() {
      this.uploader.queue.forEach((qi) => {
        if (qi.failed && !qi.uploading) {
          qi.failed = false;
          qi.uploading = false;
          qi.uploadProgress = 0;
        }
      });
      this.uploader.running = true;
      this.uploadNextFile();
    },
    acceptableFile(file) {
      var exceedsMaxFileSize = file.size > 100 * 1024 * 1024;
      var existsInFiles = this.files.some((f) => f.fileName == file.name);
      var existsInQueue = this.uploader.queue.some((qi) => qi.file.name == file.name);
      var validType = this.acceptedTypes.includes(file.type);

      var errors = [];
      if (exceedsMaxFileSize) errors.push("the file size exceeds 100MB limit");
      if (existsInFiles || existsInQueue) errors.push("the file is already added!");
      if (!validType) errors.push("the file type is not valid!");

      if (errors.length > 0) {
        var message = "<b>" + file.name + "</b> is not added because " + errors.join(" & ");

        this.$dialog.notify.error(message, {
          position: "top-right",
          timeout: 5000,
        });
      }
      return !existsInFiles && !existsInQueue && !exceedsMaxFileSize && validType;
    },
    uploadFile(fileData, config) {
      return Api()["post"](this.baseUrl, fileData, config);
    },
    updateFile(fileId, description) {
      return Api()["put"](`${this.baseUrl}/${fileId}`, { description: description });
    },
    deleteFile(fileId) {
      return Api()["delete"](`${this.baseUrl}/${fileId}`);
    },
    getFilesList() {
      return Api()["get"](this.baseUrl);
    },
  },
  watch: {
    baseUrl: {
      handler(val) {
        this.resetFileManager();
      },
    },
  },
};
</script>

<style lang="scss">
.v-badge__badge .v-icon {
  font-size: 14px !important;
}
.fm-label {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.file-manager-container {
  .file-manager {
    margin-top: 4px !important;
    display: flex;
    width: 100%;
    flex-direction: column;

    .fm-dropzone {
      position: relative;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(42, 54, 59, 0.4)' stroke-width='4' stroke-dasharray='6%2c 6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
      border-radius: 8px;
      padding: 1rem 1rem;
      padding-bottom: calc(1rem + 2px);
      text-align: center;
      // background-color: rgba($shades-black, 0.04);

      &.uploading-error {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgb(229, 76, 60, 1)' stroke-width='4' stroke-dasharray='6%2c 6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
        background-color: var(--v-error-lighten5);
      }

      .fm-empty-placeholder {
        .fm-icon {
          color: rgba($shades-black, 0.87);
          margin-bottom: 0.25rem;
        }

        .fm-main-info {
          color: rgba($shades-black, 0.87);
          font-weight: 600;
          font-size: 14px;
          margin: 0;

          label {
            color: var(--v-info-base);
            font-weight: 700;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

            &[disabled="disabled"] {
              color: var(--v-secondary-base);
              cursor: default;
              text-decoration: none !important;
            }
          }
        }
        .fm-support {
          color: rgba($shades-black, 0.4);
          font-weight: 700;
          font-size: 12px;
          margin: 0;
          margin-top: 0.25rem;
        }
      }

      .fm-drop-catcher {
        content: "Drop here";
        display: flex;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        color: #2196f3;
        font-size: 14px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 4;
        background-color: rgba($shades-black, 0.04);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        overflow: hidden;
        opacity: 1;
        transition: all 0.3s ease-out;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(33, 150, 243, 1)' stroke-width='4' stroke-dasharray='6%2c 6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
        border-radius: 8px;

        span {
          font-weight: 500;
        }

        * {
          user-select: none !important;
          pointer-events: none !important;
        }
      }
    }

    .fm-queue {
      position: relative;
      border-radius: 0.5rem;
    }

    .fm-result {
      position: relative;
    }
  }

  &.full-height-uploader {
    height: 100%;
    display: flex;
    flex-direction: column;

    .file-manager {
      height: 100%;
      flex: 1 1 auto;
      .fm-dropzone {
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;

        .fm-upload-wrapper {
          width: 100%;
        }
      }
    }
  }
}
</style>
