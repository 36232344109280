<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template #header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Invitation ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> Invitations: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <entity-id class="ml-2" :label="selected.id" :path="roleUrl" title="Role"></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a New Invitation
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-form v-model="slideouts.update.valid" ref="updateForm">
      <v-container class="pa-6">
        <v-row class="my-0" dense>
          <v-col cols="6" sm="12" md="6">
            <v-text-field-alt
              :rules="[
                allRules.required,
                allRules.length(2),
                allRules.noWhiteSpaces,
                allRules.validEmail,
              ]"
              label="Email"
              id="email"
              ref="email"
              placeholder="Email"
              v-model="selected.email"
              :readonly="readonly"
              hide-details
              class="mb-0"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="6" md="6">
            <external-roles-selector
              v-model="selected.applicationRoleId"
              :readonly="readonly"
            ></external-roles-selector>
          </v-col>
        </v-row>

        <v-row class="my-0" dense>
          <v-col cols="6" sm="6">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="First Name"
              id="firstName"
              ref="firstName"
              placeholder="First Name"
              v-model="selected.firstName"
              :readonly="readonly"
              hide-details
              class="mb-0"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="6" sm="6">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="Last Name"
              id="lastName"
              ref="lastName"
              placeholder="Last Name"
              v-model="selected.lastName"
              :readonly="readonly"
              hide-details
              class="mb-0"
            >
            </v-text-field-alt>
          </v-col>
        </v-row>
        <v-row class="my-0" dense>
          <v-col cols="12" sm="12">
            <v-textarea-alt
              label="Invitation Message"
              id="invitationMessage"
              rows="4"
              placeholder="Invitation Message"
              v-model="selected.invitationMessage"
              :readonly="readonly"
            >
            </v-textarea-alt>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && readonly && isMainRoute && $has(perms.Invitations.Update)"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchInvitation()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Invitations</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="del" v-if="$has(perms.Invitations.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.Invitations.Update) && !$has(perms.Invitations.Delete)"
            >
              No Actions Available!
            </v-subheader>
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import invitationService from "../services/invitation-service";
import perms from "../../../../plugins/permissions";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";
import ExternalRolesSelector from "../../Roles/components/ExternalRolesSelector.vue";

export default {
  name: "edit-Invitations",
  data() {
    return {
      perms,
      roleId: null,
      isFetching: false,
      selected: {
        email: "",
        firstName: "",
        lastName: "",
        invitationMessage: "",
        isDefault: false,
        permissionIds: [],
        applicationRoleId: null,
      },
      readonly: false,
      selectedCemented: {},
      slideouts: {
        update: {
          valid: false,
          active: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
          fullWidth: false,
        },
      },
      search: null,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    roleUrl() {
      if (this.selected == null) return "";
      return `invitations/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-invitations") return true;
      else return false;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "800px";
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        this.readonly &&
        !this.isFetching &&
        this.isMainRoute &&
        (!this.selected.isSystem || this.currentUser.isSuperUser) &&
        this.$has(this.perms.Invitations.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementRole();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewRole) {
      this.$emit("save", this.selected, isNewRole);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      invitationService
        .update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Invitation updated successfully!";

          this.selected = this.cloneDeep(resp.data);
          this.cementRole();
          if (this.isMainRoute) document.title = this.selected.name + " Role | Concordia";

          var isNewRole = false;
          if (!toSend.id) {
            isNewRole = true;
            message = "Invitation added successfully!";
          }
          this.announceChange(isNewRole);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
      this.slideouts.update.isLoading = false;
    },
    open(id, editMode = false) {
      if (id == null) {
        this.selected = {
          name: "",
          isDefault: false,
          permissionIds: [],
        };
        this.readonly = false;
        this.isFetching = false;
        this.roleId = null;
        this.cementRole();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
        setTimeout(() => {
          this.$refs.email.focus();
        }, 250);
      } else {
        this.readonly = !editMode;
        this.roleId = id;
        this.fetchInvitation();
      }
      this.slideouts.update.active = true;
    },
    fetchInvitation() {
      this.isFetching = true;
      invitationService
        .getById(this.roleId)
        .then((resp) => {
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementRole();
          if (this.isMainRoute) document.title = this.selected.name + " Role | Concordia";
        })
        .catch((err) => {
          this.closeUpdateSlideout();
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    cementRole() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;
      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.updateForm, () => {})) return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog.warning({
        text: `Are you sure you want to delete this invitations: <b>${selected.name}</b>?`,
        title: `Delete Invitations?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              return invitationService
                .delete(selected.id)
                .then((resp) => {
                  this.$emit("delete", selected.id);
                  this.closeUpdateSlideout();
                  this.$dialog.notify.success("Invitations deleted successfully", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  var message = "an error occurred during deleting the item";
                  this.$handleError(err, message);
                });
            },
          },
        },
      });
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
  components: { PanelSizeControl, ExternalRolesSelector },
};
</script>
<style scoped lang="scss">
.tree-wrapper {
  border: 1px dashed rgba($shades-black, 0.4);
}
</style>
