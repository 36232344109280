<template>
  <div class="alt-comp-wrapper">
    <v-autocomplete-alt
      auto-select-first
      style="width: 100%"
      :rules="[allRules.required]"
      label="Manufacturer"
      id="equipmentManufacturer"
      placeholder="Choose a Manufacturer"
      v-model="selected.manufacturerId"
      @input="manufacturerChanged"
      :items="manufacturers"
      :filter="manufacturersFilter"
      dense
      filled
      clearable
      item-text="name"
      item-value="id"
      :loading="isManufacturersLoading"
      :disabled="isManufacturersLoading"
      :readonly="readonly"
    >
      <template v-slot:selection="{ item }">
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo-mini">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.logoUrl != null && item.logoUrl != ''"
                  >
                    <img
                      :key="item.id + '_img'"
                      :src="item.logoUrl"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="item.logoUrl != null && item.logoUrl != ''"
                :src="item.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h5 class="ma-0 ml-2">{{ item.name }}</h5>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo-mini">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.logoUrl != null && item.logoUrl != ''"
                  >
                    <img
                      :key="item.id + '_img'"
                      :src="item.logoUrl"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="item.logoUrl != null && item.logoUrl != ''"
                :src="item.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h5 class="ma-0 ml-2">{{ item.name }}</h5>
        </div>
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      :loading="isManufacturersLoading"
      :disabled="isManufacturersLoading || readonly"
      @click="getManufacturers"
    >
      <i class="fas fa-sync"></i>
    </v-btn>
  </div>
</template>

<script>
import manufacturersAPI from "../../Companies/Manufacturers/services/manufacturers-service.js";

export default {
  components: {},
  data() {
    return {
      isManufacturersLoading: true,
      manufacturers: [],
      selected: {
        manufacturer: null,
        manufacturerId: null,
      },
    };
  },
  props: {
    manufacturerId: {
      type: Number,
      default: null,
    },
    manufacturer: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    getManufacturers() {
      this.isManufacturersLoading = true;
      manufacturersAPI
        .queryLite({ itemsPerPage: -1 })
        .then((resp) => {
          this.manufacturers = resp.data.items;
          this.updateManufacturerObj();
          this.isManufacturersLoading = false;
        })
        .catch((err) => {
          this.isManufacturersLoading = false;
          this.$handleError(err);
        });
    },
    manufacturersFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const text2 = item.abbreviatedName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text1.indexOf(searchText) > -1 || text2.indexOf(searchText) > -1;
    },
    updateManufacturerObj() {
      if (this.selected.manufacturerId != null && this.manufacturers.length > 0) {
        this.selected.manufacturer = this.manufacturers.find(
          (b) => b.id == this.selected.manufacturerId
        );
      } else {
        this.selected.manufacturer = null;
      }
      this.$emit("update:manufacturer", this.selected.manufacturer);
    },
    manufacturerChanged(val) {
      this.updateManufacturerObj();
    },
  },
  mounted() {
    this.selected.manufacturerId = this.manufacturerId;
    this.getManufacturers();
  },
  watch: {
    isManufacturersLoading: {
      handler() {
        this.$emit("update:loading", this.isManufacturersLoading);
      },
    },
    manufacturerId: {
      handler() {
        if (this.selected.manufacturerId != this.manufacturerId) {
          this.selected.manufacturerId = this.manufacturerId;
          this.updateManufacturerObj();
        }
      },
    },
    "selected.manufacturerId": {
      handler() {
        if (this.selected.manufacturerId != this.manufacturerId) {
          this.$emit("update:manufacturerId", this.selected.manufacturerId);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.alt-comp-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
