import Api from "../../Shared/services/api";

const baseUrl = "ResourceShiftRequests";
export default {
  getById(id, includeContracts = false) {
    return Api().get(`${baseUrl}/${id}/?includeContracts=${includeContracts}`);
  },
  getByIdForReview(id) {
    return Api().get(`${baseUrl}/${id}/Review`);
  },
  delete(id) {
    return Api().delete(`${baseUrl}/${id}`);
  },
  update(entity) {
    if (!entity.id) return Api().post(`${baseUrl}`, entity);
    else return Api().put(`${baseUrl}/${entity.id}`, entity);
  },
  updateByManagement(entity) {
    if (!entity.id) return Api().post(`${baseUrl}/Management`, entity);
    else return Api().put(`${baseUrl}/Management/${entity.id}`, entity);
  },
  getMyShiftRequests(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}?` + qParams.toString());
  },
  getShiftRequestsForManagement(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/Management?` + qParams.toString());
  },
  sendToManagement(id) {
    return Api().patch(`${baseUrl}/${id}/SendToManagement`);
  },
  approveShiftRequest(data) {
    return Api().patch(`${baseUrl}/Management/${data.id}/Approve`, data);
  },
  rejectShiftRequest(data) {
    return Api().patch(`${baseUrl}/Management/${data.id}/Reject`, data);
  },
  getActivityLog(id, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + `/${id}/ActivityLogs?` + qParams.toString());
  },
  addActivityLog(id, note) {
    return Api().post(baseUrl + `/${id}/ActivityLogs`, {
      notes: note,
      type: 2,
    });
  },
};
