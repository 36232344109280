import Api from "../../../../Shared/services/api";
import ApiUploader from "../../../../Shared/services/apiUploader";
import { buildParams } from "../../../../../plugins/helpers";

const baseUrl = "WireCadEquipmentIntegrationRequests";
export default {
  import(fileData, config) {
    return Api().post(`${baseUrl}/Import`, fileData, config);
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  update(entity, config) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity, config);
  },
  add(formData, config) {
    return Api().post(baseUrl, formData, config);
  },
  getById(id) {
    return Api().get(`${baseUrl}/${id}`);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  sendToManagement(id) {
    return Api().patch(`${baseUrl}/${id}/SendToManagement`);
  },
  approve(data) {
    return Api().patch(`${baseUrl}/${data.id}/Approve`, data);
  },
  reject(data) {
    return Api().patch(`${baseUrl}/${data.id}/Reject`, data);
  },
};
