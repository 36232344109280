<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 1400"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideout.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1">
        <i class="fad fa-edit mr-2"></i> Update WireCAD Manufacturer Integration Request
      </h3>
      <div>
        <panel-size-control v-model="slideout.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <div color="white" style="position: sticky; top: 0; z-index: 2" ref="tabsContainer">
      <v-tabs v-model="slideout.tab" color="error" v-if="slideout.active && !isFetching">
        <v-tab :key="0"> <i class="fad fa-memo-circle-info mr-2"></i> Basic Info </v-tab>
        <v-tab :key="1"> <i class="fad fa-list mr-2"></i>Request Items</v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Request ...</p>
    </v-container>

    <v-tabs-items v-else v-model="slideout.tab" style="height: 100%">
      <v-tab-item :key="0">
        <v-form v-model="slideout.valid" ref="updateForm0" style="height: 100%; overflow: hidden">
          <v-container
            fluid
            style="height: 100%; overflow: hidden; flex-direction: column; display: flex"
          >
            <v-row align="center">
              <v-col cols="12" md="6" class="d-flex align-center">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
                  id="Manufacturer_Request_Name"
                  ref="Name"
                  label="Request Name"
                  placeholder="Request Name"
                  v-model="selected.name"
                  style="flex: 1 1 auto"
                  hide-details
                  :readonly="readonly"
                >
                </v-text-field-alt>
              </v-col>
              <v-col cols="12" md="12" class="mb-4">
                <rich-text-editor
                  ref="richTextEditor"
                  v-model="selected.justification"
                  title="Request Justification<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                  showLabel
                  allowExpand
                  :readonly="readonly"
                >
                </rich-text-editor>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <v-tab-item :key="1" style="height: 100%; overflow: hidden">
        <v-container
          fluid
          class="inset-shadow py-0 px-0"
          style="
            background: #eceff1;
            height: 100%;
            overflow: hidden;
            flex-direction: column;
            display: flex;
          "
        >
          <v-container fluid class="py-2 px-4">
            <v-row align="center">
              <v-col cols="12" md="6" class="d-flex align-center">
                <label
                  class="mr-2 d-flex align-center"
                  style="white-space: nowrap; font-weight: 600 !important; font-size: 15px"
                >
                  Data File<i class="fas fa-star-of-life pink--text label-icon"></i>
                </label>
                <file
                  :canUpdate="false"
                  :canDelete="false"
                  :canView="false"
                  outlined
                  mini
                  style="flex: 1 1 auto"
                  :value="selected.requestFile"
                ></file>
              </v-col>
              <v-col cols="12" md="6" class="d-flex align-center">
                <v-sheet
                  color="secondary"
                  elevation="2"
                  rounded
                  class="px-2 d-flex align-center"
                  height="32px"
                >
                  <div class="d-flex align-start py-0 white--text font-weight-medium fs-12px">
                    <span><i class="fad fa-layer-group mr-2"></i>Total Loaded Items</span>
                    <v-divider class="mx-2" vertical dark></v-divider>
                    <v-chip
                      class="mono-font px-2"
                      x-small
                      :color="'white'"
                      :text-color="'secondary'"
                    >
                      {{ selected.requestItems.length }}
                    </v-chip>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
          <div style="height: 100%; overflow: overlay; flex: 1 1 auto">
            <v-scroll-y-transition
              mode="out-in"
              style="
                overflow: auto;
                overflow: overlay;
                height: 100%;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
              "
            >
              <wire-cad-manufacturer-request-table
                :readonly="readonly"
                :items.sync="selected.requestItems"
              ></wire-cad-manufacturer-request-table>
            </v-scroll-y-transition>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && selected.state == enums.WireCadIntegrationRequestState.Draft.value"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="green"
                :loading="slideout.isSending"
                :disabled="slideout.isLoading || slideout.hasChanges"
                @click="sendToManagement"
              >
                <i class="fad fa-swap-opacity fa-paper-plane mr-2"></i>
                Send For Approval
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Send this request to the management for approval!
            </span>
          </v-tooltip>
        </div>
        <div
          class="d-flex ml-2"
          v-else-if="
            !isFetching &&
            selected.state == enums.WireCadIntegrationRequestState.PendingManagement.value
          "
        >
          <div class="d-flex ml-2" v-if="!isFetching && canReject">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="pink"
                  dark
                  :loading="modals.reject.loading"
                  :disabled="modals.reject.loading"
                  @click="rejectRequest"
                >
                  <i class="far fa-power-off mr-2"></i>
                  Reject Request
                </v-btn>
              </template>
              <span class="d-flex align-center"> Reject request </span>
            </v-tooltip>
          </div>
          <div class="d-flex ml-2" v-if="!isFetching && canApprove">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="cyan"
                  dark
                  :loading="modals.approve.loading"
                  :disabled="modals.approve.loading"
                  @click="approveRequest"
                >
                  <i class="far fa-check-double mr-2"></i>
                  Approve Request
                </v-btn>
              </template>
              <span class="d-flex align-center">
                Approve request and proceed to shift the hours!
              </span>
            </v-tooltip>
          </div>
        </div>
        <v-divider vertical class="ml-4 mr-2"></v-divider>
        <div
          class="d-flex ml-2"
          v-if="
            !isFetching &&
            readonly &&
            selected.state == enums.WireCadIntegrationRequestState.Draft.value &&
            $has(perms.WireCadIntegrationRequests.Update)
          "
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideout.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideout.isLoading || !slideout.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideout.valid ||
                  !validJustification ||
                  slideout.isLoading ||
                  !slideout.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideout.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save" : "Create" }}
              <kbd class="light ml-1 fs-12px"> CTRL<span class="opacity-54">+</span>S </kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideout.valid ||
                  !validJustification ||
                  slideout.isLoading ||
                  !slideout.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideout.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save & Close" : "Create & Close" }} Panel
              <kbd class="light ml-2 fs-12px">
                CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S
              </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideout.isLoading || isFetching || slideout.hasChanges"
                @click="fetchRequest()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Request</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideout.isLoading || slideout.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item
              @click="del"
              v-if="
                $has(perms.WireCadIntegrationRequests.Delete) &&
                selected.state == enums.WireCadIntegrationRequestState.Draft.value
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="
                !$has(perms.WireCadIntegrationRequests.Delete) ||
                selected.state != enums.WireCadIntegrationRequestState.Draft.value
              "
              >No Actions Available!
            </v-subheader>
          </v-list>
        </v-menu>
      </v-card-actions>

      <v-dialog
        v-model="modals.approve.active"
        max-width="800px"
        persistent
        style="z-index: 99999999"
      >
        <v-card>
          <v-card-title class="font-weight-bold" small>
            <span class="fs-16px">
              <i class="fad fa-check-double mr-2"></i>Approve Request: <b>{{ selected.name }}</b>
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-form v-model="modals.approve.valid" ref="approveRequestForm">
            <v-card-text>
              <v-container class="py-0">
                <v-row class="my-0" dense>
                  <v-col sm="12">
                    <rich-text-editor
                      v-model="modals.approve.note"
                      title="Approval Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                      showLabel
                      allowExpand
                    />
                    <input type="text" disabled readonly style="display: none" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="approveRequestDiscard()">
                <i class="fal fa-xmark mr-2"></i> Cancel
              </v-btn>
              <v-btn
                color="cyan"
                :dark="validApproveNotes && !modals.approve.loading"
                :disabled="!validApproveNotes || modals.approve.loading"
                :loading="modals.approve.loading"
                @click="approveRequestConfirmed()"
              >
                <i class="far fa-check-double mr-2"></i>
                Confirm Approval
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modals.reject.active"
        max-width="800px"
        persistent
        style="z-index: 99999999"
      >
        <v-card>
          <v-card-title class="font-weight-bold" small>
            <span class="fs-16px">
              <i class="fad fa-power-off mr-2"></i>Reject Request: <b>{{ selected.name }}</b>
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-form v-model="modals.reject.valid" ref="rejectForm">
            <v-card-text>
              <v-container class="py-0">
                <v-row class="my-0" dense>
                  <v-col sm="12">
                    <rich-text-editor
                      v-model="modals.reject.note"
                      title="Rejection Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                      showLabel
                      allowExpand
                    />
                    <input type="text" disabled readonly style="display: none" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="rejectRequestDiscard()">
                <i class="fal fa-xmark mr-2"></i> Cancel
              </v-btn>
              <v-btn
                color="pink"
                :dark="validRejectNotes && !modals.reject.loading"
                :disabled="!validRejectNotes || modals.reject.loading"
                :loading="modals.reject.loading"
                @click="rejectRequestConfirmed()"
              >
                <i class="far fa-power-off mr-2"></i>
                Confirm Rejection
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../../plugins/permissions";
import enums from "../../../../../plugins/enums";
import API from "../services/wirecad-manufactures-service";
import PanelSizeControl from "../../../../Shared/components/PanelSizeControl.vue";
import WireCadManufacturerRequestTable from "./WireCadManufacturerRequestTable.vue";
import File from "../../../../Shared/components/FileManager/File.vue";
import RichTextEditor from "../../../../Shared/components/RichTextEditor.vue";

export default {
  name: "edit-wire-cad-manufacturer-request",
  components: {
    PanelSizeControl,
    WireCadManufacturerRequestTable,
    File,
    RichTextEditor,
  },
  data() {
    return {
      perms,
      enums,
      requestId: null,
      readonly: false,
      isFetching: null,
      closeAfter: false,
      selected: {
        name: null,
        justification: null,
        requestItems: [],
      },
      selectedCemented: {},
      slideout: {
        tab: 0,
        valid: false,
        active: false,
        fullWidth: false,
        isLoading: false,
        isSaving: false,
        isClosing: false,
        hasChanges: false,
      },
      modals: {
        reject: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
        approve: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      // return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "1400px";
      return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "75%";
    },
    validJustification() {
      if (!this.slideout.active || this.isFetching) return false;
      return (
        this.selected.justification != null &&
        this.selected.justification.trim() != "<p></p>" &&
        this.selected.justification.trim() != ""
      );
    },
    canApprove() {
      if (!this.slideout.active || this.isFetching) return false;
      return (
        this.$has(perms.WireCadIntegrationRequests.Approve) &&
        this.selected.state == enums.WireCadIntegrationRequestState.PendingManagement.value
      );
    },
    canReject() {
      if (!this.slideout.active || this.isFetching) return false;
      return (
        this.$has(perms.WireCadIntegrationRequests.Reject) &&
        this.selected.state == enums.WireCadIntegrationRequestState.PendingManagement.value
      );
    },
    validApproveNotes() {
      if (!this.slideout.active || this.isFetching) return false;
      return (
        this.modals.approve.note != null &&
        this.modals.approve.note.trim() != "<p></p>" &&
        this.modals.approve.note.trim() != ""
      );
    },
    validRejectNotes() {
      if (!this.slideout.active || this.isFetching) return false;
      return (
        this.modals.reject.note != null &&
        this.modals.reject.note.trim() != "<p></p>" &&
        this.modals.reject.note.trim() != ""
      );
    },
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideout.valid &&
        this.validJustification &&
        !this.slideout.isLoading &&
        this.slideout.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideout.valid &&
        this.validJustification &&
        !this.slideout.isLoading &&
        this.slideout.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.selected.state == enums.WireCadIntegrationRequestState.Draft.value &&
        this.$has(perms.WireCadIntegrationRequests.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode();
      }
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideout.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideout.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {
        name: null,
        justification: null,
        requestItems: [],
      };
      this.cementRequest();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideout.active = false;
    },
    open(id, editMode = false) {
      this.$log(">>>>>>> open", id, editMode);
      this.readonly = !editMode;
      this.requestId = id;
      this.fetchRequest();
      this.slideout.valid = false;
      this.slideout.fullWidth = false;
      this.slideout.active = true;
    },
    fetchRequest() {
      this.isFetching = true;
      API.getById(this.requestId)
        .then((resp) => {
          this.$log("getRequestById >> success", resp.data);
          this.isFetching = false;
          this.slideout.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementRequest();
        })
        .catch((err) => {
          this.$log("getRequestById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    cementRequest() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.$log("########>>>>>>> cementRequest()");
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideout.active) return;

      this.slideout.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideout.hasChanges);

      if (this.slideout.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm0, () => {
          this.slideout.tab = 0;
        })
      )
        return;

      if (!this.validJustification) {
        this.slideout.tab = 0;
        setTimeout(() => {
          this.$refs.richTextEditor.focus();
        }, 100);
        return;
      }

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    announceChange(isNewRequest) {
      this.$emit("save", this.selected, isNewRequest);
    },
    updateConfirmed(closeAfter) {
      this.closeAfter = closeAfter;
      this.slideout.isLoading = true;
      let toSend = this.cloneDeep(this.selected);
      this.$log("update >> toSend", toSend);
      API.update(toSend)
        .then((resp) => {
          this.slideout.isLoading = false;
          var message = "Request updated successfully!";
          this.$log(">>> updated", resp.data);
          this.selected = resp.data;
          this.cementRequest();
          var isNewRequest = false;
          if (!toSend.id) {
            isNewRequest = true;
            this.requestId = this.selected.id;
            message = "Request added successfully!";
          }
          this.announceChange(isNewRequest);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (this.closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((error) => {
          this.$log("Creating request Error:", error);
          this.slideout.isLoading = false;
          this.$handleError(error);
        });
    },
    sendToManagement() {
      this.$dialog
        .warning({
          text: `Are you sure you want to send this Request to Management?<br/>
            <h4 class="fs-12px">${this.selected.name}</h4>
          `,
          title: `Send Request to Management?`,
          color: "green",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "green",
              handle: () => {
                this.slideout.isSending = true;
                return API.sendToManagement(this.selected.id)
                  .then((resp) => {
                    this.slideout.isSending = false;
                    this.onSendToManagementSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Request sent to Management successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.slideout.isSending = false;
                    this.$dialog.notify.error("Error sending request!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onSendToManagementSuccess(id) {
      this.$emit("sent", id);
    },
    onManufacturerIntegrationRequestDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    del(request) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this integration request?`,
          title: `Delete Manufacturer Integration Request?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return API.delete(request.id)
                  .then(() => {
                    this.onManufacturerIntegrationRequestDeleted(request.id);
                    this.$dialog.notify.success("Request deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch(() => {
                    this.$dialog.notify.error("An error occurred during deleting the request!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    approveRequest() {
      this.modals.approve.note = null;
      this.modals.approve.loading = false;
      this.modals.approve.active = true;
    },
    approveRequestConfirmed() {
      this.modals.approve.loading = true;
      API.approve({
        id: this.selected.id,
        note: this.modals.approve.note,
      })
        .then((resp) => {
          this.modals.approve.loading = false;
          this.onApproveRequestSuccess(this.selected.id);
          this.approveRequestDiscard();
          this.$dialog.notify.success(`Request <b>'${this.selected.name}'</b> Approved!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.approve.loading = false;
          this.$dialog.notify.error("Error approving the request!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    approveRequestDiscard() {
      this.modals.approve.note = null;
      this.modals.approve.loading = false;
      this.modals.approve.active = false;
    },
    onApproveRequestSuccess(id) {
      this.$emit("approve", id);
    },
    rejectRequest() {
      this.modals.reject.note = null;
      this.modals.reject.loading = false;
      this.modals.reject.active = true;
    },
    rejectRequestConfirmed() {
      this.modals.reject.loading = true;
      API.reject({
        id: this.selected.id,
        note: this.modals.reject.note,
      })
        .then((resp) => {
          this.modals.reject.loading = false;
          this.onRejectRequestSuccess(this.selected.id);
          this.rejectRequestDiscard();
          this.$dialog.notify.success(`Request <b>'${this.selected.name}'</b> Rejected!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.reject.loading = false;
          this.$dialog.notify.error("Error approving the request!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    rejectRequestDiscard() {
      this.modals.reject.note = null;
      this.modals.reject.loading = false;
      this.modals.reject.active = false;
    },
    onRejectRequestSuccess(id) {
      this.$emit("reject", id);
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
