export default [
  {
    text: "Group",
    value: "name",
    cellClass: "py-2",
    sortable: false,
  },
  {
    text: "Actions",
    value: "actions",
    align: "left",
    width: "100px",
    sortable: false,
  },
];
