<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Client ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> Client: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <entity-id class="ml-2" :label="selected.id" :path="clientUrl" title="Client"></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a Client
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <div color="white" style="position: sticky; top: 0; z-index: 2">
      <v-tabs
        v-model="slideouts.update.tab"
        color="error"
        v-if="slideouts.update.active && !isFetching"
      >
        <v-tab :key="0">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!basicInfoTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Basic Information
        </v-tab>
        <v-tab :key="1"> Details </v-tab>
        <v-tab :key="2"> Variables </v-tab>
        <v-tab :key="3">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!accessListTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Access List
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Client ...</p>
    </v-container>
    <v-container key="tabs-items-container" fluid class="pa-0" v-else>
      <v-tabs-items v-model="slideouts.update.tab" style="overflow: visible !important">
        <v-tab-item :key="0">
          <v-form v-model="forms.infoTab" ref="updateForm">
            <company-basic-information
              v-if="slideouts.update.active"
              v-model="selected"
              :imageApiUrl="imageApiUrl"
              :readonly="readonly"
              :isActive="slideouts.update.active"
              class="pb-0"
              @valid-changes="forms.infoTab = $event"
            ></company-basic-information>
          </v-form>
          <!-- <v-container class="pt-0">
              <v-row class="my-0" dense>
                <v-col cols="12" sm="12">
                  <user-selector
                    v-model="selected.leadVIId"
                    :obj.sync="selected.leadVI"
                    :readonly="readonly"
                    label="Lead VI (Default)"
                  ></user-selector>
                </v-col>
              </v-row>
            </v-container> -->
        </v-tab-item>
        <v-tab-item :key="1">
          <company-details v-model="selected" :readonly="readonly"></company-details>
        </v-tab-item>
        <v-tab-item :key="2">
          <v-container fluid class="px-3 pt-3 pb-0">
            <h3 class="text--primary font-weight-black mb-0 mr-3 d-inline-block">
              Client Variables
            </h3>
            <client-variables v-model="selected" :readonly="readonly"></client-variables>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="3">
          <access-list
            ref="accessListComponent"
            v-model="selected"
            :readonly="readonly"
            @valid-changes="forms.accessListTab = $event"
          ></access-list>
        </v-tab-item>
      </v-tabs-items>
    </v-container>

    <manage-contacts nested ref="manageContacts"></manage-contacts>
    <merge-client nested ref="mergeClientComp"></merge-client>
    <client-projects-slideout nested ref="clientProjects"></client-projects-slideout>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && readonly && isMainRoute && $has(perms.Clients.Update)"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="pink"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchClient()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Client</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>

          <v-list class="more-options-menu">
            <v-list-item @click="showContacts" v-if="$has(perms.CompanyContacts.View)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-address-book</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Contacts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="displayStats">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-analytics</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Stats</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-1"></v-divider>
            <v-list-item @click="mergeClient" v-if="$has(perms.Clients.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>far fa-code-merge</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Merge</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-1"></v-divider>

            <v-list-item @click="del" v-if="$has(perms.Clients.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import AccessList from "./AccessList.vue";
import perms from "../../../../plugins/permissions";
import ApiService from "../services/clients-service.js";
import CompanyBasicInformation from "../../Shared/components/BasicInformation.vue";
import CompanyDetails from "../../Shared/components/Details.vue";
// import UserSelector from "../../../Shared/components/UserSelector.vue";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";
import ManageContacts from "../../Shared/components/ManageContacts.vue";
import MergeClient from "./MergeClient.vue";
import ClientProjectsSlideout from "./ClientProjectsSlideout.vue";
import ClientVariables from "./ClientVariables.vue";

export default {
  name: "edit-client",
  data() {
    return {
      perms,
      clientId: null,
      isFetching: false,
      imageApiUrl: `clients/Image`,
      selected: {
        domain: "",
        city: "",
        industry: "",
        phone: "",
        state: "",
      },
      readonly: false,
      selectedCemented: {},
      slideouts: {
        update: {
          tab: 0,
          active: false,
          isLoading: false,
          isClosing: false,
          fullWidth: false,
          hasChanges: false,
        },
      },
      forms: {
        infoTab: true,
        accessListTab: true,
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valid() {
      if (this.forms.infoTab && this.forms.accessListTab) return true;
      return false;
    },
    clientUrl() {
      if (this.selected == null) return "";
      return `clients/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-client") return true;
      else return false;
    },
    basicInfoTabIsValid() {
      return this.forms.infoTab;
    },
    accessListTabIsValid() {
      return this.forms.accessListTab;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "800px";
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.isMainRoute &&
        this.$has(this.perms.Clients.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    showContacts() {
      this.$refs.manageContacts.open(this.selected.id, false);
    },
    mergeClient() {
      this.$refs.mergeClientComp.open(this.selected);
    },
    displayStats() {
      this.$refs.clientProjects.open(this.selected);
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementClient();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewClient) {
      this.$emit("save", this.selected, isNewClient);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      this.$log("update >> toSend", toSend);
      ApiService.update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          this.$log(">>> updated", resp.data);
          var message = "Client updated successfully!";
          this.selected = resp.data;
          this.cementClient();
          var isNewClient = false;
          if (!toSend.id) {
            this.clientId = this.selected.id;
            isNewClient = true;
            message = "Client added successfully!";
          }
          this.announceChange(isNewClient);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });

          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      if (id == null) {
        this.selected = {
          name: "",
          abbreviatedName: "",
          installationFactor: 0,
        };
        this.readonly = false;
        this.isFetching = false;
        this.clientId = null;
        this.cementClient();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
      } else {
        this.readonly = !editMode;
        this.clientId = id;
        this.fetchClient();
      }
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
      }, 250);
    },
    getClientDetails(companyId) {
      ApiService.getHubspotDetails(this.$clean(companyId)).then((resp) => {
        var details = resp.data;
        this.selected.domain = details.domain;
        this.selected.industry = details.industry;
        this.selected.state = details.state;
        this.selected.phone = details.phone;
        this.selected.city = details.city;
      });
    },
    fetchClient() {
      this.isFetching = true;
      ApiService.getClientById(this.clientId)
        .then((resp) => {
          this.$log("getClientById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementClient();
          // if (this.selected.hupspotCompanyId) {
          //   this.getClientDetails(this.selected.hupspotCompanyId);
          // }

          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getClientById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    cementClient() {
      if (this.selected.installationFactor == null) this.selected.installationFactor = 0;
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) {
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
      }

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this client: <b>${this.selected.name}</b>?`,
          title: `Delete Client?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(this.selected.id)
                  .then((resp) => {
                    this.$emit("delete", this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Item deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
  },
  watch: {
    client: {
      handler() {
        this.selected = this.cloneDeep(this.client || {});
        this.cementClient();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "selected.abbreviatedName": {
      handler() {
        if (this.selected != null && this.selected.abbreviatedName != null)
          this.selected.abbreviatedName = this.selected.abbreviatedName.toUpperCase();
      },
      deep: true,
    },
  },
  components: {
    CompanyBasicInformation,
    CompanyDetails,
    // UserSelector,
    PanelSizeControl,
    ManageContacts,
    MergeClient,
    ClientProjectsSlideout,
    ClientVariables,
    AccessList,
  },
};
</script>
