<template>
  <div class="v-select-alt" :class="{ 'span-horizontal': spanHorizontal }">
    <div style="display: flex; align-items: center; justify-content: space-between">
      <label
        v-if="label != null && label != ''"
        :for="this.id"
        v-html="label + requiredIcon"
      ></label>
      <slot name="label-append"></slot>
    </div>
    <v-select
      :id="this.id"
      ref="vSelect"
      append-icon="fas fa-caret-down"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
      @keydown="onKeyDown"
    >
      <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "v-select-alt",
  inheritAttrs: true,
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    spanHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.code == "Escape") {
        this.$refs.vSelect.blur();
        setTimeout(() => {
          let parent = this.$refs.vSelect.$el.closest("div.vue-slideout");
          if (parent) parent.focus();
        });
      }
    },
    focus() {
      this.$refs.vSelect.focus();
    },
    select() {
      this.focus();
      this.$refs.vSelect.$el.querySelector("input").select();
    },
  },
  computed: {
    hasError() {
      return this.$refs.vSelect.hasError;
    },
    hasRequiredRule() {
      if (this.$attrs == null || this.$attrs.rules == null) return false;
      return this.$attrs.rules.some((r) => r.name == "required" || r.name == "requiredNumber");
    },
    requiredIcon() {
      return this.hasRequiredRule
        ? " <i class='fas fa-star-of-life pink--text label-icon'></i>"
        : "";
    },
    commonAttrs() {
      return {
        label: "",
        solo: true,
        dense: true,
        flat: true,
        filled: true,
        class: {},
      };
    },
  },
};
</script>

<style lang="scss">
.v-select-alt {
  label {
    font-weight: 600 !important;
    font-size: 15px;
    white-space: nowrap;
  }

  .v-input {
    .v-select__slot {
      font-size: 14px !important;

      .v-select__selection {
        max-width: 100%;
      }
    }

    .v-input__control > .v-input__slot {
      background-color: rgba($shades-black, 0.08) !important;
      min-height: 38px !important;

      .v-input__icon .v-icon {
        font-size: 16px;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1.5px solid transparent !important;
        border-radius: 4px;
        z-index: 1;
        pointer-events: none;
        user-select: none;
        transition: all 0.15s ease-out;
      }
    }

    .v-input__control > .v-input__slot:hover {
      background-color: rgba($shades-black, 0.12) !important;

      &:before {
        border: 1.5px solid transparent !important;
      }
    }

    &.v-input--is-focused {
      .v-input__control > .v-input__slot {
        background-color: rgba($shades-black, 0.14) !important;

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #dddfde inset !important;
        }

        &:before {
          border: 1.5px solid rgba($shades-black, 0.87) !important;
        }
      }
    }

    &.error--text {
      .v-input__control > .v-input__slot {
        background-color: #fce4ec !important;
        .v-input__icon .v-icon {
          color: var(--v-accent-darken2) !important;
        }
      }
      .v-input__control > .v-input__slot:hover {
        background-color: #fce4ec !important;
      }
      &.v-input--is-focused {
        .v-input__control > .v-input__slot {
          background-color: #f8bbd0 !important;
        }
      }
    }

    &.v-input--is-readonly {
      .v-input__control > .v-input__slot {
        background-color: transparent !important;
        border: 1px dashed rgba($shades-black, 0.24) !important;
        cursor: text;
      }

      .v-input__append-inner {
        display: none !important;
      }
    }
  }

  &.v-select-alt-short {
    .v-input {
      .v-input__control > .v-input__slot {
        min-height: 32px !important;
      }
    }
  }

  &.span-horizontal {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
