var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"300px","persistent":""},model:{value:(_vm.modals.active),callback:function ($$v) {_vm.$set(_vm.modals, "active", $$v)},expression:"modals.active"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold d-flex",attrs:{"small":""}},[_c('i',{staticClass:"fad fa-circle-pause mr-2"}),_vm._v("Add Paused Duration ")]),_c('v-divider'),_c('v-form',{ref:"addPausedDurationForm",model:{value:(_vm.modals.valid),callback:function ($$v) {_vm.$set(_vm.modals, "valid", $$v)},expression:"modals.valid"}},[_c('v-card-text',[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field-alt',{ref:"phaseName",attrs:{"rules":[_vm.allRules.required, _vm.allRules.noWhiteSpaces],"label":"Paused Phase(s) Name","id":"phaseName","placeholder":"Paused Phase(s) Name"},model:{value:(_vm.modals.data.name),callback:function ($$v) {_vm.$set(_vm.modals.data, "name", $$v)},expression:"modals.data.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-menu',{attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","min-width":"auto","nudge-top":20},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field-alt',_vm._g(_vm._b({staticClass:"calendar-input",attrs:{"rules":[_vm.allRules.required],"prepend-inner-icon":"mdi-calendar","label":"Start Paused Month","id":"startMonthValue","placeholder":"Start Paused Month"},model:{value:(_vm.modals.data.startDate),callback:function ($$v) {_vm.$set(_vm.modals.data, "startDate", $$v)},expression:"modals.data.startDate"}},'v-text-field-alt',attrs,false),on))]}}]),model:{value:(_vm.startMonthMenu),callback:function ($$v) {_vm.startMonthMenu=$$v},expression:"startMonthMenu"}},[_c('v-date-picker',{attrs:{"color":"orange darken-3","header-color":"secondary","type":"month"},on:{"input":function($event){_vm.startMonthMenu = false}},model:{value:(_vm.modals.data.startDate),callback:function ($$v) {_vm.$set(_vm.modals.data, "startDate", $$v)},expression:"modals.data.startDate"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field-alt',{ref:"phasesCount",attrs:{"rules":[
                _vm.allRules.required,
                _vm.allRules.noWhiteSpaces,
                _vm.allRules.intNumber,
                _vm.allRules.numberMin(1) ],"label":"Paused Phases Count","id":"phasesCount","placeholder":"Paused Phases Count"},model:{value:(_vm.modals.data.phasesCount),callback:function ($$v) {_vm.$set(_vm.modals.data, "phasesCount", $$v)},expression:"modals.data.phasesCount"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addPauseDurationPhasesDiscard()}}},[_c('i',{staticClass:"fal fa-xmark mr-2"}),_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"info","disabled":!_vm.modals.valid,"loading":_vm.modals.loading},on:{"click":function($event){return _vm.addPausedDurationConfirmed()}}},[_c('i',{staticClass:"fal fa-check mr-2"}),_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }