<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 1000"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="'slideout-dark-striped-header-readonly-content view-report-history-slideout'"
    :visible.sync="slideout.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching && downloadTaskCategoryObj != null" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching
        {{ downloadTaskCategoryObj.text }} report download history ...
      </h3>
      <div v-else-if="selected != null">
        <div
          style="background: rgba(255, 255, 255, 0.24)"
          class="d-inline-flex mt-1 align-center opacity-72 fs-12px font-weight-medium px-1 rounded"
        >
          <i class="fad fa-folder mr-2"></i>{{ selected.project.fullName }}
        </div>
        <h3 class="pl-1 mt-1">
          <i class="fad fs-16px mb-1 mr-2" :class="downloadTaskCategoryObj.icon"></i>
          <span>{{ downloadTaskCategoryObj.text }}</span>
          <span class="opacity-72 pl-1">Report Download History</span>
        </h3>
      </div>
      <div>
        <panel-size-control v-model="slideout.fullWidth" dark></panel-size-control>
        <v-btn @click="closeSlideout()" icon dark>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-container
      fluid
      class="pa-5 inset-shadow text-center d-flex align-center"
      style="background: #eceff1"
      v-if="isFetching && downloadTaskCategoryObj != null"
    >
      <video
        width="250"
        muted
        loop
        autoplay
        class="inset-shadow-video"
        style="padding: 0.5rem; border-radius: 10rem; background: #fff; margin: 0 auto"
      >
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold mt-5">
        Fetching {{ downloadTaskCategoryObj.text }} report download history ...
      </p>
    </v-container>
    <v-container
      fluid
      v-else-if="selected != null"
      class="pa-5 inset-shadow"
      style="background: #eceff1"
    >
      <v-row class="mb-0">
        <v-col class="d-flex align-center">
          <v-sheet
            key="sheets-status"
            elevation="2"
            height="32"
            rounded
            class="d-inline-flex align-center justify-center pl-1 pr-1"
          >
            <!-- pl-3 pr-1 -->
            <!-- <h4 class="ma-0 text--secondary fs-14px" style="flex: none">Show:</h4> -->
            <!-- class="ml-3" -->
            <v-btn-toggle
              class="ml-0"
              v-model="activeStatus"
              mandatory
              color="deep-purple"
              dense
              group
              style="height: 32px"
            >
              <v-btn
                :value="null"
                color="white"
                class="mx-0"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad fa-file-signature mr-2"></i>
                <span style="text-transform: none" class="font-weight-medium">All</span>
              </v-btn>
              <v-divider vertical inset class="mx-2"></v-divider>
              <v-btn
                :value="enums.DownloadStatus.Succeeded.value"
                color="white"
                class="mx-0 mr-1"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad mr-2" :class="enums.DownloadStatus.Succeeded.iconAlt"></i>
                <span style="text-transform: none" class="font-weight-medium">{{
                  enums.DownloadStatus.Succeeded.text
                }}</span>
              </v-btn>
              <v-btn
                :value="enums.DownloadStatus.Inprogress.value"
                color="white"
                class="mx-0 mr-1"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad mr-2" :class="enums.DownloadStatus.Inprogress.iconAlt"></i>
                <span style="text-transform: none" class="font-weight-medium">{{
                  enums.DownloadStatus.Inprogress.text
                }}</span>
              </v-btn>
              <v-btn
                :value="enums.DownloadStatus.Pending.value"
                color="white"
                class="mx-0 mr-1"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad mr-2" :class="enums.DownloadStatus.Pending.iconAlt"></i>
                <span style="text-transform: none" class="font-weight-medium">{{
                  enums.DownloadStatus.Pending.text
                }}</span>
              </v-btn>
              <v-btn
                :value="enums.DownloadStatus.Failed.value"
                color="white"
                class="mx-0"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad mr-2" :class="enums.DownloadStatus.Failed.iconAlt"></i>
                <span style="text-transform: none" class="font-weight-medium">{{
                  enums.DownloadStatus.Failed.text
                }}</span>
              </v-btn>
            </v-btn-toggle>
          </v-sheet>
        </v-col>
      </v-row>
      <v-data-table
        style="
          overflow: auto;
          overflow: overlay;
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        "
        dense
        :headers="downloadHistoryHeaderLite"
        :items="itemsFiltered"
        :items-per-page="-1"
        class="elevation-2 download-center-table table-sticky-header-exclude-footer"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
        </template>

        <template v-slot:[`item.fileName`]="{ item }">
          <h4>{{ item.fileName }}</h4>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <download-status :status="item.status"></download-status>
        </template>

        <template v-slot:[`item.downloadTaskCategory`]="{ item }">
          <download-task-category :category="item.downloadTaskCategory"></download-task-category>
        </template>

        <template v-slot:[`item.createdBy`]="{ item }">
          <user-avatar :user="item.createdBy" icon></user-avatar>
        </template>

        <template v-slot:[`item.createDate`]="{ item }">
          <dater :date="item.createDate" date-only></dater>
        </template>

        <template v-slot:[`item.updateDate`]="{ item }">
          <dater :date="item.updateDate" date-only></dater>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            x-small
            color="green darken-1 py-0"
            :dark="item.url != null && item.url != ''"
            :href="item.url"
            :download="item.fileName"
            :disabled="item.url == null || item.url == ''"
          >
            <span class="fs-10px">
              <i class="far fa-arrow-down" style="margin-right: 0.5rem"></i
              ><span style="font-weight: 600">Download </span>
            </span>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <img width="500" src="/img/art/fogg-no-connection-2.png" />
          <p class="font-weight-bold">No Data Available!</p>
        </template>
        <template v-slot:loading>
          <video width="250" muted loop autoplay style="padding: 0.5rem">
            <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
          </video>
          <p class="font-weight-bold">Searching the Cosmos...</p>
        </template>
      </v-data-table>
    </v-container>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="isFetching"
                @click="fetchReportHistory()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span v-if="downloadTaskCategoryObj != null"
              >Refetch {{ downloadTaskCategoryObj.text }} download history</span
            >
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import enums from "../../../../../plugins/enums";
import reportsService from "../../../services/reports-service";
import downloadHistoryHeader from "../../../../DownloadCenter/config/tables/downloadHistory.header";
import PanelSizeControl from "../../../../Shared/components/PanelSizeControl.vue";
import DownloadStatus from "../../../../DownloadCenter/components/DownloadStatus.vue";
import DownloadTaskCategory from "../../../../DownloadCenter/components/DownloadTaskCategory.vue";

export default {
  name: "view-report-history",
  components: { PanelSizeControl, DownloadStatus, DownloadTaskCategory },
  data() {
    return {
      enums,
      projectId: null,
      downloadTaskCategory: null,
      activeStatus: null,
      isFetching: false,
      selected: null,
      slideout: {
        valid: false,
        active: false,
        fullWidth: false,
        isClosing: false,
      },
      downloadHistoryHeader,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideout.fullWidth ? "100%" : this.nested ? "87%" : "1000px";
    },
    downloadTaskCategoryObj() {
      if (this.downloadTaskCategory == null) return null;
      return this.getEnumMember(enums.DownloadTaskCategory, this.downloadTaskCategory);
    },
    downloadHistoryHeaderLite() {
      var list = this.downloadHistoryHeader.filter((h) => h.value != "downloadTaskCategory");
      return list;
    },
    itemsFiltered() {
      if (this.selected == null) return [];
      return this.selected.items.filter(
        (i) => this.activeStatus == null || i.status == this.activeStatus
      );
    },
  },
  methods: {
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideout.active);

      this.selected = null;
      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    closeSlideout() {
      this.slideout.active = false;
    },
    open(projectId, downloadTaskCategory) {
      this.$log(">>>>>>> open", projectId, downloadTaskCategory);
      this.projectId = projectId;
      this.downloadTaskCategory = downloadTaskCategory;
      this.fetchReportHistory();
      this.activeStatus = null;
      this.slideout.active = true;
    },
    fetchReportHistory() {
      this.isFetching = true;
      reportsService
        .getReportDownloadHistory({
          projectId: this.projectId,
          downloadTaskCategory: this.downloadTaskCategory,
        })
        .then((resp) => {
          this.$log("getReportDownloadHistory >> success", resp.data);
          this.isFetching = false;
          this.selected = this.cloneDeep(resp.data);
        })
        .catch((err) => {
          this.$log("getReportDownloadHistory >> error", err);
          this.closeSlideout();
          this.$handleError(err);
        });
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.view-report-history-slideout {
  .vue-slideout-header {
    min-height: 64px;
  }
}
</style>
