import perms from "../../plugins/permissions";
export default [
  {
    path: "/manage-contracts",
    name: "manage-contracts",
    meta: {
      layout: "dashboard",
      title: "Manage Contracts",
      permissions: [perms.ProjectContracts.Manage],
    },
    component: () => import("./views/ManageContracts.vue"),
  },
  {
    path: "/manage-contracts/:id",
    name: "manage-contract",
    meta: {
      layout: "dashboard",
      title: "Manage Contract",
      permissions: [perms.ProjectContracts.Manage],
    },
    component: () => import("./views/ManageContracts.vue"),
  },
  {
    path: "/manage-direct-expenses",
    name: "manage-direct-expenses",
    meta: {
      layout: "dashboard",
      title: "Manage Direct Expenses",
      permissions: [perms.ProjectContracts.ManageDirectExpenses],
    },
    component: () => import("./views/ManageDirectExpenses.vue"),
  },
];
