<template>
  <div
    style="height: 100%; display: flex; flex-direction: column"
    :class="{ 'pa-3': managementMode }"
  >
    <page-title
      v-if="managementMode"
      title="Manage Contracts"
      :badge="total"
      subtitle="Browse and Manage your Contracts!"
    />

    <v-row v-if="managementMode" class="mt-0 mb-1" justify="space-between">
      <v-col cols="12" md="auto">
        <div class="d-flex flex-row align-center justify-start flex-wrap" style="gap: 0.5rem">
          <v-sheet
            key="sheets-status"
            elevation="2"
            height="32"
            rounded
            class="d-inline-flex align-center justify-center pl-1 pr-1"
          >
            <!-- pl-3 pr-1 -->
            <!-- <h4 class="ma-0 text--secondary fs-14px" style="flex: none">Show:</h4> -->
            <!-- class="ml-3" -->
            <v-btn-toggle
              class="ml-0"
              v-model="options.activeStatus"
              mandatory
              color="deep-purple"
              dense
              group
              style="height: 32px"
            >
              <v-btn
                :value="5"
                color="white"
                class="mx-0"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad fa-file-signature mr-2"></i>
                <span style="text-transform: none" class="font-weight-medium">All</span>
              </v-btn>
              <v-divider vertical inset class="mx-2"></v-divider>
              <v-btn
                :value="1"
                color="white"
                class="mx-0 mr-1"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad fa-pen mr-2"></i>
                <span style="text-transform: none" class="font-weight-medium">Draft</span>
              </v-btn>
              <v-btn
                :value="2"
                color="white"
                class="mx-0 mr-1"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad fa-clock mr-2"></i>
                <span style="text-transform: none" class="font-weight-medium">Pending</span>
              </v-btn>
              <v-btn
                :value="3"
                color="white"
                class="mx-0"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad fa-circle-play mr-2"></i>
                <span style="text-transform: none" class="font-weight-medium">Approved</span>
              </v-btn>
              <v-divider vertical inset class="mx-2"></v-divider>
              <v-btn
                :value="4"
                color="white"
                class="mx-0"
                style="
                  border-radius: 4px;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                "
              >
                <i class="fad fa-archive mr-2"></i>
                <span style="text-transform: none" class="font-weight-medium">Archived</span>
              </v-btn>
            </v-btn-toggle>
          </v-sheet>
          <v-sheet
            key="sheets-zero-rate"
            elevation="2"
            height="32"
            rounded
            class="d-inline-flex align-center justify-center pl-3 pr-3"
          >
            <v-switch
              v-model="options.hasZeroRates"
              class="ma-0 pa-0"
              hide-details
              dense
              color="orange darken-1"
            >
              <template v-slot:label>
                <label
                  class="v-switch-label ma-0 fs-14px"
                  :class="{
                    'orange--text text--darken-1': options.hasZeroRates,
                    'opacity-87': !options.hasZeroRates,
                  }"
                >
                  Zero Rates
                </label>
              </template>
            </v-switch>
            <!-- <h4 class="ma-0 text--secondary fs-14px" style="flex: none">
              Show Zero Rates Only
            </h4> -->
          </v-sheet>
        </div>
      </v-col>
      <v-col cols="12" md="auto">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <export-excel :options="options"></export-excel>
          <export-pdf :options="options"></export-pdf>
          <!-- <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet> -->
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="loadingContracts"
          >
          </v-text-field>
          <refresh :loading="loadingContracts" @refresh="getProjectContracts()"></refresh>
        </div>
      </v-col>
    </v-row>

    <v-row v-else class="mt-0 mb-1" justify="space-between" align="center">
      <v-col lg="7" sm="12" cols="12" class="d-flex align-center" style="gap: 0.5rem">
        <h3 class="text--primary font-weight-black">
          Contracts
          <v-chip class="ml-1 font-weight-bold" small label :loading="loadingContracts">{{
            contracts.length
          }}</v-chip>
        </h3>
        <v-btn color="info" class="ml-2" @click="add" v-if="$has(perms.ProjectContracts.Create)">
          <i class="fal fa-plus mr-2"></i>New Contract
        </v-btn>

        <v-switch
          v-model="options.includeArchived"
          class="ma-0 ml-2"
          color="brown"
          style="font-size: 14px !important"
          hide-details
        >
          <template v-slot:label>
            <label
              class="v-switch-label d-flex align-center ma-0 mx-1 fs-14px"
              :class="{ 'brown--text': options.includeArchived }"
            >
              <i class="fad fa-archive mr-2"></i>
              Include Archived Contracts</label
            >
          </template>
        </v-switch>
      </v-col>
      <v-col
        lg="5"
        md="12"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <filter-manager
          ref="filterManager"
          v-model="selectedFilters"
          :options.sync="options"
        ></filter-manager>
        <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingContracts"
        >
        </v-text-field>
        <refresh :loading="loadingContracts" @refresh="getProjectContracts()"></refresh>
      </v-col>
    </v-row>

    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="contracts"
      :options.sync="options"
      :server-items-length="total"
      :loading="loadingContracts"
      :item-class="rowClass"
      :items-per-page="options.itemsPerPage"
      @click:row="
        $has(perms.ProjectContracts.ConstructionPhasesView) ? openConstructionPhases($event) : false
      "
      class="elevation-2 contracts-table table-sticky-header-exclude-footer"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.origin`]="{ item }">
        <contract-origin :origin="item.origin" small></contract-origin>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <contract-type :type="item.type" small></contract-type>
      </template>

      <template v-slot:[`item.project.leadAC`]="{ item }">
        <user-avatar :user="item.project.leadAC" icon showNoUser></user-avatar>
      </template>

      <template v-slot:[`item.project.client.name`]="{ item }">
        <div class="my-1">
          <client-card :client="item.project.client" hide-name square></client-card>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="d-inline-flex">
          <span class="font-weight-bold">
            {{ item.name }}
          </span>
        </div>
      </template>

      <template v-slot:[`item.project.fullName`]="{ item }">
        <a class="project-link" :href="`/projects/${item.project.id}`" target="_blank" @click.stop>
          {{ item.project.fullName }}
        </a>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <code
          class="mono-font ml-2 secondary--text fs-12px"
          :class="{
            'brown lighten-4 brown--text': item.isArchived,
            'blue-grey lighten-5':
              !item.isArchived && item.status == enums.CONTRACT_STATUS.Draft.value,
            'orange lighten-4':
              !item.isArchived && item.status == enums.CONTRACT_STATUS.Pending.value,
            'green lighten-4':
              !item.isArchived && item.status == enums.CONTRACT_STATUS.Approved.value,
          }"
          style="flex: none"
        >
          #{{ item.id }}
        </code>
      </template>

      <template v-slot:[`item.noticeToProceed`]="{ item }">
        <span v-if="item.noticeToProceed">
          <i class="fas fa-check fs-16px pl-2 green--text" style="line-height: 0"> </i>
        </span>
        <span v-else>
          <i class="fal fa-times pl-2 text--disabled" style="line-height: 0"> </i>
        </span>
      </template>

      <template v-slot:[`item.prNumber`]="{ item }">
        <span v-if="item.prNumber">
          <i class="fas fa-check fs-16px pl-2 green--text" style="line-height: 0"> </i>
        </span>
        <span v-else>
          <i class="fal fa-times pl-2 text--disabled" style="line-height: 0"> </i>
        </span>
      </template>

      <template v-slot:[`item.poNumber`]="{ item }">
        <span v-if="item.poNumber">
          <i class="fas fa-check fs-16px pl-2 green--text" style="line-height: 0"> </i>
        </span>
        <span v-else>
          <i class="fal fa-times pl-2 text--disabled" style="line-height: 0"> </i>
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="d-inline-flex align-center pl-1">
          <i
            v-if="item.isLocked"
            class="fas fa-lock-keyhole secondary--text text--darken-1 fs-16px ml-1 mr-2"
          ></i>
          <i
            v-else-if="!item.isLocked && item.status == enums.CONTRACT_STATUS.Approved.value"
            class="fas fa-lock-keyhole-open deep-orange--text text--darken-1 fs-16px ml-1 mr-2"
          ></i>
          <i
            v-else-if="!item.isLocked && item.status == enums.CONTRACT_STATUS.Draft.value"
            class="fas fa-lock-keyhole-open secondary--text opacity-24 fs-16px ml-1 mr-2"
          ></i>
          <v-chip
            v-if="item.isArchived"
            class="px-2 ml-1"
            small
            label
            :color="'brown'"
            :text-color="'white'"
          >
            <i class="fad fa-archive mr-2"></i>
            <span class="font-weight-medium" style="line-height: 0"> Archived </span>
          </v-chip>
          <contract-status v-else class="ml-2" small :status="item.status"></contract-status>
          <v-tooltip
            top
            z-index="9999999"
            max-width="400px"
            v-if="item.status == enums.CONTRACT_STATUS.Approved.value"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                class="ml-1 px-1"
                min-width="20px"
                height="24px"
                small
                color="green darken-4"
                @click.stop="displayApprovedMessage(item)"
              >
                <i class="fad fa-circle-info fs-16px"></i>
              </v-btn>
            </template>
            <div style="width: 100%" class="py-1" :id="'contract-approved-msg-' + item.id">
              <div class="font-weight-bold green--text accent-3">
                <i class="fas fa-circle-check mr-1" style="width: 20px"></i>Contract Approved
              </div>
              <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
              <div class="d-flex align-center">
                <i class="fad fa-user mr-1" style="width: 20px"></i>By:
                <span class="font-weight-bold ml-1">
                  {{ item.approvedBy.firstName }}
                  {{ item.approvedBy.lastName }}
                </span>
              </div>
              <div class="d-flex align-center">
                <i class="fad fa-calendar-day mr-1" style="width: 20px"></i>On:
                <span class="font-weight-bold ml-1"> <dater :date="item.approvedOn"></dater></span>
              </div>
              <div
                class="d-flex align-start flex-column"
                v-if="
                  item.approveNote != null &&
                  item.approveNote.trim() != '<p></p>' &&
                  item.approveNote.trim() != ''
                "
              >
                <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
                <b class="d-inline-flex align-center">
                  <i class="fad fa-note mr-1" style="width: 20px"></i>Notes:
                </b>
                <div
                  class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 mt-2"
                  style="line-break: anywhere"
                  v-html="item.approveNote"
                ></div>
              </div>
            </div>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.totalHours`]="{ item }">
        <!-- <div class="d-inline-flex">
            <v-chip
              class="fs-12px ml-2"
              style="height: 20px !important; min-height: 20px !important"
              outlined
              pill
              :color="item.totalHours > 0 ? 'info' : item.totalHours < 0 ? 'pink' : 'grey'"
            >
              <i class="fas fa-clock mr-1"></i>{{ item.totalHours | fixedFloat }}
              {{ item.totalHours == 1 ? "hr" : "hrs" }}
            </v-chip>
          </div> -->
        <span
          class="fs-12px"
          :class="
            item.totalHours > 0 ? 'info--text' : item.totalHours < 0 ? 'pink--text' : 'grey--text'
          "
        >
          <span class="mono-font fs-13px">{{
            item.totalHours | fixedFloatFormatNoDecimalPoints
          }}</span>
          {{ item.totalHours == 1 ? "hr" : "hrs" }}
        </span>
      </template>

      <template v-slot:[`item.totalLaborRevenue`]="{ item }">
        <span
          class="fs-12px"
          :class="
            item.totalLaborRevenue > 0
              ? 'info--text'
              : item.totalLaborRevenue < 0
              ? 'pink--text'
              : 'grey--text'
          "
        >
          <span class="mono-font fs-13px">{{ item.totalLaborRevenue | usdFormat }}</span>
        </span>
      </template>

      <template v-slot:[`item.totalLaborCost`]="{ item }">
        <span
          class="fs-12px"
          :class="
            item.totalLaborCost > 0
              ? 'info--text'
              : item.totalLaborCost < 0
              ? 'pink--text'
              : 'grey--text'
          "
        >
          <span class="mono-font fs-13px">{{ item.totalLaborCost | usdFormat }}</span>
        </span>
      </template>

      <template v-slot:[`item.totalDirectExpensesCost`]="{ item }">
        <span
          class="fs-12px"
          :class="
            item.totalDirectExpensesCost > 0
              ? 'info--text'
              : item.totalDirectExpensesCost < 0
              ? 'pink--text'
              : 'grey--text'
          "
        >
          <span class="mono-font fs-13px">{{ item.totalDirectExpensesCost | usdFormat }}</span>
        </span>
      </template>

      <template v-slot:[`item.totalDirectExpensesRevenue`]="{ item }">
        <span
          class="fs-12px"
          :class="
            item.totalDirectExpensesRevenue > 0
              ? 'info--text'
              : item.totalDirectExpensesRevenue < 0
              ? 'pink--text'
              : 'grey--text'
          "
        >
          <span class="mono-font fs-13px">{{ item.totalDirectExpensesRevenue | usdFormat }}</span>
        </span>
      </template>

      <template v-slot:[`item.approvedBy`]="{ item }">
        <user-avatar :user="item.approvedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.approvedOn`]="{ item }">
        <dater :date="item.approvedOn" date-only></dater>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense bottom right z-index="1000" offset-y :disabled="readonly">
          <template v-slot:activator="{ attrs: menuAttrs, on: menuOn }">
            <div class="d-inline" v-bind="menuAttrs" v-on="menuOn">
              <v-tooltip top z-index="9999999" max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon elevation="0" :disabled="readonly">
                    <i class="far fa-ellipsis-v" style="font-size: 15px"></i>
                  </v-btn>
                </template>
                <span>More Options...</span>
              </v-tooltip>
            </div>
          </template>

          <v-list class="more-options-menu">
            <v-list-item
              @click="openUsersLaborRates(item)"
              v-if="
                ($has(perms.ProjectContracts.ViewLaborSellRates) ||
                  $has(perms.ProjectContracts.ViewLaborCostRates)) &&
                !readonly
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-user</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text"> Users Labor Rates </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="openDefaultLaborRates(item)"
              v-if="
                ($has(perms.ProjectContracts.ViewLaborSellRates) ||
                  $has(perms.ProjectContracts.ViewLaborCostRates)) &&
                !readonly
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-circle-question</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">
                  Generic Entry Labor Rates
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="displayActivityLogs(item)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-history</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text"> Activity Logs </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              class="my-2"
              v-if="
                (canSendForApproval(item) ||
                  canSendForUpdate(item) ||
                  canApprove(item) ||
                  canUnapprove(item) ||
                  canLock(item) ||
                  canUnlock(item) ||
                  canShift(item) ||
                  canAddPausedDuration(item)) &&
                !item.isArchived
              "
            ></v-divider>

            <v-list-item
              @click="sendForApproval(item)"
              v-if="canSendForApproval(item) && !item.isArchived"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="orange--text">far fa-gavel</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="orange--text"> Send for Approval </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="sendForUpdate(item)"
              v-if="canSendForUpdate(item) && !item.isArchived"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="orange--text">far fa-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="orange--text text--darken-1">
                  Send Back for Updates
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="approve(item)" v-if="canApprove(item) && !item.isArchived">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="green--text">far fa-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="green--text"> Approve Contract </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="unapprove(item)" v-if="canUnapprove(item) && !item.isArchived">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="warning--text">far fa-undo</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="warning--text"> Unapprove Contract </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="lock(item)" v-if="canLock(item) && !item.isArchived">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="deep-orange--text">far fa-lock-keyhole</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="deep-orange--text"> Lock Contract </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="unlock(item)" v-if="canUnlock(item) && !item.isArchived">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="deep-orange--text">far fa-lock-keyhole-open</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="deep-orange--text"> Unlock Contract </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item
                @click="openUpdateContract(item)"
                v-if="canUpdate(item) && !item.isArchived"
              >
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small class="secondary--text">far fa-pen</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Update Contract</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

            <v-list-item
              @click="cloneContract(item)"
              v-if="
                $has(perms.ProjectContracts.Create) &&
                !item.isArchived &&
                item.type == enums.ContractType.Proposal.value
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text text--darken-1">
                  Clone
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="shiftContract(item)" v-if="canShift(item) && !item.isArchived">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-timer</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text"> Shift Contract </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="addPausedDuration(item)"
              v-if="canAddPausedDuration(item) && !item.isArchived"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-circle-pause</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text"> Add Paused Duration </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              class="my-2"
              v-if="canArchive(item) || canUnarchive(item) || canDelete(item)"
            ></v-divider>

            <v-list-item @click="archive(item)" v-if="canArchive(item)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="brown--text">far fa-archive</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="brown--text"> Archive </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="unarchive(item)" v-if="canUnarchive(item)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="brown--text">far fa-inbox-out</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="brown--text"> Unarchive </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="del(item)" v-if="canDelete(item)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="red--text">far fa-trash-alt</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip top nudge-top="-10px" z-index="9999999" max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-inline-flex ml-1">
              <v-btn
                icon
                elevation="0"
                class="secondary--text"
                @click.stop="openUpdateContract(item)"
              >
                <i class="fad fa-memo-circle-info" style="font-size: 15px"></i>
              </v-btn>
            </div>
          </template>
          <span>Contract Info</span>
        </v-tooltip>
        <v-tooltip
          v-if="canViewDocuments"
          top
          nudge-top="-10px"
          z-index="9999999"
          max-width="400px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              elevation="0"
              class="m1-1"
              @click.stop="openFileManager(item)"
            >
              <!-- <i class="fad fa-cabinet-filing secondary--text" style="font-size: 15px"></i> -->
              <i class="fad fa-folder-open secondary--text" style="font-size: 15px"></i>
            </v-btn>
          </template>
          <span>Contract Documents</span>
        </v-tooltip>
        <v-tooltip
          top
          nudge-top="-10px"
          z-index="9999999"
          max-width="400px"
          v-if="$has(perms.ProjectContracts.ConstructionPhasesView)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              elevation="0"
              class="m1-1"
              @click.stop="openConstructionPhases(item)"
            >
              <!-- <i class="fad fa-trowel-bricks secondary--text" style="font-size: 15px"></i> -->
              <i class="fad fa-timeline-arrow secondary--text" style="font-size: 15px"></i>
            </v-btn>
          </template>
          <span>Construction Phases</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template #loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <v-dialog
      v-model="modals.approveContract.active"
      max-width="600px"
      persistent
      style="z-index: 99999999"
    >
      <v-card v-if="modals.approveContract.data.contract">
        <v-card-title class="font-weight-bold" small>
          <span
            ><i class="fad fa-check-square mr-2"></i>Approve Contract:
            <b>{{ modals.approveContract.data.contract.name }}</b></span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.approveContract.valid" ref="approveContractForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.approveContract.data.approveNote"
                    title="Approval Notes"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="approveContractDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="green"
              dark
              :disabled="!modals.approveContract.valid || modals.approveContract.loading"
              :loading="modals.approveContract.loading"
              @click="approveContractConfirmed()"
            >
              <i class="fal fa-check mr-2"></i>
              Approve Contract
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Archive Contract -->
    <v-dialog
      v-model="modals.archiveContract.active"
      max-width="600px"
      persistent
      style="z-index: 99999999"
    >
      <v-card v-if="modals.archiveContract.data.contract">
        <v-card-title class="font-weight-bold" small>
          <span>
            <i class="fad fa-check-square mr-2"></i
            >{{ modals.archiveContract.isArchive ? "Archive Contract:" : "Unarchive Contract:" }}
            <b>{{ modals.archiveContract.data.contract.name }}</b></span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.archiveContract.valid" ref="archiveContractForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.archiveContract.data.note"
                    :title="getArchiveNote"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="archiveContractDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="brown"
              dark
              :disabled="!modals.archiveContract.valid || modals.archiveContract.loading"
              :loading="modals.archiveContract.loading"
              @click="archiveContractConfirmed()"
            >
              <i v-if="modals.archiveContract.isArchive" class="fad fa-archive mr-2"></i>
              <i v-else class="fad fa-inbox-out mr-2"></i>
              {{ modals.archiveContract.isArchive ? "Archive Contract" : "Unarchive Contract" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <clone-contract ref="cloneContract" @save="onCloneContractSuccess"></clone-contract>

    <contract-default-labor-rates
      @save="onDefaultRatesSave"
      ref="contractDefaultLaborRates"
    ></contract-default-labor-rates>

    <contract-users-labor-rates
      @save="onUserRatesSave"
      ref="contractUsersLaborRates"
    ></contract-users-labor-rates>

    <shift-contract ref="ShiftContract" @save="onShiftContractSaved"></shift-contract>

    <add-paused-duration ref="addPausedDurationPhase" @save="onAddPausedDurationSuccess">
    </add-paused-duration>

    <contract-activity-log ref="contractActivityLog"></contract-activity-log>

    <contract-file-manager ref="fileManager"></contract-file-manager>

    <edit-contract-details
      @save="onContractDetailsSaved"
      ref="editContractDetails"
    ></edit-contract-details>

    <contract-construction-phases
      @change="onContractPhaseChanged"
      ref="editConstructionPhases"
    ></contract-construction-phases>
  </div>
</template>

<script>
import ExportExcel from "./ExportExcel.vue";
import ExportPdf from "./ExportPdf.vue";
import contractsService from "../services/contracts-service";
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import ContractStatus from "./ContractStatus.vue";
import ContractOrigin from "./ContractOrigin.vue";
import ContractType from "./ContractType.vue";
import ContractDefaultLaborRates from "./ContractDefaultLaborRates.vue";
import ContractUsersLaborRates from "./ContractUsersLaborRates.vue";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";
import ShiftContract from "./ShiftContract.vue";
import AddPausedDuration from "./AddPausedDuration.vue";
import ContractActivityLog from "./activityLogs/ContractActivityLog.vue";
import CloneContract from "./CloneContract.vue";
import ProjectContractsHeader from "../config/tables/projectContracts.header";
import ManageContractsHeader from "../config/tables/Contracts.header";
import ContractFileManager from "./ContractFileManager.vue";
import EditContractDetails from "./EditContractDetails.vue";
import ContractConstructionPhases from "./ContractConstructionPhases.vue";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  ContractTypeFilterSettings,
  ContractOriginFilterSettings,
  ContractStatusFilterSettings,
  ClientFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";
import ClientCard from "../../Companies/Clients/components/ClientCard.vue";

export default {
  name: "contracts-manager",
  components: {
    ContractStatus,
    ContractType,
    ContractOrigin,
    // ConstructionPhaseType,
    ContractDefaultLaborRates,
    ContractUsersLaborRates,
    // HeaderWithTooltip,
    RichTextEditor,
    ShiftContract,
    AddPausedDuration,
    ContractActivityLog,
    CloneContract,
    ContractFileManager,
    EditContractDetails,
    ContractConstructionPhases,
    ExportExcel,
    ExportPdf,
    FilterManager,
    ClientCard,
  },
  data() {
    return {
      perms: perms,
      enums: enums,
      DateRangeTypes: enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE,
      contractStatus: this.$options.filters.EnumToList(enums.CONTRACT_STATUS, true),
      openContractInEditMode: false,
      selectedFilters: [],
      contracts: [],
      headers: [],
      defaultHeaders: [],
      selected: {},
      total: 0,
      loadingContracts: false,
      projectContractsTimerId: null,
      mainSearchInFocus: false,
      contractToExpand: null,
      expandedContractsPanels: [],
      loadingStates: {
        table: false,
      },
      options: {
        includeArchived: false,
        hasZeroRates: false,
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        approvedByIds: [],
        createdByIds: [],
        updatedByIds: [],
        leadACIds: [],
        clientIds: [],
        type: [],
        origin: [],
        status: [],
      },
      actions: {
        sortBy: -1,
        sortDesc: false,
      },
      modals: {
        approveContract: {
          active: false,
          valid: false,
          loading: false,
          data: {
            id: null,
            contract: null,
            approveNote: null,
          },
        },
        archiveContract: {
          active: false,
          valid: false,
          loading: false,
          isArchive: true,
          data: {
            id: null,
            contract: null,
            note: null,
          },
        },
      },
    };
  },
  props: {
    managementMode: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.headers = this.managementMode ? ManageContractsHeader : ProjectContractsHeader;
    this.defaultHeaders = this.headers;

    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  computed: {
    getArchiveNote() {
      return this.modals.archiveContract.isArchive ? "Archive Notes" : "Unarchive Notes:";
    },
    tableHeader() {
      var headers = this.headers.filter((h) => !h.hidden);
      if (!this.$has(perms.ProjectContracts.ViewRevenueSums)) {
        headers = headers.filter(
          (h) => h.value != "totalLaborRevenue" && h.value != "totalDirectExpensesRevenue"
        );
      }
      if (!this.$has(perms.ProjectContracts.ViewCostSums)) {
        headers = headers.filter(
          (h) => h.value != "totalLaborCost" && h.value != "totalDirectExpensesCost"
        );
      }
      return headers;
    },
    storageKey() {
      return this.managementMode ? "ManageContracts" : "ProjectContracts";
    },
    canViewDocuments() {
      return this.$has(perms.ProjectContracts.DocumentsView);
    },
  },
  mounted() {
    this.initFilterManagerSettings();
    this.checkForSingleContractRoute();
  },
  methods: {
    checkForSingleContractRoute() {
      if (this.$route.name == "view-project-contract") {
        this.$refs.editConstructionPhases.open(this.$route.params.contractId);
        return;
      }
      if (this.$route.name == "manage-contract") {
        this.$refs.editConstructionPhases.open(this.$route.params.id);
        return;
      }
    },
    viewClient(id) {
      const path = `/clients/${id}`;
      window.open(path, "_blank");
    },
    initFilterManagerSettings() {
      var approvedByFilterSettings = new UserFilterSettings();
      approvedByFilterSettings.title = "Approved By";
      approvedByFilterSettings.model = "approvedByIds";
      approvedByFilterSettings.ref = "approvedByFilter";

      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      var leadACFilterSettings = new UserFilterSettings();
      leadACFilterSettings.title = "Lead AC";
      leadACFilterSettings.model = "LeadACIds";
      leadACFilterSettings.ref = "LeadACFilter";

      var clientFilterSettings = new ClientFilterSettings();

      var contractTypeFilterSettings = new ContractTypeFilterSettings();

      var contractOriginFilterSettings = new ContractOriginFilterSettings();

      var contractStatusFilterSettings = new ContractStatusFilterSettings();

      if (this.managementMode) {
        this.selectedFilters = [
          contractTypeFilterSettings,
          contractOriginFilterSettings,
          // contractStatusFilterSettings,
          leadACFilterSettings,
          clientFilterSettings,
          approvedByFilterSettings,
          createdByFilterSettings,
          updatedByFilterSettings,
        ];
      } else {
        this.selectedFilters = [
          contractTypeFilterSettings,
          contractOriginFilterSettings,
          contractStatusFilterSettings,
          approvedByFilterSettings,
          createdByFilterSettings,
          updatedByFilterSettings,
        ];
      }
    },
    openFileManager(contract) {
      this.$refs.fileManager.openWithContract(contract);
    },
    openConstructionPhases(contract) {
      this.$log("openConstructionPhases > contract", contract);
      this.$refs.editConstructionPhases.open(contract.id);
    },
    rowClass(item) {
      if (item.isArchived) return "contract-archived";
      else if (item.status == enums.CONTRACT_STATUS.Approved.value) return "contract-approved";
      else if (item.status == enums.CONTRACT_STATUS.Pending.value) return "contract-pending";
      else if (item.status == enums.CONTRACT_STATUS.Draft.value) return "contract-draft";
      return "";
    },
    canSendForApproval(contract) {
      return (
        contract.status == enums.CONTRACT_STATUS.Draft.value &&
        !contract.isLocked &&
        this.$has(perms.ProjectContracts.SendForApproval)
      );
    },
    canSendForUpdate(contract) {
      return (
        contract.status == enums.CONTRACT_STATUS.Pending.value &&
        this.$has(perms.ProjectContracts.SendForUpdate)
      );
    },
    canApprove(contract) {
      return (
        contract.status == enums.CONTRACT_STATUS.Pending.value &&
        this.$has(perms.ProjectContracts.Approve)
      );
    },
    canUnapprove(contract) {
      return (
        contract.status == enums.CONTRACT_STATUS.Approved.value &&
        this.$has(perms.ProjectContracts.Unapprove)
      );
    },
    canLock(contract) {
      return (
        contract.status == enums.CONTRACT_STATUS.Approved.value &&
        !contract.isLocked &&
        this.$has(perms.ProjectContracts.Lock)
      );
    },
    canUnlock(contract) {
      return (
        contract.status == enums.CONTRACT_STATUS.Approved.value &&
        contract.isLocked &&
        this.$has(perms.ProjectContracts.Unlock)
      );
    },
    canUpdate(contract) {
      return !contract.isLocked && this.$has(perms.ProjectContracts.Update);
    },
    canShift(contract) {
      return (
        contract.status == enums.CONTRACT_STATUS.Draft.value &&
        !contract.isLocked &&
        this.$has(perms.ProjectContracts.Update)
      );
    },
    canAddPausedDuration(contract) {
      return (
        contract.status == enums.CONTRACT_STATUS.Draft.value &&
        !contract.isLocked &&
        this.$has(perms.ProjectContracts.Update)
      );
    },
    canArchive(contract) {
      return !contract.isArchived && this.$has(perms.ProjectContracts.Archive);
    },
    canUnarchive(contract) {
      return contract.isArchived && this.$has(perms.ProjectContracts.Unarchive);
    },
    canDelete(contract) {
      return !contract.isLocked && this.$has(perms.ProjectContracts.Delete);
    },
    displayActivityLogs(item) {
      setTimeout(() => {
        this.$refs.contractActivityLog.open(item);
      });
    },
    displayApprovedMessage(contract) {
      var approvedMsg = document
        .querySelector("#contract-approved-msg-" + contract.id)
        .cloneNode(true);
      var devElms = approvedMsg.querySelectorAll(".v-divider");
      devElms.forEach((dev) => {
        dev.className = "v-divider theme--light my-2 mx-0";
      });
      this.$dialog
        .info({
          title: `'${contract.name}' is approved!`,
          text: approvedMsg.innerHTML,
          color: "success",
        })
        .then((res) => {});
    },
    onDefaultRatesSave() {
      this.$log("onDefaultRatesSave");
      this.getProjectContracts();
    },
    onUserRatesSave() {
      this.$log("onUserRatesSave");
      this.getProjectContracts();
    },
    onAddPausedDurationSuccess() {
      this.$log("onAddPausedDurationSuccess");
      this.getProjectContracts();
    },
    openDefaultLaborRates(contract) {
      this.$refs.contractDefaultLaborRates.open(contract, false);
    },
    openUsersLaborRates(contract) {
      this.$refs.contractUsersLaborRates.open(contract, false);
    },
    add() {
      this.$refs.editContractDetails.create(this.projectId);
    },
    unlock(contract) {
      this.$dialog
        .warning({
          text: `Are you sure you want to unlock contract <b>${contract.name}</b>?`,
          title: `Unlock Contract?`,
          color: "deep-orange",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "deep-orange",
              handle: () => {
                return contractsService
                  .unlock(contract.id)
                  .then((resp) => {
                    this.getProjectContracts();
                    this.$dialog.notify.success("Contract unlocked successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error unlocking the contract!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    lock(contract) {
      this.$dialog
        .warning({
          text: `Are you sure you want to lock contract <b>${contract.name}</b>?`,
          title: `Lock Contract?`,
          color: "deep-orange",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "deep-orange",
              handle: () => {
                return contractsService
                  .lock(contract.id)
                  .then((resp) => {
                    this.getProjectContracts();
                    this.$dialog.notify.success("Contract locked successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error locking the contract!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    unapprove(contract) {
      this.$dialog
        .warning({
          text: `Are you sure you want to UNAPPROVE contract <b>${contract.name}</b>?`,
          title: `UNAPPROVE Contract?`,
          color: "warning",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "warning",
              handle: () => {
                return contractsService
                  .unapprove(contract.id)
                  .then((resp) => {
                    this.getProjectContracts();
                    this.$dialog.notify.success("Contract unapproved successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error unapproving the contract!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    openProject(project) {
      const path = `/projects/${project.id}`;
      window.open(path, "_blank").focus();
      //if (this.$route.path !== path) this.$router.push(path);
    },
    archiveContractDiscard() {
      this.modals.archiveContract.active = false;
      this.modals.archiveContract.loading = false;
      this.modals.archiveContract.data = {
        id: null,
        contract: null,
        note: null,
      };
    },
    archiveContractConfirmed() {
      this.modals.archiveContract.loading = true;
      contractsService
        .archiveUnArchive(
          this.modals.archiveContract.data.id,
          {
            note: this.modals.archiveContract.data.note,
          },
          this.modals.archiveContract.isArchive
        )
        .then((resp) => {
          this.getProjectContracts();
          this.$dialog.notify.success(
            `Contract <b>'${this.modals.archiveContract.data.contract.name}'</b> is ${
              this.modals.archiveContract.isArchive ? "archived" : "unarchived"
            } successfully!`,
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.archiveContractDiscard();
        })
        .catch((resp) => {
          this.modals.archiveContract.loading = false;
          this.$dialog.notify.error("Error archiving the contract!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    archive(contract) {
      this.modals.archiveContract.loading = false;
      this.modals.archiveContract.data.id = contract.id;
      this.modals.archiveContract.data.contract = contract;
      this.modals.archiveContract.active = true;
      this.modals.archiveContract.isArchive = true;
    },
    unarchive(contract) {
      this.modals.archiveContract.loading = false;
      this.modals.archiveContract.data.id = contract.id;
      this.modals.archiveContract.data.contract = contract;
      this.modals.archiveContract.active = true;
      this.modals.archiveContract.isArchive = false;
    },
    cloneContract(contract) {
      this.$refs.cloneContract.open(contract);
    },
    onCloneContractSuccess(id) {
      this.$log("onCloneContractSuccess", id);
      this.getProjectContracts();
    },
    sendForApproval(contract) {
      this.$dialog
        .warning({
          text: `Are you sure you want to send contract "<b>${contract.name}</b>" for approval?`,
          title: `Send for Approval?`,
          color: "warning",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "warning",
              handle: () => {
                return contractsService
                  .sendForApproval(contract.id)
                  .then((resp) => {
                    this.getProjectContracts();
                    this.$dialog.notify.success("Contract sent for approval successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error sending for approval!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    sendForUpdate(contract) {
      this.$dialog
        .warning({
          text: `Are you sure you want to send contract "<b>${contract.name}</b>" back for update?`,
          title: `Send Back For Updates?`,
          color: "warning",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "warning",
              handle: () => {
                return contractsService
                  .sendForUpdate(contract.id)
                  .then((resp) => {
                    this.getProjectContracts();
                    this.$dialog.notify.success("Contract sent back for updates successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error sending contract back for updates!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    approve(contract) {
      this.modals.approveContract.loading = false;
      this.modals.approveContract.data.id = contract.id;
      this.modals.approveContract.data.contract = contract;
      this.modals.approveContract.data.approveNote = contract.approveNote;
      this.modals.approveContract.active = true;
    },
    approveContractConfirmed() {
      this.modals.approveContract.loading = true;
      contractsService
        .approve(this.modals.approveContract.data.id, {
          id: this.modals.approveContract.data.id,
          note: this.modals.approveContract.data.approveNote,
        })
        .then((resp) => {
          this.getProjectContracts();
          this.$dialog.notify.success(
            `Contract <b>'${this.modals.approveContract.data.contract.name}'</b> approved successfully!`,
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.approveContractDiscard();
        })
        .catch((resp) => {
          this.modals.approveContract.loading = false;
          this.$dialog.notify.error("Error approving the contract!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    approveContractDiscard() {
      this.modals.approveContract.active = false;
      this.modals.approveContract.loading = false;
      this.modals.approveContract.data = {
        id: null,
        contract: null,
        approveNote: null,
      };
    },
    openUpdateContract(contract) {
      this.$refs.editContractDetails.open(contract.id);
    },
    onContractDetailsSaved() {
      this.$log("onContractDetailsSaved");
      this.getProjectContracts();
    },
    onContractPhaseChanged() {
      this.$log("onContractPhaseChanged");
      this.getProjectContracts();
    },
    updateContractConfirmed() {
      this.modals.updateContract.loading = true;
      let toSend = {
        projectId: this.projectId,
        id: this.modals.updateContract.data.id,
        name: this.modals.updateContract.data.name,
        status: this.modals.updateContract.data.status,
      };
      contractsService
        .update(toSend)
        .then((resp) => {
          this.$log("updateContract", resp.data);
          this.modals.updateContract.loading = false;
          this.updateContractDiscard();
          this.getProjectContracts();
          if (toSend.id == null) {
            this.contractToExpand = resp.data.id;
          }
        })
        .catch((err) => {
          this.$log("updateContract", err);
          this.modals.updateContract.loading = false;
          this.$handleError(err);
        });
    },
    onContractSaved(contractData, isNewContract) {
      this.$log("onContractSaved", contractData, isNewContract);
      this.$log("this.contracts", this.cloneDeep(this.contracts));
      this.getProjectContracts();
      return;
      if (isNewContract) {
        // this.contracts.push(this.cloneDeep(contractData));
        this.addToArr(this.contracts, contractData, true);
        this.total = this.contracts.length;
        // this.options.search = contractData.id;
      } else {
        this.updateArr(this.contracts, contractData);
      }
      this.$log("this.contracts", this.cloneDeep(this.contracts));
    },
    shiftContract(contract) {
      this.$refs.ShiftContract.openShiftContractDialog(contract);
    },
    addPausedDuration(contract) {
      this.$refs.addPausedDurationPhase.open(contract);
    },
    onShiftContractSaved(contractData) {
      this.$log("onShiftContract");
      this.getProjectContracts();
    },
    getProjectContractsDebounced() {
      this.$log(".................................. getProjectContractsDebounced()");
      if (this.projectContractsTimerId == null) {
        this.projectContractsTimerId = -1;
        this.getProjectContracts();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectContractsTimerId);

      // delay new call 400ms
      this.projectContractsTimerId = setTimeout(() => {
        this.getProjectContracts();
      }, 400);
    },
    getProjectContracts() {
      this.$log("getProjectContracts()");
      this.loadingContracts = true;
      let toSend = this.cloneDeep(this.options);
      if (this.managementMode) {
        contractsService
          .queryManagement(this.$clean(toSend, true))
          .then((resp) => {
            this.contracts = resp.data.items || [];
            this.$log("this.contracts", this.contracts);
            this.total = resp.data.total;
            this.loadingContracts = false;
            this.$log("contractsService query >>>>>> contracts", this.cloneDeep(this.contracts));
            this.total = resp.data.total;
          })
          .catch((err) => {
            this.loadingContracts = false;
            this.$handleError(err);
          });
      } else {
        contractsService
          .query(this.projectId, this.$clean(toSend, true))
          .then((resp) => {
            this.$log("contractsService query >>>>>> contracts", resp.data);
            this.contracts = resp.data.items || [];
            this.total = resp.data.total;
            this.loadingContracts = false;
          })
          .catch((err) => {
            this.loadingContracts = false;
            this.$handleError(err);
          });
      }
    },
    del(contract) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete contract <b>${contract.name}</b>
                  and all of its construction phases and financial data?`,
          title: `Delete Contract?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return contractsService
                  .delete(contract.id)
                  .then((resp) => {
                    const index = this.contracts.indexOf(
                      this.contracts.filter((e) => e.id == contract.id)[0]
                    );
                    if (index > -1) {
                      this.contracts.splice(index, 1);
                    }
                    this.$dialog.notify.success("Contract deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the contract!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    addToContracts(contract) {
      this.addToArr(this.contracts, contract, true);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$log(">>>>>>>>>>>> watch options", this.cloneDeep(this.options));
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectContractsDebounced();
      },
      deep: true,
    },
    projectId: {
      handler(newRoute, oldRoute) {
        this.$log(">>>>>>>>>>>> watch projectId");
        this.getProjectContracts();
      },
    },
  },
};
</script>

<style lang="scss">
.contractEp {
  .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }

  &.contract-status-draft {
    border-left: 3px solid #607d8b;

    // .v-expansion-panel-header {
    //   background: rgba(#607d8b, 0.06);
    // }
  }
  &.contract-status-pending {
    border-left: 3px solid #ff9800;

    .v-expansion-panel-header {
      background: rgba(#ff9800, 0.06);
    }
  }
  &.contract-status-approved {
    border-left: 3px solid #4caf50;

    .v-expansion-panel-header {
      background: rgba(#4caf50, 0.06);
    }
  }
  &.contract-archived {
    border-left: 3px solid #795548;

    .v-expansion-panel-header {
      background: rgba(#795548, 0.12);
    }
  }
}
tr.contract-archived {
  background: rgba(#795548, 0.12);

  & > td:first-child {
    border-left: 3px solid #795548;
  }
}
tr.contract-approved {
  background: rgba(#4caf50, 0.06);

  & > td:first-child {
    border-left: 3px solid #4caf50;
  }
}
tr.contract-pending {
  background: rgba(#ff9800, 0.06);

  & > td:first-child {
    border-left: 3px solid #ff9800;
  }
}
tr.contract-draft {
  // background: rgba(#ff9800, 0.06);

  & > td:first-child {
    // border-left: 3px solid #607d8b;
    border-left: 3px solid rgba(#607d8b, 0.64);
  }
}

.contract-totals {
  background: rgba(#607d8b, 0.12);
  font-weight: 600;
  font-size: 18px;

  td {
    border-top: thin dashed rgba(#607d8b, 0.4);
  }
}

.no-contracts-available {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 350px;
    // opacity: 0.87;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
</style>
