var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('page-title',{attrs:{"title":"Jira Projects","show-subtitle":"","subtitle":"Browse DNA Jira Projects!","badge":_vm.total}}),_c('v-row',{staticClass:"mb-1",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex align-center pt-0",attrs:{"cols":"12","md":"auto"}}),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"}},[_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
            'max-width':
              _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
                ? '200px'
                : '110px',
          }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.isJiraProjectsLoading},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('refresh',{attrs:{"loading":_vm.isJiraProjectsLoading},on:{"refresh":function($event){return _vm.getJiraProjects()}}})],1)])],1),_c('v-data-table',{staticClass:"elevation-2 jira-projects-table table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.jiraProjectsFiltered,"options":_vm.options,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.isJiraProjectsLoading,"footer-props":{
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{staticClass:"py-2",staticStyle:{"flex":"none"},attrs:{"align-content":"center","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center mr-2",attrs:{"sm":"auto"}},[(item.avatarUrls['48x48'] != null && item.avatarUrls['48x48'] != '')?_c('v-img',{staticClass:"rounded",attrs:{"src":_vm.wrapAvatarUrl(item.avatarUrls['48x48']),"height":"40","width":"40","position":"center center","contain":""}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center col-auto flex-column"},[_c('h4',[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.projectCategory",fn:function(ref){
    var item = ref.item;
return [(item.projectCategory != null)?_c('v-chip',{staticClass:"fs-12px font-weight-medium my-1 py-0 blue-grey--text text--darken-2",attrs:{"small":"","color":"blue-grey lighten-5"}},[_vm._v(" "+_vm._s(item.projectCategory.name)+" ")]):_vm._e()]}},{key:"item.isPrivate",fn:function(ref){
    var item = ref.item;
return [(item.isPrivate)?_c('v-chip',{staticClass:"px-2",staticStyle:{"min-height":"20px !important","max-height":"20px !important","height":"20px !important"},attrs:{"small":"","color":"red","dark":""}},[_vm._v(" Private ")]):_c('v-chip',{staticClass:"px-2",staticStyle:{"min-height":"20px !important","max-height":"20px !important","height":"20px !important"},attrs:{"small":"","color":"blue accent-3","dark":""}},[_vm._v(" Public ")])]}},{key:"item.key",fn:function(ref){
    var item = ref.item;
return [(item.key != null && item.key != '')?_c('a',{staticClass:"jira-external-link",attrs:{"target":"_blank","href":'https://dangeloconsultants.atlassian.net/browse/' + item.key},on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"icon-wrpr mr-1"},[_c('i',{staticClass:"fab fa-jira org-icon"}),_c('i',{staticClass:"far fa-external-link alt-icon"})]),_c('span',[_vm._v(_vm._s(item.key))])]):_c('span',{staticClass:"text--disabled"},[_c('i',{staticClass:"fad fa-unlink"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)}),_c('view-jira-project',{ref:"viewJiraProject"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }