var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('page-title',{attrs:{"title":"Manage Direct Expenses","badge":this.total}})],1),_c('v-row',{staticClass:"mt-0 mb-1",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"d-flex align-start",staticStyle:{"gap":"0.5rem"},attrs:{"sm":"12","md":"3","cols":"12"}},[_c('date-range-month',{staticClass:"mr-1",attrs:{"actions-menu":"","start-date":_vm.options.startDate,"end-date":_vm.options.endDate},on:{"update:startDate":function($event){return _vm.$set(_vm.options, "startDate", $event)},"update:start-date":function($event){return _vm.$set(_vm.options, "startDate", $event)},"update:endDate":function($event){return _vm.$set(_vm.options, "endDate", $event)},"update:end-date":function($event){return _vm.$set(_vm.options, "endDate", $event)}}})],1),_c('v-col',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"},attrs:{"md":"9","sm":"12","cols":"12"}},[_c('filter-manager',{ref:"filterManager",attrs:{"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}}),_c('direct-expenses-export-excel',{attrs:{"options":_vm.options}}),_c('v-sheet',{staticClass:"mx-1",attrs:{"height":"28","width":"1","color":"blue-grey lighten-4"}}),_c('columns-visibility-control',{attrs:{"defaults":_vm.defaultHeaders,"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","dense":"","solo":"","hide-details":"","clearable":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getDataDebounced()}}})],1)],1),_c('v-data-table',{staticClass:"elevation-2 direct-expenses-table table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loadingStates.table,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.contract.project.client.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('client-card',{attrs:{"client":item.contract.project.client,"hide-name":"","square":""}})],1)]}},{key:"item.contract.project.fullName",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"project-link",attrs:{"href":("/projects/" + (item.contract.project.id)),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(item.contract.project.fullName)+" ")])]}},{key:"item.contract.project.leadAC",fn:function(ref){
    var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.contract.project.leadAC,"icon":"","showNoUser":""}})]}},{key:"item.contract.name",fn:function(ref){
    var item = ref.item;
return [_c('h4',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('code',{staticClass:"mono-font secondary--text fs-12px",class:{
            'brown lighten-4 brown--text': item.contract.isArchived,
            'blue-grey lighten-5':
              !item.contract.isArchived &&
              item.contract.status == _vm.enums.CONTRACT_STATUS.Draft.value,
            'orange lighten-4':
              !item.contract.isArchived &&
              item.contract.status == _vm.enums.CONTRACT_STATUS.Pending.value,
            'green lighten-4':
              !item.contract.isArchived &&
              item.contract.status == _vm.enums.CONTRACT_STATUS.Approved.value,
          },staticStyle:{"flex":"none"}},[_vm._v(" #"+_vm._s(item.contract.id)+" ")]),_vm._v(" "+_vm._s(item.contract.name)+" ")])]}},{key:"item.contract.status",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex align-center pl-1"},[(item.contract.isLocked)?_c('i',{staticClass:"fas fa-lock-keyhole secondary--text text--darken-1 fs-16px ml-1 mr-2"}):(
            !item.contract.isLocked &&
            item.contract.status == _vm.enums.CONTRACT_STATUS.Approved.value
          )?_c('i',{staticClass:"fas fa-lock-keyhole-open deep-orange--text text--darken-1 fs-16px ml-1 mr-2"}):(
            !item.contract.isLocked && item.contract.status == _vm.enums.CONTRACT_STATUS.Draft.value
          )?_c('i',{staticClass:"fas fa-lock-keyhole-open secondary--text opacity-24 fs-16px ml-1 mr-2"}):_vm._e(),(item.contract.isArchived)?_c('v-chip',{staticClass:"px-2 ml-1",attrs:{"small":"","label":"","color":'brown',"text-color":'white'}},[_c('i',{staticClass:"fad fa-archive mr-2"}),_c('span',{staticClass:"font-weight-medium",staticStyle:{"line-height":"0"}},[_vm._v(" Archived ")])]):_c('contract-status',{staticClass:"ml-2",attrs:{"small":"","status":item.contract.status}}),(item.contract.status == _vm.enums.CONTRACT_STATUS.Approved.value)?_c('v-tooltip',{attrs:{"top":"","z-index":"9999999","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 px-1",attrs:{"text":"","min-width":"20px","height":"24px","small":"","color":"green darken-4"},on:{"click":function($event){$event.stopPropagation();return _vm.displayApprovedMessage(item.contract)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fad fa-circle-info fs-16px"})])]}}],null,true)},[_c('div',{staticClass:"py-1",staticStyle:{"width":"100%"},attrs:{"id":'contract-approved-msg-' + item.contract.id}},[_c('div',{staticClass:"font-weight-bold green--text accent-3"},[_c('i',{staticClass:"fas fa-circle-check mr-1",staticStyle:{"width":"20px"}}),_vm._v("Contract Approved ")]),_c('v-divider',{staticClass:"my-2 mx-0",staticStyle:{"width":"100%"},attrs:{"dark":""}}),_c('div',{staticClass:"d-flex align-center"},[_c('i',{staticClass:"fad fa-user mr-1",staticStyle:{"width":"20px"}}),_vm._v("By: "),_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v(" "+_vm._s(item.contract.approvedBy.firstName)+" "+_vm._s(item.contract.approvedBy.lastName)+" ")])]),_c('div',{staticClass:"d-flex align-center"},[_c('i',{staticClass:"fad fa-calendar-day mr-1",staticStyle:{"width":"20px"}}),_vm._v("On: "),_c('span',{staticClass:"font-weight-bold ml-1"},[_c('dater',{attrs:{"date":item.contract.approvedOn}})],1)]),(
                item.contract.approveNote != null &&
                item.contract.approveNote.trim() != '<p></p>' &&
                item.contract.approveNote.trim() != ''
              )?_c('div',{staticClass:"d-flex align-start flex-column"},[_c('v-divider',{staticClass:"my-2 mx-0",staticStyle:{"width":"100%"},attrs:{"dark":""}}),_c('b',{staticClass:"d-inline-flex align-center"},[_c('i',{staticClass:"fad fa-note mr-1",staticStyle:{"width":"20px"}}),_vm._v("Notes: ")]),_c('div',{staticClass:"tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 mt-2",staticStyle:{"line-break":"anywhere"},domProps:{"innerHTML":_vm._s(item.contract.approveNote)}})],1):_vm._e()],1)]):_vm._e()],1)]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('construction-phase-type',{attrs:{"type":item.type}}),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.startDate",fn:function(ref){
              var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.startDate.substr(0, 7)))])]}},{key:"item.totalDirectExpensesCost",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"fs-12px",class:item.totalDirectExpensesCost > 0
            ? 'info--text'
            : item.totalDirectExpensesCost < 0
            ? 'pink--text'
            : 'grey--text'},[_c('span',{staticClass:"mono-font fs-13px"},[_vm._v(_vm._s(_vm._f("usdFormat")(item.totalDirectExpensesCost)))])])]}},{key:"item.totalDirectExpensesRevenue",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"fs-12px",class:item.totalDirectExpensesRevenue > 0
            ? 'info--text'
            : item.totalDirectExpensesRevenue < 0
            ? 'pink--text'
            : 'grey--text'},[_c('span',{staticClass:"mono-font fs-13px"},[_vm._v(_vm._s(_vm._f("usdFormat")(item.totalDirectExpensesRevenue)))])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"dense":"","offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-ellipsis-v",staticStyle:{"font-size":"16px"}})])]}}],null,true)},[_c('v-list',{staticClass:"more-options-menu"},[(_vm.$has(_vm.perms.ProjectContracts.View))?_c('v-list-item',{on:{"click":function($event){return _vm.review(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{staticClass:"secondary--text",attrs:{"small":""}},[_vm._v("fas fa-eye")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"secondary--text"},[_vm._v(" View Contract ")])],1)],1):_vm._e()],1)],1)]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }