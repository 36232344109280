<template>
  <v-container fluid class="pa-6 pt-2" style="background: #eceff1">
    <v-alert
      key="template-not-changed"
      prominent
      elevation="2"
      class="mt-2"
      type="info"
      dense
      v-if="!isSpaceTemplateChanged && !readonly && isSpaceTemplateAdded && !selected.isTemplate"
    >
      <v-row align="center">
        <v-col class="grow">
          Equipment editing is disabled because the space is linked with template!
        </v-col>
        <v-col class="shrink">
          <v-btn color="secondary" class="mr-2" @click="openTemplateInNewTab()">
            <i class="fal fa-arrow-up-right-from-square mr-2"></i> View Template
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert
      key="template-did-change"
      prominent
      elevation="2"
      class="mt-2"
      type="info"
      dense
      v-else-if="isSpaceTemplateChanged && !readonly && !selected.isTemplate"
    >
      <v-row align="center">
        <v-col class="grow">
          Linked space template has changed! Please click <b>Save</b> to load the updated equipment!
        </v-col>
        <v-col class="shrink" v-if="!equipmentOnlyMode">
          <v-btn
            class="mr-2"
            :disabled="!validSpace || isLoading || readonly"
            @click="updateConfirmed()"
            :loading="isLoading"
          >
            <i class="fal fa-check mr-2"></i> Save
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-row class="my-1" justify="space-between">
      <v-col class="text-right d-flex flex-row justify-start">
        <slot name="equipment" v-if="equipmentOnlyMode"></slot>
        <v-btn
          v-if="!readonly && !equipmentOnlyMode"
          color="info"
          ref="addAreaGroupBtn"
          @click="openCreateAreaGroupDialog"
          :disabled="isSpaceTemplateChanged || isSpaceTemplateAdded"
        >
          <!-- <i class="fal fa-plus mr-2"></i>Add Equipment -->
          <i class="fal fa-plus mr-2"></i>Create Area Group
        </v-btn>

        <v-tooltip
          v-if="!equipmentOnlyMode"
          top
          nudge-top="-4px"
          max-width="265px"
          z-index="999999999999999"
          class="d-inline"
          color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn class="ml-2" color="indigo" dark @click="spaceInstaTally">
                <span><i class="fas fa-bolt mr-2"></i></span>Insta Tally
              </v-btn></span
            >
          </template>
          <span style="font-size: 14px"> Get Instant Totals for this Space! </span>
        </v-tooltip>

        <v-btn
          v-if="!readonly && !equipmentOnlyMode"
          color="secondary"
          class="ml-2"
          @click="openCopyTemplateAreaGroupDialog"
          :disabled="isSpaceTemplateChanged || isSpaceTemplateAdded || readonly"
        >
          <i class="fad fa-copy mr-2"></i>Copy Area Groups From a Template
        </v-btn>
      </v-col>
      <v-col class="text-right d-flex flex-row justify-end">
        <v-btn
          @click="expandAll"
          class="mr-1"
          text
          color="secondary"
          style="text-transform: none !important"
        >
          <i class="fal fa-chevron-double-down mr-2"></i> Expand All
        </v-btn>
        <v-btn
          @click="collapseAll"
          class="mr-1"
          text
          color="secondary"
          style="text-transform: none !important"
        >
          <i class="fal fa-chevron-double-up mr-2"></i> Collapse All
        </v-btn>
        <v-text-field
          v-if="!equipmentOnlyMode"
          v-model="searchEquipment"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          style="max-width: 200px"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-expansion-panels
      class="space-area-groups"
      key="areaGroupsEP"
      ref="areaGroupsEP"
      v-model="expandedAreaGroupPanels"
      multiple
    >
      <v-expansion-panel v-for="(areaGroup, i) in selected.areaGroups" :key="i">
        <v-expansion-panel-header class="px-4 py-0" :ripple="true">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="d-flex align-center">
              <span class="font-weight-bold">
                <i
                  class="fad mr-2"
                  :class="
                    areaGroup.isRack ? 'fa-container-storage fa-rotate-90 teal--text' : 'fa-shapes'
                  "
                  style="transition: 0.15s ease-out all"
                ></i>
                <span :class="areaGroup.isRack ? 'teal--text' : ''">{{ areaGroup.name }}</span>
                <code class="text-caption secondary--text ml-2" v-if="areaGroup.id != null">
                  #{{ areaGroup.id }}
                </code>
                <code class="text-caption ml-2 secondary white--text" v-else> Not Saved Yet </code>
              </span>
              <span v-if="!equipmentOnlyMode">
                <v-btn
                  v-if="areaGroup.isRack"
                  class="ml-2 fs-12px"
                  color="teal"
                  small
                  dark
                  @click.stop="openRackVisualizer(areaGroup)"
                >
                  <i class="fad fa-container-storage fa-rotate-90 mr-2"></i>
                  Rack Visualizer
                </v-btn>
                <v-btn
                  style="padding: 6px 12px !important"
                  class="ml-2 fs-12px"
                  v-if="!readonly"
                  color="info"
                  @click.stop="openEquipmentsPanel(areaGroup)"
                  :disabled="isSpaceTemplateChanged || isSpaceTemplateAdded"
                >
                  <i class="far fa-plus mr-2"></i> Add Equipment
                </v-btn>
                <v-tooltip
                  top
                  nudge-top="-4px"
                  max-width="265px"
                  z-index="99999"
                  class="d-inline"
                  color="secondary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2 fs-12px"
                      color="indigo"
                      dark
                      small
                      @click.stop="areaGroupInstaTally(areaGroup)"
                    >
                      <span><i class="fas fa-bolt mr-2"></i></span>Insta Tally
                    </v-btn>
                  </template>
                  <span style="font-size: 14px"> Get Instant Totals for this Area Group! </span>
                </v-tooltip>
              </span>
            </v-col>
            <v-col cols="auto" v-if="!equipmentOnlyMode">
              <v-menu dense offset-y left bottom :z-index="200" v-if="!readonly">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon elevation="0" v-bind="attrs" v-on="on" class="mx-2">
                    <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                  </v-btn>
                </template>
                <v-list class="more-options-menu">
                  <v-list-item
                    @click="openRenameAreaGroupDialog(areaGroup)"
                    :disabled="readonly || isSpaceTemplateChanged || isSpaceTemplateAdded"
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>far fa-pen</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="">Rename Area Group</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="openCloneAreaGroupDialog(areaGroup)"
                    :disabled="readonly || isSpaceTemplateChanged || isSpaceTemplateAdded"
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>far fa-clone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="">Clone Area Group</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="!areaGroup.isRack"
                    @click="convertToRackAG(areaGroup)"
                    :disabled="readonly || isSpaceTemplateChanged || isSpaceTemplateAdded"
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small class="teal--text">
                        far fa-container-storage fa-rotate-90
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="teal--text">
                        Convert to a Rack Area Group
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="areaGroup.isRack"
                    @click="convertToNormalAG(areaGroup)"
                    :disabled="readonly || isSpaceTemplateChanged || isSpaceTemplateAdded"
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small class="teal--text"> far fa-shapes </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="teal--text">
                        Convert to a Normal Area Group
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="delAreaGroup(areaGroup)"
                    :disabled="readonly || isSpaceTemplateChanged || isSpaceTemplateAdded"
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small class="error--text">far fa-ban</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="error--text"> Delete Area Group </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-scroll-y-transition mode="out-in" v-if="!equipmentOnlyMode">
            <div
              style="height: 32px"
              class="d-flex align-center justify-start pt-3"
              :key="'bulk_update_' + i"
              v-if="
                !isSpaceTemplateChanged &&
                !isSpaceTemplateAdded &&
                selectedEquipment[areaGroup.id || areaGroup.tempId] != null &&
                selectedEquipment[areaGroup.id || areaGroup.tempId].length > 0
              "
            >
              <div
                class="d-flex align-center justify-center indigo lighten-5"
                style="height: 32px; border: 1px solid #b39ddb !important; border-radius: 0.5rem"
              >
                <div class="d-flex position-relative">
                  <span
                    style="position: absolute; top: -16px; left: 5px; z-index: 1"
                    class="fs-10px font-weight-bold opacity-54 text-uppercase"
                    >Provider</span
                  >
                  <equipment-provider-selector-static
                    v-model="bulk.providerId"
                    :providers-list="providersList"
                    show-menu-icon
                    clearable
                    empty-text=" N/C"
                    empty-tooltip="No Change"
                  ></equipment-provider-selector-static>
                </div>
                <div class="d-flex position-relative">
                  <span
                    style="position: absolute; top: -16px; left: 5px; z-index: 1"
                    class="fs-10px font-weight-bold opacity-54 text-uppercase"
                    >Installer</span
                  >
                  <equipment-installer-selector-static
                    v-model="bulk.installerId"
                    :installers-list="installersList"
                    show-menu-icon
                    clearable
                    empty-text=" N/C"
                    empty-tooltip="No Change"
                  ></equipment-installer-selector-static>
                </div>
                <div class="d-flex position-relative">
                  <span
                    style="position: absolute; top: -16px; left: 5px; z-index: 1"
                    class="fs-10px font-weight-bold opacity-54 text-uppercase"
                    >Count</span
                  >
                  <counter :ref="'counterAG_' + i" v-model="bulk.count" :min="0" :max="1000" />
                </div>
                <v-tooltip
                  top
                  z-index="9999"
                  :key="'areaGroupUpdateBtn' + i"
                  v-if="!equipmentOnlyMode"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="fs-12px"
                      color="blue accent-4"
                      text
                      v-bind="attrs"
                      :disabled="
                        !selectedEquipment[areaGroup.id || areaGroup.tempId] ||
                        selectedEquipment[areaGroup.id || areaGroup.tempId].length < 1 ||
                        (bulk.providerId == null && bulk.installerId == null && bulk.count == 0)
                      "
                      v-on="on"
                      @click.stop="updateInstallerAndProvider(areaGroup)"
                    >
                      <i class="far fa-check mr-2"></i> Update
                    </v-btn>
                  </template>
                  <span>Update Selected Equipment with these Installer and Provider</span>
                </v-tooltip>
              </div>
              <div
                class="d-flex align-center justify-center indigo lighten-5 ml-2"
                style="height: 32px; border: 1px solid #b39ddb !important; border-radius: 0.5rem"
              >
                <v-tooltip
                  top
                  z-index="9999"
                  :key="'areaGroupCopyBtn' + i"
                  v-if="!readonly && !equipmentOnlyMode"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="fs-12px"
                      text
                      color="blue accent-4"
                      v-bind="attrs"
                      :disabled="
                        !selectedEquipment[areaGroup.id || areaGroup.tempId] ||
                        selectedEquipment[areaGroup.id || areaGroup.tempId].length < 1
                      "
                      v-on="on"
                      @click.stop="openCopyEquipmentDialog(areaGroup)"
                    >
                      <i class="far fa-copy mr-2"> </i> Copy
                    </v-btn>
                  </template>
                  <span>Copy Selected Equipment to Another Area Group</span>
                </v-tooltip>
              </div>
              <div
                class="d-flex align-center justify-center indigo lighten-5 ml-2"
                style="height: 32px; border: 1px solid #b39ddb !important; border-radius: 0.5rem"
              >
                <v-menu
                  dense
                  offset-y
                  right
                  bottom
                  style="z-index: 999"
                  v-if="!readonly && !equipmentOnlyMode"
                >
                  <template v-slot:activator="{ attrs: attrsMenu, on: onMenu }">
                    <v-tooltip top z-index="9999" :key="'areaGroupMoveBtn' + i">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-btn
                            v-bind="attrsMenu"
                            v-on="onMenu"
                            class="fs-12px"
                            text
                            color="blue accent-4"
                            :disabled="
                              !selectedEquipment[areaGroup.id || areaGroup.tempId] ||
                              selectedEquipment[areaGroup.id || areaGroup.tempId].length < 1
                            "
                          >
                            <i class="far fa-external-link-alt mr-2"> </i> Move
                            <i class="fas fa-caret-down ml-4"> </i>
                          </v-btn>
                        </div>
                      </template>
                      <span>Move Selected Equipment</span>
                    </v-tooltip>
                  </template>
                  <v-list class="more-options-menu">
                    <v-list-item @click="openMoveEquipmentDialog(areaGroup)">
                      <v-list-item-icon class="mr-2 justify-center">
                        <i class="fad fa-shapes secondary--text"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="secondary--text">
                          Move to another area group
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!selected.isTemplate"
                      @click="openMoveEquipmentToAnotherSpaceDialog(areaGroup)"
                    >
                      <v-list-item-icon class="mr-2 justify-center">
                        <i class="fad fa-table-pivot secondary--text"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="secondary--text">
                          Move to another space
                          <i
                            v-if="hasChanges"
                            class="fad fa-triangle-exclamation orange--text text--darken-2 ml-2"
                          ></i>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div
                class="d-flex align-center justify-center red lighten-5 ml-2"
                style="height: 32px; border: 1px solid #f44336 !important; border-radius: 0.5rem"
              >
                <v-tooltip
                  top
                  z-index="9999"
                  :key="'areaGroupDeleteBtn' + i"
                  v-if="!readonly && !equipmentOnlyMode"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="fs-12px"
                      text
                      color="red"
                      v-bind="attrs"
                      :disabled="
                        !selectedEquipment[areaGroup.id || areaGroup.tempId] ||
                        selectedEquipment[areaGroup.id || areaGroup.tempId].length < 1
                      "
                      v-on="on"
                      @click.stop="deleteSelectedEquipmentFromAreaGroup(areaGroup)"
                    >
                      <i class="far fa-trash-alt mr-2"> </i> Delete
                    </v-btn>
                  </template>
                  <span>Delete Selected Equipment from this Area Group</span>
                </v-tooltip>
              </div>
              <v-btn
                v-if="!equipmentOnlyMode"
                class="fs-12px ml-2"
                color="orange darken-3"
                text
                @click.stop="clearSelection(areaGroup)"
              >
                <i class="far fa-square-xmark fs-15px mr-2"></i> Clear Selected
              </v-btn>
            </div>
            <div
              v-else-if="!isSpaceTemplateChanged && !isSpaceTemplateAdded && !readonly"
              style="height: 32px"
              class="d-flex align-center justify-start pt-2"
              :key="'bulk_update_empty_' + i"
            >
              <h4 class="ma-0 opacity-54">Select equipment to view the bulk actions!</h4>
            </div>
          </v-scroll-y-transition>
          <v-data-table
            dense
            :headers="areaGroupEquipmentHeader"
            :items="areaGroup.areaEquipments"
            :item-class="rowClass"
            :items-per-page="-1"
            class="elevation-2 area-group-equipment-table mt-4"
            hide-default-footer
            :show-select="
              true ||
              (!isSpaceTemplateChanged && !isSpaceTemplateAdded && !readonly && !equipmentOnlyMode)
            "
            checkbox-color="deep-purple"
            selectable-key
            item-key="equipment.id"
            :search="searchEquipment"
            :custom-filter="equipmentFilter"
            v-model="selectedEquipment[areaGroup.id || areaGroup.tempId]"
          >
            <template v-slot:progress>
              <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
            </template>

            <template v-slot:[`header.data-table-select`]="{ props, on }">
              <v-simple-checkbox
                color="deep-purple"
                :ripple="false"
                v-bind="props"
                v-on="on"
                :disabled="
                  isSpaceTemplateChanged || isSpaceTemplateAdded || readonly || equipmentOnlyMode
                "
                key="user-header-select"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.data-table-select`]="{ item, index, isSelected, select }">
              <v-simple-checkbox
                color="deep-purple"
                :ripple="false"
                :value="isSelected"
                :disabled="
                  isSpaceTemplateChanged || isSpaceTemplateAdded || readonly || equipmentOnlyMode
                "
                @click="
                  isSpaceTemplateChanged || isSpaceTemplateAdded || readonly || equipmentOnlyMode
                    ? false
                    : select(!isSelected)
                "
                :key="'user-select-' + index"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.equipment.name`]="{ item }">
              <v-tooltip left z-index="999" nudge-left="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="equipment-img">
                      <div
                        style="height: 100%; width: 100%"
                        v-viewer
                        @click.stop
                        v-if="item.equipment.imageUrl != null && item.equipment.imageUrl != ''"
                      >
                        <img
                          :key="item.equipment.id + '_img'"
                          :src="item.equipment.imageUrl"
                          height="100%"
                          width="100%"
                        />
                      </div>
                      <i v-else :class="'fad ' + item.equipment.category.icon"></i>
                    </div>
                  </div>
                </template>
                <span>
                  <v-img
                    v-if="(item.equipment.imageUrl != null) & (item.equipment.imageUrl != '')"
                    class="img"
                    :src="item.equipment.imageUrl"
                    width="250px"
                    height="250px"
                    contain
                  ></v-img>
                  <i
                    v-else
                    :class="'fad ' + item.equipment.category.icon"
                    style="font-size: 32px; padding: 2rem"
                  ></i>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.equipment.tag`]="{ item }">
              <div class="equipment-tag">{{ item.equipment.tag }}</div>
            </template>
            <template v-slot:[`item.equipment.msrp`]="{ item }">
              <div class="font-weight-bold mono-font">
                {{ item.equipment.msrp | usdFormat }}
              </div>
            </template>
            <template v-slot:[`item.equipment.category`]="{ item }">
              <v-row no-gutters>
                <v-col sm="auto" class="d-flex justify-content-start align-items-center">
                  <i
                    :class="'equipment-icon fad ' + item.equipment.category.icon"
                    v-if="item.equipment.category"
                  ></i>
                </v-col>
                <v-col class="d-flex align-center">
                  <span class="category-name" v-if="item.equipment.category">{{
                    item.equipment.category.name
                  }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.extendedMsrp`]="{ item }">
              <div class="font-weight-bold mono-font">
                {{ (item.equipment.msrp * item.count) | usdFormat }}
              </div>
            </template>
            <template v-slot:[`item.equipment.manufacture.name`]="{ item }">
              <div
                v-if="item.equipment.manufacture != null"
                class="d-flex align-center my-1"
                style="gap: 0.5rem"
              >
                <manufacturer-card
                  :manufacturer="item.equipment.manufacture"
                  hide-name
                ></manufacturer-card>
              </div>
              <div v-else class="text--disabled">N/A</div>
            </template>
            <template v-slot:[`item.equipment.model`]="{ item }">
              <div class="equipment-model">{{ item.equipment.model }}</div>
            </template>
            <template v-slot:[`item.count`]="{ item }">
              <counter
                :key="item.equipment.id"
                v-model="item.count"
                :readonly="isSpaceTemplateChanged || isSpaceTemplateAdded || readonly"
                :min="0"
                :max="1000"
                @input="onEquipmentsCountChange($event, areaGroup, item.equipment)"
              />
            </template>
            <template v-slot:[`item.installerId`]="{ item }">
              <equipment-installer-selector-static
                v-model="item.installerId"
                @change="
                  (val) => {
                    item.installer = val;
                  }
                "
                :installers-list="installersList"
                :readonly="isSpaceTemplateChanged || isSpaceTemplateAdded || readonly"
              ></equipment-installer-selector-static>
            </template>
            <template v-slot:[`item.providerId`]="{ item }">
              <equipment-provider-selector-static
                v-model="item.providerId"
                @change="
                  (val) => {
                    item.provider = val;
                  }
                "
                :providers-list="providersList"
                :readonly="isSpaceTemplateChanged || isSpaceTemplateAdded || readonly"
              ></equipment-provider-selector-static>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
              <user-avatar :user="item.createdBy" icon></user-avatar>
            </template>
            <template v-slot:[`item.updatedBy`]="{ item }">
              <user-avatar :user="item.updatedBy" icon></user-avatar>
            </template>
            <template v-slot:[`item.createDate`]="{ item }">
              <dater :date="item.createDate" date-only></dater>
            </template>
            <template v-slot:[`item.updateDate`]="{ item }">
              <dater :date="item.updateDate" date-only></dater>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                color="secondary"
                icon
                small
                elevation="0"
                @click="viewEquipment(item.equipment)"
                class="mr-1"
              >
                <i class="fad fa-info" style="font-size: 14px"></i>
              </v-btn>
              <v-btn
                :disabled="isSpaceTemplateAdded || readonly || equipmentOnlyMode"
                color="red"
                icon
                small
                elevation="0"
                @click="deleteEquipmentFromAreaGroup(areaGroup, item.equipment)"
              >
                <i class="fal fa-trash-alt" style="font-size: 14px"></i>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <img width="300" src="/img/art/fogg-no-connection-2.png" />
              <p class="font-weight-bold">No Data Available!</p>
            </template>
            <template v-slot:[`body.append`]="{ items }" v-if="!equipmentOnlyMode">
              <tr class="space-area-group-totals">
                <td colspan="5" align="right" v-if="false">
                  <h4 class="align-right blue-grey--text">Area Group Totals:</h4>
                </td>
                <td><i class="fad fa-hyphen"></i></td>
                <td><i class="fad fa-hyphen"></i></td>
                <td class="pl-5"><i class="fad fa-hyphen"></i></td>
                <td class="pl-5"><i class="fad fa-hyphen"></i></td>
                <td class="pl-4"><i class="fad fa-hyphen"></i></td>
                <td class="pl-4"><i class="fad fa-hyphen"></i></td>
                <td class="text-center">
                  <div class="fs-13px" style="width: 100px">
                    {{ sum(items, "count") }}
                  </div>
                </td>
                <td class="pl-4"><i class="fad fa-hyphen"></i></td>
                <td class="text-left">
                  <span class="fs-13px mono-font">
                    {{ sumMSRP(items) | usdFormat }}
                  </span>
                </td>
                <td class="pl-7"><i class="fad fa-hyphen"></i></td>
                <td class="pl-8"><i class="fad fa-hyphen"></i></td>
                <td class="pl-8"><i class="fad fa-hyphen"></i></td>
                <td class="pl-8"><i class="fad fa-hyphen"></i></td>
                <td class="pl-8"><i class="fad fa-hyphen"></i></td>
                <td class="pl-8"><i class="fad fa-hyphen"></i></td>
                <td class="pl-8"><i class="fad fa-hyphen"></i></td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <equipment-insta-tally ref="instaTallyModal"></equipment-insta-tally>

    <rack-visualizer nested ref="rackVisualizer"></rack-visualizer>

    <!-- add Equipments -->
    <slideout
      dock="right"
      size="950px"
      :min-size="500"
      :visible.sync="slideouts.addEquipments.active"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold pa-1" small>
          <i class="fad fa-plus-square mr-2"></i> Add Equipment to Area Group
        </h3>
        <v-btn @click="discardAddEquipments()" icon><i class="far fa-times"></i></v-btn>
      </template>
      <v-container fluid class="pa-6 pt-2" style="background: #eceff1">
        <add-equipments
          v-model="selectedAreaGroup.areaEquipments"
          :areaGroupName="selectedAreaGroup.name"
          :installersList="installersList"
          :providersList="providersList"
          ref="addEquipmentsControl"
          v-if="slideouts.addEquipments.active"
        ></add-equipments>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="slideouts.addEquipments.active = false">
            <i class="fal fa-check mr-2"></i> Done
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>

    <!-- @save="onEquipmentSaved" -->
    <edit-equipment ref="editEquipment" nested></edit-equipment>

    <!-- Rename Area Group Modal -->
    <v-dialog
      v-model="modals.renameAreaGroup.active"
      max-width="600px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-pen-square mr-2"></i>Rename Area Group
          <b class="ml-1" v-if="selectedAreaGroup != null">{{ selectedAreaGroup.name }}</b>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.renameAreaGroup.valid" ref="renameAreaGroupForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Area Group Name"
                    id="areaGroupName"
                    ref="areaGroupName"
                    placeholder="Area Group Name"
                    hide-details
                    v-model="modals.renameAreaGroup.data.name"
                  >
                  </v-text-field-alt>
                  <h4 class="error--text" v-if="!isRenameAreaGroupNameValid">
                    Area Group Name Already Exists!
                  </h4>
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="renameAreaGroupDiscard()">
              <i class="fal fa-chevron-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              :disabled="!modals.renameAreaGroup.valid || !isRenameAreaGroupNameValid"
              @click="renameAreaGroupConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Rename
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Clone Area Group Modal -->
    <v-dialog
      v-model="modals.cloneAreaGroup.active"
      max-width="600px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-pen-square mr-2"></i>Clone Area Group:
          <b class="ml-1" v-if="selectedAreaGroup != null">{{ selectedAreaGroup.name }}</b>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.cloneAreaGroup.valid" ref="cloneAreaGroupForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Area Group Name"
                    id="cloneAreaGroupName"
                    ref="cloneAreaGroupName"
                    placeholder="Area Group Name"
                    hide-details
                    v-model="modals.cloneAreaGroup.data.name"
                  >
                  </v-text-field-alt>
                  <h4 class="error--text" v-if="!isCloneAreaGroupNameValid">
                    Area Group Name Already Exists!
                  </h4>
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cloneAreaGroupDiscard()">
              <i class="fal fa-chevron-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              :disabled="!modals.cloneAreaGroup.valid || !isCloneAreaGroupNameValid"
              @click="cloneAreaGroupConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Clone
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Create Area Group Modal -->
    <v-dialog
      v-model="modals.createAreaGroup.active"
      max-width="400px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-pen-square mr-2"></i>Create Area Group
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.createAreaGroup.valid" ref="createAreaGroupForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Area Group Name"
                    id="createAreaGroupName"
                    ref="createAreaGroupName"
                    placeholder="Area Group Name"
                    v-model="modals.createAreaGroup.data.name"
                  >
                  </v-text-field-alt>
                  <h4 class="error--text" v-if="!isAddAreaGroupNameValid">
                    Area Group Name Already Exists!
                  </h4>
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="createAreaGroupDiscard()">
              <i class="fal fa-chevron-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              :disabled="!modals.createAreaGroup.valid || !isAddAreaGroupNameValid"
              @click="createAreaGroupConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Copy Template Area Groups Modal -->
    <v-dialog
      v-model="modals.copyTemplateAreaGroups.active"
      max-width="600px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-copy mr-2"></i>Copy Area Groups From a Template
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.copyTemplateAreaGroups.valid" ref="copyTemplateAreaGroupsForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12" class="py-0">
                  <space-template-selector
                    :rules="[allRules.required]"
                    v-model="modals.copyTemplateAreaGroups.templateId"
                    :loading="modals.copyTemplateAreaGroups.isTemplateLoading"
                    :obj.sync="modals.copyTemplateAreaGroups.template"
                  ></space-template-selector>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="copyTemplateAreaGroupDialogDiscard()"
              :disabled="modals.copyTemplateAreaGroups.isLoading"
            >
              <i class="fal fa-chevron-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              :disabled="
                modals.copyTemplateAreaGroups.templateId == null ||
                modals.copyTemplateAreaGroups.isLoading
              "
              :loading="modals.copyTemplateAreaGroups.isLoading"
              @click="copyTemplateAreaGroupDialogConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Copy
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Move Equipment Modal -->
    <v-dialog
      v-model="modals.moveEquipment.active"
      max-width="550px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-split mr-2"></i>Move Selected Equipment to Another Area Group
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.moveEquipment.valid">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12" class="py-0">
                  <div elevation="0" class="d-flex flex-row align-center">
                    <label class="fs-15px ma-0 mr-1" style="font-weight: 600">Move to</label>
                    <v-radio-group
                      v-model="modals.moveEquipment.moveToNewAreaGroup"
                      mandatory
                      hide-details
                      class="mt-0 pt-0 ml-1 big-board-radio"
                      row
                    >
                      <v-radio :value="false" color="orange darken-1">
                        <template v-slot:label>
                          <div
                            class="font-weight-bold fs-13px d-flex align-center"
                            :class="
                              !modals.moveEquipment.moveToNewAreaGroup
                                ? 'orange--text text--darken-1'
                                : 'secondary--text'
                            "
                          >
                            Existing Area Group
                          </div>
                        </template>
                      </v-radio>
                      <v-radio :value="true" color="orange darken-1">
                        <template v-slot:label>
                          <div
                            class="font-weight-bold fs-13px d-flex align-center"
                            :class="
                              modals.moveEquipment.moveToNewAreaGroup
                                ? 'orange--text text--darken-1'
                                : 'secondary--text'
                            "
                          >
                            New Area Group
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                  <v-divider class="my-3"></v-divider>
                </v-col>
                <v-col
                  sm="12"
                  class="py-0"
                  style="height: 90px"
                  v-if="!modals.moveEquipment.moveToNewAreaGroup"
                >
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="!modals.moveEquipment.moveToNewAreaGroup ? [allRules.required] : []"
                    label="Destination Area Group"
                    placeholder="No Area Group Selected!"
                    v-model="modals.moveEquipment.targetAreaGroup"
                    :items="modals.moveEquipment.areaGroups"
                    dense
                    filled
                    clearable
                    return-object
                    item-text="name"
                  >
                  </v-autocomplete-alt>
                </v-col>
                <v-col sm="12" class="py-0" style="height: 90px" v-else>
                  <v-text-field-alt
                    label="New Area Group Name"
                    placeholder="New Area Group Name"
                    v-model="modals.moveEquipment.newAreaGroupName"
                    :rules="
                      modals.moveEquipment.moveToNewAreaGroup
                        ? [allRules.required, areaGroupNameIsUnique]
                        : []
                    "
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="discardMoveEquipmentDialog()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn color="info" :disabled="!modals.moveEquipment.valid" @click="moveEquipment()">
              <i class="fal fa-check mr-2"></i> Move
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Move Equipment to Another Space Modal -->
    <v-dialog
      v-model="modals.moveEquipmentToAnotherSpace.active"
      max-width="550px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold secondary white--text" small>
          <i class="fad fa-split mr-3"></i>Move Selected Equipment to Another Space
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.moveEquipmentToAnotherSpace.valid">
          <v-card-text>
            <v-container class="pa-2">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <project-space-selector
                    :label="`<i class='fad fa-table-pivot mr-2'></i>Target Space`"
                    :project-id="projectId"
                    :except="[selected.id]"
                    disable-templated-spaces
                    v-model="modals.moveEquipmentToAnotherSpace.targetSpaceId"
                    ref="projectSpaceSelector"
                    hide-details
                  ></project-space-selector>
                </v-col>
                <v-col sm="12" class="mt-4">
                  <div elevation="0" class="d-flex flex-column align-start">
                    <label class="fs-15px ma-0 mb-2" style="font-weight: 600">
                      <i class="fad fa-split mr-2"></i>Move to
                    </label>
                    <v-radio-group
                      v-model="modals.moveEquipmentToAnotherSpace.moveToNewAreaGroup"
                      mandatory
                      class="mt-0 pt-0 big-board-radio"
                      row
                    >
                      <v-radio :value="false" color="info">
                        <template v-slot:label>
                          <div
                            class="font-weight-bold fs-13px d-flex align-center"
                            :class="
                              !modals.moveEquipmentToAnotherSpace.moveToNewAreaGroup
                                ? 'info--text'
                                : 'secondary--text'
                            "
                          >
                            Existing Area Group
                          </div>
                        </template>
                      </v-radio>
                      <v-radio :value="true" color="info">
                        <template v-slot:label>
                          <div
                            class="font-weight-bold fs-13px d-flex align-center"
                            :class="
                              modals.moveEquipmentToAnotherSpace.moveToNewAreaGroup
                                ? 'info--text'
                                : 'secondary--text'
                            "
                          >
                            New Area Group
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col
                  sm="12"
                  class="mt-1"
                  style="height: 90px"
                  v-if="!modals.moveEquipmentToAnotherSpace.moveToNewAreaGroup"
                >
                  <space-area-group-selector
                    :label="
                      modals.moveEquipmentToAnotherSpace.targetSpaceId == null
                        ? `<i class='fad fa-shapes mr-2'></i>Target Area Group <span class='opacity-54 pl-2'>
                              <i class='fad fa-circle-info mr-1'></i> Select a space first!</span>`
                        : `<i class='fad fa-shapes mr-2'></i>Target Area Group`
                    "
                    :space-id="modals.moveEquipmentToAnotherSpace.targetSpaceId"
                    :readonly="modals.moveEquipmentToAnotherSpace.targetSpaceId == null"
                    v-model="modals.moveEquipmentToAnotherSpace.targetAreaGroupId"
                    ref="spaceAreaGroupSelector"
                    hide-details
                  ></space-area-group-selector>
                </v-col>
                <v-col sm="12" class="mt-1" style="height: 90px" v-else>
                  <v-text-field-alt
                    :label="
                      modals.moveEquipmentToAnotherSpace.targetSpaceId == null
                        ? `<i class='fad fa-shapes mr-2'></i>New Area Group Name <span class='opacity-54 pl-2'>
                              <i class='fad fa-circle-info mr-1'></i> Select a space first!</span>`
                        : `<i class='fad fa-shapes mr-2'></i>New Area Group Name`
                    "
                    placeholder="New Area Group Name"
                    :readonly="modals.moveEquipmentToAnotherSpace.targetSpaceId == null"
                    v-model="modals.moveEquipmentToAnotherSpace.targetAreaGroupName"
                    :rules="
                      modals.moveEquipmentToAnotherSpace.moveToNewAreaGroup
                        ? [allRules.required]
                        : []
                    "
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="discardMoveEquipmentToAnotherSpaceDialog()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="info"
              :disabled="
                !modals.moveEquipmentToAnotherSpace.valid ||
                modals.moveEquipmentToAnotherSpace.isLoading
              "
              :loading="modals.moveEquipmentToAnotherSpace.isLoading"
              @click="moveEquipmentToAnotherSpace()"
            >
              <i class="fal fa-check mr-2"></i> Move
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Copy Equipment Modal -->
    <v-dialog
      v-model="modals.copyEquipment.active"
      max-width="550px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-copy mr-2"></i>Copy Selected Equipment to Another Area Group
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.copyEquipment.valid">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12" class="py-0">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Destination Area Group"
                    placeholder="No Area Group Selected!"
                    v-model="modals.copyEquipment.targetAreaGroups"
                    :items="modals.copyEquipment.areaGroups"
                    dense
                    filled
                    multiple
                    clearable
                    return-object
                    item-text="name"
                  >
                  </v-autocomplete-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="discardCopyEquipmentDialog()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn color="info" :disabled="!modals.copyEquipment.valid" @click="copyEquipment()">
              <i class="fal fa-check mr-2"></i> Copy
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AddEquipments from "../../Equipments/components/AddEquipments.vue";
import spaceTemplatesService from "../services/spaceTemplates-service";
import spaceService from "../services/spaces-service";
// import SpaceTemplateSelector from "../../Shared/components/SpaceTemplateSelector.vue";
import areaGroupEquipmentHeader from "../config/tables/areaGroupEquipment.header";
import EquipmentInstaTally from "@Shared/components/EquipmentInstaTally.vue";
import EquipmentInstallerSelectorStatic from "../../Equipments/components/EquipmentInstallerSelectorStatic.vue";
import EquipmentProviderSelectorStatic from "../../Equipments/components/EquipmentProviderSelectorStatic.vue";
import RackVisualizer from "./RackVisualizer.vue";
import ProjectSpaceSelector from "../../Shared/components/ProjectSpaceSelector.vue";
import SpaceAreaGroupSelector from "../../Shared/components/SpaceAreaGroupSelector.vue";
import ManufacturerCard from "../../Companies/Manufacturers/components/ManufacturerCard.vue";

export default {
  name: "space-area-groups-manager",
  components: {
    AddEquipments,
    EquipmentProviderSelectorStatic,
    EquipmentInstallerSelectorStatic,
    EquipmentInstaTally,
    EquipmentProviderSelectorStatic,
    SpaceTemplateSelector: () => import("@Shared/components/SpaceTemplateSelector.vue"),
    RackVisualizer,
    ProjectSpaceSelector,
    SpaceAreaGroupSelector,
    ManufacturerCard,
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    installersList: {
      type: Array,
      default: () => [],
    },
    providersList: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: null,
    },
    isSpaceTemplateChanged: {
      type: Boolean,
      default: null,
    },
    isSpaceTemplateAdded: {
      type: Boolean,
      default: null,
    },
    validSpace: {
      type: Boolean,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: null,
    },
    equipmentOnlyMode: {
      type: Boolean,
      default: false,
    },
    hasChanges: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bulk: {
        installerId: null,
        providerId: null,
        count: 0,
      },
      selected: {},
      equipmentToView: {},
      selectedEquipment: {},
      selectedAreaGroup: null,
      expandedAreaGroupPanels: [],
      tempAreaGroupId: -1,
      isTemplateLoading: false,
      searchTemplates: "",
      searchEquipment: "",
      slideouts: {
        addEquipments: {
          active: false,
          isLoading: false,
        },
      },
      modals: {
        renameAreaGroup: {
          active: false,
          valid: false,
          data: {
            name: null,
          },
        },
        cloneAreaGroup: {
          active: false,
          valid: false,
          data: {
            name: null,
          },
        },
        createAreaGroup: {
          active: false,
          valid: false,
          data: {
            name: null,
          },
        },
        copyTemplateAreaGroups: {
          active: false,
          valid: false,
          isLoading: false,
          isTemplateLoading: false,
          searchTemplates: "",
          templates: [],
          templatesTimerId: null,
          templateId: null,
          template: null,
        },
        moveEquipment: {
          active: false,
          valid: false,
          moveToNewAreaGroup: false,
          newAreaGroupName: null,
          targetAreaGroup: null,
          sourceAreaGroup: null,
        },
        moveEquipmentToAnotherSpace: {
          active: false,
          valid: false,
          isLoading: false,
          sourceAreaGroup: null,
          moveToNewAreaGroup: false,
          targetAreaGroups: [],
          targetSpaceId: null,
          targetAreaGroupId: null,
          targetAreaGroupName: null,
        },
        copyEquipment: {
          active: false,
          valid: false,
          copyToNewAreaGroup: false,
          newAreaGroupName: null,
          targetAreaGroups: [],
          sourceAreaGroup: null,
        },
      },
      areaGroupEquipmentHeader: areaGroupEquipmentHeader,
    };
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
    // this.$log(">>>>>>>>>>>>>>>>>>>>>>> SAG Manager MOUNTED");
  },
  methods: {
    sum(items, prop) {
      var sum = items.reduce((total, item) => {
        return this.fixedFloat(total + this.deepGetByPath(item, prop));
      }, 0);
      return sum;
    },
    sumMSRP(items) {
      var sum = items.reduce((total, item) => {
        return this.fixedFloat(total + item.equipment.msrp * item.count);
      }, 0);
      return sum;
    },
    areaGroupNameIsUnique(val) {
      this.$log("areaGroupNameIsUnique", val);
      if (val == null) return true;
      var found = this.selected.areaGroups.find((ag) => ag.name.toLowerCase() == val.toLowerCase());
      return found ? "Area Group with the same name already exists!" : true;
    },
    convertToRackAG(areaGroup) {
      //check if contains one and only rack equipment
      var racksFound = areaGroup.areaEquipments.filter((ae) => ae.equipment.isRack);
      if (racksFound.length == 1) {
        areaGroup.isRack = true;
      } else if (racksFound.length == 0) {
        this.$dialog.notify.error(`No rack equipment added to this area group!`, {
          position: "top-right",
          timeout: 3000,
        });
      } else if (racksFound.length > 1) {
        this.$dialog.notify.error(`More than 1 rack equipment exists in this area group!`, {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    convertToNormalAG(areaGroup) {
      areaGroup.isRack = false;
      areaGroup.rackData = null;
    },
    openRackVisualizer(areaGroup) {
      this.$refs.rackVisualizer.open(areaGroup);
    },
    openTemplateInNewTab() {
      window.open(`/space-templates/${this.selected.templateId}`, "_blank");
    },
    rowClass(item) {
      // return item.count > 0 ? "row-not-empty" : "";
      return "";
    },
    spaceInstaTally() {
      var list = this.selected.areaGroups
        .map((areaGroup) => {
          return [
            ...areaGroup.areaEquipments.map((areaEquipment) => {
              return {
                equipmentId: areaEquipment.equipment.id,
                equipmentCount: areaEquipment.count,
              };
            }),
          ];
        })
        .flat();
      // this.$log("spaceInstaTally", list);
      this.$refs.instaTallyModal.calculate(list);
    },
    areaGroupInstaTally(areaGroup) {
      var list = areaGroup.areaEquipments
        .map((areaEquipment) => {
          return {
            equipmentId: areaEquipment.equipment.id,
            equipmentCount: areaEquipment.count,
          };
        })
        .flat();
      // this.$log("areaGroupInstaTally", list);
      this.$refs.instaTallyModal.calculate(list);
    },
    equipmentFilter(value, search, item) {
      const searchText = search.toLowerCase();
      this.$log("item", item);
      return (
        item.equipment.tag.toLowerCase().indexOf(searchText) > -1 ||
        item.equipment.model.toLowerCase().indexOf(searchText) > -1 ||
        item.equipment.clientPartNumber.toLowerCase().indexOf(searchText) > -1 ||
        (item.installer &&
          (item.installer.name.toLowerCase().indexOf(searchText) > -1 ||
            item.installer.description.toLowerCase().indexOf(searchText) > -1)) ||
        (item.provider &&
          (item.provider.name.toLowerCase().indexOf(searchText) > -1 ||
            item.provider.description.toLowerCase().indexOf(searchText) > -1)) ||
        (item.equipment.category &&
          item.equipment.category.name.toLowerCase().indexOf(searchText) > -1) ||
        (item.equipment.manufacture &&
          item.equipment.manufacture.name.toLowerCase().indexOf(searchText) > -1)
      );
    },
    expandAll() {
      this.expandedAreaGroupPanels = this.selected.areaGroups.map((ag, index) => index);
    },
    collapseAll() {
      this.expandedAreaGroupPanels = [];
    },
    deleteEquipmentFromAreaGroup(areaGroup, equipment) {
      this.$dialog.warning({
        text: `Are you sure you want to delete this equipment?`,
        title: `Delete Equipment?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              let foundIndex = areaGroup.areaEquipments.findIndex(
                (e) => e.equipment.id === equipment.id
              );
              if (foundIndex != -1) {
                areaGroup.areaEquipments.splice(foundIndex, 1);
              }
            },
          },
        },
      });
    },
    deleteSelectedEquipmentFromAreaGroup(areaGroup) {
      this.$log("this.selectedEquipment", this.selectedEquipment);
      this.$dialog.warning({
        text: `Are you sure you want to delete all selected equipment?`,
        title: `Delete Selected Equipment?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              for (
                let i = 0;
                i < this.selectedEquipment[areaGroup.id || areaGroup.tempId].length;
                i++
              ) {
                let selectedAreaEquipment =
                  this.selectedEquipment[areaGroup.id || areaGroup.tempId][i];
                let foundIndex = areaGroup.areaEquipments.findIndex(
                  (ae) => ae.equipment.id == selectedAreaEquipment.equipmentId
                );
                if (foundIndex != -1) {
                  areaGroup.areaEquipments.splice(foundIndex, 1);
                }
              }
              this.clearSelection(areaGroup);
            },
          },
        },
      });
    },
    updateInstallerAndProvider(sourceAreaGroup) {
      this.selectedEquipment[sourceAreaGroup.id || sourceAreaGroup.tempId].forEach(
        (areaEquipment) => {
          // var sourceIndex = sourceAreaGroup.areaEquipments.findIndex((a) => a.id == areaEquipment.id);
          // replace sourceAreaGroup.areaEquipments[sourceIndex] with areaEquipment
          if (this.bulk.providerId != null) areaEquipment.providerId = this.bulk.providerId;
          if (this.bulk.installerId != null) areaEquipment.installerId = this.bulk.installerId;
          if (this.bulk.count != null && this.bulk.count != "" && this.bulk.count != 0)
            areaEquipment.count = this.bulk.count;
        }
      );
    },
    clearSelection(sourceAreaGroup) {
      this.selectedEquipment[sourceAreaGroup.id || sourceAreaGroup.tempId] = [];
      this.bulk.installerId = null;
      this.bulk.providerId = null;
      this.bulk.count = 0;
    },
    openMoveEquipmentDialog(areaGroup) {
      if (
        this.selectedEquipment[areaGroup.id || areaGroup.tempId] &&
        this.selectedEquipment[areaGroup.id || areaGroup.tempId].length > 0
      ) {
        this.modals.moveEquipment.active = true;
        this.modals.moveEquipment.areaGroups = this.selected.areaGroups.filter((area) => {
          const sourceId = areaGroup.id || areaGroup.tempId;
          const areaId = area.id || area.tempId;
          return areaId !== sourceId;
        });
        this.modals.moveEquipment.sourceAreaGroup = areaGroup;
      }
    },
    discardMoveEquipmentDialog() {
      this.modals.moveEquipment.active = false;
      this.modals.moveEquipment.newAreaGroupName = null;
      this.modals.moveEquipment.moveToNewAreaGroup = false;
    },
    moveEquipment() {
      if (this.modals.moveEquipment.moveToNewAreaGroup) {
        this.modals.moveEquipment.targetAreaGroup = this.createAreaGroup(
          this.modals.moveEquipment.newAreaGroupName
        );
      }
      const sourceAreaGroup = this.modals.moveEquipment.sourceAreaGroup;
      this.selectedEquipment[sourceAreaGroup.id || sourceAreaGroup.tempId].forEach((equipment) => {
        var sourceIndex = sourceAreaGroup.areaEquipments.findIndex((a) => a.id == equipment.id);
        sourceAreaGroup.areaEquipments.splice(sourceIndex, 1);
        var targetIndx = this.modals.moveEquipment.targetAreaGroup.areaEquipments.findIndex((a) => {
          const sourceId = equipment.equipmentId || equipment.equipment.id;
          const targetId = a.equipmentId || a.equipment.id;
          return sourceId == targetId;
        }); // equipment exist in target

        if (targetIndx >= 0) {
          this.modals.moveEquipment.targetAreaGroup.areaEquipments[targetIndx].count +=
            equipment.count; // equipment already exist in target
        } else {
          this.modals.moveEquipment.targetAreaGroup.areaEquipments.push(equipment);
        }
      });

      this.modals.moveEquipment.active = false;
      this.modals.moveEquipment.newAreaGroupName = null;
      this.modals.moveEquipment.moveToNewAreaGroup = false;
      this.selectedEquipment[sourceAreaGroup.id || sourceAreaGroup.tempId] = [];
    },
    openMoveEquipmentToAnotherSpaceDialog(areaGroup) {
      if (this.hasChanges) {
        this.$dialog
          .warning({
            text: "You can't move equipment if you have unsaved changes!",
            title: `Move Equipment to another space`,
            color: "warning",
            persistent: true,
            zIndex: 10001,
            actions: {
              true: {
                text: "OK",
              },
            },
          })
          .then((res) => {});
        return;
      }
      if (
        this.selectedEquipment[areaGroup.id || areaGroup.tempId] &&
        this.selectedEquipment[areaGroup.id || areaGroup.tempId].length > 0
      ) {
        this.modals.moveEquipmentToAnotherSpace.sourceAreaGroup = areaGroup;
        this.modals.moveEquipmentToAnotherSpace.active = true;
        this.modals.moveEquipmentToAnotherSpace.isLoading = false;
      }
    },
    discardMoveEquipmentToAnotherSpaceDialog() {
      this.modals.moveEquipmentToAnotherSpace.active = false;
      this.modals.moveEquipmentToAnotherSpace.isLoading = false;
      this.modals.moveEquipmentToAnotherSpace.sourceAreaGroup = null;
      this.modals.moveEquipmentToAnotherSpace.moveToNewAreaGroup = false;
      this.modals.moveEquipmentToAnotherSpace.targetAreaGroups = [];
      this.modals.moveEquipmentToAnotherSpace.targetSpaceId = null;
      this.modals.moveEquipmentToAnotherSpace.targetAreaGroupId = null;
      this.modals.moveEquipmentToAnotherSpace.targetAreaGroupName = null;
    },
    moveEquipmentToAnotherSpace() {
      this.modals.moveEquipmentToAnotherSpace.isLoading = true;
      var toSend = {
        sourceAreaGroupEquipmentIds: this.selectedEquipment[
          this.modals.moveEquipmentToAnotherSpace.sourceAreaGroup.id
        ].map((ae) => ae.id),
        targetSpaceId: this.modals.moveEquipmentToAnotherSpace.targetSpaceId,
        targetAreaGroupId: this.modals.moveEquipmentToAnotherSpace.moveToNewAreaGroup
          ? null
          : this.modals.moveEquipmentToAnotherSpace.targetAreaGroupId,
        targetAreaGroupName: this.modals.moveEquipmentToAnotherSpace.moveToNewAreaGroup
          ? this.modals.moveEquipmentToAnotherSpace.targetAreaGroupName
          : null,
      };
      this.$log("moveAreaGroupEquipmentToAnotherSpace toSend", toSend);
      spaceService
        .moveAreaGroupEquipmentToAnotherSpace(toSend)
        .then((resp) => {
          this.$log("moveAreaGroupEquipmentToAnotherSpace resp.data", resp.data);
          this.$dialog.notify.success("Selected equipment moved successfully!", {
            position: "top-right",
            timeout: 3000,
          });
          this.$emit("equipment-moved");
          this.discardMoveEquipmentToAnotherSpaceDialog();
        })
        .catch((err) => {
          this.modals.moveEquipmentToAnotherSpace.isLoading = false;
          this.$handleError(err);
        });
    },
    openCopyEquipmentDialog(areaGroup) {
      if (
        this.selectedEquipment[areaGroup.id || areaGroup.tempId] &&
        this.selectedEquipment[areaGroup.id || areaGroup.tempId].length > 0
      ) {
        this.modals.copyEquipment.active = true;
        this.modals.copyEquipment.areaGroups = this.selected.areaGroups.filter((area) => {
          const sourceId = areaGroup.id || areaGroup.tempId;
          const areaId = area.id || area.tempId;
          return areaId !== sourceId;
        });
        this.modals.copyEquipment.sourceAreaGroup = areaGroup;
      }
    },
    discardCopyEquipmentDialog() {
      this.modals.copyEquipment.active = false;
    },
    copyEquipment() {
      const sourceAreaGroup = this.modals.copyEquipment.sourceAreaGroup;
      this.selectedEquipment[sourceAreaGroup.id || sourceAreaGroup.tempId].forEach((equipment) => {
        // var sourceIndex = sourceAreaGroup.areaEquipments.findIndex((a) => a.id == equipment.id);
        // sourceAreaGroup.areaEquipments.splice(sourceIndex, 1);
        this.modals.copyEquipment.targetAreaGroups.forEach((targetAG) => {
          var targetIndx = targetAG.areaEquipments.findIndex((a) => {
            const sourceId = equipment.equipmentId || equipment.equipment.id;
            const targetId = a.equipmentId || a.equipment.id;
            return sourceId == targetId;
          }); // equipment exist in target

          if (targetIndx >= 0) {
            targetAG.areaEquipments[targetIndx].count += equipment.count; // equipment already exist in target
          } else {
            targetAG.areaEquipments.push(equipment);
          }
        });
      });

      this.modals.copyEquipment.active = false;
      this.modals.copyEquipment.targetAreaGroups = [];
      this.selectedEquipment[sourceAreaGroup.id || sourceAreaGroup.tempId] = [];
    },
    sortAreaGroups() {
      this.selected.areaGroups.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    },
    checkForChanges() {
      this.$log("checkForChanges()");
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);

      if (this.slideouts.update.hasChanges) {
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
      }

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    openCloneAreaGroupDialog(areaGroup) {
      this.selectedAreaGroup = areaGroup;
      this.modals.cloneAreaGroup.data.name = this.selectedAreaGroup.name + " [Clone]";
      this.modals.cloneAreaGroup.active = true;
      setTimeout(() => {
        this.$refs.cloneAreaGroupForm.resetValidation();
      });
      setTimeout(() => {
        this.$refs.cloneAreaGroupName.select();
      }, 100);
    },
    cloneAreaGroupDiscard() {
      this.modals.cloneAreaGroup.active = false;
      this.modals.cloneAreaGroup.data.name = null;
      this.selectedAreaGroup = null;
    },
    cloneAreaGroupConfirmed() {
      var cloned = this.cloneDeep(this.selectedAreaGroup);
      cloned.name = this.modals.cloneAreaGroup.data.name;
      cloned.id = null;
      cloned.tempId = this.getTempAreaGroupId();
      cloned.areaEquipments.forEach((areaEquipment) => {
        delete areaEquipment.id;
      });
      this.selected.areaGroups.unshift(cloned);
      this.modals.cloneAreaGroup.data.name = null;
      this.selectedAreaGroup = null;
      this.modals.cloneAreaGroup.active = false;
    },
    openRenameAreaGroupDialog(areaGroup) {
      this.selectedAreaGroup = areaGroup;
      this.modals.renameAreaGroup.data.name = this.selectedAreaGroup.name;
      this.modals.renameAreaGroup.active = true;
      setTimeout(() => {
        this.$refs.renameAreaGroupForm.resetValidation();
      });
      setTimeout(() => {
        this.$refs.areaGroupName.select();
      }, 100);
    },
    renameAreaGroupDiscard() {
      this.modals.renameAreaGroup.active = false;
      this.modals.renameAreaGroup.data.name = null;
      this.selectedAreaGroup = null;
    },
    renameAreaGroupConfirmed() {
      this.selectedAreaGroup.name = this.modals.renameAreaGroup.data.name;
      this.modals.renameAreaGroup.data.name = null;
      this.selectedAreaGroup = null;
      this.modals.renameAreaGroup.active = false;
    },
    openCreateAreaGroupDialog() {
      this.modals.createAreaGroup.data.name =
        "New Area Group " + (this.selected.areaGroups.length + 1);
      this.modals.createAreaGroup.active = true;
      setTimeout(() => {
        this.$refs.createAreaGroupForm.resetValidation();
      });
      setTimeout(() => {
        this.$refs.createAreaGroupName.select();
      }, 100);
    },
    createAreaGroupDiscard() {
      this.modals.createAreaGroup.active = false;
      this.modals.createAreaGroup.data.name = null;
    },
    createAreaGroupConfirmed() {
      this.createAreaGroup(this.modals.createAreaGroup.data.name);
      this.modals.createAreaGroup.data.name = null;
      this.modals.createAreaGroup.active = false;
    },
    createAreaGroup(name) {
      let newAreaGroup = {
        id: null,
        tempId: this.getTempAreaGroupId(),
        name: name,
        areaEquipments: [],
      };
      this.selected.areaGroups.unshift(this.cloneDeep(newAreaGroup));
      this.sortAreaGroups();
      this.expandedAreaGroupPanels.push(
        this.selected.areaGroups.findIndex((ag) => ag.tempId == newAreaGroup.tempId)
      );

      return this.selected.areaGroups.find((ag) => ag.tempId == newAreaGroup.tempId);
    },
    getTempAreaGroupId() {
      this.tempAreaGroupId -= 1;
      return this.tempAreaGroupId;
    },
    getAreaGroupEquipmentsCount(areaGroup) {
      if (areaGroup != null)
        return areaGroup.areaEquipments.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    discardAddEquipments() {
      this.slideouts.addEquipments.active = false;
    },
    onEquipmentsCountChange(newVal, areaGroup, equipment) {
      let foundIndex = areaGroup.areaEquipments.findIndex((e) => e.equipment.id === equipment.id);
      if (newVal <= 0 && foundIndex != -1) {
        //areaGroup.areaEquipments.splice(foundIndex, 1);
      }
    },
    getEquipmentsCount(item) {
      if (item != null && item.areaGroups != null)
        return item.areaGroups.reduce((total, cur) => {
          // return total + cur.count + cur.count * this.getEquipmentAccessoriesCount(cur.equipment);
          return total + this.getAreaGroupEquipmentsCount(cur);
        }, 0);
      else return 0;
    },
    getEquipmentAccessoriesCount(item) {
      if (item != null && item.accessories != null)
        return item.accessories.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    viewEquipment(equipmentObj) {
      this.equipmentToView = this.cloneDeep(equipmentObj);
      this.$refs.editEquipment.open(equipmentObj.id);
    },
    openEquipmentsPanel(areaGroup) {
      this.selectedAreaGroup = areaGroup;
      this.slideouts.addEquipments.active = true;
      setTimeout(() => {
        this.$refs.addEquipmentsControl.$refs.mainSearch.focus();
      }, 350);
    },
    delAreaGroup(areaGroup) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this area group?`,
          title: `Delete Area Group`,
          color: "error",
          persistent: true,
          zIndex: 10001,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                let index;
                if (areaGroup.tempId != null && areaGroup.tempId < 0)
                  index = this.selected.areaGroups.findIndex((a) => a.tempId == areaGroup.tempId);
                else index = this.selected.areaGroups.findIndex((a) => a.id == areaGroup.id);
                if (index >= 0) this.selected.areaGroups.splice(index, 1);
              },
            },
          },
        })
        .then((res) => {});
    },
    openCopyTemplateAreaGroupDialog() {
      this.modals.copyTemplateAreaGroups.templateId = null;
      this.modals.copyTemplateAreaGroups.template = null;
      this.modals.copyTemplateAreaGroups.active = true;
      setTimeout(() => {
        this.$refs.copyTemplateAreaGroupsForm.resetValidation();
      });
      setTimeout(() => {
        this.$refs.spaceTemplateCopy.focus();
      }, 100);
    },
    copyTemplateAreaGroupDialogDiscard() {
      this.modals.copyTemplateAreaGroups.active = false;
      this.modals.copyTemplateAreaGroups.isLoading = false;
      this.modals.copyTemplateAreaGroups.templateId = null;
      this.modals.copyTemplateAreaGroups.template = null;
    },
    copyTemplateAreaGroupDialogConfirmed() {
      this.modals.copyTemplateAreaGroups.isLoading = true;
      spaceTemplatesService
        .getTemplateAreaGroups(this.modals.copyTemplateAreaGroups.template.id)
        .then((resp) => {
          var message = "Template area groups are copied successfully!";
          this.$log("getTemplateAreaGroups resp.data", resp.data);
          if (resp.data != null && resp.data.length > 0) {
            resp.data.forEach((areaGroup) => {
              areaGroup.id = null;
              areaGroup.tempId = this.getTempAreaGroupId();
              areaGroup.areaEquipments.forEach((areaEquipment) => {
                areaEquipment.id = undefined;
              });
            });
            this.selected.areaGroups.unshift(...this.cloneDeep(resp.data));
            // this.expandedAreaGroupPanels = this.selected.areaGroups.map((ag, index) => index);

            // setTimeout(() => {
            //   this.$refs.areaGroupAddEquipBtn0[0].$el.focus();
            // }, 100);

            this.$dialog.notify.success(message, {
              position: "top-right",
              timeout: 3000,
            });
          } else {
            message = "Selected Template Is Empty!";

            this.$dialog.notify.warning(message, {
              position: "top-right",
              timeout: 3000,
            });
          }

          this.copyTemplateAreaGroupDialogDiscard();
        })
        .catch((err) => {
          this.modals.copyTemplateAreaGroups.isLoading = true;
          this.$handleError(err);
        });
    },
  },
  computed: {
    isRenameAreaGroupNameValid() {
      if (
        !this.modals.renameAreaGroup.active ||
        this.selected == null ||
        this.selected.areaGroups == null ||
        this.selectedAreaGroup == null
      )
        return true;
      return !this.selected.areaGroups.some(
        (ag) =>
          (ag.name == this.modals.renameAreaGroup.data.name &&
            ag.id != this.selectedAreaGroup.id) ||
          (this.selectedAreaGroup.tempId != null &&
            ag.name == this.modals.renameAreaGroup.data.name &&
            ag.tempId != this.selectedAreaGroup.tempId)
      );
    },
    isCloneAreaGroupNameValid() {
      if (
        !this.modals.cloneAreaGroup.active ||
        this.selected == null ||
        this.selected.areaGroups == null ||
        this.selectedAreaGroup == null
      )
        return true;
      return !this.selected.areaGroups.some(
        (ag) => ag.name == this.modals.cloneAreaGroup.data.name
      );
    },
    isAddAreaGroupNameValid() {
      if (
        !this.modals.createAreaGroup.active ||
        this.selected == null ||
        this.selected.areaGroups == null
      )
        return true;
      return !this.selected.areaGroups.some(
        (ag) => ag.name == this.modals.createAreaGroup.data.name
      );
    },
  },
  watch: {
    searchEquipment: {
      handler() {
        this.expandAll();
      },
    },
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.value;
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
    "slideouts.addEquipments.active": {
      handler() {
        if (this.slideouts.addEquipments.active) {
          setTimeout(() => {
            this.$refs.addEquipmentsControl.focus();
          }, 350);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.area-group-equipment-table {
  border: 1px solid rgba($shades-black, 0.24);

  tbody tr.v-data-table__selected {
    background: rgba($deep-purple-base, 0.1) !important;
  }

  .v-data-table-header .mdi-minus-box {
    color: $deep-purple-base !important;
  }
}

.v-menu__content.provider-installer-select-menu {
  z-index: 111 !important;
}
.v-alert.v-alert--dense {
  padding: 0 !important;

  .v-alert__icon {
    &::after {
      border-radius: 0.25rem 0 0 0.25rem !important;
    }
  }
}
.space-area-groups .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
}

.space-area-groups .v-expansion-panel-content__wrap {
  padding: 0 1rem 1rem 1rem;
}

.space-area-group-totals {
  background: rgba(#607d8b, 0.12);
  font-weight: 600;
  font-size: 18px;

  td {
    border-top: thin dashed rgba(#607d8b, 0.54);
  }

  &:hover {
    background: rgba(#607d8b, 0.24) !important;
  }
}
</style>
