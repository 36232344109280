import Api from "../../Shared/services/api";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "DirectExpenses";
export default {
  getDirectExpensesByRange(options) {
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/GetDirectExpensesByRange?" + qParams.toString());
  },
  exportExcel(options) {
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/ExportEntryDetailsInExcel?" + qParams.toString());
  },
};
