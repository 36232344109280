<template>
  <v-container fluid class="px-6 pt-2" style="background: #eceff1">
    <v-row class="my-0" justify="center" align="center">
      <v-col>
        <h3>Spaces that contains this Equipment!</h3>
      </v-col>
      <v-col
        lg="5"
        md="2"
        sm="6"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-2"
      :headers="headers"
      :items="entities"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      :options.sync="options"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.imageUrl`]="{ item }">
        <v-tooltip right z-index="999" nudge-right="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-inline-block">
              <div class="space-img">
                <v-img
                  v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                  class="img"
                  :src="item.imageUrl"
                  height="100%"
                  width="100%"
                  contain
                ></v-img>
                <i v-else :class="'fad fa-table-pivot'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item.imageUrl != null) & (item.imageUrl != '')"
              class="img"
              :src="item.imageUrl"
              height="250px"
              width="250px"
              contain
            ></v-img>
            <i v-else :class="'fad fa-table-pivot'" style="font-size: 32px; padding: 2rem"></i>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <strong>{{ item.name }} </strong>
        <p class="ma-0" v-if="item.description != null && item.description.trim() != ''">
          {{ item.description }}
        </p>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          elevation="0"
          color="info"
          target="_blank"
          @click.stop
          :href="`/projects/${item.projectId}/spaces/${item.id}`"
        >
          <i class="fal fa-external-link"></i>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import associatedSpaceHeader from "../../Spaces/config/tables/associatedSpace.header";
import equipmentService from "../services/equipments-service";

export default {
  name: "equipment-has-projects",
  props: {
    equipment: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    storageKey: {
      type: String,
    },
  },
  data() {
    return {
      entities: [],
      total: 0,
      clientsTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
      loadingStates: {
        table: false,
      },
      headers: associatedSpaceHeader,
    };
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  methods: {
    getEquipmentItems() {
      this.$backToTop(
        0,
        document.querySelector(".equipment-stats-tabs.v-window.v-item-group.v-tabs-items")
      );
      this.loadingStates.table = true;
      if (this.equipment) {
        equipmentService
          .getEquipmentItems(this.equipment.id, this.$clean(this.options), "spaces")
          .then((resp) => {
            this.entities = resp.data.items;
            this.total = resp.data.total;
            this.loadingStates.table = false;
          })
          .catch(() => {
            this.loadingStates.table = false;
          });
      }
    },
    getProjectDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getEquipmentItems();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);

      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getEquipmentItems();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectDebounced();
      },
      deep: true,
    },
    active(val) {
      if (val) {
        this.options.page = 1;
        this.getProjectDebounced();
      } else {
        this.entities = [];
        this.total = 0;
      }
    },
  },
};
</script>
