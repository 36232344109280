<template>
  <v-menu dense offset-y left z-index="1000" max-height="275px">
    <template v-slot:activator="{ attrs, on }">
      <v-btn color="secondary" small dark width="120px" class="px-2 ml-4" v-bind="attrs" v-on="on">
        <i class="fas fa-usd mr-2"></i> References
        <i class="fas fa-caret-down ml-3 mr-1"></i>
      </v-btn>
    </template>
    <v-list dense class="sell-rate-refs-menu-list">
      <v-list-item
        v-for="(optionItem, i) in options"
        @click="() => (laborRate = optionItem.sellRate)"
        :key="i"
      >
        <v-list-item-content>
          <v-list-item-title color="secondary" class="mb-2">
            <span class="mono-font fs-14px">
              {{ optionItem.sellRate | usdFormat }}
              <span class="opacity-54">/hr</span>
            </span>
          </v-list-item-title>
          <v-list-item-subtitle color="secondary">
            <div class="d-flex flex-column mt-2" style="gap: 0.25rem">
              <div
                v-for="(ref, refIndex) in optionItem.refs"
                :key="refIndex"
                class="d-flex flex-column"
              >
                <span
                  v-if="ref.refType == 'DefaultUserRate'"
                  class="secondary--text opacity-87 fs-13px"
                >
                  <i class="fad fa-user-gear mr-1"></i> Default User Rate
                </span>
                <div
                  class="d-flex justify-space-between"
                  v-else-if="ref.refType == 'ContractUserRate'"
                >
                  <span class="d-flex align-start flex-column">
                    <span class="info--text">
                      {{ ref.project.fullName }}
                    </span>
                    <span class="d-flex align-center fs-13px">
                      <i class="fad fa-file-signature mr-1 secondary--text opacity-87"></i>
                      <code
                        class="mono-font fs-10px blue-grey lighten-5 secondary--text opacity-87 rounded px-1 mr-1"
                        style="flex: none"
                      >
                        #{{ ref.id }}
                      </code>
                      <span class="secondary--text opacity-87">
                        {{ ref.name }}
                      </span>
                    </span>
                  </span>
                  <v-btn
                    icon
                    elevation="0"
                    @click.stop
                    target="_blank"
                    :to="`/projects/${ref.project.id}/contracts/${ref.id}`"
                  >
                    <i class="far fa-arrow-up-right-from-square" style="font-size: 16px"></i>
                  </v-btn>
                </div>
                <v-divider class="my-2" v-if="refIndex != optionItem.refs.length - 1"></v-divider>
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import enums from "../../../plugins/enums";

export default {
  name: "labor-sell-rates-references",
  components: {},
  data() {
    return {
      enums,
      laborRate: null,
    };
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.laborRate = this.cloneDeep(this.value);
  },
  methods: {},
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.laborRate, this.value)) {
          this.laborRate = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    laborRate: {
      handler() {
        if (!this.isEqual(this.laborRate, this.value)) {
          this.$emit("input", this.cloneDeep(this.laborRate));
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
