<template>
  <div v-if="state != null && stateObj != null">
    <v-chip
      class="integration-request-state px-2"
      :small="!small"
      :x-small="small"
      :color="stateObj.color || 'secondary'"
      :text-color="stateObj.textColor || 'white'"
    >
      <i class="fas mr-2" :class="stateObj.icon" style="line-height: 0"></i>
      <span class="font-weight-medium" style="line-height: 0">{{
        stateObj.text.toUpperCase()
      }}</span>
    </v-chip>
  </div>
</template>

<script>
import enums from "../../../../../plugins/enums";
export default {
  name: "integration-request-state",
  data() {
    return {
      stateObj: null,
    };
  },
  props: {
    state: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateStateObj();
  },
  methods: {
    updateStateObj() {
      if (this.state != null) {
        this.stateObj = this.getEnumMember(enums.WireCadIntegrationRequestState, this.state);
      }
    },
  },
  watch: {
    state: {
      handler() {
        this.updateStateObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
