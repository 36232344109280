export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "60px",
    hidable: true,
    hidden: false,
  },
  {
    text: "Status",
    value: "status",
    width: "100px",
    hidable: true,
    hidden: false,
  },
  {
    text: "Type",
    value: "templateType",
    width: "160px",
    hidable: true,
    hidden: false,
  },
  {
    text: "Category",
    value: "downloadTaskCategory",
    width: "160px",
    hidable: true,
    hidden: false,
  },
  { text: "File Name", value: "fileName", hidable: false, hidden: false },
  {
    text: "Created By",
    value: "createdBy",
    width: "130px",
    hidable: true,
    hidden: false,
  },
  {
    text: "Created Date",
    value: "createDate",
    width: "130px",
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "130px",
    hidable: true,
    hidden: false,
  },
];
