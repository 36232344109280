var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5 pt-2",attrs:{"fluid":""}},[_c('div',{staticClass:"pa-5 mt-3 inset-shadow",staticStyle:{"background":"#eceff1","border-radius":"0.5rem"}},[_c('v-btn',{ref:"addContact",attrs:{"disabled":_vm.readonly,"color":"info"},on:{"click":function($event){return _vm.open()}}},[_c('i',{staticClass:"fal fa-plus mr-2"}),_vm._v("Add New Contact ")]),_c('v-data-table',{staticClass:"elevation-2 mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.selected.leadContacts,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.contactState",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getTextByValue(item.contactState, _vm.states))+" ")]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item,"hide-roles":"","disable-open-link":""}})]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","nudge-top":"-16px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-2",on:{"click":function($event){$event.stopPropagation();return _vm.viewClient(item.company.id)}}},'div',attrs,false),on),[(item.company.logoUrl != null && item.company.logoUrl != '')?_c('v-img',{attrs:{"src":item.company.logoUrl,"max-height":"28","max-width":"54","position":"left center","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","max-height":"28","max-width":"54","position":"left center","contain":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.company.name))])])]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
return [_c('v-btn',{attrs:{"disabled":_vm.readonly,"icon":"","elevation":"0","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.del(index)}}},[_c('i',{staticClass:"fal fa-trash-alt"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}),_c('add-contacts-to',{ref:"addContactToLead",attrs:{"readonly":_vm.readonly,"selected":_vm.selected},on:{"confirm":_vm.slideoutsConfirmed}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }