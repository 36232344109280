<template>
  <!-- Create Area Group Modal -->
  <v-dialog v-model="modals.active" max-width="300px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fad fa-circle-pause mr-2"></i>Add Paused Duration
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="addPausedDurationForm">
        <v-card-text>
          <v-row class="my-0">
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field-alt
                :rules="[allRules.required, allRules.noWhiteSpaces]"
                label="Paused Phase(s) Name"
                id="phaseName"
                ref="phaseName"
                placeholder="Paused Phase(s) Name"
                v-model="modals.data.name"
              >
              </v-text-field-alt>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-menu
                transition="scale-transition"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
                :nudge-top="20"
                v-model="startMonthMenu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field-alt
                    :rules="[allRules.required]"
                    class="calendar-input"
                    prepend-inner-icon="mdi-calendar"
                    label="Start Paused Month"
                    id="startMonthValue"
                    placeholder="Start Paused Month"
                    v-model="modals.data.startDate"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field-alt>
                </template>
                <v-date-picker
                  color="orange darken-3"
                  header-color="secondary"
                  v-model="modals.data.startDate"
                  @input="startMonthMenu = false"
                  type="month"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field-alt
                :rules="[
                  allRules.required,
                  allRules.noWhiteSpaces,
                  allRules.intNumber,
                  allRules.numberMin(1),
                ]"
                label="Paused Phases Count"
                id="phasesCount"
                ref="phasesCount"
                placeholder="Paused Phases Count"
                v-model="modals.data.phasesCount"
              >
              </v-text-field-alt>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="addPauseDurationPhasesDiscard()">
            <i class="fal fa-xmark mr-2"></i>Cancel
          </v-btn>
          <v-btn
            color="info"
            @click="addPausedDurationConfirmed()"
            :disabled="!modals.valid"
            :loading="modals.loading"
          >
            <i class="fal fa-check mr-2"></i> Confirm
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import contractService from "../services/contracts-service";
import enums from "../../../plugins/enums";

export default {
  components: {},
  props: {},
  data() {
    return {
      enums: enums,
      startMonthMenu: false,
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        data: {
          name: "",
          startDate: "",
          phasesCount: 0,
          contractId: "",
        },
      },
    };
  },
  methods: {
    getLastDayOfMonth(year, month) {
      const nextMonthFirstDay = new Date(year, month + 1, 1);
      // Subtract one day from the first day of the next month to get the last day of the current month
      const lastDay = new Date(nextMonthFirstDay - 1);
      return lastDay;
    },
    open(contract) {
      this.$log("Open => ", contract);
      this.modals.active = true;
      this.modals.data.contractId = contract.id;
    },
    addPauseDurationPhasesDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        data: {
          name: "",
          contractId: "",
          startDate: "",
          phasesCount: 0,
        },
      };
    },
    addPausedDurationConfirmed() {
      this.modals.loading = true;
      let d = new Date(this.modals.data.startDate);
      this.modals.data.endDate = this.getLastDayOfMonth(d.getUTCFullYear(), d.getUTCMonth());
      contractService
        .addPauseDurationPhases(this.modals.data.contractId, this.modals.data)
        .then((res) => {
          this.$dialog.notify.success("Paused Duration added successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.addPauseDurationPhasesDiscard();
          this.$emit("save");
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.loading = false));
    },
  },
};
</script>
