<template>
  <v-container
    fluid
    v-if="projectId != null && projectId != '' && projectId != NaN"
    class="drawing-documents drawer-closed pa-3 d-flex"
  >
    <!-- <v-sheet class="content-top-bar" color="white" elevation="1" width="100%" height="41">
        <div class="d-flex flex-row align-center">
          <h4 class="secondary--text pl-3">
            <i class="fad fa-pen-ruler mr-4"></i>Drawing Documents View Manager
          </h4>
        </div>
        <div class="d-flex flex-row align-center">
          <v-btn
            :disabled="!canCreate"
            @click="openNewDocumentPanel"
            text
            depressed
            color="info"
            class="action-btn"
            :key="'create-new-menu-btn'"
          >
            <i class="fad fa-file-plus mr-2"></i>New Document
          </v-btn>

          <v-menu offset-y left>
            <template v-slot:activator="{ attrs, on }">
              <v-btn v-bind="attrs" v-on="on" icon small color="secondary" class="action-btn">
                <i class="far fa-ellipsis-vertical"></i>
              </v-btn>
            </template>
            <v-list dense>
              <v-subheader style="height: 32px">Bulk Actions</v-subheader>
              <v-tooltip top z-index="999" nudge-top="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-list-item link style="min-height: 32px" @click="bulkCreate">
                      <v-list-item-icon class="mr-2 d-flex align-center justify-center">
                        <i class="fad fa-circle-plus fa-swap-opacity secondary--text fs-16px"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                          Bulk Create Documents
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
                <span>Bulk Create Drawing Documents on the Fly!</span>
              </v-tooltip>
              <v-divider></v-divider>
              <v-tooltip top z-index="999" nudge-top="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-list-item link style="min-height: 32px" @click="exportTemplate">
                      <v-list-item-icon class="mr-2 d-flex align-center justify-center">
                        <i class="fad fa-down-to-bracket secondary--text fs-16px"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                          Download Empty Template
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
                <span>Download an Empty Drawing Documents Excel Template!</span>
              </v-tooltip>
              <v-tooltip top z-index="999" nudge-top="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-list-item link style="min-height: 32px" @click="exportFilledTemplate">
                      <v-list-item-icon class="mr-2 d-flex align-center justify-center">
                        <i class="fad fa-down-to-bracket secondary--text fs-16px"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                          Download Filled Template
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
                <span>Download a Filled Drawing Documents Excel Template!</span>
              </v-tooltip>
              <v-divider></v-divider>
              <v-tooltip top z-index="999" nudge-top="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-list-item link style="min-height: 32px" @click="importTemplate">
                      <v-list-item-icon class="mr-2 d-flex align-center justify-center">
                        <i class="fad fa-up-from-bracket secondary--text fs-16px"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                          Upload Updated Template
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
                <span>Upload an Updated Drawing Documents Excel Template!</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </div>
      </v-sheet> -->
    <!-- <div class="pa-3 d-flex flex-column drawing-documents-container"> -->
    <drawing-documents-main-view
      ref="drawingDocsList"
      :project-id="projectId"
      :spaces="overviewSection != null ? overviewSection.spaces : []"
      :buildings="overviewSection != null ? overviewSection.buildings : []"
      @new-document="openNewDocumentPanel"
      @bulk-create="bulkCreate"
      @export-template="exportTemplate"
      @export-filled-template="exportFilledTemplate"
      @import-template="importTemplate"
    ></drawing-documents-main-view>
    <!-- </div> -->

    <!-- Exported template  -->
    <v-dialog
      v-model="exportDocumentsTemplate.dialog"
      max-width="450px"
      style="z-index: 99999999"
      @click:outside="exportDocumentsTemplate.loading = true"
    >
      <v-fade-transition mode="out-in">
        <v-card v-if="exportDocumentsTemplate.loading" :key="'export-loading-card'">
          <v-card-text class="py-3">
            <h3 class="mb-2">Generating template...</h3>
            <v-progress-linear indeterminate color="info" class="mb-1" rounded></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else :key="'export-result-card'">
          <v-card-title class="font-weight-bold" small>
            <i class="fad fa-file-arrow-down mr-2"></i>Exported template file is ready!
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="py-0">
              <div class="text-center py-7 mt-3 d-flex justify-center align-center flex-column">
                <div class="report-file-icon" :class="exportDocumentsTemplate.reportFileExt">
                  <i
                    class="fad fa-file-excel"
                    v-if="exportDocumentsTemplate.reportFileExt == 'xlsx'"
                  ></i>
                  <i
                    class="fad fa-file-word"
                    v-if="exportDocumentsTemplate.reportFileExt == 'docx'"
                  ></i>
                </div>
                <h3 class="report-result mt-2">Report Generated</h3>
                <h3 class="report-name mt-2 text--secondary">
                  {{ exportDocumentsTemplate.reportFileName }}
                </h3>
                <v-btn
                  text
                  color="info"
                  class="report-download-link mt-2"
                  :href="exportDocumentsTemplate.reportUrl"
                  :download="exportDocumentsTemplate.reportFileName"
                >
                  <i class="fad fa-arrow-alt-to-bottom mr-2"></i>Download
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                exportDocumentsTemplate.dialog = false;
                exportDocumentsTemplate.loading = true;
              "
            >
              <i class="fal fa-chevron-left mr-2"></i> Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-dialog>

    <!-- Exported Filled template  -->
    <v-dialog
      v-model="exportDocumentsFilledTemplate.dialog"
      max-width="450px"
      style="z-index: 99999999"
      @click:outside="exportDocumentsFilledTemplate.loading = true"
    >
      <v-fade-transition mode="out-in">
        <v-card v-if="exportDocumentsFilledTemplate.loading" :key="'export-loading-card'">
          <v-card-text class="py-3">
            <h3 class="mb-2">Generating Filled Template...</h3>
            <v-progress-linear indeterminate color="info" class="mb-1" rounded></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else :key="'export-result-card'">
          <v-card-title class="font-weight-bold" small>
            <i class="fad fa-file-arrow-down mr-2"></i>Exported filled template file is ready!
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="py-0">
              <div class="text-center py-7 mt-3 d-flex justify-center align-center flex-column">
                <div class="report-file-icon" :class="exportDocumentsFilledTemplate.reportFileExt">
                  <i
                    class="fad fa-file-excel"
                    v-if="exportDocumentsFilledTemplate.reportFileExt == 'xlsx'"
                  ></i>
                  <i
                    class="fad fa-file-word"
                    v-if="exportDocumentsFilledTemplate.reportFileExt == 'docx'"
                  ></i>
                </div>
                <h3 class="report-result mt-2">Report Generated</h3>
                <h3 class="report-name mt-2 text--secondary">
                  {{ exportDocumentsFilledTemplate.reportFileName }}
                </h3>
                <v-btn
                  text
                  color="info"
                  class="report-download-link mt-2"
                  :href="exportDocumentsFilledTemplate.reportUrl"
                  :download="exportDocumentsFilledTemplate.reportFileName"
                >
                  <i class="fad fa-arrow-alt-to-bottom mr-2"></i>Download
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                exportDocumentsFilledTemplate.dialog = false;
                exportDocumentsFilledTemplate.loading = true;
              "
            >
              <i class="fal fa-chevron-left mr-2"></i> Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-dialog>

    <!-- Import filled drawing documents template -->
    <v-dialog
      v-model="importDocumentsTemplate.dialog"
      max-width="500px"
      style="z-index: 99999999"
      persistent
    >
      <v-fade-transition mode="out-in">
        <v-card>
          <v-card-title class="font-weight-bold" small>
            <i class="fad fa-file-arrow-up mr-2"></i>
            Import filled drawing documents template file
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container class="pt-5 pb-0" v-if="importDocumentsTemplate.dialog">
              <v-file-input
                :ref="importDocumentsTemplate.fileInputRef"
                v-model="importDocumentsTemplate.file"
                :rules="importDocumentsTemplate.rules"
                accept=".xlsx"
                label="Drawing documents template file"
                outlined
                dense
                show-size
                counter
                truncate-length="46"
                :loading="importDocumentsTemplate.loading"
                :disabled="importDocumentsTemplate.loading"
              >
                <template v-slot:selection="{ text }">
                  <v-chip color="info" dark small style="max-height: 18px; margin-top: 8px">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeImportTemplate()" :disabled="importDocumentsTemplate.loading">
              <i class="fal fa-times mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="info"
              @click="startImportingTemplate"
              :loading="importDocumentsTemplate.loading"
              :disabled="importDocumentsTemplate.loading || !templateValid"
            >
              <i class="fad fa-up-from-bracket mr-2"></i> Import
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-dialog>

    <bulk-create-drawing-documents
      ref="bulkCreateSlideout"
      :project-id="projectId"
      :spaces-total="spacesTotal"
      @updated="onBulkCreateUpdated"
    ></bulk-create-drawing-documents>
  </v-container>
</template>

<script>
import axios from "@/plugins/axios.js";
import perms from "../../../plugins/permissions";
import ddmAPI from "../services/drawing-documents-service";
import DrawingDocumentsMainView from "./DrawingDocumentsMainView.vue";
import BulkCreateDrawingDocuments from "./BulkCreateDrawingDocuments.vue";

export default {
  components: { DrawingDocumentsMainView, BulkCreateDrawingDocuments },
  data() {
    return {
      total: 0,
      search: "",
      valid: false,
      mainSearchInFocus: false,
      options: {
        search: null,
      },
      loadingStates: {
        table: false,
      },
      exportDocumentsTemplate: {
        dialog: false,
        loading: false,
        reportUrl: "",
        reportFileName: "",
        reportFileExt: "",
      },
      exportDocumentsFilledTemplate: {
        dialog: false,
        loading: false,
        reportUrl: "",
        reportFileName: "",
        reportFileExt: "",
      },
      importDocumentsTemplate: {
        dialog: false,
        fileInputRef: "import-template-file-input",
        loading: false,
        files: [],
        cancellation: null,
        rules: [
          (file) =>
            !file ||
            file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            "Only .xlsx excel files are acceptable!",
        ],
      },
    };
  },
  computed: {
    spacesTotal() {
      return this.overviewSection != null ? this.overviewSection.spacesTotal : 0;
    },
    canCreate() {
      return this.$has(perms.DrawingDocuments.Create);
    },
    canEdit() {
      return this.$has(perms.DrawingDocuments.Update);
    },
    canDelete() {
      return this.$has(perms.DrawingDocuments.Delete);
    },
    templateValid() {
      if (
        this.importDocumentsTemplate.file != null &&
        this.importDocumentsTemplate.file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
        return true;
      return false;
    },
    templateType() {
      if (this.importDocumentsTemplate.file != null) return this.importDocumentsTemplate.file.type;
      return null;
    },
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    overviewSection: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    //get drawing documents
    this.$log("project ddm", this.projectId);
  },
  methods: {
    bulkCreate() {
      this.$refs.bulkCreateSlideout.open();
    },
    onBulkCreateUpdated() {
      this.$refs.drawingDocsList.loadDrawingDocuments();
    },
    openNewDocumentPanel() {
      this.$refs.drawingDocsList.addDocument();
    },
    exportTemplate() {
      this.exportDocumentsTemplate.loading = true;
      setTimeout(() => {
        this.startExportingTemplate();
      }, 50);
    },
    startExportingTemplate() {
      this.exportDocumentsTemplate.dialog = true;
      ddmAPI
        .exportDrawingDocumentsTemplate(this.projectId)
        .then((resp) => {
          this.exportDocumentsTemplate.dialog = true;
          this.exportDocumentsTemplate.loading = false;
          let url = URL.createObjectURL(
            new Blob([resp.data], {
              type: resp.data.type,
            })
          );
          this.exportDocumentsTemplate.reportUrl = url;
          this.exportDocumentsTemplate.reportFileName = resp.headers["x-file-name"];
          this.exportDocumentsTemplate.reportFileExt = resp.headers["x-file-name"].split(".").pop();
        })
        .catch((err) => {
          this.exportDocumentsTemplate.dialog = false;
          this.exportDocumentsTemplate.loading = true;
          this.$handleError(err);
        });
    },
    exportFilledTemplate() {
      this.exportDocumentsFilledTemplate.loading = true;
      setTimeout(() => {
        this.startExportingFilledTemplate();
      }, 50);
    },
    startExportingFilledTemplate() {
      this.exportDocumentsFilledTemplate.dialog = true;
      ddmAPI
        .exportDrawingDocumentsFilledTemplate(this.projectId)
        .then((resp) => {
          this.exportDocumentsFilledTemplate.dialog = true;
          this.exportDocumentsFilledTemplate.loading = false;
          let url = URL.createObjectURL(
            new Blob([resp.data], {
              type: resp.data.type,
            })
          );
          this.exportDocumentsFilledTemplate.reportUrl = url;
          this.exportDocumentsFilledTemplate.reportFileName = resp.headers["x-file-name"];
          this.exportDocumentsFilledTemplate.reportFileExt = resp.headers["x-file-name"]
            .split(".")
            .pop();
        })
        .catch((err) => {
          this.exportDocumentsFilledTemplate.dialog = false;
          this.exportDocumentsFilledTemplate.loading = true;
          this.$handleError(err);
        });
    },
    importTemplate() {
      this.importDocumentsTemplate.dialog = true;
    },
    startImportingTemplate() {
      this.importDocumentsTemplate.loading = true;
      var formData = new FormData();
      formData.append(
        "formFile",
        this.importDocumentsTemplate.file,
        this.importDocumentsTemplate.file.name
      );
      const _cancelToken = axios.CancelToken;
      this.importDocumentsTemplate.cancellation = {
        cancelToken: _cancelToken,
        source: _cancelToken.source(),
      };
      formData.append("cancellation", this.importDocumentsTemplate.cancellation.source.token);
      ddmAPI
        .importDrawingDocumentsTemplate(this.projectId, formData, {
          cancelToken: this.importDocumentsTemplate.cancellation.source.token,
        })
        .then((response) => {
          this.$log("Template is imported successfully!", response, response.data);
          this.$dialog.notify.success("Template is imported successfully!", {
            position: "top-right",
            timeout: 10 * 1000,
          });
          this.$refs.drawingDocsList.loadDrawingDocuments();
          this.closeImportTemplate();
        })
        .catch((error) => {
          this.$log("Uploading Template Error:", error);
          this.closeImportTemplate();

          if (axios.isCancel(error)) {
            this.$log("Importing template is cancelled by user!", error.message);
            this.$dialog.notify.error("Importing template is cancelled by user!", {
              position: "top-right",
              timeout: 10 * 1000,
            });
          } else {
            //handle error
            this.$handleError(error);
          }
        });
    },
    closeImportTemplate() {
      this.importDocumentsTemplate.dialog = false;
      this.importDocumentsTemplate.loading = false;
      this.importDocumentsTemplate.file = [];
    },
  },
  watch: {
    options: {
      handler() {
        // this.getProjectSpacesDebounced();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.drawing-documents {
  .drawing-documents-content {
    background: #eceff1;
    flex: 1 1 auto;
    border-radius: 0.5rem;
    overflow: hidden;

    .content-top-bar.v-sheet.theme--light {
      border-top: 1px solid rgba($shades-black, 0.1) !important;
      border-right: 1px solid rgba($shades-black, 0.1) !important;
      border-top-color: rgba($shades-black, 0.1) !important;
      border-radius: 0 0.35rem 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem;
      flex: none;
      position: sticky;
      top: 0;
      z-index: 100;
      z-index: 8;

      .action-btn {
        font-size: 12px;

        i {
          font-size: 14px;
        }
      }
      .action-btn-sm {
        font-size: 12px;

        i {
          font-size: 12px;
        }
      }
    }

    .drawing-documents-container {
      height: 100%;
      overflow: auto;
      overflow: overlay;
    }
  }

  &.drawer-closed {
    .content-top-bar.v-sheet.theme--light {
      border-left: 1px solid rgba($shades-black, 0.1) !important;
      border-radius: 0.35rem 0.35rem 0 0;
    }

    // .drawing-documents-content {
    //   border-radius: 0.35rem !important;
    // }
  }
}
</style>
