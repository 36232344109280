var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slideout',{class:'slideout-readonly-content',attrs:{"dock":"right","size":_vm.mainPanelSize,"min-size":_vm.nested ? 400 : 700,"allow-resize":_vm.nested ? false : false,"append-to":_vm.nested ? '' : '#app',"visible":_vm.slideouts.update.active},on:{"update:visible":function($event){return _vm.$set(_vm.slideouts.update, "active", $event)},"close":_vm.onSlideoutClosing},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.isFetching)?_c('h3',{staticClass:"font-weight-bold pa-1",attrs:{"small":""}},[_c('i',{staticClass:"fad fa-spinner-third fa-spin mr-2"}),_vm._v(" Fetching MS Team Info ... ")]):(_vm.selected != null)?_c('h3',{staticClass:"font-weight-bold pa-1",attrs:{"small":""}},[_c('img',{staticClass:"mr-2",staticStyle:{"height":"14px"},attrs:{"src":_vm.defaultTeamsLogo}}),_c('span',{staticClass:"opacity-64"},[_vm._v("MS Team:")]),_vm._v(" "+_vm._s(_vm.selected.name)+" ")]):_vm._e(),_c('div',[_c('panel-size-control',{model:{value:(_vm.slideouts.update.fullWidth),callback:function ($$v) {_vm.$set(_vm.slideouts.update, "fullWidth", $$v)},expression:"slideouts.update.fullWidth"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeUpdateSlideout()}}},[_c('i',{staticClass:"far fa-times"})])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":"","z-index":"999","nudge-top":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"2","width":"32px","height":"32px","min-width":"32px","min-height":"32px","loading":_vm.isFetching,"disabled":_vm.isFetching},on:{"click":function($event){return _vm.fetchJiraProject()}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fal fa-arrows-rotate",staticStyle:{"font-size":"16px"}})])]}}])},[_c('span',[_vm._v("Refetch Jira Project Details")])])],1)],1)]},proxy:true}])},[(_vm.isFetching)?_c('v-container',{staticClass:"pa-0 text-center",attrs:{"fluid":""}},[_c('video',{staticStyle:{"padding":"0.5rem","margin":"0 auto"},attrs:{"width":"320","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Fetching MS Team ...")])]):(_vm.selected != null)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"my-0 w-100",staticStyle:{"flex":"none"},attrs:{"dense":""}},[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"fs-15px opacity-87 mb-1"},[_vm._v("Name")]),_c('div',{staticClass:"d-flex align-center readonly-border pa-2",staticStyle:{"gap":"1rem","height":"40px"}},[_c('h4',[_vm._v(_vm._s(_vm.selected.name))])])]),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"4"}},[_c('h3',{staticClass:"fs-15px opacity-87 mb-1"},[_vm._v("Visibility")]),_c('div',{staticClass:"d-flex align-center readonly-border pa-2",staticStyle:{"gap":"1rem","height":"40px"}},[_c('ms-team-visibility',{attrs:{"small":"","visibility":_vm.msTeam.visibility}})],1)]),_c('v-col',{staticClass:"mb-3 pl-4",attrs:{"cols":"12","md":"8"}},[_c('h3',{staticClass:"fs-15px opacity-87 mb-2"},[_vm._v("General Channel Links")]),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('v-tooltip',{attrs:{"top":"","nudge-top":"-4","disabled":_vm.teamMainLink == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"secondary","outlined":"","small":"","target":"_blank","href":_vm.teamMainLink,"disabled":_vm.teamMainLink == null}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fad fa-arrow-up-right-from-square fs-14px mr-2"}),_vm._v(" Open in App ")])]}}])},[_c('span',[_vm._v("Open in Microsoft Teams App")])]),_c('v-tooltip',{attrs:{"top":"","nudge-top":"-4","disabled":_vm.sharepointUrl == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"secondary","outlined":"","small":"","target":"_blank","href":_vm.sharepointUrl,"disabled":_vm.sharepointUrl == null}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fad fa-folder-tree fs-14px mr-2"}),_vm._v(" Open SharePoint Folder ")])]}}])},[_c('span',[_vm._v("Open SharePoint Folder")])])],1)]),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"fs-15px opacity-87 mb-1"},[_vm._v("Description")]),_c('div',{staticClass:"d-flex align-center readonly-border pa-2",staticStyle:{"min-height":"40px"}},[(_vm.selected.description != null && _vm.selected.description != '')?_c('div',[_vm._v(" "+_vm._s(_vm.selected.description)+" ")]):_c('i',{staticClass:"fad fa-empty-set opacity-72"})])]),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"fs-15px opacity-87 mb-1"},[_vm._v("Channels")]),(_vm.selected.channels != null && _vm.selected.channels.length > 0)?_c('div',{staticClass:"d-flex flex-wrap align-center readonly-border pa-4 inset-shadow",staticStyle:{"gap":"1rem","background":"#eceff1","border-radius":"0.5rem"}},_vm._l((_vm.selected.channels),function(channel,i){return _c('v-card',{key:i,staticClass:"d-flex align-center py-1 px-2",staticStyle:{"gap":"0.5rem","flex-wrap":"nowrap"}},[_c('h4',{staticStyle:{"white-space":"nowrap"}},[_c('i',{staticClass:"fad fa-bullhorn mr-2"}),_vm._v(_vm._s(channel.name)+" ")])])}),1):_c('div',{staticClass:"d-flex flex-wrap align-center readonly-border pa-4 inset-shadow",staticStyle:{"gap":"1rem","background":"#eceff1","border-radius":"0.5rem"}},[_c('i',{staticClass:"fad fa-empty-set opacity-72"})])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }