<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 500 : 1100"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    class="slideout-dark-header"
    :visible.sync="slideouts.view.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Offer ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1 d-flex align-center" small>
        <span><i class="fad fa-horse-head mr-2"></i>View: {{ selected.name }}</span>
        <entity-id class="ml-2" :label="selected.id" dark disable-link></entity-id>
        <resource-offer-state class="ml-2" :state="selected.state"></resource-offer-state>
      </h3>
      <div>
        <panel-size-control dark v-model="slideouts.view.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon dark>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <div
      color="white"
      style="position: sticky; top: 0; z-index: 2"
      @wheel="wheelIt"
      ref="tabsContainer"
    >
      <v-tabs
        v-model="slideouts.view.tab"
        color="error"
        v-if="slideouts.view.active && !isFetching"
      >
        <v-tab :key="0"> <i class="fad fa-memo-circle-info mr-2"></i> Basic Info </v-tab>
        <v-tab :key="1">
          <i class="fad fa-clock mr-2"></i>Offered Hours
          <v-chip
            :color="slideouts.view.tab == 1 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ totalOfferedHours }}
          </v-chip>
        </v-tab>
        <v-tab :key="2" v-if="selected.approvedLaborSellRates != null && managementMode">
          <i class="fad fa-usd-circle mr-2"></i>Approved Labor Sell Rates
        </v-tab>
        <v-tab
          :key="selected.approvedLaborSellRates != null && managementMode ? 3 : 2"
          v-if="selected.state == enums.ResourceOfferState.Approved.value"
        >
          <i class="fad fa-file-signature mr-2"></i>Generated Contracts
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Offer ...</p>
    </v-container>

    <v-tabs-items v-else v-model="slideouts.view.tab" style="height: 100%">
      <v-tab-item :key="0">
        <v-container fluid class="pa-4">
          <v-row dense>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field-alt
                :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
                label="Name"
                placeholder="Offer Name"
                v-model="selected.name"
                readonly
              ></v-text-field-alt>
            </v-col>
            <v-col cols="12" md="12" class="mb-4">
              <rich-text-editor
                ref="richTextEditor"
                v-model="selected.justification"
                title="Offer Justification<i class='fas fa-star-of-life pink--text label-icon'></i>"
                showLabel
                allowExpand
                readonly
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="1" style="height: 100%">
        <v-container fluid class="pa-4" style="height: 100%">
          <div style="height: 100%; display: flex; flex-direction: column">
            <v-row class="mt-0">
              <v-col style="width: 100%" class="pt-0">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-up-from-arc mr-2"></i>Take hours from
                  <span class="opacity-64 fs-12px ml-1">"Source"</span>
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.sourceUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
              </v-col>
              <v-col style="width: 100%" class="pt-0">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-down-to-arc mr-2"></i>Offer hours to
                  <span class="opacity-64 fs-12px ml-1">"Target"</span>
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.targetUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
              </v-col>
            </v-row>
            <!-- <v-divider class="mt-4"></v-divider> -->
            <div class="mt-6 mb-0 d-flex flex-row align-center" style="gap: 1rem">
              <label class="input-label">
                <i class="fad fa-clock mr-2"></i>Offered Hours
                <v-chip
                  color="secondary"
                  dark
                  class="ml-2 font-weight-bold"
                  small
                  label
                  style="height: 20px"
                  >{{ totalOfferedHours }}
                </v-chip>
              </label>
            </div>
            <div
              class="pa-5 inset-shadow mt-2"
              style="
                background: #eceff1;
                border-radius: 0.5rem;
                overflow: auto;
                overflow: overlay;
                height: 100%;
              "
            >
              <v-slide-y-reverse-transition mode="out-in" style="width: 100%">
                <div
                  v-if="selected.offerRecords != null && selected.offerRecords.length == 0"
                  key="empty"
                  style="gap: 1rem"
                  class="d-flex align-center justify-center flex-column"
                >
                  <i class="fad fa-horse-saddle fa-swap-opacity" style="font-size: 7rem"></i>
                  <h3 class="mb-1">Nothing to offer yet!</h3>
                </div>
                <v-slide-y-transition
                  v-else
                  key="not-empty"
                  mode="out-in"
                  group
                  style="width: 100%; gap: 1rem"
                  class="d-flex flex-column"
                >
                  <offer-record-view-card
                    v-for="(offerRecord, i) in selected.offerRecords"
                    :key="'or_' + i"
                    :value="selected.offerRecords[i]"
                    :show-sell-rates="selected.approvedLaborSellRates != null && managementMode"
                    :show-received-hours="selected.approvedLaborSellRates != null && managementMode"
                    :sourceSellRate="sourceSellRateByLaborType(offerRecord.sourceLaborType)"
                    :targetSellRate="targetSellRateByLaborType(offerRecord.targetLaborType)"
                    @delete="deleteOfferRecord(offerRecord)"
                    fullWidth
                  ></offer-record-view-card>
                </v-slide-y-transition>
              </v-slide-y-reverse-transition>
            </div>
          </div>
        </v-container>
      </v-tab-item>
      <v-tab-item
        :key="2"
        style="height: 100%"
        v-if="selected.approvedLaborSellRates != null && managementMode"
      >
        <v-container fluid class="pa-4" style="height: 100%">
          <div style="height: 100%; display: flex; flex-direction: column; flex: none">
            <v-row class="mt-0" style="flex: none">
              <v-col style="width: 100%; height: 100%" class="pt-0 d-flex flex-column">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-up-from-arc mr-2"></i>Source Labor Rates
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.sourceUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
                <div
                  class="pa-5 inset-shadow mt-2 d-flex flex-column"
                  style="background: #eceff1; border-radius: 0.5rem; gap: 0.5rem"
                >
                  <v-card
                    elevation="2"
                    rounded="lg"
                    class="py-2 px-3"
                    v-for="(labor, i) in selected.approvedLaborSellRates.sourceSellRates
                      .laborRatePerLaborTypes"
                    :key="i"
                  >
                    <v-container class="pa-0">
                      <v-row dense>
                        <v-col cols="12" md="5" class="d-flex flex-column">
                          <b class="blue-grey--text fs-12px" style="white-space: nowrap"> Labor </b>
                          <b class="opacity-87 fs-14px" style="white-space: nowrap">
                            {{ getLaborTypeObj(labor.laborType).desc }}
                          </b>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" md="7" class="d-flex flex-column pl-3">
                          <b class="blue-grey--text fs-12px" style="white-space: nowrap">
                            Sell Rate
                          </b>
                          <span class="font-weight-bold mono-font fs-12px">
                            {{ labor.laborRate | usdFormat }} /hr
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col style="width: 100%; height: 100%" class="pt-0 d-flex flex-column">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-down-to-arc mr-2"></i>Target Labor Rates
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.targetUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
                <div
                  class="pa-5 inset-shadow mt-2 d-flex flex-column"
                  style="background: #eceff1; border-radius: 0.5rem; gap: 0.5rem"
                >
                  <v-card
                    elevation="2"
                    rounded="lg"
                    class="py-2 px-3"
                    v-for="(labor, i) in selected.approvedLaborSellRates.targetSellRates
                      .laborRatePerLaborTypes"
                    :key="i"
                  >
                    <v-container class="pa-0">
                      <v-row dense>
                        <v-col cols="12" md="5" class="d-flex flex-column">
                          <b class="blue-grey--text fs-12px" style="white-space: nowrap"> Labor </b>
                          <b class="opacity-87 fs-14px" style="white-space: nowrap">
                            {{ getLaborTypeObj(labor.laborType).desc }}
                          </b>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" md="7" class="d-flex flex-column pl-3">
                          <b class="blue-grey--text fs-12px" style="white-space: nowrap">
                            Sell Rate
                          </b>
                          <span class="font-weight-bold mono-font fs-12px">
                            {{ labor.laborRate | usdFormat }} /hr
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-tab-item>
      <v-tab-item
        :key="selected.approvedLaborSellRates != null && managementMode ? 3 : 2"
        v-if="selected.state == enums.ResourceOfferState.Approved.value"
      >
        <v-container fluid class="pa-4" style="height: 100%">
          <div class="mb-0 d-flex flex-row align-center" style="gap: 1rem">
            <label class="input-label">
              <i class="fad fa-file-signature mr-2"></i>
              Generated Contracts<v-chip
                v-if="selected.generatedContracts != null"
                color="secondary"
                dark
                class="ml-2 font-weight-bold"
                small
                label
                style="height: 20px"
                >{{ selected.generatedContracts.length }}
              </v-chip>
            </label>
          </div>
          <div
            class="pa-5 inset-shadow mt-2"
            style="background: #eceff1; border-radius: 0.5rem; overflow: auto; overflow: overlay"
          >
            <v-slide-y-reverse-transition mode="out-in" style="width: 100%">
              <div
                v-if="
                  selected.generatedContracts == null || selected.generatedContracts.length == 0
                "
                key="empty"
                style="gap: 1rem"
                class="d-flex align-center justify-center flex-column"
              >
                <i class="fad fa-empty-set opacity-54" style="font-size: 7rem"></i>
                <h3 class="mb-1">No contracts available to show!</h3>
              </div>
              <v-slide-y-transition
                v-else
                key="not-empty"
                mode="out-in"
                group
                style="width: 100%; gap: 1rem"
                class="d-flex flex-column"
              >
                <v-card
                  class="pa-2"
                  v-for="(contract, i) in selected.generatedContracts"
                  :key="'or_' + i"
                >
                  <h4>
                    <i class="fad fa-folder-open mr-1 secondary--text"></i>
                    <a
                      small
                      text
                      target="_blank"
                      class="py-0 px-1 fs-12px secondary--text"
                      max-height="20px"
                      :href="`/projects/${contract.project.id}`"
                      >{{ contract.project.fullName }}
                    </a>
                  </h4>
                  <h4 class="fs-14px my-2 d-flex align-center">
                    <i class="fad fa-file-signature mr-1" style="width: 20px"></i>
                    <code class="fs-11px blue-grey lighten-5 mono-font mr-1 px-1">
                      #{{ contract.id }}
                    </code>
                    <a
                      small
                      text
                      class="py-0 px-1 fs-12px secondary--text"
                      max-height="20px"
                      target="_blank"
                      :href="`/projects/${contract.project.id}/contracts/${contract.id}`"
                    >
                      {{ contract.name }}
                    </a>
                  </h4>
                  <h4 class="fs-12px d-flex align-center opacity-72">
                    <i class="far fa-clock mr-1" style="width: 20px"></i>
                    <dater :date="contract.createDate"></dater>
                  </h4>
                </v-card>
              </v-slide-y-transition>
            </v-slide-y-reverse-transition>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex ml-2" v-if="!isFetching && selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.view.isLoading || isFetching"
                @click="fetchOffer()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Offer</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import offersAPI from "../services/resource-offers-service";
import ResourceOfferState from "./ResourceOfferState.vue";
import OfferRecordViewCard from "./OfferRecordViewCard.vue";
import usersAPI from "../../Admin/services/StaffService";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import enums from "../../../plugins/enums";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";

export default {
  name: "view-offer",
  components: {
    PanelSizeControl,
    UserAllSelector,
    OfferRecordViewCard,
    ResourceOfferState,
    RichTextEditor,
  },
  data() {
    return {
      perms,
      enums,
      offerId: null,
      isFetching: false,
      isUsersLoading: false,
      users: [],
      selected: {},
      selectedCemented: {},
      slideouts: {
        view: {
          tab: 0,
          active: false,
          fullWidth: false,
          isLoading: false,
          isClosing: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    managementMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.view.fullWidth ? "100%" : this.nested ? "87%" : "1100px";
    },
    offersUrl() {
      if (this.selected == null) return "";
      return `offers/${this.selected.id}`;
    },
    totalOfferedHours() {
      if (!this.slideouts.view.active || this.isFetching) return 0;
      else
        return this.selected.offerRecords.reduce((total, cur) => {
          return total + cur.offeredHours;
        }, 0);
    },
    sourceSellRateByLaborType() {
      return (laborType) => {
        return this.selected.approvedLaborSellRates?.sourceSellRates.laborRatePerLaborTypes.find(
          (l) => l.laborType == laborType
        )?.laborRate;
      };
    },
    targetSellRateByLaborType() {
      return (laborType) => {
        return this.selected.approvedLaborSellRates?.targetSellRates.laborRatePerLaborTypes.find(
          (l) => l.laborType == laborType
        )?.laborRate;
      };
    },
  },
  created() {
    this.getAllUsersData();
  },
  methods: {
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    onSlideoutClosing(e) {
      //reset the changes
      this.selected = {};
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideouts.view.active = false;
    },
    open(id) {
      this.$log(">>>>>>> open", id);
      this.offerId = id;
      this.fetchOffer();
      this.slideouts.view.tab = 1;
      this.slideouts.view.active = true;
    },
    fetchOffer() {
      this.isFetching = true;
      var includeContracts = true;
      offersAPI
        .getById(this.offerId, includeContracts)
        .then((resp) => {
          this.$log("getOfferById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.view.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
        })
        .catch((err) => {
          this.$log("getOfferById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
  },
};
</script>
