<template>
  <div
    class="d-flex"
    style="gap: 0.5rem; flex-wrap: wrap; justify-content: flex-end"
    v-if="!refresh"
  >
    <template v-for="(filter, k) in Object.values(activeFilters)">
      <v-slide-x-reverse-transition mode="out-in" :key="'filter-menu' + k">
        <div v-if="filter.active" class="filter-menu-option">
          <filter-menu
            v-bind="filter.attrs"
            v-model="optionsData[filter.model]"
            :items="
              filter.attrs.items
                ? filter.attrs.fixedItems
                  ? filter.attrs.items
                  : $data[filter.attrs.items]
                : []
            "
            @close="filterHasChanged(filter, k)"
          >
            <template #[`item`]="{ item }">
              <user-avatar
                v-if="['createdBy', 'updatedBy', 'user'].includes(filter.name)"
                :user="item"
                hide-roles
                disable-open-link
              ></user-avatar>
              <v-list-item-content v-if="filter.name == 'projectStatus'">
                <project-status :status="item.value" small></project-status>
              </v-list-item-content>
              <v-list-item-content v-if="filter.name == 'leadStatus'">
                <lead-status :status="item.value" small></lead-status>
              </v-list-item-content>
              <v-list-item-content v-if="filter.name == 'provider'">
                <equipment-provider :provider-name="item.name"></equipment-provider>
              </v-list-item-content>
              <v-list-item-content v-if="filter.name == 'installer'">
                <equipment-installer :installer-name="item.name"></equipment-installer>
              </v-list-item-content>
              <template v-if="filter.name == 'category'">
                <v-list-item-avatar>
                  <i v-if="item.icon" :class="'equipment-icon fad ' + item.icon"></i>
                  <i v-else :class="'equipment-icon fad fa-plug'"></i>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item-content v-if="filter.name == 'make'">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="filter.name == 'manufacturer'">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="filter.name == 'productFamily'">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="filter.name == 'building'">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="filter.name == 'level'">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="filter.name == 'userRoles'">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar class="mr-3 my-0" rounded v-if="filter.name == 'spaceTemplate'">
                <v-img
                  height="25"
                  width="25"
                  v-if="item.imageUrl"
                  :src="item.imageUrl"
                  class="img"
                  position="center center"
                  contain
                ></v-img>
                <i v-else :class="'fad fa-table-pivot'"></i>
              </v-list-item-avatar>
              <v-list-item-content v-if="filter.name == 'spaceTemplate'">
                <v-list-item-title>
                  <span class="fs-12px font-weight-medium">{{ item.name }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar class="mr-3 my-0" rounded v-if="filter.name == 'client'">
                <v-img
                  height="25"
                  width="25"
                  v-if="item.logoUrl != null && item.logoUrl != ''"
                  :src="item.logoUrl"
                  class="img"
                  position="center center"
                  contain
                ></v-img>
                <v-img
                  v-else
                  src="/img/DNA_Symbol.png"
                  height="25"
                  width="25"
                  position="center center"
                  contain
                >
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content v-if="filter.name == 'client'">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template #[`selection`]="{ item, groups }">
              <v-btn
                v-if="
                  filter.name == 'createdBy' || filter.name == 'updatedBy' || filter.name == 'user'
                "
                class="text-capitalize body-1 d-flex align-center"
                height="32"
                :class="item ? 'filter-active' : ''"
                :color="item ? 'secondary' : 'white'"
                small
              >
                <i class="fad fa-filter me-2 fs-12px"></i>{{ filter.attrs.title }}
                <span v-if="item">: {{ item.firstName }} {{ item.lastName }}</span>
                <v-chip x-small color="grey" v-if="groups.length > 1">
                  +{{ groups.length - 1 }}
                </v-chip>
                <!-- <i class="far fa-chevron-down ms-1" style="font-size: 12px"></i> -->
              </v-btn>
            </template>
          </filter-menu>
        </div>
      </v-slide-x-reverse-transition>
    </template>

    <v-menu rounded="md" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          style="height: 32px !important"
          color="white"
          v-bind="attrs"
          v-on="on"
          class="text-capitalize d-flex align-center"
          height="32"
          ref="menuBtn"
        >
          <span><i class="fad fa-sliders-v mr-2"></i></span> Filters
        </v-btn>
      </template>
      <v-list class="pb-0">
        <v-list-item-group v-model="activeFiltersModel" multiple>
          <v-list-item
            :value="k"
            v-for="(filter, k) in activeFilters"
            :key="'filterList' + k"
            @change="filterHasChanged(filter)"
            active-class="blue--text"
            dense
          >
            <template v-slot:default="{ active }">
              <v-list-item-action class="mr-1 my-0">
                <v-checkbox
                  :value="filter.active"
                  :input-value="active"
                  color="info"
                  dense
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content class="px-1">
                <v-list-item-title style="font-size: 12px !important">
                  {{ filter.attrs.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import EquipmentProvider from "../../Equipments/components/EquipmentProvider.vue";
import LeadStatus from "../../Leads/components/LeadStatus.vue";
import ProjectStatus from "../../Projects/components/ProjectStatus.vue";
import EquipmentInstaller from "../../Equipments/components/EquipmentInstaller.vue";
import categoriesAPI from "../../Equipments/services/categories-service";
import equipmentsAPI from "../../Equipments/services/equipments-service";
import spacesAPI from "../../Spaces/services/spaces-service";
import manufacturersAPI from "../../Companies/Manufacturers/services/manufacturers-service.js";
import Enums from "../../../plugins/enums";

export default {
  name: "filters-control",
  data() {
    return {
      enums: Enums,
      categories: [],
      manufacturers: [],
      productFamilies: [],
      buildings: [],
      levels: [],
      activeFilters: {},
      activeFiltersModel: [],
      optionsData: [],
      refresh: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  computed: {},
  mounted() {
    this.activeFilters = this.cloneDeep(this.value);
    this.optionsData = this.cloneDeep(this.options);
    this.initFetches();
    // this.$log(
    //   "Object.values(activeFilters)",
    //   this.cloneDeep(Object.values(this.activeFilters))
    // );
  },
  methods: {
    initFetches() {
      if (this.activeFilters.category) this.getCategories();
      if (this.activeFilters.building) this.getBuildingsAndLevels();
      if (this.activeFilters.manufacturer) this.getManufacturersAndProductFamilies();
    },
    getBuildingsAndLevels() {
      spacesAPI.getBuildingsAndLevels(this.projectId).then((resp) => {
        this.buildings = resp.data;
      });
    },
    getManufacturersAndProductFamilies() {
      manufacturersAPI.getAll().then((resp) => {
        var mans = resp.data;
        this.manufacturers = mans.map((m) => {
          m.productFamilies.unshift({
            id: -1,
            name: "Product Family not applicable",
            description: "Product Family not applicable",
          });
          return m;
        });
      });
    },
    filterHasChanged(filter, index = null) {
      //clear selection
      if (filter.active && this.$refs[filter.attrs.ref] != null)
        this.$refs[filter.attrs.ref].clearSelection();
      filter.active = !filter.active;

      //if the level is activated, then the building should be activated too.
      if (filter.name == "level" && filter.active == true && this.activeFilters.building != null) {
        this.activeFilters.building.active = true;
      }

      //if the building is de-activated, then the level should be de-activated too.
      if (
        filter.name == "building" &&
        filter.active == false &&
        this.activeFilters.level != null &&
        this.activeFilters.level.active
      ) {
        this.$refs[this.activeFilters.level.attrs.ref].clearSelection();
        this.activeFilters.level.active = false;
      }

      this.$log(
        "Object.values(this.activeFilters):",
        this.cloneDeep(Object.values(this.activeFilters))
      );
      this.$nextTick(() => {
        this.activeFiltersModel = Object.values(this.activeFilters)
          .map((filter, key) => (filter.active ? filter.name : null))
          .filter((filterName) => filterName != null);
      });
    },
    getCategories() {
      this.$log("getCategories()");
      categoriesAPI.get().then((resp) => (this.categories = resp.data.items));
    },
    focus() {
      this.$refs.menuBtn.$el.focus();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.value, this.activeFilters)) {
          this.activeFilters = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    activeFilters: {
      handler() {
        this.$emit("input", this.activeFilters);
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isEqual(this.options, this.optionsData)) return;
        this.optionsData = this.cloneDeep(this.options);
      },
      deep: true,
    },
    optionsData: {
      handler() {
        if (this.isEqual(this.options, this.optionsData)) return;
        this.$emit("update:options", this.optionsData);
      },
      deep: true,
    },
    "optionsData.buildingIds"(ids) {
      if (ids.length > 0) {
        if (this.activeFilters.level) this.activeFilters.level.attrs.disabled = false;
        this.optionsData.levelIds = [];
        this.levels = this.buildings
          .filter((elm) => ids.includes(elm.id))
          .map((elm) => elm.levels)
          .flat();
      } else if (this.activeFilters.level) this.activeFilters.level.attrs.disabled = true;
    },
    "optionsData.manufacturerIds"(ids) {
      if (ids.length > 0) {
        if (this.activeFilters.productFamily)
          this.activeFilters.productFamily.attrs.disabled = false;
        this.optionsData.productFamilyIds = [];
        this.productFamilies = this.manufacturers
          .filter((elm) => ids.includes(elm.id))
          .map((elm) => elm.productFamilies)
          .flat();
      } else if (this.activeFilters.productFamily) {
        this.activeFilters.productFamily.attrs.disabled = true;
        this.optionsData.productFamilyIds = [];
      }
    },
    refresh(val) {
      if (val) {
        setImmediate(() => {
          this.refresh = false;
        }, 1000);
      }
    },
  },
  components: { EquipmentProvider, EquipmentInstaller, ProjectStatus, LeadStatus },
};
</script>
<style lang="scss" scoped></style>
